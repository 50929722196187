import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class ReceivableVerifyApiClass {
    // 收款核销分页
    getVerificationPage(params) {
        const url = `finance/receivableVerification/page`;
        return this.POST(url, deleteNull(params));
    }

    // 获取应收对账详情
    getVerificationOne(params) {
        const url = `finance/receivableReconciliation/findByIds`;
        return this.POST(url, params);
    }

    // 正常核销
    postVerification(params) {
        const url = `finance/receivableVerification/normalVerification`;
        return this.POST(url, params);
    }

    // 异常核销
    postExceptionVerification(params) {
        const url = `finance/receivableVerification/exceptionVerification/${params.reconciliationId}`;
        return this.POST(url, params);
    }

    // 核销复核
    postReviewVerification(params) {
        const url = `finance/receivableVerification/reVerification/${params.reconciliationId}`;
        return this.SPOST(url, params);
    }
}
