const moduleName = "common/";
export const id = {
    id: "BASIC_DATA_TAX_RATE",
    method: "POST",
    name: "税率维护",
    type: "menu",
    apiName: "getTaxRateList",
    url: `${moduleName}taxRate/list`
};

export const children = {
    SAVE_DATA: {
        id: "BASIC_DATA_TAX_RATE_SAVE_DATA",
        apiName: "saveTaxRate",
        method: "POST",
        name: "保存",
        type: "view",
        url: `${moduleName}taxRate/saveOrUpdate`
    }
};
