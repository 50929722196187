// const prevUrl = "wms/receiptDemand"
export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MONITOR",
    name: "出货监控",
    type: "menu"
    // method: 'POST',
    // apiName: 'getReceipt',
    // url: `${prevUrl}/list`
};

export const children = {
    // ADD_DATA: {
    //     id: 'WAREHOUSE_MANAGEMENT_SHIPMENT_MONITOR_ADD_DATA',
    //     apiName: 'addReceipt',
    //     method: 'POST',
    //     name: '新建',
    //     type: 'view',
    //     url: `${prevUrl}/add`
    // }
};
