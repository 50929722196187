const m = "wms/shipment";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHIPMENT_PICK_DETAILS",
    name: "拣货作业明细",
    type: "menu_hide",
    method: "GET",
    apiName: "getShipmentPickById",
    url: `${m}/getOnePick`,
    paramsType: 1
};

export const children = {
    // LIST: {
    //     id: 'WAREHOUSE_MANAGEMENT_SHIPMENT_PICK_DETAILS_LIST',
    //     apiName: 'getShipmentPickDetailsList',
    //     method: 'GET',
    //     name: '获取拣货明细列表',
    //     type: 'view',
    //     url: `${m}/getOnePick`,
    //     paramsType: 1,
    //     isOnlyApi: true
    // },
    FINISH: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_PICK_DETAILS_FINISH",
        apiName: "finishShipmentPickDetails",
        method: "GET",
        name: "拣货完成",
        type: "view",
        url: `${m}/finishPick`,
        paramsType: 1
    },
    CANCEL_PICK: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_PICK_DETAILS_CANCEL_PICK",
        name: "撤销拣货",
        type: "view"
        // apiName: 'cancelPickShipmentPickDetails',
        // method: 'GET',
        // url: `${m}/finishPick`,
        // paramsType: 1,
    }
};
