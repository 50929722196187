const m = "client/evaluation";
export const id = {
    id: "CLIENT_EVALUATION",
    method: "POST",
    name: "客户评价",
    type: "menu",
    apiName: "getClientEvaluationList",
    url: `${m}/list`,
    sort: 5
};

export const children = {
    // ADD_DATA: {
    //     id: 'CLIENT_EVALUATION_ADD_DATA',
    //     apiName: 'onSaveSuggestItem',
    //     method: 'POST',
    //     name: '新增',
    //     type: 'view',
    //     url: `${m}/save`
    // }
};
