const moduleName = "resource/";
export const id = {
    id: "RESOURCE_MANAGEMENT_BASE_COURIER",
    method: "POST",
    name: "配送员资源",
    type: "menu",
    apiName: "getDistributor",
    url: `${moduleName}distributor/list`,
    sort: 6
};

export const children = {
    ADD_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_COURIER_ADD_DATA",
        apiName: "addDistributor",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}distributor/save`
    },
    DEL_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_COURIER_CODE",
        apiName: "delDistributor",
        method: "GET",
        name: "删除",
        type: "view",
        url: `${moduleName}distributor/delete`
    },
    // UNBIND: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_UNBIND',
    //     apiName: 'unbindDirver',
    //     method: 'GET',
    //     name: '解绑司机',
    //     type: 'view',
    //     url: `${moduleName}distributor/unbind`
    // },
    IMPORT_COURIER: {
        id: "RESOURCE_MANAGEMENT_BASE_COURIER_IMPORT_COURIER",
        apiName: "importCourier",
        method: "POST",
        name: "导入",
        type: "view",
        url: `${moduleName}distributor/batchSave`
    },
    EDIT_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_COURIER_EDIT_DATA",
        apiName: "editDistributor",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}car/edit`
    }
    // AUTH_DATA: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_AUTH_DATA',
    //     apiName: 'authDistributor',
    //     method: 'POST',
    //     name: '认证',
    //     type: 'view',
    //     url: `${moduleName}car/auth`
    // },
    // CANCEL_AUTH: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_CANCEL_AUTH',
    //     apiName: 'cancelAuthDistributor',
    //     method: 'POST',
    //     name: '取消认证',
    //     type: 'view',
    //     url: `${moduleName}car/cancelAuth`
    // },
    // ENABLE: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_ENABLE',
    //     apiName: 'onDistributorEnable',
    //     method: 'POST',
    //     name: '启用',
    //     type: 'view',
    //     url: `${moduleName}car/enable`
    // },
    // DISABLE: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_DISABLE',
    //     apiName: 'onDistributorDisable',
    //     method: 'POST',
    //     name: '禁用',
    //     type: 'view',
    //     url: `${moduleName}car/disable`
    // },
    // REVIEW: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_REVIEW',
    //     apiName: 'onDistributorReview',
    //     method: 'POST',
    //     name: '审核',
    //     type: 'view',
    //     url: `${moduleName}car/reviewOrReject`
    // },
    // ASSOCIATE_DRIVER: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_ASSOCIATE_DRIVER',
    //     apiName: 'onDistributorAssociate',
    //     method: 'POST',
    //     name: '关联司机',
    //     type: 'view',
    //     url: `${moduleName}car/bindDriver`
    // },
    // REPORT: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_REPORT',
    //     apiName: 'onDistributorReport',
    //     method: 'POST',
    //     name: '上报',
    //     type: 'view',
    //     url: `${moduleName}car/report`
    // },
    // EXPORT_LIST: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_EXPORT_LIST',
    //     apiName: 'carExport',
    //     method: 'POSTFILE',
    //     name: '导出',
    //     type: 'view',
    //     url: `${moduleName}car/export`
    // },
    // EXPORT_TEMP: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_EXPORT_TEMP',
    //     apiName: 'carExportTemplate',
    //     method: 'GETFILE',
    //     name: '导出车辆模板',
    //     type: 'view',
    //     url: `${moduleName}car/exportTemp`
    // },
    // IMPORT_CAR: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_IMPORT_CAR',
    //     apiName: 'batchSaveDistributor',
    //     method: 'POST',
    //     name: '导入',
    //     type: 'view',
    //     url: `${moduleName}car/batchSave`
    // },
    // LOOK_MORE: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_COURIER_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
