import React from "react";

export default function () {
    return (
        <svg id="图层_1" data-name="图层 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
            <title>quotation</title>
            <path
                fill="currentColor"
                d="M11.5,0H2A1,1,0,0,0,1,1V15a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V4.35a1,1,0,0,0-.2-.6L12.3.4A1,1,0,0,0,11.5,0ZM14,4V15H2V1h9.55Z"
                transform="translate(-1)"
            />
            <rect fill="currentColor" x="10" y="1" width="1" height="4" />
            <rect fill="currentColor" x="12" y="3" width="1" height="3" transform="translate(16 -8) rotate(90)" />
            <path
                fill="currentColor"
                d="M3,2H4.08l1,1.9c.15.31.23.54.35.82h0c.12-.26.24-.54.39-.84L6.91,2H8L5.89,5.27v0h1.5V5.9H5.89V8H5V5.9H3.46V5.29H5v0Z"
                transform="translate(-1)"
            />
            <rect
                fill="currentColor"
                x="9.5"
                y="5.5"
                width="1"
                height="4"
                rx="0.5"
                ry="0.5"
                transform="translate(16.5 -2.5) rotate(90)"
            />
            <rect fill="currentColor" x="3" y="9" width="8" height="1" rx="0.5" ry="0.5" />
            <rect fill="currentColor" x="3" y="11" width="8" height="1" rx="0.5" ry="0.5" />
            <rect fill="currentColor" x="3" y="13" width="8" height="1" rx="0.5" ry="0.5" />
        </svg>
    );
}
