import { Modal, message } from "antd";

const likeModal = (target) => {
    target.instance = null;
    target.getInstance = () => {
        if (!Tips.instance) {
            Tips.instance = new Tips();
        }
        return Tips.instance;
    };
    target.prototype.info = message.info;
    target.prototype.success = message.success;
    target.prototype.confirm = message.confirm;
};

@likeModal
class Tips {
    warning(err, opt) {
        // console.log('waring', err, opt)
        if (arguments.length === 0) {
            return Modal.warning({ title: "操作失败" });
        } else {
            if (opt) {
                return Modal.warning(opt);
            } else {
                let title =
                    (typeof err).toLowerCase() === "string"
                        ? err
                        : err.msg
                        ? err.msg
                        : err.message
                        ? err.message
                        : "操作警告";
                return Modal.warning({ title: title });
            }
        }
    }

    error(err, opt) {
        // console.log('error', opt)
        if (arguments.length === 0) {
            return Modal.error({ title: "操作失败" });
        } else {
            if (opt) {
                return Modal.error(opt);
            } else {
                // console.log(typeof(err), err.name, err.message)
                let title =
                    (typeof err).toLowerCase() === "string"
                        ? err
                        : err.msg
                        ? err.msg
                        : err.message
                        ? err.message
                        : "操作失败";
                return Modal.error({ title: title });
            }
        }
    }
}

export default Tips.getInstance();
