import React from "react";
interface TitleSplitProps {
    title?: string;
    children?: React.ReactNode;
    noBordered?: boolean;
    noPadding?: boolean;
    noMargin?: boolean;
    className?: string;
    style?: React.CSSProperties;
}
export const TitleSplit = ({ children, title, noBordered, noPadding, noMargin, className, style }: TitleSplitProps) => {
    return (
        <div
            className={className || ""}
            style={{
                padding: noPadding ? 0 : "5px 1px",
                borderBottom: noBordered ? "none" : "1px solid #eee",
                marginBottom: noMargin ? 0 : 10,
                fontWeight: "bold",
                width: "100%",
                ...style
            }}
        >
            {title || children}
        </div>
    );
};
