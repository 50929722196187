import { id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...{ [id.id]: id }
};

export default class RealTimeProfitApi {
    POST: any;
    POSTFILE: any;

    /**
     * 实时毛利
     *
     * @param {any} params
     * @returns
     *
     * @memberOf
     */

    // 获取实时毛利列表
    getOrderGrossProfitList(params) {
        let url = `finance/orderGrossProfit/page`;
        return this.POST(url, deleteNull(params));
    }
    // 导出实时毛利列表
    exportOrderGrossProfitList(params) {
        let url = `finance/orderGrossProfit/export`;
        return this.POSTFILE(url, deleteNull(params));
    }

    // 获取应收结算费用明细
    getReceivableProfitList(params) {
        let url = `finance/receivableSettlement/orderReceivableSettlementExpensePage`;
        return this.POST(url, deleteNull(params));
    }
    // 获取应付结算费用明细
    getPayableProfitList(params) {
        let url = `finance/payableSettlement/orderPayableSettlementExpensePage`;
        return this.POST(url, deleteNull(params));
    }
    // 获取特殊结算费用明细
    getSpecialProfitList(params) {
        let url = `finance/costSpecialExpenseMaintain/page`;
        return this.POST(url, deleteNull(params));
    }
}
