const moduleName = "finance/";
import getChildPowerJson from "../../../../utils/getChildPowerJson";
import newPage from "../add/power_hide";
export const id = {
    id: "CAPITAL_FLOW_PROJECT",
    method: "POST",
    name: "资金流水",
    type: "menu",
    apiName: "getCapitalFlowList",
    url: `${moduleName}cashFlow/list`
};

// let moduleName = ''
const nPage = getChildPowerJson.toJson(newPage);

export const children = {
    [nPage.id]: nPage,
    REPORT: {
        id: "CAPITAL_FLOW_PROJECT_DEL_DATA",
        apiName: "deleteCapitalFlow",
        method: "POST",
        name: "上报",
        type: "view",
        url: `${moduleName}cashFlow/report`
    },
    EDIT_DATA: {
        id: "CAPITAL_FLOW_PROJECT_EDIT_DATA",
        apiName: "editCapitalFlow",
        method: "POSTFILE",
        name: "编辑",
        type: "view",
        url: `${moduleName}cashFlow/edit`
    }
};
