const m = "wms/receiptAbnormal";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_RECEIPT_ABNORMAL",
    method: "POST",
    name: "收货异常",
    type: "menu",
    apiName: "getReceiptAbnormalList",
    url: `${m}/list`
};

export const children = {
    ADD_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_ABNORMAL_ADD_DATA",
        apiName: "addReceiptAbnormal",
        method: "POST",
        name: "添加异常",
        type: "view",
        url: `${m}/save`
    },
    EDIT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_ABNORMAL_EDIT_DATA",
        apiName: "editReceiptAbnormal",
        method: "POST",
        name: "处理异常",
        type: "view",
        url: `${m}/save`
    },
    LOOK_MORE: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_ABNORMAL_LOOK_MORE",
        name: "查看",
        type: "view"
    },
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_ABNORMAL_DEL_DATA",
        apiName: "deleteReceiptAbnormal",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${m}/delete`
    },
    MATERIAL_ADD: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_ABNORMAL_MATERIAL_ADD",
        name: "物料添加",
        type: "element"
    },
    MATERIAL_DEAL: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_ABNORMAL_MATERIAL_DEAL",
        name: "物料处理",
        type: "element"
    }
};
