import React, { Component } from "react";
import { Col } from "antd";
import rApi from "@src/http";
import "./index.less";
class ColItem extends Component {
    static defaultProps = {
        propsStyle: {},
        style: {},
        contentStyle: {}
    };

    state = {};

    constructor(props) {
        super(props);
    }

    render() {
        let props = this.props;
        let openLabelWidth = "labelWidth" in props;
        const { style } = this.props;
        const style1 = {
            ...(props.type === 2 ? { padding: "5px 0px", fontSize: "12px", margin: "5px 0" } : { padding: "5px 0px" }),
            ...props.contentStyle
        };
        const style2 = {
            ...(props.type === 2 ? { padding: "0px 8px", borderBottom: "1px solid #eee" } : {}),
            ...props.contentStyle
        };
        if (!props.label && props.children) {
            return (
                <Col style={{ padding: 0, ...style }} offset={props.offset} span={props.span}>
                    {props.type === 2 && props.text ? (
                        <div
                            className={props.isWrap ? "" : "text-overflow-ellipsis"}
                            style={{ color: "#484848", borderBottom: "1px solid #eee" }}
                            title={props.text}
                        >
                            {props.text ? props.text : "无"}&nbsp;
                        </div>
                    ) : (
                        props.children
                    )}
                </Col>
            );
        }
        let labelStyle = {};
        if (props.labelStyle) {
            labelStyle = props.labelStyle;
        }
        if (openLabelWidth) {
            // labelStyle.width = props.labelWidth
            labelStyle = {
                ...labelStyle,
                ...props.labelWidth
            };
        }
        // console.log ('props.children', props.children)
        return (
            <Col offset={props.offset} span={props.span} style={{ padding: 0, ...style }}>
                {props.label ? (
                    <div
                        className={props.isNoCenter || props.labelInTop ? "flex" : "flex flex-vertical-center"}
                        style={Object.assign({}, style1, props.propsStyle)}
                    >
                        <div style={labelStyle}>
                            {this.props.space}
                            {props.isRequired ? (
                                <span style={{ position: "relative" }}>
                                    <i style={{ color: "red", fontWeight: "bold", position: "absolute", left: -8 }}>
                                        *
                                    </i>
                                </span>
                            ) : null}
                            <span style={{ color: "#888888" }}>
                                {React.isValidElement(props.label)
                                    ? props.label
                                    : props.label
                                    ? `${props.label}${props.colon === true ? " " : "："}`
                                    : ""}
                            </span>
                        </div>
                        <div className={props.isWrap ? "flex1" : "flex1 text-overflow-ellipsis"} style={style2}>
                            {
                                //props.children
                                props.type === 2 ? (
                                    <span
                                        className={props.isWrap ? "" : "text-overflow-ellipsis"}
                                        style={{ color: "#484848" }}
                                        title={props.text}
                                    >
                                        {props.text ? props.text : "无"}&nbsp;
                                    </span>
                                ) : (
                                    props.children
                                )
                            }
                        </div>
                        {(props.unitName && <span style={{ marginLeft: 5 }}>{props.unitName}</span>) || null}
                    </div>
                ) : (
                    <div className="flex flex-vertical-center" style={Object.assign({}, style1, props.style)}></div>
                )}
            </Col>
        );
    }
}

export default ColItem;
