import { children, id } from "./powerhidehistory";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class CooperativeApi {
    /**
     * 获取合作项目明细配置数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf DemandApi
     */
    // [children.GET_LIST.apiName](params) {
    //     let url = children.GET_LIST.url
    //     return this.POST(url, deleteNull(params))
    // }
    // getMethodByUnitId(params) { //根据费用单位ID获取对应的计算方式
    //     return this.POST(`project/cooperationProject/getStatisticsMethodByUnitId`, params)
    // }
    // getCooperationProjectById(params) { //根据id获取单条合作项目
    //     return this.POST(`project/cooperationProject/getOne/${params.id}`)
    // }
    // getCustoms(params) { //获取关区数据
    //     return this.POST(`cooperationProject/getCustomsAreaByProjectId/${params.projectId}` )
    // }
    // [power[id.id].apiName](params) {
    //     let url = power[id.id].url + '/' + params.id
    //     return this.POST(url, deleteNull(params))
    // }
    // [children.EDIT_DATA.apiName](params) {
    //     let url = children.EDIT_DATA.url
    //     return this.POST(url, params)
    // }
    // [children.START_DATA.apiName](params) {
    //     let url = children.START_DATA.url + '/' + params.id
    //     return this.POST(url, params)
    // }
    // [children.STOP_DATA.apiName](params) {
    //     let url = children.STOP_DATA.url + '/' + params.id
    //     return this.POST(url, params)
    // }
    // [children.IMPORT.apiName](params) {
    //     let url = children.IMPORT.url
    //     return this.POST(url, params)
    // }
    // projectSaveDevice(params) { //保存绑定设备
    //     return this.POST(`project/cooperationProject/saveDevice`, params)
    // }
    // projectDeleteDevice(params) { //删除设备
    //     return this.GET(`project/cooperationProject/deleteDevice/${params.id}`)
    // }
    // projectExportDevice(params) { //绑定nb导出
    //     return this.GETFILE(`project/cooperationProject/exportDeviceData/${params.id}`)
    // }
    // projectDeleteDemand(params) { // 需求规划配置删除
    //     return this.GET(`project/cooperationProject/deleteDemand/${params.id}`)
    // }
    // addMessagePush(params) { // 新增/编辑消息推送
    //     return this.POST(`project/messagePush/save`, params)
    // }
    // getMessagePushList(params) { // 获取消息推送
    //     return this.POST(`project/messagePush/list`, params)
    // }
    // delMessagePush(params) { // 删除消息推送
    //     return this.POST(`project/messagePush/delete`, params)
    // }
    // addReportPush(params) { // 新增/编辑报表推送
    //     return this.POST(`project/reportPush/save`, params)
    // }
    // getReportPushList(params) { // 获取报表推送
    //     return this.POST(`project/reportPush/list`, params)
    // }
    // delReportPush(params) { // 删除报表推送
    //     return this.POST(`project/reportPush/delete`, params)
    // }
}
