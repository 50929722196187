import { md5hash } from "../utils/hash";
import cookie from "@src/router/login/cookie.js";
import { allbooktypes } from "@src/store/wordbooknav";

let mName1 = "common/";
let mName2 = "resource/";
const enumPrev = "resource/enum/";

export default class PublicApi {
    constructor(props) {
        const list1 = Object.getOwnPropertyNames(Object.getPrototypeOf(this));

        for (let name of list1) {
            if (name !== "constructor") {
                props[name] = this[name];
            }
        }
    }

    /**
     * 获取系统信息
     */
    getSystemInformation() {
        return new Promise((resolve, reject) => {
            this.GET("/account/company/info")
                .then((res) => {
                    resolve(res);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
    /**
     * 获取登录 token
     *
     * @param {any} params
     * @returns
     */
    getAccessToken(params) {
        // 身份验证接口
        return new Promise((resolve, reject) => {
            if (!params.password) {
                reject("no password");
            }
            // 验证码登录 不需要加密， password 提前加密
            // params.password = md5hash(params.password);
            this.SPOST("/web/token", {
                grant_type: "password",
                ...params
            })
                .then((token) => {
                    resolve(token);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    /**
     * 刷新token
     *
     * @param {any} params
     * @returns
     *
     * @memberOf PublicApi
     */
    refreshToken() {
        return new Promise((resolve, reject) => {
            const user = cookie.getLastUser();
            // console.log("cookie", user);
            if (!user?.password) {
                reject(Error("登录未记住密码!"));
            }
            this.SPOST("/web/token", {
                grant_type: "refresh_token",
                refreshToken: true,
                refresh_token: localStorage.getItem("refreshToken"),
                username: user?.username ?? localStorage.getItem("userAccount"),
                password: user?.password ? md5hash(user?.password) : null
            })
                .then((token) => {
                    resolve(token);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getWordBooks(params) {
        return this.GET(`${mName1}dictionary/${params.id}`);
    }

    verificationCode(params) {
        return this.SPOST(`/accountRequestCode`, params);
    }

    getDicByText(text) {
        const target = allbooktypes.find((item) => item["text"] === text) || { id: null };
        return this.GET(`${mName1}dictionary/getDictionaryByTypeAndStatus/${target.id}`);
    }

    getDictionaryByTypeAndStatus(params) {
        return this.GET(`${mName1}dictionary/getDictionaryByTypeAndStatus/${params.id}`);
    }

    addBook(params) {
        return this.POST(`${mName1}dictionary/add`, params);
    }

    // delBook(params) {
    //     return this.GET(`${mName1}dictionary/delete/${params.id}`)
    // }

    setBookStatus(params) {
        return this.GET(`${mName1}dictionary/updateStatus/${params.id}?status=${params.status}`);
    }

    editBook(params) {
        return this.POST(`${mName1}dictionary/save`, params);
    }

    getLegalPersons(params) {
        return this.POST(`${mName1}faren/getFarens`, params);
    }

    createLegalPersons(params) {
        return this.POST(`${mName1}faren/add`, params);
    }

    editLegalPersons(params) {
        return this.POST(`${mName1}faren/save`, params);
    }

    deleteLegalPersons(params) {
        return this.GET(`${mName1}faren/delete/${params.id}`);
    }

    getAreas(params) {
        return this.POST(`${mName1}area/getAreas`, params);
    }

    deleteArea(params) {
        return this.GET(`${mName1}area/delete/${params.id}`);
    }

    addArea(params) {
        return this.POST(`${mName1}area/add`, params);
    }

    editArea(params) {
        return this.POST(`${mName1}area/save`, params);
    }

    bindArea(params) {
        return this.POST(`${mName1}district/bindArea`, params);
    }

    getOwnProvinces(params) {
        return this.GET(`${mName1}district/getOwnProvince/${params.id}`);
    }

    getProvinces(type) {
        if (type) {
            return this.GET(`${mName1}district/getAllProvinces?type=${type}`);
        }
        return this.GET(`${mName1}district/getAllProvinces`);
    }

    getAbroadArea(type) {
        if (type) {
            return this.GET(`${mName1}district/getArea?type=${type}`);
        }
        return this.GET(`${mName1}district/getArea`);
    }

    getCitys(params) {
        return this.GET(`${mName1}district/getCitiesByProvinceCode/${params.id}`);
    }

    getCountys(params) {
        return this.GET(`${mName1}district/getAreasByCityCode/${params.id}`);
    }

    getStreets(params) {
        return this.GET(`${mName1}district/getStreetsByAreaCode/${params.id}`);
    }

    getAreaByAddress(params) {
        return this.GET(`${mName1}faren/getAreaByAddress/${params.id}`);
    }
    // submitData(params) {
    //     return this.POST(`${mName2}carrier/submit`, params)
    // }
    getAreaByProvince(params) {
        //根据省份获取片区名称
        return this.GET(`${mName1}district/getAreaByProvince/${params.proName}`);
    }

    getCarPageData() {
        return this.GET(`${mName2}car/page/data`);
    }

    downQuotationTemp(params) {
        return this.GETFILE(`${mName2}carrierQuotation/downQuotationTemp` + params);
        // return this.GET(`http://192.168.2.242:8084/resource/carrierQuotation/downQuotationTemp?showFields=[%22%E8%B5%B7%E8%BF%90%E5%9C%B0%22,%22%E7%9B%AE%E7%9A%84%E5%9C%B0%22,%22%E6%97%B6%E6%95%88%22,%22%E6%98%AF%E5%90%A6%E9%AB%98%E9%80%9F%22]`)
    }

    exportQuotationTemp(url, params) {
        //  return this.GETFILE(`${mName2}carrierQuotation/exportData` + params)
        // console.log('exportQuotationTemp', params)
        return this.GETFILE(url, params);
        // return this.GET(`http://192.168.2.242:8084/resource/carrierQuotation/downQuotationTemp?showFields=[%22%E8%B5%B7%E8%BF%90%E5%9C%B0%22,%22%E7%9B%AE%E7%9A%84%E5%9C%B0%22,%22%E6%97%B6%E6%95%88%22,%22%E6%98%AF%E5%90%A6%E9%AB%98%E9%80%9F%22]`)
    }

    publicRequest(url, method, params) {
        return this[method](url, params);
    }

    // getAddressJson() {
    //     return new Promise((resolve, reject) => {
    //         axios.get('/assetsdata/address.json').then(res => resolve(res.data)).catch(error => {
    //             console.log('获取地址数据失败')
    //             resolve({ data: [] })
    //         })
    //     })
    // }

    /**
     * 保存表头
     * @param { } params
     */
    onSaveTableHeader(params) {
        return this.POST(`${mName1}accountListSort/saveOrUpdate`, params);
    }

    /**
     * 获取表头
     * @param { type } params
     */
    getTableHeaderByType(params) {
        return this.POST(`${mName1}accountListSort/list`, params);
    }

    /**
     * 删除表头配置
     * @param { type } params
     */
    clearTableHeaderByType(params) {
        return this.POST(`${mName1}accountListSort/delete`, params);
    }

    // 获取银行
    getEnumBankType() {
        return this.GET(`${enumPrev}getBankType`);
    }

    // 获取付款方式
    getEnumPaymentMethod() {
        return this.GET(`${enumPrev}getPaymentMethod`);
    }

    // 获取合作状态
    getCooperationStatus() {
        return this.GET(`common/dictionary/cooperationStatus`);
    }

    // 获取所有汇率
    getExchangeRateAll() {
        return this.GET(`common/exchangeRate/getAll`);
    }

    // 获取合作状态
    getExpenseCostItems(params) {
        return this.POST(`common/expense/getListByWareHouseExpenseType`, params);
    }

    // 根据 ids 获取 发货单的路线行
    getOrderReceiveSenders(params) {
        return this.POST(`order/stowage/orderReceiveSender`, params);
    }

    getExchangeRateCycle(params) {
        return this.GET(
            `/common/exchangeRate/cycle?exchangeCurrency=${params.exchangeCurrency}&holdCurrency=${params.holdCurrency}&time=${params.time}`,
            params
        );
    }

    commonUploadFile(params, rqConfig) {
        return this.FDPOST(`/common/file/uploadFile`, params, rqConfig);
    }
}
