const m = "wms/report";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHEET_DELIVER",
    name: "出货报表",
    type: "menu",
    apiName: "getReportShipmentList",
    method: "POST",
    url: `${m}/shipmentList`
};

export const children = {
    EXPORT: {
        id: "WAREHOUSE_MANAGEMENT_SHEET_DELIVER_EXPORT",
        apiName: "exportShipmentData",
        method: "POSTFILE",
        name: "导出报表",
        type: "view",
        url: `wms/export/report/shipmentData`
    }
};
