import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};
export default class ConsigneeApi {
    /**
     * 获取收货方数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.DELETE(url, params);
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        return this.POST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url;
        return this.PUT(url, params);
    }

    [children.BLUK_CREATE.apiName](params) {
        // 导入
        let url = children.BLUK_CREATE.url;
        return this.POST(url, params);
    }

    [children.EXPORT_TEMPLATE.apiName](params) {
        // 导出模板
        let url = children.EXPORT_TEMPLATE.url;
        return this.GETFILE(url, params);
    }

    salesman_getOne(params) {
        // 取消默认收货方
        let url = `resource/salesman/getOne/${params.id}`;
        return this.GET(url, params);
    }
}
