import { children, id } from "./power_hide";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class ReportInventoryApi {
    /**
     * @param {any} params
     * @returns
     * @memberOf ReportInventoryApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    // [children.EXPORT.apiName](params) {
    //     let url = children.EXPORT.url
    //     return this.POSTFILE(url, deleteNull(params))
    // }

    getWarehouseArea(params) {
        // 获取库存仓库
        let url = `wms/warehouse/area/` + params.id;
        return this.GET(url, params);
    }

    submitWarehouseAreaLayout(params) {
        let url = `wms/warehouse/map/save`;
        return this.POST(url, params);
    }

    getWarehouseAreaLayout(params) {
        let url = `wms/warehouse/` + params.id;
        return this.GET(url, params);
    }
}
