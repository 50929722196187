import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

const moduleName = "finance/receivableReconciliation";
const moduleNameWms = "wms/reconciliation";
export default class CarApi {
    /**
     * 获取应收对账跟踪列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    // [children.UPLOAD_INVOICE.apiName](params) { //上传发票
    //     let url = children.UPLOAD_INVOICE.url
    //     return this.POST(url, params)
    // }

    // [children.EDIT_INVOICE.apiName](params) { // 编辑发票号
    //     let url = `${children.EDIT_INVOICE.url}/${params.id}/${params.receiptNumber}`
    //     return this.GET(url, params)
    // }

    // [children.DEL_DATA.apiName](params) { //删除开票
    //     let url = children.DEL_DATA.url + `/${params.id}`
    //     return this.GET(url, params)
    // }

    uploadBillAttachment(params) {
        //上传发票
        let url = `${moduleName}/updateInvoiceInformationById`;
        return this.POST(url, params);
    }

    uploadBillAttachmentForWms(params) {
        //上传发票wms
        let url = `${moduleNameWms}/updateInvoiceInformationById`;
        return this.POST(url, params);
    }

    getCreateBillingUser(params) {
        //获取开票申请人
        return this.GET(`${moduleName}/getCreateUser`, params);
    }

    getBillingClientLegal(params) {
        //获取客户法人
        return this.GET(`${moduleName}/getClientLegal`, params);
    }

    getBillingProject(params) {
        //获取项目
        return this.GET(`${moduleName}/getProject`, params);
    }
}
