const moduleName = "order/order/";
export const id = {
    id: "BUSINESS_MANAGEMENT_ORDER_DISTRIBUTE",
    method: "POST",
    name: "发货单分配",
    type: "menu",
    sort: 3
};

export const children = {
    NO_DISTRIBUTE: {
        id: "BUSINESS_MANAGEMENT_ORDER_NO_DISTRIBUTE",
        apiName: "orderDistributionNo",
        method: "POST",
        name: "不合单分配",
        type: "view",
        url: `${moduleName}/assign`
    },
    DISTRIBUTE_ONE: {
        id: "BUSINESS_MANAGEMENT_ORDER_DISTRIBUTE_ONE",
        apiName: "orderDistributionOne",
        method: "POST",
        name: "逐一分配",
        type: "view",
        url: `${moduleName}distributeOneByOne`
    },
    DISTRIBUTE_COMBINE: {
        id: "BUSINESS_MANAGEMENT_ORDER_DISTRIBUTE_COMBINE",
        apiName: "orderDistributionCombine",
        method: "POST",
        name: "合单分配",
        type: "view",
        url: `${moduleName}combinedOrderDistribution`
    },
    DISTRIBUTE_Manual: {
        id: "BUSINESS_MANAGEMENT_ORDER_DISTRIBUTE_Manual",
        apiName: "orderDistributionManual",
        method: "POST",
        name: "人工分配",
        type: "view",
        url: `${moduleName}manualAssignment`
    }
};
