const moduleName = "project/shipper";
export const id = {
    id: "PROJECT_MANAGEMENT_CONSIGNER",
    method: "POST",
    name: "发货方管理",
    type: "menu",
    apiName: "getConsigners",
    url: `${moduleName}/page`,
    sort: 2
};

// let moduleName = ''
export const children = {
    ADD_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_ADD_DATA",
        apiName: "addConsigner",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}/save`
    },
    DEL_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_CODE",
        apiName: "delConsigner",
        method: "DELETE",
        name: "删除",
        type: "view",
        url: `${moduleName}/remove`
    },
    EDIT_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_EDIT_DATA",
        apiName: "editConsigner",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}/edit`
    },
    ENABLE_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_ENABLE_DATA",
        method: "PUT",
        name: "启用",
        type: "view"
    },
    DISABLE_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_DISABLE_DATA",
        name: "禁用",
        type: "view"
    },
    CANCELDEFUALT_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_CANCELDEFUALT_DATA",
        name: "取消默认收货方",
        type: "view"
    },
    SETDEFUALT_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_SETDEFUALT_DATA",
        name: "设置默认收货方",
        type: "view"
    },
    BLUK_CREATE: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_BLUK_CREATE",
        apiName: "consignerBatchSave",
        method: "POST",
        name: "批量导入",
        type: "view",
        url: `${moduleName}/batchSave`
    },
    EXPORT_TEMPLATE: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_EXPORT_TEMPLATE",
        apiName: "consignerExportTemplate",
        method: "GETFILE",
        name: "导出模板",
        type: "view",
        url: `${moduleName}/exportTemplate`
    },
    LOOK_MORE: {
        id: "PROJECT_MANAGEMENT_CONSIGNER_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
