const mdl = "wms/warehouseCost";
const md2 = "wms/reconciliation";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_PRIMECOST",
    method: "POST",
    name: "仓储成本录入",
    type: "menu",
    apiName: "getWhPrimeCostList",
    url: `${mdl}/list`
};

export const children = {
    ADD_DATA: {
        id: "WAREHOUSE_MANAGEMENT_PRIMECOST_ADD_DATA",
        apiName: "addWhPrimeCost",
        method: "POST",
        name: "新建仓储成本",
        type: "view",
        url: `${mdl}/add`
    },
    EDIT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_PRIMECOST_EDIT_DATA",
        apiName: "editWhPrimeCost",
        method: "POST",
        name: "编辑仓储成本",
        type: "view",
        url: `${mdl}/edit`
    },
    SEE: {
        id: "WAREHOUSE_MANAGEMENT_PRIMECOST_SEE",
        name: "查看仓储成本",
        type: "view"
    },
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_PRIMECOST_DEL_DATA",
        apiName: "delWhPrimeCost",
        method: "GET",
        name: "删除仓储成本",
        type: "view",
        url: `${mdl}/delete`
    },
    CONFIRM_DATA: {
        id: "WAREHOUSE_MANAGEMENT_PRIMECOST_CONFIRM_DATA",
        apiName: "confirmWhPrimeCost",
        method: "GET",
        name: "确认仓储成本",
        type: "view",
        url: `${mdl}/confirm`
    },
    CANCEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_PRIMECOST_CANCEL_DATA",
        apiName: "cancelWhPrimeCost",
        method: "GET",
        name: "取消仓储成本确认",
        type: "view",
        url: `${mdl}/cancel`
    },
    GENERATE_BILLS: {
        id: "WAREHOUSE_MANAGEMENT_PRIMECOST_GENERATE_BILLS",
        // apiName: 'cancelWhPrimeCost',
        //method: 'GET',
        name: "生成对账单",
        type: "view"
        // url: `${mdl}/cancel`
    },
    BILLS_CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_PRIMECOST_CONFIRM",
        apiName: "reconciliationSave",
        method: "POST",
        name: "确定生成对账单",
        type: "view",
        url: `${md2}/save`
    }
};
