const prevUrl = "finance/costSpecialExpenseMaintain/";

export const id = {
    id: "SPECIAL_COAST",
    method: "POST",
    name: "成本特殊费用维护",
    type: "menu",
    apiName: "getSpecialCostList",
    url: `${prevUrl}page`,
    sort: 5
};
export const children = {
    GET_ALL_UNIT: {
        id: "SPECIAL_COAST_GET_ALL_UNIT",
        apiName: "getAllUnit",
        method: "GET",
        name: "获取所有单位",
        type: "view",
        url: `common/dictionary/getAllUnit`
    },
    SAVE: {
        id: "SPECIAL_COAST_SAVE",
        apiName: "specialCostsave",
        method: "POST",
        name: "新增",
        type: "view",
        url: `${prevUrl}save`
    },
    SAVE_AND_SUBMIT: {
        id: "SPECIAL_COAST_SAVE_AND_SUBMIT",
        apiName: "specialCostSaveAndSubmit",
        method: "POST",
        name: "新增并提交",
        type: "view"
        // url: `${prevUrl}saveAndSubmit/${flowDefineCode}`
    },
    SUBMIT: {
        id: "SPECIAL_COAST_SUBMIT",
        apiName: "specialCostSumbit",
        method: "POST",
        name: "提交",
        type: "view"
    },
    UPDATE: {
        id: "SPECIAL_COAST_UPDATE",
        apiName: "specialCostUpdate",
        method: "POST",
        name: "修改",
        type: "view"
    },
    CANCEL_SUMBIT: {
        id: "SPECIAL_COAST_CANCEL_SUMBIT",
        apiName: "specialCostCancelSumbit",
        method: "POST",
        name: "取消提交",
        type: "view"
    },
    REJECT_TASK: {
        id: "SPECIAL_COAST_REJECT_TASK",
        apiName: "specialCostRejectTask",
        method: "SPOST",
        name: "驳回",
        type: "view"
    },
    COMPLETE_TASK: {
        id: "SPECIAL_COAST_COMPLETE_TASK",
        apiName: "specialCostCompleteTask",
        method: "POST",
        name: "审核通过",
        type: "view"
    },

    CHECK_APPROVE_PERMISSION: {
        id: "SPECIAL_COAST_CHECK_APPROVE_PERMISSION",
        apiName: "specailCheckApprovePermission",
        method: "GET",
        name: "检测审核权限",
        type: "view"
    },

    DELETE: {
        id: "SPECIAL_COAST_DELETE",
        apiName: "deleteSpecial",
        method: "POST",
        name: "删除",
        type: "view"
    }
};
