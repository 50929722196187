import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};
const moduleName = "account/";

export default class AddOrderApi {
    /**
     * 获取新建发货单列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf UserApi
     */

    [children.CONFIRM_DATA.apiName](params) {
        //确认
        const url = children.CONFIRM_DATA.url + "/" + params.id;
        return this.GET(url, params);
    }

    [children.LOCK_ORDER.apiName](params) {
        // 锁单
        const url = children.LOCK_ORDER.url + "/" + params.id;
        return this.GET(url, params);
    }

    [children.CANCEL_DATA.apiName](params) {
        // 取消确认
        const url = children.CANCEL_DATA.url + "/" + params.id;
        return this.GET(url, params);
    }

    // [children.ADD_PRA_ORDER.apiName](params) {
    //     const url= children.ADD_PRA_ORDER.url
    //     return this.POST(url, params)
    // }

    [children.SAVE_DATA.apiName](params) {
        // 保存
        const url = children.ADD_PRA.url;
        return this.POST(url, params);
    }

    // [children.GET_RECEIVERS.apiName](params) {
    //     const url= children.GET_RECEIVERS.url
    //     return this.POST(url, params)
    // }

    // addPraOrder(params) { //提交预发货单
    //     const url= 'finance/receivableSettlement/saveOrderForm'
    //     return this.POST(url, params)
    // }

    // getReciversAndWarehouse(params) { //获取收发货方列表和仓库列表
    //     const url= 'project/receiverOrSender/getList'
    //     return this.POST(url, params)
    // }

    // getProClientQuotation(params) { //获取客户报价
    //     const url= 'project/cooperationProject/getClientQuotationRow'
    //     return this.POST(url, params)
    // }

    // getClientQuotationTransportMode(params) { //获取客户报价
    //     const url= 'project/cooperationProject/getClientQuotationTransportMode'
    //     return this.POST(url, params)
    // }

    getReceivableSettlementDetailById(params) {
        //根据ID获取单个发货单信息
        const url = `finance/receivableSettlement/getOne/${params.id}`;
        return this.GET(url, params);
    }

    rsGetEstimateExpenseByOrderId(params) {
        const url = `finance/receivableSettlement/getSettlementExpense/${params.id}`;
        return this.GET(url, params);
    }

    rsSwitchQuotationCost(params) {
        const url = `finance/receivableSettlement/switch/billingMethod/quotation/${params.id}`;
        return this.GET(url, params);
    }

    rsSwitchTempCost(params) {
        const url = `finance/receivableSettlement/switch/billingMethod/temp/${params.id}`;
        return this.GET(url, params);
    }

    rsChangeQuotationLine(params) {
        const url = `finance/receivableSettlement/matchQuotationLineExpense/${params.id}/${params.quotationLineId}`;
        return this.GET(url, params);
    }

    rsGetMatchQuotationLineExpense(params) {
        const url = `project/clientQuotation/matchQuotationLineExpense/${params.quotationLineId}`;
        return this.GET(url, params);
    }

    rsEditEstimateExpense(params) {
        const url = `finance/receivableSettlement/editExpense`;
        return this.POST(url, params);
    }

    lockReceivableSettlement(params) {
        const url = `finance/receivableSettlement/lock/` + params.id;
        return this.GET(url, params);
    }

    unLockReceivableSettlement(params) {
        const url = `finance/receivableSettlement/unLock/` + params.id;
        return this.GET(url, params);
    }

    saveReceivableSettlement(params) {
        const url = `finance/receivableSettlement/edit`;
        return this.POST(url, params);
    }
}
