import { children, id } from "./power";
// import { deleteNull } from '@src/utils'
const power = {
    ...children,
    ...{ [id.id]: id }
};

let moduleName = "common/";

export default class AddressFileApi {
    // 根据id获取单条数据
    deleteDistrict(params) {
        let url = `${moduleName}district/delete?id=${params.id}`;
        return this.GET(url);
    }

    // 修改地址档案
    editDistrict(params) {
        let url = `${moduleName}district/edit`;
        return this.POST(url, params);
    }
}
