// declare module "react-base-table"
import * as React from "react";
import ReactBaseTable, { AutoResizer } from "@src/libs/react-base-table/src";
// import AutoResizer from '@src/libs/react-base-table/src/AutoResizer'
import { Pagination, Spin, Popconfirm, Icon, Checkbox } from "antd";
import { shallowEqual, BrowserType } from "@src/utils";
import classNames from "classnames";
import styled from "styled-components";
import {
    // flatArray,
    treeMap,
    flatFilter,
    normalizeColumns
} from "antd/es/table/util";
import "react-base-table/styles.css";
import "./index.less";
import { connect } from "dva";
import _ from "@src/utils/lodash";

// console.log('BrowserType', BrowserType(), BrowserType() === 'Chrome')
// tslint:disable-next-line:variable-name
// const BaseTable = ReactBaseTable;
const emptyObject = {};

// tslint:disable-next-line:no-empty
function noop() {}

const defaultPagination = {
    onChange: noop,
    onShowSizeChange: noop
};

let BaseTable = ReactBaseTable;
if (BrowserType() === "Chrome") {
    BaseTable = styled(ReactBaseTable)`
        .BaseTable__table .BaseTable__body {
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                background-color: #e3e3e3;
            }

            ::-webkit-scrollbar:vertical {
                width: 10px;
            }

            ::-webkit-scrollbar:horizontal {
                height: 10px;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                border: 2px solid #e3e3e3;
                background-color: #999;

                &:hover {
                    background-color: #666;
                }
            }

            ::-webkit-resizer {
                display: none;
            }
        }
    `;
}

// export interface IColumnProps<T> extends ColumnProps<T> {
//     resizable?: boolean;
// }
// export interface ITableProps<T> extends TableProps<T> {
//     columns?: Array<IColumnProps<T>>;
//     width;
//     onColumnResize?: (event) => void;
//     onColumnResizeEnd?: (event) => void;
// }

// export interface ITableState<T> extends TableState<T>  {
//     width: number;
//     show: boolean;
// }
// const getScrollbarSize = () => 10

const getScrollbarSize =
    BrowserType() === "Chrome"
        ? {
              getScrollbarSize: () => 10
          }
        : {};

class FilterPop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedValue: []
        };
    }

    render() {
        const { onTableChange } = this.props;
        const { column } = this.props;
        const { checkedValue } = this.state;
        const options = column.filters.map((item) => ({
            label: item.text,
            value: item.value
        }));
        return (
            <Popconfirm
                placement="bottomLeft"
                icon={null}
                overlayClassName="global_header_column_filters_pop"
                title={
                    <Checkbox.Group
                        options={options}
                        onChange={(checkedValue) => {
                            this.setState({ checkedValue });
                        }}
                    />
                }
                onConfirm={() =>
                    onTableChange(undefined, {
                        [column.key]: checkedValue.map((val) => "" + val)
                    })
                }
                okText="确定"
                cancelText="取消"
            >
                <Icon type="filter" />
            </Popconfirm>
        );
    }
}

const headerRenderer = ({ cells, columns }, onTableChange) => {
    const ret = [];
    columns.forEach((column, idx) => {
        const target = cells[idx];
        if (!!column.filters && column.filters instanceof Array && column.filters.length) {
            ret.push(
                <div
                    {...target.props}
                    key={`header-${idx}`}
                    className={`${target.props.className} global_header_column_filters`}
                >
                    <div className="con">
                        {target && target.props && target.props.children && target.props.children[1]
                            ? target.props.children[1]
                            : null}
                        <FilterPop onTableChange={onTableChange} column={column} />
                    </div>
                    {target && target.props && target.props.children && target.props.children[3]
                        ? target.props.children[3]
                        : null}
                </div>
            );
        } else if (!!column.filterView) {
            ret.push(
                <div
                    {...target.props}
                    key={`header-${idx}`}
                    className={`${target.props.className} global_header_column_filters`}
                    style={{ ...target.props.style, paddingRight: 0 }}
                >
                    <div className="con" style={{ width: "100%", height: "100%" }}>
                        <div className="flex flex1" style={{ alignItems: "center" }}>
                            {target && target.props && target.props.children && target.props.children[1]
                                ? target.props.children[1]
                                : null}
                        </div>
                        {React.isValidElement(column.filterView)
                            ? column.filterView
                            : typeof column.filterView === "function"
                            ? column.filterView()
                            : null}
                    </div>
                    {target && target.props && target.props.children && target.props.children[3]
                        ? target.props.children[3]
                        : null}
                </div>
            );
        } else if (!!column.title && typeof column.title !== "string") {
            ret.push(
                <div
                    {...target.props}
                    key={`header-${idx}`}
                    className={`${target.props.className} global_header_column_custom`}
                >
                    {column.title}
                    {target && target.props && target.props.children && target.props.children[3]
                        ? target.props.children[3]
                        : null}
                </div>
            );
        } else {
            ret.push(cells[idx]);
        }
    });
    // console.log('headerRenderer', columns, cells)
    return ret;
};
@connect(({ global }) => {
    const { domWidth, siderWidth, freshTabKey } = global;
    // console.log('change', domWidth, siderWidth)
    return {
        siderWidth,
        freshTabKey
    };
})
class ITable extends React.Component {
    static defaultProps = {
        dataSource: [],
        prefixCls: "ant-table",
        useFixedHeader: false,
        className: "",
        size: "small",
        loading: false,
        bordered: true,
        indentSize: 20,
        locale: {},
        // rowKey: 'key',
        showHeader: true
    };

    columns = [];
    rootDom = React.createRef(null);
    titleDom = null;
    tableContent = null;
    pageChange = false;
    state = {
        pagination: {},
        filters: {},
        sortColumn: null,
        prevProps: {},
        components: {},
        columns: [],
        sortOrder: undefined,
        width: 500,
        show: true
    };

    constructor(props) {
        super(props);
        this.columns = props.columns || normalizeColumns(props.children);
        this.state = {
            ...this.state,
            ...this.getDefaultSortOrder(this.columns),
            // 减少状态
            filters: this.getFiltersFromColumns(),
            pagination: this.getDefaultPagination(props),
            width: props.width || 500,
            show: !!props.width
            // pivot: 0
        };
    }

    componentDidMount() {
        const { show } = this.state;
        const { getTableAllWidth } = this.props;
        // const MutationObserver = window.MutationObserver
        // if (MutationObserver) {
        //     const callback = function (mutationsList) {
        //         console.log('mutationsList', mutationsList)
        //         for (var mutation of mutationsList) {
        //             if (mutation.type == 'childList') {
        //                 console.log('A child node has been added or removed.');
        //             }
        //             else if (mutation.type == 'attributes') {
        //                 console.log('The ' + mutation.attributeName + ' attribute was modified.');
        //             }
        //         }
        //     };
        //     const observer = new MutationObserver(callback);
        //     observer.observe(this.rootDom.current, {
        //         attributes: true,
        //         childList: false,
        //         subtree: false
        //     });
        // }
        // console.log('this.rootDom', this.rootDom, this.state.width)
        // if (!show && this.rootDom && this.rootDom.current) {
        //     this.setState({
        //         show: true,
        //         width: this.rootDom.current.offsetWidth
        //     })
        //     if(getTableAllWidth) {
        //         getTableAllWidth(this.rootDom.current.offsetWidth)
        //     }
        // }
        // window.addWindowEvents('contentResize', this.windowResize, false)
    }

    windowResize = () => {
        // const { getTableAllWidth } = this.props
        // clearTimeout(this.timer)
        // this.timer = setTimeout(() => {
        //     if (this.rootDom && this.rootDom.current) {
        //         this.setState({
        //             show: true,
        //             width: this.rootDom.current.offsetWidth
        //         })
        //         if(getTableAllWidth) {
        //             getTableAllWidth(this.rootDom.current.offsetWidth)
        //         }
        //     }
        // }, 100)
    };

    componentWillUnmount() {
        clearTimeout(this.timer);
        window.removeWindowEvents("contentResize", this.windowResize, false);
    }

    // componentDidUpdate() {
    //     const { getTableAllWidth } = this.props
    //     if (this.rootDom && this.rootDom.current && this.rootDom.current.offsetWidth && (this.rootDom.current.offsetWidth !== this.state.width)) {
    //         this.setState({
    //             width: this.rootDom.current.offsetWidth
    //         })
    //     } else {
    //         setTimeout(() => {
    //             if (this.rootDom && this.rootDom.current && this.rootDom.current.offsetWidth && (this.rootDom.current.offsetWidth !== this.state.width)) {
    //                 this.setState({
    //                     width: this.rootDom.current.offsetWidth
    //                 })
    //             }
    //         }, 20)
    //     }
    // }

    shouldComponentUpdate(newProps, newState) {
        // console.log('shouldComponentUpdate', )
        const oldProps = this.props;
        const oldState = this.state;
        // if (newProps.rowIndex === 2)
        // console.log('oldProps.rowData', this.rowData.isEdit, newProps.rowData.isEdit)
        const { siderWidth } = this.props;
        // console.log('w', siderWidth, newProps.siderWidth)
        const updateTabTable = !!newProps.freshTabKey && newProps.freshTabKey !== oldProps.freshTabKey;
        return (
            !shallowEqual(oldProps, newProps) ||
            !shallowEqual(oldState, newState) ||
            !shallowEqual(this.rowData, newProps.rowData) ||
            siderWidth !== newProps.siderWidth ||
            updateTabTable
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.columns = nextProps.columns || normalizeColumns(nextProps.children);
        if ("pagination" in nextProps || "pagination" in this.props) {
            this.setState(
                (previousState) => {
                    const newPagination = {
                        ...defaultPagination,
                        ...previousState.pagination,
                        ...nextProps.pagination
                    };
                    newPagination.current = newPagination.current || 1;
                    newPagination.pageSize = newPagination.pageSize || 10;
                    return { pagination: nextProps.pagination !== false ? newPagination : emptyObject };
                },
                () => {
                    const newPagination = this.state.pagination;
                    if (
                        newPagination &&
                        newPagination.total &&
                        newPagination.pageSize > 0 &&
                        newPagination.current > Math.ceil(newPagination.total / newPagination.pageSize)
                    ) {
                        this.handlePageChange(Math.ceil(newPagination.total / newPagination.pageSize));
                    }
                }
            );
        }
        if (nextProps.rowSelection && "selectedRowKeys" in nextProps.rowSelection) {
            // this.store.setState({
            //     selectedRowKeys: nextProps.rowSelection.selectedRowKeys || [],
            // });
        }
        if ("dataSource" in nextProps && nextProps.dataSource !== this.props.dataSource) {
            // this.store.setState({
            //     selectionDirty: false,
            // });
        }
    }

    componentDidUpdate(preProps) {
        if (preProps.loading !== this.props.loading && preProps.loading && this.pageChange) {
            this.pageChange = false;
            if (this.tableContent) {
                this.tableContent.scrollToTop(0);
                this.tableContent.scrollToLeft(0);
            }
        }
    }

    getDefaultSortOrder(columns) {
        const definedSortState = this.getSortStateFromColumns(columns);

        const defaultSortedColumn = flatFilter(columns || [], (column) => column.defaultSortOrder != null)[0];

        if (defaultSortedColumn && !definedSortState.sortColumn) {
            return {
                sortColumn: defaultSortedColumn,
                sortOrder: defaultSortedColumn.defaultSortOrder
            };
        }

        return definedSortState;
    }

    getSortStateFromColumns(columns) {
        // return first column which sortOrder is not falsy
        const sortedColumn = this.getSortOrderColumns(columns).filter((col) => col.sortOrder)[0];

        if (sortedColumn) {
            return {
                sortColumn: sortedColumn,
                sortOrder: sortedColumn.sortOrder
            };
        }

        return {
            sortColumn: null,
            sortOrder: null
        };
    }

    getSortOrderColumns(columns) {
        return flatFilter(columns || this.columns || [], (column) => "sortOrder" in column);
    }

    getFiltersFromColumns(columns) {
        const filters = {};
        this.getFilteredValueColumns(columns).forEach((col) => {
            const colKey = this.getColumnKey(col);
            filters[colKey] = col.filteredValue;
        });
        return filters;
    }

    getFilteredValueColumns(columns) {
        return flatFilter(columns || this.columns || [], (column) => typeof column.filteredValue !== "undefined");
    }

    getColumnKey(column, index) {
        return column.key || column.dataIndex || index;
    }

    getDefaultPagination(props) {
        const pagination = props.pagination || {};
        return this.hasPagination(props)
            ? {
                  ...defaultPagination,
                  ...pagination,
                  current: pagination.defaultCurrent || pagination.current || 1,
                  pageSize: pagination.defaultPageSize || pagination.pageSize || 10
              }
            : {};
    }

    hasPagination(props) {
        return (props || this.props).pagination !== false;
    }

    renderPagination = (paginationPosition) => {
        // 强制不需要分页
        if (!this.hasPagination()) {
            return null;
        }
        let size = "default";
        const pagination = this.state.pagination;
        if ("size" in pagination) {
            size = pagination.size;
        } else if (this.props.size === "middle" || this.props.size === "small") {
            size = "small";
        }
        const position = pagination.position || "bottom";
        const total = pagination.total || this.getLocalData().length;
        const { cusFooter } = this.props;
        // console.log('cusFooter', cusFooter)
        // console.log('total', total, pagination.current, Math.ceil(total / pagination.pageSize) )

        return position === paginationPosition || position === "both" ? (
            <div className="flex flex-vertical-center global_table_cusfooter">
                <div className="flex1" style={{ width: 0 }}>
                    {cusFooter}
                </div>
                <Pagination
                    key={`pagination-${paginationPosition}`}
                    {...pagination}
                    className={classNames(pagination.className, `${this.props.prefixCls}-pagination`)}
                    onChange={(page, pageSize) => {
                        // console.log('page', page)
                        this.pageChange = true;
                        this.handlePageChange(page);
                    }}
                    total={total}
                    size={size}
                    current={this.getMaxCurrent(total)}
                    onShowSizeChange={this.handleShowSizeChange}
                />
            </div>
        ) : (
            <div style={{ height: 30 }}></div>
        );
    };

    getLocalData(state, filter) {
        const currentState = state || this.state;
        const { dataSource } = this.props;
        let data = dataSource || [];
        // 优化本地排序
        data = data.slice(0);
        const sorterFn = this.getSorterFn(currentState);
        if (sorterFn) {
            data = this.recursiveSort(data, sorterFn);
        }
        // 筛选
        if (filter && currentState.filters) {
            Object.keys(currentState.filters).forEach((columnKey) => {
                const col = this.findColumn(columnKey);
                if (!col) {
                    return;
                }
                const values = currentState.filters[columnKey] || [];
                if (values.length === 0) {
                    return;
                }
                const onFilter = col.onFilter;
                data = onFilter
                    ? data.filter((record) => {
                          return values.some((v) => onFilter(v, record));
                      })
                    : data;
            });
        }
        return data;
    }

    getSorterFn(state) {
        const { sortOrder, sortColumn } = state || this.state;
        if (!sortOrder || !sortColumn || typeof sortColumn.sorter !== "function") {
            return;
        }

        return (a, b) => {
            const result = sortColumn.sorter(a, b, sortOrder);
            if (result !== 0) {
                return sortOrder === "descend" ? -result : result;
            }
            return 0;
        };
    }

    handlePageChange = (current, ...otherArguments) => {
        const props = this.props;
        const pagination = { ...this.state.pagination };
        if (current) {
            pagination.current = current;
        } else {
            pagination.current = pagination.current || 1;
        }
        pagination.onChange(pagination.current, ...otherArguments);

        const newState = {
            pagination
        };
        // Controlled current prop will not respond user interaction
        if (
            props.pagination &&
            typeof props.pagination === "object" &&
            // tslint:disable-next-line:ban-types
            "current" in props.pagination
        ) {
            newState.pagination = {
                ...pagination,
                current: this.state.pagination.current
            };
        }
        this.setState(newState);

        // this.store.setState({
        //     selectionDirty: false,
        // });

        const { onChange } = this.props;
        if (onChange) {
            const state = this.prepareParamsArguments({
                ...this.state,
                selectionDirty: false,
                pagination
            });
            // console.log('onChange', state && state[1])
            if (
                !!otherArguments &&
                otherArguments instanceof Array &&
                otherArguments.length &&
                state[1] &&
                _.isObject(otherArguments[0])
            ) {
                state[1] = otherArguments[0];
            } else {
                state[1] = null;
            }
            // console.log('onChange state', state, otherArguments, _.isObject(otherArguments[0]))
            onChange.apply(null, state);
            // onChange(state[0])
        }
    };

    prepareParamsArguments(state) {
        const pagination = { ...state.pagination };
        // remove useless handle function in Table.onChange
        delete pagination.onChange;
        delete pagination.onShowSizeChange;
        const filters = state.filters;
        const sorter = {};
        if (state.sortColumn && state.sortOrder) {
            sorter.column = state.sortColumn;
            sorter.order = state.sortOrder;
            sorter.field = state.sortColumn.dataIndex;
            sorter.columnKey = this.getColumnKey(state.sortColumn);
        }

        const extra = {
            currentDataSource: this.getLocalData(state)
        };

        return [pagination, filters, sorter, extra];
    }

    recursiveSort(data, sorterFn) {
        const { childrenColumnName = "children" } = this.props;
        return data.sort(sorterFn).map((item) =>
            item[childrenColumnName]
                ? {
                      ...item,
                      [childrenColumnName]: this.recursiveSort(item[childrenColumnName], sorterFn)
                  }
                : item
        );
    }

    findColumn(myKey) {
        let column;
        treeMap(this.columns, (c) => {
            if (this.getColumnKey(c) === myKey) {
                column = c;
            }
        });
        return column;
    }

    getMaxCurrent(total) {
        // const {
        //     pagination: { current, pageSize },
        // } = this.state;
        const pagination = this.state.pagination;
        const current = pagination.current;
        const pageSize = pagination.pageSize;
        if ((current - 1) * pageSize >= total) {
            return Math.floor((total - 1) / pageSize) + 1;
        }
        return current;
    }

    handleShowSizeChange = (current, pageSize) => {
        const pagination = this.state.pagination;
        pagination.onShowSizeChange(current, pageSize);
        const nextPagination = {
            ...pagination,
            pageSize,
            current
        };
        this.setState({ pagination: nextPagination });

        const { onChange } = this.props;
        if (onChange) {
            const state = this.prepareParamsArguments({
                ...this.state,
                pagination: nextPagination
            });
            onChange.apply(null, state);
        }
    };

    getCurrentPageData() {
        let data = this.getLocalData();
        let current;
        let pageSize;
        const state = this.state;
        // 如果没有分页的话，默认全部展示
        if (!this.hasPagination()) {
            pageSize = Number.MAX_VALUE;
            current = 1;
        } else {
            const pagination = state.pagination;
            pageSize = pagination.pageSize;
            current = this.getMaxCurrent(pagination.total || data.length);
        }

        // 分页
        // ---
        // 当数据量少于等于每页数量时，直接设置数据
        // 否则进行读取分页数据
        if (data.length > pageSize || pageSize === Number.MAX_VALUE) {
            data = data.filter((_, i) => {
                return i >= (current - 1) * pageSize && i < current * pageSize;
            });
        }
        return data;
    }

    getWidth = () => {
        // const { width } = this.props
    };

    render() {
        const {
            style,
            className,
            prefixCls,
            columns,
            scroll,
            rowSelection,
            title,
            titleStyle,
            emptyRenderer,
            bordered,
            isUseTableMaxHeight,
            onRow,
            CustomerTable,
            headerHeight,
            headerProps
        } = this.props;
        const data = this.getCurrentPageData();
        let loading = this.props.loading;
        const rowEventHandlers = {
            onClick: ({ rowData, rowIndex }) => {
                if (onRow) {
                    const handle = onRow(rowData, rowIndex);
                    if (handle.onClick) {
                        // console.log('rowClick', rowData)
                        handle.onClick();
                    }
                }
            }
            // onDoubleClick: action('double click'),
            // onContextMenu: action('context menu'),
            // onMouseEnter: action('mouse enter'),
            // onMouseLeave: action('mouse leave'),
        };

        if (typeof loading === "boolean") {
            loading = {
                spinning: loading
            };
            // loading = {
            //     spinning: true,
            // };
        }
        const paginationPatchClass =
            this.hasPagination() && data && data.length !== 0
                ? `${prefixCls}-with-pagination`
                : `${prefixCls}-without-pagination`;
        let tableClass = [];
        if (bordered) {
            tableClass.push("_isBorder");
        }

        let heightSetting = {};
        if (isUseTableMaxHeight && data && data.length) {
            // 使用最大高度配置
            heightSetting = { maxHeight: (scroll && scroll.y) || 500 };
        } else {
            heightSetting = { height: !data.length ? 85 : (scroll && scroll.y) || 500 }; // 表格高度配置
        }
        // console.log('heightSetting', heightSetting, scroll)
        // const dom = this.rootDom.current
        // console.log('columns', columns)
        return (
            <div
                ref={this.rootDom}
                className={classNames(`${prefixCls}-wrapper`, className)}
                style={{ width: "100%", ...style }}
            >
                {title && (
                    <div className="title-dom" ref={this.titleDom} style={{ padding: "5px 0", ...titleStyle }}>
                        {title && title()}
                    </div>
                )}
                <Spin
                    {...loading}
                    className={loading.spinning ? `${paginationPatchClass} ${prefixCls}-spin-holder` : ""}
                    // style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                >
                    <AutoResizer>
                        {({ width, height }) => {
                            // console.log('AutoResizer', width, height, this.rootDom, dom && dom.offsetWidth)
                            return (
                                <div style={isUseTableMaxHeight ? {} : { minHeight: scroll.y }}>
                                    <BaseTable
                                        {...heightSetting}
                                        getRowStyle={this.props.getRowStyle}
                                        getRowHeight={this.props.getRowHeight}
                                        rowKey={this.props.rowKey || "id"}
                                        columns={
                                            columns &&
                                            columns.map((item) => {
                                                let frozen;
                                                if (item.fixed) {
                                                    frozen = item.fixed;
                                                }
                                                return {
                                                    ...item,
                                                    dataKey: item.dataIndex,
                                                    key: item.key,
                                                    frozen,
                                                    width: item.width || 150,
                                                    resizable: "resizable" in item ? item.resizable : true
                                                };
                                            })
                                        }
                                        width={width || (this.rootDom && this.rootDom.offsetWidth)}
                                        // height={100}
                                        rowProps={this.props.rowProps}
                                        fixed={true}
                                        data={data}
                                        useIsScrolling
                                        style={{ boxShadow: "none", marginBottom: 1 }}
                                        className={tableClass.join(" ")}
                                        loading={loading}
                                        headerHeight={headerHeight || 36}
                                        rowHeight={36}
                                        selectable={!!rowSelection}
                                        footerHeight={0}
                                        onColumnResizeEnd={this.props.onColumnResizeEnd}
                                        // getScrollbarSize={getScrollbarSize}
                                        {...getScrollbarSize}
                                        headerRenderer={(params) => headerRenderer(params, this.handlePageChange)}
                                        emptyRenderer={() => {
                                            return emptyRenderer();
                                        }}
                                        // rowClassName={({rowData,rowIndex,columns})=>{
                                        //     if(this.props.rowClassName){
                                        //         this.props.rowClassName(rowData,rowIndex,columns)
                                        //     }
                                        // }}
                                        rowEventHandlers={rowEventHandlers}
                                        getThis={(v) => (this.tableContent = v)}
                                        CustomerTable={CustomerTable}
                                        headerProps={headerProps}
                                    />
                                </div>
                            );
                        }}
                    </AutoResizer>
                </Spin>
                {/* <Spin
                    {...loading}
                    className={loading.spinning ? `${paginationPatchClass} ${prefixCls}-spin-holder` : ''}
                    style={{ width: '100%', overflow: 'hidden' }}
                >
                    {
                        // loading.spinning && <div style={{ heightSetting, overflow: 'hidden'}}></div>
                    }
                </Spin> */}
                {
                    // loading.spinning &&
                    // <div style={{ ...heightSetting, width: '100%', overflow: 'hidden', position: 'relative' }}>
                    //     <Spin
                    //         {...loading}
                    //         className={loading.spinning ? `${paginationPatchClass} ${prefixCls}-spin-holder` : ''}
                    //         // style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                    //     >
                    //         {
                    //             // loading.spinning && <div style={{ heightSetting, overflow: 'hidden'}}></div>
                    //         }
                    //         <div style={{ ...heightSetting, overflow: 'hidden' }}></div>
                    //     </Spin>
                    // </div>
                }
                <div className="pagination">{this.renderPagination("bottom")}</div>
            </div>
        );
    }
}

export default ITable;
