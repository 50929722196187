import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class ReceivableApiClass {
    // 获取应收对账列表
    getReceivableSettlement(params) {
        const url = `finance/receivableSettlement/page`;
        return this.POST(url, deleteNull(params));
    }
    // 生成对账单
    postGenerateBill(params) {
        const url = `finance/receivableReconciliation/generate`;
        return this.POST(url, params);
    }
    // 获取应收对账单列表
    getAccountReceivableList(params) {
        return this.POST(`finance/receivableReconciliation/page`, deleteNull(params));
    }
    // 获取预计生成对账数据
    getPredictGenerateData(params) {
        return this.POST(`finance/receivableReconciliation/predictGenerateData`, deleteNull(params));
    }
    // 根据id获取对账单的发货单信息
    getReconciliationFromId(params) {
        const url = `finance/receivableReconciliation/adjustmentSettlementList/${params.id}`;
        return this.POST(url, params);
    }
    // 调整对账单
    postAdjustmentGenerate(params) {
        const url = `finance/receivableReconciliation/adjustmentGenerate`;
        return this.POST(url, params);
    }
    // 确认对账单
    postConfirmReconciliation(params) {
        const url = `finance/receivableReconciliation/confirm`;
        return this.POST(url, params);
    }
    // 删除对账单
    delReconciliation(params) {
        const url = `finance/receivableReconciliation/delete`;
        return this._DELETE(url, params);
    }
    // 撤销对账单
    postCancelReconciliation(params) {
        const url = `finance/receivableReconciliation/cancelConfirm/${params.id}`;
        return this.POST(url, params);
    }
    // 修改对账单
    updateReconciliationBill(params) {
        const url = `finance/receivableReconciliation/update/${params.flag}/${params.id}`;
        return this.POST(url, params.body);
    }
    // 导出应收对账单
    exportReconciliationBill(params) {
        const url = `finance/receivableReconciliation/exportReconciliationMain`;
        return this.GETFILE(url, params);
    }
    // 导出应收invoice单
    exportInvoice(params) {
        const url = `finance/receivableReconciliation/exportInvoice/${params.id}`;
        return this.GETFILE(url, params);
    }
}
