import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class InventoryApi {
    /**
     * 获取库存管理数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf InventoryApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    [children.EXPORT_DATA.apiName](params) {
        let url = children.EXPORT_DATA.url;
        return this.POSTFILE(url, params);
    }

    [children.CHANGE_STATUS.apiName](params) {
        // 状态改变
        let url = children.CHANGE_STATUS.url;
        return this.POST(url, params);
    }

    [children.ON_FREEZE.apiName](params) {
        // 冻结
        let url = children.ON_FREEZE.url;
        return this.POST(url, params);
    }

    [children.ALLOT.apiName](params) {
        // 调拨
        let url = children.ALLOT.url;
        return this.POST(url, params);
    }

    [children.MOVE_LOCATION.apiName](params) {
        // 移位
        let url = children.MOVE_LOCATION.url;
        return this.POST(url, params);
    }

    [children.DISMANT_PLATE.apiName](params) {
        // 拆板
        let url = children.DISMANT_PLATE.url;
        return this.POST(url, params);
    }

    [children.MERGE_PLATE.apiName](params) {
        // 合板
        let url = children.MERGE_PLATE.url;
        return this.POST(url, params);
    }

    [children.SEE_LOGS.apiName](params) {
        // 查看日志
        let url = children.SEE_LOGS.url + `/${params.id}`;
        return this.GET(url, params);
    }

    inventoryManageGetClientNames(params) {
        // 获取客户名称列表
        let url = `wms/inventoryManage/getClientNames`;
        return this.GET(url, params);
    }

    inventoryManageGetMaterialsName(params) {
        // 获取物料名称列表
        let url = `wms/inventoryManage/getMaterialsName`;
        return this.GET(url, params);
    }

    inventoryManageGetMaterialsNumber(params) {
        // 获取物料号列表
        let url = `wms/inventoryManage/getMaterialsNumber`;
        return this.GET(url, params);
    }

    inventoryManageGetWarehouseNames(params) {
        // 获取仓库名称列表
        let url = `wms/inventoryManage/getWarehouseNames`;
        return this.GET(url, params);
    }

    inventoryManageGetWarehouseStorageNumber(params) {
        // 获取库位列表
        let url = `wms/inventoryManage/getWarehouseStorageNumber`;
        return this.GET(url, params);
    }

    getWarehouseStorageNumberByWarehouseId(params) {
        // 根据仓库id获取库位
        let url = `wms/inventoryManage/getWarehouseStorageNumberById/${params.warehouseId}`;
        return this.GET(url, params);
    }

    getInventoryManageScanList(params) {
        // 获取序列号列表
        let url = `wms/inventoryManage/scanList/${params.id}`;
        return this.GET(url, params);
    }

    getInventoryMangeLogs(params) {
        // 获取日志
        let url = `wms/inventoryManage/getLogs/${params.id}`;
        return this.GET(url, params);
    }
}
