import React, { Component } from "react";
import { Menu, Popover, Button, Spin, Icon, Input } from "antd";
import { allbooktypes } from "../../store/wordbooknav";
import { isArray, dedupe } from "@src/utils";
import TagButton from "./tag";
import "./index.less";
import PropTypes from "prop-types";
import rApi from "@src/http";
import { v4 as uuidv4 } from "uuid";

export default class MultiSelect extends Component {
    /**
     * 父组件传值接口list、text、getDataMethod、params
     * defaultValue 数组[{id:'', title: ''}]
     *
     * @memberof MultiSelect
     */
    static propTypes = {
        list: PropTypes.array, //选择值[{id: '', title: ''}]
        text: PropTypes.string, //数据字典内的导航值eg: 计量单位
        getDataMethod: PropTypes.string, //请求方法名
        getDataPromise: PropTypes.function, //请求方法名
        params: PropTypes.object, //请求参数
        defaultValue: PropTypes.array, //默认值
        labelField: PropTypes.string, //默认值字段名
        dataKey: PropTypes.string,
        openType: PropTypes.number, //值为2时为参看状态无法编辑
        style: PropTypes.object,
        componentType: PropTypes.number // 类型 1. 数据字典选择 2. input输入
    };

    constructor(props) {
        super(props);
        this.state = {
            type: null,
            selectList: [],
            tagList: [],
            selectedKeys: [],
            labelField: props.labelField || "title",
            dataKey: props.dataKey || "records",
            loading: false,
            style: {},
            inputValue: ""
        };
        if (props.text) {
            this.state.type = 1;
        } else if (props.getDataMethod) {
            this.state.type = 3;
        } else if (props.getDataPromise) {
            this.state.type = 4;
        } else if (props.list !== "" || props.list !== null) {
            this.state.type = 2;
        }

        // console.log("MultiSelect", props);
        if ("value" in props || props.defaultValue) {
            const _dValue = props.value ? props.value : props.defaultValue ? props.defaultValue : [];
            const { labelField } = this.state;

            this.state.tagList = [..._dValue.filter((item) => item && item[labelField])];
            this.state.selectedKeys = this.state.tagList.map((item) => {
                return item[labelField].toString(); // selectedKeys为string eg: '43','44'
            });
            // console.log("MultiSelect", this.state.selectedKeys);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ("value" in nextProps) {
            const { labelField } = this.state;
            const tagList = [...nextProps.value.filter((item) => item && item[labelField])];
            const selectedKeys = [...nextProps.value.filter((item) => item && item[labelField])].map((item) => {
                return item[labelField].toString(); // selectedKeys为string eg: '43','44'
            });

            this.setState({ tagList, selectedKeys });
        }
    }

    // componentDidMount() {
    //     this.setState({selectList: this.props.sectList});
    // }
    handleButtonSelect = (e) => {
        const { componentType } = this.props;
        if (componentType === 2) {
            const { inputValue } = this.state;
            this.state.tagList.push({ id: uuidv4(), title: inputValue });
        } else {
            this.setState({ selectedKeys: e.selectedKeys });
            this.state.tagList.push(e.item.props.value);
        }
        // console.log('selectedKeys', e.selectedKeys)
        if (this.props.handleChangeSelect) {
            this.props.handleChangeSelect(this.state.tagList);
        }
    };
    handleButtonDeSelect = (e) => {
        let delValue = this.state.tagList;
        const { labelField } = this.state;
        let index = delValue.findIndex((item) => item[labelField] === e.key || item[labelField].toString() === e.key);
        delValue.splice(index, 1);
        this.setState({ selectedKeys: e.selectedKeys, tagList: delValue });
        if (this.props.handleChangeSelect) {
            this.props.handleChangeSelect(this.state.tagList);
        }
    };
    handleShow = (itemTitle) => {
        const { labelField } = this.state;
        let deValue = this.state.tagList;
        let selKeys = this.state.selectedKeys;
        deValue.splice(
            deValue.findIndex((item) => item[labelField] === itemTitle),
            1
        );
        selKeys.splice(
            selKeys.findIndex((item) => item[labelField] === itemTitle),
            1
        );
        this.setState({ tagList: deValue, selectedKeys: selKeys });
        if (this.props.handleChangeSelect) {
            this.props.handleChangeSelect(this.state.tagList);
        }
    };
    getBookType = (text) => {
        let items = allbooktypes.filter((item) => {
            if (item["text"] === text) {
                return true;
            }
            return false;
        });
        return items && items[0] ? items[0] : null;
    };

    onFocus = () => {
        let {
            text,
            list,
            getDataMethod,
            getDataPromise,
            params,
            labelField,
            dataKey,
            isTransportQuotation,
            componentType
        } = this.props;
        let { data, loading, type } = this.state;

        if (componentType === 2) {
        } else {
            this.setState({
                loading: true
            });
            if (type === 1) {
                return new Promise((resolve, reject) => {
                    let typeId = this.getBookType(text);
                    rApi["getDicByText"](text).then((data) => {
                        this.setState({
                            selectList: data,
                            loading: false
                        });
                    });
                });
            } else if (type === 2) {
                return new Promise((resolve, reject) => {
                    this.setState({
                        selectList: list,
                        loading: false
                    });
                    resolve(true);
                });
            } else if (type === 3 && isTransportQuotation) {
                //报价显示报价单号及运输方式
                return rApi[getDataMethod](params)
                    .then((d) => {
                        let list = [];
                        if (isArray(d)) {
                            list = d;
                        } else if (d && isArray(d.list)) {
                            list = d.list;
                        } else if (d && d.clients) {
                            list = d.clients;
                        } else if (d && d.records) {
                            list = d.records;
                        } else {
                            list = [];
                        }
                        let dataMap = list.map((item) => {
                            let tm =
                                (item.transportModeBusinessModes && item.transportModeBusinessModes.length) > 0
                                    ? item.transportModeBusinessModes.map((d) => {
                                          return d.transportModeName;
                                      })
                                    : null;
                            return {
                                ...item,
                                transportModes: dedupe(tm).join(" ")
                            };
                        });
                        this.setState({
                            selectList:
                                dataMap && dataMap.length > 0
                                    ? dataMap.map((item) => {
                                          let obj = {
                                              id: item.id,
                                              title: `${item.quotationNumber}${
                                                  item.transportModes ? ` ${item.transportModes}` : ""
                                              }`,
                                              quotationNumber: `${item.quotationNumber}${
                                                  item.transportModes ? ` ${item.transportModes}` : ""
                                              }`
                                          };
                                          return obj;
                                      })
                                    : [],
                            loading: false
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            loading: false
                        });
                    });
            } else if (type === 3) {
                return rApi[getDataMethod](params)
                    .then((d) => {
                        let list = [];
                        if (isArray(d)) {
                            list = d;
                        } else if (d && isArray(d.list)) {
                            list = d.list;
                        } else if (d && d.clients) {
                            list = d.clients;
                        } else if (d && d.records) {
                            list = d.records;
                        } else {
                            list = [];
                        }
                        this.setState({
                            selectList:
                                list && list.length > 0
                                    ? list.map((item) => {
                                          let obj = {
                                              id: item.id,
                                              title: item[labelField],
                                              [labelField]: item[labelField]
                                          };
                                          return obj;
                                      })
                                    : [],
                            loading: false
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            loading: false
                        });
                    });
            } else if (type === 4) {
                return getDataPromise()
                    .then((list) => {
                        this.setState({ selectList: list, loading: false });
                    })
                    .catch((e) => {
                        this.setState({
                            loading: false
                        });
                    });
            }
        }
    };

    onInputChange = ({ target: { value } }) => {
        this.setState({
            inputValue: value
        });
    };

    onInputConfirm = () => {
        this.handleButtonSelect();
        this.setState({ inputValue: "" });
    };

    onInputKeyup = (e) => {
        if (e.keyCode === 13) {
            this.onInputConfirm();
        }
    };

    // onChange = () => {
    //     this.props.handleChangeSelect(this.state.selectedKeys)
    //     console.log('this.props.handleChangeSelect')
    // }
    render() {
        let { selectList, loading, inputValue, labelField } = this.state;
        let { getPopupContainer, openType, style, componentType, disabled } = this.props;
        let getTContainer = {};
        if (getPopupContainer) {
            getTContainer.getPopupContainer = getPopupContainer;
        } else {
            getTContainer.getPopupContainer = () => document.querySelector("#scroll-view");
        }
        const menu = (
            <Spin spinning={loading} size="small">
                <Menu
                    selectedKeys={this.state.selectedKeys}
                    multiple={true}
                    onSelect={this.handleButtonSelect}
                    onDeselect={this.handleButtonDeSelect}
                >
                    {selectList && selectList.length > 0 ? (
                        selectList.map((item, index) => (
                            <Menu.Item value={item} key={item[labelField] || index}>
                                <a>{item[labelField]}</a>
                            </Menu.Item>
                        ))
                    ) : (
                        <Menu.Item disabled={true}>
                            <a>暂无数据</a>
                        </Menu.Item>
                    )}
                </Menu>
            </Spin>
        );
        const input = (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 5 }}>
                <Input value={inputValue} onChange={this.onInputChange} onKeyUp={this.onInputKeyup}></Input>
                <Button style={{ marginLeft: 10 }} onClick={this.onInputConfirm}>
                    确定
                </Button>
                {/* <Icon type="check-circle" style={{ fontSize: 16, marginLeft: 10 }} onClick={this.onInputConfirm} /> */}
            </div>
        );
        return (
            <div className="flex flex-vertical-center multi-select-box" style={{ ...style, minHeight: 24 }}>
                {this.state.tagList.map((item, index) => (
                    <TagButton
                        key={item.id}
                        itemTitle={item[labelField]}
                        itemId={item.id}
                        handleShow={this.handleShow}
                        openType={openType}
                        disabled={disabled}
                    ></TagButton>
                ))}
                {openType === 2 || disabled ? null : (
                    <Popover
                        {...getTContainer}
                        overlayClassName="multi-select-popover"
                        placement="right"
                        content={componentType === 2 ? input : menu}
                        trigger="click"
                        onClick={this.onFocus}
                    >
                        <Icon style={{ cursor: "pointer", fontSize: "15px", marginTop: -1 }} type="plus-circle" />
                    </Popover>
                )}
            </div>
        );
    }
}

// export default SourceDemo;
