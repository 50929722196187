import { children, id } from "./power";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class WarehouseManegementReceiptManageApi {
    /**
     * 获取收货管理数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf WarehouseManegementReceiptDemandApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    [children.BATCH_DEL.apiName](params) {
        let url = children.BATCH_DEL.url;
        return this.POST(url, params);
    }

    // [children.GET_DETAILS_DATA.apiName](params) {
    //     let url = children.GET_DETAILS_DATA.url
    //     return this.POST(url, params)
    // }

    // [children.DETAILS_SYSTEM_RECEIPT.apiName](params) {
    //     let url = children.DETAILS_SYSTEM_RECEIPT.url + `/${params.id}`
    //     return this.GET(url, params)
    // }

    // [children.BATCH_IN.apiName](params) {
    //     let url = children.BATCH_IN.url
    //     return this.POST(url, params)
    // }

    // [children.DETAILS_BOARD_BATCH.apiName](params) {
    //     let url = children.DETAILS_BOARD_BATCH.url + `/${params.receiptManageId}`
    //     return this.POST(url, params)
    // }

    // [children.DEL_BOARD_BATCH.apiName](params) {
    //     let url = children.DEL_BOARD_BATCH.url
    //     return this.POST(url, params)
    // }

    // [children.COMPLETE_RECEIPT.apiName](params) {
    //     let url = children.COMPLETE_RECEIPT.url + `/${params.id}`
    //     return this.GET(url, params)
    // }

    // [children.COMPLETE_UPPERSHELF.apiName](params) {
    //     let url = children.COMPLETE_UPPERSHELF.url + `/${params.id}`
    //     return this.GET(url, params)
    // }

    // [children.SCAN_DEL.apiName](params) {
    //     let url = children.SCAN_DEL.url
    //     return this.POST(url, params)
    // }

    // [children.DETAILS_EXPORT.apiName](params) {
    //     let url = children.DETAILS_EXPORT.url + `/${params.id}`
    //     return this.GETFILE(url)
    // }

    // [children.EXPORT_PDF.apiName](params) {
    //     let url = children.EXPORT_PDF.url + `/${params.id}`
    //     return this.GETFILE(url)
    // }

    // getReceiptMaterial(params) { //获取收货明细物料
    //     let url = `wms/receiptList/material/${params.id}`
    //     return this.GET(url, params)
    // }

    // addReceiptDetailsMaterial(params) { //收货明细新建保存物料
    //     let url = `wms/receiptList/add`
    //     return this.POST(url, params)
    // }

    // editReceiptDetailsMaterial(params) { //收货明细编辑保存物料
    //     let url = `wms/receiptList/edit`
    //     return this.POST(url, params)
    // }

    // getReceiptScanList(params) { //收货扫描列表
    //     let url = `wms/receiptScanList/list`
    //     return this.POST(url, params)
    // }

    // importReceiptScanList(params) { //收货扫描导入
    //     let url = `wms/receiptScanList/batchAdd`
    //     return this.POST(url, params)
    // }

    // editReceiptScanData(params) { //收货扫描编辑保存
    //     let url = `wms/receiptScanList/edit`
    //     return this.POST(url, params)
    // }

    // // delReceiptScanData(params) { //收货扫描删除
    // //     let url = `wms/receiptScanList/delete`
    // //     return this.POST(url, params)
    // // }

    // exportReceiptScanData(params) { //收货扫描导出
    //     let url = `wms/receiptScanList/export/${params.id}`
    //     return this.GETFILE(url, params)
    // }

    // receiptManageUpload(params) { //收货管理附件上传
    //     let url = `wms/receiptManage/uploadAttachment`
    //     return this.POST(url, params)
    // }
}
