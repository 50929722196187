import React, { useRef, useState, useEffect, Ref, RefObject } from "react";
import { isFullscreen, getMidPosition, getAnalogLngLat, bearing, formatXlsxDate } from "@src/utils";
import rApi from "@src/http";
import Map from "@src/components/bmap/map";
import Popup from "@src/components/bmap/popup";
import start from "@src/libs/img/icons/map_start.svg";
import arrow from "@src/libs/img/icons/map_arrow.svg";
import tihuo from "@src/libs/img/icons/map_tihuo.png";
import xiehuo from "@src/libs/img/icons/map_xiehuo.png";
import mudi from "@src/libs/img/icons/map_end.svg";
import mark from "@src/libs/img/map_mark.svg";
import zhongzhuan from "@src/libs/img/icons/map_middle.svg";
import { message } from "antd";
import _ from "@src/utils/lodash";
import { exportReqToExcel } from "@src/utils/logic";
import { ImportExcel } from "@src/components/upload_excel";
import AwesomeCard from "@src/views/transport_management/stowage_center/map/awesome_card";
import "./index.less";

const icons = {
    start,
    tihuo,
    xiehuo,
    mudi,
    zhongzhuan,
    mark,
    arrow
};
const importKeyDic = {
    序号: "position",
    地址信息: "address",
    经度: "longitude",
    纬度: "latitude",
    到达时间: "arriveTime",
    离开时间: "leaveTime"
};
interface IProps {
    id: string | number;
    height: string | number;

    hideFooter?: boolean; // 隐藏底部统计数据
    hideOrderPath?: boolean; // 隐藏运单详情路线
    awesomeCardTitle?: string; // 右侧显示栏的标题
    calcHeight?: number; // 地图需要计算的高度
}

type latLng = { latitude: number; longitude: number; transit: boolean; receiveOrSendName: string; address: string };
interface DetailData {
    id: number | string;
    departure: latLng; // 出发地
    destination: latLng; // 目的地 若 transit 为 true 则为中转地
    resourceVehicleName: string; // 车牌号
    specificationModel: string; // 车辆规格
    stowageNumber: string; // 配载单号
    totalQuantity: number; // 总件数
    totalGrossWeight: number; // 总重量 重量
    totalNetWeight: number; // 总净重
    totalVolume: number;
    transportModeName: string; // 运送方式名称
    status: 1 | 2 | 3 | 4; // 待确认 | 待发运 | 运输中 | 已签收
}

interface PointItem {
    lat: number;
    lng: number;
    type: "dep" | "des"; // 发 | 收
    iconKey: string;
    isOrderPoint: boolean;
    name: string;
    address: string;
    transit: boolean;
    grossWeight: { string: number };
    quantity: { string: number };
    volume: { string: number };
    totalGrossWeight: number;
    totalQuantity: number;
    totalVolume: number;
}

export interface routeConfig {
    address: string;
    arriveTime: number;
    createTime: number;
    id: number;
    latitude: number;
    leaveTime: number;
    longitude: number;
    nodeType: number;
    orderId: number;
    position: number;
    stowageId: number;
    transportInfo?: Record<string, string | number>;
}
interface Lines {
    id: number | string;
    status: number;
    stowageNumber: string;
    dep: latLng;
    deps: Lines[]; // 其他相同经纬度的 出发地
    des: latLng;
    dess: Lines[]; // 其他相同经纬度的 收货地 / 中转地
}

interface MapContext {
    points: any; // 格式化的 标点 数据
    lines: any; // 线
    markers: any; // 根据标点创建的 Marker 对象[]
    polylines: any; // 根据线创建的 Polyline 对象[]
    BMapPoints: any; // 百度地图 point 对象[]
    focusPoints: any; // 当前聚焦的百度地图 point 对象[]
    type: "mapContext";
}

interface MapContextStowage {
    points: any; // 格式化的 标点 数据
    markers: any; // 根据标点创建的 Marker 对象[]
    lines: any; // 线 运单本身不需要当前字段 为了统一处理悬浮窗 跟 发货单保持一致
    polylines: any; // 根据线创建的 Polyline 对象[]
    BMapPoints: any; // 百度地图 point 对象[]
    analog: {
        // 虚拟路线 箭头
        prevLine: any;
        marker: any;
        nextLine: any;
    };
    type: "mapContextStowage";
}

const BMapGL = window["BMapGL"];

// 获取货物信息详情
function getFooterColumn(totalQuantity, totalGrossWeight, totalVolume) {
    return [
        {
            key: "totalQuantity",
            title: "件数",
            value: totalQuantity
        },
        {
            key: "totalGrossWeight",
            title: "重量",
            value: totalGrossWeight
        },
        {
            key: "totalVolume",
            title: "体积",
            value: totalVolume
        }
    ];
}

function getColumns(curPoint: PointItem) {
    const { name, type, transit, address } = curPoint;
    return [
        {
            key: "name",
            title: type === "dep" ? (transit ? "中转地名称" : "发货人") : transit ? "中转地名称" : "收货人",
            value: name
        },
        {
            key: "address",
            title: type === "dep" ? (transit ? "中转地址" : "发货地址") : transit ? "中转地址" : "收货地址",
            value: getAddressString(address)
        }
    ];
}

const getAddressString = (address) => {
    // console.log('getAddressString', address)
    if (address && address.formatAddress) {
        return address.formatAddress;
    }
    try {
        return JSON.parse(address).formatAddress;
    } catch (e) {
        return "";
    }
};

const calcHeight = 152;

export default function Index(props: IProps) {
    const ctx = useRef(null);
    const timeout = useRef(null);
    const popupInstance = useRef(null);
    // 发货单的相关保存数据
    const mapContext = useRef<MapContext>({
        points: {},
        lines: [],
        markers: [],
        polylines: [],
        BMapPoints: [],
        type: "mapContext",
        focusPoints: null
    });
    // 运单的相关保存数据
    const mapContextStowage = useRef<MapContextStowage>({
        points: {},
        markers: [],
        lines: [],
        polylines: [],
        BMapPoints: [],
        analog: {
            prevLine: null,
            marker: null,
            nextLine: null
        },
        type: "mapContextStowage"
    });
    const [pageInfo, setPageInfo] = useState({
        detailVisible: true,
        pathVisible: true,
        cardActiveIdx: null
    });
    // 使用 ref 使 marker 的监听事件内也能获取到值
    const cardActiveIdxRef = useRef(null);
    const awesome_cardRef = useRef(null);

    const [infos, setInfos] = useState<{ detailData: DetailData[]; orderDetail: Record<string, any> }>({
        detailData: null,
        orderDetail: null
    });
    const [popupInfo, setPopupInfo] = useState({ columns: [], footColumns: [] });

    const { id } = props;

    function onGetMapCtx({ mapCtx, curveLine }, mapDom) {
        ctx.current = { mapCtx, curveLine, mapDom };
        getMapTrace(id);
    }

    // 发货单追踪数据
    function getMapTrace(id) {
        rApi["getStowageMap"]({ id })
            .then((data) => {
                setInfos({ detailData: data.stowageMapTraces, orderDetail: data.order });
                formatData(data.stowageMapTraces, data.order);
            })
            .catch((err) => {
                console.error(err);
                setInfos({ detailData: [], orderDetail: [] });
            });
    }

    // 格式化数据
    function formatData(stowage: DetailData[], order) {
        const { mapCtx } = ctx.current;

        const pointObj: {
            [key: string]: PointItem;
        } = {};

        stowage.forEach((item) => {
            const { departure, destination, id, totalGrossWeight, totalQuantity, totalVolume, stowageNumber } = item;
            const {
                latitude: dep_lat,
                longitude: dep_lng,
                transit: dep_transit,
                receiveOrSendName: dep_name,
                address: dep_address
            } = departure;
            const {
                latitude: des_lat,
                longitude: des_lng,
                transit: des_transit,
                receiveOrSendName: des_name,
                address: des_address
            } = destination;
            // 发
            handlePointItem(pointObj, {
                lat: dep_lat,
                lng: dep_lng,
                type: "dep",
                iconKey: dep_transit ? "zhongzhuan" : "tihuo",
                isOrderPoint: false,
                name: dep_name,
                address: dep_address,
                transit: dep_transit,
                grossWeight: { [stowageNumber]: totalGrossWeight },
                quantity: { [stowageNumber]: totalQuantity },
                volume: { [stowageNumber]: totalVolume },
                totalGrossWeight,
                totalQuantity,
                totalVolume
            });
            // 收
            handlePointItem(pointObj, {
                lat: des_lat,
                lng: des_lng,
                type: "des",
                iconKey: des_transit ? "zhongzhuan" : "mudi",
                isOrderPoint: false,
                name: des_name,
                address: des_address,
                transit: des_transit,
                grossWeight: { [stowageNumber]: totalGrossWeight },
                quantity: { [stowageNumber]: totalQuantity },
                volume: { [stowageNumber]: totalVolume },
                totalGrossWeight,
                totalQuantity,
                totalVolume
            });
        });

        order.receiverSenderList.forEach((item) => {
            const {
                id,
                quantity: totalQuantity,
                receiverAddress: des_address,
                receiverLatitude: des_lat,
                receiverLongitude: des_lng,
                receiverName: des_name,
                senderAddress: dep_address,
                senderLatitude: dep_lat,
                senderLongitude: dep_lng,
                senderName: dep_name,
                volume: totalVolume,
                weight: totalGrossWeight,
                transitPlaceOneName
            } = item;
            // 发
            handlePointItem(pointObj, {
                lat: dep_lat,
                lng: dep_lng,
                type: "dep",
                iconKey: "tihuo",
                isOrderPoint: true,
                name: dep_name,
                address: dep_address,
                transit: false,
                grossWeight: { orderId: totalGrossWeight },
                quantity: { orderId: totalQuantity },
                volume: { orderId: totalVolume },
                totalGrossWeight,
                totalQuantity,
                totalVolume
            });
            // 收
            handlePointItem(pointObj, {
                lat: des_lat,
                lng: des_lng,
                type: "des",
                iconKey: transitPlaceOneName ? "zhongzhuan" : "mudi",
                isOrderPoint: true,
                name: des_name,
                address: des_address,
                transit: transitPlaceOneName ? true : false,
                grossWeight: { orderId: totalGrossWeight },
                quantity: { orderId: totalQuantity },
                volume: { orderId: totalVolume },
                totalGrossWeight,
                totalQuantity,
                totalVolume
            });
        });

        const lines = handleLine(stowage);
        drawMarker(pointObj, mapContext);
        drawLines(lines);

        mapContext.current.points = pointObj;
        mapContext.current.lines = lines;

        mapCtx.setViewport(mapContext.current.BMapPoints, { margin: [20, 50, 20, 20] });
    }

    // 获取弹窗实例
    function onGetPopupInstance(ctx) {
        popupInstance.current = ctx;
        addPopup(mapContext);
    }

    // 悬浮窗
    // curContext 为 mapContext | mapStowageContext
    function addPopup(curContext: RefObject<MapContext> | RefObject<MapContextStowage>, filter = null) {
        const instance = popupInstance.current;
        let { markers, points, lines, polylines, type } = curContext.current;

        if (filter) {
            markers = filter(markers);
        }

        markers.forEach((marker, idx) => {
            const { __latLng } = marker;
            const cur: PointItem = points[__latLng];
            if (cur) {
                const { totalGrossWeight, totalQuantity, totalVolume, grossWeight, quantity, volume } = cur;

                marker.__onMouseOver = (e) => {
                    let curLines;
                    if (type === "mapContext") {
                        curLines = lines[cardActiveIdxRef.current] || {};
                    } else {
                        curLines = lines[0];
                    }
                    const curPolyLines = polylines.find((el) => el.__stowageNumber === curLines.stowageNumber);
                    const columns = getColumns(cur);
                    let footColumns;
                    if (curLines && curPolyLines?.__latLng?.includes(__latLng)) {
                        footColumns = getFooterColumn(
                            quantity[curLines.stowageNumber],
                            grossWeight[curLines.stowageNumber],
                            volume[curLines.stowageNumber]
                        );
                    } else {
                        footColumns = getFooterColumn(totalQuantity, totalGrossWeight, totalVolume);
                    }
                    clearTimeout(timeout.current);
                    const {
                        pixel: { x, y }
                    } = e;
                    instance.setOffset(new BMapGL.Size(x, y));
                    setPopupInfo({ columns, footColumns });
                };
                marker.addEventListener("mouseover", marker.__onMouseOver);

                marker.__onMouseOut = () => {
                    timeout.current = setTimeout(() => {
                        setPopupInfo({ columns: [], footColumns: [] });
                    }, 200);
                };
                marker.addEventListener("mouseout", marker.__onMouseOut);
            }
        });
    }

    function removePopupListener(curContext) {
        const { markers } = curContext.current;
        markers.forEach((marker) => {
            marker.removeEventListener("mouseover", marker.__onMouseOver);
            marker.removeEventListener("mouseout", marker.__onMouseOut);
        });
    }

    // 悬浮窗的 mouseOver mouseOut 事件
    function onPopupMouseOver() {
        clearTimeout(timeout.current);
    }
    function onPopupMouseOut() {
        timeout.current = setTimeout(() => {
            setPopupInfo({ columns: [], footColumns: [] });
        }, 200);
    }
    function onPopupClick() {
        const { detailVisible } = pageInfo;
        if (!detailVisible) {
            setPageInfo((prev) => ({ ...prev, detailVisible: true }));
        }
    }

    // 绘制 Marker
    function drawMarker(pointObj, curContext) {
        const { mapCtx } = ctx.current;
        const markers = [];
        const BMapPoints = [];
        Object.keys(pointObj).forEach((key) => {
            const cur: PointItem = pointObj[key];
            if (cur.lng && cur.lat) {
                const offset = cur.iconKey === "mark" ? new BMapGL.Size(0, -13) : new BMapGL.Size(-1.5, 0);
                const iconPos = cur.iconKey === "mark" ? new BMapGL.Size(16, 30) : new BMapGL.Size(26, 26);

                BMapPoints.push(new BMapGL.Point(cur.lng, cur.lat));

                const icon = new BMapGL.Icon(icons[cur.iconKey], iconPos);
                const mapPoint = new BMapGL.Point(cur.lng, cur.lat);
                const marker = new BMapGL.Marker(mapPoint, {
                    icon,
                    enableMassClear: true,
                    offset,
                    title: cur.name
                });
                marker.__latLng = key;
                mapCtx.addOverlay(marker);
                markers.push(marker);
            }
        });
        curContext.current.markers = markers;
        curContext.current.BMapPoints = BMapPoints;
    }

    // 绘制 Polyline
    function drawLines(lines: Lines[]) {
        const { mapCtx, curveLine } = ctx.current;
        const polylines = [];
        lines.forEach((item) => {
            const { dep, des, deps, dess } = item;
            if (deps.length) {
                const _dess = [des, ...deps.map((el) => el.des)];
                for (let i = 0; i < _dess.length; i++) {
                    if (i === _dess.length - 1) continue;
                    const polyline = new curveLine(
                        [
                            new BMapGL.Point(_dess[i].longitude, _dess[i].latitude),
                            new BMapGL.Point(_dess[i + 1].longitude, _dess[i + 1].latitude)
                        ],
                        {
                            strokeColor: item.status === 4 ? "#38a56a" : "#2196F3",
                            strokeWeight: 2,
                            strokeOpacity: 0.9,
                            strokeStyle: item.status === 1 ? "dashed" : "solid"
                        }
                    );
                    polyline.__stowageNumber = item.stowageNumber;
                    polyline.__latLng = [
                        `-${_dess[i].latitude}-${_dess[i].longitude}`,
                        `-${_dess[i + 1].latitude}-${_dess[i + 1].longitude}`
                    ];
                    polylines.push(polyline);
                }
            }

            if (dess.length) {
                const _deps = [dep, ...dess.map((el) => el.dep)];
                for (let i = 0; i < _deps.length; i++) {
                    if (i === _deps.length - 1) continue;
                    const polyline = new curveLine(
                        [
                            new BMapGL.Point(_deps[i].longitude, _deps[i].latitude),
                            new BMapGL.Point(_deps[i + 1].longitude, _deps[i + 1].latitude)
                        ],
                        {
                            strokeColor: item.status === 4 ? "#38a56a" : "#2196F3",
                            strokeWeight: 2,
                            strokeOpacity: 0.9,
                            strokeStyle: item.status === 1 ? "dashed" : "solid"
                        }
                    );
                    polyline.__stowageNumber = item.stowageNumber;
                    polyline.__latLng = [
                        `-${_deps[i].latitude}-${_deps[i].longitude}`,
                        `-${_deps[i + 1].latitude}-${_deps[i + 1].longitude}`
                    ];
                    polylines.push(polyline);
                }
            }

            const polyline = new curveLine(
                [new BMapGL.Point(dep.longitude, dep.latitude), new BMapGL.Point(des.longitude, des.latitude)],
                {
                    strokeColor: item.status === 4 ? "#38a56a" : "#2196F3",
                    strokeWeight: 2,
                    strokeOpacity: 0.9,
                    strokeStyle: item.status === 1 ? "dashed" : "solid"
                }
            );
            polyline.__stowageNumber = item.stowageNumber;
            polyline.__latLng = [`-${dep.latitude}-${dep.longitude}`, `-${des.latitude}-${des.longitude}`];
            polylines.push(polyline);
        });
        polylines.forEach((item) => mapCtx.addOverlay(item));
        mapContext.current.polylines = polylines;
    }

    // 处理 Line 的数据 DetailData
    function handleLine(stowage: any[]) {
        let temp;
        let lines: Lines[] = stowage.map((item) => ({
            id: item.id,
            status: item.status,
            stowageNumber: item.stowageNumber,
            dep: { ...item.departure },
            deps: [],
            des: { ...item.destination },
            dess: []
        }));

        const result = [];

        // 找到发货地相同的路线 后续将收货地连接起来
        // 找到收货地相同的线路 后续将发货地连接起来
        // while(temp = lines.shift()) {
        //     const dep_res = lines.map(item => item.id === temp.id && latLngIsSame(item, temp, 'dep') && item).filter(Boolean);
        //     if (dep_res.length) {
        //         temp.deps.push(...dep_res);
        //         dep_res.forEach(item => lines.splice(lines.findIndex(el => el.id === item.id), 1));
        //     }
        //     const des_res = lines.map(item => item.id === temp.id && latLngIsSame(item, temp, 'des') && item).filter(Boolean);
        //     if (des_res.length) {
        //         temp.dess.push(...des_res)
        //         des_res.forEach(item => lines.splice(lines.findIndex(el => el.id === item.id), 1));
        //     }
        //     result.every(item => item.id !== temp.id && !latLngIsSame(item, temp, 'dep') && !latLngIsSame(item, temp, 'des')) && result.push(temp);
        // }

        // 讲那么多情况 不就是无脑将所有起点连接 所有终点连接
        while ((temp = lines.shift())) {
            const dep_res = lines.map((item) => item.id === temp.id && item).filter(Boolean);
            if (dep_res.length) {
                temp.deps.push(...dep_res);
                // dep_res.forEach(item => lines.splice(lines.findIndex(el => el.id === item.id), 1));
            }
            const des_res = lines.map((item) => item.id === temp.id && item).filter(Boolean);
            if (des_res.length) {
                temp.dess.push(...des_res);
                // des_res.forEach(item => lines.splice(lines.findIndex(el => el.id === item.id), 1));
            }
            result.every((item) => item.id !== temp.id) && result.push(temp);
        }

        return result;
    }

    // 经纬度判断
    function latLngIsSame(prev, next, key) {
        return prev[key].longitude === next[key].longitude && prev[key].latitude === next[key].latitude;
    }

    // 处理 Marker 的数据
    function handlePointItem(pointObj, defaultData) {
        const {
            lat,
            lng,
            type,
            name,
            address,
            transit,
            totalGrossWeight,
            totalQuantity,
            totalVolume,
            iconKey,
            grossWeight,
            quantity,
            volume
        } = defaultData;

        if (pointObj["-" + lat + "-" + lng]) {
            if (pointObj["-" + lat + "-" + lng].type === type) {
                // 若终点起点已绘制 只修改图标 中转地标示 不计算货物
                if (defaultData.isOrderPoint) {
                    pointObj["-" + lat + "-" + lng].transit = false;
                    pointObj["-" + lat + "-" + lng].iconKey = iconKey;
                    pointObj["-" + lat + "-" + lng].totalGrossWeight = totalGrossWeight;
                    pointObj["-" + lat + "-" + lng].totalQuantity = totalQuantity;
                    pointObj["-" + lat + "-" + lng].totalVolume = totalVolume;
                } else {
                    pointObj["-" + lat + "-" + lng] = {
                        ...pointObj["-" + lat + "-" + lng],
                        grossWeight: { ...pointObj["-" + lat + "-" + lng].grossWeight, ...grossWeight },
                        quantity: { ...pointObj["-" + lat + "-" + lng].quantity, ...quantity },
                        volume: { ...pointObj["-" + lat + "-" + lng].volume, ...volume },
                        totalGrossWeight: (pointObj["-" + lat + "-" + lng].totalGrossWeight += totalGrossWeight),
                        totalQuantity: (pointObj["-" + lat + "-" + lng].totalQuantity += totalQuantity),
                        totalVolume: (pointObj["-" + lat + "-" + lng].totalVolume += totalVolume)
                    };
                }
            }
        } else {
            pointObj["-" + lat + "-" + lng] = {
                lat: lat,
                lng: lng,
                type: type,
                iconKey: iconKey,
                name: name,
                address: address,
                transit: transit,
                grossWeight: grossWeight,
                quantity: quantity,
                volume: volume,
                totalGrossWeight,
                totalQuantity,
                totalVolume
            };
        }
    }

    // 返回原点
    function onRecoveryOrigin() {
        const { mapCtx } = ctx.current;
        const { BMapPoints, focusPoints } = mapContext.current;
        mapCtx.setViewport(focusPoints || BMapPoints, { margins: [20, 50, 20, 20] });
    }

    // 全屏处理
    function onFullScreen(isFullScreen, id) {
        const { mapDom, mapCtx } = ctx.current;
        const { BMapPoints, focusPoints } = mapContext.current;
        if (mapDom.id === id) {
            setTimeout(() => {
                mapCtx.setViewport(focusPoints || BMapPoints, { margins: [20, 50, 20, 20] });
            }, 100);
        }
    }

    // 选项卡点击事件
    function onCardClick(item, idx) {
        const { mapCtx } = ctx.current;
        const { cardActiveIdx } = pageInfo;
        const { polylines, BMapPoints } = mapContext.current;

        // 移除其他运单的相关节点
        removePopupListener(mapContextStowage);
        mapContextStowage.current.markers.map((el) => mapCtx.removeOverlay(el));
        mapContextStowage.current.polylines.map((el) => mapCtx.removeOverlay(el));
        Object.keys(mapContextStowage.current.analog).map((key) =>
            mapCtx.removeOverlay(mapContextStowage.current.analog[key])
        );

        if (cardActiveIdx === idx) {
            setPageInfo((prev) => ({ ...prev, cardActiveIdx: null }));
            cardActiveIdxRef.current = null;
            mapCtx.setViewport(BMapPoints, { margins: [20, 50, 20, 20] });
            polylines.map((el) => el.show());
            // 若运单中的节点包含了 发货单中的节点 则 原发货单中的节点的 悬浮窗会被覆盖
            // 此处暂时全部重置 重新监听
            removePopupListener(mapContext);
            addPopup(mapContext);
            return;
        }

        const {
            departure: { latitude: dep_lat, longitude: dep_lng },
            destination: { latitude: des_lat, longitude: des_lng },
            id
        } = item;
        const points = [
            { lng: dep_lng, lat: dep_lat },
            { lng: des_lng, lat: des_lat }
        ];
        const mid = getMidPosition(points);
        mapCtx.panTo(new BMapGL.Point(mid.lng, mid.lat));
        mapContext.current.focusPoints = points.map((el) => new BMapGL.Point(el.lng, el.lat));
        mapCtx.setViewport(mapContext.current.focusPoints, { margins: [20, 50, 20, 20] });
        // 若没配置路径 则显示默认路径
        if (item.status === 3 || item.status === 4) {
            getStowagePath(item);
        } else {
            drawDefaultLines(item);
        }
        setPageInfo((prev) => ({ ...prev, cardActiveIdx: idx, pathVisible: true }));
        cardActiveIdxRef.current = idx;
    }

    // 绘制默认路线
    function drawDefaultLines(item) {
        const { polylines } = mapContext.current;
        polylines.forEach((el) => {
            if (el.__stowageNumber === item.stowageNumber) {
                el.show();
            } else {
                el.hide();
            }
        });
    }

    // 获取运单路由列表
    function getStowagePath(item) {
        const { id: stowageId } = item;
        rApi["getStowageList"]({ orderId: id, stowageId })
            .then((res) => {
                if (res.length) {
                    drawStowageDetail(item, res);
                } else {
                    drawDefaultLines(item);
                }
            })
            .catch((err) => {
                console.log(err);
                message.warn("获取路由列表错误，请联系管理员");
            });
    }

    // 处理运单数据 标点 悬浮窗 等
    function drawStowageDetail(item, httpRes: routeConfig[]) {
        // 隐藏发货单路线
        mapContext.current.polylines.map((el) => el.hide());
        const pointObj = {};
        const { stowageNumber } = item;
        httpRes
            .filter((el) => el?.longitude && el?.latitude)
            .map((el) =>
                handlePointItem(pointObj, {
                    lat: el.latitude,
                    lng: el.longitude,
                    type: el.nodeType === 1 ? "dep" : el.nodeType === 2 ? "des" : "mark",
                    iconKey: el.nodeType === 1 ? "tihuo" : el.nodeType === 2 ? "xiehuo" : "mark",
                    isOrderPoint: false,
                    name: el?.transportInfo?.receiveOrSendName || el.address,
                    address: el.address,
                    transit: false,
                    grossWeight: { [stowageNumber]: el?.transportInfo?.totalGrossWeight },
                    quantity: { [stowageNumber]: el?.transportInfo?.totalQuantity },
                    volume: { [stowageNumber]: el?.transportInfo?.totalVolume },
                    totalGrossWeight: el?.transportInfo?.totalGrossWeight,
                    totalQuantity: el?.transportInfo?.totalQuantity,
                    totalVolume: el?.transportInfo?.totalVolume
                })
            );
        mapContextStowage.current.points = pointObj;
        drawMarker(pointObj, mapContextStowage);
        drawStowageLine(item, pointObj);

        addPopup(mapContextStowage, (markers) => markers.filter((el) => pointObj[el.__latLng].type !== "mark"));
    }

    // 绘制运单路线
    function drawStowageLine(item, pointObj) {
        const { mapCtx } = ctx.current;
        const keys = Object.keys(pointObj);
        const polylines = [];
        keys.map((key, idx) => {
            if (idx !== keys.length - 1) {
                const polyline = new BMapGL.Polyline(
                    [
                        new BMapGL.Point(pointObj[key].lng, pointObj[key].lat),
                        new BMapGL.Point(pointObj[keys[idx + 1]].lng, pointObj[keys[idx + 1]].lat)
                    ],
                    { strokeColor: "#2196F3", strokeWeight: 2, strokeOpacity: 0.9, strokeStyle: "solid" }
                );
                polyline.__path = [
                    { lng: pointObj[key].lng, lat: pointObj[key].lat },
                    { lng: pointObj[keys[idx + 1]].lng, lat: pointObj[keys[idx + 1]].lat }
                ];
                polylines.push(polyline);
            }
        });
        polylines.forEach((el) => mapCtx.addOverlay(el));
        mapContextStowage.current.polylines = polylines;
        mapContextStowage.current.lines = [{ stowageNumber: item.stowageNumber }];

        prevAddAnalogLine(item);
    }

    // 获取连接虚线的点
    function prevAddAnalogLine(item) {
        const { stowageNumber } = item;
        const { polylines } = mapContextStowage.current;
        const lastPolyline = polylines[polylines.length - 1];
        if (!lastPolyline) {
            return;
        }
        const endPoints = lastPolyline.__path[1];
        const curLines = mapContext.current.lines.find((el) => el.stowageNumber === stowageNumber);
        // 先判断同发货单的第一层终点 再判断相同发货单下的其他终点
        if (endPoints.lat !== curLines.des.latitude && endPoints.lng !== curLines.des.longitude) {
            addAnalogLine(endPoints, { lng: curLines.des.longitude, lat: curLines.des.latitude });
        } else {
            if (curLines.deps.length) {
                const find = curLines.deps.find(
                    (el) => el.des.latitude !== endPoints.lat && endPoints.lng !== el.des.longitude
                );
                // 有可连接虚线的终点
                if (find) {
                    addAnalogLine(endPoints, { lng: find.des.longitude, lat: find.des.latitude });
                }
            }
        }
    }

    // 添加虚拟线 以及箭头
    function addAnalogLine(prev: { lng: number; lat: number }, next: { lng: number; lat: number }) {
        const { mapCtx } = ctx.current;
        const pos = getAnalogLngLat([_.cloneDeep(prev), _.cloneDeep(next)], 20);
        const prevLine = new BMapGL.Polyline(
            [new BMapGL.Point(prev.lng, prev.lat), new BMapGL.Point(pos.lng, pos.lat)],
            {
                strokeColor: "#2196F3",
                strokeWeight: 2,
                strokeOpacity: 0.9,
                strokeStyle: "solid"
            }
        );
        const rotation = bearing(prev, pos);
        const icon = new BMapGL.Icon(icons.arrow, new BMapGL.Size(26, 26));
        const marker = new BMapGL.Marker(pos, {
            icon,
            rotation,
            offset: new BMapGL.Size(0, 0)
        });
        marker.setRotation(rotation);
        const nextLine = new BMapGL.Polyline(
            [new BMapGL.Point(pos.lng, pos.lat), new BMapGL.Point(next.lng, next.lat)],
            {
                strokeColor: "#2196F3",
                strokeWeight: 2,
                strokeOpacity: 0.9,
                strokeStyle: "dashed"
            }
        );
        mapCtx.addOverlay(prevLine);
        mapCtx.addOverlay(marker);
        mapCtx.addOverlay(nextLine);
        mapContextStowage.current.analog = { prevLine, marker, nextLine };
    }

    const downImportTemplate = () => {
        rApi["exportStowageRouteConfigTemplate"]()
            .then((res) => {
                try {
                    exportReqToExcel(res);
                } catch (e) {
                    console.log(e);
                }
            })
            .catch((e) => {
                message.error(e.msg || "操作失败!");
            });
    };
    const excelToData = async (header, data, jsonData) => {
        // console.log("excelToData", header, data, jsonData);
        const wayBillData: any = infos?.detailData?.[cardActiveIdxRef.current] ?? {};
        try {
            const importKeyDicReverse = Object.keys(importKeyDic).reduce(
                (p, c) => ({
                    ...p,
                    [importKeyDic[c]]: c
                }),
                {}
            );
            const noNeedList = ["position", "longitude", "latitude"];
            const requireList = Object.keys(importKeyDicReverse).filter((key) => !noNeedList.includes(key));
            // console.log("importKeyDicReverse", importKeyDicReverse);
            jsonData.forEach((item, index) => {
                requireList.forEach((key) => {
                    if (!item[key]) {
                        message.error(`第${index + 2}行收${importKeyDicReverse[key]}不能为空!`);
                        throw new Error("end");
                    }
                });
            });
            // this.setState({ importLoad: true });
            const params = jsonData.map((item) => ({
                ...item,
                arriveTime: formatXlsxDate(item.arriveTime).valueOf(),
                leaveTime: formatXlsxDate(item.leaveTime).valueOf(),
                stowageId: wayBillData.id,
                source: 6
            }));

            // console.log("params", params);
            await rApi["importStowageRouteConfig"](params);
            message.success("操作成功");

            // console.log("awesome_cardRef", awesome_cardRef);
            if (awesome_cardRef.current?.getStowagePath) {
                getStowagePath(wayBillData);
                awesome_cardRef.current?.getStowagePath(wayBillData, cardActiveIdxRef.current);
            }
        } catch (error) {
            console.log(error);
            message.error(error.msg || "操作失败");
        }
    };

    const renderTitleLeft = (data) => {
        // console.log("data", data);
        // console.log("infos.detailData", infos.detailData);

        const wayBillData: any = infos?.detailData?.[cardActiveIdxRef.current] ?? {};
        return (
            <div>
                {wayBillData?.status == 3 && (
                    <span onClick={downImportTemplate} className="hl_item" style={{ cursor: "pointer" }}>
                        导出路径模板
                    </span>
                )}
                {wayBillData?.status == 3 && (
                    <ImportExcel excelToData={excelToData} keyDic={importKeyDic}>
                        <span className="hl_item" style={{ cursor: "pointer" }}>
                            导入路径信息
                        </span>
                    </ImportExcel>
                )}
            </div>
        );
    };

    const { height: propHeight, ...rest } = props;
    const height = isFullscreen() ? propHeight : Number(propHeight) - (props.calcHeight ?? calcHeight);
    const { columns, footColumns } = popupInfo;
    const { detailVisible, pathVisible, cardActiveIdx } = pageInfo;
    return (
        <div style={{ position: "relative", overflow: "hidden" }}>
            <Popup
                mapCtx={ctx?.current?.mapCtx}
                columns={columns}
                footColumns={footColumns}
                onGetPopupInstance={onGetPopupInstance}
                onClick={onPopupClick}
                onMouseOver={onPopupMouseOver}
                onMouseOut={onPopupMouseOut}
            />
            <Map height={height} onGetMapCtx={onGetMapCtx}></Map>
            <AwesomeCard
                ref={awesome_cardRef}
                style={{ position: "absolute", right: 0, top: 0, marginTop: 50 }}
                domHeight={Number(propHeight)}
                contentStyle={{ maxHeight: window.innerHeight - 320 }}
                fromType={3}
                visible={detailVisible}
                pathVisible={pathVisible}
                mapRef={ctx?.current?.mapDom}
                data={infos.detailData}
                orderDetail={infos.orderDetail}
                activeIdx={cardActiveIdx}
                onVisibleChange={(detailVisible) => setPageInfo((prev) => ({ ...prev, detailVisible }))}
                onPathVisibleChange={(pathVisible) => setPageInfo((prev) => ({ ...prev, pathVisible }))}
                onCardClick={onCardClick}
                onRecoveryOrigin={onRecoveryOrigin}
                onFullScreen={onFullScreen}
                cusTitleLeft={renderTitleLeft}
                {...rest}
            ></AwesomeCard>
        </div>
    );
}
