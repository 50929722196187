const moduleName = "project/";
export const id = {
    id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE",
    method: "POST",
    name: "客户仓储报价-旧",
    type: "menu_hide",
    apiName: "getClientQuotation",
    url: `${moduleName}clientQuotation/list`,
    sort: 3
};

export const children = {
    // GET_LIST: {
    //     id: 'PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_GET_LIST',
    //     apiName: 'getClientQuotation',
    //     method: 'POST',
    //     name: '获取数据',
    //     type: 'view',
    //     url: `${moduleName}clientQuotation/list`
    // },
    ADD_DATA: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_ADD_DATA",
        apiName: "addClientQuotation",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}clientQuotation/addClientQuotation`
    },
    DEL_DATA: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_CODE",
        apiName: "delClientQuotation",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}clientQuotation/delete`
    },
    EDIT_DATA: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_EDIT_DATA",
        apiName: "editClientQuotation",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}clientQuotation/editClientQuotation`
    },
    EXAMINE_PASS: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_EXAMINE_PASS",
        apiName: "clientQuotationExaminePass",
        method: "GET",
        name: "审核通过",
        type: "view",
        url: `${moduleName}clientQuotation/pass`
    },
    CANCEL_PASS: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_CANCEL_PASS",
        apiName: "clientQuotationCancelExaminePass",
        method: "GET",
        name: "取消通过",
        type: "view",
        url: `${moduleName}clientQuotation/cancel`
    },
    EXAMINE_REJECT: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_EXAMINE_REJECT",
        apiName: "clientQuotationExamineReject",
        method: "POST",
        name: "审核驳回",
        type: "view",
        url: `${moduleName}clientQuotation/reject`
    },
    EXAMINE_SUBMIT: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_EXAMINE_SUBMIT",
        apiName: "clientQuotationSubmit",
        method: "GET",
        name: "提交",
        type: "view",
        url: `${moduleName}clientQuotation/submit`
    },
    SUSPEND_STATUS: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_SUSPEND_STATUS",
        apiName: "storeQuotationSuspend",
        method: "GET",
        name: "禁用",
        type: "view",
        url: `${moduleName}clientQuotation/suspend`
    },
    RECOVERY_STATUS: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_RECOVERY_STATUS",
        apiName: "storeQuotationRecovery",
        method: "GET",
        name: "恢复",
        type: "view",
        url: `${moduleName}clientQuotation/recevory`
    },
    EXPORT_LIST: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_EXPORT_LIST",
        apiName: "exportStoreQuotationList",
        method: "POST",
        name: "导出",
        type: "view",
        url: `${moduleName}clientQuotation/exportClientQuotationData`
    },
    ADD_COST: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_ADD_COST",
        name: "添加费用项",
        type: "view"
    },
    EDIT_COST: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_EDIT_COST",
        name: "编辑费用项",
        type: "view"
    },
    DEL_COST: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_DEL_COST",
        name: "删除费用项",
        type: "view"
    },
    LOOK_MORE: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
