const moduleName = "wms/validityWarning/";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_INVERTORY_EARLY_WARNING",
    method: "POST",
    name: "库存有效期预警",
    type: "menu",
    apiName: "getEarlyWarning",
    url: `${moduleName}list`
};

export const children = {};
