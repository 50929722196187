import React, { Component } from "react";
import rApi from "@src/http";
import { Cascader } from "antd";
import PropTypes from "prop-types";
// import addressData from '@src/libs/address'
import "./cascader.less";
// import list from '@src/views/business_management/order/list';

// const getAddressData = () => {
//     return import('../../libs/address.json')
// }
const getAddressData = () => {
    return import("../../libs/address.json");
};

export default class CascaderAddress extends Component {
    /**
     * 父组件传值接口defaultValue
     *
     * @memberof CascaderAddress
     */
    static propTypes = {
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        selectGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        // value: PropTypes.oneOfType([
        //     PropTypes.string,
        //     PropTypes.array
        // ])
    };

    static defaultProps = {
        selectGrade: 1 // 1 加载城市, 2加载区县,3加载街道
    };

    state = {
        defaultValue: [],
        options: [],
        data: [],
        show: true,
        isChange: false,
        abroadAddressList: [],
        isAbroad: false
    };

    constructor(props) {
        super(props);
        const { defaultValue } = props;
        try {
            let arr = defaultValue && typeof defaultValue !== "object" ? JSON.parse(defaultValue) : defaultValue;
            this.state.defaultValue = arr.map((item) => {
                if (item.code && !item.id) {
                    return {
                        ...item,
                        id: item.code
                    };
                } else {
                    return item;
                }
            });
        } catch (e) {}
    }

    componentDidMount() {
        this.initData();
        this.abroadAddress();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.defaultValue !== nextProps.defaultValue) {
            let arr =
                nextProps.defaultValue && typeof nextProps.defaultValue !== "object"
                    ? JSON.parse(nextProps.defaultValue)
                    : nextProps.defaultValue;
            let defaultValue = arr.map((item) => {
                if (item.code && !item.id) {
                    return {
                        ...item,
                        id: item.code
                    };
                } else {
                    return item;
                }
            });
            this.setState({
                defaultValue: defaultValue
            });
        }
    }

    abroadAddress = () => {
        rApi.getAbroadArea(1).then((res) => {
            this.setState({
                abroadAddressList: res.data
            });
        });
    };

    onChange = (value, selectedOptions) => {
        selectedOptions = selectedOptions.map((item) => {
            if (item.value === "国内" || item.value === "国外") {
                this.setState({
                    isAbroad: item.value === "国外"
                });
                return {
                    ...item,
                    value: "del",
                    label: "国内外"
                };
            }
            return {
                ...item
            };
        });
        this.setState({ data: selectedOptions.filter((item) => item.value != "del"), isChange: true });
        const { onChangeProvince, handleChangeAddress } = this.props;
        if (handleChangeAddress) {
            let arr = selectedOptions.filter((item) => item.value !== "del").map((item) => item.label) || [];
            // if(arr && arr.length > 0){
            handleChangeAddress(arr, selectedOptions);
            // }
        }

        if (onChangeProvince && value && value.length > 1) {
            let name = "";
            let data = value[1];
            if (selectedOptions && selectedOptions[0]) {
                let options = selectedOptions[0];
                if (options.children && options.children.length) {
                    let v = options.children.map((item) => item.value === data);
                    name = v && v.label;
                }
            }
            let dict = {
                value: data,
                label: name
            };

            onChangeProvince(dict);
        }
    };

    initData = () => {
        const changeValue = (list) => {
            if (!list || list.length < 0) {
                return null;
            }
            return list.map((item) => {
                return {
                    ...item,
                    children: changeValue(item.children),
                    value: item.value,
                    label: item.label
                };
            });
        };
        this.getDataAnsy = getAddressData();
        this.getDataAnsy
            .then((res) => {
                if (!this.state.unMount) {
                    this.setState({
                        options: [
                            { label: "全国", value: "全国" },
                            ...res.data.map((item) => ({
                                ...item,
                                children: changeValue(item.children),
                                value: item.value,
                                label: item.label
                            }))
                        ]
                    });
                }
            })
            .catch((e) => {
                if (!this.state.unMount) {
                    message.error("load address data error");
                }
            })
            .finally(() => {
                if (!this.state.unMount) {
                    this.setState({
                        loading: false
                    });
                }
            });
    };

    componentWillUnmount() {
        // if (this.getDataAnsy) {
        //     this.getDataAnsy.reject('xx')
        // }
        this.setState({
            unMount: true
        });
    }

    loadData = (selectedOptions) => {
        // const targetOption = selectedOptions[selectedOptions.length - 1]
        // const { selectGrade } = this.props
        // if (selectedOptions.length === 1) {
        //     targetOption.loading = true
        //     rApi.getCitys(targetOption).then((d) => {
        //         targetOption.loading = false
        //         targetOption.children = d.map((item, index) => {
        //             if (selectGrade === 1 || selectGrade === 'city') {
        //                 return {
        //                     id: item.id,
        //                     label: item.name,
        //                     value: item.name
        //                 }
        //             }
        //             return {
        //                 isLeaf: false,
        //                 id: item.id,
        //                 label: item.name,
        //                 value: item.name
        //             }
        //         })
        //         this.setState({
        //             options: [...this.state.options],
        //         })
        //     }).catch(e => {
        //         message.error('加载城市失败！')
        //     })
        // } else if (selectedOptions.length === 2) {
        //     targetOption.loading = true
        //     rApi.getCountys(targetOption).then(d => {
        //         targetOption.loading = false
        //         targetOption.children = d.map((item, index) => {
        //             if (selectGrade === 2 || selectGrade === 'county') {
        //                 return {
        //                     id: item.id,
        //                     label: item.name,
        //                     value: item.name
        //                 }
        //             }
        //             return {
        //                 isLeaf: false,
        //                 id: item.id,
        //                 label: item.name,
        //                 value: item.name
        //             }
        //         })
        //         this.setState({
        //             options: [...this.state.options],
        //         })
        //     }).catch(e => {
        //         message.error('加载区县失败！')
        //     })
        // } else if (selectedOptions.length === 3) {
        //     targetOption.loading = true
        //     rApi.getStreets(targetOption).then(d => {
        //         targetOption.loading = false;
        //         targetOption.children = d.map((item, index) => {
        //             return {
        //                 id: item.id,
        //                 label: item.name,
        //                 value: item.name
        //             }
        //         })
        //         this.setState({
        //             options: [...this.state.options],
        //         })
        //     }).catch(e => {
        //         message.error('加载街道失败！')
        //     })
        // }
    };

    defaultValueToString = (s) => {
        s = s || [];
        return s.join("/");
    };

    filter = (inputValue, path) => {
        return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };

    render() {
        let { defaultValue, options, isChange, abroadAddressList, data } = this.state;
        const { getPopupContainer } = this.props;
        if (!this.state.show) {
            return false;
        }
        let getTContainer = {};
        let def = {
            defaultValue:
                typeof defaultValue === "object"
                    ? defaultValue
                    : typeof defaultValue === "string"
                    ? JSON.parse(defaultValue)
                    : null
        };
        if (!defaultValue || defaultValue.length < 1 || !def.defaultValue) {
            def = {};
        }
        if (getPopupContainer) {
            getTContainer.getPopupContainer = getPopupContainer;
        } else {
            getTContainer.getPopupContainer = () => document.querySelector("#scroll-view");
        }
        const isPropsValue = "value" in this.props;

        let optionsData = [
            {
                label: "国内",
                value: "国内",
                children: options
            },
            {
                label: "国外",
                value: "国外",
                children: abroadAddressList
            }
        ];
        let displayRenderValue = "";
        if (this.state.defaultValue && this.state.defaultValue.length) {
            this.state.defaultValue.map((item, index) => {
                if (index === this.state.defaultValue.length - 1) {
                    displayRenderValue += item;
                } else {
                    displayRenderValue += item + "/";
                }
            });
        }

        return (
            <div>
                {options && options.length > 10 && (
                    <Cascader
                        //{...def}
                        {...(isPropsValue
                            ? {
                                  value: this.props.value
                              }
                            : {
                                  defaultValue: this.props.defaultValue
                              })}
                        title={this.props.title}
                        changeOnSelect
                        showSearch={this.filter}
                        allowClear={true}
                        style={this.props.style}
                        options={optionsData}
                        onChange={this.onChange}
                        placeholder={this.props.placeholder ? this.props.placeholder : "选择省市"}
                        // loadData={this.loadData}
                        popupClassName="cas-addr-wrapper"
                        size={window._size}
                        displayRender={(label) => {
                            // if (!label.length) {
                            //     return data && data.filter(item => item !== '国内' && item !== '国外').map(item => item.name).join(' / ') || '';
                            // }
                            return displayRenderValue;
                        }}
                    >
                        {
                            // !isChange && defaultValue && defaultValue.length > 0 ?
                            // <span
                            //     style={{width: '100%'}}
                            //     className={'ant-cascader-picker'}
                            // >
                            //     <span className={`${'ant-cascader'}-picker-label`}>
                            //         {
                            //             this.defaultValueToString(this.props.defaultValue)
                            //         }
                            //     </span>
                            //     <input type="text" className="ant-input ant-cascader-input ant-input-sm" />
                            // </span> : null
                        }
                    </Cascader>
                )}
            </div>
        );
    }
}

// export default SourceDemo;
