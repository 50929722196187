const m = "wms/departureTransport";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_ENTRUCK",
    name: "装车发运",
    type: "menu",
    method: "POST",
    apiName: "getShipmentEntruckList",
    url: `${m}/list`
};

export const children = {
    SAVE: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_SAVE",
        name: "新增",
        type: "view",
        apiName: "saveShipmentEntruck",
        method: "POST",
        url: `${m}/save`
    },
    SAVE_EDIT: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_SAVE_EDIT",
        name: "编辑",
        type: "view"
    },
    DELETE: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DELETE",
        name: "删除",
        type: "view",
        apiName: "deleteShipmentEntruck",
        method: "POST",
        url: `${m}/delete`
    }
};
