import load from "@src/layout/modules_load";

const RES_DEMAND = load(() => import("./receipt/demand"));
const RES_MANAGE = load(() => import("./receipt/manage"));

export default {
    RES_DEMAND: {
        component: RES_DEMAND,
        name: "收货需求"
    },
    RES_MANAGE: {
        component: RES_MANAGE,
        name: "收货管理"
    }
};
