const w = "client/client/";

export const id = {
    id: "CLIENT_SOURCE",
    method: "POST",
    name: "客户资料",
    type: "menu",
    apiName: "getClients",
    url: `${w}list`,
    sort: 1
};

export const children = {
    // GET_LIST: {
    //     id: 'CLIENT_SOURCE_GET_LIST',
    //     apiName: 'getClients',
    //     method: 'POST',
    //     name: '获取客户资料数据',
    //     type: 'view',
    //     url: '/client/list'
    // },
    ADD_DATA: {
        id: "CLIENT_SOURCE_ADD_DATA",
        apiName: "createClient",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${w}save`
    },
    REVIEW: {
        id: "CLIENT_SOURCE_REVIEW",
        apiName: "onClientReview",
        method: "POST",
        name: "审核",
        type: "view",
        url: `${w}review`
    },
    SALES_CARRIER_PASS: {
        id: "CLIENT_SOURCE_SALES_CARRIER_PASS",
        name: "销售审核",
        type: "view"
    },
    MARKET_CARRIER_PASS: {
        id: "CLIENT_SOURCE_MARKET_CARRIER_PASS",
        name: "市场审核",
        type: "view"
    },
    RESET_PASSWORD: {
        id: "CLIENT_SOURCE_RESET_PASSWORD",
        apiName: "onClientResetPassword",
        method: "POST",
        name: "重置密码",
        type: "view",
        url: `${w}resetPassword`
    },
    ENABLE: {
        id: "CLIENT_SOURCE_RESET_ENABLE",
        apiName: "onClientenable",
        method: "GET",
        name: "启用/禁用",
        type: "view",
        url: `${w}enable`
    },
    SUMBIT_DATA: {
        id: "CLIENT_SOURCE_SUMBIT_DATA",
        apiName: "submitClient",
        method: "POST",
        name: "提交",
        type: "view",
        url: `${w}submit`
    },
    // GEN_CODE: {
    //     id: 'CLIENT_SOURCE_GEN_CODE',
    //     apiName: 'gencode',
    //     method: 'POST',
    //     name: '获取客户代码',
    //     type: 'view',
    //     url: '/client/gencode'
    // },
    DEL_DATA: {
        id: "CLIENT_SOURCE_DEL_DATA",
        apiName: "delClient",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${w}delete`
    },
    EDIT_DATA: {
        id: "CLIENT_SOURCE_EDIT_DATA",
        apiName: "editClient",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${w}save`
    },
    BLUK_CREATE: {
        id: "CLIENT_SOURCE_BLUK_CREATE",
        apiName: "blukCreate",
        method: "POST",
        name: "批量导入",
        type: "view",
        url: `${w}batchSave`
    },
    EXPORT_TEMPLATE: {
        id: "CLIENT_SOURCE_EXPORT_TEMPLATE",
        apiName: "clientExportTemplate",
        method: "GETFILE",
        name: "导出模板",
        type: "view",
        url: `${w}exportTemp`
    },
    EXPORT_LIST: {
        id: "CLIENT_SOURCE_EXPORT_LIST",
        apiName: "clientExportList",
        method: "POSTFILE",
        name: "导出",
        type: "view",
        url: `${w}exportData`
    },
    LOOK_MORE: {
        id: "CLIENT_SOURCE_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
