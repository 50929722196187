import React, { useState, useEffect } from "react";
import { selfQueryString } from "@src/utils";
import {
    Form,
    // Button,
    // Row,
    // Col,
    message,
    // Divider,
    Input,
    Spin,
    Tabs,
    Empty
} from "antd";
import style from "./index.module.less";
import { BaseInfo, ShipmentInfo, MaterialListInfo } from "@src/views/transport_management/order/add2/base";
import IMapView from "@src/views/transport_management/order/add/map";
import rApi from "@src/http";
import _ from "lodash";

const { Search } = Input;
interface IPageInfo {
    orderNumber: string;
    customerNumber: string;
    clientName: string;
    clientId: number;
    departureTime: string;
    arrivalTime: string;
    billingMethodName: string;
    billingMethodId: number;
    agingName: string;
    agingId: number;
    remark: string;
    deliveryRequirements: string;
    salesmanName: string;
    salesmanId: number;
    salesmanPhoneNumber: string;
    businessRepresentativeList: any[];
    receiverSenderList: any[];
    contacts: any[];
    passwordRecipientList: any[];
    openDeliveryPassword: boolean;
    source: number;
    getDefaultCee: string;
    unload: string;
    arrive: string;
    id: number;
    [key: string]: any;
}
const defPageInfo = {
    orderNumber: null,
    customerNumber: null,
    clientName: null,
    clientId: null,
    departureTime: null,
    arrivalTime: null,
    billingMethodName: null,
    billingMethodId: null,
    agingName: null,
    agingId: null,
    remark: null,
    deliveryRequirements: null,
    salesmanName: null,
    salesmanId: null,
    salesmanPhoneNumber: null,
    businessRepresentativeList: [],
    receiverSenderList: [],
    openDeliveryPassword: null,
    passwordRecipientList: null,
    contacts: [],
    source: null,
    getDefaultCee: null,
    unload: null,
    arrive: null,
    id: null
};
const IndexPage = Form.create()(function (props: any) {
    const [tab, setTab] = useState("1");
    const [searchOrderNumber, setSearchOrderNumber] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);
    const [orderPageInfo, setOrderPageInfo] = useState<IPageInfo>(defPageInfo);

    function onChangeTab(activeKey) {
        setTab(activeKey);
    }
    const getDataDetail = async (customerNumber) => {
        if (customerNumber === null || customerNumber === undefined || customerNumber?.trim() === "") {
            message.warn("客户单号不能为空,查询无效");
            return;
        }
        setPageLoading(true);
        try {
            const res = await rApi["getAppOrderDetail"](customerNumber);
            console.log("res", res);
            setOrderPageInfo(res ?? defPageInfo);
        } catch (err) {
            setOrderPageInfo(defPageInfo);
            message.error(err?.msg ?? "系统错误,请联系管理员");
        } finally {
            setPageLoading(false);
        }
    };

    function changeSearchVal({ target: { value } }) {
        setSearchOrderNumber(value);
    }
    useEffect(() => {
        const { customerNumber } = selfQueryString(props?.location?.search);
        setSearchOrderNumber(customerNumber);
        getDataDetail(customerNumber);
    }, [props?.location?.search]);
    const { pageData, form } = props;
    const {
        orderNumber,
        customerNumber,
        clientName,
        clientId,
        departureTime,
        arrivalTime,
        billingMethodName,
        billingMethodId,
        agingName,
        agingId,
        remark,
        feedbackMessage,
        deliveryRequirements,
        salesmanName,
        salesmanId,
        salesmanPhoneNumber,
        businessRepresentativeList,
        receiverSenderList,
        openDeliveryPassword,
        passwordRecipientList,
        contacts,
        source,
        getDefaultCee,
        unload,
        arrive,
        id
    } = orderPageInfo;
    let readOnly = true;
    const materialList = receiverSenderList?.[0]?.["materialList"];
    const { getFieldDecorator, setFieldsValue } = form;
    const conHeight = (window?.screen?.height ?? 960) - 260;
    const openType = 1;
    return (
        <div className={style.publicPage}>
            <Spin spinning={pageLoading}>
                <div className={style.main}>
                    <div className={style.searchBox}>
                        <Search
                            placeholder="客户单号"
                            style={{ height: 30 }}
                            onSearch={() => getDataDetail(searchOrderNumber)}
                            onChange={changeSearchVal}
                            value={searchOrderNumber}
                            enterButton
                        />
                    </div>
                    {!id ? (
                        <Empty></Empty>
                    ) : (
                        <>
                            <Tabs activeKey={tab} onChange={onChangeTab}>
                                <Tabs.TabPane tab="发货单明细" key="1">
                                    <BaseInfo
                                        readOnly={readOnly}
                                        openType={openType}
                                        source={source}
                                        orderNumber={orderNumber}
                                        customerNumber={customerNumber}
                                        clientName={clientName}
                                        clientId={clientId}
                                        departureTime={departureTime}
                                        arrivalTime={arrivalTime}
                                        billingMethodName={billingMethodName}
                                        billingMethodId={billingMethodId}
                                        agingName={agingName}
                                        agingId={agingId}
                                        remark={remark}
                                        feedbackMessage={feedbackMessage}
                                        deliveryRequirements={deliveryRequirements}
                                        salesmanName={salesmanName}
                                        salesmanId={salesmanId}
                                        salesmanPhoneNumber={salesmanPhoneNumber}
                                        businessRepresentativeList={businessRepresentativeList}
                                        getFieldDecorator={getFieldDecorator}
                                        changeState={() => {}}
                                        setFieldsValue={setFieldsValue}
                                        getDefaultCee={getDefaultCee}
                                        unload={unload}
                                        arrive={arrive}
                                    />
                                    <ShipmentInfo
                                        changeState={() => {}}
                                        changeReceiverSenderList={() => {}}
                                        clientName={clientName}
                                        clientId={clientId}
                                        contacts={contacts}
                                        passwordRecipientList={passwordRecipientList}
                                        openDeliveryPassword={openDeliveryPassword}
                                        readOnly={readOnly}
                                        // businessRepresentativeList={businessRepresentativeList}
                                        receiverSenderList={receiverSenderList}
                                        openType={pageData?.openType ?? 3}
                                        source={source}
                                    />
                                    <MaterialListInfo
                                        changeReceiverSenderList={() => {}}
                                        materialList={_.isArray(materialList) ? materialList : []}
                                        readOnly={readOnly}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="地图追踪" key="3">
                                    {id && <IMapView id={id} height={conHeight} />}
                                </Tabs.TabPane>
                            </Tabs>
                        </>
                    )}
                </div>
            </Spin>
        </div>
    );
});

export default IndexPage;
