const m = "order/";
export const id = {
    id: "SENDCAR_MANAGEMENT_DETAILS",
    method: "POST",
    name: "运单明细",
    type: "menu_hide",
    sort: 103
    // apiName: 'getSendCarList',
    // url: `${m}sendCar/list`
};

// let m = ''
export const children = {
    // TRACK_CAR: {
    //     id: 'SEND_CAR_MANAGEMENT_TRACK_CAR',
    //     apiName: 'sendcarTrackEdit',
    //     method: 'POST',
    //     name: '追踪',
    //     type: 'view',
    //     url: `${m}sendCar/editTrack`
    // },
};
