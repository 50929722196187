import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};
export default class ConsignerApi {
    /**
     * 获取发货方数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.DELETE(url, params);
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        return this.POST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url;
        return this.PUT(url, params);
    }

    [children.BLUK_CREATE.apiName](params) {
        // 导入
        let url = children.BLUK_CREATE.url;
        return this.POST(url, params);
    }

    [children.EXPORT_TEMPLATE.apiName](params) {
        // 导出模板
        let url = children.EXPORT_TEMPLATE.url;
        return this.GETFILE(url, params);
    }

    consigner_cancelDefault(params) {
        // 取消默认发货方
        let url = `project/shipper/cancelDefault/${params.id}`;
        return this.PUT(url, params);
    }
    consigner_disable(params) {
        // 禁用
        let url = `project/shipper/disable/${params.id}`;
        return this.PUT(url, params);
    }
    consigner_enable(params) {
        // 启用
        let url = `project/shipper/enable/${params.id}`;
        return this.PUT(url, params);
    }

    consigner_setDefault(params) {
        // 设置默认发货方
        const url = `project/shipper/setDefault/${params.id}`;
        return this.PUT(url);
    }
    consigner_getDefault(params) {
        // 获取默认发货方
        const url = `project/shipper/getShipper`;
        return this.GET(url);
    }
    checkNameIsRepeat(params) {
        // 检测是否重复名字
        let url = `project/receiverOrSender/checkNameIsRepeat`;
        return this.POST(url, params);
    }

    // 百度境外地址查询
    abroadSearch(params) {
        const url = `common/district/abroadSearch?query=${params.query}&&region=${params.region}`;
        return this.GET(url, params);
    }
    consigner_getOne(params) {
        const url = `project/shipper/getOne/${params.id}`;
        return this.GET(url);
    }
}
