import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class {
    /**
     * 获取组织列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    // [children.GET_LIST.apiName](params) {
    //     let url = children.GET_LIST.url
    //     return this.GET(url, deleteNull(params))
    // }
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.GET(url, deleteNull(params));
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.SPOST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url;
        return this.POST(url, params);
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        // console.log('this', this)
        return this.POST(url, params);
    }
    [children.DISABLE.apiName](params) {
        let url = children.DISABLE.url + "/" + params.id;
        // console.log('this', this)
        return this.POST(url, deleteNull(params));
    }

    [children.ENABLE.apiName](params) {
        let url = children.ENABLE.url + "/" + params.id;
        // console.log('this', this)
        return this.POST(url, deleteNull(params));
    }

    [children.GRANT_LIST.apiName](params) {
        let url = children.GRANT_LIST.url + "?organizationId=" + params.id;
        // console.log('this', this)
        return this.GET(url, params);
    }
}
