import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class InventoryApi {
    /**
     *
     * @param {any} params
     * @returns
     *
     * @memberOf InventoryApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    // [children.EXPORT_DATA.apiName](params) { // 导出
    //     let url = children.EXPORT_DATA.url
    //     return this.POSTFILE(url, params)
    // }

    [children.ADD_DATA.apiName](params) {
        // 新增
        let url = children.ADD_DATA.url;
        return this.POST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        // 编辑
        let url = children.EDIT_DATA.url;
        return this.POST(url, params);
    }

    [children.DEL_DATA.apiName](params) {
        // 删除
        let url = children.DEL_DATA.url;
        return this.POST(url, params);
    }

    [children.DEAL_WITH.apiName](params) {
        // 处理
        let url = children.DEAL_WITH.url;
        return this.POST(url, params);
    }

    [children.DUTY_DEFINE.apiName](params) {
        // 责任界定
        let url = children.DUTY_DEFINE.url;
        return this.POST(url, params);
    }

    getComplaintDetailsById(params) {
        // 获取客户投诉建议
        let url = `client/complaint/${params.id}`;
        return this.GET(url, params);
    }
}
