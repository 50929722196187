import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class OrderApi {
    /**
     * 派车管理列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf UserApi
     */
    // [children.GET_LIST.apiName](params) {
    //     let url = children.GET_LIST.url
    //     return this.POST(url, params)
    // }

    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    // [children.CONFIRM_CAR.apiName](params) { //确认
    //     let url = children.CONFIRM_CAR.url
    //     return this.POST(url, params)
    // }

    [children.SIGN_CAR.apiName](params) {
        //签收
        let url = children.SIGN_CAR.url + "/" + params.id;
        return this.GET(url, params);
    }

    [children.CANCEL_CAR.apiName](params) {
        //取消
        let url = children.CANCEL_CAR.url + "/" + params.id;
        return this.GET(url, params);
    }

    [children.TRACK_CAR.apiName](params) {
        let url = children.TRACK_CAR.url;
        return this.POST(url, params);
    }

    [children.RETURN_FILE.apiName](params) {
        let url = children.RETURN_FILE.url;
        return this.POST(url, params);
    }

    [children.EXPORT.apiName](params) {
        let url = children.EXPORT.url + `/${params.id}`;
        return this.GETFILE(url);
    }

    getOneSendCarList(params) {
        //获取单个运单
        let url = `order/sendCar/getOne/${params.id}`;
        return this.POST(url, params);
    }

    getSendCarOrders(params) {
        //获取运单下的发货单
        let url = `order/stowage/sendCarOrders/${params.id}`;
        return this.GET(url, params);
    }

    getOrdersBySendCarNumber(params) {
        //获取根据运单号获取运单下的发货单
        let url = `order/sendCar/getOrdersBySendCarNumber/${params.sendCarNumber}`;
        return this.GET(url, params);
    }

    getSendCarCostInfo(params) {
        //获取运单费用信息
        let url = `order/sendCar/expenseDetail/${params.id}`;
        return this.GET(url, params);
    }

    confirmCar(params) {
        //确认
        let url = `order/sendCar/confirm`;
        return this.POST(url, params);
    }

    delSendCar(params) {
        //删除
        let url = `order/sendCar/delete`;
        return this.POST(url + "/" + params.id, { ids: params });
    }

    getSendCarAttachmentById(params) {
        //获取运单附件信息
        let url = `order/sendCar/getAttachmentById/${params.id}`;
        return this.GET(url, params);
    }

    sendcarDeviceUntied(params) {
        //解除绑定设备
        let url = `order/sendCar/untied/${params.id}`;
        return this.GET(url, params);
    }

    sendcarSaveEvaluation(params) {
        //保存评价
        let url = `order/transport/evaluation/saveEvaluation`;
        return this.POST(url, params);
    }

    sendcarshowEvaluation(params) {
        // 查看评价
        let url = `order/transport/evaluation/showEvaluation/${params.sendCarId}`;
        return this.GET(url, params);
    }
}
