import { children, id } from "./power_hide";

export default class EditAllocate {
    [id.apiName](pramsId) {
        let url = id.url + `/${pramsId}`;
        return this.GET(url);
    }

    [children.SAVE.apiName](params) {
        let url = children.SAVE.url;
        return this.POST(url, params);
    }

    editTransportInsurance(params) {
        return this.POST(`order/insurance/edit`, params);
    }
}
