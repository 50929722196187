import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class AbnormalApi {
    /**
     * 获取数据
     * @param {any} params
     * @returns
     * @memberOf WarehouseManegementReceiptDemandApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this[power[id.id].method](url, deleteNull(params));
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        return this[children.ADD_DATA.method](url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url + "/" + params.id;
        return this[children.EDIT_DATA.method](url, params);
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this[children.DEL_DATA.method](url, params);
    }

    // gencode(params) { //获取客户代码
    //     return this.POST('/client/gencode', params)
    // }
}
