import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class LegalPersonApi {
    /**
     * 获取核算项目列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf LegalPersonsApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.POST(url, params);
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        return this.POST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = `${children.EDIT_DATA.url}`;
        return this.POST(url, params);
    }

    [children.LOCK_ACTION.apiName](params) {
        let url = children.LOCK_ACTION.url;
        return this.POST(url, params);
    }

    [children.CANCEL_LOCK.apiName](params) {
        let url = children.CANCEL_LOCK.url;
        return this.POST(url, params);
    }

    getSpecialCostOperator() {
        let url = "finance/specialExpense/getCreateUser";
        return this.GET(url);
    }

    getSpecialExpense() {
        // 获取特殊费用项
        let url = "finance/specialExpense/getSpecialExpense     ";
        return this.GET(url);
    }
}
