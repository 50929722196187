import React, { Component, Fragment } from "react";
import { Input, Icon } from "antd";
import { isArray } from "lodash";
import PropTypes from "prop-types";
import "./headerview_template.less";
import { connect } from "dva";

const Search = Input.Search;

const style = { width: 130, paddingRight: 10 };
const ActionButton = (props) => {
    return <div style={{ ...style, ...props.style }}>{props.children}</div>;
};

@connect(({ global }) => ({
    pageWidth: global.domWidth - global.siderWidth
}))
class HeaderView extends Component {
    /**
     *  propTypes
     *  props.children 子元素
     *  props.onChangeSearchValue(data, callback(set loading status)) 点击搜索时调用
     *
     * @static
     *
     * @memberOf HeaderView
     */
    static propTypes = {
        onChangeSearchValue: PropTypes.func,
        isNoneSearch: PropTypes.bool, //是否显示头部关键字搜索框,默认显示
        titleBar: PropTypes.element, //头部标题,默认null
        style: PropTypes.object, //style样式
        customClass: PropTypes.string, //自定义className
        deductionSize: PropTypes.number //当筛选栏每个筛选组长度超过默认长度时=>有多少个就传多少 默认0
    };

    static defaultProps = {
        isNoneSearch: false,
        titleBar: null,
        style: {},
        customClass: "",
        deductionSize: 0,
        defaultValue: null
    };

    state = {
        data: [],
        value: [],
        loading: false,
        keyword: "",
        showMore: false
    };

    constructor(props) {
        super(props);
        this.dom = React.createRef();
    }

    onFocus = () => {
        // console.log('onFocus')
    };

    onChangeValue = (d) => {
        // console.log('onChangeValue', d)
        this.setState(d, () => {
            if (this.props.onChangeSearchValue) {
                this.props.onChangeSearchValue(d.keyword, () => {
                    this.setState({ loading: false });
                });
            }
        });
    };

    onShowMore = () => {
        this.setState({
            showMore: !this.state.showMore
        });
    };

    render() {
        const { customClass, children, deductionSize, defaultValue, customSearchBtn, moreStyle } = this.props;
        let { showMore } = this.state;
        let pageWidth = (this.dom && this.dom.offsetWidth && this.dom.offsetWidth - 200) || this.props.pageWidth - 200;
        let allSearchMenu = children ? (isArray(children) ? children.filter((item) => item) : [children]) : null; // 所有搜索条件
        let size = 0;
        if (isArray(allSearchMenu)) {
            for (let i = 0; i < allSearchMenu.length; i++) {
                const item = allSearchMenu[i];
                pageWidth -= (item && item.props && item.props.style && item.props.style.width) || 135;
                if (pageWidth < 90) {
                    size = i;
                    break;
                }
            }
        }
        // console.log('pageWidth', pageWidth, size, this.dom)
        let ratio = allSearchMenu && allSearchMenu.length && size > 0;
        if (ratio) {
            this.filterBtn = allSearchMenu.slice(0, size); // 第一行过滤项
            this.moreBtn = allSearchMenu.slice(size); //更多过滤项
        } else {
            this.filterBtn = allSearchMenu;
            this.moreBtn = [];
        }
        // console.log('this.moreBtn', this.dom)
        // return null
        return (
            <div
                ref={this.dom}
                style={Object.assign(
                    {
                        padding: "10px 10px 10px",
                        borderBottom: "1px solid #eee",
                        background: "#fff",
                        position: "relative"
                    },
                    this.props.style ? this.props.style : {}
                )}
            >
                <div className={`flex ${customClass ? customClass : ""}`} style={{ position: "relative" }}>
                    {this.props.titleBar ? (
                        this.props.titleBar
                    ) : (
                        <Fragment>
                            <div className="flex" style={{ flexWrap: "wrap" }}>
                                {isArray(this.filterBtn) ? (
                                    this.filterBtn
                                        .filter((item) => item)
                                        .map((view, index) => {
                                            let style = view.props && view.props.style ? view.props.style : {};
                                            return (
                                                <ActionButton style={style} length={this.filterBtn.length} key={index}>
                                                    {view}
                                                </ActionButton>
                                            );
                                        })
                                ) : (
                                    <ActionButton
                                        style={
                                            this.filterBtn && this.filterBtn.props && this.filterBtn.props.style
                                                ? this.filterBtn.props.style
                                                : {}
                                        }
                                        length={1}
                                    >
                                        {this.filterBtn}
                                    </ActionButton>
                                )}
                            </div>
                            {ratio ? (
                                <div
                                    style={{
                                        // borderBottom: showMore ? '0' : '1px solid #d9d9d9',
                                        borderRadius: 4,
                                        borderColor: showMore ? window._global._theme.primary_color : "#d9d9d9"
                                        // height: showMore ? 42 : 32,
                                        // marginBottom: !showMore ? 10 : 0
                                    }}
                                    onClick={this.onShowMore}
                                    className="btn-wrapper"
                                >
                                    更多
                                    <Icon
                                        type="down"
                                        theme="outlined"
                                        style={{
                                            fontSize: "12px",
                                            color: "rgba(0, 0, 0, 0.25)",
                                            transition: "all 0.5s",
                                            transform: showMore ? "rotate(-180deg)" : ""
                                        }}
                                    />
                                    <div className={showMore ? "btn-mask" : ""}></div>
                                </div>
                            ) : null}
                        </Fragment>
                    )}
                    <div className="flex1"></div>
                    {customSearchBtn
                        ? customSearchBtn
                        : !this.props.isNoneSearch && (
                              <div style={{ width: 180 }} className={this.props.className}>
                                  <Search
                                      placeholder={this.props.title ? this.props.title : "输入关键字搜索"}
                                      onSearch={(value) => {
                                          this.onChangeValue({ keyword: value });
                                      }}
                                      defaultValue={defaultValue}
                                      size={window._size}
                                      onChange={(e) => {
                                          // console.log('value', value)
                                          if (this.props.onChange) {
                                              this.props.onChange(e.target.value);
                                          }
                                          // if (this.props.parent && this.props.parent.setState) {
                                          //     // console.log('value', e)
                                          //     if ('keyword' in this.props.parent.state) {
                                          //         this.props.parent.setState({
                                          //             keyword: e.target.value
                                          //         })
                                          //     }
                                          //     if ('keywords' in this.props.parent.state) {
                                          //         this.props.parent.setState({
                                          //             keywords: e.target.value
                                          //         })
                                          //     }

                                          // }
                                      }}
                                  />
                              </div>
                          )}
                </div>
                {this.dom && (
                    <div
                        className="btn-main"
                        style={{
                            display: showMore && this.moreBtn.length > 0 ? "block" : "none",
                            position: "fixed",
                            zIndex: 10,
                            width:
                                this.dom && this.dom.current ? this.dom.current.offsetWidth + 1 : this.props.pageWidth,
                            left: this.dom && this.dom.current ? this.dom.current.getBoundingClientRect().x - 1 : 9,
                            top:
                                this.dom && this.dom.current
                                    ? this.dom.current.getBoundingClientRect().height +
                                      this.dom.current.getBoundingClientRect().y
                                    : 0, // this.dom.getBoundingClientRect().y + this.dom.getBoundingClientRect().height,
                            paddingTop: 10,
                            paddingBottom: 10,
                            ...moreStyle
                        }}
                    >
                        <div className="flex" style={{ flexWrap: "wrap" }}>
                            {isArray(this.moreBtn) ? (
                                this.moreBtn
                                    .filter((item) => item)
                                    .map((view, index) => {
                                        let style = view.props && view.props.style ? view.props.style : {};
                                        return (
                                            <ActionButton style={style} length={this.moreBtn.length} key={index}>
                                                {view}
                                            </ActionButton>
                                        );
                                    })
                            ) : (
                                <ActionButton
                                    style={
                                        this.moreBtn && this.moreBtn.props && this.moreBtn.props.style
                                            ? this.moreBtn.props.style
                                            : {}
                                    }
                                    length={1}
                                >
                                    {this.moreBtn}
                                </ActionButton>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

HeaderView.ActionButton = ActionButton;

export default HeaderView;
