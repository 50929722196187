const m = "wms/checkList";
const m2 = "wms/checkManage";
const exportUrl = "wms/export";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS",
    name: "盘点管理明细",
    type: "menu_hide",
    method: "GET",
    apiName: "getCheckDetailsById",
    url: `${m}/getOne`,
    paramsType: 1
};

export const children = {
    CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_CONFIRM",
        apiName: "confirmCheckDetails",
        method: "GET",
        name: "确认",
        type: "view",
        url: `${m2}/confirm`,
        paramsType: 1
    },
    UN_CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_UN_CONFIRM",
        apiName: "unConfirmCheckDetails",
        method: "GET",
        name: "取消确认",
        type: "view",
        url: `${m2}/cancel`,
        paramsType: 1
    },
    LIST: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_LIST",
        apiName: "getCheckDetailsListById",
        method: "GET",
        name: "获取盘点明细列表",
        type: "view",
        url: `${m}/list`,
        paramsType: 1,
        isOnlyApi: true
    },
    DELETE_DETAILS: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_DELETE_DETAILS",
        apiName: "deleteCheckDetails",
        method: "POST",
        name: "删除盘点明细",
        type: "view",
        url: `${m}/delete`,
        paramsType: 2,
        paramsKeys: ["id"],
        paramsBodyKey: "ids"
    },
    START: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_START",
        apiName: "startCheckDetails",
        method: "GET",
        name: "启动盘点",
        type: "view",
        url: `${m2}/startUp`,
        paramsType: 1
    },
    COMPLETE: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_COMPLETE",
        apiName: "completeCheckDetails",
        method: "GET",
        name: "完成盘点",
        type: "view",
        url: `${m2}/complete`,
        paramsType: 1
    },
    REVIEW: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_REVIEW",
        apiName: "reviewCheckDetails",
        method: "GET",
        name: "盘点复审",
        type: "view",
        url: `${m2}/review`,
        paramsType: 1
    },
    SAVE_COUNT: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_SAVE_COUNT",
        apiName: "saveCheckDetailsList",
        method: "POST",
        name: "保存盘点",
        type: "view",
        url: `${m}/saveCheck`
    },
    REPLAY: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_REPLAY",
        apiName: "replayCheckDetailsList",
        method: "POST",
        name: "创建复盘",
        type: "view",
        url: `${m2}/create/replay`,
        paramsType: 2,
        paramsKeys: ["id"],
        paramsBodyKey: "ids"
    },
    CREATE_PROFILTLOSS: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_CREATE_PROFILTLOSS",
        apiName: "createProfitLoss",
        method: "POST",
        name: "调整盈亏", //创建盈亏单
        type: "view",
        url: `${m2}/create/profitLoss`,
        paramsType: 2,
        paramsKeys: ["id"],
        paramsBodyKey: "ids"
    },

    FINISH: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_FINISH",
        apiName: "finishCheckDetails",
        method: "GET",
        name: "任务完成",
        type: "view",
        url: `${m2}/finish`,
        paramsType: 1
    },
    EXPORT_T: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_EXPORT_T",
        apiName: "checkDetailsExportTemp",
        method: "POSTFILE",
        name: "导出盘点单",
        type: "view",
        url: `${exportUrl}/check/listResult`
    },
    IMPORT: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DETAILS_IMPORT",
        apiName: "importCheckResult",
        method: "POST",
        name: "导入结果单",
        type: "view",
        url: `${m}/importCheckList`
    }
};
