import React, { Component } from "react";
import Modal from "../modular_window";
import { Form, Popconfirm, Icon, InputNumber, Input, Switch, Checkbox, Button } from "antd";
import FunctionPower from "@src/layout/power_view/function.jsx";
import { objDeepCopy, fromHex } from "@src/utils";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const dealColumns = (columns) =>
    columns.map((col) => {
        // console.log('col', col)
        return {
            ...col,
            isNoDisplay: "isNoDisplay" in col ? Boolean(col.isNoDisplay) : false,
            isNoExport: "isNoExport" in col ? Boolean(col.isNoExport) : false
        };
    });

const WithSortableElement = SortableElement(
    ({
        col,
        colIndex,
        len,
        tableType,
        showSettingExport,
        onEditHeaderTitle,
        onChangeTitle,
        onChangeWidth,
        onChangeShow,
        onChangeExport
    }) => {
        const { title, isNoDisplay, fixed, isNoExport, _edit } = col;
        let width = col.width || 50;
        width = width < 50 ? 50 : width;
        width = width > 500 ? 500 : width;
        // console.log('title', title)
        return (
            <div className={`global_table_setting_item`}>
                <div className="name">
                    {_edit ? (
                        <Input
                            defaultValue={title}
                            style={{ width }}
                            size={window._size}
                            onChange={(e) => onChangeTitle(e.target.value, colIndex)}
                        />
                    ) : (
                        <div
                            className="text"
                            style={{ background: fromHex(window._global._theme.global_primary_color, 0.2), width }}
                        >
                            {title}
                        </div>
                    )}
                    <FunctionPower power={{ id: "EDIT_TITLE" }}>
                        <Icon
                            type="edit"
                            className="global_click_text"
                            style={{
                                marginLeft: "10px",
                                position: "relative"
                            }}
                            onClick={(e) => {
                                console.log("click", e);
                                e.stopPropagation();
                                onEditHeaderTitle(colIndex);
                            }}
                        />
                    </FunctionPower>
                </div>

                <div className="setting">
                    <span>宽度：</span>
                    <InputNumber
                        defaultValue={width}
                        size={window._size}
                        placeholder="50-500"
                        disabled={colIndex === len - 1 || false}
                        min={50}
                        max={500}
                        step={10}
                        onChange={(value) => onChangeWidth(value, colIndex)}
                    />
                    <span>&emsp;px&emsp;</span>
                    <Switch
                        className="divider"
                        checkedChildren="显示"
                        unCheckedChildren="隐藏"
                        defaultChecked={tableType === "advanced-table" ? !isNoDisplay : fixed ? false : !isNoDisplay}
                        disabled={fixed || false}
                        size={window._size}
                        onChange={(checked) => onChangeShow(checked, colIndex)}
                    />
                    {!!showSettingExport && (
                        <Checkbox
                            className="divider"
                            style={{ marginLeft: 20 }}
                            checked={!isNoExport}
                            onChange={(ev) => onChangeExport(ev.target.checked, colIndex)}
                        >
                            导出
                        </Checkbox>
                    )}
                </div>
            </div>
        );
    }
);

const SortableList = SortableContainer(({ columns, ...restProps }) => {
    // console.log('cols', columns)
    return (
        <div className="global_table_setting_list">
            {columns.map((column, columnIndex) => (
                <WithSortableElement
                    key={`item-${column.key || columnIndex}`}
                    col={column}
                    colIndex={columnIndex}
                    len={columns.length}
                    index={columnIndex}
                    disabled={column.fixed}
                    {...restProps}
                />
            ))}
        </div>
    );
});

class HeaderSetting extends Component {
    constructor(props) {
        super(props);
        const cols = objDeepCopy(dealColumns(props.newColumns));
        // console.log('constructor cols', cols)
        this.state = {
            open: false,
            loading: false,
            recoveryLoad: false,
            cols
        };
        if (props.getThis) {
            props.getThis(this);
        }
    }

    show(d) {
        const cols = objDeepCopy(dealColumns(d.cols));
        // console.log('show cols', cols)
        this.setState({
            cols,
            open: true
        });
    }

    actionDone = () => {
        this.changeOpen(false, "save");
        // message.success('操作成功！')
    };

    changeOpen = (state, type = null) => {
        // console.log('changeOpen', state)
        const { cancelUpdateColumns } = this.props;
        this.setState({
            open: state
        });
        if (!state) {
            this.clearValue();
            // console.log('changeOpen', cancelUpdateColumns, type)
            if (cancelUpdateColumns && !type) {
                cancelUpdateColumns();
            }
        }
    };

    clearValue() {
        this.setState({
            loading: false,
            recoveryLoad: false
        });
    }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.saveSubmit();
            }
        });
    };

    // 保存
    saveSubmit = async () => {
        try {
            const { cols } = this.state;
            const { onSubmitTableHeader, tableKey, tableType, tableSettingSave } = this.props;
            const onCols = cols.map((d, index) => ({
                key: d.key,
                title: d.title,
                width: d.width || 120,
                sort: index,
                isNoDisplay: !!d.isNoDisplay ? 1 : 0,
                isNoExport: !!d.isNoExport ? 1 : 0,
                id: d.id || null,
                type: tableKey
            }));

            this.setState({ loading: true });
            if (tableType === "advanced-table") {
                await tableSettingSave(onCols);
            } else {
                await onSubmitTableHeader(onCols);
            }
            this.actionDone();
            this.setState({ loading: false });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    // 恢复默认配置
    onDeleteHeaderData = async () => {
        const { onDeleteHeaderData, tableType, tableSettingReset } = this.props;
        try {
            this.setState({
                recoveryLoad: true
            });
            if (tableType === "advanced-table") {
                await tableSettingReset();
            } else {
                await onDeleteHeaderData();
            }
            this.actionDone();
            this.setState({
                recoveryLoad: false
            });
        } catch (error) {
            console.log(error);
            this.setState({
                recoveryLoad: false
            });
        }
    };

    // 编辑表头按钮
    onEditHeaderTitle = (index) => {
        this.setState((state) => {
            return {
                cols: state.cols.map((col, colIndex) => ({
                    ...col,
                    _edit: colIndex === index ? !col._edit : col._edit
                }))
            };
        });
    };

    // 修改表头名称
    onChangeTitle = (value, index) => {
        let { cols } = this.state;
        cols[index].title = value;
        this.setState({
            cols
        });
    };

    // 修改宽度
    onChangeWidth = (value, index) => {
        let { cols } = this.state;
        cols[index].width = value;
        this.setState({
            cols
        });
    };

    // 是否显示
    onChangeShow = (checked, index) => {
        let { cols } = this.state;
        cols[index].isNoDisplay = !checked;
        this.setState({
            cols
        });
    };

    onChangeExport = (checked, index) => {
        let { cols } = this.state;
        cols[index].isNoExport = !checked;
        this.setState({
            cols
        });
    };

    onSortEnd = ({ oldIndex, newIndex, collection, isKeySorting }) => {
        // console.log('onSortEnd', oldIndex, newIndex)
        if (newIndex === 0) return;
        const { cols } = this.state;
        const newList = [...cols];
        const dragItem = {
            ...newList.splice(oldIndex, 1)[0]
        };
        newList.splice(newIndex, 0, { ...dragItem });
        this.setState({ cols: newList });
    };

    getColumns = () => {
        // console.log('t', this.state)
        return this.state.cols;
    };

    render() {
        const { showSettingExport } = this.props;
        const { cols, recoveryLoad } = this.state;
        // console.log('columns', columns)
        return (
            <Modal
                onSubmit={this.handleSubmit}
                style={{ width: "100%", maxWidth: 850, minHeight: 300 }}
                changeOpen={this.changeOpen}
                loading={this.state.loading}
                open={this.state.open}
                title="表头设置"
                customButton={
                    <FunctionPower power={{ id: "RECOVER_LOCAL_DATA" }}>
                        <Popconfirm
                            placement="topLeft"
                            title="确定恢复默认配置吗?"
                            onConfirm={this.onDeleteHeaderData}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button
                                size={window._size}
                                style={{
                                    marginLeft: 10,
                                    height: 26,
                                    lineHeight: "26px",
                                    verticalAlign: "middle",
                                    borderRadius: 4
                                }}
                                loading={recoveryLoad}
                            >
                                恢复默认配置
                            </Button>
                        </Popconfirm>
                    </FunctionPower>
                }
                haveFooter
            >
                <div style={{ minHeight: 272, padding: "10px" }} ref="rootDom">
                    <Form>
                        <SortableList
                            lockToContainerEdges
                            hideSortableGhost
                            lockAxis={"y"}
                            // lockOffset={'0%'}
                            distance={2}
                            columns={cols}
                            helperClass="global_table_setting_drag_item"
                            onSortEnd={this.onSortEnd}
                            showSettingExport={showSettingExport}
                            onEditHeaderTitle={this.onEditHeaderTitle}
                            onChangeTitle={this.onChangeTitle}
                            onChangeWidth={this.onChangeWidth}
                            onChangeShow={this.onChangeShow}
                            onChangeExport={this.onChangeExport}
                            tableType={this.props.tableType}
                        />
                    </Form>
                </div>
            </Modal>
        );
    }
}

export default Form.create()(HeaderSetting);
