import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class OrderDistributeApi {
    [power.DISTRIBUTE_ONE.apiName](params) {
        const url = children.DISTRIBUTE_ONE.url;
        return this.POST(url, deleteNull(params));
    }
    [power.DISTRIBUTE_COMBINE.apiName](params) {
        const url = children.DISTRIBUTE_COMBINE.url;
        return this.POST(url, deleteNull(params));
    }
    [power.DISTRIBUTE_Manual.apiName](params) {
        const url = children.DISTRIBUTE_Manual.url;
        return this.POST(url, deleteNull(params));
    }
    searchOtherCarriers(params) {
        // 查询其他承运商
        const url = "resource/carrier/checkOtherCarriers";
        return this.POST(url, deleteNull(params));
    }
    transshipmentCarrier(params) {
        // 中转地分配承运商
        const url = "order/order/transshipmentCarrier";
        return this.POST(url, deleteNull(params));
    }
    rematchCarrier(params) {
        // 人工删除发货单后重新匹配承运商
        const url = "order/order/rematchCarrier";
        return this.POST(url, deleteNull(params));
    }
}
