const m = "wms/report";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHEET_INVOICING",
    name: "进销存报表",
    type: "menu",
    apiName: "getInvoicingReceiptList",
    method: "POST",
    url: `${m}/invoicingList`
};

export const children = {
    EXPORT: {
        id: "WAREHOUSE_MANAGEMENT_SHEET_INVOICING_EXPORT",
        apiName: "exportInvoicingData",
        method: "POSTFILE",
        name: "导出报表",
        type: "view",
        url: `wms/export/report/invoicingListData`
    },
    RECEIVE: {
        id: "WAREHOUSE_MANAGEMENT_SHEET_INVOICING_RECEIVE",
        name: "入库明细",
        type: "view"
    },
    DELIVER: {
        id: "WAREHOUSE_MANAGEMENT_SHEET_INVOICING_DELIVER",
        name: "出库明细",
        type: "view"
    },
    SEARCH: {
        id: "WAREHOUSE_MANAGEMENT_SHEET_INVOICING_SEARCH",
        name: "搜索",
        type: "view"
    }
};
