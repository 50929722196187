const moduleName = "resource/distributionRules/";
export const id = {
    id: "RESOURCE_MANAGEMENT_CARRIER_ALLOCATION",
    method: "POST",
    name: "承运商分配规则管理",
    type: "menu",
    apiName: "carrierDistributionRules_getList",
    url: `${moduleName}page`,
    sort: 1
};
// let moduleName = ''
export const children = {
    ADD_DATA: {
        id: "RESOURCE_MANAGEMENT_CARRIER_ALLOCATION_ADD_DATA",
        apiName: "carrierDistributionRules_add",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}save`
    },
    DEL_DATA: {
        id: "RESOURCE_MANAGEMENT_CARRIER_ALLOCATION_DEL_DATA",
        apiName: "carrierDistributionRules_del",
        method: "DELETE",
        name: "删除",
        type: "view",
        url: `${moduleName}remove`
    },
    EDIT_DATA: {
        id: "RESOURCE_MANAGEMENT_CARRIER_ALLOCATION_EDIT_DATA",
        apiName: "carrierDistributionRules_edit",
        method: "PUT",
        name: "编辑",
        type: "view",
        url: `${moduleName}edit`
    },
    ON_DISABLE: {
        id: "RESOURCE_MANAGEMENT_CARRIER_ALLOCATION_ON_DISABLE",
        apiName: "carrierDistributionRules_onDisable",
        method: "PUT",
        name: "禁用",
        type: "view",
        url: `${moduleName}disable`
    },
    ON_ENABLE: {
        id: "RESOURCE_MANAGEMENT_CARRIER_ALLOCATION_ON_ENABLE",
        apiName: "carrierDistributionRules_oneEnable",
        method: "PUT",
        name: "启用",
        type: "view",
        url: `${moduleName}enable`
    },
    LOOK_MORE: {
        id: "RESOURCE_MANAGEMENT_CARRIER_ALLOCATION_LOOK_MORE",
        name: "查看",
        method: "GET",
        type: "view",
        apiName: "carrierDistributionRules_getOne",
        url: `${moduleName}getOne`
    }
};
