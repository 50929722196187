import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class BillingApiClass {
    // 申请开票
    postApplyInvoice(params) {
        const url = `finance/receivableInvoice/applyInvoice`;
        return this.POST(url, params);
    }

    // 获取开票分页
    getInvoicePage(params) {
        const url = `finance/receivableInvoice/page`;
        return this.POST(url, params);
    }

    // 获取单个开票单信息
    getInvoiceOne(params) {
        const url = `finance/receivableInvoice/findById/${params.receivableInvoiceId}`;
        return this.POST(url, params);
    }

    // 更新上传附件
    updateInvoiceAttachment(params) {
        const url = `finance/receivableInvoice/updateAttachment/${params.receivableInvoiceId}`;
        return this.POST(url, params.body);
    }

    // 开票确认
    postInvoiceConfirm(params) {
        const url = `finance/receivableInvoice/makeInvoiceConfirm/${params.receivableInvoiceId}`;
        return this.POST(url, params);
    }

    // 根据客户id获取客户开票资料
    getClientFianceInfo(params) {
        const url = `client/client/clientInvoicePageList/${params.clientId}`;
        return this.SPOST(url, params);
    }

    // 保存客户发票信息
    postSaveClientFianceInfo(params) {
        const url = `client/client/saveClientInvoice/${params.clientId}`;
        return this.POST(url, params);
    }
}
