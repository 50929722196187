import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class SendcarDetailsApi {
    /**
     *
     * @param {any} params
     * @returns
     * @memberOf SendcarDetailsApi
     */
    // [power[id.id].apiName](params) {
    //     let url = power[id.id].url
    //     return this.POST(url, deleteNull(params))
    // }

    // 获取运单所属发货单
    getOrderListBySendcarId(params) {
        let url = `order/sendCar/getOrderListById/${params.id}`;
        return this.GET(url, params);
    }

    // 新增/编辑运单追踪日志
    addOrEditSendcarLogs(params) {
        let url = `order/sendCar/saveOrEditTrackRecord`;
        return this.POST(url, params);
    }

    // 新增/编辑运单追踪日志
    appAddOrEditSendcarLogs(params) {
        let url = `order/app/sendCar/saveOrEditTrackRecord`;
        return this.POST(url, params);
    }

    // 获取指定在途记录=>通过运单id
    appGetInTransitInfo(params) {
        let url = `order/app/sendCar/getInTransitInfo/${params.id}`;
        return this.GET(url, params);
    }

    // 保存在途记录信息
    appSaveInTransitInfo(params) {
        let url = `order/app/sendCar/saveInTransitInfo`;
        return this.POST(url, params);
    }

    // 删除运单追踪日志
    deleteSendcarLogs(params) {
        let url = `order/sendCar/deleteTrackRecord/${params.id}`;
        return this.GET(url, params);
    }

    // 获取发货单收发货方信息
    getReceiverSenderBySendcarId(params) {
        let url = `order/sendCar/getReceiverSenders/${params.id}`;
        return this.GET(url, params);
    }

    // 获取运单详情地图数据
    getSendcarTrajectory(params) {
        let url = `order/sendCar/getTrajectory/${params.id}`;
        return this.GET(url, params);
    }

    // 获取app运单详情地图数据
    getAppSendcarTrajectory(params) {
        let url = `order/app/sendCar/getLatitudeAndLongitude/${params.id}`;
        return this.GET(url, params);
    }
}
