const moduleName = "common/";

export const id = {
    id: "BASIC_DATA_APPROVAL_PROCESS",
    name: "审批流程配置",
    type: "menu"
    // moduleName
};

export const children = {};
