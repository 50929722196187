import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class ReportInventoryApi {
    /**
     * @param {any} params
     * @returns
     * @memberOf ReportInventoryApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.EXPORT.apiName](params) {
        let url = children.EXPORT.url;
        return this.POSTFILE(url, deleteNull(params));
    }

    getInventoryWarehouse(params) {
        // 获取库存仓库
        let url = `wms/report/getInventoryWarehouse`;
        return this.GET(url, params);
    }

    getInventoryClient(params) {
        // 获取库存客户
        let url = `wms/report/getInventoryClient`;
        return this.GET(url, params);
    }

    // 获取汇总数据
    getInventorySummary(params) {
        let url = `wms/inventoryManage/count`;
        return this.POST(url, params);
    }
}
