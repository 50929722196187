import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import ReactDom from "react-dom";
import LogisticsDetail from "@src/libs/baidumap/logisticsControl";
import "./index.less";

interface IProps {
    columns: any[];
    footColumns: any[];
    mapCtx: any; // BMapGL.Map
    onClick: () => void;
    onGetPopupInstance: (ctx) => void;
    onMouseOver: () => void;
    onMouseOut: () => void;
}

const BMapGL = window["BMapGL"];

export default function Index(props: IProps) {
    const { columns, footColumns, onMouseOver, onGetPopupInstance, onMouseOut, onClick, mapCtx } = props;
    const container = useRef(null);

    useEffect(() => {
        if (mapCtx) {
            const logistics = LogisticsDetail({ BMapGL });
            const instance: any = new logistics(new BMapGL.Size(-999, -999));
            instance.initialize(() => {
                const div = document.querySelector("#map_popup");
                mapCtx.getContainer().appendChild(div);
                return div;
            });
            onGetPopupInstance(instance);
            mapCtx.addControl(instance);
        }
    }, [mapCtx]);

    useEffect(() => {
        const div = document.createElement("div");
        div.id = "map_popup";
        container.current = div;
        document.body.appendChild(div);
    }, []);

    if (!columns.length) return null;

    return createPortal(
        <div
            className="hoverDetail"
            style={{ flexDirection: "column" }}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <div style={{ borderBottom: "1px solid #fff", paddingBottom: 5 }}>
                {columns.map((item) => (
                    <div className="info" key={item.key}>
                        <div className="normal" style={{ flexShrink: 0 }}>
                            {item.title}
                        </div>
                        <div className="normal" style={{ marginLeft: 10, textAlign: "right" }}>
                            {item.value}
                        </div>
                    </div>
                ))}
            </div>
            {footColumns && footColumns.length && (
                <div className="detail">
                    {footColumns.map((item) => (
                        <div className="item" key={item.key}>
                            <div className="normal" style={{ marginRight: 5 }}>
                                {item.title}
                            </div>
                            <div className="normal">{item.value}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>,
        document.querySelector("#map_popup")
    );
}
