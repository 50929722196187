import { IDvaModel } from "./models";
import { headerHeight } from "@src/layout/config";
import MODULEDEFINE from "@src/views/MODULEDEFINE";
import { findArrIndex, sleep, localSave, localRead, localRemove } from "@src/utils";
import { sideMinWidth, sideMaxWidth } from "@src/layout/config";
import version from "@src/version";
import { message, Modal } from "antd";
import rApi from "@src/http";
import { routerRedux } from "dva/router";
import { toOrderAdd } from "@src/layout/to_page";
import cookie from "@src/router/login/cookie.js";

// let contentViews = {}

let timer: any = null;
let tabCache = {};
let _md = null;

function modalFn(userAccount) {
    return new Promise((resolve, reject) => {
        if (_md) _md = null;
        _md = Modal.confirm({
            title: "登录失效",
            content: "点击确定重新连接，点击取消将注销登录进入登录页。",
            onOk() {
                rApi["refreshToken"]()
                    .then((res) => {
                        const loginInfo = setLoginInfoToLocal({ ...res, userAccount });
                        // console.log("refreshToken loginInfo", loginInfo);
                        resolve(loginInfo);
                    })
                    .catch((error) => {
                        console.log("refreshToken error", error);
                        reject(error);
                    });
            },
            onCancel() {
                reject("cancel");
            }
        });
    });
}

function getDomSize() {
    const domWidth = document.body.offsetWidth;
    const domHeight = document.body.offsetHeight;
    return {
        domWidth,
        domHeight
    };
}

function setLoginInfoToLocal(info) {
    const {
        permissions,
        access_token,
        username,
        id,
        organizationName,
        refresh_token,
        expires_in,
        userAccount,
        roleName
    } = info;
    const refreshToken = refresh_token || "";
    const getTokenTime = new Date().getTime() / 1000;
    const expiresIn = expires_in || 0;
    const powers = {};
    function toObj(item: powerItem) {
        if (item.code) {
            powers[item.code] = item;
        }
        if (item.children && item.children.length > 0) {
            item.children.forEach((c) => {
                toObj(c);
            });
        }
    }
    permissions.forEach((item: powerItem) => {
        toObj(item);
    });
    localSave("isLogin", true);
    localSave("token", access_token);
    localSave("userName", username || "");
    localSave("organizationName", organizationName || "");
    localSave("menus", JSON.stringify(permissions || []));
    localSave("refreshToken", refreshToken || "");
    localSave("getTokenTime", getTokenTime);
    localSave("expiresIn", expiresIn);
    localSave("userId", id || null);
    localSave("userAccount", userAccount || "");
    localSave("roleName", roleName || "");
    return {
        isLogin: true,
        menus: permissions || [],
        powers,
        token: access_token || "",
        userName: username || "",
        userId: id || null,
        organizationName: organizationName || "",
        refreshToken: refreshToken || "",
        getTokenTime,
        expiresIn,
        userAccount: userAccount || "",
        roleName: roleName || ""
    };
}

function removeLoginInfoFromLocal() {
    localRemove("isLogin");
    localRemove("token");
    localRemove("userName");
    localRemove("organizationName");
    localRemove("menus");
    localRemove("refreshToken");
    localRemove("getTokenTime");
    localRemove("expiresIn");
    localRemove("userId");
    localRemove("roleName");
    localRemove("platformName");
    localRemove("logoUrl");
    return {
        isLogin: false,
        menus: [],
        powers: {},
        token: "",
        userName: "",
        userId: null,
        organizationName: "",
        refreshToken: "",
        getTokenTime: 0,
        expiresIn: 0,
        userAccount: "",
        roleName: ""
    };
}

export function initState(): IGlobalState {
    const siderCollapsed = process.env.NODE_ENV !== "production" ? false : false;
    const siderWidth = siderCollapsed ? sideMinWidth : sideMaxWidth;
    // const isLogin = Boolean(localRead('isLogin'))
    const menus = JSON.parse(localRead("menus") || "[]");
    const powers = {};
    function toObj(item: powerItem) {
        if (item.code) {
            powers[item.code] = item;
        }
        if (item.children && item.children.length > 0) {
            item.children.forEach((c) => {
                toObj(c);
            });
        }
    }
    menus.forEach((item: powerItem) => {
        toObj(item);
    });
    const token = localRead("token") || localRead("accessToken") || "";
    const userName = localRead("userName") || "";
    const userId = parseInt(localRead("userId") || "0", 10) || null;
    const organizationName = localRead("organizationName") || "";
    const refreshToken = localRead("refreshToken") || "";
    const getTokenTime = parseInt(localRead("getTokenTime") || "0", 10);
    const expiresIn = parseInt(localRead("expiresIn") || "0", 10);
    const userAccount = localRead("userAccount") || "";
    const roleName = localRead("roleName") || "";
    const platformName = localRead("platformName") || "";
    const logoUrl = localRead("logoUrl") || "";
    const icp = localRead("icp") || "";
    // console.log('initState', isLogin, localRead('isLogin'))
    return {
        ...getDomSize(),
        tableHeight: 0,
        openedTabs: [],
        activeTabKey: "HOME",
        siderCollapsed,
        siderWidth,
        _size: "small",
        contentRefresh: null,
        _version: version,
        isLogin: !!token,
        menus,
        powers,
        token,
        userName,
        userId,
        organizationName,
        refreshToken,
        getTokenTime,
        expiresIn,
        userAccount,
        roleName,
        platformName,
        logoUrl,
        icp,
        freshTabKey: ""
    };
}
export interface IPageData<T> {
    [key: string]: T;
}

export interface ITab {
    origin: any;
    pageData: any;
    key: string;
    instance?: any;
}

type powerItem = {
    id: number;
    name: string;
    code: string;
    type: string;
    url: string | null;
    method: string | null;
    children: powerItem[];
    depth: number | null;
    parent: null | number;
    path: string | null;
};

export interface IGlobalState {
    domWidth: number; // body宽度
    domHeight: number; // body高度
    tableHeight: number; // 表格高度
    openedTabs: ITab[]; // 当前打开tabs以及页面缓存数据
    activeTabKey: string; // 当前激活tab页Key
    siderCollapsed: boolean; // 侧边栏折叠
    siderWidth: number; // 侧边栏宽度
    _size: string; // 组件ui尺寸
    _version: string; // 版本号
    contentRefresh: { [key: string]: boolean }; // content刷新
    isLogin: boolean; // 是否登录
    menus: powerItem[]; // 权限菜单数据
    powers: {
        [code: string]: powerItem;
    };
    token: string;
    userName: string;
    userId: number | null;
    organizationName: string;
    refreshToken: string;
    getTokenTime: number;
    expiresIn: number;
    userAccount: string;
    roleName: string;
    icp: string;
    platformName: string;
    logoUrl: string;
    freshTabKey: string;
}

const model: IDvaModel<IGlobalState> = {
    namespace: "global",
    state: initState(),
    effects: {
        *init({ payload }, { select, put, call, fork }) {
            const { page } = payload;
            const { isLogin, getTokenTime, expiresIn, userAccount } = yield select(({ global }) => ({
                isLogin: global.isLogin,
                getTokenTime: global.getTokenTime,
                expiresIn: global.expiresIn,
                userAccount: global.userAccount
            }));
            const nowTime: number = new Date().getTime() / 1000;
            const t = expiresIn - (nowTime - getTokenTime);

            // console.log('init>>>>>>>', isLogin, t, expiresIn, nowTime, getTokenTime)
            if (page === "login") {
                if (_md && _md.destroy) {
                    _md.destroy();
                    _md = null;
                }
                if (isLogin && Boolean(localRead("isLogin")) && t > 0) {
                    yield put(routerRedux.push("/"));
                } else {
                    yield put({ type: "logout" });
                }
            } else {
                if (isLogin && Boolean(localRead("isLogin"))) {
                    try {
                        if (t > 0) {
                            clearTimeout(timer);
                            timer = yield call(sleep, t * 1000);
                        }
                        const loginInfo = yield call(modalFn, userAccount);
                        // console.log("ret", loginInfo);
                        yield put({
                            type: "updateState",
                            payload: loginInfo
                        });
                    } catch (error) {
                        console.log("err", error);
                        yield put({ type: "logout", payload: { toLogin: true } });
                    }
                } else {
                    yield put({ type: "logout", payload: { toLogin: true } });
                }
            }
        },

        *login({ payload }, { put, call }) {
            try {
                const res = yield call(rApi["getAccessToken"], {
                    ...payload,
                    username: payload.username,
                    password: payload.password
                });
                const loginInfo = setLoginInfoToLocal({
                    ...res,
                    userAccount: payload.username
                });
                // console.log("login payload", payload);

                yield put({
                    type: "updateState",
                    payload: loginInfo
                });
                yield put(routerRedux.push("/"));
            } catch (error) {
                console.log(error);
                message.error(error.msg || "请检查账号密码是否正确！");
            }
        },

        *logout({ payload }, { select, put, call }) {
            const loginInfo = removeLoginInfoFromLocal();
            yield put({
                type: "updateState",
                payload: loginInfo
            });
            if (!!payload.toLogin) {
                yield put(routerRedux.push("/login"));
            }
        },

        *getPlatformInfo({ payload }, { select, put, call }) {
            try {
                const res = yield call(rApi["getSystemInformation"]);
                localSave("platformName", res.platformName || "");
                localSave("logoUrl", res.logo || "");
                yield put({
                    type: "updateState",
                    payload: {
                        platformName: res.platformName || "",
                        logoUrl: res.logo || "",
                        icp: res.icp || ""
                    }
                });
            } catch (error) {
                console.log("getPlatformInfo Error", error);
            }
        },

        *refresh({ payload }, { select, put }) {
            const { index } = payload;
            const { activeTabKey, openedTabs } = yield select(({ global }) => ({
                openedTabs: global.openedTabs,
                activeTabKey: global.activeTabKey
            }));
            if (!openedTabs[index] || openedTabs[index].key !== activeTabKey) {
                return;
            }
            const targetKey = openedTabs[index].key;
            const newOpenedTabs = [...openedTabs];
            newOpenedTabs[index] = tabCache[targetKey] || newOpenedTabs[index];
            // console.log('refresh', openedTabs, activeTabKey, tabCache[targetKey])
            yield put({
                type: "updateContentRefresh",
                payload: {
                    [targetKey]: true
                }
            });
            yield put({
                type: "updateState",
                payload: {
                    openedTabs: newOpenedTabs
                }
            });
            yield sleep(100);
            yield put({
                type: "updateContentRefresh",
                payload: {
                    [targetKey]: false
                }
            });
        },

        *updateTabKey({ payload }, { put, select }) {
            yield put({ type: "updateState", payload: { freshTabKey: payload.key } });
            yield put({ type: "updateState", payload: { freshTabKey: "" } });
        },
        *updatePageData({ payload }, { put, select }) {
            const { openedTabs } = yield select(({ global }) => ({
                openedTabs: global.openedTabs
            }));
            const find = openedTabs.find((item) => item.key === payload.key);
            if (find) {
                toOrderAdd({
                    id: payload.id,
                    pageData: {
                        ...payload,
                        openType: 1,
                        origin: find.origin
                    }
                });
                yield put({
                    type: "closeTabByKey",
                    payload: {
                        key: find.key
                    }
                });
            }
        }
    },
    reducers: {
        updateState(state: IGlobalState, { payload }: any) {
            return {
                ...state,
                ...payload
            };
        },

        setDomSizeAndTableHeight(state: IGlobalState, { payload }: any) {
            const { domWidth } = payload;
            let { domHeight } = payload;
            if (domHeight <= 0) {
                domHeight = window.innerHeight;
            }
            const tableHeight = domHeight - headerHeight - 44 - 34 - 40 - 34 - 10;
            // console.log('tableHeight', tableHeight, domHeight)
            // console.log('tableHeight', tableHeight)
            return {
                ...state,
                domWidth,
                domHeight,
                tableHeight
            };
        },

        updateActiveTabKey(state: IGlobalState, { payload }: any) {
            return {
                ...state,
                activeTabKey: payload.key
            };
        },

        updateOpenedTabs(state: IGlobalState, { payload }: any) {
            return {
                ...state,
                openedTabs: payload
            };
        },

        // 在当前tab页后面打开一个tab页
        openTab(state: IGlobalState, { payload }: any) {
            const { openedTabs, activeTabKey } = state;
            const { key, id } = payload;
            let newActiveTabKey = key;
            const newOpenedTabs = [...openedTabs];
            const module = MODULEDEFINE[key];
            const title = !!module
                ? !!module.name
                    ? module.name
                    : module.id && module.id.name
                    ? module.id.name
                    : "-"
                : "开发中...";
            const newTab = {
                title,
                ...payload
            };

            if (newActiveTabKey === "HOME") {
                return {
                    ...state,
                    activeTabKey: "HOME"
                };
            }
            if (module && module.id && module.id.type === "menu_hide" && "id" in payload) {
                newActiveTabKey = key + id;
                newTab.component = key;
                newTab.key = key + id;
                if (!tabCache[key + id]) {
                    tabCache[key + id] = { ...newTab };
                }
                if (openedTabs.some((tab: ITab) => tab.key === key + id)) {
                    return {
                        ...state,
                        openedTabs: openedTabs.map((item) => {
                            if (item.key === newActiveTabKey) {
                                return {
                                    ...item,
                                    ...newTab
                                };
                            }
                            return item;
                        }),
                        activeTabKey: newActiveTabKey
                    };
                }
            } else {
                newTab.component = key;
                newTab.key = key;
                if (!tabCache[key]) {
                    tabCache[key] = { ...newTab };
                }
                if (openedTabs.some((tab: ITab, idx: number) => tab.key === key)) {
                    return {
                        ...state,
                        openedTabs: openedTabs.map((item) => {
                            if (item.key === newActiveTabKey) {
                                return {
                                    ...item,
                                    ...newTab
                                };
                            }
                            return item;
                        }),
                        activeTabKey: newActiveTabKey
                    };
                }
            }
            const currentIndex = findArrIndex(openedTabs, "key", activeTabKey);
            if (currentIndex !== -1) {
                newOpenedTabs.splice(currentIndex + 1, 0, newTab);
            } else {
                newOpenedTabs.push(newTab);
            }
            // console.log("openTab", newOpenedTabs);
            return {
                ...state,
                openedTabs: newOpenedTabs,
                activeTabKey: newActiveTabKey
            };
        },

        updateTabData(state: IGlobalState, { payload }: any) {
            const { tabKey, newKey, ...rest } = payload;
            const newOpenedTabs = [...state.openedTabs];
            const targetIndex = newOpenedTabs.findIndex((tab: ITab) => tab.key === tabKey);
            if (!!newOpenedTabs[targetIndex]) {
                newOpenedTabs[targetIndex] = {
                    ...newOpenedTabs[targetIndex],
                    ...rest
                };
                if ("newKey" in payload) {
                    newOpenedTabs[targetIndex].key = newKey;
                }
                if ("newKey" in payload) {
                    tabCache[rest.key] = null;
                    tabCache[newKey] = {
                        ...newOpenedTabs[targetIndex],
                        ...rest
                    };
                    return {
                        ...state,
                        activeTabKey: newKey,
                        openedTabs: newOpenedTabs
                    };
                } else {
                    tabCache[rest.key] = {
                        ...newOpenedTabs[targetIndex],
                        ...rest
                    };
                    return {
                        ...state,
                        openedTabs: newOpenedTabs
                    };
                }
            } else {
                return state;
            }
        },

        closeTabByKey(state: IGlobalState, { payload }: any) {
            const { key } = payload;
            const { openedTabs, activeTabKey } = state;

            let newActiveTabKey = activeTabKey;
            const newOpenedTabs = [...openedTabs];
            const targetIndex = findArrIndex(newOpenedTabs, "key", key);
            if (
                openedTabs[targetIndex] &&
                openedTabs[targetIndex].key &&
                openedTabs[targetIndex].key === activeTabKey
            ) {
                newActiveTabKey = targetIndex - 1 > -1 ? openedTabs[targetIndex - 1].key : "HOME";
            }
            newOpenedTabs.splice(targetIndex, 1);
            tabCache[key] = null;
            return {
                ...state,
                activeTabKey: newActiveTabKey,
                openedTabs: newOpenedTabs
            };
        },

        updateSiderStatus(state: IGlobalState, { payload }: any) {
            const siderCollapsed = !state.siderCollapsed;
            return {
                ...state,
                siderCollapsed,
                siderWidth: siderCollapsed ? sideMinWidth : sideMaxWidth
            };
        },

        close(state: IGlobalState, { payload }: any) {
            const { index } = payload;
            const { openedTabs, activeTabKey } = state;

            let newActiveTabKey = activeTabKey;
            if (openedTabs[index] && openedTabs[index].key && openedTabs[index].key === activeTabKey) {
                newActiveTabKey = index - 1 > -1 ? openedTabs[index - 1].key : "HOME";
            }
            const newOpenedTabs = [...openedTabs];
            newOpenedTabs.splice(index, 1);
            tabCache[openedTabs[index].key] = null;
            return {
                ...state,
                activeTabKey: newActiveTabKey,
                openedTabs: newOpenedTabs
            };
        },

        closeOther(state: IGlobalState, { payload }: any) {
            const { index } = payload;
            const { openedTabs, activeTabKey } = state;

            let newActiveTabKey = activeTabKey;
            if (openedTabs[index].key !== activeTabKey) {
                newActiveTabKey = openedTabs[index].key;
            }
            tabCache[openedTabs[index].key] = openedTabs[index];
            // console.log("closeOther", openedTabs[index].key);
            return {
                ...state,
                activeTabKey: newActiveTabKey,
                openedTabs: [openedTabs[index]]
            };
        },

        closeAll(state: IGlobalState, { payload }: any) {
            tabCache = {};
            return {
                ...state,
                activeTabKey: "HOME",
                openedTabs: []
            };
        },

        closeRight(state: IGlobalState, { payload }: any) {
            const { index } = payload;
            const { openedTabs, activeTabKey } = state;

            let newActiveTabKey = activeTabKey;
            const newOpenedTabs = [...openedTabs];
            const currentIndex = findArrIndex(newOpenedTabs, "key", activeTabKey);
            if (currentIndex > index) {
                newActiveTabKey = newOpenedTabs[index].key;
            }
            newOpenedTabs.splice(index + 1, openedTabs.length);
            for (const key in tabCache) {
                if (tabCache.hasOwnProperty(key)) {
                    if (!newOpenedTabs.some((tab: ITab) => tab.key === key)) {
                        tabCache[key] = null;
                    }
                }
            }
            return {
                ...state,
                activeTabKey: newActiveTabKey,
                openedTabs: newOpenedTabs
            };
        },

        closeLeft(state: IGlobalState, { payload }: any) {
            const { index } = payload;
            const { openedTabs, activeTabKey } = state;

            let newActiveTabKey = activeTabKey;
            const newOpenedTabs = [...openedTabs];
            const currentIndex = findArrIndex(newOpenedTabs, "key", activeTabKey);
            if (currentIndex < index) {
                newActiveTabKey = newOpenedTabs[index].key;
            }
            newOpenedTabs.splice(0, index);
            for (const key in tabCache) {
                if (tabCache.hasOwnProperty(key)) {
                    if (!newOpenedTabs.some((tab: ITab) => tab.key === key)) {
                        tabCache[key] = null;
                    }
                }
            }
            return {
                ...state,
                activeTabKey: newActiveTabKey,
                openedTabs: newOpenedTabs
            };
        },

        updateContentRefresh(state: IGlobalState, { payload }: any) {
            return {
                ...state,
                contentRefresh: payload
            };
        }
    }
};
export default model;
