export const id = {
    id: "RECEIVABLE_MANAGEMENT_RECEIVABLE",
    method: "POST",
    name: "应收对账",
    type: "menu",
    sort: 1
};

export const children = {
    GENERATED_BILL: {
        id: "RECEIVABLE_MANAGEMENT_RECEIVABLE_GENERATED_BILL",
        method: "POST",
        name: "生成账单",
        type: "view"
    },
    FAST_GENERATED_BILL: {
        id: "RECEIVABLE_MANAGEMENT_RECEIVABLE_FAST_GENERATED_BILL",
        method: "POST",
        name: "快速账单",
        type: "view"
    },
    UPDATE: {
        id: "RECEIVABLE_MANAGEMENT_RECEIVABLE_UPDATE",
        name: "修改",
        type: "view"
    },
    CONFIRM: {
        id: "RECEIVABLE_MANAGEMENT_RECEIVABLE_CONFIRM",
        name: "确认",
        type: "view"
    },
    DELETE: {
        id: "RECEIVABLE_MANAGEMENT_RECEIVABLE_DELETE",
        name: "删除",
        type: "view"
    },
    REVOKE: {
        id: "RECEIVABLE_MANAGEMENT_RECEIVABLE_REVOKE",
        name: "撤销账单",
        type: "view"
    },
    EXPORT: {
        id: "RECEIVABLE_MANAGEMENT_RECEIVABLE_EXPORT",
        name: "导出报表",
        type: "view"
    }
};
