const m = "order/customs";

import getChildPowerJson from "../../../utils/getChildPowerJson";
// import newPage from '../insurance_details/power_hide'
export const id = {
    id: "BUSINESS_MANAGEMENT_CLEARANCE_LIST",
    method: "POST",
    name: "报关管理",
    type: "menu",
    apiName: "getClearanceList",
    url: `${m}/list`,
    sort: 4
};

// const nPage = getChildPowerJson.toJson(newPage)

export const children = {
    // [nPage.id]: nPage,
    EDIT: {
        id: "BUSINESS_MANAGEMENT_CLEARANCE_LIST_EDIT",
        name: "编辑",
        type: "view"
    },
    ADD: {
        id: "BUSINESS_MANAGEMENT_CLEARANCE_LIST_ADD",
        name: "创建报关单",
        type: "view",
        apiName: "addClearanceOrder",
        method: "POST",
        url: `${m}/save`
    },
    DELETE: {
        id: "BUSINESS_MANAGEMENT_CLEARANCE_LIST_DELETE",
        apiName: "deleteTransportClearance",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${m}/delete`
    },
    EDIT_DATA: {
        id: "BUSINESS_MANAGEMENT_CLEARANCE_LIST_EDIT_DATA",
        apiName: "editTransportClearance",
        method: "POST",
        name: "保存",
        type: "view",
        url: `${m}/saveLog`
    }
};
