const moduleName = "wms/profitLossManage/";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_INVERTORY_PROFIT_LOSS_DETAILS",
    method: "GET",
    name: "调整盈亏详情",
    type: "menu_hide",
    apiName: "getProfitLossDetails",
    url: `${moduleName}getOne/`
};

export const children = {};
