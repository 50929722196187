export const id = {
    id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_DETAILS",
    method: "POST",
    name: "应付对账明细",
    type: "menu_hide"
    // apiName: 'accountPayableList',
    // url: `order/sendCar/list`
};

export const children = {};
