const m = "wms/statistics";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_STATISTICS",
    method: "POST",
    name: "统计与计费",
    type: "menu",
    apiName: "getWhStatisticsList",
    url: `${m}/list`
};

export const children = {
    ADD_DATA: {
        id: "WAREHOUSE_MANAGEMENT_STATISTICS_ADD_DATA",
        apiName: "addWhStatistics",
        method: "POST",
        name: "生成费用单",
        type: "view",
        url: `${m}/generateCostSheet`
    }
};
