const moduleName = "project/consignee/";
export const id = {
    id: "PROJECT_MANAGEMENT_CONSIGNEE",
    method: "POST",
    name: "收货方管理",
    type: "menu",
    apiName: "getConsignees",
    url: `${moduleName}/page`,
    sort: 2
};

export const children = {
    ADD_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_ADD_DATA",
        apiName: "addConsignee",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}/save`
    },
    DEL_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_CODE",
        apiName: "delConsignee",
        method: "DELETE",
        name: "删除",
        type: "view",
        url: `${moduleName}/remove`
    },
    EDIT_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_EDIT_DATA",
        apiName: "editConsignee",
        method: "PUT",
        name: "编辑",
        type: "view",
        url: `${moduleName}/edit`
    },
    ENABLE_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_ENABLE_DATA",
        method: "PUT",
        name: "启用",
        type: "view"
    },
    DISABLE_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_DISABLE_DATA",
        name: "禁用",
        type: "view"
    },
    SETDEFUALT_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_SETDEFUALT_DATA",
        name: "设置默认收货方",
        type: "view"
    },
    CANCELDEFUALT_DATA: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_CANCELDEFUALT_DATA",
        name: "取消默认收货方",
        type: "view"
    },
    BLUK_CREATE: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_BLUK_CREATE",
        apiName: "consigneeBatchSave",
        method: "POST",
        name: "批量导入",
        type: "view",
        url: `${moduleName}/batchSave`
    },
    EXPORT_TEMPLATE: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_EXPORT_TEMPLATE",
        apiName: "consigneeExportTemplate",
        method: "GETFILE",
        name: "导出模板",
        type: "view",
        url: `${moduleName}/exportTemplate`
    },
    LOOK_MORE: {
        id: "PROJECT_MANAGEMENT_CONSIGNEE_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
