const prevUrl = "finance/payableReconciliation";

export const id = {
    id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE",
    method: "POST",
    name: "运输应付对账",
    type: "menu",
    apiName: "accountPayableList",
    url: `order/stowage/list`
};

export const children = {
    ADD_DATA: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_ADD_DATA",
        apiName: "addPayableAccount",
        method: "POST",
        name: "派车-生成对账单",
        type: "view",
        url: `${prevUrl}/save`
    },
    ADD_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_ADD_SPEC",
        name: "特殊业务-生成对账单",
        type: "view"
    },
    EDIT_ACCOUNT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_EDIT_ACCOUNT",
        apiName: "editAccountPayable",
        method: "POST",
        name: "编辑应付对账",
        type: "view",
        url: `${prevUrl}/edit`
    },
    CONFIRM_ACCOUNT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CONFIRM_ACCOUNT",
        apiName: "confirmAccountPayable",
        method: "POST",
        name: "确认应付对账",
        type: "view",
        url: `${prevUrl}/confirm`
    },
    CANCEL_ACCOUNT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CANCEL_ACCOUNT",
        apiName: "cancelAccountPayable",
        method: "POST",
        name: "取消应付对账",
        type: "view",
        url: `${prevUrl}/cancel`
    },
    CONFIRM_FINANCE: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CONFIRM_FINANCE",
        apiName: "confirmFinancePayable",
        method: "POST",
        name: "派车-财务确认",
        type: "view",
        url: `${prevUrl}/confirmByFinance`
    },
    CONFIRM_FINANCE_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CONFIRM_FINANCE_SPEC",
        name: "特殊业务-财务确认",
        type: "view"
    },
    CANCEL_FINANCE: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CANCEL_FINANCE",
        apiName: "cancelFinancePayable",
        method: "POST",
        name: "派车-财务取消",
        type: "view",
        url: `${prevUrl}/cancelByFinance`
    },
    CANCEL_FINANCE_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CANCEL_FINANCE_SPEC",
        name: "特殊业务-财务取消",
        type: "view"
    },
    DEL_ACCOUNT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_DEL_ACCOUNT",
        apiName: "deletePayableAccount",
        method: "POST",
        name: "删除对账单",
        type: "view",
        url: `${prevUrl}/delete`
    },
    EXPORT_CASH: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_EXPORT_CASH",
        apiName: "exportAccountPayableList",
        method: "POSTFILE",
        name: "派车-导出现结",
        type: "view",
        url: `order/sendCar/exportData`
    },
    EXPORT_CASH_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_EXPORT_CASH_SPEC",
        name: "特殊业务-导出现结",
        type: "view"
    },
    EXPORT_FULL: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_EXPORT_FULL",
        apiName: "exportAccountPayableList",
        method: "POSTFILE",
        name: "派车-导出月结",
        type: "view",
        url: `order/sendCar/exportData`
    },
    EXPORT_FULL_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_EXPORT_FULL_SPEC",
        name: "特殊业务-导出月结",
        type: "view"
    },
    EXPORT_LIST: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABL_EXPORT_LIST",
        apiName: "exportPayableReconciliationList",
        method: "POSTFILE",
        name: "应付对账导出(汇总表)",
        type: "view",
        url: `${prevUrl}/exportPayableReconciliationList`
    },
    EXPORT_DETAILS: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABL_EXPORT_DETAILS",
        apiName: "exportAccountPayableDetails",
        method: "GETFILE",
        name: "应付对账导出(明细表)",
        type: "view",
        url: `order/sendCar/exportAccountPayableDetails`
    },
    BILLS_UPLOAD: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_BILLS_UPLOAD",
        //apiName: 'addCar',
        //method: 'POST',
        name: "对账单上传",
        type: "view"
        //url: `${moduleName}order/save`
    },
    BILLS_EDIT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_BILLS_EDIT",
        //apiName: 'addCar',
        //method: 'POST',
        name: "对账单编辑",
        type: "view"
        //url: `${moduleName}order/save`
    },
    CONFIRM_CUSTOMER: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CONFIRM_CUSTOMER",
        apiName: "paymentStatusProcess",
        method: "GET",
        name: "派车-客服确认",
        type: "view",
        url: `${prevUrl}/statusProcess`
    },
    CONFIRM_CUSTOMER_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CONFIRM_CUSTOMER_SPEC",
        name: "特殊业务-客服确认",
        type: "view"
    },
    CANCEL_CUSTOMER: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CANCEL_CUSTOMER",
        name: "派车-客服取消",
        type: "view"
    },
    CANCEL_CUSTOMER_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_CANCEL_CUSTOMER_SPEC",
        name: "特殊业务-客服取消",
        type: "view"
    },
    LOOK_DATA: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_LOOK_DATA",
        name: "派车-查看",
        type: "view"
    },
    LOOK_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_LOOK_SPEC",
        name: "特殊业务-查看",
        type: "view"
    },
    LOGS: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_LOGS",
        name: "派车-日志",
        type: "view"
    },
    LOGS_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_LOGS_SPEC",
        name: "特殊业务-日志",
        type: "view"
    },
    DELETE_DATA: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_DELETE_DATA",
        name: "派车-删除",
        type: "view"
    },
    DELETE_SPEC: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_DELETE_SPEC",
        name: "特殊业务-删除",
        type: "view"
    },
    BILLS_UPLOAD: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_BILLS_UPLOAD",
        name: "派车-上传附件",
        type: "view"
    },
    SPEC_UPLOAD: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_SPEC_UPLOAD",
        name: "特殊业务-上传附件",
        type: "view"
    }
};
