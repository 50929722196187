const moduleName = "finance/receivableReconciliation";
export const id = {
    id: "FINANCIAL_MANAGEMENT_INVOICE_MANAGEMENT",
    method: "POST",
    name: "开票管理",
    type: "menu",
    apiName: "invoiceList",
    url: `${moduleName}/invoiceList`
};

export const children = {
    ADD_DATA: {
        id: "FINANCIAL_MANAGEMENT_INVOICE_MANAGEMENT_ADD_DATA",
        //apiName: 'saveOrUpdate',
        // method: 'POST',
        name: "新增",
        type: "view"
        // url: `${moduleName}/saveOrUpdate`
    },
    DEL_DATA: {
        id: "FINANCIAL_MANAGEMENT_INVOICE_MANAGEMENT_DEL_DATA",
        //apiName: 'deleteBilling',
        // method: 'GET',
        name: "删除",
        type: "view"
        // url: `${moduleName}/deleteBilling`
    },
    ON_INVOICE: {
        id: "FINANCIAL_MANAGEMENT_INVOICE_MANAGEMENT_ON_INVOICE",
        //apiName: 'addCar',
        //method: 'POST',
        name: "开票",
        type: "view"
        //url: `${moduleName}car/save`
    },
    EXPORT_LIST: {
        id: "FINANCIAL_MANAGEMENT_INVOICE_MANAGEMENT_EXPORT_LIST",
        //apiName: 'addCar',
        //method: 'POST',
        name: "导出",
        type: "view"
        //url: `${moduleName}car/save`
    },
    UPLOAD_INVOICE: {
        id: "FINANCIAL_MANAGEMENT_INVOICE_MANAGEMENT_UPLOAD_INVOICE",
        apiName: "uploadBillAttachment",
        method: "POST",
        name: "上传发票",
        type: "view",
        url: `${moduleName}/updateInvoiceInformationById`
    }
    // UPLOAD_INVOICE: {
    //     id: 'FINANCIAL_MANAGEMENT_INVOICE_MANAGEMENT_UPLOAD_INVOICE',
    //     apiName: 'uploadBillAttachment',
    //     method: 'POST',
    //     name: '上传发票',
    //     type: 'view',
    //     url: `${moduleName}/uploadBillAttachment`
    // },
    // EDIT_INVOICE: {
    //     id: 'FINANCIAL_MANAGEMENT_INVOICE_MANAGEMENT_EDIT_INVOICE',
    //     apiName: 'recUpdateReceiptNumber',
    //     method: 'GET',
    //     name: '编辑发票号',
    //     type: 'view',
    //     url: `${moduleName}/updateReceiptNumber`
    // },
    // LOOK_MORE: {
    //     id: 'FINANCIAL_MANAGEMENT_RECEIVABLES_TRACKING_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
