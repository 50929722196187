import React from "react";
import { ColumnItemBox } from "@src/components/table_template";

// /*
// // 表格列工厂
// columnItem: [key, title, width, render], render: 'default' || ({ key, val, row, rowIndex, ColumnItemBox, defaultRender }) => {}
// */

const defaultRender = (val) => val;
const strategy = ([key, title, width, render, opt]) => {
    const Action = {
        textCell: ([key, title, width, render]) => ({
            // className: 'text-overflow-ellipsis',
            // title: (opt && opt.filters) ? title : title,
            title,
            dataIndex: key,
            key,
            width,
            ...opt,
            render: (val) => val
        }),
        renderCell: ([key, title, width, render]) => ({
            // className: `text-overflow-ellipsis`,
            title,
            dataIndex: key,
            key,
            width,
            ...opt,
            render: (val, row, rowIndex) =>
                render({ key, title, val, row, rowIndex, ColumnItemBox, defaultRender: defaultRender })
        })
    };
    if (render === "default") {
        return Action["textCell"]([key, title, width, render]);
    } else {
        return Action["renderCell"]([key, title, width, render]);
    }
};

const ColumnFactory = (function () {
    return {
        execute(columns) {
            if (columns.length) {
                return columns.map((col) => strategy(col));
            }
            return [];
        }
    };
})();

export const ColumnFactoryV2 = (columns) => {
    if (columns.length) {
        return columns.map((col) => strategy(col));
    }
    return [];
};

export default ColumnFactory;
