import Tips from "@src/components/tips";
import mixinStyle from "@src/libs/style/mixin_style";

export const windowMixin = () => {
    const arrayExtend = () => {
        if (!Array.prototype.contains) {
            Array.prototype.contains = function (key) {
                if (this.indexOf(key) > -1) {
                    return true;
                } else {
                    return false;
                }
            };
        }
        Array.prototype.find =
            Array.prototype.find ||
            function (fn) {
                let result = null;
                for (let i = 0; i < this.length; i++) {
                    const element = this[i];
                    if (fn(element)) {
                        result = [element];
                        break;
                    }
                }
                return result;
            };
    };
    const objectExtend = () => {
        Object.keys =
            Object.keys ||
            function (obj) {
                let result = [];
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        result.push(key);
                    }
                }
                return result;
            };
        Object.values =
            Object.values ||
            function (obj) {
                let result = [];
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        result.push(obj[key]);
                    }
                }
                return result;
            };
    };
    return (target) => {
        // console.log('a', args)
        window._tips = Tips;
        window._size = window._size || "small";
        window._global = window._global || {
            _size: "small",
            _theme: mixinStyle
        };

        arrayExtend();
        objectExtend();
    };
};
