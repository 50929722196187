import {
    trim,
    isString,
    isNumber,
    isArray,
    keys,
    values,
    isObject,
    isNaN,
    flatMapDeep,
    cloneDeep,
    flatten,
    isFunction,
    flatMap,
    startsWith,
    isEqual,
    uniq,
    uniqBy,
    isEmpty
} from "lodash";

export default {
    trim,
    isString,
    isNumber,
    isArray,
    keys,
    values,
    isObject,
    isNaN,
    flatMapDeep,
    cloneDeep,
    flatten,
    isFunction,
    flatMap,
    startsWith,
    isEqual,
    uniq,
    uniqBy,
    isEmpty
};
