import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class ReceivaccountApi {
    /**
     *
     * @param {any} params
     * @returns
     * @memberOf ReceivaccountApi
     */
    // [power[id.id].apiName](params) {
    //     let url = power[id.id].url
    //     return this.POST(url, deleteNull(params))
    // }
    // [power.EDIT_DATA.apiName](params) {
    //     let url = power.EDIT_DATA.url
    //     return this.POST(url, params)
    // }
    // [power.DEL_DATA.apiName](params) {
    //     let url = power.DEL_DATA.url + `/${params.id}`
    //     return this.GET(url, params)
    // }
    // [power.CONFIRM.apiName](params) {
    //     let url = power.CONFIRM.url
    //     return this.POST(url, params)
    // }
    // [power.CANCEL.apiName](params) {
    //     let url = power.CANCEL.url
    //     return this.POST(url, params)
    // }
    // [power.CONFIRM_FINANCE.apiName](params) {
    //     let url = power.CONFIRM_FINANCE.url
    //     return this.POST(url, params)
    // }
    // [power.CANCEL_FINANCE.apiName](params) {
    //     let url = power.CANCEL_FINANCE.url
    //     return this.POST(url, params)
    // }
    // getWhReceivaccountById (params) {
    //     //获取应收对账单数据
    //     let url = `wms/reconciliation/getOne/${params.id}`
    //     return this.GET(url, params)
    // }
    // getWhReceivaccountDetails(params) {
    //     //获取应收对账单对账明细数据
    //     let url = `wms/reconciliation/detail/${params.id}/${params.reconciliationType}`
    //     return this.GET(url, params)
    // }
    // deleteWhReceivaccountDetails(params) {
    //     //删除应收对账单对账明细
    //     let url = `wms/reconciliation/deleteReconciliationDetail/${params.id}`
    //     return this.GET(url, params)
    // }
    // getWhReceivaccountClientLegal(params) {
    //     //获取应收对账单客户法人筛选列表
    //     let url = `wms/reconciliation/getClientLegal`
    //     return this.GET(url, params)
    // }
    // getWhReceivaccountOrderLegal(params) {
    //     //获取应收对账单接单法人筛选列表
    //     let url = `wms/reconciliation/getOrderLegal`
    //     return this.GET(url, params)
    // }
    // getWhReceivaccountOperator(params) {
    //     //获取应收对账单开立人筛选列表
    //     let url = `wms/reconciliation/getOperator`
    //     return this.GET(url, params)
    // }
}
