import { children } from "./power";

export default class {
    [children.DOWNLOAD_TEMPLATE.apiName](params) {
        let url = children.DOWNLOAD_TEMPLATE.url;
        return this.GETFILE(url, params);
    }
    [children.INVALID_STATUS.apiName](params) {
        let url = children.INVALID_STATUS.url + "/" + params.id;
        return this.GET(url, params);
    }
    [children.EXAMINE_SUBMIT.apiName](params) {
        let url = children.EXAMINE_SUBMIT.url + "/" + params.id;
        return this.GET(url, params);
    }
    [children.EXAMINE_PASS.apiName](params) {
        let url = children.EXAMINE_PASS.url + "/" + params.id;
        return this.GET(url, params);
    }
    [children.CANCEL_PASS.apiName](params) {
        let url = children.CANCEL_PASS.url + "/" + params.id;
        return this.GET(url, params);
    }
    [children.EXAMINE_REJECT.apiName](params) {
        let url = children.EXAMINE_REJECT.url + "/" + params.id + "/" + params.reason || "";
        return this.POST(url, params);
    }
    [children.GET_QUOTATION_HEADER.apiName](params) {
        let url = children.GET_QUOTATION_HEADER.url + "/" + params.id;
        return this.GET(url, params);
    }
    [children.GET_QUOTATION_DATA.apiName](params) {
        let url = children.GET_QUOTATION_DATA.url + "/" + params.id;
        return this.POST(url, params);
    }
    [children.EXPORT_QUOTATION_DATA.apiName](params) {
        let url = children.EXPORT_QUOTATION_DATA.url + "/" + params.id;
        return this.POSTFILE(url, params);
    }
    getclientQuotationLog(params) {
        const url = `/project/clientQuotation/getLog/${params.id}`;
        return this.GET(url, params);
    }

    getClientMatchQuotationExpenseItems(params) {
        let url = "project/clientQuotation/matchQuotationExpense";
        return this.POST(url, params);
    }
}
