const m = "wms/scanning";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_RECEIPT_SCANNING",
    name: "收货扫描",
    type: "menu"
    // method: 'POST',
    // apiName: 'accountPayableList',
    // url: `order/sendCar/list`
};

export const children = {
    CONFIRM_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_SCANNING_CONFIRM_DATA",
        apiName: "confirmReceiptScanning",
        method: "POST",
        name: "提交理货",
        type: "view",
        url: `${m}/confirm`
    },
    SCANNING_SUBMIT: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_SCANNING_SCANNING_SUBMIT",
        name: "扫描提交",
        type: "view"
    }
};
