const m = "wms/checkManage";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_CHECK",
    name: "盘点作业",
    type: "menu",
    method: "POST",
    apiName: "getCheckList",
    url: `${m}/list`
};

export const children = {
    SAVE: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_SAVE",
        name: "新增",
        type: "view",
        apiName: "saveCheckItem",
        method: "POST",
        url: `${m}/save`
    },
    DELETE: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_DELETE",
        name: "删除",
        type: "view",
        apiName: "deleteCheckItem",
        method: "GET",
        url: `${m}/delete`,
        paramsType: 1
    },
    S_LIST1: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_S_LIST1",
        name: "获取客户名称列表",
        type: "view",
        apiName: "getCheckClientNames",
        method: "POST",
        url: `${m}/getClientNames`,
        isOnlyApi: true
    },
    S_LIST2: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_S_LIST2",
        name: "获取批次号列表",
        type: "view",
        apiName: "getCheckBatchNumber",
        method: "POST",
        url: `${m}/getBatchNumber`,
        isOnlyApi: true
    },
    S_LIST3: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_S_LIST3",
        name: "获取供应商列表",
        type: "view",
        apiName: "getCheckSupplier",
        method: "POST",
        url: `${m}/getSupplier`,
        isOnlyApi: true
    },
    S_LIST4: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_S_LIST4",
        name: "获取料号列表",
        type: "view",
        apiName: "getCheckMaterialNumber",
        method: "POST",
        url: `${m}/getMaterialNumber`,
        isOnlyApi: true
    },
    S_LIST5: {
        id: "WAREHOUSE_MANAGEMENT_CHECK_S_LIST5",
        name: "获取品质列表",
        type: "view",
        apiName: "getCheckQuality",
        method: "POST",
        url: `${m}/getQuality`,
        isOnlyApi: true
    }
};
