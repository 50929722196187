import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class ChargeReceivableApi {
    /**
     * @param {any} params
     * @returns
     *
     * @memberOf WarehouseManegementInventoryManageApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    // [children.DO_ESTIMATE.apiName](params) {
    //     let url = children.DO_ESTIMATE.url
    //     return this.POST(url, params)
    // }

    [children.DO_EDIT.apiName](params) {
        let url = children.DO_EDIT.url;
        return this.POST(url, params);
    }

    [children.DO_DELETE.apiName](params) {
        let url = children.DO_DELETE.url + `/${params.id}`;
        return this.POST(url, params);
    }

    [children.EXPORT_LIST.apiName](params) {
        // 导出
        let url = children.EXPORT_LIST.url;
        return this.POSTFILE(url, params);
    }

    [children.EXPORT_DETAILS.apiName](params) {
        // 导出明细
        let url = children.EXPORT_DETAILS.url;
        return this.POSTFILE(url, params);
    }

    [children.AUTO_GENERATE.apiName](params) {
        // 开立
        let url = children.AUTO_GENERATE.url;
        return this.GET(url, params);
    }

    // 应收预估待开立列表
    getChargeReceiveOrder(params) {
        //获取发货单制单人信息
        return this.POST(`order/order/listForEstimate`, params);
    }

    getChargeOrderOperators(params) {
        //获取发货单制单人信息
        return this.POST(`order/order/getCreateUser`, params);
    }

    getChargeOperatorList(params) {
        //获取配载制单人信息
        return this.POST(`order/stowage/getCreateUser`, params);
    }

    getChargeReceivableEstimateOrderinfo(params) {
        //获取一条发货单信息
        return this.POST(`order/order/getOne/${params.id}/${params.orderType}`, params);
    }

    getReceivableEstimateDetails(params) {
        //获取应收预估明细
        return this.GET(`finance/receivableEstimate/details/${params.id}`);
    }
}
