const prevUrl = "wms/receiptDemand";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND",
    method: "POST",
    name: "收货需求",
    type: "menu",
    apiName: "getReceipt",
    url: `${prevUrl}/list`
};

export const children = {
    ADD_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_ADD_DATA",
        apiName: "addReceipt",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${prevUrl}/add`
    },
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DEL_DATA",
        apiName: "delReceipt",
        method: "GET",
        name: "删除",
        type: "view",
        url: `${prevUrl}/delete`
    },
    EXPORT: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_EXPORT",
        name: "导出",
        type: "view",
        apiName: "exportReceipt",
        method: "POSTFILE",
        url: `${prevUrl}/exportReceipt`
    },
    // CANCEL_DATA: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_CANCEL_DATA',
    //     name: '取消确认',
    //     type: 'view',
    //     apiName: 'cancelReceiptDemand',
    //     method: 'GET',
    //     url: `${prevUrl}/cancel`
    // },
    SAVE_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_SAVE_DATA",
        name: "保存",
        type: "view"
    },
    IMPORT_MATERIAL: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_IMPORT_MATERIAL",
        name: "导入",
        type: "view"
    },
    EXPORT_MATERIAL: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_EXPORT_MATERIAL",
        name: "导出表头",
        type: "view"
    },
    ADD_MATERIAL: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_ADD_MATERIAL",
        name: "新建物料明细",
        type: "view"
    }
};
