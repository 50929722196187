const m = "client/transportAssessment";
export const id = {
    id: "RESOURCE_TRANSPORT_ASSESSMENT",
    method: "POST",
    name: "运输考核",
    type: "menu",
    apiName: "getTransportAssessmentList",
    url: `${m}/list`,
    sort: 11
};

export const children = {
    ADD_DATA: {
        id: "RESOURCE_TRANSPORT_ASSESSMENT_ADD_DATA",
        apiName: "onAddTransportAssessment",
        method: "POST",
        name: "新增",
        type: "view",
        url: `${m}/save`
    },
    EDIT_DATA: {
        id: "RESOURCE_TRANSPORT_ASSESSMENT_EDIT_DATA",
        apiName: "onEditTransportAssessment",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${m}/save`
    },
    DEL_DATA: {
        id: "RESOURCE_TRANSPORT_ASSESSMENT_DEL_DATA",
        apiName: "onDelTransportAssessment",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${m}/delete`
    },
    ON_REVIEW: {
        id: "RESOURCE_TRANSPORT_ASSESSMENT_ON_REVIEW",
        apiName: "onReviewTransportAssessment",
        method: "GET",
        name: "审核",
        type: "view",
        url: `${m}/approve`
    },
    LOOK_MORE: {
        id: "RESOURCE_TRANSPORT_ASSESSMENT_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
