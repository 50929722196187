const moduleName = "finance/specialExpense/";
export const id = {
    id: "SPECIAL_COST_MAINTENANCE_LIST",
    method: "POST",
    name: "特殊费用维护",
    type: "menu",
    apiName: "getSpecialExpenseList",
    url: `${moduleName}list`
};

export const children = {
    ADD_DATA: {
        id: "SPECIAL_COST_MAINTENANCE_CODE_ADD_DATA",
        apiName: "addSpecialExpense",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}add`
    },
    EDIT_DATA: {
        id: "SPECIAL_COST_MAINTENANCE_EDIT_DATA",
        apiName: "editSpecialExpense",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}edit`
    },
    DEL_DATA: {
        id: "SPECIAL_COST_MAINTENANCE_DEL_DATA",
        apiName: "delSpecialExpense",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}delete`
    },
    LOCK_ACTION: {
        id: "SPECIAL_COST_MAINTENANCE_LOCK_ACTION",
        apiName: "lockSpecialExpense",
        method: "POST",
        name: "锁定",
        type: "view",
        url: `${moduleName}lock`
    },
    CANCEL_LOCK: {
        id: "SPECIAL_COST_MAINTENANCE_CANCEL_LOCK",
        apiName: "cancelLockSpecialExpense",
        method: "POST",
        name: "取消锁定",
        type: "view",
        url: `${moduleName}cancelLock`
    }
    // LOOK_MORE: {
    //     id: 'LEGAL_PERSON_LIST_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
