export const id = {
    id: "RECEIVABLE_MANAGEMENT_RECEIVABLE_DETAILS",
    method: "POST",
    name: "应收对账明细",
    type: "menu_hide",
    sort: 2
    // apiName: 'accountPayableList',
    // url: `order/sendCar/list`
};

export const children = {
    SAVE: {
        id: "RECEIVABLE_MANAGEMENT_RECEIVABLE_DETAILS_SAVE",
        method: "POST",
        name: "保存",
        type: "view"
    }
};
