import { children, id } from "./power";

export default class AllocateApi {
    [id.apiName](params) {
        let url = id.url;
        return this.POST(url, params);
    }

    [children.DELETE.apiName](params) {
        let url = children.DELETE.url;
        return this.POST(url, params);
    }

    getClearanceLogs(params) {
        let url = `order/customs/getLog/${params.id}`;
        return this.GET(url);
    }

    getCustomsByOrderReceiverSenderId(params) {
        let url = `order/customs/customsByOrderReceiverSenderId/${params.id}`;
        return this.GET(url);
    }

    getDownloadLog(params) {
        let url = `order/customs/getDownloadLog/${params.id}`;
        return this.GET(url);
    }
}
