import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class IndexApi {
    /**
     * 获取回单管理列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.BATCH_DOWNLOAD.apiName](params) {
        //批量下载
        let url = children.BATCH_DOWNLOAD.url;
        return this.POSTFILE(url, params);
    }

    getOrderReceiver(params) {
        //获取收货方
        let url = `order/order/getReceiver`;
        return this.GET(url, params);
    }
    uploadByCustomerNumber(params) {
        //获取收货方
        let url = `order/order/uploadByCustomerNumber`;
        return this.POST(url, params);
    }

    receiptExport(params) {
        //导出回单列表订单数据
        let url = `order/order/exportReceiptListOrderData`;
        return this.POSTFILE(url, params);
    }
    receiptExport(params) {
        //导出回单列表订单数据
        let url = `order/order/exportReceiptListOrderData`;
        return this.POSTFILE(url, params);
    }
    receiptUploadConfirm(params) {
        let url = `order/order/batchReceiptConfirmByOrderNumber`;
        return this.POSTFILE(url, params);
    }
}
