/*
 * @Descripttion:
 * @Author: hl.huang
 * @Date: 2021-01-11 16:30:22
 * @LastEditors: 黄华林
 * @LastEditTime: 2021-01-15 09:24:04
 */
import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

const moduleName = "/finance/payableReconciliationMain/";
export default class PayableReconciliationApiClass {
    // 获取待生成对账单列表
    payable_getSettlement(params) {
        const url = `${moduleName}settlementList`;
        return this.POST(url, deleteNull(params));
    }
    // 获取已生成对账单
    payable_getGeneratedList(params) {
        const url = `${moduleName}list`;
        return this.POST(url, deleteNull(params));
    }
    // 生成对账单
    payable_postGenerateBill(params) {
        const url = `${moduleName}generation`;
        return this.POST(url, deleteNull(params));
    }
    // 快速请款单
    payable_fastGeneration(params) {
        const url = `${moduleName}fastGeneration`;
        return this.POST(url, deleteNull(params));
    }
    // 删除对账单
    payable_deleteData(params) {
        const url = `${moduleName}delete`;
        return this.POST(url, deleteNull(params));
    }
    // 编辑对账单
    payable_editData(params) {
        const url = `${moduleName}edit`;
        return this.POST(url, deleteNull(params));
    }
    // 获取单条应付对账单
    payableDetail_getOne(params) {
        return this.GET(`${moduleName}getOne?id=${params.id}`, params);
    }
    // 确认
    payable_commit(params) {
        return this.POST(`${moduleName}commit`, params);
    }
    // 撤销
    payable_Undo(params) {
        return this.POST(`${moduleName}undo`, params);
    }
    // 备注
    payable_remark(params) {
        return this.POST(`${moduleName}remark`, params);
    }
    // 上传对账单附件
    payable_upload(id, params) {
        return this.POST(`${moduleName}upload/${id}`, params);
    }
    // 获取快速生成对账单总金额
    payable_getTotalCost(params) {
        return this.POST(`${moduleName}getTotalCost`, params);
    }
    // 快速生成对账单
    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    payable_fastGeneration(params) {
        return this.POST(`${moduleName}fastGeneration`, params);
    }
    // 发送对账单
    payable_sendStatement(id) {
        return this.GET(`${moduleName}sendStatement/${id}`);
    }
    // 导出报表 /payableReconciliationMain/
    payable_batchExport(params) {
        return this.POSTFILE(`${moduleName}batchExport`, deleteNull(params));
    }
    // 批量发送对账单
    payable_batchSendStatement(ids) {
        return this.POST(`${moduleName}batchSendStatement/`, ids);
    }
}
