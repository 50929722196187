import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class PrimeCostApi {
    /**
     *
     * @param {any} params
     * @returns
     * @memberOf PrimeCostApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [power.SAVE_PAYMENT.apiName](params) {
        //保存补扣款
        let url = power.SAVE_PAYMENT.url;
        return this.POST(url, params);
    }

    [power.DEL_DATA.apiName](params) {
        //删除对账单
        let url = power.DEL_DATA.url + `/${params.id}`;
        return this.GET(url, params);
    }

    [power.DEL_PAYMENT.apiName](params) {
        //删除补扣款
        let url = power.DEL_PAYMENT.url + `/${params.id}`;
        return this.GET(url, params);
    }

    [power.DEL_DETAILS.apiName](params) {
        //删除对账单明细
        let url = power.DEL_DETAILS.url + `/${params.id}`;
        return this.GET(url, params);
    }

    getReconciliationDetail(params) {
        //获取对账单明细
        let url = `/wms/reconciliation/detail/${params.id}/${params.reconciliationType}`;
        return this.GET(url, params);
    }

    getOneReconciliation(params) {
        //获取单条对账单
        let url = `/wms/reconciliation/getOne/${params.id}`;
        return this.GET(url, params);
    }
}
