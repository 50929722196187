import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class ReceivableApi {
    /**
     *
     * @param {any} params
     * @returns
     * @memberOf ReceivableApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [power.PASS.apiName](params) {
        let url = power.PASS.url + `/${params.id}`;
        return this.GET(url, params);
    }

    [power.REJECT.apiName](params) {
        let url = power.REJECT.url + `/${params.id}`;
        return this.GET(url, params);
    }

    [power.EDIT_DATA.apiName](params) {
        let url = power.EDIT_DATA.url;
        return this.POST(url, params);
    }

    [power.DEL_DATA.apiName](params) {
        let url = power.DEL_DATA.url + `/${params.id}`;
        return this.GET(url, params);
    }

    [power.GENERATE_BILL.apiName](params) {
        let url = power.GENERATE_BILL.url;
        return this.POST(url, params);
    }

    getWhReceivableById(params) {
        //获取应收费用数据
        let url = `wms/receivableCost/${params.id}`;
        return this.GET(url, params);
    }

    getWhReceivableDetailsById(params) {
        //获取应收费用详情数据
        let url = `wms/receivableCost/cargoDetails/${params.id}`;
        return this.GET(url, params);
    }
}
