const moduleName = "wms/warehouse";
import getChildPowerJson from "../../../utils/getChildPowerJson";
import newPage from "../define_add/power_hide";
import mapPower from "../map/power_hide";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_DEFINE",
    method: "POST",
    name: "仓库定义",
    type: "menu",
    apiName: "getWarehouseList",
    url: `${moduleName}/list`
};

const nPage = getChildPowerJson.toJson(newPage);
const map = getChildPowerJson.toJson(mapPower);
export const children = {
    [nPage.id]: nPage,
    [map.id]: map,
    MAP: {
        id: "WAREHOUSE_MANAGEMENT_DEFINE_MAP",
        method: "GET",
        name: "仓位图",
        type: "view"
    },
    ADD_DATA: {
        id: "WAREHOUSE_MANAGEMENT_DEFINE_ADD_DATA",
        name: "新建",
        type: "view"
    },
    EDIT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_DEFINE_EDIT_DATA",
        apiName: "editWarehouse",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}/edit`
    },
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_DEFINE_DEL_DATA",
        apiName: "delWarehouse",
        method: "GET",
        name: "删除",
        type: "view",
        url: `${moduleName}/delete`
    }
};
