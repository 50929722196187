const m = "wms/shipmentDemand";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS",
    name: "出货需求明细",
    type: "menu_hide"
    // method: 'POST',
    // apiName: 'accountPayableList',
    // url: `order/sendCar/list`
};

export const children = {
    CONFIRM_DATA: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_CONFIRM_DATA",
        apiName: "confirmShipmentDemand",
        method: "GET",
        name: "确认",
        type: "view",
        url: `${m}/demandConfirm`
    },
    CANCEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_CANCEL_DATA",
        apiName: "cancelShipmentDemand",
        method: "GET",
        name: "取消确认",
        type: "view",
        url: `${m}/demandCancelConfirm`
    },
    FINISH: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_FINISH",
        apiName: "shipmentDemandFinish",
        method: "GET",
        name: "需求完结",
        type: "view",
        url: `${m}/demandEnd`
    },
    UN_FINISH: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_UN_FINISH",
        apiName: "shipmentDemandUnFinish",
        method: "GET",
        name: "取消完结",
        type: "view",
        url: `${m}/demandCancelEnd`
    },
    EDIT_BASEINFO: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_EDIT_BASEINFO",
        apiName: "shipmentDemandEditBaseInfo",
        method: "POST",
        name: "修改基本信息",
        type: "view",
        url: `${m}/editBaseInfo`
    },
    SAVE_RECEIPT: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_SAVE_RECEIPT",
        apiName: "shipmentDemandSaveShipmentItem",
        method: "POST",
        name: "创建出货单",
        type: "view",
        url: `${m}/saveShipmentItem`
    },
    EDIT_RECEIPT: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_EDIT_RECEIPT",
        apiName: "shipmentDemandEditShipmentItem",
        method: "POST",
        name: "编辑出货单基本信息",
        type: "view",
        url: `${m}/editManageBaseInfo`
    },
    DELETE_RECEIPT: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_DELETE_RECEIPT",
        apiName: "shipmentDemandDeleteShipmentItem",
        method: "GET",
        name: "删除出货单",
        type: "view",
        url: `${m}/deleteShipmentItem`
    },
    EDIT_DETAILS: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_EDIT_DETAILS",
        apiName: "shipmentDemandEditCargoDetails",
        method: "POST",
        name: "编辑出货单货物",
        type: "view",
        url: `${m}/editManageCargoDetails`
    },
    DELETE_DETAILS: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_DELETE_DETAILS",
        apiName: "shipmentDemandDeleteCargoDetails",
        method: "GET",
        name: "删除出货单货物",
        type: "view",
        url: `${m}/deleteShipmentCargoDetails`
    },
    IMPORT: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_IMPORT",
        apiName: "shipmentDemandImportMaterials",
        method: "POST",
        name: "导入",
        type: "view",
        url: `${m}/importMaterialForSave`
    },
    EXPORT_T: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_EXPORT_T",
        name: "导出表头",
        type: "view",
        apiName: "shipmentDemandExportTemp",
        method: "GETFILE",
        url: `${m}/exportTemp`
    }

    // GENERATE: {
    //     id: 'WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DETAILS_GENERATE',
    //     apiName: 'batchSaveShipmentItem',
    //     method: 'POST',
    //     name: '批量生成',
    //     type: 'view',
    //     url: `${m}/batchSaveShipmentItem`
    // }
};
