const m = "wms/report";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHEET_STOCK",
    name: "库存报表",
    type: "menu",
    apiName: "getReportInventoryList",
    method: "POST",
    url: `${m}/inventoryList`
};

export const children = {
    EXPORT: {
        id: "WAREHOUSE_MANAGEMENT_SHEET_STOCK_EXPORT",
        apiName: "exportInventoryData",
        method: "POSTFILE",
        name: "导出报表",
        type: "view",
        url: `wms/export/report/inventoryData`
    }
};
