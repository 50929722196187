import { Parent } from "@src/components/table_template";
import React, { Component } from "react";
class ModularParent extends (Parent || Component) {
    constructor(props) {
        super(props);
        if (props.getThis) {
            props.getThis(this);
        }
    }
}

export default ModularParent;
