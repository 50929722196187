import { children, id } from "./power";
import { deleteNull } from "@src/utils";
// 分配规则接口设置
export default class CarrierDistributionRulesApi {
    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.DELETE(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url;
        return this.PUT(url, params);
    }

    [children.ON_DISABLE.apiName](params) {
        let url = `${children.ON_DISABLE.url}/${params.id}`;
        return this.PUT(url, params);
    }

    [children.ON_ENABLE.apiName](params) {
        let url = children.ON_ENABLE.url + "/" + params.id;
        return this.PUT(url, params);
    }

    [children.LOOK_MORE.apiName](params) {
        let url = children.ON_ENABLE.url;
        return this.POST(url, deleteNull(params));
    }

    [children.LOOK_MORE.apiName](params) {
        let url = children.LOOK_MORE.url + "/" + params.id;
        return this.GET(url, deleteNull(params));
    }
}
