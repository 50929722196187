const md2 = "wms/reconciliation";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_ACCOUNT",
    method: "POST",
    name: "仓库成本对账",
    type: "menu",
    apiName: "getWhPrimeAccoutList",
    url: `${md2}/costList`
};

export const children = {
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_DEL_DATA",
        apiName: "deleteWmsAccount",
        method: "GET",
        name: "删除对账单",
        type: "view",
        url: `${md2}/delete`
    },
    CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_CONFIRM",
        //apiName: 'confirmWhReceivaccount',
        //method: 'POST',
        name: "对账单确认",
        type: "view"
        //url: `${m}/confirm`
    },
    CANCEL: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_CANCEL",
        // apiName: 'cancelWhReceivaccount',
        // method: 'POST',
        name: "取消对账确认",
        type: "view"
        //  url: `${m}/cancel`
    },
    CONFIRM_FINANCE: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_CONFIRM_FINANCE",
        // apiName: 'financeConfirmWhReceivaccount',
        // method: 'POST',
        name: "财务确认",
        type: "view"
        // url: `${m}/financeConfirm`
    },
    CANCEL_FINANCE: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_CANCEL_FINANCE",
        //apiName: 'financeCancelWhReceivaccount',
        // method: 'POST',
        name: "取消财务确认",
        type: "view"
        // url: `${m}/financeCancel`
    },
    ADD_PAYMENT: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_ADD_PAYMENT",
        // apiName: 'cancelWhPrimeCost',
        //method: 'GET',
        name: "新增补扣款",
        type: "view"
        // url: `${mdl}/cancel`
    },
    SAVE_PAYMENT: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_SAVE_PAYMENT",
        apiName: "editReplenishment",
        method: "POST",
        name: "保存补扣款",
        type: "view",
        url: `${md2}/editReplenishment`
    },
    EDIT_PAYMENT: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_EDIT_PAYMENT",
        name: "编辑补扣款",
        type: "view"
    },
    DEL_PAYMENT: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_DEL_PAYMENT",
        apiName: "deleteReplenishment",
        method: "GET",
        name: "删除补扣款",
        type: "view",
        url: `${md2}/deleteReplenishment`
    },
    DEL_DETAILS: {
        id: "WAREHOUSE_MANAGEMENT_ACCOUNT_DEL_DETAILS",
        apiName: "delReconciliationDetails",
        method: "GET",
        name: "删除对账明细",
        type: "view",
        url: `${md2}/deleteReconciliationDetail`
    }
    // LOOK_MORE: {
    //     id: 'WAREHOUSE_MANAGEMENT_ACCOUNT_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
