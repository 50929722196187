import curveLine from "./curve_line";
export function MP(ak) {
    // 加载百度SDK
    if (!global.BMapGL) {
        global.BMapGL = {};
        global.BMapGL._preloader = new Promise((resolve, reject) => {
            global._initBaiduMap = function () {
                resolve(global.BMapGL);
                global.document.body.removeChild($script);
                global.BMapGL._preloader = null;
                global._initBaiduMap = null;
            };
            const $script = document.createElement("script");
            global.document.body.appendChild($script);
            // $script.src = `//api.map.baidu.com/api?v=2.0&ak=${ak}&callback=_initBaiduMap`;
            $script.src = `//api.map.baidu.com/api?type=webgl&v=1.0&ak=${ak}&callback=_initBaiduMap`;
        });
        return global.BMapGL._preloader;
    } else if (!global.BMapGL._preloader) {
        return Promise.resolve(global.BMapGL);
    } else {
        return global.BMapGL._preloader;
    }
}

export const getCurveLine = curveLine;

export default MP("DSbseTSYLq0dwPl69EZZmjCyMgmr2rGi");

export function geocoder(val) {
    return new Promise((resolve, reject) => {
        const $script = document.createElement("script");
        global.document.body.appendChild($script);
        $script.src = `//api.map.baidu.com/geocoding/v3/?address=${val}&output=json&ak=I9v8LzcNNWH1Fbs5ePWrPRzGlQDqNlZw&callback=__baiduGeocoderCallback`;
        global.__baiduGeocoderCallback = function (res) {
            resolve(res);
            global.__baiduGeocoderCallback = null;
        };
        setTimeout(() => {
            resolve(null);
        }, 10000);
    });
}

export function reverseGeocoding(lnglat) {
    return new Promise((resolve, reject) => {
        const $script = document.createElement("script");
        global.document.body.appendChild($script);
        $script.src = `//api.map.baidu.com/reverse_geocoding/v3/?ak=I9v8LzcNNWH1Fbs5ePWrPRzGlQDqNlZw&output=json&coordtype=wgs84ll&location=${lnglat.lat},${lnglat.lng}&callback=__baiduReverGeocoderCallback`;
        global.__baiduReverGeocoderCallback = function (res) {
            resolve(res);
            global.__baiduReverGeocoderCallback = null;
        };
        setTimeout(() => {
            resolve(null);
        }, 10000);
    });
}
