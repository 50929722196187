const moduleName = "resource/";
export const id = {
    id: "RESOURCE_MANAGEMENT_OFFER_CARRIER",
    method: "POST",
    name: "承运商报价-旧",
    type: "menu_hide",
    apiName: "getOfferCarrier",
    url: `${moduleName}carrierQuotation/list`,
    sort: 9
};

export const children = {
    // GET_LIST: {
    //     id: 'RESOURCE_MANAGEMENT_OFFER_CARRIER_GET_LIST',
    //     apiName: 'getOfferCarrier',
    //     method: 'POST',
    //     name: '获取数据',
    //     type: 'view',
    //     url: `${moduleName}carrierQuotation/list`
    // },
    ADD_DATA: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_ADD_DATA",
        apiName: "addOfferCarrier",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}carrierQuotation/addTwo`
    },
    DEL_DATA: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_CODE",
        apiName: "delOfferCarrier",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}carrierQuotation/delete`
    },
    EDIT_DATA: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_EDIT_DATA",
        apiName: "editOfferCarrier",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}carrierQuotation/editTwo`
    },
    SALES_CARRIER_PASS: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_SALES_CARRIER_PASS",
        apiName: "offerExaminePass",
        method: "POST",
        name: "销售审核通过",
        type: "view",
        url: `${moduleName}carrierQuotation/pass`
    },
    // MARKET_CARRIER_PASS: {
    //     id: 'RESOURCE_MANAGEMENT_OFFER_CARRIER_MARKET_CARRIER_PASS',
    //     apiName: 'offerExaminePass',
    //     method: 'POST',
    //     name: '市场审核通过',
    //     type: 'view',
    //     url: `${moduleName}carrierQuotation/pass`
    // },
    CARRIER_CANCEL: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_CARRIER_CANCEL",
        apiName: "offerCancelExaminePass",
        method: "POST",
        name: "取消通过",
        type: "view",
        url: `${moduleName}carrierQuotation/cancel`
    },
    CARRIER_REJECT: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_CARRIER_REJECT",
        apiName: "offerExamineReject",
        method: "POST",
        name: "审核驳回",
        type: "view",
        url: `${moduleName}carrierQuotation/reject`
    },
    CARRIER_SUBMIT: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_CARRIER_SUBMIT",
        apiName: "offerSubmit",
        method: "POST",
        name: "提交",
        type: "view",
        url: `${moduleName}carrierQuotation/submit`
    },
    SUSPEND_STATUS: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_SUSPEND_STATUS",
        apiName: "offerSuspend",
        method: "GET",
        name: "禁用",
        type: "view",
        url: `${moduleName}carrierQuotation/suspend`
    },
    RECOVERY_STATUS: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_RECOVERY_STATUS",
        apiName: "offerRecevory",
        method: "GET",
        name: "恢复",
        type: "view",
        url: `${moduleName}carrierQuotation/recevory`
    },
    EXPORT_LIST: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_EXPORT_LIST",
        apiName: "exportCarrierQuotation",
        method: "POST",
        name: "导出",
        type: "view",
        url: `${moduleName}carrierQuotation/export`
    },
    QUOTATION_RELOAD: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_RELOAD",
        name: "承运商报价->刷新",
        type: "view"
    },
    QUOTATION_CLEAR: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_CLEAR",
        name: "承运商报价->批量删除",
        type: "view"
    },
    QUOTATION_ADD: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_ADD",
        name: "承运商报价->新增",
        type: "view"
    },
    QUOTATION_EXPORT: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_EXPORT",
        name: "承运商报价->导出",
        type: "view"
    },
    QUOTATION_EXPORT_DATA: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_EXPORT_DATA",
        name: "承运商报价->导出表头",
        type: "view"
    },
    QUOTATION_EXPORT_HEADER: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_EXPORT_HEADER",
        name: "承运商报价->导出表数据",
        type: "view"
    },
    QUOTATION_EXPORT_QUO: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_EXPORT_QUO",
        name: "承运商报价->导出报价单",
        type: "view"
    },
    QUOTATION_IMPORT: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_IMPORT",
        name: "承运商报价->导入",
        type: "view"
    },
    QUOTATION_EXPENSE: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_EXPENSE",
        name: "承运商报价->费用项",
        type: "view"
    },
    QUOTATION_EDIT: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_EDIT",
        name: "承运商报价->编辑",
        type: "view"
    },
    QUOTATION_DEL: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_QUOTATION_DEL",
        name: "承运商报价->删除",
        type: "view"
    },
    LOOK_MORE: {
        id: "RESOURCE_MANAGEMENT_OFFER_CARRIER_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
