const moduleName = "order/";
export const id = {
    id: "BUSINESS_MANAGEMENT_RECEIPT_LIST",
    method: "POST",
    name: "回单管理",
    type: "menu",
    apiName: "getReceiptList",
    url: `${moduleName}order/receiptListQuery`,
    sort: 5
};

export const children = {
    // ADD_DATA: {
    //     id: 'BUSINESS_MANAGEMENT_RECEIPT_LIST_ADD_DATA',
    //     //apiName: 'addCar',
    //     //method: 'POST',
    //     name: '保存',
    //     type: 'view',
    //    // url: `${moduleName}order/save`
    // },
    EDIT_DATA: {
        id: "BUSINESS_MANAGEMENT_RECEIPT_LIST_EDIT_DATA",
        //apiName: 'addCar',
        // method: 'POST',
        name: "回单编辑",
        type: "view"
        //url: `${moduleName}order/save`
    },
    DEL_DATA: {
        id: "BUSINESS_MANAGEMENT_RECEIPT_LIST_DEL_DATA",
        name: "回单删除",
        type: "view"
    },
    CONFIRM_DATA: {
        id: "BUSINESS_MANAGEMENT_RECEIPT_LIST_CONFIRM_DATA",
        name: "回单确认",
        type: "view"
    },
    EXPORT_LIST: {
        id: "BUSINESS_MANAGEMENT_RECEIPT_LIST_EXPORT_LIST",
        name: "导出",
        type: "view"
    },
    BATCH_DOWNLOAD: {
        id: "BUSINESS_MANAGEMENT_RECEIPT_LIST_BATCH_DOWNLOAD",
        apiName: "orderBatchDownload",
        method: "POSTFILE",
        name: "批量下载",
        type: "view",
        url: `${moduleName}order/batchDownload`
    },
    UPLOAD_RECEIPT: {
        id: "BUSINESS_MANAGEMENT_RECEIPT_LIST_UPLOAD_RECEIPT",
        name: "回单上传",
        type: "view"
    },
    UPLOAD_ORDER_CONFIRM: {
        id: "BUSINESS_MANAGEMENT_RECEIPT_LIST_UPLOAD_ORDER_CONFIRM",
        name: "导入发货单确认",
        type: "view"
    },
    LOOK_RECEIPT: {
        id: "BUSINESS_MANAGEMENT_RECEIPT_LIST_LOOK_RECEIPT",
        name: "查看回单",
        type: "view"
    }
};
