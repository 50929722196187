import React, { CSSProperties, forwardRef } from "react";
import { Icon, Spin, message } from "antd";
import moment from "moment";
import "./index.less";
import classNames from "classnames";
import { NodeInfo } from "@src/views/transport_management/allocate_edit/routeConfig";
import { isFullscreen, fullScreen, exitScreen, stringObjectObject, addressFormat } from "@src/utils";

import rApi from "@src/http";
import _ from "@src/utils/lodash";

export interface IProps {
    style?: Record<string, any>;
    domHeight: number;
    visible?: boolean;
    pathVisible?: boolean; // 运输路径
    columns?: {
        key: string;
        title: string;
        dealData?: (val: Record<string, any>, record?: any) => string | JSX.Element;
        render?: (val: Record<string, any>) => string | JSX.Element;
    }[]; // 信息渲染列
    data?: Record<string, any>[] | Record<string, any>;
    orderDetail?: Record<string, any>; // 发货单信息
    routeData?: NodeInfo[]; // 路由信息
    routeInfo?: Record<string, any>; // 从接口拉到的路由信息
    fromType: number; // 1. 配载中心 2 运单追踪 3 发货单追踪
    activeIdx?: number; // 卡片 active 索引
    waybillStatus?: number; // 运单状态
    mapRef: any;
    contentStyle?: CSSProperties; // 内容容器样式
    onVisibleChange: (visible: boolean) => void;
    onPathVisibleChange: (visible: boolean) => void;
    onCardClick?: (item: Record<string, any>, idx: number) => void; // res 为接口返回数据 用于发货单判断是否配了路径
    onRouteConfig?: () => void;
    onFullScreen?: (isFullScreen: boolean, mapId: string) => void;
    onRecoveryOrigin?: () => void; // 恢复原点对焦
    routeRenderList?: orderRenderList[];

    hideFooter?: boolean; // 隐藏底部统计数据
    hideOrderPath?: boolean; // 隐藏运单详情路线
    awesomeCardTitle?: string; // 右侧显示栏的标题
    cusTitleLeft?: (data?) => string | JSX.Element;
}

type orderRenderList = {
    id: string;
    title: string;
    dateTime: moment.Moment;
    timeHide?: boolean;
    path?: string;
    requestAddress?: string;
    address?: string;
    source?: number | string;
};

interface IState {
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: Record<string, string | number | object>;
    orderPathList: NodeInfo[]; // 发货单 路由路径
    orderRenderList: orderRenderList[];
}

const titleTable = {
    "1": "相关运单",
    "2": "调度信息",
    "3": "发货单分段"
};

const getStatus = (status) =>
    ({
        "1": "待确认",
        "2": "待发运",
        "3": "运输中",
        "4": "已签收"
    }[status]);

const getColor = (status) =>
    ({
        "1": "#f64d3f",
        "2": "#ea8f2a",
        "3": "#5496ad",
        "4": "rgb(79,155,51)"
    }[status]);

const getSource = (status) =>
    ({
        "1": {
            name: "A",
            detail: "平台端手动编辑"
        },
        "2": {
            name: "B",
            detail: "小程序手动编辑"
        },
        "3": {
            name: "C",
            detail: "小程序扫码定位"
        },
        "4": {
            name: "D",
            detail: "GPS设备接口"
        },
        "5": {
            name: "E",
            detail: "承运商可通接口"
        },
        "6": {
            name: "F",
            detail: "EXCEL导入" // 包含发货单、运单；平台端、承运商端
        }
    }[status]);

export default class AwesomeCard extends React.Component<IProps, IState> {
    // 全屏的地图id
    fullScreenMapId: string = null;

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            orderPathList: null,
            orderRenderList: null
        };
    }

    componentDidMount() {
        document.addEventListener("fullscreenchange", this.onFullScreenChange);
    }

    componentWillUnmount() {
        document.removeEventListener("fullscreenchange", this.onFullScreenChange);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.activeIdx !== this.props.activeIdx && nextProps.activeIdx === null) {
            this.setState({ orderRenderList: null });
        }
    }

    onCardVisible = () => {
        const { onVisibleChange } = this.props;
        onVisibleChange(!this.props.visible);
    };

    onPathVisible = () => {
        const { onPathVisibleChange } = this.props;
        onPathVisibleChange(!this.props.pathVisible);
    };

    onCardClick = (item, idx) => {
        const { onCardClick, fromType, activeIdx } = this.props;
        onCardClick(item, idx);
        if (fromType === 3 && activeIdx !== idx) {
            this.getStowagePath(item, idx);
        }
    };

    // 运单 若为多提 / 多送 会出现重复的运单id
    filterData(data) {
        return data.reduce((t, c) => {
            if (!t.find((el) => el.id === c.id)) {
                t.push(c);
            }
            return t;
        }, []);
    }

    // 渲染入口为配载中心的内容
    renderStowageContent = () => {
        let { data, activeIdx, orderDetail } = this.props;
        if (data === null) {
            return (
                <div className="awesome_card_content">
                    <Spin spinning={data === null}></Spin>
                </div>
            );
        }
        if (!data.length) {
            return (
                <div className="awesome_card_content">
                    <div className="not_found">暂无运单</div>
                </div>
            );
        }
        const { contentStyle } = this.props;
        data = this.filterData(data);
        return (
            <div className={classNames("awesome_card_content")} style={contentStyle ? { ...contentStyle } : {}}>
                {data.map((item, idx) => (
                    <div
                        className={classNames("stowage_item", { active: activeIdx === idx })}
                        key={item.id}
                        onClick={() => this.onCardClick(item, idx)}
                    >
                        <div className="stowage_item_title">
                            <span>{item.stowageNumber}</span>
                            <span className="status" style={{ color: getColor(item.status) }}>
                                {getStatus(item.status)}
                            </span>
                        </div>
                        <div className="stowage_item_info">
                            <span className="left">承运商：</span>
                            <span className="right">{item.carrierName}</span>
                        </div>
                        <div className="stowage_item_info">
                            <span className="left">结算方式：</span>
                            <span className="right">{orderDetail.billingMethodName}</span>
                        </div>
                        <div className="stowage_item_info">
                            <span className="left">车牌号：</span>
                            <span className="right">{item.carCode}</span>
                        </div>
                        {/* <div className="stowage_item_info">
                            <span className="left">型号规格：</span>
                            <span className="right">{item.specificationModel}</span>
                        </div> */}
                        <div className="stowage_item_info">
                            <span className="left">运送时间：</span>
                            <span className="right">
                                {item.departureTime ? moment(item.departureTime).format("YYYY-MM-DD HH:mm") : ""}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    // 渲染运单内容
    renderWaybillContent = () => {
        let { columns, data, routeInfo } = this.props;
        if (routeInfo) {
            data = routeInfo;
        }
        if (data === null || data === undefined) {
            return (
                <div className="awesome_card_content">
                    <Spin spinning={data === null || data === undefined}></Spin>
                </div>
            );
        }

        return (
            <div className="awesome_card_content">
                {columns.map((item) => (
                    <div className="item" key={item.key}>
                        <span className="normal" style={{ flexShrink: 0 }}>
                            {item.title}：
                        </span>
                        <span
                            className="normal white text-overflow-ellipsis"
                            style={{ textAlign: "right" }}
                            title={item.dealData ? item.dealData(data[item.key], data) : data[item.key] || "-"}
                        >
                            {item.dealData ? item.dealData(data[item.key], data) : data[item.key] || "-"}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    // 渲染路由内容
    renderRouterContent = () => {
        const { routeData, onRouteConfig, routeRenderList, waybillStatus, domHeight } = this.props;
        if (routeData && routeData.length) {
            let lastTime;
            routeRenderList.map((item, idx) => {
                if (!lastTime) {
                    lastTime = item.dateTime;
                    return;
                }

                if (moment(item.dateTime).isSame(lastTime, "day")) {
                    routeRenderList[idx - 1].timeHide = true;
                } else {
                    routeRenderList[idx - 1].timeHide = false;
                }
                lastTime = item.dateTime;
            });
        }
        return (
            <div className="awesome_card" style={{ marginTop: 10 }}>
                <div className="awesome_card_header">
                    <div className="left">
                        <div className="angle"></div>
                        <div className="title">路由信息</div>
                    </div>
                    {waybillStatus === 3 || waybillStatus === 4 ? (
                        <div className="right" onClick={onRouteConfig}>
                            <div className="angle"></div>
                            <div className="title">路由配置</div>
                            <div className="route_blank"></div>
                        </div>
                    ) : (
                        <div className="right">
                            <div className="angle"></div>
                            <div className="blank"></div>
                        </div>
                    )}
                </div>
                <div
                    className={classNames("awesome_card_content", {
                        awesome_card_route: routeData && routeData.length >= 1
                    })}
                    style={{
                        maxHeight: isFullscreen() ? `calc(${domHeight}px - 400px)` : `calc(${domHeight}px - 515px)`
                    }}
                >
                    <Spin spinning={routeData === null}>
                        {routeData === null ? null : !routeData.length ? (
                            <div className="not_found">暂无路由信息</div>
                        ) : (
                            <>
                                {routeRenderList.map((item) => (
                                    <div className="route_item" key={item.id}>
                                        <span style={{ width: 73 }}>
                                            {item.timeHide ? "" : moment(item.dateTime).format("YYYY-MM-DD")}
                                        </span>
                                        <span className="point"></span>
                                        <div className="right">
                                            <div>
                                                <span>{moment(item.dateTime).format("HH:mm:ss")} &nbsp; </span>
                                                <span>{item.requestAddress}</span>
                                                <span>{item.title}</span>
                                            </div>
                                            <div className="route_address" title={item.path}>
                                                {getSource(item?.source)?.name ?? ""} {item.path}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </Spin>
                </div>
                <div className="awesome_card_bottom">
                    <div className="blank"></div>
                </div>
            </div>
        );
    };

    // 渲染路由内容
    renderRouterPath = () => {
        const { routeData, pathVisible, domHeight } = this.props;
        let { routeRenderList } = this.props;
        if (routeRenderList) {
            routeRenderList = routeRenderList
                .filter((item) => item.path)
                .sort((a, b) => (a.dateTime < b.dateTime ? -1 : 1));
        }
        return (
            <div
                className={classNames("way_path", "awesome_card")}
                style={{
                    marginTop: 10,
                    position: "absolute",
                    left: 10,
                    top: 40,
                    transform: `translateX(${pathVisible ? 0 : -300}px)`
                }}
            >
                <div className="awesome_card_header">
                    <div className="left">
                        <div className="angle"></div>
                        <div className="title">运输路径</div>
                    </div>
                    <div className="right">
                        <div className="angle"></div>
                        <div className="blank"></div>
                    </div>
                </div>
                <div
                    className={classNames("awesome_card_content", {
                        awesome_card_path: routeData && routeData.length >= 1
                    })}
                    style={{
                        maxHeight: isFullscreen() ? `calc(${domHeight}px - 200px)` : `calc(${domHeight}px - 320px)`
                    }}
                >
                    <Spin spinning={routeData === null}>
                        {routeData === null ? null : !routeData.length ? (
                            <div className="not_found">暂无运输路径</div>
                        ) : (
                            <>
                                {routeRenderList.map((item, idx) => (
                                    <React.Fragment key={item.id}>
                                        {item.path ? (
                                            <div className="path_item">
                                                <span className="point">{idx + 1}</span>
                                                <div className="text">
                                                    <span className="address_path" title={item.path}>
                                                        {getSource(item?.source)?.name ?? ""} {item.path}
                                                    </span>
                                                    <div className="triangle"></div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </Spin>
                </div>
                <div className="awesome_card_bottom">
                    <div className="blank"></div>
                </div>
                <div className="awesome_card_show" onClick={this.onPathVisible}>
                    <Icon type={pathVisible ? "left" : "right"} style={{ fontSize: 18, color: "#fff" }}></Icon>
                </div>
            </div>
        );
    };

    // 渲染发货单 底部
    renderFooter() {
        let { data } = this.props;
        let { orderDetail } = this.props;

        data && (data = data.filter((el) => el.destination.transit !== true));

        const quantity = data ? data.reduce((t, c) => t + c.totalQuantity, 0) : 0;
        const grossWeight = data ? data.reduce((t, c) => t + c.totalGrossWeight, 0) : 0;
        const volume = data ? data.reduce((t, c) => t + c.totalVolume, 0) : 0;

        orderDetail === null && (orderDetail = { totalQuantity: 0, totalGrossWeight: 0, totalVolume: 0 });

        return (
            <div className="map_footer">
                <div className="item">
                    <div className="normal">件数</div>
                    <div className="number">
                        {quantity}/{orderDetail.totalQuantity}
                    </div>
                </div>
                <div className="item">
                    <div className="normal">重量</div>
                    <div className="number">
                        {grossWeight}/{orderDetail.totalGrossWeight}kg
                    </div>
                </div>
                <div className="item">
                    <div className="normal">体积</div>
                    <div className="number">
                        {volume}/{orderDetail.totalVolume}m³
                    </div>
                </div>
            </div>
        );
    }

    // 获取运单路由列表
    getStowagePath(item, idx) {
        const { orderDetail } = this.props;
        rApi["getStowageList"]({ orderId: orderDetail.id, stowageId: item.id })
            .then((res) => {
                const orderPathList = this.stowageToNodeInfo(res);
                const orderRenderList = this.dealRouteData(orderPathList);
                this.setState({ orderPathList, orderRenderList });
            })
            .catch((err) => {
                console.log(err);
                message.warn("获取路由列表错误，请联系管理员");
            });
    }

    // 路由信息 接口返回的数据转 NodeInfo
    stowageToNodeInfo(data) {
        const result: NodeInfo[] = [];
        data.map((item) => {
            result.push({
                id: item.id,
                source: item.source,
                type: item.nodeType,
                address: item.nodeType === 3 ? null : { orderId: item.orderId, title: item.address },
                addressString: item.nodeType === 3 ? item.address : null,
                startDate: moment(item.arriveTime),
                endDate: moment(item.leaveTime),
                lngLat: [item.longitude, item.latitude],
                status: "complete",
                dateDisable: null
            });
        });
        return result;
    }

    fullscreenHandle = () => {
        const { mapRef } = this.props;

        if (mapRef) {
            if (isFullscreen()) {
                exitScreen();
                setTimeout(() => {
                    this.fullScreenMapId = null;
                }, 500);
            } else {
                this.fullScreenMapId = mapRef.id;
                fullScreen(mapRef.parentElement);
            }
        }
    };

    onFullScreenChange = (e) => {
        const { onFullScreen } = this.props;
        const {
            target: { firstChild }
        } = e;
        const id = firstChild.id;
        onFullScreen(isFullscreen(), id);
    };

    // 处理路由数据-用于展示
    // TODO: routeData 中的 item 包含了 startDate 跟 endData 两个时间点
    // 途径（临时路由） 只存在 endData 一个时间点
    // NodeInfo 转 render路由数据结构
    dealRouteData(orderPathList: NodeInfo[]) {
        const { fromType } = this.props;
        let result = [];
        if (fromType === 3) {
            let tempRoute = _.cloneDeep(orderPathList);
            tempRoute = tempRoute.sort((a, b) => (a.endDate < b.endDate ? 1 : -1));
            tempRoute.map((item, idx) => {
                const title =
                    item.type === 1 ? ["到达提货地", "已提货"] : item.type === 2 ? ["到达卸货地", "已卸货"] : ["途径"];
                let path = item.address ? item.address.requestAddress : item.addressString;
                if (!path) {
                    path = addressFormat(item.address.title);
                }
                if (item.type === 3) {
                    result.push(
                        ...[{ id: `${idx}-end`, title: title[0], dateTime: item.endDate, path, source: item.source }]
                    );
                } else {
                    result.push(
                        ...[
                            { id: `${idx}-end`, title: title[1], dateTime: item.endDate, path, source: item.source },
                            { id: `${idx}-start`, title: title[0], dateTime: item.startDate, source: item.source }
                        ]
                    );
                }
            });
            return result;
        }
        return null;
    }

    // 渲染发货单 路径
    renderOrderPath = () => {
        const { activeIdx, pathVisible, domHeight } = this.props;
        const { orderRenderList } = this.state;
        if (orderRenderList && orderRenderList.length) {
            let lastTime;
            orderRenderList.map((item, idx) => {
                if (!lastTime) {
                    lastTime = item.dateTime;
                    return;
                }

                if (moment(item.dateTime).isSame(lastTime, "day")) {
                    orderRenderList[idx - 1].timeHide = true;
                } else {
                    orderRenderList[idx - 1].timeHide = false;
                }
                lastTime = item.dateTime;
            });
        }
        if (!orderRenderList && activeIdx === null) return null;
        return (
            <div
                className={classNames("way_path", "awesome_card")}
                style={{
                    position: "absolute",
                    left: 10,
                    top: 50,
                    transform: `translateX(${pathVisible ? 0 : -300}px)`
                }}
            >
                <div className="awesome_card_header">
                    <div className="left">
                        <div className="angle"></div>
                        <div className="title">路由信息</div>
                    </div>
                    <div className="right">
                        <div className="angle"></div>
                        <div className="blank"></div>
                    </div>
                </div>
                <div
                    className={classNames("awesome_card_content", {
                        awesome_card_route: orderRenderList && orderRenderList.length >= 1
                    })}
                    style={{
                        maxHeight: isFullscreen() ? `calc(${domHeight}px - 200px)` : `calc(${domHeight}px - 320px)`
                    }}
                >
                    <Spin spinning={orderRenderList === null}>
                        {orderRenderList === null ? null : !orderRenderList.length ? (
                            <div className="not_found">暂无路由信息</div>
                        ) : (
                            <>
                                {orderRenderList.map((item) => (
                                    <div className="route_item" key={item.id}>
                                        <span style={{ width: 73 }}>
                                            {item.timeHide ? "" : moment(item.dateTime).format("YYYY-MM-DD")}
                                        </span>
                                        <span className="point"></span>
                                        <div className="right">
                                            <div>
                                                <span>{moment(item.dateTime).format("HH:mm:ss")} &nbsp; </span>
                                                <span>{item.requestAddress}</span>
                                                <span>{item.title}</span>
                                            </div>
                                            <div
                                                className="route_address"
                                                title={`
                                                   ${getSource(item?.source)?.name ?? ""} ${
                                                    typeof item.path === "string" && item.path.startsWith("{")
                                                        ? stringObjectObject(item.path).formatAddress
                                                        : item.path
                                                }
                                                   `}
                                            >
                                                {getSource(item?.source)?.name ?? ""}
                                                {typeof item.path === "string" && item.path.startsWith("{")
                                                    ? stringObjectObject(item.path).formatAddress
                                                    : item.path}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </Spin>
                </div>
                <div className="awesome_card_bottom">
                    <div className="blank"></div>
                </div>
                <div className="awesome_card_show" onClick={this.onPathVisible}>
                    <Icon type={pathVisible ? "left" : "right"} style={{ fontSize: 18, color: "#fff" }}></Icon>
                </div>
            </div>
        );
    };

    render() {
        const { style, visible, fromType, routeInfo, onRecoveryOrigin } = this.props;
        let { data, hideFooter, hideOrderPath, awesomeCardTitle, cusTitleLeft } = this.props;

        if (routeInfo) {
            data = routeInfo;
        }

        return (
            <>
                {fromType !== 4 && (
                    <div className="awesome_header">
                        <div className="header_left">{cusTitleLeft?.(data)}</div>
                        <div className="btns">
                            <Icon
                                type="undo"
                                style={{ color: "#fff", fontSize: 20, marginRight: 10 }}
                                title="恢复原点"
                                onClick={onRecoveryOrigin}
                            />
                            {isFullscreen() ? (
                                <Icon
                                    type="shrink"
                                    style={{ color: "#fff", fontSize: 20 }}
                                    title="取消全屏"
                                    onClick={this.fullscreenHandle}
                                />
                            ) : (
                                <Icon
                                    type="arrows-alt"
                                    style={{ color: "#fff", fontSize: 20 }}
                                    title="全屏"
                                    onClick={this.fullscreenHandle}
                                />
                            )}
                        </div>
                    </div>
                )}

                <div className="awesome" style={{ ...style, transform: visible ? `translateX(0px)` : "", zIndex: 10 }}>
                    {fromType !== 4 && (
                        <div className="awesome_card">
                            <div className="awesome_card_header">
                                <div className="left">
                                    <div className="angle"></div>
                                    <div className="title">{awesomeCardTitle || titleTable[fromType] || ""}</div>
                                </div>
                                <div className="right">
                                    <div className="angle"></div>
                                    <div className="blank"></div>
                                </div>
                            </div>
                            {data === null ? (
                                <div className="awesome_card_content">
                                    <Spin spinning={data === null}></Spin>
                                </div>
                            ) : fromType === 1 || fromType === 3 ? (
                                this.renderStowageContent()
                            ) : fromType === 2 ? (
                                this.renderWaybillContent()
                            ) : null}
                            <div className="awesome_card_bottom">
                                <div className="blank"></div>
                            </div>
                            <div className="awesome_card_show" onClick={this.onCardVisible}>
                                <Icon type={visible ? "right" : "left"} style={{ fontSize: 18, color: "#fff" }}></Icon>
                            </div>
                        </div>
                    )}
                    {fromType === 2 && this.renderRouterContent()}
                </div>
                {fromType === 2 && this.renderRouterPath()}
                {fromType === 3 && !hideFooter && this.renderFooter()}
                {fromType === 3 && !hideOrderPath && this.renderOrderPath()}
            </>
        );
    }
}
