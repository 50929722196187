import detailView from "./detail/power_hide";
import getChildPowerJson, { toJson } from "../../../utils/getPowerJson";
// console.log('detailView', detailView)
const moduleName = "finance/profit/";
export const id = {
    id: "FINANCIAL_MANAGEMENT_REALTIME_PROFIT",
    name: "实时毛利",
    type: "menu"
};

const page = toJson(detailView);

export const children = {
    [page.id]: page
};
