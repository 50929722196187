import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class ReportReceiptApi {
    /**
     * @param {any} params
     * @returns
     * @memberOf ReportReceiptApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.EXPORT.apiName](params) {
        let url = children.EXPORT.url;
        return this.POSTFILE(url, deleteNull(params));
    }

    getReceiptWarehouse(params) {
        // 获取收货需求仓库
        let url = `wms/report/getReceiptWarehouse`;
        return this.GET(url, params);
    }

    getReceiptClient(params) {
        // 获取收货需求客户
        let url = `wms/report/getReceiptClient`;
        return this.GET(url, params);
    }

    // 获取汇总数据
    getReceiptReportSummary(params) {
        let url = `wms/report/receiptListCount`;
        return this.POST(url, params);
    }
}
