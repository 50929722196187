const m = "wms/wave";
const m2 = "wms/shipment";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE",
    name: "波次计划",
    type: "menu",
    method: "POST",
    apiName: "getShipmentWaveList",
    url: `${m}/list`
};

export const children = {
    ASSIGN_PICK: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_ASSIGN_PICK",
        name: "指派拣货人",
        type: "view",
        apiName: "assignShipmentWavePick",
        method: "POST",
        url: `${m}/assign`
    },
    DEL: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DEL",
        name: "删除",
        type: "view",
        method: "POST",
        apiName: "deleteShipmentWave",
        url: `${m}/delete`
    }
};
