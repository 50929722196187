import React, { useState, useRef } from "react";
import { STATUS, STATUS_COLOR } from "../list/def";
import {
    Tag,
    Form,
    Radio,
    Select,
    DatePicker,
    Popconfirm,
    Input,
    Icon,
    Button,
    InputNumber,
    Modal,
    Switch,
    Table,
    message,
    Dropdown,
    Menu
} from "antd";
import _ from "@src/utils/lodash";
import { TitleSplit } from "@src/components/details_temp";
import { HeaderBox } from "@src/components/details_temp";
import { Row, Col } from "@src/components/grid";
import { configBox, FlowLayout } from "@src/components/box";
import RemoteSelect from "@src/components/select_databook";
import SelectMulti from "@src/components/select_multi";
import "./base.less";
import { NP as NPLib, addressToString } from "@src/utils";
import moment from "moment";
// 发货方编辑
import Consigner from "@src/views/client/consigner/addoredit";
// 收货方编辑
import Consignee from "@src/views/client/consignee/addoredit";

import rApi from "@src/http";

const ConsignerEdit: any = Consigner;
const ConsigneeEdit: any = Consignee;
const unloadList = [
    {
        text: "送货方卸货",
        value: "送货方卸货"
    },
    {
        text: "接货方卸货",
        value: "接货方卸货"
    }
];
const arriveList = [
    {
        text: "到门",
        value: "到门"
    },
    {
        text: "自提",
        value: "自提"
    }
];

const dropdownRenderTitleStyle = {
    flexShrink: 0,
    padding: 5,
    borderBottom: "1px solid #eee",
    borderLeft: "1px solid #eee",
    TextAlign: "center",
    overflow: "hidden"
    // boxSizing: "border-box"
};
const textHiddenStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    WhiteSpace: "nowrap"
};
const getAddress = (address) => {
    // console.log('address', address)
    if (address && address.formatAddress) {
        return address.formatAddress;
    }
    try {
        if (_.isString(address)) {
            return JSON.parse(address).formatAddress;
        }
        return "";
    } catch (e) {
        console.error("e", e);
        return "";
    }
};
// 任务 1467  切换发运方式 需修改或者屏蔽时效要求,是否卸货,是否到门
interface switchBillingData {
    agingDis: boolean;
    unloadDis: boolean;
    arriveDis: boolean;
    agingId: number;
    agingName: string;
    unload: string;
    arrive: string;
}
const switchBilling = (billingMethodName, initState): switchBillingData => {
    let { agingDis, agingId, agingName, unloadDis, unload, arriveDis, arrive } = Object.assign(
        {
            agingDis: false,
            agingId: null,
            agingName: null,
            unloadDis: false,
            unload: null,
            arriveDis: false,
            arrive: null
        },
        initState
    );

    switch (billingMethodName) {
        case "委托自提":
            unload = "接货方卸货";
            unloadDis = true;
            arrive = "自提";
            arriveDis = true;

            agingName = "标准";
            agingId = 1807;
            agingDis = true;
            break;
        case "指定专线":
            unload = "接货方卸货";
            unloadDis = true;
            arrive = "自提";
            arriveDis = true;

            agingDis = false;
            break;
        // case "物流白云月结":
        // case "白云月结":
        // case "客户月结":
        // case "到付":
        default:
            agingDis = false;
            unloadDis = false;
            arriveDis = false;
            break;
    }
    return {
        agingDis,
        unloadDis,
        arriveDis,
        agingId,
        agingName,
        unload,
        arrive
    };
};

const { Option } = Select;
const NP = new NPLib();
const labelStyle = {
    width: 86,
    textAlign: "right"
};
const transit = (val) => (val === null || isNaN(val) ? 0 : val);
// tslint:disable-next-line:variable-name
export const TitleView = ({ pageData }) => (
    <div className="flex" style={{ fontSize: "18px" }}>
        {/* <div style={{ fontWeight: 'bold' }}>{!pageData.orderNumber ? '在线下单' : '发货单明细'}</div> */}
        {!!pageData.orderNumber && (
            <div className="flex">
                <div>
                    <Tag color={STATUS_COLOR[pageData.status]}>{STATUS[pageData.status]}</Tag>
                </div>
                <div
                    style={{
                        color: window["_global"]._theme.global_primary_color
                    }}
                >
                    &emsp;
                    {pageData.orderNumber}
                </div>
                <div className="flex1"></div>
            </div>
        )}
    </div>
);
// ConsigneeOrConsigner
const CeeOrCer = ({ type, onChange, value: propsValue, readOnly, clientId }) => {
    const ceeAddEditView = useRef(null);
    const cerAddEditView = useRef(null);
    const isCee = type === "cee";
    const cargoName = isCee ? "收货方" : "发货方";
    const api = isCee ? "getConsignees" : "getConsigners";

    const concatName = propsValue?.[isCee ? "receiverPhoneNumber" : "senderPhoneNumber"];
    const phone = propsValue?.[isCee ? "receiverContact" : "senderContact"];
    const detailAddress = propsValue?.[isCee ? "receiverAddress" : "senderAddress"]?.formatAddress;
    const nameAndPhone =
        !concatName && !phone ? "联系人/联系电话" : `${concatName ? concatName : "无"}|${phone ? phone : "无"}`;
    const idKey = isCee ? "receiverId" : "senderId";
    const nameKey = isCee ? "receiverName" : "senderName";
    const fillSelectValue =
        propsValue?.[idKey] && propsValue?.[nameKey]
            ? {
                  id: propsValue[idKey],
                  name: propsValue[nameKey]
              }
            : null;

    // console.log("nameAndPhone", concatName, phone);
    const showModal = ({ key }) => {
        let detailInfo = null;
        if (key === "1" || key === "2") {
            if (!propsValue) {
                message.warn(`${cargoName}数据为空,无法执行操作`);
                return;
            }
            detailInfo = {
                id: isCee ? propsValue.receiverId : propsValue.senderId,
                edit: key === "1",
                openType: Number(key),
                title: key === "1" ? `编辑${cargoName}` : key === "2" ? `查看${cargoName}` : `新增${cargoName}`
            };
        }
        if (!isCee && cerAddEditView.current) {
            // 发货方
            cerAddEditView.current.show({
                detailInfo
            });
        } else if (isCee && ceeAddEditView.current) {
            // 收货方
            ceeAddEditView.current.show({
                detailInfo,
                editSuccess: () => {}
            });
        }
    };
    const modalCancel = () => {};
    const content = (
        <Menu onClick={showModal}>
            <Menu.Item key={1} disabled={readOnly}>
                编辑货方
            </Menu.Item>
            <Menu.Item key={2} disabled={readOnly}>
                查看货方
            </Menu.Item>
            <Menu.Item key={3} disabled={readOnly}>
                新增货方
            </Menu.Item>
        </Menu>
    );
    return (
        <div>
            {/* 发货方 */}
            <ConsignerEdit
                getThis={(v) => {
                    cerAddEditView.current = v;
                }}
                modalCancel={modalCancel}
                thirdEditSuccess={(data) => {
                    onChange &&
                        onChange({
                            name: data?.name,
                            receiverOrSenderId: data?.receiverOrSenderId,
                            concatName: data?.contactName,
                            phone: data?.contactNumber,
                            address: {
                                pro: data?.pro,
                                city: data?.city,
                                dist: data?.dist,
                                street: data?.street,
                                extra: data?.extra,
                                formatAddress: data?.address
                            },
                            origin: data
                        });
                }}
            />
            {/* 收货方 */}
            <ConsigneeEdit
                getThis={(v) => {
                    ceeAddEditView.current = v;
                }}
                modalCancel={modalCancel}
                thirdEditSuccess={(data) => {
                    onChange &&
                        onChange({
                            name: data?.name,
                            receiverOrSenderId: data?.receiverOrSenderId,
                            concatName: data?.contactName,
                            phone: data?.contactNumber,
                            address: {
                                pro: data?.pro,
                                city: data?.city,
                                dist: data?.dist,
                                street: data?.street,
                                extra: data?.extra,
                                formatAddress: data?.address
                            },
                            origin: data
                        });
                }}
            />
            <div className="flex flex-vertical-center">
                <div className="flex1" style={{ marginRight: 26, maxWidth: "60%" }}>
                    <RemoteSelect
                        value={fillSelectValue}
                        onChangeValue={(value) => {
                            const originObj = value?.origin_data?.[0];
                            const _concatName = originObj?.contactName;
                            const _phone = originObj?.contactNumber;
                            const _detailAddress = addressToString(originObj);
                            // setConcatName(_concatName);
                            // setPhone(_phone);
                            // setDetailAddress(_detailAddress);
                            onChange &&
                                onChange({
                                    name: originObj?.name,
                                    receiverOrSenderId: originObj?.receiverOrSenderId,
                                    concatName: _concatName,
                                    phone: _phone,
                                    address: {
                                        pro: originObj?.pro,
                                        city: originObj?.city,
                                        dist: originObj?.dist,
                                        street: originObj?.street,
                                        extra: originObj?.extra,
                                        formatAddress: _detailAddress
                                    },
                                    origin: originObj
                                });
                        }}
                        disabled={readOnly}
                        placeholder={cargoName}
                        getDataMethod={api}
                        params={{ offset: 0, limit: 20, clientId }}
                        dataKey={"records"}
                        labelField="name"
                        isAsyncSearch
                        noInitChange
                        dropdownRender={(menu) => (
                            <div style={{ padding: "0 5px" }}>
                                <div
                                    onMouseDown={(e) => {
                                        // const dom = e.target
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="flex" style={{ padding: "0 12px", backgroundColor: "#f7f7f7" }}>
                                        <div style={{ ...dropdownRenderTitleStyle, width: 150 }}>货方名称</div>
                                        <div style={{ ...dropdownRenderTitleStyle, width: 80 }}>联系人</div>
                                        <div style={{ ...dropdownRenderTitleStyle, width: 100 }}>联系电话</div>
                                        <div style={{ ...dropdownRenderTitleStyle, width: 300 }}>详细地址</div>
                                    </div>
                                </div>
                                {menu}
                            </div>
                        )}
                        renderItemContent={(optionItem) => (
                            <div className="flex" style={{ height: "100%" }}>
                                <div
                                    style={{
                                        ...dropdownRenderTitleStyle,
                                        width: 150,
                                        padding: "0 5px"
                                    }}
                                >
                                    {optionItem.name}
                                </div>
                                <div style={{ ...dropdownRenderTitleStyle, width: 80, padding: "0 5px" }}>
                                    {optionItem.contactName}
                                </div>
                                <div
                                    style={{
                                        ...dropdownRenderTitleStyle,
                                        width: 100,
                                        padding: "0 5px"
                                    }}
                                >
                                    {optionItem.contactNumber}
                                </div>
                                <div
                                    style={{
                                        ...dropdownRenderTitleStyle,
                                        width: 300,
                                        padding: "0 5px"
                                    }}
                                >
                                    {optionItem.address}
                                </div>
                            </div>
                        )}
                    />
                    <Dropdown overlay={content} disabled={readOnly}>
                        <Icon style={{ cursor: readOnly ? "not-allowed" : "", padding: "0 5px" }} type="read" />
                    </Dropdown>
                </div>
                <div className="flex1">
                    <Input placeholder="联系人/联系电话" disabled value={nameAndPhone} />
                </div>
            </div>
            <Input.TextArea rows={3} style={{ marginTop: 8 }} placeholder="详细地址" disabled value={detailAddress} />
        </div>
    );
};

// tslint:disable-next-line:variable-name
export const BaseInfo = ({
    readOnly,
    orderNumber,
    customerNumber,
    clientName,
    clientId,
    departureTime,
    arrivalTime,
    billingMethodName,
    billingMethodId,
    agingName,
    agingId,
    remark,
    changeState,
    deliveryRequirements,
    salesmanName,
    salesmanId,
    salesmanPhoneNumber,
    getFieldDecorator,
    setFieldsValue,
    businessRepresentativeList,
    source,
    openType,
    getDefaultCee,
    unload,
    arrive,
    feedbackMessage
}) => {
    let _agingDis = false,
        _unloadDis = false,
        _arriveDis = false;
    if (billingMethodName) {
        const data = switchBilling(billingMethodName, {});
        _agingDis = data.agingDis;
        _unloadDis = data.unloadDis;
        _arriveDis = data.arriveDis;
    }
    const [agingDis, setAgingDis] = useState(_agingDis);
    const [unloadDis, setUnloadDis] = useState(_unloadDis);
    const [arriveDis, setArriveDis] = useState(_arriveDis);
    // console.log(salesmanPhoneNumber, salesmanName);
    return (
        <section style={{ background: "white", padding: "10px 20px" }}>
            <TitleSplit>基本信息</TitleSplit>
            <Row gutter={24} style={{ maxWidth: 1260 }}>
                <Col span={7} label="系统单号" labelStyle={labelStyle} text={orderNumber}>
                    {getFieldDecorator("orderNumber", { initialValue: orderNumber })(<Input disabled />)}
                </Col>
                <Col span={7} label="发货单号" labelStyle={labelStyle} text={customerNumber}>
                    {getFieldDecorator("customerNumber", { initialValue: customerNumber })(<Input disabled />)}
                </Col>
                <Col span={7} label="客户名称" labelStyle={labelStyle} text={clientName} isRequired>
                    {getFieldDecorator("clientId", {
                        initialValue: clientName && clientId ? { id: clientId, shortname: clientName } : null,
                        rules: [{ required: true, message: "请选择客户名称" }]
                    })(
                        <RemoteSelect
                            disabled={readOnly}
                            onChangeValue={(value) => {
                                if (value) {
                                    const origin_data = value?.origin_data?.[0];
                                    const businessRepresentativeList = origin_data?.businessRepresentativeList;
                                    const contacts = origin_data?.contacts;
                                    const pickupPassword = origin_data?.pickupPassword;
                                    const defaultSalesman = businessRepresentativeList?.filter(
                                        (item) => item.defaultBusinessRepresentative === 1
                                    )?.[0];

                                    changeState({
                                        clientId: value?.id,
                                        clientName: value?.shortname ?? value?.name,
                                        salesmanId: defaultSalesman?.id,
                                        salesmanName: defaultSalesman?.name,
                                        salesmanPhoneNumber: defaultSalesman?.phoneNumber,
                                        businessRepresentativeList: businessRepresentativeList,
                                        contacts: contacts,
                                        openDeliveryPassword: pickupPassword === 1
                                    });
                                    getDefaultCee &&
                                        getDefaultCee({
                                            clientId: value?.id
                                        });
                                } else {
                                    changeState({
                                        clientId: null,
                                        clientName: null,
                                        salesmanId: null,
                                        salesmanName: null,
                                        salesmanPhoneNumber: null,
                                        businessRepresentativeList: [],
                                        contacts: []
                                    });
                                }
                            }}
                            labelField="shortname"
                            getDataMethod={"getClients"}
                            params={{ offset: 0, limit: 20 }}
                            isAsyncSearch
                            noInitChange
                        />
                    )}
                </Col>
                <Col span={7} label="业务员" labelStyle={labelStyle} text={salesmanName} isRequired>
                    {getFieldDecorator("salesmanId", {
                        initialValue:
                            salesmanPhoneNumber && salesmanName
                                ? { phoneNumber: salesmanPhoneNumber, name: salesmanName }
                                : null
                        // rules: [{ required: true, message: "请选择业务员" }]
                    })(
                        <RemoteSelect
                            // def={
                            //     salesmanPhoneNumber && salesmanName
                            //         ? { phoneNumber: salesmanPhoneNumber, name: salesmanName }
                            //         : null
                            // }
                            disabled={readOnly}
                            onChangeValue={(value) => {
                                const origin_data = value?.origin_data?.[0];
                                changeState({
                                    salesmanId: origin_data?.id,
                                    salesmanName: origin_data?.name,
                                    salesmanPhoneNumber: origin_data?.phoneNumber
                                });
                            }}
                            list={businessRepresentativeList}
                            labelField="name"
                            noInitChange
                        />
                    )}
                </Col>
                <Col span={7} label="发货时间" labelStyle={labelStyle} text={departureTime}>
                    {getFieldDecorator("departureTime", { initialValue: departureTime ? moment(departureTime) : null })(
                        <DatePicker
                            disabled={readOnly}
                            onChange={(date, dateString) =>
                                changeState({
                                    departureTime: dateString ? dateString : null
                                })
                            }
                            style={{ width: "100%" }}
                            format={"YYYY-MM-DD HH:mm"}
                            showTime
                            size={window._size}
                        />
                    )}
                </Col>
                <Col span={7} label="要求到达时间" labelStyle={labelStyle} text={arrivalTime}>
                    {getFieldDecorator("arrivalTime", { initialValue: arrivalTime ? moment(arrivalTime) : null })(
                        <DatePicker
                            disabled={readOnly}
                            onChange={(date, dateString) =>
                                changeState({
                                    arrivalTime: dateString ? dateString : null
                                })
                            }
                            style={{ width: "100%" }}
                            format={"YYYY-MM-DD HH:mm"}
                            showTime
                            size={window._size}
                        />
                    )}
                </Col>
                <Col span={7} label="送货要求" labelStyle={labelStyle} text={deliveryRequirements}>
                    {getFieldDecorator("deliveryRequirements", { initialValue: deliveryRequirements })(
                        <Input
                            disabled={readOnly}
                            onChange={(e) =>
                                changeState({
                                    deliveryRequirements: e.target.value
                                })
                            }
                        />
                    )}
                </Col>
                <Col span={7} label="发运方式" labelStyle={labelStyle} text={billingMethodName} isRequired>
                    {getFieldDecorator("billingMethodId", {
                        initialValue:
                            billingMethodId && billingMethodName
                                ? { id: billingMethodId, title: billingMethodName }
                                : null,
                        rules: [{ required: true, message: "请选择发运方式" }]
                    })(
                        <RemoteSelect
                            disabled={readOnly}
                            onChangeValue={(value) => {
                                if (value) {
                                    // debugger;
                                    const data = switchBilling(value.title, { agingId, agingName, unload, arrive });
                                    // console.log(data);
                                    setAgingDis(data.agingDis);
                                    setUnloadDis(data.unloadDis);
                                    setArriveDis(data.arriveDis);
                                    setFieldsValue({
                                        agingId:
                                            data.agingId && data.agingName
                                                ? { id: data.agingId, title: data.agingName }
                                                : null,
                                        unload: data.unload,
                                        arrive: data.arrive
                                    });
                                    changeState({
                                        agingId: data.agingId,
                                        agingName: data.agingName,
                                        unload: data.unload,
                                        arrive: data.arrive
                                    });
                                }
                                changeState({
                                    billingMethodId: value ? value.id : null,
                                    billingMethodName: value ? value.title : null
                                });
                            }}
                            text="发运方式"
                            labelField="title"
                            params={{ offset: 0, limit: 20 }}
                            isAsyncSearch
                            noInitChange
                        />
                    )}
                </Col>
                <Col span={7} label="时效要求" labelStyle={labelStyle} text={agingName}>
                    {getFieldDecorator("agingId", {
                        initialValue: agingId && agingName ? { id: agingId, title: agingName } : null
                    })(
                        <RemoteSelect
                            disabled={readOnly ? readOnly : agingDis}
                            onChangeValue={(value) =>
                                changeState({
                                    agingName: value ? value.title : null,
                                    agingId: value ? value.id : null
                                })
                            }
                            text="时效要求"
                            labelField="title"
                            params={{ offset: 0, limit: 20 }}
                            isAsyncSearch
                            noInitChange
                        />
                    )}
                </Col>
                <Col span={7} label="是否卸货" labelStyle={labelStyle} text={agingName}>
                    {getFieldDecorator("unload", {
                        initialValue: unload
                    })(
                        <Select
                            onChange={(value) => {
                                changeState({
                                    unload: value ? value : null
                                });
                            }}
                            disabled={readOnly ? readOnly : unloadDis}
                        >
                            {unloadList.map((item) => (
                                <Option key={item.value} value={item.value}>
                                    {item.text}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Col>
                <Col span={7} label="是否到门" labelStyle={labelStyle} text={agingName}>
                    {getFieldDecorator("arrive", {
                        initialValue: arrive
                    })(
                        <Select
                            onChange={(value) => {
                                changeState({
                                    arrive: value ? value : null
                                });
                            }}
                            disabled={readOnly ? readOnly : arriveDis}
                        >
                            {arriveList.map((item) => (
                                <Option key={item.value} value={item.value}>
                                    {item.text}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Col>
                <Col span={7} />
                <Col span={24} label="备注" labelStyle={labelStyle} text={remark}>
                    {getFieldDecorator("remark", { initialValue: remark })(
                        <Input.TextArea
                            rows={3}
                            disabled={readOnly}
                            onChange={(e) =>
                                changeState({
                                    remark: e.target.value
                                })
                            }
                        />
                    )}
                </Col>
                <Col span={24} label="反愦信息" labelStyle={labelStyle} text={feedbackMessage}>
                    {getFieldDecorator("feedbackMessage", { initialValue: feedbackMessage })(
                        <Input.TextArea
                            rows={3}
                            disabled={readOnly}
                            onChange={(e) =>
                                changeState({
                                    feedbackMessage: e.target.value
                                })
                            }
                        />
                    )}
                </Col>
            </Row>
        </section>
    );
};

function getKeysForPattern(source, addonStr) {
    if (!source) return;
    const obj = {};
    const addonExp = new RegExp(addonStr, "ig");
    const isAddressExp = /Address/gi;
    const isJsonStrExp = /^{(.)*}$/gi;
    Object.keys(source).forEach((key) => {
        if (addonExp.test(String(key))) {
            obj[key] = source[key];

            //如果是地址并且是json字符串  解析地址数据
            const isAddress = isAddressExp.test(String(key));
            const isJsonStr = isJsonStrExp.test(source[key]);
            if (isAddress && isJsonStr) {
                const parseObj = JSON.parse(source[key]);
                obj[`${addonStr}Address`] = parseObj;
            }
            // 循环时进行正则校验 lastIndex会变动
            addonExp.lastIndex = 0;
            isAddressExp.lastIndex = 0;
            isJsonStrExp.lastIndex = 0;
        }
    });
    return obj;
}
export const ShipmentInfo = ({
    changeState,
    changeReceiverSenderList,
    openDeliveryPassword,
    readOnly,
    contacts,
    passwordRecipientList,
    receiverSenderList,
    openType,
    clientName,
    clientId,
    source
}) => {
    const receiverObj = getKeysForPattern(receiverSenderList?.[0], "receiver");
    const senderObj = getKeysForPattern(receiverSenderList?.[0], "sender");

    return (
        <section
            style={{
                background: "white",
                padding: "10px 20px"
            }}
        >
            <TitleSplit>发运信息</TitleSplit>
            <Row gutter={24} style={{ maxWidth: 1260 }}>
                <Col span={11} label="发货方" labelStyle={labelStyle} text={""} isRequired>
                    <CeeOrCer
                        type="cer"
                        onChange={(value) => {
                            changeReceiverSenderList({
                                type: "update",
                                index: 0,
                                mergeObj: {
                                    senderName: value.name,
                                    senderId: value?.receiverOrSenderId,
                                    senderContact: value.concatName,
                                    senderPhoneNumber: value.phone,
                                    senderAddress: value.address,
                                    senderLatitude: value?.origin?.latitude,
                                    senderLongitude: value?.origin?.longitude,
                                    senderAreaName: value?.origin?.areaName
                                }
                            });
                        }}
                        value={senderObj}
                        readOnly={openType === 1 && source === 2 ? false : readOnly}
                        clientId={clientId}
                    />
                </Col>
                <Col span={11} label="收货方" labelStyle={labelStyle} text={""} isRequired>
                    <CeeOrCer
                        type="cee"
                        onChange={(value) => {
                            changeReceiverSenderList({
                                type: "update",
                                index: 0,
                                mergeObj: {
                                    receiverName: value.name,
                                    receiverId: value?.receiverOrSenderId,
                                    receiverContact: value.concatName,
                                    receiverPhoneNumber: value.phone,
                                    receiverAddress: value.address,
                                    receiverLatitude: value?.origin?.latitude,
                                    receiverLongitude: value?.origin?.longitude,
                                    receiverAreaName: value?.origin?.areaName
                                }
                            });
                        }}
                        value={receiverObj}
                        readOnly={openType === 1 && source === 2 ? false : readOnly}
                        clientId={clientId}
                    />
                </Col>
                <Col span={24} label="提货密码" labelStyle={labelStyle} text={""}>
                    <Switch
                        onChange={(checked) => {
                            if (checked) {
                                if (openType === 3) {
                                    if (!contacts || contacts.length === 0) {
                                        message.warning("当前客户的客户联系人为空,不能开启提货密码");
                                        return;
                                    } else if (contacts.some((item) => !item.email)) {
                                        message.warning("当前客户的客户联系人没有填写邮箱,不能开启提货密码");
                                        return;
                                    }
                                    changeState({
                                        openDeliveryPassword: true
                                    });
                                } else {
                                    changeState({
                                        openDeliveryPassword: true
                                    });
                                }
                            } else {
                                changeState({
                                    passwordRecipientList: [],
                                    openDeliveryPassword: false
                                });
                            }
                        }}
                        disabled={readOnly}
                        checked={openDeliveryPassword}
                    />
                    <div style={{ padding: "10px", background: "#F2F2F2", marginTop: "10px" }}>
                        <SelectMulti
                            getDataPromise={() => {
                                return new Promise((resolve, reject) => {
                                    if (!clientName && !clientId) {
                                        message.warning("必须先选择客户!");
                                        reject();
                                        return;
                                    }

                                    const params = {
                                        keyword: clientName,
                                        limit: 20,
                                        offset: 0,
                                        pageNo: 1,
                                        pageSize: 20
                                    };
                                    rApi["getClients"](params)
                                        .then((res) => {
                                            const o_contacts = res?.records?.[0]?.contacts;
                                            const contacts = _.isArray(o_contacts) ? o_contacts : [];
                                            let flag = true;
                                            if (contacts.length === 0) {
                                                flag = false;
                                                message.warning("当前客户的客户联系人为空,不能开启提货密码");
                                            } else if (contacts.some((item) => !item.email)) {
                                                flag = false;
                                                message.warning("当前客户的客户联系人没有填写邮箱,不能开启提货密码");
                                            }

                                            changeState({
                                                contacts,
                                                openDeliveryPassword: flag
                                            });
                                            resolve(contacts);
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            message.error(err.msg || "获取客户联系失败");
                                            changeState({
                                                passwordRecipientList: [],
                                                openDeliveryPassword: false
                                            });
                                            reject(err);
                                        });
                                });
                            }}
                            handleChangeSelect={(value) => {
                                changeState({
                                    passwordRecipientList: value
                                });
                            }}
                            value={passwordRecipientList || []}
                            disabled={readOnly}
                            labelField="email"
                        />
                    </div>
                </Col>
            </Row>
        </section>
    );
};

export const MaterialListInfo = ({ materialList, changeReceiverSenderList, readOnly }) => {
    const columns = [
        {
            title: "物料名称",
            dataIndex: "materialName",
            key: "materialName",
            width: 149,
            render: (text, record, index) => {
                if (record.isSummary) {
                    return "总计";
                }
                return (
                    <RemoteSelect
                        value={
                            record.materialName && _.isNumber(record.materialsId)
                                ? { itemName: record.materialName, id: record.materialsId }
                                : null
                        }
                        onChange={(val) => {
                            const id = val?.id;
                            changeReceiverSenderList({
                                type: "updateMaterial",
                                index: 0,
                                mIndex: index,
                                mergeObj: {
                                    materialsId: id === "temporaryItem" ? -1 : id,
                                    materialName: val?.itemName,
                                    materialNumber: val?.materialItemNumber
                                }
                            });
                        }}
                        disabled={readOnly}
                        placeholder="请选择物料"
                        getDataMethod="getMaterials"
                        params={{ offset: 0, limit: 20 }}
                        dataKey={"records"}
                        labelField="itemName"
                        isAsyncSearch
                        noInitChange
                        hasInput
                        inputKey="temporaryItem"
                    />
                );
            }
        },
        {
            title: "数量",
            dataIndex: "quantity",
            key: "quantity",
            width: 140,
            render(text, record, index) {
                if (record.isSummary) return text;
                return (
                    <InputNumber
                        min={0}
                        value={_.isNumber(text) ? text : 0}
                        onChange={(val) =>
                            changeReceiverSenderList({
                                type: "updateMaterial",
                                index: 0,
                                mIndex: index,
                                mergeObj: { quantity: transit(val) }
                            })
                        }
                        disabled={readOnly}
                    />
                );
            }
        },
        {
            title: "件数",
            dataIndex: "boxCount",
            key: "boxCount",
            width: 100,
            render(text, record, index) {
                if (record.isSummary) return text;
                return (
                    <InputNumber
                        min={0}
                        value={_.isNumber(text) ? text : 0}
                        onChange={(val) =>
                            changeReceiverSenderList({
                                type: "updateMaterial",
                                index: 0,
                                mIndex: index,
                                mergeObj: { boxCount: transit(val) }
                            })
                        }
                        disabled={readOnly}
                    />
                );
            }
        },
        {
            title: "重量(kg)",
            dataIndex: "weight",
            key: "weight",
            width: 100,
            render(text, record, index) {
                if (record.isSummary) return text;
                return (
                    <InputNumber
                        min={0}
                        value={_.isNumber(text) ? text : 0}
                        onChange={(val) =>
                            changeReceiverSenderList({
                                type: "updateMaterial",
                                index: 0,
                                mIndex: index,
                                mergeObj: { weight: transit(val) }
                            })
                        }
                        disabled={readOnly}
                    />
                );
            }
        },
        {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
            width: 100,
            render(text, record, index) {
                if (record.isSummary) return;
                return (
                    <Input
                        value={text || ""}
                        onChange={({ target: { value } }) =>
                            changeReceiverSenderList({
                                type: "updateMaterial",
                                index: 0,
                                mIndex: index,
                                mergeObj: { remark: value }
                            })
                        }
                        disabled={readOnly}
                    />
                );
            }
        },
        {
            title: "操作",
            dataIndex: "cus_action",
            key: "cus_action",
            width: 80,
            render: (text, record, index) => {
                if (record.isSummary) return;
                return (
                    !readOnly && (
                        <span
                            className="global_table_click_text"
                            style={{ marginLeft: 10, cursor: "pointer", color: "#FFBA30" }}
                            onClick={() =>
                                changeReceiverSenderList({ type: "removeMaterial", index: 0, mIndex: index })
                            }
                        >
                            删除
                        </span>
                    )
                );
            }
        }
    ];

    const dealData = (data) => {
        if (_.isArray(data)) {
            const summary = data.reduce(
                (prev, cur) => {
                    prev.quantity = NP.plus(transit(prev.quantity), transit(cur.quantity));
                    prev.boxCount = NP.plus(transit(prev.boxCount), transit(cur.boxCount));
                    prev.weight = NP.plus(transit(prev.weight), transit(cur.weight));
                    return prev;
                },
                {
                    quantity: 0,
                    boxCount: 0,
                    weight: 0,
                    isSummary: true
                }
            );
            return data.concat([summary]);
        }
        return [];
    };

    return (
        <section
            style={{
                background: "white",
                padding: "10px 20px"
            }}
        >
            <TitleSplit>货物明细</TitleSplit>
            <Table
                columns={columns}
                bordered
                dataSource={dealData(materialList)}
                pagination={false}
                rowKey={(record, index) => String(index)}
            />
            {!readOnly && (
                <div
                    style={{
                        color: "#FFBA30",
                        border: "2px dotted #E8E8E8",
                        padding: "8px",
                        textAlign: "center",
                        margin: "12px  0"
                    }}
                    onClick={() => changeReceiverSenderList({ type: "addMaterial", index: 0 })}
                >
                    <Icon type="plus-circle" /> 添加
                </div>
            )}
        </section>
    );
};

export interface IFooter {
    submitLoad: boolean;
    editLoad: boolean;
    pageData: any;
    handleEdit: () => void;
    handleSubmit: () => void;
    readOnly: boolean;
    status: number;
}

// tslint:disable-next-line:variable-name
export const Footer = ({ submitLoad, editLoad, pageData, handleSubmit, handleEdit, readOnly, status }: IFooter) => (
    <HeaderBox
        title={pageData?.id ? "发货单号" : "新建发货单"}
        tags={pageData?.id && <Tag color={STATUS_COLOR[pageData.status]}>{STATUS[pageData.status]}</Tag>}
        number={pageData?.orderNumber}
    >
        <>
            {status && (
                <Button
                    onClick={handleEdit}
                    loading={editLoad}
                    style={{ marginLeft: 5 }}
                    size={window["_size"]}
                    disabled={readOnly}
                >
                    保存
                </Button>
            )}
            <Button
                type="primary"
                loading={submitLoad}
                onClick={handleSubmit}
                style={{ marginLeft: 5 }}
                size={window["_size"]}
                disabled={readOnly}
            >
                提交
            </Button>
        </>
    </HeaderBox>
);
