const moduleName = "project/";
module.exports.id = {
    id: "CAPITAL_FLOW_DETAILS",
    method: "POST",
    name: "资金流水明细",
    type: "menu_hide"
};

// let moduleName = ''

module.exports.children = {};
