import React, { Component } from "react";
import { Button } from "antd";
import Header from "./header";
import ModularParent from "./modular_parent";
import Dragger from "./dragger_r";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { headerHeight } from "@src/layout/config";
import "./index.less";
import { connect } from "dva";

/**
 * 默认弹框组件
 */
const Footer = (props) => {
    // console.log('footer', props.addFooter())
    return (
        <div className="custom-modal-footer flex flex-vertical-center">
            <div className="flex1" />
            {!props.noConfirmButton && (
                <Button
                    size={window._size}
                    disabled={props.confirmDisable}
                    onClick={props.onSubmit}
                    key="ok"
                    loading={props.loading}
                    style={{ color: "#fff", background: props.confirmDisable ? "#f5f5f5" : "#FFBA30", border: 0 }}
                >
                    {props.footerText ? props.footerText : "确定"}
                </Button>
            )}
            {props.customButton}
            {/* { props.addFooter && props.addFooter() } */}
            {!props.noCancelButton && (
                <Button size={window._size} onClick={props.onCancel} key="close">
                    {"取消"}
                </Button>
            )}
        </div>
    );
};

@connect(({ global }) => ({
    siderWidth: global.siderWidth,
    pageWidth: global.domWidth - global.siderWidth
}))
class Modal extends Component {
    static propTypes = {
        getContentDom: PropTypes.func,
        className: PropTypes.string,
        noCancelButton: PropTypes.bool
    };

    static defaultProps = {
        open: false,
        haveFooter: false,
        isHaveRenderBody: false,
        stopDragEvent: true,
        className: "",
        noCancelButton: false
    };

    state = {
        show: false,
        height: 800
    };

    upDateView = true;
    draggerCount = 0;

    constructor(props) {
        super(props);
        this.state.show = this.props.open;
        if (props.getThis) {
            props.getThis(this);
        }
        // console.log('React', React)
        // this.view = React.createRef()
        // console.log('Dragger', Dragger)
    }
    /**
     * 设置box最高高度
     *
     *
     * @memberOf Modal
     */
    componentDidMount() {
        let view = document.querySelector(".navs");
        if (view) {
            this.setState(
                {
                    show: this.props.open
                },
                () => {
                    this.onWindowResize();
                }
            );
        }
        window.addEventListener("resize", this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
    }

    onWindowResize = () => {
        let width = document.body.offsetWidth - document.querySelector(".navs").offsetWidth;
        this.setState({ height: window.innerHeight - headerHeight, width: width });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.props.open) {
            this.setState({ show: nextProps.open }, () => {
                if (nextProps.open) {
                    this.setState({});
                }
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('shouldComponentUpdate',  nextProps.open || nextState.show || (nextState.show !== this.state.show) || nextProps.open !== this.props.open)
        // console.log('this.upDateView', this.upDateView)
        if (this.upDateView) {
            return true;
        }
        return (
            nextProps.open ||
            nextState.show ||
            nextState.show !== this.state.show ||
            nextProps.open !== this.props.open ||
            nextState.width !== this.state.width
        );
        // return true
    }

    close = () => {
        if (this.props.changeOpen) {
            this.props.changeOpen(false);
        } else {
            this.setState({ show: false });
        }
    };

    stopEvent(e) {
        console.log("stopEvent", e);
        if (e.stopPropagation) {
            e.stopPropagation();
        }
    }

    onSubmit = () => {
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    };

    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            this.close();
        }
    };

    stopPropagationDragEvent = (e, dragType) => {
        const { stopDragEvent } = this.props;
        // console.log('stopPropagationDragEvent', e.target, dragType, stopDragEvent)
        if (e && e.stopPropagation && stopDragEvent && e.target && e.target.className === "handle _modal_drag_event") {
            e.stopPropagation();
        }
        if (dragType === "start") {
            let formErrorPop = document.body.querySelectorAll(".form-error-pop") || [];
            try {
                formErrorPop.forEach((item) => {
                    if (item && item.style) {
                        item.style.display = "none";
                    }
                });
            } catch (e) {
                console.log(e);
            }
        }
        if (dragType === "end") {
            let formErrorPop = document.body.querySelectorAll(".form-error-pop") || [];
            try {
                formErrorPop.forEach((item) => {
                    if (item && item.style) {
                        item.style.display = "";
                    }
                });
            } catch (e) {
                console.log(e);
            }
            if (this.draggerCount < 2) {
                this.onDrag();
            }
            this.draggerCount++;
        }
    };

    onDrag = () => {
        const { parent } = this.props;
        if (!parent) {
            return;
        }
        const { form } = parent.props;
        if (!form) return;
        let errors = form.getFieldsError();
        if (errors) {
            errors = Object.keys(errors)
                .map((item) => errors[item])
                .some((item) => item);
        }
        if (errors) {
            form.validateFields((err, values) => {});
        }
    };

    cal = () => {
        const { pageWidth } = this.props;
        const { height } = this.state;
        let width = pageWidth;
        // console.log('cal', width, pageWidth)
        let view = this.refs.view;
        // console.log('this.view', width, height)
        if (view) {
            let offsetHeight = view.offsetHeight;
            let w = (width - 60) / 2;
            let h = (height - offsetHeight) / 2;
            //  console.log('height', h, offsetHeight, headerHeight)
            this.upDateView = false;
            return { left: w, top: h, right: w, bottom: h + offsetHeight - headerHeight * 2 + 50 };
        }
        let w = width / 2;
        let h = height / 2;
        this.upDateView = true;
        return { left: w, top: h, right: w, bottom: h + 220 };
    };

    render() {
        const { modalBoxStyle, coverStyle, siderWidth } = this.props;
        let { height, show } = this.state;
        let {
            style,
            title,
            footer,
            haveFooter,
            loading,
            isHaveRenderBody,
            renderContainer,
            footerText,
            getContentDom,
            children,
            className,
            bodyStyle,
            noCancelButton,
            noConfirmButton,
            headeaStyle,
            pageWidth
        } = this.props;
        if (!show) return null;
        // console.log('render', pageWidth)
        let view = (
            <div style={{ position: "relative", zIndex: 200, ...coverStyle }}>
                <div
                    className={`custom-modal flex flex-level-center flex-vertical-center ${className}`}
                    style={{
                        right: 0,
                        width: `calc(100% - ${siderWidth}px)`,
                        height: height,
                        zIndex: 100,
                        bottom: 0
                    }}
                >
                    <Dragger
                        style={style}
                        bounds={this.cal}
                        dragEvent={this.stopPropagationDragEvent}
                        hasDraggerHandle={true}
                    >
                        <div
                            // onClick={this.stopEvent}
                            // style={style}
                            style={{ ...modalBoxStyle }}
                            ref="view"
                            className="custom-modal-box paper-3"
                        >
                            {title ? (
                                <div>
                                    <div className="custom-modal-title flex flex-vertical-center">
                                        <div
                                            className="handle _modal_drag_event"
                                            style={{
                                                flex: 1,
                                                padding: "0 20px",
                                                cursor: "move",
                                                fontSize: "14px",
                                                ...headeaStyle
                                            }}
                                        >
                                            {title}
                                        </div>
                                        <div onClick={this.close.bind(this)} className="custom-modal-close">
                                            <i className="iconfont close" style={{ fontSize: "14px" }}></i>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div
                                ref={(v) => {
                                    this.content = v;
                                    if (getContentDom) {
                                        getContentDom(v);
                                    }
                                }}
                                className="custom-modal-content"
                                style={{ maxHeight: haveFooter ? height - 41 - 60 : height - 60, ...bodyStyle }}
                            >
                                {children}
                            </div>
                            {footer && <div className="custom-modal-footer">{footer}</div>}
                            {haveFooter && (
                                <Footer
                                    onSubmit={this.onSubmit}
                                    onCancel={this.onCancel}
                                    loading={loading}
                                    footerText={footerText}
                                    customButton={this.props.customButton}
                                    addFooter={this.props.addFooter}
                                    noCancelButton={noCancelButton}
                                    noConfirmButton={noConfirmButton}
                                    confirmDisable={this.props.confirmDisable}
                                />
                            )}
                        </div>
                    </Dragger>
                </div>
            </div>
        );
        if (isHaveRenderBody) {
            return ReactDOM.createPortal(view, document.body);
        }
        if (renderContainer) {
            return ReactDOM.createPortal(view, renderContainer.current);
        }
        return view;
    }
}

Modal.Header = Header;
Modal.ModularParent = ModularParent;

export default Modal;
