import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

const moduleName = "wms/profitLossManage/";

export default class {
    /**
     * 获取调整盈亏列表
     *
     * @param {any} params
     * @returns
     *
     * @memberOf profitLossApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url + params.checkManageId;
        return this.GET(url, deleteNull(params));
    }

    //提交盈亏
    submitProfitLoss(params) {
        let url = `${moduleName}submit/profitLoss/` + params.id;
        return this.GET(url, params);
    }

    //获取单条调整亏盈信息
    getOneProfitDetail(params) {
        let url = `${moduleName}` + params.id;
        return this.GET(url, params);
    }

    //初审通过
    firstReviewPass(params) {
        let url = `${moduleName}first/review/pass/` + params.id;
        return this.GET(url, params);
    }

    //初审驳回
    firstReviewReject(params) {
        let url = `${moduleName}first/review/reject/` + params.id;
        return this.GET(url, params);
    }

    //复审通过
    againReviewPass(params) {
        let url = `${moduleName}again/review/pass/` + params.id;
        return this.GET(url, params);
    }

    //复审驳回
    againReviewReject(params) {
        let url = `${moduleName}again/review/reject/` + params.id;
        return this.GET(url, params);
    }

    //盈亏单明细列表
    getProfitLossDetailsList(params) {
        let url = `${moduleName}details/` + params.id;
        return this.GET(url, params);
    }

    //提交盈亏保存
    adjustProfitLoss(params) {
        let url = `${moduleName}adjust/profitLoss`;
        return this.POST(url, params);
    }

    //上传图片
    uploadFileProfitLoss(params) {
        let url = `${moduleName}uploadFile`;
        return this.POST(url, params);
    }
}
