import React, { Component } from "react";
import { Upload, message } from "antd";
import XLSX from "xlsx";
import FunctionPower from "@src/layout/power_view/function.jsx";
import PropTypes from "prop-types";

class ImportExcel extends Component {
    static propTypes = {
        excelToData: PropTypes.func,
        keyDic: PropTypes.object
    };

    static defaultProps = {
        keyDic: null
    };

    beforeUpload = (file) => {
        let f = file;
        let reader = new FileReader();
        reader.onload = (e) => {
            let data = e.target.result;
            if (!this.rABS) data = new Uint8Array(data);
            try {
                this.processWb(XLSX.read(data, { type: this.rABS ? "binary" : "array" }));
            } catch (e) {
                console.error("e", e);
                message.error("文件解析错误, 请确保传入文件为excel表格！");
            }
        };
        if (this.rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
        return false;
    };

    processWb = (wb) => {
        /* get data */
        const { excelToData, keyDic } = this.props;
        const ws = wb.Sheets[wb.SheetNames[0]];
        let exData = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const header = !!exData[0] ? exData[0] : [];
        const data = exData.slice(1);
        const jsonData = [];
        data.forEach((arr) => {
            const item = {};
            let someKeyNotEmpty = false;
            header.forEach((title, i) => {
                if (keyDic && keyDic[title]) {
                    const k = keyDic[title];
                    item[k] = arr[i];
                    if (arr[i]) {
                        someKeyNotEmpty = true;
                    }
                }
            });
            if (someKeyNotEmpty) {
                jsonData.push(item);
            }
        });
        // console.log('exData', exData)
        // console.log('header', header)
        // console.log('data', data)
        // console.log("jsonData", jsonData);
        if (excelToData) {
            excelToData(header, data, jsonData);
        }
    };

    render() {
        const { power, children } = this.props;
        return (
            // <FunctionPower power={power}>
            <Upload showUploadList={false} beforeUpload={this.beforeUpload}>
                {children}
            </Upload>
            // </FunctionPower>
        );
    }
}

export default ImportExcel;
