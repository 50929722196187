const moduleName = "resource/carrierQuotation";
export const id = {
    id: "QUOTATION_CARRIER",
    method: "POST",
    name: "承运商报价",
    type: "menu",
    apiName: "getQuotationOfferCarrier",
    url: `${moduleName}/list`,
    sort: 8
};

export const children = {
    // GET_LIST: {
    //     id: 'QUOTATION_CARRIER_GET_LIST',
    //     apiName: 'getQuotationOfferCarrier',
    //     method: 'POST',
    //     name: '获取数据',
    //     type: 'view',
    //     url: `${moduleName}/list`
    // },
    ADD_DATA: {
        id: "QUOTATION_CARRIER_ADD_DATA",
        apiName: "addQuotationOfferCarrier",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}/save`
    },
    DEL_DATA: {
        id: "QUOTATION_CARRIER_CODE",
        apiName: "delQuotationOfferCarrier",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}/delete`
    },
    EDIT_DATA: {
        id: "QUOTATION_CARRIER_EDIT_DATA",
        apiName: "editQuotationOfferCarrier",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}/save`
    },
    CARRIER_PASS: {
        id: "QUOTATION_CARRIER_CARRIER_PASS",
        apiName: "offerQuotationPass",
        method: "POST",
        name: "审核通过",
        type: "view",
        url: `${moduleName}/pass`
    },
    CARRIER_CANCEL: {
        id: "QUOTATION_CARRIER_CARRIER_CANCEL",
        apiName: "offerCancelQuotationPass",
        method: "POST",
        name: "取消通过",
        type: "view",
        url: `${moduleName}/cancel`
    },
    CARRIER_REJECT: {
        id: "QUOTATION_CARRIER_CARRIER_REJECT",
        apiName: "offerQuotationReject",
        method: "POST",
        name: "审核驳回",
        type: "view",
        url: `${moduleName}/reject`
    },
    CARRIER_SUBMIT: {
        id: "QUOTATION_CARRIER_CARRIER_SUBMIT",
        apiName: "offerQuotationSubmit",
        method: "POST",
        name: "提交",
        type: "view",
        url: `${moduleName}/submit`
    },
    SUSPEND_STATUS: {
        id: "QUOTATION_CARRIER_SUSPEND_STATUS",
        apiName: "offerQuotationSuspend",
        method: "GET",
        name: "禁用",
        type: "view",
        url: `${moduleName}/suspend`
    },
    INVALID_STATUS: {
        id: "QUOTATION_CARRIER_INVALID_STATUS",
        apiName: "voidQuote",
        method: "GET",
        name: "作废",
        type: "view",
        url: `${moduleName}/invalid`
    },
    RECOVERY_STATUS: {
        id: "QUOTATION_CARRIER_RECOVERY_STATUS",
        apiName: "offerQuotationRecevory",
        method: "GET",
        name: "恢复",
        type: "view",
        url: `${moduleName}/recevory`
    },
    EXPORT_LIST: {
        id: "QUOTATION_CARRIER_EXPORT_LIST",
        apiName: "exportCarrierQuotation",
        method: "POST",
        name: "导出",
        type: "view",
        url: `${moduleName}/export`
    },
    // QUOTATION_RELOAD: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_RELOAD',
    //     name: '承运商报价->刷新',
    //     type: 'view',
    // },
    // QUOTATION_CLEAR: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_CLEAR',
    //     name: '承运商报价->批量删除',
    //     type: 'view',
    // },
    // QUOTATION_ADD: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_ADD',
    //     name: '承运商报价->新增',
    //     type: 'view',
    // },
    // QUOTATION_EXPORT: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_EXPORT',
    //     name: '承运商报价->导出',
    //     type: 'view',
    // },
    // QUOTATION_EXPORT_DATA: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_EXPORT_DATA',
    //     name: '承运商报价->导出表头',
    //     type: 'view',
    // },
    // QUOTATION_EXPORT_HEADER: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_EXPORT_HEADER',
    //     name: '承运商报价->导出表数据',
    //     type: 'view',
    // },
    // QUOTATION_EXPORT_QUO: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_EXPORT_QUO',
    //     name: '承运商报价->导出报价单',
    //     type: 'view',
    // },
    // QUOTATION_IMPORT: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_IMPORT',
    //     name: '承运商报价->导入',
    //     type: 'view',
    // },
    // QUOTATION_EXPENSE: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_EXPENSE',
    //     name: '承运商报价->费用项',
    //     type: 'view',
    // },
    // QUOTATION_EDIT: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_EDIT',
    //     name: '承运商报价->编辑',
    //     type: 'view',
    // },
    // QUOTATION_DEL: {
    //     id: 'QUOTATION_CARRIER_QUOTATION_DEL',
    //     name: '承运商报价->删除',
    //     type: 'view',
    // },
    SAVE_QUOTATION_HEADER: {
        id: "OFFER_CARRIER_SAVE_QUOTATION_HEADER",
        apiName: "saveQuotationHeaderCarrierV2Quotation",
        method: "POST",
        name: "保存报价表头",
        type: "view",
        url: `${moduleName}/quotation/header/save`
    },
    GET_QUOTATION_HEADER: {
        id: "OFFER_CARRIER_GET_QUOTATION_HEADER",
        apiName: "getQuotationHeaderCarrierV2Quotation",
        method: "GET",
        name: "获取报价表头",
        type: "view",
        url: `${moduleName}/quotation/header`
    },
    QUOTATION_SAVE: {
        id: "OFFER_CARRIER_GET_QUOTATION_SAVE",
        apiName: "saveQuotationCarrierV2Quotation",
        method: "POST",
        name: "报价保存",
        type: "view",
        url: `${moduleName}/quotation/save`
    },
    GET_QUOTATION_DATA: {
        id: "OFFER_CARRIER_GET_QUOTATION_DATA",
        apiName: "getQuotationCarrierV2Quotation",
        method: "POST",
        name: "获取报价数据",
        type: "view",
        url: `${moduleName}/quotation/data`
        // /quotation/data/{id}
    },
    DELETE_QUOTATION_DATA: {
        id: "OFFER_CARRIER_DELETE_QUOTATION_DATA",
        apiName: "deleteQuotationCarrierV2Quotation",
        method: "POST",
        name: "编辑报价数据",
        type: "view",
        url: `${moduleName}/quotation/delete`
        // /quotation/data/{id}
    },
    EXPORT_QUOTATION_HEADER: {
        id: "OFFER_CARRIER_EXPORT_QUOTATION_HEADER",
        apiName: "exportHeaderCarrierV2Quotation",
        method: "POSTFILE",
        type: "view",
        url: `${moduleName}/exportQuotationHeader`,
        name: "导出报价模板"
        // /quotation/data/{id}
    },
    EXPORT_QUOTATION_DATA: {
        id: "OFFER_CARRIER_EXPORT_QUOTATION_DATA",
        apiName: "exportDataCarrierV2Quotation",
        method: "POSTFILE",
        type: "view",
        url: `${moduleName}/exportQuotationData`,
        name: "导出报价数据"
        // /quotation/data/{id}
    },
    LOOK_MORE: {
        id: "QUOTATION_CARRIER_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
