const moduleName = "project/";
export const id = {
    id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT",
    method: "POST",
    name: "客户运输报价-旧",
    type: "menu_hide",
    apiName: "getClientQuotation",
    url: `${moduleName}clientQuotation/list`,
    sort: 2
};

export const children = {
    // GET_LIST: {
    //     id: 'PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_GET_LIST',
    //     apiName: 'getClientQuotation',
    //     method: 'POST',
    //     name: '获取数据',
    //     type: 'view',
    //     url: `${moduleName}clientQuotation/list`
    // },
    ADD_DATA: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_ADD_DATA",
        apiName: "addClientQuotation",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}clientQuotation/addClientQuotation`
    },
    DEL_DATA: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORTT_CODE",
        apiName: "delClientQuotation",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}clientQuotation/delete`
    },
    EDIT_DATA: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_EDIT_DATA",
        apiName: "editClientQuotation",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}clientQuotation/editClientQuotation`
    },
    EXAMINE_PASS: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_EXAMINE_PASS",
        apiName: "clientQuotationExaminePass",
        method: "GET",
        name: "审核通过",
        type: "view",
        url: `${moduleName}clientQuotation/pass`
    },
    CANCEL_PASS: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_CANCEL_PASS",
        apiName: "clientQuotationCancelExaminePass",
        method: "GET",
        name: "取消通过",
        type: "view",
        url: `${moduleName}clientQuotation/cancel`
    },
    EXAMINE_REJECT: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_EXAMINE_REJECT",
        apiName: "clientQuotationExamineReject",
        method: "POST",
        name: "审核驳回",
        type: "view",
        url: `${moduleName}clientQuotation/reject`
    },
    EXAMINE_SUBMIT: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_EXAMINE_SUBMIT",
        apiName: "clientQuotationSubmit",
        method: "GET",
        name: "提交",
        type: "view",
        url: `${moduleName}clientQuotation/submit`
    },
    SUSPEND_STATUS: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_SUSPEND_STATUS",
        apiName: "clientQuotationSuspend",
        method: "GET",
        name: "禁用",
        type: "view",
        url: `${moduleName}clientQuotation/suspend`
    },
    RECOVERY_STATUS: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_RECOVERY_STATUS",
        apiName: "clientQuotationRecovery",
        method: "GET",
        name: "恢复",
        type: "view",
        url: `${moduleName}clientQuotation/recevory`
    },
    EXPORT_LIST: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_EXPORT_LIST",
        apiName: "exportClientQuotationData",
        method: "POST",
        name: "导出",
        type: "view",
        url: `${moduleName}clientQuotation/exportClientQuotationData`
    },
    QUOTATION_RELOAD: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_RELOAD",
        name: "运输报价->刷新",
        type: "view"
    },
    QUOTATION_CLEAR: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_CLEAR",
        name: "运输报价->批量删除",
        type: "view"
    },
    QUOTATION_ADD: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_ADD",
        name: "运输报价->新增",
        type: "view"
    },
    QUOTATION_EXPORT: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_EXPORT",
        name: "运输报价->导出",
        type: "view"
    },
    QUOTATION_EXPORT_DATA: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_EXPORT_DATA",
        name: "运输报价->导出表头",
        type: "view"
    },
    QUOTATION_EXPORT_HEADER: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_EXPORT_HEADER",
        name: "运输报价->导出表数据",
        type: "view"
    },
    QUOTATION_EXPORT_QUO: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_EXPORT_QUO",
        name: "运输报价->导出报价单",
        type: "view"
    },
    QUOTATION_IMPORT: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_IMPORT",
        name: "运输报价->导入",
        type: "view"
    },
    QUOTATION_EXPENSE: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_EXPENSE",
        name: "运输报价->费用项",
        type: "view"
    },
    QUOTATION_EDIT: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_EDIT",
        name: "运输报价->编辑",
        type: "view"
    },
    QUOTATION_DEL: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_QUOTATION_DEL",
        name: "运输报价->删除",
        type: "view"
    },
    LOOK_MORE: {
        id: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_TRANSPORT_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
