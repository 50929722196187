import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

const moduleName = "common/exchangeRate/";

export default class {
    /**
     * 获取汇率配置列表
     *
     * @param {any} params
     * @returns
     *
     * @memberOf exchangeRateApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    saveExchangeRate(params) {
        let url = `${moduleName}saveOrUpdate`;
        return this.POST(url, params);
    }

    saveOrUpdateMaintain(params) {
        let url = `${moduleName}saveOrUpdateExchangeRateMaintain`;
        return this.POST(url, params);
    }

    getCurrencyList(params) {
        let url = `common/currency/list`;
        return this.POST(url, params);
    }

    saveOrUpdateCurrency(params) {
        let url = `common/currency/saveOrUpdate`;
        return this.POST(url, params);
    }

    deleteExchange(params) {
        let url = `${moduleName}deleteExchangeRateMaintain` + "/" + params.id;
        return this.POST(url, params);
    }

    exchangeRateMaintainList(params) {
        let url = `${moduleName}exchangeRateMaintainList`;
        return this.POST(url, params);
    }

    getExchangeRateLineData(params) {
        let url = `${moduleName}getExchangeRateLineData` + "/" + params.id;
        return this.GET(url, params);
    }
}
