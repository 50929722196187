import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class AllocateApi {
    /**
     * 获取配载管理数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.POST(url, params);
    }
    [children.EXPORT.apiName](params) {
        let url = children.EXPORT.url;
        return this.POSTFILE(url, params);
    }
    getStowageCreateUser(params) {
        //获取操作人信息
        let url = "order/stowage/getCreateUser";
        return this.GET(url, params);
    }

    stowageUpdateBindTypeValue(params) {
        //修改sendcarOrderList的bindType
        let url = "order/stowage/updateBindTypeValue";
        return this.POST(url, params);
    }

    deleteStowageList(params) {
        let url = "order/stowage/associatedWaybill/" + params.id;
        return this.GET(url, params);
    }

    getStowageFiles(params) {
        let url = "order/stowage/getStowageFiles/" + params.id;
        return this.GETFILE(url, params);
    }

    getCommission(params) {
        let url = `order/stowage/export/Commission`;
        return this.GETFILE(url, params);
    }

    updateSixSingleNumber(params) {
        let url = `order/stowage/sixSingleNumber/edit/` + params.id + "?sixSingleNumber=" + params.sixUnionSingleNumber;
        return this.GET(url, params);
    }
    stowageConfirmPaperReceipt(ids) {
        // /stowage/confirmPaperReceipt/{id} 确认纸质回单
        let url = `order/stowage/confirmPaperReceipt/`;
        return this.PUT(url, ids);
    }

    stowageBatchImportAddress(params) {
        let url = "order/stowageRouteConfig/batchStowageImport";
        return this.POST(url, params);
    }
}
