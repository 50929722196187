import load from "@src/layout/modules_load";
import { id } from "./power_hide";

export default {
    [id.id]: {
        component: load(() => import("./index")),
        name: id.name,
        id: id,
        contentStyle: {
            background: "none"
        }
    }
};
