import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class AccountReceivableApi {
    /**
     * @param {any} params
     * @returns
     *
     * @memberOf AccountReceivableApi
     */
    // /receivableReconciliation/receivableReconciliationReceiptPage / { receivableReconciliationId }
    getReceivableReconciliationReceiptPage(params) {
        // 获取单条应收对账单
        return this.GET(`finance/receivableReconciliation/receivableReconciliationReceiptPage/${params.id}`, params);
    }
    // receivableReconciliationExpensePage/{receivableReconciliationId}
    getReceivableReconciliationExpensePage(params) {
        // 获取单条应收对账单
        return this.GET(`finance/receivableReconciliation/receivableReconciliationExpensePage/${params.id}`, params);
    }
}
