import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

const moduleName = "finance/receivableReconciliation";
const moduleNameWms = "wms/reconciliation";

export default class CarApi {
    /**
     * 获取应收对账跟踪列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    // [children.ADD_DATA.apiName](params) { //新增编辑开票
    //     let url = children.ADD_DATA.url + `/${params[0].operationType}`
    //     return this.POST(url, params)
    // }

    // [children.DEL_DATA.apiName](params) { //删除开票
    //     let url = children.DEL_DATA.url + `/${params.id}/${params.operationType}`
    //     return this.GET(url, params)
    // }

    // [children.WRITE_OFF.apiName](params) { //完成核销
    //     let url = children.WRITE_OFF.url + `/${params.id}`
    //     return this.GET(url, params)
    // }

    getOneBillingData(params) {
        //获取单个应收对账单
        return this.GET(`${moduleName}/getOne/${params.id}`, params);
    }

    getOneBillingDataForWms(params) {
        //获取仓库单个应收对账单
        return this.GET(`${moduleNameWms}/getOne/${params.id}`, params);
    }

    writeOff(params) {
        //完成核销
        return this.GET(`${moduleName}/writeOff/${params.id}`, params);
    }

    writeOffForWms(params) {
        //完成核销仓储
        return this.GET(`${moduleNameWms}/writeOff/${params.id}`, params);
    }

    saveOrUpdate(params) {
        //新增编辑开票
        let url = `${moduleName}/saveOrUpdate` + `/${params[0].operationType}`;
        return this.POST(url, params);
    }

    saveOrUpdateForWms(params) {
        // 新增编辑开票仓库
        let url = `${moduleNameWms}/saveOrUpdate` + `/${params[0].operationType}`;
        return this.POST(url, params);
    }

    deleteBilling(params) {
        //删除开票
        let url = `${moduleName}/deleteBilling` + `/${params.id}/${params.operationType}`;
        return this.GET(url, params);
    }

    deleteBillingForWms(params) {
        // 删除开票仓库
        let url = `${moduleNameWms}/deleteBilling` + `/${params.id}/${params.operationType}`;
        return this.GET(url, params);
    }

    getReceivableTrackClient(params) {
        //获取应收款跟踪列表客户信息
        return this.GET(`${moduleName}/getReceivableTrackClient`, params);
    }

    getReceivableTrackOrderLegal(params) {
        //获取应收款跟踪列表接单法人
        return this.GET(`${moduleName}/getReceivableTrackOrderLegal`, params);
    }

    getReceivableTrackProject(params) {
        //获取应收款跟踪列表项目
        return this.GET(`${moduleName}/getReceivableTrackProject`, params);
    }
}
