import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class StowageMaintenanceApi {
    /**
     * 发货单数据维护
     *
     * @param {any} params
     * @returns
     *
     * @memberOf UserApi
     */

    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    [power.CONFIRM.apiName](params) {
        let url = power.CONFIRM.url;
        return this.POST(url, params);
    }

    getDispatchDetail(params) {
        const url = power.GET_DISPATCH_INFO.url + "/" + params.id;
        return this.GET(url, params);
    }

    relatedCarrier(params) {
        const url = "project/cooperationProject/relatedCarrier";
        return this.POST(url, params);
    }

    otherCarrier(params) {
        const url = "project/cooperationProject/otherCarrier";
        return this.POST(url, params);
    }

    getCarrierQuotationByProjectIds(params) {
        const url = "project/cooperationProject/getCarrierQuotationByProjectIds";
        return this.POST(url, params);
    }

    matchCarrierQuotationLineExpense(params) {
        const url = "resource/carrierQuotation/matchQuotationLineExpense/" + params.id;
        return this.GET(url, params);
    }

    getCustomsRoute(params) {
        const url = "order/order/getCustomsRoute/" + params.id;
        return this.GET(url, params);
    }

    getStowageReceiverSenders(params) {
        const url = "order/order/getCustomsRoute/" + params.id;
        return this.GET(url, params);
    }

    deleteStowageOrder(params) {
        // /stowage/deleteStowageOrder / { stowageOrderId }
        const url = "order/stowage/deleteStowageOrder/" + params.id;
        return this.GET(url, params);
    }

    // 根据发货单ID查询运单追踪数据
    getStowageMap(params) {
        const url = `order/order/getStowageMapTrace/${params.id}`;
        return this.GET(url, params);
    }

    // 获取运单路由配置列表
    getStowageList(params = {}) {
        const url = `order/stowageRouteConfig/list?orderId=${params.orderId}&stowageId=${params.stowageId}`;
        return this.GET(url);
    }

    // 根据运单路由id获取运输信息
    getTransportInfo(params) {
        const url = `order/stowageRouteConfig/findTransportInfo/${params.id}`;
        return this.GET(url);
    }

    //  导出托运单
    exportConsignMend(params) {
        const url = `order/stowage/export/consignment`;
        return this.POSTFILE(url, params);
    }
}
