const moduleName = "wms/profitLossManage/";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_INVERTORY_PROFIT_LOSS",
    method: "POST",
    name: "调整盈亏管理",
    type: "menu",
    apiName: "getProfitLossList",
    url: `${moduleName}list`
};

export const children = {};
