const m = "client/complaint";
export const id = {
    id: "CLIENT_SUGGESTIONS",
    method: "POST",
    name: "投诉建议",
    type: "menu",
    apiName: "getClientSuggestList",
    url: `${m}/list`,
    sort: 4
};

export const children = {
    ADD_DATA: {
        id: "CLIENT_SUGGESTIONS_ADD_DATA",
        apiName: "onSaveSuggestItem",
        method: "POST",
        name: "新增",
        type: "view",
        url: `${m}/save`
    },
    EDIT_DATA: {
        id: "CLIENT_SUGGESTIONS_EDIT_DATA",
        apiName: "onSaveSuggestItem",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${m}/save`
    },
    DEL_DATA: {
        id: "CLIENT_SUGGESTIONS_DEL_DATA",
        apiName: "onDelSuggestItem",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${m}/delete`
    },
    DEAL_WITH: {
        id: "CLIENT_SUGGESTIONS_DEAL_WITH",
        apiName: "onDealSuggestItem",
        method: "POST",
        name: "处理",
        type: "view",
        url: `${m}/deal`
    },
    DUTY_DEFINE: {
        id: "CLIENT_SUGGESTIONS_DUTY_DEFINE",
        apiName: "onDutyDefineSuggestItem",
        method: "POST",
        name: "责任界定",
        type: "view",
        url: `${m}/duty`
    }
};
