import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class InventoryApi {
    /**
     * 获取库存管理数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf InventoryApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    [children.EXPORT_DATA.apiName](params) {
        // 导出
        let url = children.EXPORT_DATA.url;
        return this.POSTFILE(url, params);
    }

    [children.CANCEL_CHANGE.apiName](params) {
        // 取消变更
        let url = children.CANCEL_CHANGE.url + "/" + params.id;
        return this.GET(url, params);
    }

    [children.DEL_DATA.apiName](params) {
        // 删除
        let url = children.DEL_DATA.url;
        return this.POST(url, params);
    }

    getInventoryChangeDetails(params) {
        //获取变更明细数据
        let url = `wms/inventoryChange/${params.id}`;
        return this.GET(url);
    }
}
