import * as React from "react";
import { connect } from "dva";
import { Icon, Input } from "antd";
import AutoSizer from "react-virtualized-auto-sizer";
import { isArray } from "lodash";

const { Search } = Input;

export interface IHeadViewProps {
    onChange?: (value: string) => any;
    onSearch?: (keyword: string, callback: () => any) => any;
    isNoneSearch?: boolean;
    titleBar?: React.ReactElement | string | null;
    style?: React.CSSProperties;
    customClass?: string;
    deductionSize?: number;
    defaultValue?: string;
    customSearchBtn?: React.ReactElement | string | null;
    moreStyle?: StyleSheet;
    pageWidth?: number;
    className?: string;
    title?: string;
}

export interface IHeadViewState {
    loading: boolean;
    keyword: string;
    show: boolean;
}

export interface IActionButtonProps {
    style?: StyleSheet;
    length?: number;
    children: React.ReactElement | string | null;
}

const style = { width: 130, paddingRight: 10 };

// tslint:disable-next-line:variable-name
export const ActionButton: React.FC<IActionButtonProps> = (props) => {
    return <div style={{ ...style, ...props.style }}>{props.children}</div>;
};

@connect(({ global }) => ({
    pageWidth: global.domWidth - global.siderWidth
}))
class IHeadView extends React.Component<IHeadViewProps, IHeadViewState> {
    static defaultProps = {
        isNoneSearch: false,
        titleBar: null,
        style: {},
        customClass: "",
        deductionSize: 0,
        defaultValue: null,
        parent: {}
    };

    state = {
        loading: false,
        keyword: "",
        show: false
    };

    dom: React.RefObject<HTMLDivElement>;
    filterBtn: any;
    moreBtn: any;

    constructor(props: IHeadViewProps) {
        super(props);
        this.dom = React.createRef();
    }

    onChangeValue = (d: { keyword: string }) => {
        // console.log('onChangeValue', d)
        this.setState(d, () => {
            if (this.props.onSearch) {
                this.props.onSearch(d.keyword, () => {
                    this.setState({ loading: false });
                });
            }
        });
    };

    isShow = () => {
        this.setState({
            show: !this.state.show
        });
    };

    // onResize = (size) => {
    //     console.log('onResize', size)
    // }

    render() {
        const {
            customClass,
            children,
            // deductionSize,
            defaultValue,
            customSearchBtn,
            moreStyle
        } = this.props;
        const { show } = this.state;
        // let pageWidth = (this.dom && this.dom.current && this.dom.current.offsetWidth && (this.dom.current.offsetWidth - 200)) || (this.props.pageWidth - 200)
        // console.log("(this.dom && this.dom.current) ", this.dom.current);
        return (
            <div style={{ minHeight: 45 }}>
                <AutoSizer disableWidth={false} disableHeight={false}>
                    {(sizer) => {
                        let pageWidth = sizer.width;
                        const allSearchMenu: any = children
                            ? isArray(children)
                                ? children.filter((item) => item)
                                : [children]
                            : null; // 所有搜索条件
                        let size = 0;
                        if (isArray(allSearchMenu)) {
                            for (let i = 0; i < allSearchMenu.length; i++) {
                                const item = allSearchMenu[i];
                                pageWidth -= (item && item.props && item.props.style && item.props.style.width) || 135;
                                if (pageWidth < 300) {
                                    size = i;
                                    break;
                                }
                            }
                        }
                        //  console.log('width', pageWidth)
                        // console.log('pageWidth', pageWidth, size, this.dom)
                        const ratio = allSearchMenu && allSearchMenu.length && size > 0;
                        let filterBtn: any = [];
                        let moreBtn: any = [];
                        if (ratio) {
                            filterBtn = allSearchMenu.slice(0, size); // 第一行过滤项
                            moreBtn = allSearchMenu.slice(size); // 更多过滤项
                        } else {
                            filterBtn = allSearchMenu;
                            moreBtn = [];
                        }
                        return (
                            <div
                                ref={this.dom}
                                style={{
                                    padding: "10px 10px 10px",
                                    borderBottom: "1px solid #eee",
                                    ...this.props.style,
                                    width: sizer.width
                                }}
                            >
                                <div className={`flex ${customClass ? customClass : ""}`}>
                                    {this.props.titleBar ? (
                                        this.props.titleBar
                                    ) : (
                                        <React.Fragment>
                                            <div className="flex" style={{ flexWrap: "wrap" }}>
                                                {isArray(filterBtn) ? (
                                                    filterBtn
                                                        .filter((item) => item)
                                                        .map((view, index) => {
                                                            // tslint:disable-next-line:no-shadowed-variable
                                                            const style =
                                                                view.props && view.props.style ? view.props.style : {};
                                                            return (
                                                                <ActionButton
                                                                    style={style}
                                                                    length={filterBtn.length}
                                                                    key={index}
                                                                >
                                                                    {view}
                                                                </ActionButton>
                                                            );
                                                        })
                                                ) : (
                                                    <ActionButton
                                                        style={
                                                            filterBtn && filterBtn.props && filterBtn.props.style
                                                                ? filterBtn.props.style
                                                                : {}
                                                        }
                                                        length={1}
                                                    >
                                                        {filterBtn}
                                                    </ActionButton>
                                                )}
                                            </div>
                                            {ratio ? (
                                                <div
                                                    style={{
                                                        // borderBottom: show ? '0' : '1px solid #d9d9d9',
                                                        minWidth: 68,
                                                        borderRadius: 4,
                                                        borderColor: show
                                                            ? window["_global"]._theme.primary_color
                                                            : "#d9d9d9"
                                                        // height: show ? 42 : 32,
                                                        // marginBottom: !show ? 10 : 0
                                                    }}
                                                    onClick={this.isShow}
                                                    className="btn-wrapper"
                                                >
                                                    更多
                                                    <Icon
                                                        type="down"
                                                        theme="outlined"
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "rgba(0, 0, 0, 0.25)",
                                                            transition: "all 0.5s",
                                                            transform: show ? "rotate(-180deg)" : ""
                                                        }}
                                                    />
                                                    <div className={show ? "btn-mask" : ""} />
                                                </div>
                                            ) : null}
                                        </React.Fragment>
                                    )}
                                    <div className="flex1" />
                                    {customSearchBtn
                                        ? customSearchBtn
                                        : !this.props.isNoneSearch && (
                                              <div style={{ width: 180 }} className={this.props.className}>
                                                  <Search
                                                      placeholder={
                                                          this.props.title ? this.props.title : "输入关键字搜索"
                                                      }
                                                      onSearch={(value) => {
                                                          this.onChangeValue({ keyword: value });
                                                      }}
                                                      defaultValue={defaultValue}
                                                      size={window["_size"]}
                                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                          // console.log('value', value)
                                                          if (this.props.onChange) {
                                                              this.props.onChange(e.target.value);
                                                          }
                                                      }}
                                                  />
                                              </div>
                                          )}
                                </div>
                                {this.dom && (
                                    <div
                                        className="btn-main"
                                        style={{
                                            display: show && moreBtn.length > 0 ? "block" : "none",
                                            position: "fixed",
                                            zIndex: 10,
                                            width:
                                                this.dom && this.dom.current
                                                    ? this.dom.current.offsetWidth + 1
                                                    : this.props.pageWidth,
                                            left:
                                                this.dom && this.dom.current
                                                    ? this.dom.current.getBoundingClientRect().x - 1
                                                    : 9,
                                            top:
                                                this.dom && this.dom.current
                                                    ? this.dom.current.getBoundingClientRect().height +
                                                      0 +
                                                      this.dom.current.getBoundingClientRect().y
                                                    : 0, // this.dom.getBoundingClientRect().y + this.dom.getBoundingClientRect().height,
                                            paddingTop: 10,
                                            paddingBottom: 0,
                                            ...moreStyle
                                        }}
                                    >
                                        <div className="flex" style={{ flexWrap: "wrap" }}>
                                            {isArray(moreBtn) ? (
                                                moreBtn
                                                    .filter((item) => item)
                                                    .map((view, index) => {
                                                        // tslint:disable-next-line:no-shadowed-variable
                                                        const style =
                                                            view.props && view.props.style ? view.props.style : {};
                                                        return (
                                                            <ActionButton
                                                                style={{ ...style, marginBottom: 10 }}
                                                                length={moreBtn.length}
                                                                key={index}
                                                            >
                                                                {view}
                                                            </ActionButton>
                                                        );
                                                    })
                                            ) : (
                                                <ActionButton
                                                    style={
                                                        moreBtn && moreBtn.props && moreBtn.props.style
                                                            ? moreBtn.props.style
                                                            : {}
                                                    }
                                                    length={1}
                                                >
                                                    {moreBtn}
                                                </ActionButton>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </AutoSizer>
            </div>
        );
    }
}

export default IHeadView;
