// import { children } from './power'
export default class QuickQuotation {
    /**
     * 快速报价接口
     *
     * @param {any} params
     * @returns
     * @memberOf QuickQuotation
     */

    getOnceQuotation(params) {
        let url = "/resource/carrierQuotation/quickSearch/detail";
        return this.POST(url, params);
    }

    // 匹配报价行
    getMatchingRow(params) {
        let url = "/project/matching/getMatchingRwo";
        return this.POST(url, params);
    }

    // 导出报价
    exportQuikQuotation(params) {
        let url = "/project/matching/exportData";
        return this.POSTFILE(url, params);
    }

    // 导出表头
    exportQuikQuotationHeader(params) {
        let url = "/project/matching/exportGaugeOutfit";
        return this.POSTFILE(url, params);
    }
}
