export default class IndexApi {
    /**
     * 获取数据
     * @param {any} params
     * @returns
     */

    getStatisticsHomeData(params) {
        // 获取统计数据
        return this.GET(`/order/order/statisticsHomeData`, params);
    }

    getSendCarStatisticsHomeData(params) {
        // 运输统计运单
        return this.POST(`/order/sendCar/statis`, params);
    }

    getOrderStatisticsHomeData(params) {
        // 运输统计发货单
        return this.POST(`/order/order/statis`, params);
    }

    getFinanceStatisticsHomeData(params) {
        // 财务统计
        return this.POST(`/finance/profit/statis`, params);
    }

    getWmsStatisticsHomeData(params) {
        // 仓储统计
        return this.POST(`/wms/statistics/statis`, params);
    }
}
