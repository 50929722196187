import load from "@src/layout/modules_load";
import power from "./power_hide";

const { id } = power;

export default {
    [id.id]: {
        component: load(() => import("./index")),
        name: id.name,
        id: id
    }
};
