import { children, id } from "./power_hide";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class {
    [power[id.id].apiName](warehouseId) {
        let url = power[id.id].url + `/${warehouseId}`;
        return this.GET(url);
    }

    [children.SAVE.apiName](params) {
        let url = children.SAVE.url;
        return this.POST(url, params);
    }

    getWarehouseSum(params) {
        //获取仓库规模汇总
        let url = `wms/warehouse/scale/summary/${params.id}`;
        return this.GET(url, params);
    }

    getStorageList(params) {
        //储位信息列表
        let url = "wms/warehouseStorage/list";
        return this.POST(url, params);
    }
}
