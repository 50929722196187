import React, { Component } from "react";
import { Icon } from "antd";
import "./tag.less";
export default class CustomRemoteSelect extends Component {
    state = {};
    // constructor(props) {
    //     super(props)
    // }
    render() {
        let { openType, disabled } = this.props;
        return (
            <div className="tag-wrapper">
                <div className="flex flex-vertical-center" style={{ height: "100%" }}>
                    <div className="flex1">{this.props.itemTitle}</div>
                    <div className="flex">
                        {openType === 2 || disabled ? null : (
                            <Icon
                                type="close"
                                theme="outlined"
                                onClick={() => this.props.handleShow(this.props.itemTitle)}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
