const m = "finance/payableReconciliation";
export const id = {
    id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS",
    method: "POST",
    name: "请款管理",
    type: "menu",
    apiName: "getReqfundsList",
    url: `${m}/requestPaymentList`
};

export const children = {
    ADD_DATA: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_ADD_DATA",
        apiName: "reqfundsAdd",
        method: "POST",
        name: "新增请款单",
        type: "view",
        url: `${m}/addPayment`
    },
    EDIT_DATA: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_EDIT_DATA",
        apiName: "reqfundsEdit",
        method: "POST",
        name: "编辑请款单",
        type: "view",
        url: `${m}/editPayment`
    },
    DEL_DATA: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_DEL_DATA",
        apiName: "reqfundsDelete",
        method: "GET",
        name: "删除请款单",
        type: "view",
        url: `${m}/deleteRequestPayment`
    },
    SAVE: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_SAVE",
        name: "查看",
        type: "view"
    },
    PM: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_PM",
        name: "PM",
        type: "view"
    },
    PMO: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_PMO",
        name: "PMO",
        type: "view"
    },
    CAR: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_CAR",
        name: "现结车",
        type: "view"
    },
    ACCOUNT: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_ACCOUNT",
        name: "会计",
        type: "view"
    },
    TREASURER: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_TREASURER",
        name: "财务主管",
        type: "view"
    },
    OFFICER: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_OFFICER",
        name: "财务总监",
        type: "view"
    },
    CEO: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_REQFUNDS_CEO",
        name: "总裁",
        type: "view"
    }
};
