import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

const moduleName = "resource/resource-vehicle/";
export default class {
    /**
     * 获取载具管理列表
     *
     * @param {any} params
     * @returns
     *
     * @memberOf VehicleApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    //保存
    saveVehicle(params) {
        let url = `${moduleName}save`;
        return this.POST(url, params);
    }

    //更新
    updateVehicle(params) {
        let url = `${moduleName}update/` + params.id;
        return this.POST(url, params);
    }

    //更新
    deleteVehicle(params) {
        let url = `${moduleName}delete/` + params.id;
        return this.POST(url, params);
    }
}
