import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class BenefitApi {
    /**
     * @param {any} params
     * @returns
     *
     * @memberOf 经营效益分析
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.EXPORT_ALL.apiName](params) {
        // 总表导出
        let url = children.EXPORT_ALL.url;
        return this.POSTFILE(url, deleteNull(params));
    }

    [children.EXPORT_SUB.apiName](params) {
        // 分表导出
        let url = children.EXPORT_SUB.url;
        return this.POSTFILE(url, deleteNull(params));
    }

    generateReceivableWriteOff(params) {
        let url = "finance/receivableVerification/generate";
        return this.POST(url, params);
    }
}
