const moduleName = "resource/resource-vehicle/";
export const id = {
    id: "RESOURCE_MANAGEMENT_VEHICLE_MANAGEMENT",
    method: "POST",
    name: "载具管理",
    type: "menu",
    apiName: "getVehicleList",
    url: `${moduleName}page`,
    sort: 4
};

export const children = {
    ADD_DATA: {
        id: "RESOURCE_MANAGEMENT_VEHICLE_ADD",
        apiName: "vehicleAdd",
        name: "新建",
        type: "view"
    },

    EDIT_DATA: {
        id: "RESOURCE_MANAGEMENT_VEHICLE_EDIT_DATA",
        apiName: "vehicleEdit",
        name: "编辑",
        type: "view"
    },

    DEL_DATA: {
        id: "RESOURCE_MANAGEMENT_VEHICLE_DEL_DATA",
        apiName: "vehicleDelete",
        name: "删除",
        type: "view"
    },

    EXPORT_DATA: {
        id: "RESOURCE_MANAGEMENT_VEHICLE_EXPORT_DATA",
        apiName: "vehicleExport",
        method: "GETFILE",
        name: "导出模板",
        type: "view",
        url: `${moduleName}export`
    },
    IMPORT_DATA: {
        id: "RESOURCE_MANAGEMENT_VEHICLE_IMPORT_DATA",
        apiName: "vehicleBatchImport",
        method: "POST",
        name: "导入数据",
        type: "view",
        url: `${moduleName}batchImport`
    }
};
