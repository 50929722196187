import load from "@src/layout/modules_load";
import power from "./index.power";

export default {
    [power.id]: {
        component: load(() => import("./index.jsx")),
        name: power.name,
        id: power
    }
};
