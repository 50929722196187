const moduleName = "wms/businessRuleQuality/";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_QUALITY_INSPECTION",
    name: "质检规则",
    type: "menu",
    apiName: "getQualityList",
    url: `${moduleName}list`
};

export const children = {
    // GET_LIST: {
    //     id: 'WAREHOUSE_MANAGEMENT_BUSINESS_RULES_QUALITY_INSPECTION_GET_LIST',
    //     apiName: 'getCarTypes',
    //     method: 'POST',
    //     name: '获取列表',
    //     type: 'view',
    //     url: `${moduleName}carType/getCarTypes`
    // },
    ADD_DATA: {
        id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_QUALITY_INSPECTION_ADD_DATA",
        apiName: "addQuality",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}save`
    },
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_QUALITY_INSPECTION_CODE",
        apiName: "delQuality",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}delete`
    },
    EDIT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_QUALITY_INSPECTION_EDIT_DATA",
        apiName: "editQuality",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}save`
    },
    LOOK_MORE: {
        id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_QUALITY_INSPECTION_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
