export const id = {
    id: "ADDRESS_FILE",
    method: "POST",
    name: "地址档案",
    type: "menu"
};

const moduleName = "account/";
let moduleName1 = "common/";
export const children = {
    REGISTER: {
        id: "ADDRESS_FILE_GET_LIST",
        apiName: "getAddFileList",
        method: "POST",
        name: "获取列表",
        type: "view",
        url: `${moduleName}views/register`
    },
    AREA_CONFIG: {
        id: "ADDRESS_FILE_AREA_CONFIG",
        name: "片区配置",
        type: "view"
    },

    GET_DISTRICT_SEARCH: {
        id: "ADDRESS_FILE_DISTRICT_SEARCH",
        apiName: "getDistrictList",
        method: "GET",
        name: "国外一级目录",
        type: "view",
        url: `${moduleName1}district/search`
    },

    DISTRICT_ADD: {
        id: "ADDRESS_FILE_DISTRICT_ADD",
        apiName: "districtAdd",
        method: "POST",
        name: "添加地址档案",
        type: "view",
        url: `${moduleName1}district/add`
    }
};
