import React, { useEffect, useRef } from "react";
import bmapStyle from "@src/libs/baidumap/bmap_style_new.json";
import { getCurveLine } from "@src/libs/baidumap";

interface IProps {
    height: string | number;
    onGetMapCtx: (params: { mapCtx; curveLine }, mapDom) => void;
    BMapGL?: any;
}

export default function wrapper(BMapGL) {
    // console.log("BMapGL", BMapGL);
    function Map(props: IProps) {
        const { onGetMapCtx } = props;

        const load = useRef(false);
        const mapId = useRef(new Date().getTime() + ~~(Math.random() * 100) + "").current;
        const mapDom = useRef(null);
        // baidu 地图 map 对象
        // const BMapGL = useRef(window['BMapGL']).current
        let mapCtx = useRef(null).current;

        useEffect(() => {
            BMapGL && !load.current && mapInit();
        }, [BMapGL]);

        function mapInit() {
            const curveLine = getCurveLine(BMapGL);
            // console.log('map', props)
            mapCtx = new BMapGL.Map(mapId, { enableMapClick: false });
            removeLogo();
            const point = new BMapGL.Point(113.930785, 22.538088); // 创建点坐标
            mapCtx.centerAndZoom(point, 11);
            mapCtx.enableScrollWheelZoom(true);
            onGetMapCtx({ mapCtx, curveLine }, mapDom.current);

            mapCtx.setMapStyleV2({
                styleJson: bmapStyle
            });
            load.current = true;
        }

        function removeLogo() {
            mapCtx.addEventListener("tilesloaded", () => {
                for (let i = 0; i < document.getElementsByClassName(" anchorBL").length; i++) {
                    (document.getElementsByClassName(" anchorBL")[i] as HTMLElement).style.display = "none";
                }
            });
        }

        const { height } = props;

        return <div id={mapId} ref={mapDom} className="map" style={{ height }} />;
    }

    return Map;
}
