import XLSX from "xlsx";
import moment from "moment";
import { isNumber } from "./index";

// 物料计量项key
export const materialKeys = ["quantityCount", "boxCount", "boardCount", "grossWeight", "volume"];
// 物料计量项actualKey
export const actualMaterialKeys = [
    "actualQuantityCount",
    "actualBoxCount",
    "actualBoardCount",
    "actualGrossWeight",
    "actualVolume"
];

/* 物料规则计算
key = boardCount:板数
key = boxCount:箱数
key = quantityCount:数量
key = grossWeight:重量
key = volume:体积
ruleObj.quantityScale: 数量/箱
ruleObj.boxScale: 箱/板
ruleObj.weightScale: 重量规则
ruleObj.volumeScale: 体积规则

calGorV: () => 计算重量和体积
calQBB: () => 计算数量箱数和板数
*/
export const ruleCalculate = (
    key,
    val,
    ruleObj = {
        quantityScale: 0,
        boxScale: 0,
        weightScale: 0,
        volumeScale: 0,
        perUnitWeight: "",
        perUnitVolume: ""
    },
    defVal = {}
) => {
    let { quantityScale, boxScale, weightScale, volumeScale, perUnitWeight, perUnitVolume } = ruleObj;
    let rt = {
        boardCount: 0,
        boxCount: 0,
        quantityCount: 0,
        grossWeight: 0,
        volume: 0,
        ...defVal
    };
    const calQBB = (rt, key, val) => {
        // 根据 数量/箱数/板数 计算 数量箱数板数
        if (key === "quantityCount") {
            rt.quantityCount = val;
            if (!isNaN(quantityScale) && quantityScale) {
                rt.boxCount = Math.ceil(val / quantityScale);
                if (!isNaN(boxScale) && boxScale) {
                    rt.boardCount = Math.ceil(val / quantityScale / boxScale);
                }
            }
        } else if (key === "boxCount") {
            rt.boxCount = val;
            if (!isNaN(quantityScale) && quantityScale) {
                rt.quantityCount = val * quantityScale;
            }
            if (!isNaN(boxScale) && boxScale) {
                rt.boardCount = Math.ceil(val / boxScale);
            }
        } else if (key === "boardCount") {
            rt.boardCount = val;
            if (!isNaN(boxScale) && boxScale) {
                rt.boxCount = val * boxScale;
                if (!isNaN(quantityScale) && quantityScale) {
                    rt.quantityCount = val * boxScale * quantityScale;
                }
            }
        }
        return rt;
    };
    const calGorV = (rt, type = null) => {
        // 根据数量箱数板数计算重量和体积
        if (type === "grossWeight" || !type) {
            if (perUnitWeight === 3 && !isNaN(weightScale)) {
                rt.grossWeight = rt.quantityCount * weightScale;
            } else if (perUnitWeight === 2 && !isNaN(weightScale)) {
                rt.grossWeight = rt.boxCount * weightScale;
                if (!isNaN(quantityScale) && quantityScale) {
                    const rule = weightScale / quantityScale;
                    rt.grossWeight = rt.quantityCount * rule;
                }
            } else if (perUnitWeight === 1 && !isNaN(weightScale)) {
                rt.grossWeight = rt.boardCount * weightScale;
                // if (!isNaN(boxScale) && boxScale) {
                //     let rule = weightScale / boxScale
                //     rt.grossWeight = rt.boxCount * rule
                //     if (!isNaN(quantityScale) && quantityScale) {
                //         rule = weightScale / boxScale / quantityScale
                //         rt.grossWeight = rt.quantityCount * rule
                //     }
                // }
            }
        }
        if (type === "volume" || !type) {
            if (perUnitVolume === 3 && !isNaN(volumeScale)) {
                rt.volume = rt.quantityCount * volumeScale;
            } else if (perUnitVolume === 2 && !isNaN(volumeScale)) {
                rt.volume = rt.boxCount * volumeScale;
                if (!isNaN(quantityScale) && quantityScale) {
                    const rule = volumeScale / quantityScale;
                    rt.volume = rt.quantityCount * rule;
                }
            } else if (perUnitVolume === 1 && !isNaN(volumeScale)) {
                rt.volume = rt.boardCount * volumeScale;
                // if (!isNaN(boxScale) && boxScale) {
                //     let rule = volumeScale / boxScale
                //     rt.volume = rt.boxCount * rule
                //     if (!isNaN(quantityScale) && quantityScale) {
                //         rule = volumeScale / boxScale / quantityScale
                //         rt.volume = rt.quantityCount * rule
                //     }
                // }
            }
        }
        return rt;
    };
    const calGV = (rt, key, val) => {
        // 根据重量/体积 计算 数量箱数板数和 体积/重量
        const m = new Map();
        m.set(1, "boardCount");
        m.set(2, "boxCount");
        m.set(3, "quantityCount");
        rt[key] = val;
        if (key === "grossWeight") {
            if (perUnitWeight === 3 && !isNaN(weightScale) && weightScale !== 0) {
                rt.quantityCount = Math.floor(rt[key] / weightScale);
            } else if (perUnitWeight === 2 && !isNaN(weightScale) && weightScale !== 0) {
                rt.boxCount = Math.floor(rt[key] / weightScale);
            } else if (perUnitWeight === 1 && !isNaN(weightScale) && weightScale !== 0) {
                rt.boardCount = Math.floor(rt[key] / weightScale);
            }
            rt = calGorV(rt, "volume");
            rt = calQBB(rt, m.get(perUnitWeight), rt[m.get(perUnitWeight)]);
        } else if (key === "volume") {
            if (perUnitVolume === 3 && !isNaN(volumeScale) && volumeScale !== 0) {
                rt.quantityCount = Math.floor(rt[key] / volumeScale);
            } else if (perUnitVolume === 2 && !isNaN(volumeScale) && volumeScale !== 0) {
                rt.boxCount = Math.floor(rt[key] / volumeScale);
            } else if (perUnitVolume === 1 && !isNaN(volumeScale) && volumeScale !== 0) {
                rt.boardCount = Math.floor(rt[key] / volumeScale);
            }
            rt = calGorV(rt, "grossWeight");
            rt = calQBB(rt, m.get(perUnitVolume), rt[m.get(perUnitVolume)]);
        } else {
        }
        return rt;
    };
    switch (key) {
        case "quantityCount": //数量
            rt = calQBB(rt, key, val);
            rt = calGorV(rt);
            break;

        case "boxCount": //箱数
            rt = calQBB(rt, key, val);
            rt = calGorV(rt);
            break;

        case "boardCount": //板数
            rt = calQBB(rt, key, val);
            rt = calGorV(rt);
            break;

        case "grossWeight": //重量
            rt = calGV(rt, key, val);
            break;

        case "volume": //体积
            rt = calGV(rt, key, val);
            break;

        default:
            break;
    }

    // console.log('rt', rt)
    return rt;
};

/* 费用计算

应收费用计算公式 {
    含税金额：价格 * (1 + 补扣税)
    不含税金额：含税金额 / (1 + 发票税)
}

quotePriceArr: array //报价费用数据
reimburseArr: array //实报实销费用数据
opt.type: string //类型
opt.invoiceTax: number // 发票税 || 现结价结算税率 || 油卡税
opt.deductionTax: number //补扣税
opt.invoiceLowestFee: number //报价最低费用
opt.payTaxesType: 现结价税率计算方式 1:税率结算 2:油卡支付
opt.oilAmount: 油卡金额

return => {
    rt.offer: 报价
    rt.taxFree: 税后金额
    rt.taxIncluded: 总金额
    rt.monthlyItems: 月结费用项
}
*/
export const costCalculate = (
    quotePriceArr = [],
    reimburseArr = [],
    opt = {
        type: "现结价",
        invoiceTax: 0,
        deductionTax: 0,
        invoiceLowestFee: 0,
        payTaxesType: null,
        oilAmount: 0
    }
) => {
    const { type, invoiceTax, deductionTax, invoiceLowestFee, payTaxesType, oilAmount } = opt;
    let offer = 0,
        taxIncluded = 0,
        taxFree = 0,
        monthlyItems = [];

    // console.log('costCalculate params', opt)
    /* 费用项计算 */
    const reduceFun = (total, cur) => {
        const {
            accountingStrategy, //是否月结
            lowestFee //最低费用
        } = cur;
        if (accountingStrategy === 1 || !accountingStrategy) {
            //非月结
            let price = cur.chargeFee && !isNaN(cur.chargeFee) ? parseFloat(cur.chargeFee) : 0; //单价
            let num =
                cur.orderExpenseItemUnitCoefficientList &&
                cur.orderExpenseItemUnitCoefficientList.length &&
                cur.orderExpenseItemUnitCoefficientList[0].costUnitValue &&
                !isNaN(cur.orderExpenseItemUnitCoefficientList[0].costUnitValue)
                    ? parseFloat(cur.orderExpenseItemUnitCoefficientList[0].costUnitValue)
                    : 0; //计量量
            let cost = price * num; //当前费用
            // if (hasTax) { // 如果是算税
            //     cost = cost * (1 + deductionTax / 100)
            // }
            cost = lowestFee && !isNaN(lowestFee) && cost < lowestFee ? lowestFee : cost;
            total += cost;
        } else if (accountingStrategy === 2) {
            //月结
            monthlyItems.push(cur);
            total += 0;
        } else {
            total += 0;
        }
        return total;
    };
    /* 实报实销计算 */
    const reimburseReduce = (total, cur) => {
        let price = cur.costUnitValue && !isNaN(cur.costUnitValue) ? parseFloat(cur.costUnitValue) : 0;
        return (total += price);
    };
    /* 现结价计算 */
    const cashPriceReduce = (total, cur) => {
        let price = cur.expenseValue && !isNaN(cur.expenseValue) ? cur.expenseValue : 0;
        return (total += price);
    };
    if (type === "费用项") {
        // 报价金额
        offer = quotePriceArr.reduce((total, cur) => reduceFun(total, cur), 0);
        offer =
            invoiceLowestFee &&
            !isNaN(invoiceLowestFee) &&
            quotePriceArr.length > 0 &&
            offer < parseFloat(invoiceLowestFee)
                ? parseFloat(invoiceLowestFee)
                : offer;
        // 总金额
        taxIncluded = offer * (1 + deductionTax / 100);
        // 税后金额
        taxFree = taxIncluded / (1 + invoiceTax / 100);
    } else if (type === "实报实销") {
        // 报价金额
        offer = reimburseArr.reduce((total, cur) => reimburseReduce(total, cur), 0);
        // 总金额
        taxIncluded = reimburseArr.reduce((total, cur) => reimburseReduce(total, cur), 0);
        // 税后金额
        taxFree = taxIncluded;
    } else if (type === "合计") {
        offer = quotePriceArr.reduce((total, cur) => reduceFun(total, cur), 0);
        offer =
            invoiceLowestFee &&
            !isNaN(invoiceLowestFee) &&
            quotePriceArr.length > 0 &&
            offer < parseFloat(invoiceLowestFee)
                ? parseFloat(invoiceLowestFee)
                : offer;
        let totalReimburse = reimburseArr.reduce((total, cur) => reimburseReduce(total, cur), 0);
        taxIncluded = offer * (1 + deductionTax / 100);
        // 报价金额
        offer += totalReimburse;
        taxFree = taxIncluded / (1 + invoiceTax / 100);
        // 总金额
        taxIncluded += totalReimburse;
        // 税后金额
        taxFree += totalReimburse;
    } else if (type === "现结价") {
        // 报价金额
        offer = quotePriceArr.reduce((total, cur) => cashPriceReduce(total, cur), 0);
        // 总金额
        taxIncluded = offer;
        if (payTaxesType === 1) {
            // 税率结算 -> 未税金额 = 总金额/（1+结算税率）
            // 税后金额
            taxFree = offer / (1 + (invoiceTax || 0) / 100);
        } else if (payTaxesType === 2) {
            // 油卡支付 未税金额 = 油卡金额/（1+油卡税）+总金额-油卡金
            // 税后金额
            taxFree = oilAmount / (1 + (invoiceTax || 0) / 100) + offer - oilAmount;
            // console.log('taxFree', taxFree, oilAmount, invoiceTax, offer)
        } else {
            taxFree = offer;
        }
    } else {
    }
    // console.log('rt', typeof taxIncluded, taxFree)
    try {
        offer = !isNaN(offer) && typeof offer !== "string" ? parseFloat(offer.toFixed(4)) : parseFloat(offer);
        taxIncluded =
            !isNaN(taxIncluded) && typeof taxIncluded !== "string"
                ? parseFloat(taxIncluded.toFixed(4))
                : parseFloat(taxIncluded);
        taxFree = !isNaN(taxFree) && typeof taxFree !== "string" ? parseFloat(taxFree.toFixed(4)) : parseFloat(taxFree);
    } catch (error) {
        offer = offer;
        taxIncluded = taxIncluded;
        taxFree = taxFree;
    }
    return {
        offer,
        taxIncluded,
        taxFree,
        monthlyItems
    };
};

/**
 * @description 发货单费用计算
 * @param {Array} expenseItemArr 费用项数据
 * @param {Array} expensePriceArr 实报实销数据
 * @param {obj} param2 配置
 *  @param {number} param2.taxes 发票税
 *  @param {number} param2.withholdingTax 补扣税
 *  @param {number} param2.quotationLowestFee 报价最低费用
 * @returns {obj} result 结果
 *  @returns result.totalAmount 合计金额        = 费用项合计 + 实报实销合计
 *  @returns result.estimatedAmount 预估金额    = 费用项合计 * (1 + 补扣税) + 实报实销合计
 *  @returns result.afterTaxAmount 税后金额     = 费用项合计 * (1 + 补扣税) / (1 + 发票税) + 实报实销合计
 *  @returns { Array } result.monthlyItems 月结费用项
 */
export const orderCostItemCalc = (expenseItemArr, expensePriceArr, { taxes, withholdingTax, quotationLowestFee }) => {
    // try {
    //     if (!expenseItemArr) {
    //         throw '费用项数据不存在'
    //     }
    // } catch (error) {
    //     console.log('发货单费用计算错误', error)
    //     return
    // }
    taxes = taxes || 0;
    withholdingTax = withholdingTax || 0;
    quotationLowestFee = (quotationLowestFee && Number(quotationLowestFee)) || 0;
    const monthlyItems = [];
    // 费用项合计
    let expenseItemTotal = expenseItemArr.reduce(
        (
            rt,
            {
                accountingStrategy, // 月结状态
                chargeFee, // 单价
                lowestFee, // 最低费用
                expenseItemId, // 费用项类型ID
                startValue, // 系数左区间值
                endValue, // 系数右区间值
                orderExpenseItemUnitCoefficientList, // 发货单关键数据
                ...item
            }
        ) => {
            if (accountingStrategy === 1 || !accountingStrategy) {
                // 如果该费用项不是月结
                chargeFee = chargeFee || 0;
                startValue = startValue || 0;
                endValue = endValue || null;
                lowestFee = lowestFee || 0;
                // console.log('orderExpenseItemUnitCoefficientList', orderExpenseItemUnitCoefficientList)
                // 计算系数
                let ratio =
                    orderExpenseItemUnitCoefficientList &&
                    orderExpenseItemUnitCoefficientList.length &&
                    orderExpenseItemUnitCoefficientList[0].costUnitValue
                        ? orderExpenseItemUnitCoefficientList[0].costUnitValue || 0
                        : 0;
                if (expenseItemId === 3) {
                    ratio = 1;
                } else if (expenseItemId === 4) {
                    // 如果是续重
                    ratio =
                        ratio >= startValue
                            ? endValue < ratio && endValue > startValue
                                ? endValue - startValue
                                : ratio - startValue
                            : 0;
                }
                rt += lowestFee < ratio * chargeFee ? ratio * chargeFee : lowestFee;
                //  console.log('lowestFee', lowestFee, rt, (lowestFee < ratio * chargeFee), ((lowestFee < ratio * chargeFee) ? ratio * chargeFee : lowestFee))
            } else {
                // 认为是月结
                monthlyItems.push(item);
                rt += 0;
            }
            return rt;
        },
        0
    );
    // 实报实销合计
    const expensePriceTotal = expensePriceArr.reduce(
        (
            rt,
            {
                costUnitValue // 发货单关键数据
            }
        ) => (rt += costUnitValue || 0),
        0
    );
    expenseItemTotal = expenseItemTotal > quotationLowestFee ? expenseItemTotal : quotationLowestFee;
    const totalAmount = expenseItemTotal + expensePriceTotal;
    const estimatedAmount = expenseItemTotal * (1 + withholdingTax / 100) + expensePriceTotal;
    const afterTaxAmount = (expenseItemTotal * (1 + withholdingTax / 100)) / (1 + taxes / 100) + expensePriceTotal;
    // console.log('quotationLowestFee', totalAmount, typeof totalAmount === 'number' && totalAmount.toFixed(4) || 0)
    return {
        totalAmount: (typeof totalAmount === "number" && totalAmount.toFixed(4)) || 0,
        estimatedAmount: (typeof estimatedAmount === "number" && estimatedAmount && estimatedAmount.toFixed(4)) || 0,
        afterTaxAmount: (typeof afterTaxAmount === "number" && afterTaxAmount && afterTaxAmount.toFixed(4)) || 0,
        monthlyItems: monthlyItems
    };
};

/* 
仓储费用计算：
type: 类型
data: 数据
taxes: 发票税
withholdingTax: 补扣税
*/
export const costCalc = ({ type, taxes, withholdingTax, data }) => {
    // console.log('args', type, taxes, withholdingTax, data)
    let total = 0,
        taxesFirst = 0,
        taxesSecond = 0,
        monthlyItems = [];
    if (!taxes || !withholdingTax) {
        //  console.log('税率为0或不存在!')
        taxes = taxes || 0;
        withholdingTax = withholdingTax || 0;
    }
    const strategy = {
        // 合同价
        contract: () => {
            total = data.reduce((rt, { chargeFee, mileage, accountingStrategy, lowestFee, ...cur }) => {
                if (accountingStrategy === 2) {
                    //月结
                    monthlyItems.push(cur);
                } else {
                    //非月结
                    let cost =
                        (!isNaN(chargeFee) ? parseFloat(chargeFee) : 0) * (!isNaN(mileage) ? parseFloat(mileage) : 0); //当前费用
                    cost = lowestFee && !isNaN(lowestFee) && cost < lowestFee ? lowestFee : cost;
                    rt += cost;
                }
                return rt;
            }, 0);
            // 应收金额
            taxesFirst = withholdingTax ? total * (1 + withholdingTax / 100) : total;
            // 未税金额
            taxesSecond = taxes ? taxesFirst / (1 + taxes / 100) : taxesFirst;
            return {
                total,
                taxesFirst,
                taxesSecond,
                monthlyItems
            };
        },
        // 现结价
        cash: () => {
            total = data.reduce(
                (rt, { costUnitValue, ...cur }) => (rt += !isNaN(costUnitValue) ? parseFloat(costUnitValue) : 0),
                0
            );
            // 应收金额
            taxesFirst = total;
            // 未税金额
            taxesSecond = total;
            return {
                total,
                taxesFirst,
                taxesSecond,
                monthlyItems
            };
        }
    };
    return strategy[type]();
};

/*
 * 仓储费用单个费用项计算
 * item: 费用项
 * type: 类型 contract: 合同价, cash: 现结价
 */
export const costItemCalc = (item, type = "contract") => {
    let taxesFirst = 0,
        taxesSecond = 0;
    // console.log('item', item)
    const reg = /\./;
    const strategy = {
        contract: () => {
            let cost =
                (!isNaN(item.chargeFee) ? parseFloat(item.chargeFee) : 0) *
                (!isNaN(item.mileage) ? parseFloat(item.mileage) : 0); //当前费用
            cost = item.lowestFee && !isNaN(item.lowestFee) && cost < item.lowestFee ? item.lowestFee : cost;
            if (item.accountingStrategy === 2) {
                taxesSecond = taxesFirst = cost;
            } else {
                taxesFirst = item.withholdingTax ? cost * (1 + item.withholdingTax / 100) : cost;
                taxesSecond = item.taxes ? taxesFirst / (1 + item.taxes / 100) : taxesFirst;
            }
            return {
                taxesFirst: reg.test(taxesFirst) ? taxesFirst.toFixed(4) : taxesFirst,
                taxesSecond: reg.test(taxesSecond) ? taxesSecond.toFixed(4) : taxesSecond
            };
        },
        cash: () => {
            taxesSecond = taxesFirst = !isNaN(item.costUnitValue) ? parseFloat(item.costUnitValue) : 0;
            return {
                taxesFirst: reg.test(taxesFirst) ? taxesFirst.toFixed(4) : taxesFirst,
                taxesSecond: reg.test(taxesSecond) ? taxesSecond.toFixed(4) : taxesSecond
            };
        }
    };
    return strategy[type]();
};

/* 下载文件 */
export const downloadFile = async (blob, fileName = "download.xlsx") => {
    let doma = document.createElement("a");
    doma.download = fileName;
    doma.href = URL.createObjectURL(blob); //绑定a标签
    doma.click(); //模拟点击实现下载
    await setTimeout(() => {
        //延时释放
        URL.revokeObjectURL(blob); //用URL.revokeObjectURL()来释放这个object URL
    }, 100);
};

/* 
导出数据到EXCEL
    fileName: 文件名
    data: 导出数据 [{}]
    keyMap: 表头映射对象 {}
*/
export const exportDataToExcel = (fileName = "download", data = [], keyMap = null) => {
    const s2ab = (s) => {
        if (typeof ArrayBuffer !== "undefined") {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        } else {
            var buf = new Array(s.length);
            for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }
    };
    let d = data;
    if (keyMap) {
        d = d.map((item) => {
            let obj = {};
            for (const k in keyMap) {
                let val = item[keyMap[k]];
                obj[k] = val;
            }
            return obj;
        });
    }

    const wopts = { bookType: "xlsx", bookSST: false, type: "binary" }; //这里的数据是用来定义导出的格式类型
    // const wopts = { bookType: 'csv', bookSST: false, type: 'binary' };//ods格式
    // const wopts = { bookType: 'ods', bookSST: false, type: 'binary' };//ods格式
    // const wopts = { bookType: 'xlsb', bookSST: false, type: 'binary' };//xlsb格式
    // const wopts = { bookType: 'fods', bookSST: false, type: 'binary' };//fods格式
    // const wopts = { bookType: 'biff2', bookSST: false, type: 'binary' };//xls格式
    const fName = `${fileName}.${wopts.bookType == "biff2" ? "xls" : wopts.bookType}`;
    const wb = { SheetNames: ["Sheet1"], Sheets: {}, Props: {} };
    wb.Sheets["Sheet1"] = XLSX.utils.json_to_sheet(d); //通过json_to_sheet转成单页(Sheet)数据
    const blob = new Blob([s2ab(XLSX.write(wb, wopts))], { type: "application/octet-stream" });
    downloadFile(blob, fName);
};

/* 
接口导出数据到EXCEL
*/
export const exportReqToExcel = async (res) => {
    console.log("res", res);
    let header = res.headers;
    console.log("header", header);
    try {
        if (!header["content-disposition"]) {
            throw Error("end");
        }
        let contentDisposition = header["content-disposition"];
        contentDisposition = contentDisposition.split(";")[1];
        let fileName = window.decodeURIComponent(contentDisposition.replace("filename=", ""));
        const blob = new Blob([res.data]);
        await downloadFile(blob, fileName);
    } catch (e) {
        console.log(e);
    }
};

/* 
接口导出数据到pdf
exportPdf:文件名
*/
export const exportReqToPDF = (res, fName = "download") => {
    let header = res.headers;
    let contentDisposition = header["content-disposition"] || `;${fName}.pdf`;
    // console.log('c', contentDisposition)
    contentDisposition = contentDisposition.split(";")[1];
    let fileName = window.decodeURIComponent(contentDisposition.replace("filename=", "")) || `${fName}.pdf`;
    const blob = new Blob([res.data]);
    downloadFile(blob, fileName);
};

/**
 * 配载现结车服务费计算
 * @param list : 现结车费用项
 * @param serviceFee : 服务费率
 */
export function calcServiceFee(list, serviceFee = 0, defalutVal = {}) {
    // 服务费费用项数据
    const rt = {
        expenseId: 178,
        expenseName: "服务费(无车人)",
        sendCarId: null,
        ...defalutVal,
        expenseValue: 0
    };
    const total = list.filter((item) => item.expenseId !== 178).reduce((t, item) => (t += item.expenseValue || 0), 0);
    const fm = (100 - serviceFee) / 100;
    rt.expenseValue = fm ? total / fm - total : 0;
    const result = [rt].concat(list);
    return result;
}

/**
 * 根据单位配置和物料数据获取费用项表关键数据key或value
 * @param {
 *      unitConfig: object, // 运输/仓储单位配置 { boardCount: { billingUnitList: array, materialUnitList: array }, boxCount, grossWeight, volume, quantity, other }
 *      type: number, // 类型 1:根据单位配置和费用项和物料数据获取费用项单位对应物料单位总量
 *      materialList: array, // 物料数据
 *      costItem: object, // 费用项
 * } param0 => result
 */
export function getResultByUnitRule({ unitConfig, type, materialList, costItem }) {
    unitConfig = unitConfig || {};
    type = type || 0;
    costItem = costItem || {};
    materialList = materialList || [];
    if (type === 0) {
        let result = 0;
        for (const key in unitConfig) {
            if (unitConfig.hasOwnProperty(key)) {
                const ele = unitConfig[key];
                if (
                    ele.billingUnitList &&
                    ele.billingUnitList.length &&
                    ele.billingUnitList.some((item) => item.billingUnitId === costItem.costUnitId)
                ) {
                    const theKey = key === "quantity" ? "quantityCount" : key;
                    result = materialList.reduce((rt, m) => (rt += m[theKey] || 0), 0);
                    // console.log('rt', theKey, result)
                }
            }
        }
        return result;
    }
    return null;
}

export const formatTime = (t, formatter = "YYYY-MM-DD HH:mm") => {
    return !!t ? moment(t).format(formatter) : "";
};

export const formatTimeAddOneDay = (t = moment(), formatter = "YYYY-MM-DD HH:mm") => {
    return moment(t).add("day", 1).format(formatter);
};

export const integrateObj = (state = {}, keys = []) => {
    const props = {};
    keys.forEach((key) => {
        props[key] = state[key];
    });
    return props;
};

export const setIntegrateObj = (keys = []) => {
    const state = {};
    keys.forEach((key) => {
        state[key] = null;
    });
    return state;
};

export const filterNullItem = (obj = {}) => {
    if (typeof obj !== "object") {
        return obj;
    }
    const ret = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const val = obj[key];
            if (val !== null && val !== "") {
                ret[key] = val;
            }
        }
    }
    return ret;
};

export const onReceiptCalculationV2 = (r) => {
    // 收货计算=>根据单位和单位件数计算收货数量
    // mainUnitId: null, // 主单位id
    // boxCoefficient: null, // 箱系数
    // boxUnitId: null, // 箱单位id
    // trayCoefficient: null, // 托盘系数
    // trayUnitId: null, // 托盘单位id
    // otherCoefficient: null, //其他系数
    // otherUnitId: null, // 其他单位id
    let rQuantity = 0; // 收货数量
    let rVolume = 0; // 收货总体积
    let rArea = 0; // 收货总面积
    let rGrossWeight = 0; // 收货总重量
    let rNetWeight = 0; // 收货总净重
    // console.log('onReceiptCalculation', r, r.otherUnitId && Number(r.unitId) === r.otherUnitId)
    if (r.unitId && r.expectedQuantityCount) {
        rQuantity = r.expectedQuantityCount;
        rVolume = rQuantity * (r.singleVolume || 0) || 0;
        rArea = rQuantity * (r.singleArea || 0) || 0;
        rGrossWeight = rQuantity * (r.singleWeight || 0) || 0;
        rNetWeight = rQuantity * (r.singleNetWeight || 0) || 0;
    }
    const rt = {
        rQuantity: Number(rQuantity.toFixed(2)) || 0,
        rVolume: Number(rVolume.toFixed(2)) || 0,
        rArea: Number(rArea.toFixed(2)) || 0,
        rGrossWeight: Number(rGrossWeight.toFixed(2)) || 0,
        rNetWeight: Number(rNetWeight.toFixed(2)) || 0,
        perUnitWeightName: r.perUnitWeightName || "", // 重量单位
        perUnitVolumeName: r.perUnitVolumeName || "", // 体积单位
        areaUnitName: r.areaUnitName || "" // 面积单位
    };
    return rt;
};

export const onReceiptCalculation = (r) => {
    // 收货计算=>根据单位和单位件数计算收货数量
    // mainUnitId: null, // 主单位id
    // boxCoefficient: null, // 箱系数
    // boxUnitId: null, // 箱单位id
    // trayCoefficient: null, // 托盘系数
    // trayUnitId: null, // 托盘单位id
    // otherCoefficient: null, //其他系数
    // otherUnitId: null, // 其他单位id
    let rQuantity = 0; // 收货数量
    let rVolume = 0; // 收货总体积
    let rArea = 0; // 收货总面积
    let rGrossWeight = 0; // 收货总重量
    let rNetWeight = 0; // 收货总净重
    // console.log('onReceiptCalculation', r, r.otherUnitId && Number(r.unitId) === r.otherUnitId)
    if (r.unitId && r.quantity) {
        if (r.mainUnitId && Number(r.unitId) === r.mainUnitId) {
            rQuantity = r.quantity;
        } else if (r.boxUnitId && Number(r.unitId) === r.boxUnitId) {
            rQuantity = r.quantity * (r.boxCoefficient || 0);
        } else if (r.trayUnitId && Number(r.unitId) === r.trayUnitId) {
            rQuantity = r.quantity * (r.trayCoefficient || 0);
        } else if (r.otherUnitId && Number(r.unitId) === r.otherUnitId) {
            rQuantity = r.quantity * (r.otherCoefficient || 0);
        }
        rVolume = rQuantity * (r.singleVolume || 0) || 0;
        rArea = rQuantity * (r.singleArea || 0) || 0;
        rGrossWeight = rQuantity * (r.singleWeight || 0) || 0;
        rNetWeight = rQuantity * (r.singleNetWeight || 0) || 0;
    }
    const rt = {
        rQuantity: Number(rQuantity.toFixed(2)) || 0,
        rVolume: Number(rVolume.toFixed(2)) || 0,
        rArea: Number(rArea.toFixed(2)) || 0,
        rGrossWeight: Number(rGrossWeight.toFixed(2)) || 0,
        rNetWeight: Number(rNetWeight.toFixed(2)) || 0,
        perUnitWeightName: r.perUnitWeightName || null, // 重量单位
        perUnitVolumeName: r.perUnitVolumeName || null, // 体积单位
        areaUnitName: r.areaUnitName || null // 面积单位
    };
    return rt;
};

export const onReceiptCalcByExpectedQuantityCount = (r) => {
    // 收货计算=>根据单位和收货数量计算单位件数
    let rQuantity = 0; // 单位件数
    let rVolume = 0; // 收货总体积
    let rArea = 0; // 收货总面积
    let rGrossWeight = 0; // 收货总重量
    let rNetWeight = 0; // 收货总净重
    if (r.unitId && isNumber(r.expectedQuantityCount)) {
        if (r.mainUnitId && Number(r.unitId) === r.mainUnitId) {
            rQuantity = r.expectedQuantityCount || 0;
        } else if (r.boxUnitId && Number(r.unitId) === r.boxUnitId) {
            rQuantity = r.boxCoefficient ? Math.ceil((r.expectedQuantityCount || 0) / r.boxCoefficient) : 0;
        } else if (r.trayUnitId && Number(r.unitId) === r.trayUnitId) {
            rQuantity = r.trayCoefficient ? Math.ceil((r.expectedQuantityCount || 0) / r.trayCoefficient) : 0;
        } else if (r.otherUnitId && Number(r.unitId) === r.otherUnitId) {
            rQuantity = r.otherCoefficient ? Math.ceil((r.expectedQuantityCount || 0) / r.otherCoefficient) : 0;
        }
        rVolume = r.expectedQuantityCount * (r.singleVolume || 0) || 0;
        rArea = r.expectedQuantityCount * (r.singleArea || 0) || 0;
        rGrossWeight = r.expectedQuantityCount * (r.singleWeight || 0) || 0;
        rNetWeight = r.expectedQuantityCount * (r.singleNetWeight || 0) || 0;
    }
    return {
        rQuantity: Number(rQuantity.toFixed(2)) || 0,
        rVolume: Number(rVolume.toFixed(2)) || 0,
        rArea: Number(rArea.toFixed(2)) || 0,
        rGrossWeight: Number(rGrossWeight.toFixed(2)) || 0,
        rNetWeight: Number(rNetWeight.toFixed(2)) || 0
    };
};

export const getTheTabData = (global) => {
    let result = null;
    for (let i = 0; i < global.openedTabs.length; i++) {
        const tab = global.openedTabs[i];
        if (tab.key === global.activeTabKey) {
            result = tab || null;
            break;
        }
    }
    return result;
};
