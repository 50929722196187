const m = "wms/wave";
const m2 = "wms/shipmentManage";
const exportUrl = "wms/export";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS",
    name: "波次计划明细",
    type: "menu_hide",
    method: "GET",
    apiName: "getShipmentWaveById",
    url: `${m}/getOne`,
    paramsType: 1
};

export const children = {
    CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_CONFIRM",
        name: "确认",
        type: "view",
        apiName: "shipmentWaveConfirm",
        method: "GET",
        url: `${m}/confirm`,
        paramsType: 1
    },
    UN_CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_UN_CONFIRM",
        name: "取消确认",
        type: "view",
        apiName: "shipmentWaveCancelConfirm",
        method: "GET",
        url: `${m}/cancel`,
        paramsType: 1
    },
    STOP: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_STOP",
        name: "中止计划",
        type: "view"
        // apiName: 'shipmentWaveStop',
        // method: 'POST',
        // url: `${m}/terminationReceipt`
    },
    FINISH: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_FINISH",
        name: "波次完成",
        type: "view",
        apiName: "shipmentWaveFinish",
        method: "GET",
        url: `${m}/finish`,
        paramsType: 1
    },
    SHIPMENT_LIST: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SHIPMENT_LIST",
        apiName: "shipmentWaveShipmentList2",
        method: "GET",
        name: "获取出货单列表(播种式分拣/边拣边分)",
        type: "view",
        url: `${m}/getWaveShipment`,
        paramsType: 1,
        isOnlyApi: true
    },
    SHIPMENT_DEL1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SHIPMENT_DEL1",
        name: "出货单删除",
        type: "view"
        // apiName: 'shipmentWaveShipmentDelete1',
        // method: 'GET',
        // url: `${m}/seeding/getOne`,
    },
    SHIPMENT_DEL2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SHIPMENT_DEL2",
        type: "view",
        name: "出货单删除(边拣边分)",
        apiName: "shipmentWaveShipmentDelete2",
        method: "POST",
        url: `${m}/deleteShipmentManage`,
        isOnlyApi: true
    },
    PLAN_LIST1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_PLAN_LIST1",
        name: "波次计划单列表(播种式分拣)",
        type: "view",
        apiName: "shipmentWavePlanList1",
        method: "GET",
        url: `${m}/seeding/getOne`,
        paramsType: 1,
        isOnlyApi: true
    },
    PLAN_LIST2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_PLAN_LIST2",
        apiName: "shipmentWavePlanList2",
        method: "GET",
        name: "波次计划单列表(边拣边分)",
        type: "view",
        url: `${m}/getOneDetail`,
        paramsType: 1,
        isOnlyApi: true
    },
    SYSTEM_ALLOCATE1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SYSTEM_ALLOCATE1",
        name: "系统分配",
        type: "view",
        apiName: "shipmentWaveSystemAllocate1",
        method: "GET",
        url: `${m}/seeding/assignAuto`,
        paramsType: 1
    },
    SYSTEM_ALLOCATE2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SYSTEM_ALLOCATE2",
        name: "系统分配(边拣边分)",
        type: "view",
        apiName: "shipmentWaveSystemAllocate2",
        method: "GET",
        url: `${m2}/wave/assignAuto`,
        paramsType: 1,
        isOnlyApi: true
    },
    INVENTORY: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_INVENTORY",
        apiName: "shipmentWaveInventory",
        method: "GET",
        name: "获取可用库存列表数据(播种式分拣)",
        type: "view",
        url: `${m}/seeding/getAvailableStockList`,
        paramsType: 1,
        isOnlyApi: true
    },
    HAND_ALLOCATE: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_HAND_ALLOCATE",
        apiName: "shipmentWaveHandAllocate",
        name: "手动分配",
        type: "view",
        method: "POST",
        url: `${m}/seeding/saveDistribution`
    },
    ALLOCATE_LIST1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_ALLOCATE_LIST1",
        name: "获取分配清单列表(播种式分拣)",
        type: "view",
        apiName: "shipmentWaveAllocateList1",
        method: "GET",
        url: `${m}/seeding/distribution`,
        paramsType: 1,
        isOnlyApi: true
    },
    ALLOCATE_LIST2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_ALLOCATE_LIST2",
        apiName: "shipmentWaveAllocateList2",
        method: "GET",
        name: "获取分配清单列表(边拣边分)",
        type: "view",
        url: `${m}/getDistributionList`,
        paramsType: 1,
        isOnlyApi: true
    },
    CANCEL_ALLOCATE1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_CANCEL_ALLOCATE1",
        name: "撤销分配",
        type: "view",
        apiName: "shipmentWaveCancelAllocate1",
        method: "GET",
        url: `${m}/seeding/cancelDistribution`,
        paramsType: 1
    },
    CANCEL_ALLOCATE2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_CANCEL_ALLOCATE2",
        apiName: "shipmentWaveCancelAllocate2",
        method: "GET",
        name: "撤销分配(边拣边分)",
        type: "view",
        url: `${m2}/wave/cancelAllocation`,
        paramsType: 1,
        isOnlyApi: true
    },
    ALLOCATE_FINISH1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_ALLOCATE_FINISH1",
        name: "分配完成",
        type: "view",
        url: `${m}/seeding/finishDistribution`,
        paramsType: 1,
        apiName: "shipmentWaveAllocateFinish1",
        method: "GET"
    },
    ALLOCATE_FINISH2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_ALLOCATE_FINISH2",
        name: "分配完成(边拣边分)",
        type: "view",
        url: `${m2}/wave/assignmentCompleted`,
        paramsType: 1,
        apiName: "shipmentWaveAllocateFinish2",
        method: "GET",
        isOnlyApi: true
    },
    SYSTEM_PICK1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SYSTEM_PICK1",
        name: "快速拣货",
        type: "view",
        apiName: "shipmentWaveSystemPick1",
        method: "GET",
        url: `${m}/seeding/pick/fast`,
        paramsType: 1
    },
    SYSTEM_PICK2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SYSTEM_PICK2",
        name: "快速拣货(边拣边分)",
        apiName: "shipmentWaveSystemPick2",
        method: "GET",
        type: "view",
        url: `${m2}/wave/pick/fast`,
        paramsType: 1,
        isOnlyApi: true
    },
    PICK_LIST1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_PICK_LIST1",
        name: "获取拣货清单列表(播种式分拣)",
        type: "view",
        apiName: "shipmentWavePickList1",
        method: "GET",
        url: `${m}/seeding/picking`,
        paramsType: 1,
        isOnlyApi: true
    },
    PICK_LIST2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_PICK_LIST2",
        apiName: "shipmentWavePickList2",
        method: "GET",
        name: "获取拣货清单列表(边拣边分)",
        type: "view",
        url: `${m}/pickList`,
        paramsType: 1,
        isOnlyApi: true
    },
    CANCEL_PICK: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_CANCEL_PICK",
        apiName: "shipmentWaveCancelPick",
        method: "POST",
        name: "撤销拣货",
        type: "view",
        url: `${m}/seeding/cancelPicking`
    },
    FINISH_PICK1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_FINISH_PICK1",
        name: "拣货完成",
        type: "view",
        apiName: "shipmentWaveFinishPick1",
        method: "GET",
        url: `${m}/seeding/finishPicking`,
        paramsType: 1
    },
    FINISH_PICK2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_FINISH_PICK2",
        name: "拣货完成(边拣边分)",
        type: "view",
        apiName: "shipmentWaveFinishPick2",
        method: "GET",
        url: `${m2}/wave/pick/finish`,
        paramsType: 1,
        isOnlyApi: true
    },
    SYSTEM_PICK: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SYSTEM_PICK",
        name: "系统分拣",
        type: "view",
        apiName: "shipmentWaveSystemPick",
        method: "GET",
        url: `${m}/seeding/systemPicking`,
        paramsType: 1
    },
    // SORTING_LIST: {
    //     id: 'WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SORTING_LIST',
    //     name: '获取分拣清单列表(播种式分拣)',
    //     type: 'view',
    //     apiName: 'shipmentWaveSortingList',
    //     method: 'GET',
    //     url: `${m}/seeding/sort/list`,
    //     paramsType: 1,
    //     isOnlyApi: true
    // },
    // SERIAL: {
    //     id: 'WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SERIAL',
    //     apiName: 'shipmentWaveSerial',
    //     method: 'GET',
    //     name: '获取序列号列表',
    //     type: 'view',
    //     url: `wms/shipmentScanList`,
    //     paramsType: 1,
    //     isOnlyApi: true
    // },
    // SERIAL_SPLIT: {
    //     id: 'WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_SERIAL_SPLIT',
    //     apiName: 'shipmentWaveSerialDismantled',
    //     method: 'GET',
    //     name: '获取库存序列号列表',
    //     type: 'view',
    //     url: `wms/shipmentScanList/inventory`,
    //     paramsType: 1,
    //     isOnlyApi: true
    // },
    EXPORT_PLAN1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_EXPORT_PLAN1",
        name: "导出计划单",
        type: "view",
        apiName: "shipmentWaveExportPlan1",
        method: "POSTFILE",
        url: `${exportUrl}/wave/seedingDetail`
    },
    EXPORT_PLAN2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_EXPORT_PLAN2",
        name: "导出计划单(边拣边分)",
        type: "view",
        apiName: "shipmentWaveExportPlan2",
        method: "POSTFILE",
        url: `${exportUrl}/wave/detail`,
        isOnlyApi: true
    },
    EXPORT_ALLOCATE1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_EXPORT_ALLOCATE1",
        name: "导出分配单",
        type: "view",
        apiName: "shipmentWaveExportAllocate1",
        method: "POSTFILE",
        url: `${exportUrl}/wave/seedingDistribution`
    },
    EXPORT_ALLOCATE2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_EXPORT_ALLOCATE2",
        name: "导出分配单(边拣边分)",
        type: "view",
        apiName: "shipmentWaveExportAllocate2",
        method: "POSTFILE",
        url: `${exportUrl}/wave/distribution`,
        isOnlyApi: true
    },
    EXPORT_PICK1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_EXPORT_PICK1",
        name: "导出拣货单",
        type: "view",
        apiName: "shipmentWaveExportPick1",
        method: "POSTFILE",
        url: `${exportUrl}/wave/seedingPicking`
    },
    EXPORT_PICK2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_WAVE_DETAILS_EXPORT_PICK2",
        name: "导出分拣单",
        type: "view",
        apiName: "shipmentWaveExportSort",
        method: "POSTFILE",
        url: `${exportUrl}/wave/pickList`
    }
};
