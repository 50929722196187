import dva from "dva";
import createLoading from "dva-loading";

import global from "./global";
import wmsPackage from "./wms/package";
import wmsCheck from "./wms/check";
import order from "./tms/order";
import stowageCenter from "./tms/stowage_center";
import orderDistribute from "./tms/orderDistribute";

export const app = dva({
    onError(err) {
        // console.error('dva onError', err)
        err.preventDefault();
    }
});
app.use(
    createLoading({
        loading: {
            global: false,
            models: {}
        }
    })
);
export const initGlobalModels = (props) => {
    app.model({
        ...global,
        state: {
            ...global.state,
            ...props?.global?.state
        }
    });
    app.model(wmsPackage);
    // app.model(layoutState);
    app.model(wmsCheck);
    app.model(order);
    app.model(stowageCenter);
    app.model(orderDistribute);
};
