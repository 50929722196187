import React from "react";
import { app } from "@src/models";
import { Router, Switch, Route, Redirect } from "dva/router";
import Layout from "@src/layout/Main";
import Login from "./login";
import { ConfigProvider } from "antd";
import App from "../App";
import { connect } from "dva";
import ContentView from "@src/layout/Content";
import zhCN from "antd/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

import publicPageOrder from "@src/views/publicPage/order";

moment.locale("en");
@connect(({ global }) => ({
    isLogin: global.isLogin
}))
class LoginOrLayout extends React.Component {
    componentDidMount() {
        const { dispatch } = this.props;
        // console.log('app didMount start')
        dispatch({
            type: "global/init",
            payload: {
                page: "index"
            }
        });
    }

    render() {
        const { isLogin } = this.props;
        if (!isLogin) {
            return (
                <Redirect
                    to={{
                        pathname: "/login"
                    }}
                />
            );
        }
        return <Layout {...this.props} />;
    }
}

export const qiankunInitRoute = (props) => {
    app.router(({ history }) => {
        history.listen((location, action) => {
            // console.log('history', location, action)
            // app._store.dispatch({
            //     type: 'layoutState/updateCurrentPath',
            //     payload: {
            //         ...location
            //     }
            // });
        });
        return (
            <ConfigProvider
                locale={zhCN}
                // prefixCls={'comma'}
            >
                <App>
                    <Router history={history}>
                        <Switch>
                            <Route path="/" render={() => <ContentView {...props} />} />
                        </Switch>
                    </Router>
                </App>
            </ConfigProvider>
        );
    });
};

export default () => {
    app.router(({ history }) => {
        history.listen((location, action) => {
            // console.log('history', location, action)
            // app._store.dispatch({
            //     type: 'layoutState/updateCurrentPath',
            //     payload: {
            //         ...location
            //     }
            // });
        });
        return (
            <ConfigProvider
                locale={zhCN}
                // prefixCls={'comma'}
            >
                <App>
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/publicPageOrder" component={publicPageOrder} />
                            <Route exact path="/login" component={Login} />
                            <Route path="/" render={(props) => <LoginOrLayout {...props} />} />
                        </Switch>
                    </Router>
                </App>
            </ConfigProvider>
        );
    });
};
