import React, { Component } from "react";
import * as mobxState from "./store";
import { Provider } from "mobx-react";
import rApi from "@src/http";
import { windowMixin } from "./mixin.js";
import { connect } from "dva";

@connect(({ global }) => ({
    domWidth: global.domWidth,
    domHeight: global.domHeight,
    info: global.info
}))
@windowMixin()
class App extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        let domHeight = window.innerHeight;
        let domWidth = window.innerWidth;
        dispatch({ type: "global/setDomSizeAndTableHeight", payload: { domWidth, domHeight } });
        window.windowEvents = {
            resize: [],
            siderCollapsed: [],
            contentResize: []
        };
        window.addWindowEvents = (event, fn) => {
            if (window.windowEvents[event]) {
                window.windowEvents[event].push(fn);
            }
        };
        window.removeWindowEvents = (event, fn) => {
            if (window.windowEvents[event]) {
                window.windowEvents[event] = window.windowEvents[event].filter((item) => item !== fn);
            }
        };
        window.onresize = (event) => {
            // console.log('window.onresize ', window.windowEvents.contentResize)
            window.windowEvents.resize.forEach((fn) => {
                fn(event);
            });
            window.windowEvents.contentResize.forEach((fn) => {
                fn(event);
            });
        };
        window.addWindowEvents("resize", this.windowResize, false);
    }

    componentWillUnmount() {
        window.removeWindowEvents("resize", this.windowResize, false);
    }

    windowResize = (event) => {
        const { dispatch } = this.props;
        const domHeight = window.innerHeight;
        const domWidth = window.innerWidth;
        dispatch({ type: "global/setDomSizeAndTableHeight", payload: { domWidth, domHeight } });
    };

    componentDidCatch(error, errorInfo) {
        // this.setState({ error });
        // if (process.env.NODE_ENV === 'production') {
        //     if (this.Raven) {
        //         this.Raven.captureException(error, { extra: errorInfo, user:  'xxx'});
        //     }
        // }
    }

    render() {
        const { children } = this.props;
        return (
            <Provider {...mobxState} rApi={rApi}>
                {children}
            </Provider>
        );
    }
}

export default App;
