import RequestMethod from "./http";
import AllApi from "./all_api";
import _ from "@src/utils/lodash";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const allPower = require("../../libs/power.json");

const powerTreeToList = (powerTree) => {
    return powerTree.reduce((rt, power) => {
        if (!!power && !!power.children && power.children.length) {
            power.children = powerTreeToList(power.children);
            return rt.concat([power, ...power.children]);
        } else {
            return rt.concat([power]);
        }
    }, []);
};

const formatPowerToApi = (powerTree, requestMethod) => {
    try {
        const powerList = powerTreeToList(powerTree);
        // const list = treeToFlat(powerList)
        // let apiNameTo = {}
        // list.forEach(item => {
        //     if (item && item.apiName && item.url) {
        //         apiNameTo[item.apiName] = item.url
        //     }
        // })
        // console.log("powerList", list, apiNameTo);
        powerList.forEach((power) => {
            if (!!power.apiName && !!power.url && !!power.method && power.onlyIsPower !== true) {
                const apiName = power.apiName;
                if (!!requestMethod[apiName]) {
                    // console.error("requestApi已存在和apiName重复项>>>", requestMethod[apiName])
                } else {
                    requestMethod[apiName] = (params) => {
                        let url = power.url;
                        if (!!power.paramsType) {
                            if (power.paramsType === 1) {
                                url += `/${params}`;
                            } else if (power.paramsType === 2) {
                                if (!("paramsKeys" in power) || !(power.paramsKeys instanceof Array)) {
                                    console.error(
                                        `${apiName}方法缺少paramsKeys参数或者参数错误，请设置一个key Array声明该方法参数path key!`
                                    );
                                } else {
                                    power.paramsKeys.forEach((k) => {
                                        url += `/${params[k]}`;
                                    });
                                }
                            }
                        }
                        const realParams = [url];
                        if (!power.onlyPathParams) {
                            if ("paramsBodyKey" in power) {
                                realParams.push(params[power.paramsBodyKey]);
                            } else {
                                realParams.push(params);
                            }
                        }
                        return requestMethod[power.method].apply(requestMethod, realParams);
                    };
                }
            }
        });
    } catch (error) {
        console.error("formatPowerToApi Error", error);
    }
};

const requestApi = () => {
    const requestApi = new RequestMethod();
    for (let key in AllApi) {
        if (!requestApi[key]) {
            if (AllApi && typeof AllApi[key] === "function") {
                let api = AllApi[key].prototype;
                for (let name of Object.getOwnPropertyNames(api)) {
                    if (name !== "constructor") {
                        requestApi[name] = api[name].bind(requestApi);
                    }
                }
            } else {
                requestApi[key] = AllApi[key];
            }
        } else {
            console.error(`请求Api${key}名字发生重复`);
        }
    }
    formatPowerToApi(_.cloneDeep(allPower), requestApi);
    return requestApi;
};

export default requestApi();
