const m = "order/insurance/";

import getChildPowerJson from "../../../utils/getChildPowerJson";
import newPage from "../insurance_details/power_hide";

export const id = {
    id: "BUSINESS_MANAGEMENT_INSURANCE_LIST",
    method: "POST",
    name: "保险管理",
    type: "menu",
    apiName: "getInsuranceList",
    url: `${m}list`,
    sort: 6
};

const nPage = getChildPowerJson.toJson(newPage);

export const children = {
    [nPage.id]: nPage,
    EDIT: {
        id: "BUSINESS_MANAGEMENT_INSURANCE_LIST_EDIT",
        name: "编辑",
        type: "view"
    },
    DELETE: {
        id: "BUSINESS_MANAGEMENT_INSURANCE_LIST_DELETE",
        apiName: "deleteTransportInsurance",
        method: "GET",
        name: "删除",
        type: "view",
        url: `${m}delete`
    }
};
