import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class AccountPayableApi {
    /**
     * @param {any} params
     * @returns
     *
     * @memberOf AccountPayableApi
     */
}
