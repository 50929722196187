const moduleName1 = "resource/";
import getChildPowerJson from "../../../../utils/getChildPowerJson";
import newPage from "../add/power_hide";
const nPage = getChildPowerJson.toJson(newPage);

// const nPage = {
//     id: "CONTRACT_MGT_CARRIER_DETAILS",
//     method: "POST",
//     name: "承运商合同明细",
//     type: "menu_hide"
// };

export const id = {
    id: "CONTRACT_MGT_CARRIER_LIST",
    method: "POST",
    name: "承运商合同管理",
    type: "menu"
};

export const children = {
    [nPage.id]: nPage,
    ADD_DATA_CARRIER: {
        id: "CONTRACT_MGT_PROJECT_ADD_DATA1",
        apiName: "addContractCarrierMgt",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName1}carrierContract/save`
    },
    EDIT_DATA_CARRIER: {
        id: "CONTRACT_MGT_PROJECT_EDIT_DATA1",
        apiName: "editContracCarriertMgt",
        method: "POSTFILE",
        name: "编辑",
        type: "view",
        url: `${moduleName1}carrierContract/edit`
    },
    DEL_DATA_CARRIER: {
        id: "CONTRACT_MGT_PROJECT_DEL_DATA1",
        apiName: "deleteContractCarrierMgt",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName1}carrierContract/delete`
    }
};
