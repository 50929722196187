const moduleName = "finance/";
module.exports.id = {
    id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_ADD",
    method: "POST",
    name: "应收结算明细",
    type: "menu_hide",
    sort: 2
    // apiName: 'getUsers',
    // url: `${moduleName}list`
};

// let moduleName = ''

module.exports.children = {
    // ADD_PRA_ORDER: {
    //     id: 'RECEIVABLE_SETTLEMENT_MAINTENANCE_ADD_PRA_ORDER',
    //     apiName: 'addPraReceivableSettlementMaintenance',
    //     method: 'POST',
    //     name: '提交预发货单',
    //     type: 'view',
    //     url: `${moduleName}order/saveReceivableSettlementMaintenanceForm`
    // },
    SAVE_DATA: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_ADD_PRA",
        apiName: "addReceivableSettlementMaintenance",
        method: "POST",
        name: "调整单据",
        type: "view",
        url: `${moduleName}order/save`
    },
    ADD_SEND: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_ADD_SEND",
        name: "新建发货方",
        type: "view"
    },
    EDIT_SEND: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_EDIT_SEND",
        name: "编辑发货方",
        type: "view"
    },
    ADD_RECIVE: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_ADD_RECIVE",
        name: "新建收货方",
        type: "view"
    },
    EDIT_RECIVE: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_EDIT_RECIVE",
        name: "编辑收货方",
        type: "view"
    },
    QUOTATION_DETAILS: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_QUOTATION_DETAILS",
        name: "查看报价明细",
        type: "view"
    },
    HIDE_MONEY: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_HIDE_MONEY",
        name: "查看金额",
        type: "view"
    },
    CONFIRM_DATA: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_CONFIRM_DATA",
        apiName: "confirmReceivableSettlementMaintenance",
        method: "GET",
        name: "结算确认",
        type: "view",
        url: `${moduleName}receivableSettlement/lock/confirm`
    },
    CANCEL_DATA: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_CANCEL_DATA",
        apiName: "cancelReceivableSettlementMaintenance",
        method: "GET",
        name: "取消确认",
        type: "view",
        url: `${moduleName}receivableSettlement/lock/cancel`
    },
    SUPPLEMENTARY_EXPENSE: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_SUPPLEMENTARY_EXPENSE",
        name: "费用补录",
        type: "view"
    },
    LOCK_ORDER: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_LOCK_ORDER",
        apiName: "lockReceivableSettlementMaintenance",
        method: "GET",
        name: "锁单",
        type: "view",
        url: `${moduleName}receivableSettlement/lock`
    },
    PRESERVATION_COST: {
        id: "RECEIVABLE_SETTLEMENT_MAINTENANCE_PRESERVATION_COST",
        name: "保存费用",
        type: "view"
    }
};
