import load from "@src/layout/modules_load";
import { id } from "./powerhidehistory";

export default {
    [id.id]: {
        component: load(() => import("./index")),
        name: id.name,
        id: id
    }
};
