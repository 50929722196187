import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class DemandDetailsApi {
    /**
     * @param {any} params
     * @returns
     * @memberOf DemandDetailsApi
     */

    // 确认
    [children.CONFIRM_DATA.apiName](params) {
        let url = children.CONFIRM_DATA.url + "/" + params.id;
        return this.GET(url, params);
    }

    // 取消确认
    [children.CANCEL_DATA.apiName](params) {
        let url = children.CANCEL_DATA.url + "/" + params.id;
        return this.GET(url, params);
    }

    // 需求完结
    [children.FINISH.apiName](params) {
        let url = children.FINISH.url + "/" + params.id;
        return this.GET(url, params);
    }

    // 取消完结
    [children.UN_FINISH.apiName](params) {
        let url = children.UN_FINISH.url + "/" + params.id;
        return this.GET(url, params);
    }

    // 修改基本信息
    [children.EDIT_BASEINFO.apiName](params) {
        let url = children.EDIT_BASEINFO.url;
        return this.POST(url, params);
    }

    // 创建收货单
    [children.SAVE_RECEIPT.apiName](params) {
        let url = children.SAVE_RECEIPT.url;
        return this[children.SAVE_RECEIPT.method](url, params);
    }

    // 编辑收货单基本信息
    [children.EDIT_RECEIPT.apiName](params) {
        let url = children.EDIT_RECEIPT.url;
        return this[children.EDIT_RECEIPT.method](url, params);
    }

    // 删除收货单
    [children.DELETE_RECEIPT.apiName](params) {
        let url = children.DELETE_RECEIPT.url + `/${params.id}`;
        return this[children.DELETE_RECEIPT.method](url, params);
    }

    // 编辑收货单货物
    [children.EDIT_DETAILS.apiName](params) {
        let url = children.EDIT_DETAILS.url;
        return this[children.EDIT_DETAILS.method](url, params);
    }

    // 删除收货单货物
    [children.DELETE_DETAILS.apiName](params) {
        let url = children.DELETE_DETAILS.url + `/${params.id}`;
        return this[children.DELETE_DETAILS.method](url, params);
    }

    // 获取单条收货需求
    ReceiptDemandGetone(params) {
        let url = `wms/receiptDemand/getOne/${params.receiptDemandId}`;
        return this.GET(url, params);
    }

    // 新增/修改收货需求货物明细
    receiptDemandSaveOrUpdateCargoDetail(params) {
        let url = `wms/receiptDemand/saveOrUpdateCargoDetail`;
        return this.POST(url, params);
    }

    // 删除收货需求货物明细
    receiptDemandDeleteCargoDetail(params) {
        let url = `wms/receiptDemand/deleteCargoDetail/${params.id}`;
        return this.GET(url, params);
    }

    // 获取收货单列表
    getReceiptItemByReceiptDemandId(params) {
        let url = `wms/receiptDemand/getReceiptItemByReceiptDemandId/${params.receiptDemandId}`;
        return this.GET(url, params);
    }

    // // 导入货物明细
    // importReceiptDemandGoods (params) {
    //     let url = `wms/receiptDemand/batchSave`
    //     return this.POST(url, params)
    // }

    // 获取统计数据
    getReceiptDemandStatistics(params) {
        let url = `wms/receiptDemand/statistics/${params.id}`;
        return this.GET(url, params);
    }

    // 根据收货单id获取收货单数据
    getReceiptDemandCargoDetails(params) {
        let url = `wms/receiptManage/cargo/detail/${params.id}`;
        return this.GET(url, params);
    }
}
