const moduleName = "common/";
export const id = {
    id: "FRE_PROJECT_LIST",
    method: "POST",
    name: "核算项目",
    type: "menu",
    apiName: "getFrdProjectList",
    url: `${moduleName}frdProject/getAll`
};

export const children = {
    ADD_DATA: {
        id: "FRE_PROJECT_LIST_CODE_ADD_DATA",
        apiName: "addFrdProject",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}frdProject/add`
    },
    EDIT_DATA: {
        id: "FRE_PROJECT_LIST_EDIT_DATA",
        apiName: "editFrdProject",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}frdProject/edit`
    },
    DEL_DATA: {
        id: "FRE_PROJECT_LIST_CODE_DEL_DATA",
        apiName: "delFrdProject",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}frdProject/delete`
    }
    // LOOK_MORE: {
    //     id: 'LEGAL_PERSON_LIST_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
