import { children, id } from "./power";

export default class AllocateApi {
    [id.apiName](params) {
        let url = id.url;
        return this.POST(url, params);
    }

    [children.DELETE.apiName](id) {
        let url = children.DELETE.url + `/${id}`;
        return this.GET(url);
    }
}
