// 主版本 一般没有颠覆性升级不修改
export const MAJOR = 2;

// 次版本 有新模块添加可修改
export const MINOR = 1;

// 修订版本 bug修复修改
export const PATCH = 1;

// 打包时间
export const BUILD_DATE = 202409141610;

export default `${MAJOR}.${MINOR}.${PATCH}`;
