const moduleName = "client/";
import getChildPowerJson from "../../../../utils/getChildPowerJson";
import newPage from "../add/power_hide";
export const id = {
    id: "CONTRACT_MGT_PROJECT",
    method: "POST",
    name: "客户合同管理",
    type: "menu",
    apiName: "getContractMgtList",
    url: `${moduleName}clientContract/list`
};

// let moduleName = ''
const nPage = getChildPowerJson.toJson(newPage);

export const children = {
    [nPage.id]: nPage,
    ADD_DATA: {
        id: "CONTRACT_MGT_PROJECT_ADD_DATA",
        apiName: "addContractMgt",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}clientContract/save`
    },
    EDIT_DATA: {
        id: "CONTRACT_MGT_PROJECT_EDIT_DATA",
        apiName: "editContractMgt",
        method: "POSTFILE",
        name: "编辑",
        type: "view",
        url: `${moduleName}clientContract/edit`
    },
    DEL_DATA: {
        id: "CONTRACT_MGT_PROJECT_DEL_DATA",
        apiName: "deleteContractMgt",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}clientContract/delete`
    }
};
