const m = "wms/bale";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHIPMENT_PACKAGE",
    name: "出库打包",
    type: "menu"
    // method: 'POST',
    // apiName: 'accountPayableList',
    // url: `order/sendCar/list`
};

export const children = {
    PACKAGE_DETAIL: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_PACKAGE_PACKAGE_DETAIL",
        apiName: "getPackageDetail",
        method: "POST",
        name: "提交理货",
        type: "view",
        url: `${m}/getPackageDetail`
    },
    PACKAGE_CAR_GO_DETAILS: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_PACKAGE_PACKAGE_SUBMIT",
        method: "POST",
        apiName: "getPackageCarGoDetails",
        name: "扫描提交",
        type: "view",
        url: `${m}/getCargoDetails`
    },
    GENERATE_PACKING_NUMBER: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_GENERATE_PACKING_NUMBER",
        method: "GET",
        apiName: "generatePackingNumber",
        name: "生成包装编号",
        type: "view",
        url: `${m}/generatePackingNumber`,
        isOnlyApi: true
    },
    GET_LIST_BY_PACKING_NUMBER: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_GET_LIST_BY_PACKING_NUMBER",
        method: "POST",
        apiName: "getListByPackingNumber",
        name: "包装编号查包装材料",
        type: "view",
        url: `${m}/getListByPackingNumber`,
        isOnlyApi: true
    },
    BOX_LIST: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_BOX_LIST",
        method: "POST",
        apiName: "getBoxList",
        name: "装箱清单",
        type: "view",
        url: `${m}/list`,
        isOnlyApi: true
    },
    BOX_SAVE: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_BOX_SAVE",
        method: "POST",
        apiName: "saveInBox",
        name: "确认装箱",
        type: "view",
        url: `${m}/save`,
        isOnlyApi: true
    },
    BOX_ATTACH_SAVE: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_BOX_ATTACH_SAVE",
        method: "POST",
        apiName: "saveAttachBox",
        name: "修改附加信息",
        type: "view",
        url: `${m}/editExtraInfo`,
        isOnlyApi: true
    },
    GET_BATCH_NUMBER: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_GET_BATCH_NUMBER",
        method: "POST",
        apiName: "getBatchNumber",
        name: "获取批次号",
        type: "view",
        url: `${m}/getBatchNumber`,
        isOnlyApi: true
    }
};
