import React from "react";
import { Layout, Menu, Icon } from "antd";
import { connect } from "dva";
import QuotationIcon from "@src/libs/img/nvas/quotation";
import ContractIcon from "@src/libs/img/nvas/contract";

const { Sider } = Layout;
const { SubMenu } = Menu;

const iconType = {
    HOME: "iconfont home",
    CLIENT: "iconfont client",
    RESOURCE_MANAGEMENT: "iconfont resource_management",
    BASE_DATA: "iconfont base_data",
    SYSTEM_MANAGEMENT: "iconfont system_management",
    PROJECT_MANAGEMENT: "iconfont project_management",
    BUSINESS_MANAGEMENT: "iconfont business_management",
    WAREHOUSE_MANAGEMENT: "iconfont warehouse_management",
    FINANCIAL_MANAGEMENT: "iconfont financial_management",
    RECEIPT_MANAGEMENT_LIST: "iconfont receipt_management",
    CONTRACT_MGT: <ContractIcon />,
    QUOTATION_MANAGEMENT: <QuotationIcon />,
    SUPERVISION_REPORT: "iconfont supervision",
    SETTLEMENT: <Icon style={{ fontSize: "15px", marginRight: 0 }} type="calculator" />
};

@connect(({ global }) => ({
    menus: global.menus
}))
class Sidebar extends React.Component {
    state = {
        openKeys: []
    };

    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.collapsed !== this.props.collapsed && nextProps.collapsed) {
            this.setState({
                openKeys: []
            });
        }
    }

    onOpenChange = (openKeys) => {
        //展开一个另一个收缩
        if (openKeys && openKeys[openKeys.length - 1] && openKeys[openKeys.length - 1] === "HOME") {
            this.props.menuClick({
                key: "HOME"
            });
            return;
        }
        this.rootSubmenuKeys = this.props.menus.map((item) => item.code);
        const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : []
            });
        }
    };

    // 侧边栏导航菜单渲染
    renderMenu = (menus, deep = 0) => {
        // const fontSize = {
        //     fontSize: 14 - deep * 1
        // }
        return menus.map((menu) => {
            let code = menu.code || menu.id;
            if (
                menu.code === "APP_PDA" ||
                menu.code === "WE_APP" ||
                menu.code === "TABLE_HEADER" ||
                (menu.code === "WORD_BOOK" && menu.children.length < 1)
            ) {
                return null;
            }
            if (menu.children && menu.children.length > 0 && menu.type !== "menu" && menu.type !== "menu_hide") {
                return (
                    <SubMenu
                        key={code}
                        title={
                            <span>
                                {typeof iconType[menu.code] === "string" ? (
                                    <span className="anticon" style={{ verticalAlign: "middle" }}>
                                        <i className={iconType[menu.code]} style={{ fontSize: "14px" }}></i>
                                    </span>
                                ) : iconType[menu.code] ? (
                                    <span className="anticon" style={{ verticalAlign: "middle" }}>
                                        {iconType[menu.code]}
                                    </span>
                                ) : null}
                                <span title={menu.name} style={{ verticalAlign: "middle" }}>
                                    {menu.name}
                                </span>
                            </span>
                        }
                    >
                        {this.renderMenu(
                            menu.children.sort((curr, next) => (curr && next ? curr.sort - next.sort : 0)),
                            deep + 1
                        )}
                    </SubMenu>
                );
            } else if (menu.type === "menu") {
                if (menu.code === "HOME") {
                    return (
                        <Menu.Item className="home-menu-item" key={menu.code}>
                            {iconType[menu.code] ? (
                                <span className="anticon" style={{ verticalAlign: "middle" }}>
                                    <i className={iconType[menu.code]} style={{ fontSize: "14px" }}></i>
                                </span>
                            ) : null}
                            <span title={menu.name} style={{ verticalAlign: "middle" }}>
                                {menu.name}
                            </span>
                        </Menu.Item>
                    );
                }
                return (
                    <Menu.Item key={code}>
                        {iconType[menu.code] ? (
                            <span className="anticon" style={{ verticalAlign: "middle" }}>
                                <i className={iconType[menu.code]} style={{ fontSize: "14px" }}></i>
                            </span>
                        ) : null}
                        <span title={menu.name}>{menu.name}</span>
                    </Menu.Item>
                );
            }
        });
    };

    render() {
        const { collapsed, siderWidth, onCollapsed, selectedKeys, menuClick, menus } = this.props;
        const { openKeys } = this.state;
        // console.log('sidebarRender', selectedKeys)
        return (
            <Sider
                className={"paper-1 home-left-sider navs"}
                width={siderWidth}
                collapsible
                collapsedWidth={siderWidth}
                collapsed={collapsed}
                onCollapse={onCollapsed}
                theme="light"
            >
                <Menu
                    className="tree-menu"
                    style={{ width: siderWidth }}
                    mode="inline"
                    theme={"light"}
                    selectedKeys={selectedKeys}
                    // inlineCollapsed={collapsed}
                    openKeys={openKeys}
                    onClick={(e) => menuClick(e)}
                    onOpenChange={this.onOpenChange}
                >
                    {this.renderMenu(menus)}
                </Menu>
            </Sider>
        );
    }
}

export default Sidebar;
