// 0 - 已取消 1 - 待确认 2 - 已确认 3 - 提货中 4 - 运输中  5 - 已签收
export const STATUS = {
    0: "已取消",
    1: "待确认",
    2: "已确认",
    3: "运输中",
    4: "待签收",
    5: "已签收",
    6: "已回单"
};
export const STATUS_COLOR = {
    0: "#eee",
    1: "#b3b3b3",
    2: "#00bcd4",
    3: "#2196f3",
    4: "#b7ca03",
    5: "#05b50c",
    6: "#009688"
};
export const businessModelNameToString = {
    257: "零担",
    258: "整车",
    259: "快递"
    // 269: '客户自提'
};

export const SOURCE = {
    // 2: "货主",
    2: "ERP",
    1: "平台"
};
