const moduleName = "common/exchangeRate/";
export const id = {
    id: "BASIC_DATA_EXCHANGE_RATE",
    method: "POST",
    name: "汇率配置",
    type: "menu",
    apiName: "getExchangeRateList",
    url: `${moduleName}list`
};

export const children = {
    GET_EXCHANGE_RATEPOST: {
        id: "BBASIC_DATA_EXCHANGE_RATE_GET_EXCHANGE_RATEPOST",
        apiName: "getExchangeRatepost",
        method: "POST",
        name: "获取汇率",
        type: "view",
        url: `${moduleName}getExchangeRate`
    }
};
