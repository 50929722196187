import React from "react";
import { Layout, message } from "antd";
import HeaderTabs from "./header/HeaderTabs";
import logo from "@src/libs/img/logo.png";
import LikeQuotation from "./header/like_quotation";
import More from "./header/More";
import Sidebar from "./Sidebar";
import Content from "./Content";
import themeColorClient from "./themeColorClient";
import { connect } from "dva";

const updateTheme = (newPrimaryColor) => {
    if (newPrimaryColor) {
        const timeOut = 0;
        const hideMessage = message.loading("正在切换主题！", timeOut);
        themeColorClient.changeColor(newPrimaryColor).finally(() => hideMessage());
    }
};

@connect(({ global }) => ({
    openedTabs: global.openedTabs,
    activeTabKey: global.activeTabKey,
    siderWidth: global.siderWidth,
    siderCollapsed: global.siderCollapsed,
    platformName: global.platformName,
    logoUrl: global.logoUrl
}))
export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openKeys: [],
            selectedKeys: ["HOME"]
        };
    }

    // 侧边栏收起状态改变
    onCollapsed = (collapsed) => {
        const { dispatch } = this.props;
        dispatch({
            type: "global/updateSiderStatus"
        });
    };

    menuClick = (e) => {
        const { dispatch, openedTabs, activeTabKey } = this.props;
        if (activeTabKey === e.key) return;
        if (openedTabs.some((tab) => tab.key === e.key)) {
            dispatch({ type: "global/updateActiveTabKey", payload: { key: e.key } });
        } else {
            if (e.key !== "HOME") {
                dispatch({
                    type: "global/openTab",
                    payload: {
                        key: e.key,
                        component: e.key
                    }
                });
            } else {
                dispatch({ type: "global/updateActiveTabKey", payload: { key: "HOME" } });
            }
        }
        this.setState({ selectedKeys: [e.key] });
    };

    contextMenuClick = (e) => {
        const { dispatch } = this.props;
        // console.log('e.data', e.dataFromProvider, e.data)
        dispatch({
            type: `global/${e.data}`,
            payload: {
                index: e.dataFromProvider - 1
            }
        });
    };

    onSortEnd = ({ oldIndex, newIndex, collection, isKeySorting }) => {
        if (newIndex === 0) return;
        const { openedTabs, dispatch } = this.props;
        const newTabsList = [...openedTabs];
        const dragItem = {
            ...newTabsList.splice(oldIndex - 1, 1)[0]
        };
        // console.log('t', newTabsList, oldIndex, newIndex, dragItem)
        newTabsList.splice(newIndex - 1, 0, { ...dragItem });
        dispatch({ type: "global/updateOpenedTabs", payload: newTabsList });
    };

    changeColor = () => {
        updateTheme("#ff0000");
    };

    render() {
        let { selectedKeys } = this.state;
        const { activeTabKey, siderCollapsed, siderWidth, logoUrl, platformName } = this.props;
        selectedKeys = activeTabKey ? [activeTabKey] : selectedKeys;
        // console.log('layout render')

        return (
            <>
                <Layout.Header className="layout_header">
                    <div className="logo flex flex-vertical-center">
                        <img alt="logo" src={logoUrl || logo} />
                        <div className="logo_title flex1 text-overflow-ellipsis" title={platformName}>
                            {platformName}
                        </div>
                    </div>

                    <HeaderTabs
                        menuClick={this.menuClick}
                        contextMenuClick={this.contextMenuClick}
                        onSortEnd={this.onSortEnd}
                    />

                    <div className="flex flex-veritcal-center right_con" style={{ minWidth: 180 }}>
                        {/* <Button onClick={this.changeColor}>
                        click
                    </Button> */}
                        {/* <LikeQuotation /> */}
                        <More />
                    </div>
                </Layout.Header>
                <Layout className="root_layout">
                    <Sidebar
                        siderWidth={siderWidth}
                        collapsed={siderCollapsed}
                        onCollapsed={this.onCollapsed}
                        menuClick={this.menuClick}
                        selectedKeys={selectedKeys}
                    />

                    <Layout className="layout_content" id="scroll-view" style={{ left: siderWidth }}>
                        <Content siderWidth={siderWidth} collapsed={siderCollapsed} menuClick={this.menuClick} />
                    </Layout>
                </Layout>
            </>
        );
    }
}
