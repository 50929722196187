const m = "wms/report";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHEET_RECEIVE",
    name: "收货报表",
    type: "menu",
    apiName: "getReportReceiptList",
    method: "POST",
    url: `${m}/receiptList`
};

export const children = {
    EXPORT: {
        id: "WAREHOUSE_MANAGEMENT_SHEET_RECEIVE_EXPORT",
        apiName: "exportReportData",
        method: "POSTFILE",
        name: "导出报表",
        type: "view",
        url: `wms/export/report/receiptData`
    }
};
