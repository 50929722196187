const prevUrl = "wms/shipmentDemand";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND",
    method: "POST",
    name: "出货需求",
    type: "menu",
    apiName: "getShipmentDemand",
    url: `${prevUrl}/list`
};

export const children = {
    ADD_DATA: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_ADD_DATA",
        apiName: "addShipment",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${prevUrl}/save`
    },
    EDIT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_EDIT_DATA",
        apiName: "editShipment",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${prevUrl}/save`
    },
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_DEL_DATA",
        apiName: "delShipment",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${prevUrl}/delete`
    },
    // EXPORT: {
    //     id: 'WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_EXPORT',
    //     name: '导出',
    //     type: 'view',
    //     apiName: 'exportShipment',
    //     method: 'POSTFILE',
    //     url: `${prevUrl}/exportShipment`
    // },
    SAVE_DATA: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_SAVE_DATA",
        name: "保存",
        type: "view"
    },
    IMPORT_MATERIALS: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_IMPORT_MATERIALS",
        name: "导入",
        type: "view"
    },
    EXPORT_MATERIALS_HEADER: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_EXPORT_MATERIALS_HEADER",
        name: "导出表头",
        type: "view"
    },
    ADD_MATERIALS: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_DEMAND_ADD_MATERIALS",
        name: "添加货物明细",
        type: "view"
    }
};
