import React, { Component } from "react";
import ModalQuotation from "./modal_quotation";
import quotation_icon from "@src/libs/img/quotation.svg";
// import rApi from '@src/http'
import "./index.less";

/**
 * 报价查询
 * @class LikeQuotation
 * @extends {Component}
 */
class LikeQuotation extends Component {
    show = () => {
        this.modalQuotation.show();
    };

    render() {
        return (
            <div className="right-bottom">
                <span
                    onClick={this.show}
                    style={{
                        color: "white",
                        verticalAlign: "middle",
                        fontSize: 16,
                        padding: 4
                    }}
                >
                    <img src={quotation_icon} />
                </span>
                <ModalQuotation getThis={(v) => (this.modalQuotation = v)} />
            </div>
        );
    }
}

export default LikeQuotation;
