const prevUrl = "finance/receivableReconciliation";

export const id = {
    id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE",
    method: "POST",
    name: "运输应收对账",
    type: "menu",
    apiName: "accountReceivableList",
    url: `finance/receivableSettlement/page`
};

export const children = {
    ADD_ACCOUNT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_ADD_ACCOUNT",
        apiName: "addAccountReceivable",
        method: "POST",
        name: "生成对账单",
        type: "view",
        url: `${prevUrl}/generate`
    },
    EDIT_ACCOUNT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_EDIT_ACCOUNT",
        apiName: "editAccountReceivable",
        method: "POST",
        name: "编辑应收对账",
        type: "view",
        url: `${prevUrl}/edit`
    },
    SAVE: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_SAVE",
        apiName: "saveAccountReceivable",
        method: "POST",
        name: "保存",
        type: "view",
        url: `${prevUrl}/update`
    },
    SUBMIT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_SUBMIT",
        apiName: "submitAccountReceivable",
        method: "POST",
        name: "提交",
        type: "view",
        url: `${prevUrl}/submit`
    },
    CLIENT_CONFIRM: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_CONFIRM_ACCOUNT",
        apiName: "confirmAccountReceivable",
        method: "POST",
        name: "客服确认",
        type: "view",
        url: `${prevUrl}/serviceReview`
    },
    CLIENT_REJECT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_CLIENT_REJECT",
        apiName: "clientRejectAccountReceivable",
        method: "POST",
        name: "客服驳回",
        type: "view",
        url: `${prevUrl}/serviceReview`
    },
    CLIENT_CANCEL: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_CLIENT_CANCEL",
        apiName: "cancelAccountReceivable",
        method: "POST",
        name: "客服取消确认",
        type: "view",
        url: `${prevUrl}/serviceCancelConfirm`
    },
    FINANCE_CONFIRM: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_FINANCE_CONFIRM",
        apiName: "confirmFinanceReceivable",
        method: "POST",
        name: "财务确认",
        type: "view",
        url: `${prevUrl}/financeReview`
    },
    FINANCE_CANCEL: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_FINANCE_CANCEL",
        apiName: "cancelFinanceReceivable",
        method: "POST",
        name: "财务取消确认",
        type: "view",
        url: `${prevUrl}/financeCancelConfirm`
    },
    FINANCE_REJECT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_CANCEL_REJECT",
        apiName: "cancelRejectFinanceReceivable",
        method: "POST",
        name: "财务驳回",
        type: "view",
        url: `${prevUrl}/financeReview`
    },
    DEL_ACCOUNT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_DEL_ACCOUNT",
        apiName: "delAccountReceivable",
        method: "POST",
        name: "删除应收对账",
        type: "view",
        url: `${prevUrl}/delete`
    },
    ADD_DATA: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_LIST_ADD_DATA",
        //apiName: 'addCar',
        // method: 'POST',
        name: "对账单上传",
        type: "view"
        //url: `${moduleName}order/save`
    },
    EDIT_DATA: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_EDIT_DATA",
        //apiName: 'addCar',
        // method: 'POST',
        name: "对账单编辑",
        type: "view"
        //url: `${moduleName}order/save`
    },
    DEL_DATA: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_DEL_DATA",
        //apiName: 'addCar',
        //method: 'POST',
        name: "对账单删除",
        type: "view"
        //url: `${moduleName}order/save`
    },
    RECEIPT_CONFIRM: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_RECEIPT_CONFIRM",
        apiName: "confirmByCustomer",
        method: "POST",
        name: "确认",
        type: "view",
        url: `${prevUrl}/confirmByCustomer`
    },
    RECEIPT_CANCEL_CONFIRM: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_RECEIPT_CANCEL_CONFIRM",
        apiName: "cancelConfirmAccountReceivable",
        method: "POST",
        name: "取消提交",
        type: "view",
        url: `${prevUrl}/cancelConfirm`
    },
    EXPORT_ACCOUNT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_EXPORT_ACCOUNT",
        apiName: "exportAccountReceivableList",
        method: "POSTFILE",
        name: "导出应收对账",
        type: "view",
        url: `order/order/exportData`
    },
    EXPORT_LIST: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_EXPORT_LIST",
        apiName: "exportReceivableReconciliationQueryConditionList",
        method: "POSTFILE",
        name: "应收对账导出(汇总表)",
        type: "view",
        url: `${prevUrl}/exportReceivableReconciliationQueryConditionList`
    },
    EXPORT_DETAILS: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_EXPORT_DETAILS",
        apiName: "exportAccountReceivableDetails",
        method: "GETFILE",
        name: "应收对账导出(明细表)",
        type: "view",
        url: `${prevUrl}/exportAccountReceivableDetails`
    },
    LOGS: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_LOGS",
        name: "日志",
        type: "view"
    },
    AUDIT_CONFIRM: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_AUDIT_CONFIRM",
        method: "POST",
        name: "审核通过",
        type: "view"
    },
    AUDIT_REJECT: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_RECEIVABLE_AUDIT_REJECT",
        method: "POST",
        name: "审核驳回",
        type: "view"
    }
};
