import { deleteNull } from "@src/utils";

export default class payableApi {
    payableSettlementBatchCancel(ids) {
        const url = `finance/payableSettlement/lock/batchCancel`;
        return this.POST(url, ids);
    }
    payableSettlementExport(ids) {
        const url = `finance/payableSettlement/export`;
        return this.POSTFILE(url, ids);
    }
    payableSettlementExportList(ids) {
        const url = `finance/payableSettlement/exportList`;
        return this.POSTFILE(url, ids);
    }
}
