import React, { Component } from "react";
import { message, Button, Popconfirm, Pagination, Checkbox, Icon } from "antd";
import Table from "../table";
import rApi from "@src/http";
import * as TablePlugin from "./action.jsx";
import PropTypes from "prop-types";
import FunctionPower, { showPower } from "@src/layout/power_view/function.jsx";
// import { isArray } from '@src/utils'
import UploadExcel from "@src/components/upload_excel";
import HeaderSetting from "./header_setting";
import "./tableview.less";
import { isArray } from "lodash";

let temp = 1;

class SelectionCell extends React.Component {
    onChange = (e) => {
        const { rowData, column } = this.props;
        const { rowSelection } = column;
        // console.log('onChange', rowData, rowIndex, column, rowSelection, e.target)
        rowSelection.onSelect(rowData, e.target.checked);
    };

    render() {
        const { rowData, column } = this.props;
        const { selectedPropsRowKeys, rowSelection } = column;
        const checked = selectedPropsRowKeys.some((r) => r.id === rowData.id);
        let disabled = false;
        try {
            disabled = rowSelection.getCheckboxProps(rowData).disabled;
        } catch (error) {
            // console.error('selection getCheckboxProps异常', error)
            disabled = false;
        }
        // console.log('SelectionCell render', column, selectedPropsRowKeys, checked)
        if (rowData.hideCheckBox) return null;
        return (
            <Checkbox
                checked={checked}
                onChange={this.onChange}
                disabled={disabled}
                onClick={(e) => e.stopPropagation()}
            ></Checkbox>
        );
    }
}

class SelectionHeader extends React.Component {
    filterDisabled(arr) {
        const { column } = this.props;
        const { rowSelection } = column;
        return arr.filter((item) => !rowSelection.getCheckboxProps(item).disabled);
    }

    onChange = (e) => {
        const { column } = this.props;
        const { rowSelection, dataSource, selectedPropsRowKeys } = column;
        const selected = e.target.checked;
        const selectedRows = selected ? this.filterDisabled(dataSource) : [];
        const changeRows = selected
            ? this.filterDisabled(dataSource).filter((row) => !selectedPropsRowKeys.some((r) => r.id === row.id))
            : this.filterDisabled(dataSource).filter((row) => selectedPropsRowKeys.some((r) => r.id === row.id));
        // console.log('onChange', e, selected, selectedRows, changeRows, selectedPropsRowKeys)
        rowSelection.onSelectAll(selected, selectedRows, changeRows);
    };

    get headerChecked() {
        const { dataSource, selectedPropsRowKeys } = this.props.column;
        const filterList = this.filterDisabled(dataSource);
        return filterList.length > 0 && filterList.every((row) => selectedPropsRowKeys.some((r) => r.id === row.id));
    }

    render() {
        // console.log('SelectionHeader render')
        const { column, checkDisabled } = this.props;
        const { dataSource, selectedPropsRowKeys, rowSelection } = column;
        // console.log('column', column, this.props)
        let checked = false;
        let indeterminate = false;
        let disabled = false;
        if (isArray(dataSource)) {
            checked = this.headerChecked;
            // console.log('dataSource', dataSource)
            indeterminate = !checked && dataSource.some((row) => selectedPropsRowKeys.some((r) => r.id === row.id));
            disabled = dataSource.every((row) => rowSelection.getCheckboxProps(row).disabled);
        } else {
            // console.log('dataSource', dataSource)
        }
        // console.log('checked', checked)
        // const disabled = dataSource.every(row => rowSelection.getCheckboxProps(row).disabled)
        return (
            <Checkbox
                checked={checked}
                indeterminate={checkDisabled ? !checkDisabled : indeterminate}
                onChange={this.onChange}
                disabled={checkDisabled || disabled}
            ></Checkbox>
        );
    }
}

const TableHeader = (props) => {
    let {
        power,
        onDeletes,
        TableHeaderChildren,
        TableHeaderTitle,
        TableHeaderStyle,
        TableHeaderButtonStyle,
        title,
        isHideHeaderButton,
        isHideAddButton,
        isHideDeleteButton,
        isHideDragButton,
        getPopupContainer,
        isRequired,
        isHideHeaderTitle,
        hasExportButton,
        exportButtonLoading,
        hasImportButton,
        getExcelData,
        importLoading,
        addText
    } = props;
    power = power || {};
    getPopupContainer = getPopupContainer || (() => document.body);
    // console.log('columns', props.TableHeaderTitle)
    return (
        <div className="flex flex-vertical-center">
            {isHideHeaderTitle ? null : (
                <div style={{ paddingLeft: 0, ...TableHeaderStyle }}>
                    {isRequired ? <span style={{ color: "red", fontWeight: "bold" }}>*</span> : ""}
                    {TableHeaderTitle || title}
                </div>
            )}
            <div className="flex1" style={{ textAlign: "right", ...TableHeaderButtonStyle }}>
                {isHideHeaderButton || isHideAddButton ? null : (
                    <FunctionPower power={power.ADD_DATA}>
                        <Button
                            onClick={props.onAdd}
                            style={{
                                marginRight:
                                    isHideDeleteButton && !hasImportButton && !hasExportButton && isHideDragButton
                                        ? 0
                                        : 10,
                                verticalAlign: "middle"
                            }}
                            size={window._size}
                        >
                            {addText}
                        </Button>
                    </FunctionPower>
                )}
                {
                    isHideHeaderButton || isHideDeleteButton
                        ? null
                        : showPower({
                              power: power.BATCH_DEL || power.DEL_DATA
                          })(
                              <Popconfirm
                                  title={`确定要删除所有选中项?`}
                                  onConfirm={onDeletes}
                                  okText="确定"
                                  cancelText="取消"
                              >
                                  <Button
                                      style={{
                                          marginRight:
                                              hasImportButton ||
                                              hasExportButton ||
                                              TableHeaderChildren ||
                                              !isHideDragButton
                                                  ? 10
                                                  : 0,
                                          verticalAlign: "middle"
                                      }}
                                      size={window._size}
                                  >
                                      删除
                                  </Button>
                              </Popconfirm>
                          )
                    // <FunctionPower power={power.BATCH_DEL || power.DEL_DATA}>
                    //     <Popconfirm
                    //         title={`确定要删除所有选中项?`}
                    //         onConfirm={onDeletes}
                    //         okText="确定"
                    //         cancelText="取消"
                    //     >
                    //         <Button style={{marginRight: (hasImportButton || hasExportButton || TableHeaderChildren || !isHideDragButton) ? 10 : 0, verticalAlign: 'middle'}}>
                    //             删除
                    //         </Button>
                    //     </Popconfirm>
                    // </FunctionPower>
                }
                {hasImportButton && (
                    <FunctionPower power={power.IMPORT}>
                        <UploadExcel getExcelData={getExcelData} loading={importLoading} />
                    </FunctionPower>
                )}
                {hasExportButton && (
                    <FunctionPower power={power.EXPORT}>
                        <Button
                            onClick={props.onExport}
                            style={{
                                marginRight: isHideHeaderButton || isHideDragButton ? 0 : 10,
                                verticalAlign: "middle"
                            }}
                            loading={exportButtonLoading}
                            size={window._size}
                        >
                            导出
                        </Button>
                    </FunctionPower>
                )}
                {TableHeaderChildren ? TableHeaderChildren : null}
                {isHideHeaderButton || isHideDragButton ? null : (
                    <Button
                        title={"列排序"}
                        size={window._size}
                        style={{ marginRight: 0, verticalAlign: "middle", minWidth: 24 }}
                        icon="setting"
                        onClick={props.onHeaderSetting}
                    />
                )}
            </div>
        </div>
    );
};

/**
 * 表格模板
 *
 * @class TableView
 * @extends {Component}
 */
class TableView extends Component {
    backRequestCols = {}; // 备份远程数据库数据
    static propTypes = {
        style: PropTypes.object, //样式
        isForceKey: PropTypes.bool, // 是否在无id的项中强制加入index字段
        onExport: PropTypes.func, // 导出 () => {}
        onRowClick: PropTypes.func, // 行点击事件
        columns: PropTypes.array.isRequired, // table columns 参照antd3.0以后table组件
        isNoneSelected: PropTypes.bool, // 是否不需要显示check列
        isNoneNum: PropTypes.bool, // 是否不需要显示序号列
        isNonePagination: PropTypes.bool, //是否不需要显示分页
        actionView: PropTypes.func, // 定制action view函数 , 函数
        actionWidth: PropTypes.number, // action列宽度, 必须填整数（最小为90）
        selectedPropsRowKeys: PropTypes.array, // 设置选中项的数组，以id为标识
        getCheckboxProps: PropTypes.func, // 设置table行checkbox点击状态 return {disabled: bool}
        isPreventActionEvent: PropTypes.bool, // 是否阻止actionView点击事件冒泡
        isNoneAction: PropTypes.bool, // 是否不需要显示操作栏
        tableHeight: PropTypes.number, // 表格高度
        columnKey: PropTypes.string, //列定义key值
        filterSortItems: PropTypes.array, //过滤列排序项，传入columns中的dataIndex
        isNoneScroll: PropTypes.bool, //是否不需要滚动
        noPadding: PropTypes.bool, // 是否取消公共样式默认padding
        noTitleBar: PropTypes.bool, //是否没有顶部栏
        onAdd: PropTypes.func, //表格顶部新建按钮click事件
        batchDel: PropTypes.func, // 表格顶部删除按钮 确认click事件
        onEditItem: PropTypes.func, //表格操作编辑 click事件 (record, index) => {} record:行数据, index: 行索引
        onLookItem: PropTypes.func, //表格操作查看 click事件 (record, index) => {} record:行数据, index: 行索引
        onSaveAddNewData: PropTypes.func, //表格操作编辑保存 click事件 (record, index) => {} record:行数据, index: 行索引
        onSaveDeleteNewData: PropTypes.func, //表格操作删除确认（保存） click事件 (record, index) => {} record:行数据, index: 行索引
        onDeleteItem: PropTypes.func, //表格操作删除确认(编辑) click事件 (record, index) => {} record:行数据, index: 行索引
        modalName: PropTypes.string, // 新建编辑按钮默认打开的弹窗指针名
        isCustomPagination: PropTypes.bool, //是否自定义分页显示
        handleTableChange: PropTypes.func, //表格改变触发
        isShowActionDel: PropTypes.bool, //是否显示action删除
        isHideHeaderTitle: PropTypes.bool, //是否显示表头
        isCusSource: PropTypes.bool, //是否自定义 dataSource
        sourceList: PropTypes.array, //自定义dataSource 数据
        cusNumColRender: PropTypes.func, //自定义序号列render方法 (t, record, index) => {}
        cusSelection: PropTypes.func, //自定义选择列 (otp) => {}
        cusLoading: PropTypes.bool, //自定义loading
        hasExportButton: PropTypes.bool, //是否需要表头导出
        exportButtonLoading: PropTypes.bool, //导出按钮loading
        addText: PropTypes.string, // 头部新建按钮文字
        edittext: PropTypes.string, //action编辑文字
        isNotFilter: PropTypes.bool, //是否不过滤拖拽
        type: PropTypes.string, // 多选/单选，checkbox or radio
        handleFilter: PropTypes.func, // 过滤func
        hasImportButton: PropTypes.bool, // 是否有导入按钮
        importExcel: PropTypes.func, // 导入方法
        numWidth: PropTypes.number, // 序号列宽度
        bordered: PropTypes.bool, // 是否有边框 默认有
        isUseTableMaxHeight: PropTypes.bool, // 是否使用表格最大高 若使用则当不是有height配
        showSettingExport: PropTypes.bool,
        isNotFixedSelection: PropTypes.bool,
        headerProps: PropTypes.object,
        tableType: PropTypes.string, // 表格类型 目前只有高级表格使用
        tableSettingSave: PropTypes.func,
        tableSettingReset: PropTypes.func
        // isNotFixedNum: PropTypes.bool,
        // rowClassName:PropTypes.func,//添加选中样式
    };

    static defaultProps = {
        actionAuth: [],
        loading: false,
        actionView: null,
        columns: [],
        isNoneSelected: false,
        isNoneNum: false,
        isNonePagination: false,
        isPreventActionEvent: false,
        columnKey: "columns",
        isNoneScroll: false,
        style: null,
        noPadding: false,
        noTitleBar: false,
        modalName: "addoredit",
        isShowActionDel: true,
        isCusSource: false,
        sourceList: [],
        cusNumColRender: null,
        cusSelection: null,
        cusLoading: false,
        isNotFilter: false,
        addText: "新建",
        bordered: true,
        isUseTableMaxHeight: false,
        cusFooter: null,
        showSettingExport: false,
        headerProps: {}
    };

    state = {
        selectedRowKeys: {},
        dataSource: [],
        limit: 20,
        pagination: { position: "bottom", pageSize: 20, current: 1, showSizeChanger: true },
        importLoading: false,
        filters: {}, // 筛选过滤数据
        loadError: false,
        tableTotalWidth: 500 // 表格宽度
    };

    originCol = null;

    constructor(props) {
        super(props);
        const { getThis } = props;
        this.parent = props.parent;
        this.state.cols = this.props.columns;
        if (this.parent) {
            this.parent.tableView = this;
        }

        if (getThis) {
            getThis(this);
        }
    }

    componentDidMount() {
        const { isCusSource, power, columnKey, tableKey, columns } = this.props;
        if (tableKey || (power && power.id)) {
            let tableKey = this.props.tableKey ? this.props.tableKey : power.id;
            if (!isCusSource) {
                this.setState({
                    loading: true
                });
                Promise.all([
                    this.initData(false),
                    this.getHeaderData({ tableKey, isHideDragButton: this.props.isHideDragButton })
                ])
                    .then((d) => {})
                    .catch((e) => {
                        console.log(e);
                    });
            } else {
                this.getHeaderData({ tableKey, isHideDragButton: this.props.isHideDragButton }, true);
            }
        } else {
            if (!isCusSource) {
                this.initData();
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, props) {
        if (
            (this.props.power && nextProps.power && nextProps.power.id && nextProps.power !== this.props.power) ||
            (this.props.tableKey && nextProps.tableKey && nextProps.tableKey !== this.props.tableKey)
        ) {
            let tableKey = nextProps.tableKey ? nextProps.tableKey : nextProps.power.id;
            this.getHeaderData({ tableKey, isHideDragButton: nextProps.isHideDragButton });
        }
        // 用于触发组件更新
        if (this.props.randomRender && this.props.randomRender !== nextProps.randomRender) {
            this.getData(() => {});
        }
    }

    /**
     * 获取表头数据
     *
     * @memberof TableView
     */
    getHeaderData({ tableKey, isHideDragButton }, isLoading = false) {
        const { isHideHeaderButton, columns, tableType } = this.props;
        if (isHideHeaderButton || isHideDragButton || tableType === "advance-table") {
            // 若没有设置表头按钮 不请求
            return;
        }
        if (isLoading) {
            this.setState({
                loading: true
            });
        }
        rApi.getTableHeaderByType({
            type: tableKey
        })
            .then((d) => {
                let da = [];
                if (d && d.length) {
                    da = d.sort((a, b) => a.sort - b.sort);
                }
                da.forEach(
                    (item) =>
                        (this.backRequestCols[item.key] = {
                            ...item,
                            title:
                                item.title && item.title.length > 0
                                    ? item.title
                                    : columns &&
                                      columns.find &&
                                      columns.find((ele) => ele.key === item.key) &&
                                      columns.find((ele) => ele.key === item.key).title
                        })
                );
                // if (this.parent.onColumnsChange) {
                //     this.parent.onColumnsChange(this.backRequestCols[item.key])
                // }

                this.updateColumns();
            })
            .catch((e) => {
                message.error(e.msg || "操作失败");
            })
            .finally((d) => {
                if (isLoading) {
                    this.setState({
                        loading: false
                    });
                }
            });
    }

    /**
     * 表头顺序调整保存
     *
     * @memberof TableView
     */
    onSubmitTableHeader = async (onCol) => {
        const { columns, columnKey, power } = this.props;
        let tableKey = this.props.tableKey ? this.props.tableKey : power.id;
        try {
            if (!tableKey) {
                throw { msg: "没有唯一标识" };
            }
            await rApi.onSaveTableHeader(onCol);
            message.success("操作成功");
            this.getHeaderData({ tableKey, columnKey, columns, isHideDragButton: this.props.isHideDragButton });
        } catch (error) {
            message.error(error.msg || "操作失败");
            throw error;
        }
    };

    // 删除表头数据
    onDeleteHeaderData = () => {
        const { power } = this.props;
        let tableKey = this.props.tableKey ? this.props.tableKey : power.id;
        let requestApi = rApi.clearTableHeaderByType({ type: tableKey });
        requestApi.then((d) => {
            this.backRequestCols = {};
            this.setState({});
        });
        return requestApi;
    };

    initData = (isNoLoading) => {
        let { dataSource } = this.state;
        if (dataSource.length < 1) {
            this.getData(() => {}, isNoLoading);
        }
    };

    handleTableChange = (pagination, filters, sorter) => {
        // console.log('handleTableChange', filters)
        const { isCusSource, handleFilter, handleTableChange } = this.props;
        let pager = { ...this.state.pagination };
        pager.current = pagination.current;
        // console.log('pagination', pagination)
        if (handleTableChange) {
            handleTableChange(pagination);
            return;
        }
        if (filters && isCusSource && handleFilter) {
            handleFilter(filters);
            return;
        }
        let state = {
            pagination: pager
        };
        if (filters) {
            state.filters = filters;
        }
        this.setState(state, () => {
            this.getData(() => {});
        });
    };

    getData = (callback, isNoLoading = false) => {
        let { getData, params, noLoading } = this.props;
        const { filters } = this.state;
        const pager = { ...this.state.pagination };
        params = params || {};
        // console.log('filters', filters)
        // 过滤项处理
        if (filters) {
            const filterKeys = Object.keys(filters);
            if (filterKeys && filterKeys.length) {
                let newFilters = {};
                for (let key of filterKeys) {
                    if (filters[key] instanceof Array && filters[key].length) {
                        let newKey = `${key}Filters`;
                        Object.assign(newFilters, {
                            [newKey]: filters[key]
                        });
                    }
                }
                params = Object.assign({}, params, { ...newFilters });
                // console.log('filters', filters, params, newFilters, filterKeys)
            }
        }
        if (!isNoLoading) {
            this.setState({ loading: noLoading ? false : true, loadError: false });
        }
        let dealParams = Object.assign(
            {},
            {
                offset: pager.pageSize * (pager.current - 1),
                limit: pager.pageSize,
                pageNo: pager.current,
                pageSize: pager.pageSize
            },
            params
        );
        const req = getData(dealParams);
        req.then((d) => {
            pager.total = d.total;
            pager.showTotal = (total) => `共 ${total} 条`;
            this.setState({
                dataSource: d.dataSource || [],
                pagination: pager
            });
            if ((typeof callback).toUpperCase() === "FUNCTION") {
                callback(d.dataSource);
            }
        })
            .catch((err) => {
                console.log("tableview_template getData Error:", err);
                this.setState({
                    dataSource: [],
                    loadError: true
                });
            })
            .finally((d) => {
                if (!isNoLoading) {
                    this.setState({ loading: false });
                }
            });
        return req;
    };

    clearDataSource = () => {
        this.setState({ dataSource: [] }, () => {
            // console.log('clearDataSource', this.state.dataSource)
            // callback()
        });
    };

    updateDataTableSource = (data, callback) => {
        // console.log('updateDataTableSource', data[2])
        this.setState(
            {
                dataSource: data
            },
            callback
        );
    };

    getTableSource = () => {
        return this.state.dataSource;
    };

    clearDataSource = () => {
        this.setState({
            dataSource: [],
            pagination: {
                ...this.state.pagination,
                total: 0,
                showTotal: `共 ${0} 条`
            }
        });
    };

    updateData = (d) => {
        let { dataSource } = this.state;
        dataSource = dataSource.map((value) => {
            if (value.id === d.id) {
                return Object.assign({}, value, d);
            }
            return value;
        });
        this.setState({
            dataSource: dataSource
        });
    };

    refresh = (callback, noLoading) => {
        this.setState({ selectedRowKeys: {}, loading: false }, () => {
            this.getData(callback, noLoading);
        });
    };

    onSearch = (callback, noLoading) => {
        this.setState(
            {
                selectedRowKeys: {},
                pagination: {
                    ...this.state.pagination,
                    current: 1
                }
            },
            () => {
                this.getData(callback, noLoading);
            }
        );
    };

    onShowSizeChange = (current, size) => {
        const pager = { ...this.state.pagination };
        pager.current = current;
        pager.pageSize = size;
        this.state.pagination = pager;
        this.setState({ pagination: pager }, () => {
            this.onSearch();
        });
    };

    setNextPage = () => {
        const pager = { ...this.state.pagination };
        return this.setPageNo(pager.current + 1);
    };

    setPrePage = () => {
        const pager = { ...this.state.pagination };
        return this.setPageNo(pager.current - 1);
    };

    setPageNo = (current) => {
        const pager = { ...this.state.pagination };
        pager.current = current;
        return new Promise((resolve, reject) => {
            if (current > Math.ceil(pager.total / pager.size)) {
                reject(1);
                return;
            }
            if (current < 1) {
                reject(2);
                return;
            }
            this.setState({ pagination: pager }, () => {
                this.getData()
                    .then((res) => {
                        resolve();
                    })
                    .catch((e) => {
                        reject(3);
                    });
            });
        });
    };

    getAddOrEdit = (d) => {
        this[this.props.modalName] = d;
    };

    /* 表格顶部 新建按钮 onClick 事件 */
    onAdd = () => {
        const { parent, onAdd, modalName } = this.props;
        if (onAdd) {
            onAdd();
        } else {
            if (parent && parent[modalName]) {
                parent[modalName].show({ edit: false });
            }
        }
    };

    /* 表格顶部 删除按钮 onClick 事件 */
    onDeletes = () => {
        let { selectedRowKeys, dataSource } = this.state;
        const { batchDel, onDeleteCallback } = this.props;
        let d = Object.keys(selectedRowKeys).map((item) => {
            return selectedRowKeys[item].id;
        });
        if (batchDel) {
            batchDel(d);
        } else {
            const req = this.delete(d);
            if (onDeleteCallback) {
                onDeleteCallback(req);
            }
        }
    };

    delete = (params) => {
        const { power, onDeleteCallback } = this.props;
        if (isArray(params)) {
            if (typeof params[0] === "object") {
                params = params.map((item) => {
                    return item.id;
                });
            }
        } else if (params) {
            if (typeof params === "object") {
                params = params.id;
            }
            params = [params];
        }
        // console.log('params', params)
        const req = rApi[power.DEL_DATA.apiName](params);
        req.then(() => {
            message.success("操作成功！");
            this.getData();
            if (onDeleteCallback) {
                onDeleteCallback();
            }
        }).catch((e) => {
            console.error("delete", e);
            message.error(e.msg || "操作失败！");
        });
        return req;
    };

    /* 表格操作列默认 编辑onClick 事件 */
    onEdit = (record, index) => {
        const { modalName } = this.props;
        if (this.parent && this.parent[modalName]) {
            this.parent[modalName].show({
                edit: true,
                data: record,
                index: index
            });
        }
    };

    /* 表格操作列默认 删除确认onClick 事件 */
    onDelete = (record, index) => {
        this.delete(record);
    };

    /* 表格操作列默认 查看onClick 事件 */
    onLook = (record, index) => {
        // todo 查看数据详情
        const { modalName, onLookItem } = this.props;
        if (onLookItem) {
            onLookItem(record, index);
            return;
        }
        if (this.parent && this.parent[modalName]) {
            this.parent[modalName].show({
                edit: false,
                data: record,
                index: index
            });
        }
    };

    onExport = () => {
        // todo 导出数据
        // console.log('onExport')
        if (this.props.onExport) {
            this.props.onExport();
        }
    };

    onChangeSelect = (keys, selectedRows) => {
        // console.log('onChangeSelect', selectedRows, keys)
        const { onChangeSelect } = this.props;
        let selectedRowKeys = this.state.selectedRowKeys;
        if (selectedRows) {
            selectedRowKeys[keys.id] = keys;
        } else {
            delete selectedRowKeys[keys.id];
        }
        // console.log('onChangeSelect', selectedRowKeys, selectedRows)
        if (onChangeSelect) {
            onChangeSelect(selectedRowKeys, {
                deleteKeys: selectedRows ? [] : [keys],
                addKeys: selectedRows ? [keys] : []
            });
        }
        // console.log('onChangeSelect', keys, selectedRows)
        this.setState({ selectedRowKeys: selectedRowKeys });
    };

    onSelectAll = (selected, selectedRows, changeRows) => {
        // console.log('onSelectAll', selected, selectedRows, changeRows)
        const { onChangeSelect } = this.props;
        let selectedRowKeys = {};
        if (selected) {
            selectedRows.forEach((ele) => {
                selectedRowKeys[ele.id + ""] = ele;
            });
        }
        if (onChangeSelect) {
            onChangeSelect(selectedRowKeys, {
                deleteKeys: selected ? [] : changeRows,
                addKeys: selected ? changeRows : []
            });
        }
        // console.log('onSelectAll', selected, selectedRows, changeRows )
        this.setState({ selectedRowKeys: selectedRowKeys });
    };

    getSelectKeys = ({ selectedPropsRowKeys, selectedRowKeys }) => {
        const { getSelectKeys } = this.props;
        // console.log('getSelectKeys', selectedPropsRowKeys, selectedRowKeys)
        if (getSelectKeys) {
            return getSelectKeys({ selectedPropsRowKeys, selectedRowKeys });
        }
        return selectedPropsRowKeys
            ? selectedPropsRowKeys.map((item) => {
                  return item.id;
              })
            : Object.keys(selectedRowKeys).map((item) => {
                  return selectedRowKeys[item].id;
              });
    };

    // 计算最后一行的宽度
    calColumnsWidth = (columns) => {
        const { isNoneSelected, tableHeight } = this.props;
        const { dataSource } = this.state;
        let isScrollY = false;
        const scrollHeight = tableHeight ? tableHeight : 500;
        const len = dataSource && dataSource.length ? dataSource.length : 0;
        if (len * 36 > scrollHeight) {
            isScrollY = true;
        }
        let assignedWidth = [];
        columns.map((item) => {
            if (item.width && item.width !== "auto") {
                assignedWidth.push(item.width);
            }
            return item;
        });
        let width = this.state.tableTotalWidth - (isScrollY ? 10 : 0) - (!isNoneSelected ? 46 : 0) || 1100;
        if (assignedWidth.length > 0) {
            width -= assignedWidth.reduce((pre, curr) => pre + curr);
        }
        let length = columns.length - assignedWidth.length;
        if (width / length < 60) {
            width = 150;
        }

        assignedWidth = [];
        columns = columns.map((item) => {
            let w = item.width;
            if (!item.width || (item.width === "auto" && !item.fixed)) {
                if (item.minWidth && (item.minWidth > width / length || item.minWidth > width)) {
                    w = item.minWidth;
                } else {
                    w = width / length;
                    return {
                        ...item,
                        width: w < 150 ? 150 : w
                    };
                }
            }
            assignedWidth.push(w);
            return {
                ...item,
                width: w
            };
        });
        // if (columns && columns.length && columns[columns.length - 1].fixed) {
        //     if (assignedWidth.reduce((pre, curr) => pre + curr) < 1100)
        //     columns[columns.length - 2].width =  (1100 - assignedWidth.reduce((pre, curr) => pre + curr)) > 100 ?
        //     1100 - assignedWidth.reduce((pre, curr) => pre + curr)
        //     :
        //     100
        // }
        return columns;
    };

    handleResize = ({ column, width }) => {
        const { parent, columnKey } = this.props;
        const size = { width };
        // console.log('handleResize', column, width)
        parent &&
            parent.setState &&
            parent.setState((state) => {
                const nextColumns = state[columnKey] ? [...state[columnKey]] : [];
                let index = null;
                nextColumns.map((item, i) => {
                    if (item.dataIndex === column.dataIndex && column.key !== "action") {
                        index = i;
                    }
                    return index;
                });
                if (typeof index === "number") {
                    if (size.width < 50) {
                        size.width = 50;
                    }
                    nextColumns[index] = {
                        ...nextColumns[index],
                        width: size.width
                    };
                }
                return { columns: nextColumns };
            });
    };

    /* 导入 */
    getExcelData = async (d) => {
        if (this.state.importLoading) return;
        await this.props.importExcel(d);
        this.setState({ importLoading: false });
    };

    // 表格宽度
    getTableAllWidth = (width) => {
        // console.log('width', width)
        this.setState({
            tableTotalWidth: width
        });
    };

    /**
     * 设置表头
     *
     * @memberof TableView
     */
    onHeaderSetting = () => {
        this.headerSitting.show({
            cols: this.getColumns()
        });
    };

    /**
     * 更新表头 col=>请求的表头 this.state[columnKey]=>本地表头
     *
     * @memberof Parent
     */
    updateColumns = (columns) => {
        // let { columns } = this.props
        if (!columns) {
            columns = this.props.columns.map((item) => ({ ...item }));
        }
        let col = this.backRequestCols;
        columns = columns
            .map((item) => ({
                ...item,
                ...col[item.key]
            }))
            .sort((a, b) => a.sort - b.sort);

        if (this.parent.onColumnsChange) {
            // console.log('onColumnsChange', columns)
            this.parent.onColumnsChange(columns.filter((item) => !item.isNoDisplay));
        }
        // console.log('columns', columns)
        this.setState({
            cols: columns
        });
    };

    getColumns = () => {
        let { columns } = this.props;
        let col = this.backRequestCols;
        columns = columns
            .map((item) => ({
                ...item,
                ...col[item.key]
            }))
            .sort((a, b) => a.sort - b.sort);
        // this.setState({
        //     cols: columns
        // })
        return columns;
    };

    render() {
        const {
            isForceKey,
            isNoneSelected,
            actionView, // 定制action view函数
            actionWidth, // actionView 宽度
            fixed,
            columns,
            power,
            onSaveAddNewData,
            onSaveDeleteNewData,
            onDeleteItem,
            onEditItem,
            isNoneAction,
            isNonePagination,
            isNoneNum,
            style,
            scroll,
            selectedPropsRowKeys, // 设置选中项的数组，以id为标识
            getCheckboxProps,
            rowKey,
            onRowClick, // 行点击
            isPreventActionEvent,
            getPopupContainer,
            calcCellWidth,
            tableHeight,
            tableWidth,
            isNoneScroll,
            noPadding,
            noTitleBar,
            isCustomPagination,
            isShowActionDel,
            isCusSource,
            sourceList,
            cusNumColRender,
            cusSelection,
            cusLoading,
            edittext,
            isNotFilter,
            type,
            numWidth,
            isUseTableMaxHeight,
            cusFooter,
            showSettingExport,
            isNotFixedSelection,
            isNotFixedNum,
            headerHeight,
            rowClassName
        } = this.props;
        const { dataSource, loading, loadError, pagination } = this.state;
        let { selectedRowKeys } = this.state;
        const selectionOpt = {
            fixed: isNotFixedSelection ? false : true,
            selectedRowKeys: this.getSelectKeys({
                selectedPropsRowKeys,
                selectedRowKeys
            }),
            onSelectAll: this.onSelectAll,
            onSelect: this.onChangeSelect,
            getCheckboxProps: (record) => {
                if (getCheckboxProps) {
                    // console.log('11', getCheckboxProps(record))
                    return getCheckboxProps(record);
                }
                // console.log(2)
                return {
                    disabled: false
                };
            },
            type: type || "checkbox "
        };
        const rowSelection = cusSelection !== null ? cusSelection(selectionOpt) : selectionOpt;

        let THeader = this.props.THeader || TableHeader;
        // if (isArray(cols) && cols.length > 0 && !calcCellWidth) {
        //     for (let i = cols.length - 1; i > 0 ; i--) {
        //         if (cols[i].width && !('fixed' in cols[i])) {
        //             cols[i] = {...cols[i]}
        //             delete cols[i].width
        //             break
        //         }
        //         if (!('fixed' in cols[i]) && !cols[i].width) {
        //             break
        //         }
        //     }
        // }
        // console.log('columns', cols)
        let cols = TablePlugin.addAll({
            isNoneAction,
            isNoneNum,
            actionView: actionView,
            columns: this.getColumns().filter((item) => !item.isNoDisplay),
            that: this,
            power: power,
            actionWidth: actionWidth,
            fixed: fixed || null,
            onSaveAddNewData: onSaveAddNewData,
            onSaveDeleteNewData: onSaveDeleteNewData,
            onDeleteItem: onDeleteItem,
            onEditItem: onEditItem,
            isPreventActionEvent: isPreventActionEvent,
            isShowActionDel,
            cusNumColRender,
            edittext,
            numWidth,
            isNotFixedNum,
            pagination
        });
        cols = this.calColumnsWidth(cols);
        const allWidth =
            cols instanceof Array &&
            cols.length &&
            cols.reduce((pre, curr) => {
                /* 表格所有列宽度 */
                if (typeof curr.width === "number") {
                    return { width: pre.width + curr.width };
                } else if (typeof curr.minWidth === "number") {
                    return { width: pre.width + curr.minWidth };
                } else {
                    return { width: pre.width + 100 };
                }
            }).width;

        const emptyText = () => {
            if (loadError) {
                return (
                    <div
                        style={{
                            color: "rgba(0, 0, 0, 0.25)",
                            cursor: "pointer",
                            textAlign: "center",
                            padding: "16px 0"
                        }}
                        onClick={this.refresh}
                    >
                        数据加载错误, 点击重新加载&emsp;
                        <Icon type="reload" />
                    </div>
                );
            }
            return <div style={{ color: "rgba(0, 0, 0, 0.25)", textAlign: "center", padding: "16px 0" }}>暂无数据</div>;
        };

        // 自定义selection
        if (!isNoneSelected) {
            temp = temp === 1 ? 2 : 1;
            const selectionColumn = {
                headerClassName: "BaseTable__table_selection_header",
                className: "BaseTable__table_selection",
                width: 46,
                align: "center",
                flexShrink: 0,
                resizable: false,
                fixed: isNotFixedSelection ? null : "left",
                key: "__selection__",
                dataIndex: "__selection__",
                headerRenderer: SelectionHeader,
                cellRenderer: SelectionCell,
                selectedPropsRowKeys: selectedPropsRowKeys || Object.values(selectedRowKeys),
                rowSelection,
                dataSource: isCusSource ? sourceList : dataSource,
                _temp: temp
            };
            // console.log('s', selectionColumn, rowSelection)
            cols = [selectionColumn, ...cols];
        }
        // if (!this.originCol) {
        //     this.originCol = cols.map(item => ({ ...item }))
        // }
        // console.log('tableview_template render', cols[5])
        const events = !!onRowClick
            ? {
                  onRow: (record, rowIndex) => {
                      return {
                          onClick: () => {
                              onRowClick(record, rowIndex);
                          }
                      };
                  }
              }
            : {};
        return (
            <div
                style={{ ...(noPadding ? { padding: 0 } : {}), ...style }}
                className={(this.props.className && `tableview-box ${this.props.className}`) || "tableview-box"}
            >
                {/* tableHeight: {tableHeight} */}
                <HeaderSetting
                    parent={this}
                    getThis={(v) => (this.headerSitting = v)}
                    filterSortItems={this.props.filterSortItems}
                    columns={columns}
                    backRequestCols={this.backRequestCols}
                    newColumns={this.getColumns()}
                    isNotFilter={isNotFilter}
                    onSubmitTableHeader={this.onSubmitTableHeader}
                    onDeleteHeaderData={this.onDeleteHeaderData}
                    tableKey={
                        this.props.tableKey ? this.props.tableKey : (this.props.power && this.props.power.id) || null
                    }
                    tableType={this.props.tableType}
                    tableSettingSave={this.props.tableSettingSave}
                    tableSettingReset={this.props.tableSettingReset}
                    showSettingExport={showSettingExport}
                />
                <Table
                    defaultExpandAllRows
                    headerProps={this.props.headerProps}
                    getRowStyle={this.props.getRowStyle}
                    getRowHeight={this.props.getRowHeight}
                    bordered={this.props.bordered}
                    size={this.props.size ? this.props.size : "default"}
                    getTableAllWidth={this.getTableAllWidth}
                    cusFooter={cusFooter}
                    getPopupContainer={getPopupContainer}
                    headerHeight={headerHeight}
                    rowClassName={(record, rowIndex, column) => {
                        if (rowClassName) {
                            rowClassName(record, rowIndex, column);
                        }
                    }}
                    {...events}
                    title={
                        noTitleBar
                            ? null
                            : this.props.THeader
                            ? () => THeader
                            : () => (
                                  <THeader
                                      {...this.props}
                                      getPopupContainer={getPopupContainer}
                                      onExport={this.onExport}
                                      onDeletes={this.onDeletes}
                                      power={power}
                                      columns={columns}
                                      backRequestCols={this.backRequestCols}
                                      onAdd={this.onAdd}
                                      isNotFilter={isNotFilter}
                                      getExcelData={this.getExcelData}
                                      onHeaderSetting={this.onHeaderSetting}
                                  />
                              )
                    }
                    titleStyle={this.props.titleStyle}
                    scroll={
                        (isNoneScroll && { x: false, y: false }) ||
                        ("scroll" in this.props && scroll) || {
                            y: tableHeight ? tableHeight + 20 : false,
                            x: allWidth + (tableWidth || tableWidth === 0 ? tableWidth : 350)
                        }
                    }
                    rowKey={(record, index) => {
                        if (rowKey) {
                            return rowKey(record, index);
                        }
                        // console.log('rowKey', record, index, record.id || (isForceKey ? `index${index}` : index))
                        return record.id || (isForceKey ? `index${index}` : index);
                    }}
                    dataSource={isCusSource ? sourceList : dataSource}
                    columns={cols}
                    loading={cusLoading || loading}
                    rowSelection={isNoneSelected ? null : rowSelection}
                    onChange={this.handleTableChange}
                    expandedRowRender={this.props.expandedRowRender}
                    onColumnResizeEnd={this.handleResize}
                    emptyRenderer={emptyText}
                    isUseTableMaxHeight={isUseTableMaxHeight}
                    width={this.props.width}
                    pagination={
                        isNonePagination || isCustomPagination
                            ? false
                            : "pagination" in this.props
                            ? this.props.pagination
                            : {
                                  ...this.state.pagination,
                                  pageSizeOptions: ["20", "50", "100", "200", "500", "1000"],
                                  onShowSizeChange: this.onShowSizeChange,
                                  size: "small"
                              }
                    }
                    rowProps={this.props.rowProps}
                    CustomerTable={this.props.CustomerTable}
                />
                <div className="flex flex-vertical-center">
                    <div className="flex1" />
                    {isCustomPagination && !isNonePagination && (
                        <Pagination
                            className={"tb-custom-pagination"}
                            onChange={(page, pageSize) =>
                                this.handleTableChange({
                                    ...this.state.pagination,
                                    current: page
                                })
                            }
                            onShowSizeChange={this.onShowSizeChange}
                            pageSizeOptions={["20", "50", "100", "200", "3000"]}
                            {...this.state.pagination}
                            size={window._size}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default TableView;
