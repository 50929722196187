const moduleName = "resource/";
export const id = {
    id: "RESOURCE_MANAGEMENT_BASE_CARRIER",
    method: "POST",
    name: "承运商资源",
    type: "menu",
    apiName: "getCarrierList",
    url: `${moduleName}carrier/list`,
    sort: 1
};

// let moduleName = ''
export const children = {
    ADD_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_ADD_DATA",
        apiName: "addCarrier",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}carrier/save`
    },
    RESET_PASSWORD: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_RESET_PASSWORD",
        apiName: "onCarrierResetPassword",
        method: "POST",
        name: "重置密码",
        type: "view",
        url: `${moduleName}carrier/reset`
    },
    DEL_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_DEL_DATA",
        apiName: "delCarrier",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}carrier/delete`
    },
    EDIT_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_EDIT_DATA",
        apiName: "editCarrier",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}carrier/edit`
    },
    SUBMIT_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_SUBMIT_DATA",
        apiName: "submitCarrier",
        method: "POST",
        name: "提交",
        type: "view",
        url: `${moduleName}carrier/submit`
    },
    REVIEW: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_REVIEW",
        apiName: "reviewCarrier",
        method: "POST",
        name: "审核",
        type: "view",
        url: `${moduleName}carrier/review`
    },
    LERN_MORE: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_LERN_MORE",
        name: "更多",
        type: "view"
    },
    QUOTATION_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_DATA",
        name: "参考报价",
        type: "view"
    },
    ON_DISABLE: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_ON_DISABLE",
        apiName: "onDisable",
        method: "POST",
        name: "禁用",
        type: "view",
        url: `${moduleName}carrier/disable`
    },
    ON_ENABLE: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_ON_ENABLE",
        apiName: "oneEnable",
        method: "POST",
        name: "启用",
        type: "view",
        url: `${moduleName}carrier/enable`
    },
    EXPORT_LIST: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_EXPORT_LIST",
        apiName: "carrierExport",
        method: "POSTFILE",
        name: "导出",
        type: "view",
        url: `${moduleName}carrier/export`
    },
    EXPORT_TEMP: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_EXPORT_TEMP",
        apiName: "carrierExportTemplate",
        method: "GETFILE",
        name: "导出承运商模板",
        type: "view",
        url: `${moduleName}carrier/exportTemp`
    },
    IMPORT_CARRIER: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_IMPORT_CARRIER",
        apiName: "batchSaveCarrier",
        method: "POST",
        name: "导入",
        type: "view",
        url: `${moduleName}carrier/batchSave`
    },
    IMPORT_CONTACTS: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_IMPORT_CONTACTS",
        apiName: "importContacts",
        method: "POST",
        name: "导入联系人",
        type: "view",
        url: `${moduleName}carrier/contact/batchSave`
    },
    EXPORT_CONTACTS: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_EXPORT_CONTACTS",
        apiName: "exportContacts",
        method: "POSTFILE",
        name: "导出联系人数据",
        type: "view",
        url: `${moduleName}carrier/contact/export`
    },
    EXPORT_HEADER: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_EXPORT_HEADER",
        apiName: "exportContactsHeader",
        method: "GETFILE",
        name: "导出联系人模板",
        type: "view",
        url: `${moduleName}carrier/contact/exportTemp`
    },
    QUOTATION_RELOAD: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_RELOAD",
        name: "参考报价->刷新",
        type: "view"
    },
    QUOTATION_CLEAR: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_CLEAR",
        name: "参考报价->批量删除",
        type: "view"
    },
    QUOTATION_ADD: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_ADD",
        name: "参考报价->新增",
        type: "view"
    },
    QUOTATION_EXPORT: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_EXPORT",
        name: "参考报价->导出",
        type: "view"
    },
    QUOTATION_EXPORT_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_EXPORT_DATA",
        name: "参考报价->导出表头",
        type: "view"
    },
    QUOTATION_EXPORT_HEADER: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_EXPORT_HEADER",
        name: "参考报价->导出表数据",
        type: "view"
    },
    QUOTATION_IMPORT: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_IMPORT",
        name: "参考报价->导入",
        type: "view"
    },
    QUOTATION_EXPENSE: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_EXPENSE",
        name: "参考报价->费用项",
        type: "view"
    },
    QUOTATION_EDIT: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_EDIT",
        name: "参考报价->编辑",
        type: "view"
    },
    QUOTATION_DEL: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_QUOTATION_DEL",
        name: "参考报价->删除",
        type: "view"
    },
    LOOK_MORE: {
        id: "RESOURCE_MANAGEMENT_BASE_CARRIER_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
