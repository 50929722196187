const moduleName = "finance/payableApportionment";
export const id = {
    id: "BASIC_DATA_APPORTIONMENT_RULES",
    name: "分摊规则",
    type: "menu",
    apiName: "getApportionmentRulesList",
    url: `${moduleName}/list`
};
// ApportionmentRules ApportionmentRules

export const children = {
    // GET_LIST: {
    //     id: 'BASIC_DATA_APPORTIONMENT_RULES_GET_LIST',
    //     apiName: 'getApportionmentRulesOne',
    //     method: 'GET',
    //     name: '获取列表',
    //     type: 'view',
    //     url: `${moduleName}`
    // },
    ADD_DATA: {
        id: "BASIC_DATA_APPORTIONMENT_RULES_ADD_DATA",
        apiName: "addApportionmentRules",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}/addOrEdit`
    },
    DEL_DATA: {
        id: "BASIC_DATA_APPORTIONMENT_RULES_CODE",
        apiName: "delApportionmentRules",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}/delete`
    },
    EDIT_DATA: {
        id: "BASIC_DATA_APPORTIONMENT_RULES_EDIT_DATA",
        apiName: "editApportionmentRules",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}/addOrEdit`
    },
    START: {
        id: "BASIC_DATA_APPORTIONMENT_RULES_START",
        apiName: "startApportionmentRules",
        method: "POST",
        name: "启用/停用",
        type: "view",
        url: `${moduleName}/disableOrEnable`
    }
    // LOOK_MORE: {
    //     id: 'BASIC_DATA_APPORTIONMENT_RULES_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
