const prev = "wms/warehouse";
// const storageModule = "wms/warehouseStorage"
// const childrenModuleName = "wms/warehouseStorage"
module.exports.id = {
    id: "WAREHOUSE_MANAGEMENT_ADD_WAREHOUSE",
    method: "POST",
    name: "仓库明细",
    type: "menu_hide",
    apiName: "getWarehouseById",
    url: `${prev}`
};

module.exports.children = {
    SAVE: {
        id: "WAREHOUSE_MANAGEMENT_ADD_WAREHOUSE_SAVE_BUTTON",
        apiName: "saveWarehouse",
        method: "POST",
        name: "保存",
        type: "view",
        url: `${prev}/save`
    }
};
