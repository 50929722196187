import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class CarApi {
    /**
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [power.CHANGE_PAYMENT.apiName](params) {
        // 新增/编辑付款清单
        let url = power.CHANGE_PAYMENT.url;
        return this.POST(url, params);
    }

    [power.DEL_PAYMENT.apiName](params) {
        // 删除付款清单
        let url = power.DEL_PAYMENT.url;
        return this.POST(url, params);
    }

    [power.WRITEOFF.apiName](params) {
        // 完成核销
        let url = power.WRITEOFF.url + `/${params.id}`;
        return this.GET(url, params);
    }

    getPaymentById(params) {
        // 获取单个应付对账单
        let url = `finance/payableReconciliation/getOne/${params.id}`;
        return this.GET(url, params);
    }

    getPayableReconciliationById(id) {
        const url = `finance/payableReconciliation/getPayment/${id}`;
        return this.GET(url);
    }
}
