import React from "react";
import { InputNumber, Modal } from "antd";
import _ from "@src/utils/lodash";

const listItemStyle: React.CSSProperties = {
    // padding: 10
    borderBottom: "1px solid #eee",
    alignItems: "center",
    height: 36
};
const listItemCellStyle: React.CSSProperties = {
    padding: 5,
    borderLeft: "1px solid #eee",
    height: 36,
    display: "flex",
    alignItems: "center"
};

// tslint:disable-next-line:variable-name
export const CountDetailFlex = ({
    materialName,
    materialNumber,
    materialsId,
    orderId,
    itemSpecifications,
    quantity,
    quantityColor,
    receiverSenderId,
    status,
    unitId,
    unitName,
    volume,
    volumeColor,
    // netWeight,
    netWeightColor,
    weight,
    weightColor,
    onChangeCount,
    disabled
}: any) => (
    <div className="flex" style={listItemStyle}>
        <div style={{ width: 100, ...listItemCellStyle }}>
            {(quantityColor && (
                <InputNumber
                    parser={(value) => {
                        value.replace(unitName, "");
                        if (unitName && unitName.length > 0) {
                            return value.slice(0, value.indexOf(unitName));
                        }
                        return value;
                    }}
                    disabled={!!disabled}
                    formatter={(value) => `${value}${unitName || ""}`}
                    min={0}
                    onChange={(value) => onChangeCount({ value, key: "quantity" })}
                    value={quantity}
                />
            )) ||
                (quantity || "-") + (unitName || "")}
        </div>
        <div style={{ width: 100, ...listItemCellStyle }}>
            {(volumeColor && (
                <InputNumber min={0} onChange={(value) => onChangeCount({ value, key: "volume" })} value={volume} />
            )) ||
                volume ||
                "-"}
        </div>
        {/* <div style={{ width: 100, ...listItemCellStyle }}>
                {
                    netWeightColor && <InputNumber min={0} onChange={value => onChangeCount({ value, key: 'netWeight' })} value={netWeight} /> || (netWeight || '-')
                }
            </div> */}
        <div style={{ width: 100, ...listItemCellStyle }}>
            {(weightColor && (
                <InputNumber min={0} onChange={(value) => onChangeCount({ value, key: "weight" })} value={weight} />
            )) ||
                weight ||
                "-"}
        </div>
        <div style={{ width: 120, ...listItemCellStyle }}>{materialName}</div>
        <div style={{ width: 120, ...listItemCellStyle }}>{materialNumber}</div>
        <div style={{ width: 120, ...listItemCellStyle }}>{itemSpecifications}</div>
    </div>
);

export const getInputNumber = ({ text, row, index, key, onChangeCount, disabled, readOnly }: any) => {
    // quantity volume weight
    // return <input onChange={value => onChangeCount({ value: value.target.value, index, key, row })} value={text} />
    // return <InputNumber key={key} min={0} onChange={value => onChangeCount({ value, index, key, row })} defaultValue={text} />
    // if (readOnly) return
    // return <InputNumber disabled={!!disabled} key={key} min={0} onChange={value => onChangeCount({ value, index, key, row })} value={text} /> || (_.isNumber(text) ? text : '-')
    return (
        (!readOnly && row[key + "Color"] && (
            <InputNumber
                disabled={!!disabled}
                key={key}
                min={0}
                onChange={(value) => onChangeCount({ value, index, key, row })}
                value={text}
            />
        )) ||
        (_.isNumber(text) ? text : "-")
    );
};

interface IMaterialDetail {
    materialList: any[];
    row: any;
    key: string | number;
    index: number;
    onChangeCount: any;
    disabled?: boolean | undefined;
}

// tslint:disable-next-line:variable-name
export const MaterialDetail = ({ materialList, row, key, index, onChangeCount, disabled }: IMaterialDetail) => (
    <div style={{ background: "white", boxShadow: "0 2px 8px rgba(0,0,0,.15)" }}>
        <CountDetailFlex
            quantity={"件数"}
            volume={"体积(m³)"}
            // netWeight={'净重(kg)'}
            weight={"重量(kg)"}
            materialName={"品名"}
            materialNumber={"料号"}
            itemSpecifications={"规格"}
        />
        {materialList.map((item) => (
            <CountDetailFlex
                onChangeCount={(params) =>
                    onChangeCount({
                        ...params,
                        materialId: item.id,
                        row,
                        // key,
                        index
                    })
                }
                key={item.id}
                disabled={disabled}
                {...item}
            />
        ))}
    </div>
);

export const getAFewDecimals = (num: number, count = 2) => {
    return num.toFixed(count);
};

/**
 *  加入配载组合时，判断传入line 与 当前组合的配载节点
 * @param diffItem
 */
export const confirmNode = ({ pendingList, orderList }) => {
    return new Promise((resolve, reject) => {
        const senderTransit = {};
        orderList.forEach((item) => {
            if (!!item.senderTransit && item.senderId) {
                senderTransit[item.senderId] = true;
            }
        });
        if (orderList.length < 1) {
            resolve(true);
            return;
        }
        const diffItem = pendingList.find((item) => {
            if (!!item.senderTransit && item.senderId && !senderTransit[item.senderId]) {
                return true;
            }
            return false;
        });
        // console.log('diffItem', diffItem, orderList)
        if (diffItem) {
            Modal.confirm({
                title:
                    "配载节点：" +
                    diffItem.senderName +
                    " 与选中配载组合中的配载节点不一致," +
                    "确定选入当前配载组合吗？",
                onOk: () => {
                    resolve(true);
                },
                onCancel: () => {
                    reject();
                }
            });
            return;
        }
        resolve(true);
    });
};

export default CountDetailFlex;
