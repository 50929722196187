import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class AccountReceivableApi {
    /**
     * @param {any} params
     * @returns
     *
     * @memberOf WarehouseManegementInventoryManageApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.ADD_ACCOUNT.apiName](params) {
        let url = children.ADD_ACCOUNT.url;
        return this.POST(url, params);
    }

    [children.EDIT_ACCOUNT.apiName](params) {
        let url = children.EDIT_ACCOUNT.url;
        return this.POST(url, params);
    }

    [children.SAVE.apiName](params) {
        let url = children.SAVE.url + "/" + params.id;
        return this.POST(url, params);
    }

    [children.SUBMIT.apiName](params) {
        let url = children.SUBMIT.url + "/" + params.id + `?flowDefineCode=${params.flowDefineCode}`;
        return this.POST(url, params);
    }

    [children.CLIENT_CONFIRM.apiName](params) {
        let url = children.CLIENT_CONFIRM.url + "/" + params.id + "?accept=" + true;
        return this.SPOST(url, params);
    }

    [children.CLIENT_REJECT.apiName](params) {
        let url = children.CLIENT_REJECT.url + "/" + params.id + "?accept=" + false;
        return this.SPOST(url, params);
    }

    [children.CLIENT_CANCEL.apiName](params) {
        let url = children.CLIENT_CANCEL.url + "/" + params.id;
        return this.SPOST(url, params);
    }

    [children.FINANCE_CONFIRM.apiName](params) {
        let url = children.FINANCE_CONFIRM.url + "/" + params.id + "?accept=" + true;
        return this.SPOST(url, params);
    }

    [children.FINANCE_REJECT.apiName](params) {
        let url = children.FINANCE_REJECT.url + "/" + params.id + "?accept=" + false;
        return this.SPOST(url, params);
    }

    [children.FINANCE_CANCEL.apiName](params) {
        let url = children.FINANCE_CANCEL.url + "/" + params.id;
        return this.SPOST(url, params);
    }

    [children.DEL_ACCOUNT.apiName](params) {
        let url = children.DEL_ACCOUNT.url + `/${params.id}`;
        return this.DELETE(url, params);
    }

    [children.RECEIPT_CONFIRM.apiName](params) {
        // 客户确认
        let url = children.RECEIPT_CONFIRM.url + `/${params.id}`;
        return this.POST(url, params);
    }

    [children.RECEIPT_CANCEL_CONFIRM.apiName](params) {
        // 取消提交
        let url = children.RECEIPT_CANCEL_CONFIRM.url + `/${params.id}`;
        return this.POST(url, params);
    }

    [children.EXPORT_ACCOUNT.apiName](params) {
        // 导出对账单
        let url = children.EXPORT_ACCOUNT.url;
        return this.POSTFILE(url, params);
    }

    [children.EXPORT_LIST.apiName](params) {
        // 导出
        let url = children.EXPORT_LIST.url;
        return this.POSTFILE(url, params);
    }

    [children.EXPORT_DETAILS.apiName](params) {
        // 导出明细
        let url = children.EXPORT_DETAILS.url;
        return this.POSTFILE(url, params);
    }

    getAccountReceivableList(params) {
        // 获取应收对账单列表
        return this.POST(`finance/receivableReconciliation/page`, params);
    }

    // exportAccountReceivableList(params) { // 导出对账单
    //     return this.POSTFILE(`order/order/exportData`, params)
    // }

    getAttachmentList(params) {
        // 根据id获取附件信息
        return this.GET(`finance/receivableReconciliation/getAttachmentById/${params.id}`);
    }

    accountStatementupload(params) {
        // 对账单上传
        return this.POST(`finance/receivableReconciliation/upload`, params);
    }

    getOneEstimateStatementData(params) {
        // 获取单条预估单
        return this.GET(`finance/receivableEstimate/getOne/${params.id}`, params);
    }

    getOneAccountStatementData(params) {
        // 获取单条应收对账单
        return this.GET(`finance/receivableReconciliation/basicInfo/${params.id}`, params);
    }

    getOneAccountStatementReviewLog(params) {
        // 获取日志 /receivableReconciliation/reviewLog/
        return this.GET(`finance/receivableReconciliation/reviewLog/${params.id}`, params);
    }

    getOneAccountStatementReviewLogForFlowWork(params) {
        // 获取配置操作流程 /receivableReconciliation/reviewLog/
        return this.GET(`finance/receivableReconciliation/lookFlowTasks/${params.id}`, params);
    }

    // 获取审批任务
    getFlowWorkById(params) {
        const { moduleName, flowWorkId } = params;
        return this.POST(`${moduleName}/flowTask/findByFlowWorkId/${flowWorkId}`);
    }

    // 根据当前模块 code 判断是否配置了审核流程
    getFlowByCode(params) {
        const { moduleName, flowCode } = params;
        return this.POST(`${moduleName}/flowDefine/findByFlowDefineCode/${flowWorkId}`);
    }

    getAuditFlowList(params) {
        const { moduleName, ...reset } = params;
        return this.POST(`${moduleName}/receivableReconciliation/approvePage`, reset);
    }

    // 监测是否有审核权限
    checkApprovePermission(params) {
        const { moduleName, receivableReconciliationId, ...reset } = params;
        return this.GET(
            `${moduleName}/receivableReconciliation/checkApprovePermission/${receivableReconciliationId}`,
            reset
        );
    }
    // 审核单条数据
    approveDataState(params) {
        const { moduleName, receivableReconciliationId, accept, ...reset } = params;
        return this.SPOST(
            `${moduleName}/receivableReconciliation/approve/${receivableReconciliationId}?accept=${accept}`,
            reset
        );
    }

    // 获取操作记录
    getOperateFlowRecord(params) {
        const { id, ...reset } = params;
        return this.GET(`finance/receivableReconciliation/findOperateFlowRecordAll/${id}`, reset);
    }
}
