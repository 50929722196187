import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class DemandDetailsApi {
    /**
     * @param {any} params
     * @returns
     * @memberOf DemandDetailsApi
     */

    // 确认
    [children.CONFIRM_DATA.apiName](params) {
        let url = children.CONFIRM_DATA.url + "/" + params.id;
        return this.GET(url, params);
    }

    // 取消确认
    [children.CANCEL_DATA.apiName](params) {
        let url = children.CANCEL_DATA.url + "/" + params.id;
        return this.GET(url, params);
    }

    // 需求完结
    [children.FINISH.apiName](params) {
        let url = children.FINISH.url + "/" + params.id;
        return this.GET(url, params);
    }

    // 取消完结
    [children.UN_FINISH.apiName](params) {
        let url = children.UN_FINISH.url + "/" + params.id;
        return this.GET(url, params);
    }

    // 修改基本信息
    [children.EDIT_BASEINFO.apiName](params) {
        let url = children.EDIT_BASEINFO.url;
        return this.POST(url, params);
    }

    // 创建出货单
    [children.SAVE_RECEIPT.apiName](params) {
        let url = children.SAVE_RECEIPT.url;
        return this[children.SAVE_RECEIPT.method](url, params);
    }

    // 编辑出货单基本信息
    [children.EDIT_RECEIPT.apiName](params) {
        let url = children.EDIT_RECEIPT.url;
        return this[children.EDIT_RECEIPT.method](url, params);
    }

    // 删除出货单
    [children.DELETE_RECEIPT.apiName](params) {
        let url = children.DELETE_RECEIPT.url + `/${params.id}`;
        return this[children.DELETE_RECEIPT.method](url, params);
    }

    // 编辑出货单货物
    [children.EDIT_DETAILS.apiName](params) {
        let url = children.EDIT_DETAILS.url;
        return this[children.EDIT_DETAILS.method](url, params);
    }

    // 删除出货单货物
    [children.DELETE_DETAILS.apiName](params) {
        let url = children.DELETE_DETAILS.url + `/${params.id}`;
        return this[children.DELETE_DETAILS.method](url, params);
    }

    // 获取单条出货需求
    getShipmentDemandOne(params) {
        let url = `wms/shipmentDemand/getOne/${params.id}`;
        return this.GET(url, params);
    }

    getMaterialList(params) {
        //查询物料信息
        let url = "wms/shipmentDemand/getMaterialList";
        return this.POST(url, params);
    }

    // 导入货物明细
    importShipmentDemandGoods(params) {
        let url = `wms/shipmentDemand/batchSave`;
        return this.POST(url, params);
    }

    // 货物物料可用库存
    getAvailableInventory(params) {
        let url = `wms/inventoryManage/availableInventory`;
        return this.POST(url, params);
    }

    /////////////////////////////////////////////////////////////

    // 获取需求明细列表
    getShipmentDemandDetailById(params) {
        let url = `wms/shipmentDemand/detailsList/${params.id}`;
        return this.GET(url, params);
    }

    // 修改出货需求货物明细
    shipmentDemandSaveOrUpdateCargoDetail(params) {
        let url = `wms/shipmentDemand/updateDetails`;
        return this.POST(url, params);
    }

    // 新增出货需求货物明细
    shipmentDemandSaveCargoDetail(params) {
        let url = `wms/shipmentDemand/createDetails`;
        return this.POST(url, params);
    }

    // 删除出货需求货物明细
    shipmentDemandDeleteCargoDetail(params) {
        let url = `wms/shipmentDemand/deleteDetails/${params.id}`;
        return this.GET(url, params);
    }

    // 获取出货单列表
    getShipmentItemByShipmentDemandId(params) {
        let url = `wms/shipmentDemand/manageList/${params.shipmentDemandId}`;
        return this.GET(url, params);
    }

    // 根据收货单ID获取收货单货物明细
    getShipmentCargoDetailsById(params) {
        let url = `wms/shipmentDemand/getManageCargoDetails/${params.id}`;
        return this.GET(url, params);
    }
}
