const moduleName = "common/";
export const id = {
    id: "BASIC_DATA_ENCODING_RULES",
    name: "编码规则配置",
    type: "menu",
    apiName: "getEncodingRulesList",
    url: `${moduleName}encodingRule/list`
};

export const children = {
    // GET_LIST: {
    //     id: 'BASIC_DATA_ENCODING_RULES_GET_LIST',
    //     apiName: 'getCarTypes',
    //     method: 'POST',
    //     name: '获取列表',
    //     type: 'view',
    //     url: `${moduleName}carType/getCarTypes`
    // },
    // ADD_DATA: {
    //     id: 'BASIC_DATA_ENCODING_RULES_ADD_DATA',
    //     apiName: 'addEncodingRules',
    //     method: 'POST',
    //     name: '新建',
    //     type: 'view',
    //     url: `${moduleName}encodingRule/saveOrUpdate`
    // },
    // DEL_DATA: {
    //     id: 'BASIC_DATA_ENCODING_RULES_CODE',
    //     apiName: 'delEncodingRules',
    //     method: 'POST',
    //     name: '删除',
    //     type: 'view',
    //     url: `${moduleName}encodingRule/delete`
    // },
    EDIT_DATA: {
        id: "BASIC_DATA_ENCODING_RULES_EDIT_DATA",
        apiName: "editEncodingRules",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}encodingRule/saveOrUpdate`
    }
    // LOOK_MORE: {
    //     id: 'BASIC_DATA_ENCODING_RULES_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
