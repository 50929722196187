import React from "react";
import Icon from "../icon";
// /*
// // 表格列工厂
// columnItem: [key, title, width, render], render: 'default' || ({ key, val, row, rowIndex, ColumnItemBox, defaultRender }) => {}
// */
export interface IRenderParams {
    text?: string | number;
    rowData?: any;
    index?: number;
    key: string;
    opt?: any;
}
type IFunction = (params: IRenderParams) => React.ReactElement | string | null;
type INumNull = number | null;
type l1 = [string, string, INumNull];
type l2 = [string, string, INumNull, IFunction];
type l3 = [string, string, INumNull, IFunction, any];
export type ICol = l1 | l2 | l3;
const strategy = ([title, key, width, render, opt]: ICol) => {
    // console.log('render', render)
    if (!render) {
        return {
            key,
            title,
            dataIndex: key,
            width,
            ...opt
        };
    }
    return {
        key,
        title,
        dataIndex: key,
        width,
        render: (t: string, r: any, i: number) => {
            return render({
                text: t,
                rowData: r,
                index: i,
                key,
                opt
            });
        },
        ...opt
    };
};

export const columnFactoryV2 = (columns: ICol[]) => {
    // console.log('columns', columns)
    return (columns || []).map((col) => strategy(col));
    // return columns
};

export default columnFactoryV2;
