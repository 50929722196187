import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

const moduleName = "wms/profitLossManage/";

export default class {
    /**
     * 获取调整盈亏列表
     *
     * @param {any} params
     * @returns
     *
     * @memberOf profitLossApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    //日志
    getLogsProfitLoss(params) {
        let url = `${moduleName}log/` + params.id;
        return this.GET(url, params);
    }
    //取消
    cancelProfitLoss(params) {
        let url = `${moduleName}cancel/` + params.id;
        return this.GET(url, params);
    }
}
