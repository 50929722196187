const prevUrl = "finance/receivableReconciliation";

export const id = {
    id: "HANDLE_LIST",
    method: "POST",
    name: "运输应付结算",
    type: "menu",
    apiName: "settlementHandleList",
    url: `finance/payableSettlement/list`,
    sort: 3
};
export const children = {
    CONFIRM_DATA: {
        id: "HANDLE_LIST_CONFIRM_EDIT",
        name: "结算确认",
        type: "view"
    },
    PRESERVATION_PASS: {
        id: "HANDLE_LIST_PRESERVATION_PASS",
        name: "审核通过",
        type: "view"
    },
    PRESERVATION_REJECT: {
        id: "HANDLE_LIST_PRESERVATION_REJECT",
        name: "审核拒绝",
        type: "view"
    },
    PRESERVATION_EXPORT: {
        id: "HANDLE_LIST_PRESERVATION_EXPORT",
        name: "导出",
        type: "view"
    },
    EXPORT_MONEY: {
        id: "HANDLE_LIST_EXPORT_MONEY",
        name: "导出预估金额",
        type: "view"
    },
    PRESERVATION_CANCEL: {
        id: "HANDLE_LIST_PRESERVATION_CANCEL",
        name: "取消确认",
        type: "view"
    },
    PRESERVATION_REMATCH: {
        id: "HANDLE_LIST_PRESERVATION_REMATCH",
        name: "重新匹配报价",
        type: "view"
    }
};
