import request from "@src/http";
import { IDvaModel } from "../models";
import { message } from "antd";
const rApi: any = request;

export interface IFormData {
    inputMethod?: number; // 1自动生成，2扫描输入
    packingNumber?: string; // 包装编号
    scanningMethod?: number; // 1逐件，2毗连，3一单一包装
    // baleId?: number;
    batchNumber?: string;
    keyword?: string;
    expectedQuantityCount?: number;
    singleNumber?: string;
    currentSingleNumber?: string;
    packagingMaterialsName?: string;
    packagingMaterialsId?: number;
    batchNumberList?: any[];
}

export interface MaterialsNameOrId {
    packagingMaterialsName?: string;
    packagingMaterialsId?: number;
}

export interface IAttachInfo {
    grossWeight?: number;
    volume?: number;
    length?: number;
    width?: number;
    height?: number;
    remark?: string;
}

export interface IModelsGlobalState {
    formData: IFormData;
    attachInfo: IAttachInfo;
    packingMaterials: any[];
    scanList: any[];
    carGoList: any[];
    boxing: any[];
}

const key = ["materialNumber", "materialName", "barCode", "batchNumber"];

/**
 * 判断物料 条码 批次号 是否一致
 *
 * @param {*} currentItem
 * @param {*} nextItem
 * @returns
 */
function findArrayKeysTheSame(currentItem: any, nextItem: any) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < key.length; i++) {
        const item = key[i];
        if (nextItem[item] !== currentItem[item]) {
            return false;
        }
    }
    return true;
}

/**
 * 计算已打包物料数量
 *
 * @param {*} { carGoList, scanList, boxing }
 * @returns
 */
function calcPacked({ carGoList, scanList, boxing }) {
    let max = false;
    const maxInfo: any = [];
    scanList = scanList.map((item) => ({ ...item }));
    carGoList = carGoList.map((item) => ({ ...item }));
    boxing = boxing.map((item) => ({ ...item }));
    let pickedList = [...scanList];
    boxing.forEach((ele) => {
        pickedList = [...pickedList, ...(ele.baleDetails || [])];
    });
    try {
        carGoList = carGoList.map((carGoItem: any) => {
            const merge = pickedList.filter((scanItem: any) => {
                return findArrayKeysTheSame(carGoItem, scanItem);
            });
            let packagingCount = 0;
            if (merge.length > 1) {
                packagingCount =
                    merge.reduce((accumulator, currentValue) => ({
                        expectedQuantityCount: accumulator.expectedQuantityCount + currentValue.expectedQuantityCount
                    })).expectedQuantityCount || 0;
            } else if (merge.length === 1) {
                packagingCount = merge[0].expectedQuantityCount;
            }
            if (packagingCount > carGoItem.expectedQuantityCount) {
                max = true;
                maxInfo.push({
                    ...merge[0],
                    maxQuantity: packagingCount - carGoItem.expectedQuantityCount
                });
            }
            // console.log('packagingCount', packagingCount, merge, pickedList)
            return {
                ...carGoItem,
                packagingCount
            };
        });
    } catch (e) {
        console.error("tag", e);
    }
    return {
        carGoListC: carGoList,
        maxInfo,
        max
    };
}

const attachInfo: IAttachInfo = {
    grossWeight: null,
    volume: null,
    length: null,
    width: null,
    height: null,
    remark: null
};

const formData: IFormData = {
    packingNumber: null,
    inputMethod: 1,
    scanningMethod: 1,
    // baleId: null,
    batchNumber: null,
    keyword: null,
    expectedQuantityCount: 1,
    currentSingleNumber: null,
    singleNumber: null,
    // singleNumber: 'R20200330001-01',
    packagingMaterialsName: null,
    packagingMaterialsId: null,
    batchNumberList: []
};

const model: IDvaModel<IModelsGlobalState> = {
    namespace: "wmsPackage",
    state: {
        formData: { ...formData },
        attachInfo: { ...attachInfo },
        packingMaterials: [],
        scanList: [],
        carGoList: [],
        boxing: []
    },
    effects: {
        /**
         * 获取条码物料信息
         *
         * @param {*} { payload }
         * @param {*} { call, put, select }
         */
        *getPackageDetail({ payload }, { call, put, select }) {
            try {
                let res = yield call(rApi.getPackageDetail, payload);

                // tslint:disable-next-line:prefer-const
                let { carGoList, scanList, boxing, singleNumber } = yield select(({ wmsPackage }) => ({
                    carGoList: wmsPackage.carGoList,
                    scanList: wmsPackage.scanList,
                    boxing: wmsPackage.boxing,
                    singleNumber: wmsPackage.formData.singleNumber
                }));
                // 写入单号
                res = res.map((item) => ({
                    ...item,
                    singleNumber
                }));
                scanList = scanList.map((item) => ({ ...item }));
                let isOverflowSerial = false; // 是否已有存在相同的序列号
                res.forEach((sItem: any) => {
                    const findIndex = scanList.findIndex((scanItem: any) => {
                        return findArrayKeysTheSame(sItem, scanItem);
                    });
                    if (findIndex < 0) {
                        if (sItem.boxSerialNum) {
                            scanList = [
                                {
                                    ...sItem,
                                    serialNum: [sItem.boxSerialNum]
                                },
                                ...scanList
                            ];
                        } else {
                            scanList = [sItem, ...scanList];
                        }
                    } else {
                        scanList[findIndex].expectedQuantityCount += sItem.expectedQuantityCount;
                        if (sItem.boxSerialNum) {
                            if (scanList[findIndex].serialNum && scanList[findIndex].serialNum.length > 0) {
                                const find = scanList[findIndex].serialNum.find((ele) => ele === sItem.boxSerialNum);
                                if (!find) {
                                    scanList[findIndex].serialNum.push(sItem.boxSerialNum);
                                } else {
                                    isOverflowSerial = true;
                                }
                            } else {
                                scanList[findIndex].serialNum = [sItem.boxSerialNum];
                            }
                        }
                    }
                });
                if (isOverflowSerial) {
                    message.error("该序列号已存在！");
                    return;
                }
                const { carGoListC, max, maxInfo } = calcPacked({ carGoList, scanList, boxing });
                if (max) {
                    let s = "";
                    const infoItem: any = maxInfo[0];
                    key.forEach((ele) => {
                        s += infoItem[ele];
                        s += "，";
                    });
                    message.warning(`${s}超出总量 ${infoItem.maxQuantity}`);
                    return;
                }
                message.success("添加成功！");
                // console.log('scanList', scanList)
                yield put({
                    type: "updateGlobalState",
                    payload: {
                        scanList,
                        carGoList: carGoListC
                    }
                });
            } catch (e) {
                console.error("tag", e);
                message.error(e.msg || "获取失败");
                if (e && e.msg === "该物料存在多个批次号，请选择批次号") {
                    yield put({
                        type: "getBatchNumber"
                    });
                }
            }
        },
        /**
         * 根据出库单号 获取出库物料
         *
         * @param {*} { payload }
         * @param {*} { call, put, select }
         */
        *getPackageCarGoDetails({ payload }, { call, put, select }) {
            try {
                const res = yield call(rApi.getPackageCarGoDetails, payload);
                // tslint:disable-next-line:prefer-const
                let { carGoList, scanList, boxing } = yield select(({ wmsPackage }) => ({
                    carGoList: wmsPackage.carGoList,
                    scanList: wmsPackage.scanList,
                    boxing: wmsPackage.boxing
                }));
                // carGoList = carGoList.map(item => ({ ...item }))
                // console.log('getPackageCarGoDetails res', res)
                if (res.length < 1) {
                    yield put({
                        type: "updateGlobalState",
                        payload: {
                            carGoList: []
                        }
                    });
                    return;
                }
                // res.forEach((sItem: any) => {
                //     const findIndex = carGoList.findIndex((carGoItem: any) => {
                //         return findArrayKeysTheSame(sItem, carGoItem)
                //     })
                //     if (findIndex < 0) {
                //         carGoList = [sItem, ...carGoList]
                //     }
                // })
                carGoList = res;
                const { carGoListC } = calcPacked({ carGoList, scanList, boxing });
                yield put({
                    type: "getBoxingData",
                    payload: {
                        singleNumber: payload.singleNumber
                    }
                });
                yield put({
                    type: "updateGlobalState",
                    payload: {
                        currentSingleNumber: payload.singleNumber,
                        carGoList: carGoListC
                    }
                });
            } catch (e) {
                message.error(e.msg || "获取失败");
            }
        },
        /**
         * 获取装箱列表
         *
         * @param {*} { payload }
         * @param {*} { call, put, select }
         */
        *getBoxingData({ payload }, { call, put, select }) {
            // tslint:disable-next-line:prefer-const
            let { carGoList, scanList } = yield select(({ wmsPackage }) => ({
                carGoList: wmsPackage.carGoList,
                scanList: wmsPackage.scanList,
                formData: wmsPackage.formData
            }));
            const boxing = yield call(rApi.getBoxList, { singleNumber: payload.singleNumber });
            const { carGoListC } = calcPacked({ carGoList, scanList, boxing });
            yield put({
                type: "updateGlobalState",
                payload: {
                    boxing,
                    carGoList: carGoListC
                }
            });
        },
        /**
         * 确认装箱
         *
         * @param {*} { payload }
         * @param {*} { call, put, select }
         */
        *confirmData({ payload }, { call, put, select }) {
            // tslint:disable-next-line:no-shadowed-variable
            const { formData, scanList, attachInfo, boxing } = yield select(({ wmsPackage }) => ({
                ...wmsPackage
            }));
            try {
                yield put({
                    type: "getBoxingData",
                    payload: {
                        singleNumber: formData.singleNumber
                    }
                });
                yield put({
                    type: "updateGlobalState",
                    payload: {
                        scanList: []
                    }
                });
            } catch (e) {
                console.error("e", e);
            }
        },
        /**
         * 删除已扫物料
         *
         * @param {*} { payload }
         * @param {*} { call, put, select }
         */
        *deleteScanList({ payload }, { call, put, select }) {
            // tslint:disable-next-line:prefer-const
            let { carGoList, scanList, boxing } = yield select(({ wmsPackage }) => ({
                carGoList: wmsPackage.carGoList,
                scanList: wmsPackage.scanList,
                boxing: wmsPackage.boxing
            }));
            scanList = scanList.map((item) => ({ ...item }));
            scanList = scanList.filter((sItem) => {
                const findIndex = payload.findIndex((item) => {
                    return findArrayKeysTheSame(sItem, item);
                });
                if (findIndex < 0) {
                    return true;
                }
                return false;
            });
            const { carGoListC } = calcPacked({ carGoList, scanList, boxing });
            // console.error('scanList', scanList)
            yield put({
                type: "updateGlobalState",
                payload: {
                    scanList,
                    carGoList: carGoListC
                }
            });
        },
        *getBatchNumber({ payload }, { call, put, select }) {
            // tslint:disable-next-line:no-shadowed-variable
            const { formData } = yield select(({ wmsPackage }) => ({
                formData: wmsPackage.formData
            }));
            try {
                const res = yield call(rApi.getBatchNumber, formData);
                // message.success('添加成功！')
                yield put({
                    type: "updateFormState",
                    payload: {
                        batchNumberList: res.filter((item) => item && item.length > 0),
                        batchNumber: (res && res[0]) || null
                    }
                });
            } catch (e) {
                message.warning((e && e.msg) || "获取批次号失败");
            }
        },
        /**
         * 更新 扫描方式 条码 单号
         *
         * @param {*} { payload }
         * @param {*} { call, put, select }
         */
        *updateFormState({ payload }, { call, put, select }) {
            // tslint:disable-next-line:no-shadowed-variable
            const { formData } = yield select(({ wmsPackage }) => ({
                formData: wmsPackage.formData
            }));
            yield put({ type: "updateForm", payload });
            if (payload.packagingMaterialsId) {
                // console.log('receiptManageId', payload)
                try {
                    const packingNumber = yield call(rApi.generatePackingNumber, payload);
                    yield put({ type: "updateForm", payload: { packingNumber } });
                } catch (e) {
                    console.error("tag", e);
                    message.error(e.msg || "生成包装编号失败");
                }
            }
            if ("inputMethod" in payload && payload.inputMethod === 2) {
                // console.log('11111111',payload);
                // yield put({ type: 'updateForm', payload: {
                //     packagingMaterialsName: null,
                //     packagingMaterialsId: null
                // }});
            }
            if ("packingNumber" in payload) {
                try {
                    const packingMaterials = yield call(rApi.getListByPackingNumber, payload);

                    const pid = {};
                    yield put({
                        type: "updateForm",
                        payload: {
                            packingMaterials: packingMaterials
                                .filter((item) => {
                                    if (pid[item.packagingMaterialsId]) return;
                                    pid[item.packagingMaterialsId] = true;
                                    return true;
                                })
                                .map((item) => ({
                                    id: item.packagingMaterialsId,
                                    name: item.packagingMaterialsName
                                })),
                            packagingMaterialsName: packingMaterials && packingMaterials[0].packagingMaterialsName,
                            packagingMaterialsId: packingMaterials && packingMaterials[0].packagingMaterialsId
                        }
                    });
                } catch (e) {
                    console.error("rApi.getListByPackingNumber", e);
                }
            }
        }
    },
    reducers: {
        updateMaterialsNameOrId(state: MaterialsNameOrId, { payload }: any) {
            return {
                ...state,
                ...payload
            };
        },

        updateForm(state: IModelsGlobalState, { payload }: any) {
            return {
                ...state,
                formData: {
                    ...state.formData,
                    ...payload
                }
            };
        },
        updateAttachInfo(state: IModelsGlobalState, { payload }: any) {
            return {
                ...state,
                attachInfo: {
                    ...state.attachInfo,
                    ...payload
                }
            };
        },
        updateGlobalState(state: IModelsGlobalState, { payload }: any) {
            return {
                ...state,
                ...payload
            };
        },
        initState(state: IModelsGlobalState, { payload }: any) {
            return {
                formData: { ...formData },
                attachInfo: { ...attachInfo },
                packingMaterials: [],
                scanList: [],
                carGoList: [],
                boxing: []
            };
        }
    }
};
export default model;
