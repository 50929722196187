import React, { Component } from "react";
import { Input, Button, Popover, Radio, Tag, InputNumber, message, Spin, Modal, Popconfirm } from "antd";
import SelectDataBook from "@src/components/select_databook";
import rApi from "@src/http";
import { isArray, trim } from "@src/utils";
import { columnMap, costItemObjectToString } from "./utils";
import DragView from "@src/components/table_header_drag";
import { Scrollbars } from "react-custom-scrollbars";
import { ColumnFactory } from "@src/components/table_template";
import "./header.less";

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const Search = Input.Search;

const strNoLengthToNull = (obj) => {
    for (let key in obj) {
        if (typeof obj[key] === "string" && obj[key].length < 1) {
            obj[key] = null;
        }
    }
    return obj;
};

/**
 * 费用项基本字段
 * @class CostItemView
 * @extends {Component}
 */
class CostItemView extends Component {
    state = {
        visible: false,
        carType: null, // 车类型ID
        carTypeName: null, // 车类型名称
        costUnitId: null, // 费用单位ID
        costUnitName: null, // 费用单位名称
        expenseItemId: null, // 费用项目ID
        expenseItemName: null, // 费用项目名称
        lowestFee: null, // 最低收费
        endValue: null, // 限制区间 end
        startValue: null, // 限制区间 start
        firstWeight: null, // 首重
        intervalCostUnitName: null, // 限制区间单位名称
        intervalCostUnitId: null, // 限制区间单位ID
        accountingStrategy: 1, //计费方式
        filterId: null, //费用项id用于过滤费用单位
        defaultValue: {} //默认值
        // isMonthlyCalculation: 0 //是否允许月结
    };

    constructor(props) {
        super(props);
    }

    getDefaultValue = async () => {
        rApi.getUnitConfigureByExpenseId({
            id: this.state.filterId
        })
            .then((res) => {
                this.setState({
                    defaultValue: res && res.length > 0 ? res[0] : {}
                });
            })
            .catch((e) => {
                console.log("e");
            });
    };

    clearValue() {
        this.setState(
            {
                carType: null, // 车类型ID
                carTypeName: null, // 车类型名称
                costUnitId: null, // 费用单位ID
                costUnitName: null, // 费用单位名称
                expenseItemId: null, // 费用项目ID
                expenseItemName: null, // 费用项目名称
                lowestFee: null, // 最低收费
                endValue: null, // 限制区间 end
                startValue: null, // 限制区间 start
                firstWeight: null, // 首重
                intervalCostUnitName: null, // 限制区间单位名称
                intervalCostUnitId: null, // 限制区间单位ID
                reloadDom: true,
                accountingStrategy: 1, //计费方式
                defaultValue: {} //默认值
                // isMonthlyCalculation: 0
            },
            () => {
                this.setState({
                    reloadDom: false
                });
            }
        );
    }

    hide = () => {
        this.setState({
            visible: false
        });
        this.clearValue();
    };

    addToHeader = () => {
        const props = this.props;
        const { item, mode, addHeader } = props;
        const unitConfigureList =
            item.unitConfigureList && isArray(item.unitConfigureList)
                ? item.unitConfigureList.map((ele) => {
                      return {
                          id: ele.dictionaryId,
                          title: ele.dictionaryName
                      };
                  })
                : [];
        // let defaultValue = unitConfigureList && unitConfigureList[0] ? unitConfigureList[0] : null
        let {
            carType, // 车类型ID
            carTypeName, // 车类型名称
            costUnitId, // 费用单位ID
            costUnitName, // 费用单位名称
            expenseItemId, // 费用项目ID
            expenseItemName, // 费用项目名称
            firstWeight, // 首重
            lowestFee, // 最低收费
            endValue, // 限制区间 end
            intervalCostUnitName, // 限制区间单位名称
            intervalCostUnitId, // 限制区间单位ID
            startValue, // 限制区间 start
            accountingStrategy, //计费方式
            //isMonthlyCalculation, //是否允许月结
            defaultValue
        } = this.state;
        if (defaultValue && (!costUnitName || !costUnitId)) {
            costUnitName = defaultValue.title;
            costUnitId = defaultValue.id;
        }
        if (accountingStrategy !== 2 && (!costUnitId || !costUnitName)) {
            message.error("请选择单位!");
            return;
        }
        if (
            (startValue || startValue === 0 || endValue || endValue === 0) &&
            !intervalCostUnitName &&
            ((item.name && item.name !== "续重") || (item.expenseItemName && item.expenseItemName !== "续重"))
        ) {
            message.error("请选择限制区间单位!");
            return;
        }
        if (item.name === "首重" && !firstWeight && !firstWeight !== 0) {
            message.error("请输入首重重量!");
            return;
        }
        if (!startValue && !endValue && intervalCostUnitId) {
            message.error("请填写限制区间!");
            return;
        }
        // if((mode.businessModeName === '整车' || mode.businessModeName === '整柜') && (!carTypeName || carTypeName === null)) {
        //     message.error('请选择车型!')
        //     return
        // }
        this.hide();
        addHeader({
            ...item,
            type: 12,
            title: item.name,
            carType, // 车类型ID
            carTypeName, // 车类型名称
            costUnitId: accountingStrategy === 2 ? -1 : costUnitId, // 费用单位ID
            costUnitName: accountingStrategy === 2 ? "月" : costUnitName, // 费用单位名称
            firstWeight, // 首重
            expenseItemId: item.id, // 费用项目ID
            expenseItemName: item.name, // 费用项目名称
            lowestFee, // 最低收费
            intervalCostUnitName, // 限制区间单位名称
            intervalCostUnitId, // 限制区间单位ID
            endValue, // 限制区间 end
            startValue, // 限制区间 start
            accountingStrategy
            //isMonthlyCalculation
        });
    };

    handleVisibleChange = (visible) => {
        this.setState({ visible });
        this.clearValue();
    };

    clickItem = (item) => {
        //点击费用事件
        // console.log('点击费用事件', item)
        this.setState(
            {
                filterId: item.id
                //isMonthlyCalculation: item.isMonthlyCalculation
            },
            () => {
                this.getDefaultValue();
            }
        );
    };

    render() {
        const props = this.props;
        let { type, item, offercarrierpop, businessModeId } = props;
        // console.log('offercarrierpop', offercarrierpop)
        const unitConfigureList =
            item.unitConfigureList && isArray(item.unitConfigureList)
                ? item.unitConfigureList.map((ele) => {
                      return {
                          id: ele.dictionaryId,
                          title: ele.dictionaryName
                      };
                  })
                : [];
        let {
            visible,
            carType, // 车类型ID
            carTypeName, // 车类型名称
            costUnitId, // 费用单位ID
            costUnitName, // 费用单位名称
            expenseItemId, // 费用项目ID
            expenseItemName, // 费用项目名称
            lowestFee, // 最低收费
            intervalCostUnitName, // 限制区间单位名称
            intervalCostUnitId, // 限制区间单位ID
            firstWeight, // 首重
            endValue, // 限制区间 end
            startValue, // 限制区间 start
            reloadDom,
            accountingStrategy,
            filterId,
            defaultValue
            //isMonthlyCalculation
        } = this.state;
        let isFirstWeight = item.name === "首重" && item.id === 3;
        let ContinuedWeight = item.name === "续重" && item.id === 4;
        //  console.log('businessModeId', businessModeId)
        return (
            <Popover
                placement="topLeft"
                title="设置标签数据"
                visible={visible}
                overlayStyle={{ minWidth: 310 }}
                onVisibleChange={this.handleVisibleChange}
                getPopupContainer={offercarrierpop}
                content={
                    <div ref="offercarrierpop1">
                        <div className="cost-item-view flex">
                            <div>计费方式：</div>
                            <div>
                                <RadioGroup
                                    onChange={(e) => {
                                        //console.log('xxxxx', e.target.value)
                                        this.setState({
                                            accountingStrategy: e.target.value
                                        });
                                    }}
                                    value={accountingStrategy}
                                >
                                    <Radio value={1}>计量</Radio>
                                    <Radio value={2}>月结</Radio>
                                    {/* {
                                        isMonthlyCalculation === 1 ? <Radio value={2}>月结</Radio> : null
                                    } */}
                                </RadioGroup>
                            </div>
                        </div>
                        {accountingStrategy === 2 ? null : (
                            <div className="cost-item-view flex flex-vertical-center">
                                <div>费用单位：</div>
                                <div style={{ width: 90 }}>
                                    {reloadDom ? null : (
                                        <SelectDataBook
                                            onChangeValue={(value = {}) =>
                                                this.setState({ costUnitId: value.id, costUnitName: value.title })
                                            }
                                            getPopupContainer={() => this.refs.offercarrierpop1}
                                            defaultValue={defaultValue}
                                            // list={
                                            //     unitConfigureList
                                            // }
                                            getDataMethod={"getUnitConfigureByExpenseId"}
                                            params={{ id: filterId }}
                                            allowClear={false}
                                            size="small"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {ContinuedWeight || isFirstWeight || accountingStrategy === 2 ? null : (
                            <div className="cost-item-view">
                                最低收费：
                                <InputNumber
                                    value={lowestFee}
                                    onChange={(value) => this.setState({ lowestFee: value })}
                                    min={0}
                                    size="small"
                                />
                            </div>
                        )}
                        {ContinuedWeight && accountingStrategy === 1 ? (
                            <div className="cost-item-view">
                                续重区间：
                                <InputNumber
                                    value={startValue}
                                    onChange={(value = "") => this.setState({ startValue: value })}
                                    min={0}
                                    size="small"
                                />
                                -
                                <InputNumber
                                    value={endValue}
                                    onChange={(value = "") => this.setState({ endValue: value })}
                                    min={0}
                                    size="small"
                                />
                            </div>
                        ) : isFirstWeight ? null : accountingStrategy === 2 ? null : (
                            <div className="cost-item-view flex" style={{ height: 24 }}>
                                <div>
                                    限制区间：
                                    <InputNumber
                                        value={startValue}
                                        onChange={(value = "") => this.setState({ startValue: value })}
                                        style={{ width: 60 }}
                                        min={0}
                                        size="small"
                                    />
                                    &nbsp;-&nbsp;
                                    <InputNumber
                                        value={endValue}
                                        onChange={(value = "") => this.setState({ endValue: value })}
                                        style={{ width: 60 }}
                                        min={0}
                                        size="small"
                                    />
                                    &nbsp;&nbsp;
                                </div>
                                <div style={{ width: 70 }}>
                                    {reloadDom ? null : (
                                        <SelectDataBook
                                            onChangeValue={(value = {}) =>
                                                this.setState({
                                                    intervalCostUnitId: value.id,
                                                    intervalCostUnitName: value.title
                                                })
                                            }
                                            getPopupContainer={() => this.refs.offercarrierpop1}
                                            //text='费用单位'
                                            getDataMethod={"getUnitConfigureByExpenseId"}
                                            params={{ id: filterId }}
                                            size="small"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {ContinuedWeight || isFirstWeight ? null : (
                            <div className="cost-item-view flex">
                                <div>设置车型：</div>
                                <div style={{ width: 180 }}>
                                    {reloadDom ? null : (
                                        <SelectDataBook
                                            labelField={"name"}
                                            onChangeValue={(value = {}) =>
                                                this.setState({
                                                    carType: value.id || null,
                                                    carTypeName: value.title || null
                                                })
                                            }
                                            getPopupContainer={() => this.refs.offercarrierpop1}
                                            getDataMethod={"getCarTypes"}
                                            params={{ limit: 100, offset: 0 }}
                                            size="small"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {isFirstWeight && accountingStrategy === 1 ? (
                            <div className="cost-item-view">
                                首&emsp;&emsp;重：
                                <InputNumber
                                    value={firstWeight}
                                    onChange={(value) => this.setState({ firstWeight: value })}
                                    min={0}
                                    size="small"
                                />
                            </div>
                        ) : null}
                        <div style={{ textAlign: "end", borderTop: "1px solid #eee", paddingTop: "10px" }}>
                            <Button onClick={this.hide} style={{ marginRight: 10 }} size="small">
                                取消
                            </Button>
                            <Button onClick={this.addToHeader} type="primary" size="small">
                                确定
                            </Button>
                        </div>
                    </div>
                }
                trigger="click"
            >
                <Tag
                    title={item.name}
                    className="text-overflow-ellipsis costitem-tag"
                    color="volcano"
                    onClick={() => this.clickItem(item)}
                >
                    {item.name}
                </Tag>
            </Popover>
        );
    }
}

/**
 * 编辑费用项基本字段
 *
 * @class OnEditCostItemView
 * @extends {Component}
 */
class OnEditCostItemView extends Component {
    state = {
        id: null,
        visible: false,
        carType: null, // 车类型ID
        carTypeName: null, // 车类型名称
        costUnitId: null, // 费用单位ID
        costUnitName: null, // 费用单位名称
        expenseItemId: null, // 费用项目ID
        expenseItemName: null, // 费用项目名称
        lowestFee: null, // 最低收费
        endValue: null, // 限制区间 end
        startValue: null, // 限制区间 start
        firstWeight: null, // 首重
        intervalCostUnitName: null, // 限制区间单位名称
        intervalCostUnitId: null, // 限制区间单位ID
        accountingStrategy: 1
        // isMonthlyCalculation: 0 //是否允许月结
    };

    constructor(props) {
        super(props);
        let { data } = props;
        // console.log('constructor', data)
        this.state.carType = data.carType;
        this.state.carTypeName = data.carTypeName;
        this.state.costUnitId = data.costUnitId;
        this.state.costUnitName = data.costUnitName;
        this.state.expenseItemId = data.expenseItemId;
        this.state.expenseItemName = data.expenseItemName;
        this.state.lowestFee = data.lowestFee;
        this.state.endValue = data.endValue;
        this.state.startValue = data.startValue;
        this.state.firstWeight = data.firstWeight;
        this.state.intervalCostUnitName = data.intervalCostUnitName;
        this.state.intervalCostUnitId = data.intervalCostUnitId;
        this.state.accountingStrategy = data.accountingStrategy;
        this.state.id = data.id;
        //this.state.isMonthlyCalculation = data.isMonthlyCalculation
    }

    editToHeader = () => {
        const props = this.props;
        const { data, editHeader } = props;
        const item = data;
        let {
            id,
            carType, // 车类型ID
            carTypeName, // 车类型名称
            costUnitId, // 费用单位ID
            costUnitName, // 费用单位名称
            expenseItemId, // 费用项目ID
            expenseItemName, // 费用项目名称
            firstWeight, // 首重
            lowestFee, // 最低收费
            endValue, // 限制区间 end
            intervalCostUnitName, // 限制区间单位名称
            intervalCostUnitId, // 限制区间单位ID
            startValue, // 限制区间 start
            accountingStrategy //计费方式
        } = this.state;

        if (accountingStrategy !== 2 && (!costUnitId || !costUnitName)) {
            message.error("请选择单位!");
            return;
        }
        if (
            (startValue || startValue === 0 || endValue || endValue === 0) &&
            !intervalCostUnitName &&
            ((item.name && item.name !== "续重") || (item.expenseItemName && item.expenseItemName !== "续重"))
        ) {
            message.error("请选择限制区间单位!");
            return;
        }
        if (item.expenseItemName === "首重" && !firstWeight && !firstWeight !== 0) {
            message.error("请输入首重重量!");
            return;
        }

        if (!startValue && !endValue && intervalCostUnitId) {
            // console.log('请填写限制区间xxxx', message)
            message.error("请填写限制区间!");
            return;
        }

        // if((mode.businessModeName === '整车' || mode.businessModeName === '整柜') && (!carTypeName || carTypeName === null)) { //是否是整车
        //     message.error('请选择车型!')
        //     return
        // }
        this.hide();
        editHeader(
            strNoLengthToNull({
                ...item,
                type: 12,
                title: item.expenseItemName,
                carType, // 车类型ID
                carTypeName, // 车类型名称
                costUnitId: accountingStrategy === 2 ? -1 : costUnitId, // 费用单位ID
                costUnitName: accountingStrategy === 2 ? "月" : costUnitName,
                firstWeight, // 首重
                expenseItemId: item.expenseItemId, // 费用项目ID
                expenseItemName: item.expenseItemName, // 费用项目名称
                lowestFee, // 最低收费
                intervalCostUnitName, // 限制区间单位名称
                intervalCostUnitId, // 限制区间单位ID
                endValue, // 限制区间 end
                startValue, // 限制区间 start
                accountingStrategy,
                id
            }),
            props.index
        );
    };

    hide = () => {
        this.setState({
            visible: false
        });
    };

    handleVisibleChange = (visible) => {
        let { costItems, data } = this.props;
        let defaultIsMonty = costItems && costItems.filter((item) => item.id === data.expenseItemId);
        this.setState({
            visible
            //isMonthlyCalculation: defaultIsMonty && defaultIsMonty.length > 0 && defaultIsMonty[0].isMonthlyCalculation
        });
    };

    render() {
        const props = this.props;
        let { type, item, offercarrierpop, data } = props;
        let {
            visible,
            carType, // 车类型ID
            carTypeName, // 车类型名称
            costUnitId, // 费用单位ID
            costUnitName, // 费用单位名称
            expenseItemId, // 费用项目ID
            expenseItemName, // 费用项目名称
            lowestFee, // 最低收费
            intervalCostUnitName, // 限制区间单位名称
            intervalCostUnitId, // 限制区间单位ID
            firstWeight, // 首重
            endValue, // 限制区间 end
            startValue, // 限制区间 start
            reloadDom,
            accountingStrategy,
            //isMonthlyCalculation,
            filterId
        } = this.state;
        let isFirstWeight = firstWeight ? true : false;
        let ContinuedWeight = expenseItemName === "续重" ? true : false;
        return (
            <Popover
                placement="topLeft"
                title="设置标签数据"
                visible={visible}
                overlayStyle={{ minWidth: 310 }}
                onVisibleChange={this.handleVisibleChange}
                getPopupContainer={offercarrierpop}
                // content="XXXXXXXX"
                content={
                    <div ref="offercarrierpop1">
                        <div className="cost-item-view flex">
                            <div>计费方式：</div>
                            <div>
                                <RadioGroup
                                    onChange={(e) => {
                                        this.setState({
                                            accountingStrategy: e.target.value
                                        });
                                    }}
                                    value={accountingStrategy}
                                >
                                    <Radio value={1}>计量</Radio>
                                    <Radio value={2}>月结</Radio>
                                </RadioGroup>
                            </div>
                        </div>
                        {accountingStrategy === 2 ? null : (
                            <div className="cost-item-view flex flex-vertical-center">
                                <div>费用单位：</div>
                                <div style={{ width: 90 }}>
                                    {reloadDom ? null : (
                                        <SelectDataBook
                                            onChangeValue={(value = {}) =>
                                                this.setState({ costUnitId: value.id, costUnitName: value.title })
                                            }
                                            getPopupContainer={() => this.refs.offercarrierpop1}
                                            defaultValue={costUnitId ? { id: costUnitId, title: costUnitName } : null}
                                            //text='费用单位'
                                            getDataMethod={"getUnitConfigureByExpenseId"}
                                            params={{ id: expenseItemId }}
                                            allowClear={false}
                                            size="small"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {ContinuedWeight || isFirstWeight || accountingStrategy === 2 ? null : (
                            <div className="cost-item-view">
                                最低收费：
                                <InputNumber
                                    value={lowestFee}
                                    onChange={(value) => this.setState({ lowestFee: value })}
                                    min={0}
                                    size="small"
                                />
                            </div>
                        )}
                        {ContinuedWeight && accountingStrategy === 1 ? (
                            <div className="cost-item-view">
                                续重区间：
                                <InputNumber
                                    value={startValue}
                                    onChange={(value = "") => this.setState({ startValue: value })}
                                    min={0}
                                    size="small"
                                />
                                -
                                <InputNumber
                                    value={endValue}
                                    onChange={(value = "") => this.setState({ endValue: value })}
                                    min={0}
                                    size="small"
                                />
                            </div>
                        ) : isFirstWeight ? null : accountingStrategy === 2 ? null : (
                            <div className="cost-item-view flex" style={{ height: 24 }}>
                                <div>
                                    限制区间：
                                    <InputNumber
                                        value={startValue}
                                        onChange={(value = "") => this.setState({ startValue: value })}
                                        style={{ width: 60 }}
                                        min={0}
                                        size="small"
                                    />
                                    &nbsp;-&nbsp;
                                    <InputNumber
                                        value={endValue}
                                        onChange={(value = "") => this.setState({ endValue: value })}
                                        style={{ width: 60 }}
                                        min={0}
                                        size="small"
                                    />
                                    &nbsp;&nbsp;
                                </div>
                                <div style={{ width: 70 }}>
                                    {reloadDom ? null : (
                                        <SelectDataBook
                                            defaultValue={
                                                intervalCostUnitId
                                                    ? { id: intervalCostUnitId, title: intervalCostUnitName }
                                                    : null
                                            }
                                            onChangeValue={(value = {}) =>
                                                this.setState({
                                                    intervalCostUnitId: value.id || null,
                                                    intervalCostUnitName: value.title || null
                                                })
                                            }
                                            getPopupContainer={() => this.refs.offercarrierpop1}
                                            //text='费用单位'
                                            getDataMethod={"getConfigureTransportUnit"}
                                            params={{ unitClassification: 1, unitKind: 2 }}
                                            size="small"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {ContinuedWeight || isFirstWeight ? null : (
                            <div className="cost-item-view flex">
                                <div>设置车型：</div>
                                <div style={{ width: 180 }}>
                                    {reloadDom ? null : (
                                        <SelectDataBook
                                            defaultValue={carType ? { id: carType, name: carTypeName } : null}
                                            labelField={"name"}
                                            onChangeValue={(value = {}) =>
                                                this.setState({ carType: value.id, carTypeName: value.name })
                                            }
                                            getPopupContainer={() => this.refs.offercarrierpop1}
                                            getDataMethod={"getCarTypes"}
                                            params={{ limit: 100, offset: 0 }}
                                            size="small"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {isFirstWeight && accountingStrategy === 1 ? (
                            <div className="cost-item-view">
                                首&emsp;&emsp;重：
                                <InputNumber
                                    value={firstWeight}
                                    onChange={(value) => this.setState({ firstWeight: value })}
                                    min={0}
                                    size="small"
                                />
                            </div>
                        ) : null}
                        <div style={{ textAlign: "end", borderTop: "1px solid #eee", paddingTop: "10px" }}>
                            <Button onClick={this.hide} style={{ marginRight: 10 }} size="small">
                                取消
                            </Button>
                            <Button onClick={this.editToHeader} type="primary" size="small">
                                确定
                            </Button>
                        </div>
                    </div>
                }
                trigger="click"
            >
                <Button size="small" type="dashed" shape="circle" icon="edit" title={`编辑${expenseItemName || ""}`} />
            </Popover>
        );
    }
}

/**
 * 设置表头view
 * @class ModalSetheader
 * @extends {Component}
 */
export default class ModalSetheader extends Component {
    constructor(props) {
        super(props);
        if (props.getThis) {
            props.getThis(this);
        }
        this.state = {
            keyWord: null, //费用名称
            loading: false,
            visible: false,
            costItems: [],
            columns: [], // 基本表头
            costList: [], // 费用项表头
            headerType: "base" // 费用项表头类型 base->基本 || cost->费用项
        };
    }

    // componentDidMount() {
    //     const { businessModeId, quotationTypeId } = this.props
    //     this.getCostItemData(businessModeId, quotationTypeId)
    // }

    // 获取费用项
    getCostItemData = async (businessModeId) => {
        const { keyWord } = this.state;
        const { businessModeName, isCustoms } = this.props;
        this.setState({ loading: true });
        try {
            let res = await rApi.getListByExpenseType({
                // 获取费用项数据
                limit: 999999,
                offset: 0,
                //   receivableOrPayable: (quotationTypeId === 0 || quotationTypeId === 1) ? '应付': quotationTypeId === 2 ? '应收' : null,
                typeName:
                    isCustoms || businessModeName === "报关（纯关务）" || businessModeId === 262
                        ? "关务费用"
                        : "运输费用",
                businessModeId,
                keyWord
            });
            this.setState({ costItems: res });
        } catch (error) {
            console.log("getCostItemDataErr", error);
        }
        this.setState({ loading: false });
    };

    // 弹窗显示
    show = ({ columns, costList, quotationTypeOpts, businessModeId, businessModeName }) => {
        this.setState(
            {
                visible: true,
                columns: [...columns],
                costList: [...costList],
                businessModeId: businessModeId
            },
            () => {
                this.getCostItemData(businessModeId);
            }
        );
    };

    // 弹窗隐藏
    close = () => {
        // console.log('close')
        this.setState({
            visible: false,
            columns: [],
            costList: [],
            headerType: "base"
        });
    };

    // 切换表头选择类型
    onChange = (e) => {
        // console.log('onChange', e)
        this.setState({ headerType: e.target.value });
    };

    // 添加到表头项
    addHeader = (type, col) => {
        // console.log('addHeader', type, col)
        if (type === "base") {
            const columns = [
                ...this.state.columns,
                ...ColumnFactory.execute([[col.key, col.title, col.width, this.props.renderCell]])
            ];
            this.setState({ columns });
        } else if (type === "cost") {
            const costList = [...this.state.costList, col];
            this.setState({ costList });
        }
    };

    // 编辑费用项表头项
    editHeader = (newCol, index) => {
        // console.log('editHeader', newCol, index)
        const costList = [...this.state.costList];
        costList[index] = newCol;
        this.setState({ costList });
    };

    // 移除表头项
    removeHeader = (type, col, index) => {
        // console.log('removeHeader', type, col)
        if (type === "base") {
            const { columns } = this.state;
            columns.splice(index, 1);
            this.setState({ columns });
        } else if (type === "cost") {
            let costList = [...this.state.costList];
            costList.forEach((item, i) => {
                if (item.id === col.id) {
                    costList.splice(i, 1);
                }
            });
            this.setState({ costList });
        }
    };

    // 费用项拖拽
    moveColumn = (dragIndex, hoverIndex) => {
        // console.log('费用项拖拽', dragIndex, hoverIndex)
        const { changeHeader } = this.props;
        let { costList } = this.state;
        let items = costList.splice(dragIndex, 1);
        costList.splice(hoverIndex, 0, items[0]);
        changeHeader(costList);
    };

    // 完成
    handleOk = () => {
        const { changeHeader } = this.props;
        const { columns, costList } = this.state;
        changeHeader(columns, costList);
        this.close();
    };

    render() {
        let { mode, businessModeId, quotationTypeId } = this.props;
        const { costItems, columns, costList, loading, visible, headerType } = this.state;
        //  console.log('columns', columns, costList, headerType)
        const BASE_SHOW = headerType === "base";
        const COST_SHOW = headerType === "cost";
        return (
            <Modal
                style={{ left: 120 }}
                width={710}
                maskClosable={false}
                visible={visible}
                title="选择费用项"
                onCancel={this.close}
                footer={[
                    <Button key="confirm" type="primary" onClick={this.handleOk}>
                        完成
                    </Button>
                ]}
                bodyStyle={{
                    padding: "10px"
                }}
            >
                <div className="flex quick-carrier-header-pop">
                    <div
                        ref="offercarrierpop"
                        className="flex1"
                        style={{ borderRight: "1px solid #ddd", paddingRight: 5, marginRight: 5 }}
                    >
                        <RadioGroup size="small" onChange={this.onChange} value={headerType}>
                            <RadioButton value="base">基本</RadioButton>
                            <RadioButton value="cost">费用项</RadioButton>
                        </RadioGroup>
                        {COST_SHOW && (
                            <Search
                                placeholder="费用名称"
                                onSearch={(value) =>
                                    this.setState({ keyWord: trim(value) }, () => this.getCostItemData(businessModeId))
                                }
                                size="small"
                                style={{ width: 140, marginLeft: "55px" }}
                            />
                        )}
                        <div style={{ marginTop: 10 }}>
                            {BASE_SHOW ? (
                                <Scrollbars style={{ height: 237, margin: "5px 0" }}>
                                    <div>
                                        {
                                            //基本
                                            function () {
                                                const showList = Object.values(columnMap).filter((item) =>
                                                    columns.every((col) => col.key !== item.key)
                                                );
                                                // console.log('list', showList, columns)
                                                if (showList.length > 0) {
                                                    return showList.map((item, index) => {
                                                        return (
                                                            <Popconfirm
                                                                key={index}
                                                                title={`确定添加此项至表头?`}
                                                                onConfirm={() => this.addHeader("base", item)}
                                                                getPopupContainer={() => this.refs.offercarrierpop}
                                                                okText="确定"
                                                            >
                                                                <Tag
                                                                    title={item.title}
                                                                    className="text-overflow-ellipsis costitem-tag"
                                                                    color="volcano"
                                                                >
                                                                    {item.title}
                                                                </Tag>
                                                            </Popconfirm>
                                                        );
                                                    });
                                                }
                                                return <span>{"无"}</span>;
                                            }.bind(this)()
                                        }
                                    </div>
                                </Scrollbars>
                            ) : COST_SHOW ? (
                                <Spin spinning={loading}>
                                    <Scrollbars style={{ height: 237, margin: "5px 0" }}>
                                        <div>
                                            {costItems.map((item, index) => {
                                                return (
                                                    <CostItemView
                                                        // {...props}
                                                        offercarrierpop={() => this.refs.offercarrierpop}
                                                        key={index}
                                                        item={item}
                                                        mode={mode}
                                                        businessModeId={businessModeId}
                                                        addHeader={(params) => this.addHeader("cost", params)}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </Scrollbars>
                                </Spin>
                            ) : null}
                        </div>
                    </div>
                    <div style={{ width: 360 }}>
                        {BASE_SHOW ? (
                            <Scrollbars style={{ height: 267, margin: "5px 0" }}>
                                <div>
                                    {columns.map((item, index) => {
                                        const CAN_DELETE = item.key !== "a" && item.key !== "b";
                                        return (
                                            <div className="flex" style={{ padding: "5px" }} key={index}>
                                                <div className="flex1">{item.title}</div>
                                                {CAN_DELETE && (
                                                    <Button
                                                        onClick={() => this.removeHeader("base", item, index)}
                                                        size="small"
                                                        type="dashed"
                                                        shape="circle"
                                                        icon="close"
                                                        title="删除"
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </Scrollbars>
                        ) : COST_SHOW ? (
                            <Scrollbars style={{ height: 267, margin: "5px 0" }}>
                                <DragView
                                    cards={costList}
                                    Item={(pro) => {
                                        let text = pro.column.title;
                                        return (
                                            <div className="flex" key={pro.index}>
                                                <div className="flex1 offer-carrier-setheader" title={text}>
                                                    {costItemObjectToString(pro.column)}
                                                </div>
                                                <div style={{ marginRight: "5px" }}>
                                                    <OnEditCostItemView
                                                        offercarrierpop={() => this.refs.offercarrierpop}
                                                        data={pro.column}
                                                        costItems={costItems}
                                                        index={pro.index}
                                                        mode={mode}
                                                        businessModeId={businessModeId}
                                                        editHeader={this.editHeader}
                                                    />
                                                </div>
                                                <Button
                                                    onClick={() => this.removeHeader("cost", pro.column)}
                                                    size="small"
                                                    type="dashed"
                                                    shape="circle"
                                                    icon="close"
                                                    title="删除"
                                                />
                                            </div>
                                        );
                                    }}
                                    moveCard={this.moveColumn}
                                    isNotFilter
                                />
                            </Scrollbars>
                        ) : null}
                    </div>
                </div>
            </Modal>
        );
    }
}
