import Loading from "./loadview";
import Loadable from "@src/components/react_loadable";
import React from "react";

export default (loader) => {
    const loadable = Loadable({
        loader: loader,
        loading: (props) => <Loading loader={loader} {...props} loadable={loadable} />,
        delay: 300,
        timeout: 60000
    });
    return loadable;
};
