import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class CarApi {
    /**
     * 获取车辆列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.POST(url, params);
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        return this.POST(url, params);
    }

    [children.ENABLE.apiName](params) {
        // 启用
        let url = children.ENABLE.url;
        return this.POST(url, params);
    }

    [children.DISABLE.apiName](params) {
        // 禁用
        let url = children.DISABLE.url;
        return this.POST(url, params);
    }

    [children.REVIEW.apiName](params) {
        // 审核
        let url = children.REVIEW.url;
        return this.POST(url, params);
    }

    [children.ASSOCIATE_DRIVER.apiName](params) {
        // 关联司机
        let url = children.ASSOCIATE_DRIVER.url;
        return this.POST(url, params);
    }

    [children.REPORT.apiName](params) {
        // 上报
        let url = children.REPORT.url;
        return this.POST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url;
        // console.log('url', url)
        return this.POST(url, params);
    }

    [children.AUTH_DATA.apiName](params) {
        let url = children.AUTH_DATA.url + "/" + params.id;
        return this.POST(url, params);
    }

    [children.CANCEL_AUTH.apiName](params) {
        let url = children.CANCEL_AUTH.url + "/" + params.id;
        return this.POST(url, params);
    }

    [children.EXPORT_LIST.apiName](params) {
        // 导出表数据
        let url = children.EXPORT_LIST.url;
        return this.POSTFILE(url, deleteNull(params));
    }

    [children.EXPORT_TEMP.apiName](params) {
        //导出车辆模板
        let url = children.EXPORT_TEMP.url;
        return this.GETFILE(url, deleteNull(params));
    }

    [children.IMPORT_CAR.apiName](params) {
        //导入
        let url = children.IMPORT_CAR.url;
        return this.POST(url, params);
    }

    resourceCarBindDevice(params) {
        //绑定设备
        return this.POST(`resource/car/bindDevice`, params);
    }

    resourceCarUntiedDevice(params) {
        //删除设备
        return this.GET(`resource/car/untied/${params.id}`);
    }

    getVehicleEnergy(params) {
        // 获取能源类型
        return this.GET(`resource/enum/getVehicleEnergy`);
    }

    getCarCodeColor(params) {
        // 获取车牌颜色
        return this.GET(`resource/enum/getCarCodeColor`);
    }

    submitCar(params) {
        // 提交
        return this.POST(`resource/car/submit`, params);
    }

    reviewOrReject(params) {
        // 获取车牌颜色
        return this.POST(`resource/car/reviewOrReject`, params);
    }
}
