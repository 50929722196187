const moduleName = "project/";
module.exports.id = {
    id: "CONTRACT_MGT_CARRIER_DETAILS",
    method: "POST",
    name: "承运商合同明细",
    type: "menu_hide"
};

// let moduleName = ''

module.exports.children = {};
