/**
 * @fileoverview 百度地图的画弧线类，对外开放。
 * 允许用户在地图上完成画弧线的功能。
 * 使用者可以自定义弧线的相关样式，例如线宽、颜色等等。
 * 主入口类是<a href="symbols/BMapLib.CurveLine.html">CurveLine</a>，
 * 具体类参考同：<a href="http://developer.baidu.com/map/reference/index.php?title=Class:%E8%A6%86%E7%9B%96%E7%89%A9%E7%B1%BB/Polyline" target="_blank">Polyline</a>
 * 基于Baidu Map API 1.5。
 *
 * @author Baidu Map Api Group
 * @version 1.5
 */

/**
 * @namespace BMap的所有library类均放在BMapLib命名空间下
 */

export default function (BMapGL, map) {
    /**
     * 根据弧线的坐标节点数组
     */
    const getCurvePoints = function (points) {
        let curvePoints = [];
        for (let i = 0; i < points.length - 1; i++) {
            let p = getCurveByTwoPoints(points[i], points[i + 1]);
            if (p && p.length > 0) {
                curvePoints = curvePoints.concat(p);
            }
        }
        // console.log('curvePoints', curvePoints)
        return curvePoints;
    };

    /**
     * 根据两点获取曲线坐标点数组
     * @param Point 起点
     * @param Point 终点
     */
    const getCurveByTwoPoints = function (obj1, obj2) {
        if (!obj1 || !obj2 || !(obj1 instanceof BMapGL.Point) || !(obj2 instanceof BMapGL.Point)) {
            return null;
        }

        let B1 = function (x) {
            return 1 - 2 * x + x * x;
        };
        let B2 = function (x) {
            return 2 * x - 2 * x * x;
        };
        let B3 = function (x) {
            return x * x;
        };

        let curveCoordinates = [];

        let count = 30; // 曲线是由一些小的线段组成的，这个表示这个曲线所有到的折线的个数
        let isFuture = false;
        let t, h, h2, lat3, lng3, j, t2;
        let LnArray = [];
        let i = 0;
        let inc = 0;

        if (typeof obj2 == "undefined") {
            if (typeof curveCoordinates != "undefined") {
                curveCoordinates = [];
            }
            return;
        }

        let lat1 = parseFloat(obj1.lat);
        let lat2 = parseFloat(obj2.lat);
        let lng1 = parseFloat(obj1.lng);
        let lng2 = parseFloat(obj2.lng);

        // 计算曲线角度的方法
        if (lng2 > lng1) {
            if (parseFloat(lng2 - lng1) > 180) {
                if (lng1 < 0) {
                    lng1 = parseFloat(180 + 180 + lng1);
                }
            }
        }

        if (lng1 > lng2) {
            if (parseFloat(lng1 - lng2) > 180) {
                if (lng2 < 0) {
                    lng2 = parseFloat(180 + 180 + lng2);
                }
            }
        }
        j = 0;
        t2 = 0;
        if (lat2 == lat1) {
            t = 0;
            h = lng1 - lng2;
        } else if (lng2 == lng1) {
            t = Math.PI / 2;
            h = lat1 - lat2;
        } else {
            t = Math.atan((lat2 - lat1) / (lng2 - lng1));
            h = (lat2 - lat1) / Math.sin(t);
        }
        if (t2 == 0) {
            t2 = t + Math.PI / 5;
        }
        h2 = h / 2;
        lng3 = h2 * Math.cos(t2) + lng1;
        lat3 = h2 * Math.sin(t2) + lat1;

        for (i = 0; i < count + 1; i++) {
            curveCoordinates.push(
                new BMapGL.Point(
                    lng1 * B1(inc) + lng3 * B2(inc) + lng2 * B3(inc),
                    lat1 * B1(inc) + lat3 * B2(inc) + lat2 * B3(inc)
                )
            );
            inc = inc + 1 / count;
        }
        return curveCoordinates;
    };

    /**
     * 重写弧线的编辑功能
     */
    function enableEditing() {
        if (this.map) {
            this.disableEditing();
            for (let i = 0; i < this.points.length; i++) {
                let marker = new BMapGL.Marker(this.points[i], {
                    icon: new BMapGL.Icon("../src/circle.png", new BMapGL.Size(16, 16)),
                    enableDragging: true,
                    raiseOnDrag: true
                });
                let self = this;
                marker.addEventListener("dragend", function () {
                    self.points.length = 0;
                    for (let i = 0; i < self.editMarkers.length; i++) {
                        self.points.push(self.editMarkers[i].getPosition());
                    }
                    let curvePoints = getCurvePoints(self.points);
                    self.setPath(curvePoints);
                });
                marker.index = i;
                this.editMarkers.push(marker);
                this.map.addOverlay(marker);
            }
        }
        this.isEditing = true;
    }

    /**
     * 重写弧线的编辑功能
     */
    function disableEditing() {
        this.isEditing = false;
        //清空之前的编辑点
        for (let i = 0; i < this.editMarkers.length; i++) {
            this.map.removeOverlay(this.editMarkers[i]);
            this.editMarkers[i] = null;
        }
        this.editMarkers.length = 0;
    }

    /**
     * 获取弧线的坐标点
     */
    function getPath() {
        return this.linePoints;
    }

    const CurveLine =
        /**
         * CurveLine类的构造函数
         * @class 弧线类，实现效果的<b>入口</b>。
         * 实例化该类后，即可返回一个弧线的Polyline对象，使用方法同<a href="http://developer.baidu.com/map/reference/index.php?title=Class:%E8%A6%86%E7%9B%96%E7%89%A9%E7%B1%BB/Polyline" target="_blank">Polyline</a>
         * 即可调用map.addOverlay方法添加到地图当中
         *
         * @constructor
         * @param {Array<Point>} Array<Point> Point数组对象
         * @param {Json Object} opts 可选的输入参数，非必填项。可输入选项参考<a href="http://developer.baidu.com/map/reference/index.php?title=Class:%E8%A6%86%E7%9B%96%E7%89%A9%E7%B1%BB/PolylineOptions" target="_blank">PolylineOptions</a><br />
         *
         * @example <b>参考示例：</b><br />
         * let map = new BMapGL.Map("container");<br />map.centerAndZoom(new BMapGL.Point(116.404, 39.915), 15);<br/>let points = [new BMapGL.Point(116.432045,39.910683), new BMapGL.Point(116.388522,39.985964), <br/>new BMapGL.Point(117.218862,39.141468), new BMapGL.Point(121.485947,31.510083)];
         * <br />let curve = new BMapLib.CurveLine(points, {strokeColor:"blue", strokeWeight:3, strokeOpacity:0.5}); //新建弧线覆盖物对象
         * <br />map.addOverlay(curve); //添加到地图
         * <br/>curve.enableEditing(); //开启编辑功能
         */
        function (points, opts) {
            const curvePoints = getCurvePoints(points);
            const polyline = new BMapGL.Polyline(curvePoints, opts);
            polyline.addEventListener("lineupdate", function () {
                if (this.isEditing) {
                    this.enableEditing();
                }
            });
            polyline.editMarkers = [];
            polyline.linePoints = points;
            polyline.enableEditing = enableEditing;
            polyline.disableEditing = disableEditing;
            polyline.getPath = getPath;
            // polyline.curvePoints = curvePoints
            // console.log('polyline', polyline, curvePoints)
            return polyline;
        };
    return CurveLine;
}
