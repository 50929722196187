import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class AccountPayableApi {
    /**
     * @param {any} params
     * @returns
     *
     * @memberOf AccountPayableApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        return this.POST(url, params);
    }

    [children.EDIT_ACCOUNT.apiName](params) {
        let url = children.EDIT_ACCOUNT.url;
        return this.POST(url, params);
    }

    [children.CONFIRM_ACCOUNT.apiName](params) {
        let url = children.CONFIRM_ACCOUNT.url;
        return this.POST(url, params);
    }

    [children.CANCEL_ACCOUNT.apiName](params) {
        let url = children.CANCEL_ACCOUNT.url;
        return this.POST(url, params);
    }

    [children.CONFIRM_FINANCE.apiName](params) {
        let url = children.CONFIRM_FINANCE.url;
        return this.POST(url, params);
    }

    [children.CANCEL_FINANCE.apiName](params) {
        let url = children.CANCEL_FINANCE.url;
        return this.POST(url, params);
    }

    [children.DEL_ACCOUNT.apiName](params) {
        let url = children.DEL_ACCOUNT.url + `/${params.id}`;
        return this.POST(url, params);
    }

    [children.EXPORT_CASH.apiName](params) {
        //导出对账单(现结车)
        let url = children.EXPORT_CASH.url;
        return this.POSTFILE(url, params);
    }

    [children.EXPORT_FULL.apiName](params) {
        //导出对账单(运满满)
        let url = children.EXPORT_FULL.url;
        return this.POSTFILE(url, params);
    }

    [children.CONFIRM_CUSTOMER.apiName](params) {
        //付款确认
        let url = children.CONFIRM_CUSTOMER.url + `/${params.id}/${params.payableStatus}`;
        return this.GET(url, params);
    }

    [children.EXPORT_LIST.apiName](params) {
        // 导出
        let url = children.EXPORT_LIST.url;
        return this.POSTFILE(url, params);
    }

    [children.EXPORT_DETAILS.apiName](params) {
        // 导出明细
        let url = children.EXPORT_DETAILS.url;
        return this.POSTFILE(url, params);
    }

    getAccountPayableList(params) {
        //获取应付对账单列表
        return this.POST(`finance/payableReconciliation/list`, params);
    }

    getAttachmentPayable(params) {
        //根据对账单id获取附件信息
        return this.GET(`finance/payableReconciliation/getAttachmentById/${params.id}`);
    }

    uploadAccountPayable(params) {
        //对账单附件上传
        return this.POST(`finance/payableReconciliation/upload`, params);
    }

    exportSpecAccountPayableList(params) {
        //应付对账特殊业务导出
        return this.POSTFILE(`order/stowage/exportSpecialBusinessData`, params);
    }

    updateAssociatePayment(params) {
        // 编辑应付对账待开立
        return this.POST(`order/sendCar/updateAssociatePaymentCarrier`, params);
    }
}
