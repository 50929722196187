const m = "wms/report";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_MAP",
    name: "仓位图详情",
    type: "menu_hide",
    apiName: "getReportMapList",
    method: "POST",
    url: `${m}/MapList`
};

export const children = {
    // EXPORT: {
    //     id: 'WAREHOUSE_MANAGEMENT_MAP_EXPORT',
    //     apiName: 'exportMapData',
    //     method: 'POSTFILE',
    //     name: '导出报表',
    //     type: 'view',
    //     url: `${m}/exportMapData`
    // }
};

export default {
    id,
    children
};
