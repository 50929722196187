const moduleName = "order/";
import getChildPowerJson from "../../../../utils/getChildPowerJson";
import newPage from "../edit/power_hide";
export const id = {
    id: "ORDER_MAINTENANCE_LIST",
    method: "POST",
    name: "应收数据维护",
    type: "menu",
    apiName: "getOrderMaintainList",
    url: `${moduleName}orderMaintain/list`,
    sort: 104
};

// let moduleName = ''
const nPage = getChildPowerJson.toJson(newPage);
export const children = {
    [nPage.id]: nPage,
    EDIT_DATA: {
        id: "ORDER_MAINTENANCE_EDIT_PAGE_EDIT",
        apiName: "orderMaintenanceEdit",
        method: "POST",
        name: "维护",
        type: "view",
        url: `${moduleName}orderMaintain/edit`
    },
    ON_LOOK: {
        id: "ORDER_MAINTENANCE_LIST_ON_LOOK",
        name: "查看",
        type: "view"
    },
    BATCH_EDIT: {
        id: "ORDER_MAINTENANCE_LIST_BATCH_EDIT",
        name: "批量维护",
        type: "view"
    }
};
