import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class ReportShipmentApi {
    /**
     * @param {any} params
     * @returns
     * @memberOf ReportShipmentApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.EXPORT.apiName](params) {
        let url = children.EXPORT.url;
        return this.POSTFILE(url, deleteNull(params));
    }

    getShipmentWarehouse(params) {
        // 获取出货需求仓库
        let url = `wms/report/getShipmentWarehouse`;
        return this.GET(url, params);
    }

    getShipmentClient(params) {
        // 获取出货需求客户
        let url = `wms/report/getShipmentClient`;
        return this.GET(url, params);
    }

    // 获取汇总数据
    getShipmentReportSummary(params) {
        let url = `wms/report/sendCount`;
        return this.POST(url, params);
    }
}
