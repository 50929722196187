const m = "order/waybill";
export const id = {
    id: "SUPERVISION_ELECTRONIC_WAYBILL_DETAILS",
    method: "POST",
    name: "电子运单详情",
    type: "menu_hide",
    apiName: "getElectronicList",
    url: `${m}/list`
};

export const children = {
    // ADD_DATA: {
    //     id: 'CLIENT_EVALUATION_ADD_DATA',
    //     apiName: 'onSaveSuggestItem',
    //     method: 'POST',
    //     name: '新增',
    //     type: 'view',
    //     url: `${m}/save`
    // }
};
