const m = "wms/inventoryChange";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_INVENTORY_CHANGE",
    method: "POST",
    name: "变更管理",
    type: "menu",
    apiName: "getInventoryChange",
    url: `${m}/list`
};

export const children = {
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_CHANGE_DEL_DATA",
        apiName: "inventoryDelete",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${m}/delete`
    },
    CANCEL_CHANGE: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_CHANGE_CANCEL_CHANGE",
        apiName: "inventoryCancelChange",
        method: "POST",
        name: "取消变更",
        type: "view",
        url: `${m}/cancel`
    },
    EXPORT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_CHANGE_EXPORT_DATA",
        apiName: "inventoryChangeExport",
        method: "POST",
        name: "导出",
        type: "view",
        url: `${m}/export`
    }
};
