import { children, id } from "./power";
import { deleteNull } from "@src/utils";

const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class {
    [children.ADD_DATA_CARRIER.apiName](params) {
        let url = children.ADD_DATA_CARRIER.url;
        return this.POST(url, params);
    }

    [children.DEL_DATA_CARRIER.apiName](params) {
        let url = children.DEL_DATA_CARRIER.url + "/" + params.id;
        return this.GET(url, params);
    }

    [children.EDIT_DATA_CARRIER.apiName](params) {
        //导出
        let url = children.EDIT_DATA_CARRIER.url;
        return this.POST(url, params);
    }
}
