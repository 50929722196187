import { app, initGlobalModels } from "@src/models";
import global, { initState } from "@src/models/global";
import moment from "moment";
import promiseF from "promise.prototype.finally";
import routerInit, { qiankunInitRoute } from "./router";
import register from "./registerServiceWorker";
import "./libs/style/main.less";
// import MODULEDEFINE from '@src/views/MODULEDEFINE'
// unregister()
// 导出子应用生命周期 挂载前
export async function bootstrap(props) {
    console.log("bootstrap", props);
}

let openTimer = null;

// 导出子应用生命周期 挂载前 挂载后
export async function mount(props) {
    const { container } = props;
    // console.log('props.element', props)
    promiseF.shim();
    moment.locale("zh-cn");
    const state = {
        ...initState(),
        ...global.reducers.openTab(global.state, {
            payload: {
                key: props?.initState?.activeTab.id,
                component: props?.initState?.activeTab.id
            }
        })
    };
    // console.log("state", state);
    initGlobalModels({
        global: {
            state
        }
    });
    qiankunInitRoute(props);
    props.updateMicroEvent((event) => {
        const { moduleList, activeTab } = event;
        if (activeTab) {
            /** 防止阻塞ui线程 */
            // clearTimeout(openTimer)
            // console.log("activeTab", activeTab);
            app._store.dispatch({
                type: "global/openTab",
                payload: {
                    id: activeTab.pid,
                    key: activeTab.id,
                    component: activeTab.id,
                    pageData: activeTab.pageData
                }
            });
        }
    });
    window.scmOpenTab = props.openTab || null;
    app.start(container ? container.querySelector("#root_sscm") : document.querySelector("#root_sscm"));
    return new Promise((resolve, reject) => {
        resolve();
    });
}

// 导出子应用生命周期 挂载前 卸载后
export async function unmount() {
    // console.log('unmount')
    app._models.forEach((item) => {
        app.unmodel(item.namespace);
    });
}

if (window.__POWERED_BY_QIANKUN__) {
    // console.log("qiankun");
    if (process.env.NODE_ENV === "production") {
        // eslint-disable-next-line no-undef
        __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__ + "sscm/";
    } else {
        __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
    }
} else {
    promiseF.shim();
    moment.locale("zh-cn");
    initGlobalModels();
    routerInit();
    app.start("#root_sscm");
    // console.log('app', app)
}
// 单独开发环境
// window.__POWERED_BY_QIANKUN__ || mount();
register();
