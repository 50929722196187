import React from "react";
import update from "immutability-helper";
import { swapArray } from "@src/utils";
import rApi from "@src/http";
import { exportReqToExcel } from "@src/utils/logic";
import { message } from "antd";

class Parent extends React.Component {
    onChangeCheckbox = (checked, index) => {
        this.state.columns[index].isNoDisplay = !checked;
        this.setState({ columns: this.state.columns });
    };

    moveColumn = (dragIndex, hoverIndex, columnKey, callback) => {
        // console.log('dragIndex', dragIndex, hoverIndex, columnKey)
        const columns = this.state[columnKey];
        const dragCard = columns[dragIndex];
        this.setState(
            update(this.state, {
                [columnKey]: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard]
                    ]
                }
            }),
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    /**
     * 搜索条件发生改变
     * @memberOf Parent
     */
    searchCriteria = (callback, noLoading) => {
        if (this.tableView) {
            this.tableView.onSearch(callback, noLoading);
        }
    };

    /**
     * 搜索条件发生改变
     * @memberOf Parent
     */
    refresh = (callback, noLoading) => {
        if (this.tableView) {
            this.tableView.refresh(callback, noLoading);
        }
    };

    // 更新 dataSource 数据
    updateDataTableSource = (dataSource, callback) => {
        if (this.tableView) {
            this.tableView.updateDataTableSource(dataSource, callback);
        }
    };

    upd = (dataSource, callback) => {
        if (this.tableView) {
            this.tableView.updateDataTableSource(dataSource, callback);
            if (!this.state._changeListForchRender) {
                this.setState({ _changeListForchRender: "ttt" });
            }
        }
    };

    // 获取 dataSource 数据
    getTableSource = () => {
        if (this.tableView) {
            return this.tableView.getTableSource();
        }
        return [];
    };

    gd = () => {
        if (this.tableView) {
            return this.tableView.getTableSource();
        }
        return [];
    };

    onDeletes = () => {
        if (this.tableView) {
            this.tableView.onDeletes();
        }
    };

    cancelUpdateColumns = (columnKey, value) => {
        this.setState({
            [columnKey]: value
        });
    };

    // 表头是否显示
    onChangeShowColumn = (checked, index, columnKey, callback) => {
        let columns = this.state[columnKey];
        this.setState(
            update(this.state, {
                [columnKey]: {
                    [index]: {
                        $set: Object.assign({}, columns[index], { isNoDisplay: !checked })
                    }
                }
            }),
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    // 修改表头
    onChangeHeader = ({ value, index, columnKey, type }, callback) => {
        let columns = this.state[columnKey];
        this.setState(
            update(this.state, {
                [columnKey]: {
                    [index]: {
                        $set: Object.assign({}, columns[index], { [type]: value })
                    }
                }
            }),
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    /**
     * 更新表头 col=>请求的表头 this.state[columnKey]=>本地表头
     *
     * @memberof Parent
     */
    updateColumn = (col, columnKey = "columns") => {
        let localColumns = this.state[columnKey]; // 本地表头
        let cols = []; // 需要更新的数据
        let notExitsCol = []; // 新增的本地表头但是远程数据库不存在
        //   console.log(columnKey, this.state[columnKey], col)
        if (col && col.length) {
            localColumns.forEach((item) => {
                if (!col.some((d, index) => d.key === item.key)) {
                    let findCol = localColumns.find((r) => r.key === item.key);
                    notExitsCol.push(findCol);
                }
            });
            // if(col.length === localColumns.length) {
            col.forEach((item) => {
                if (localColumns.some((d, index) => d.key === item.key)) {
                    let findCol = localColumns.find((r) => r.key === item.key);
                    cols.push({
                        ...findCol,
                        id: item.id,
                        isNoDisplay: item.isNoDisplay ? 1 : 0,
                        title: item.title || findCol.title,
                        width: item.width || findCol.width
                    });
                }
            });
            // }
            let allData = [...cols, ...notExitsCol];
            let localLastColumns = localColumns[localColumns.length - 1]; // 本地最后一列
            let lastIndex = allData.findIndex((item) => item.key === localLastColumns.key); // 最后一列在新数组的index
            if (lastIndex !== allData.length - 1) {
                allData = swapArray(allData, lastIndex, allData.length - 1);
            }
            //   console.log('allData', allData)
            this.setState({
                [columnKey]: allData
            });
        }
        // console.log('更新后的数据', cols)
        // console.log('updateColumn', col, columnKey, this.state[columnKey])
    };

    _export = async (makeRequest, dic) => {
        try {
            if (this.tableView && this.tableView.headerSitting) {
                const cols = this.tableView.headerSitting.getColumns();
                // console.log('getHeaderSetting', this.tableView, cols)
                const headers = !!dic
                    ? cols.map((col) => ({
                          name: col.title,
                          key: !!dic[col.title] ? dic[col.title] : col.key,
                          isNoDisplay: col.isNoDisplay ? 1 : 0,
                          isNoExport: col.isNoExport ? 1 : 0
                      }))
                    : cols.map((col) => ({
                          name: col.title,
                          key: col.key,
                          isNoDisplay: col.isNoDisplay ? 1 : 0,
                          isNoExport: col.isNoExport ? 1 : 0
                      }));
                const { method, params } = makeRequest(headers);
                const res = await rApi[method](params);
                await exportReqToExcel(res);
            }
        } catch (error) {
            console.log(error);
            message.error(error.msg || "操作失败");
        }
    };
}

export default Parent;
