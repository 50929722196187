export const id = {
    id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_DETAILS_NEWS",
    method: "POST",
    name: "应付对账明细",
    type: "menu_hide",
    sort: 2
    // apiName: 'accountPayableList',
    // url: `order/sendCar/list`
};

export const children = {
    SAVE: {
        id: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_DETAILS_SAVE",
        method: "POST",
        name: "保存",
        type: "view"
    }
};
