export default function LogisticsControl(params) {
    const BMapGL = params.BMapGL;

    function LogisticsDetail(offset) {
        // 设置默认停靠位置和偏移量
        this.defaultAnchor = BMAP_ANCHOR_TOP_LEFT;
        this.defaultOffset = offset;

        //  mouseover 实现 css hover 悬停时 dom 不消失
        this.hover = false;
    }
    LogisticsDetail.prototype = new BMapGL.Control();

    LogisticsDetail.prototype.initialize = function (init) {
        this.initialize = init;
    };

    return LogisticsDetail;
}
