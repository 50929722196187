import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

const moduleName = "wms/profitLossManage/";

export default class {
    /**
     * 获取库存有效期预警列表
     *
     * @param {any} params
     * @returns
     *
     * @memberOf earlyWarningApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    //日志
    exportListValidityWarning(params) {
        let url = "wms/export/validityWarning/listValidityWarning";
        return this.POST(url, deleteNull(params));
    }
}
