const prevUrl = "finance/receivableReconciliation";

export const id = {
    id: "SETTLEMENT_ACCOUNT_RECEIVABLE",
    method: "POST",
    name: "运输应收结算",
    type: "menu",
    sort: 1
    // apiName: 'accountReceivableList',
    // url: `finance/receivableSettlement/page`
};
export const children = {
    EXPORT_DN: {
        id: "SETTLEMENT_ACCOUNT_RECEIVABLE_EXPORT_DN",
        method: "GET",
        name: "导出DN单",
        type: "menu"
    }
};
