const moduleName = "order/";
import getChildPowerJson from "../../../utils/getChildPowerJson";
import newPage from "../allocate_edit/power_hide";
export const id = {
    id: "BUSINESS_MANAGEMENT_ALLOCATE_LIST",
    method: "POST",
    name: "运单管理",
    type: "menu",
    apiName: "getAllocateList",
    url: `${moduleName}stowage/list`,
    sort: 2
};

const nPage = getChildPowerJson.toJson(newPage);

export const children = {
    [nPage.id]: nPage,
    GET_COUNT: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_GET_COUNT",
        name: "获取数量",
        type: "view",
        apiName: "stowageGetCount",
        method: "POST",
        url: `${moduleName}stowage/getCount`
    },
    EDIT_DATA: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_EDIT_DATA",
        name: "编辑",
        type: "view"
    },
    DEL_DATA: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_DELETE_LIST",
        name: "删除",
        type: "view",
        apiName: "deleteAllocateList",
        method: "POST",
        url: `${moduleName}stowage/delete`
    },
    LOOK_MORE: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_LOOK_MORE",
        name: "查看",
        type: "view"
    },
    SIGN_FOR: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_SIGN_FOR",
        name: "签收",
        type: "view",
        apiName: "orderSignFor",
        method: "POST",
        url: `${moduleName}stowage/sign`
    },
    UN_SIGN_FOR: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_UN_SIGN_FOR",
        name: "取消签收",
        type: "view",
        apiName: "orderUnSignFor",
        method: "POST",
        url: `${moduleName}stowage/cancelSign`
    },
    SHIPMENT: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_SHIPMENT",
        name: "运单发运",
        type: "view",
        apiName: "orderShipment",
        method: "POST",
        url: `${moduleName}stowage/shipment`
    },
    BIND_NB: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_BIND_NB",
        name: "NB绑定配置",
        type: "view"
    },
    UPLOAD_DEL: {
        id: "SEND_CAR_MANAGEMENT_UPLOAD_DEL",
        name: "回单删除",
        type: "view"
    },
    UPLOAD_CONFIRM: {
        id: "SEND_CAR_MANAGEMENT_UPLOAD_CONFIRM",
        name: "回单确认",
        type: "view"
    },
    UPLOAD_EDIT: {
        id: "SEND_CAR_MANAGEMENT_UPLOAD_EDIT",
        name: "回单编辑",
        type: "view"
    },
    UPLOAD_LOOK: {
        id: "SEND_CAR_MANAGEMENT_UPLOAD_LOOK",
        name: "回单编辑",
        type: "view"
    },
    CONFIRM: {
        id: "SEND_CAR_MANAGEMENT_CONFIRM",
        name: "确认",
        type: "view",
        apiName: "orderTransportConfirm",
        method: "POST",
        url: `${moduleName}stowage/confirm`
    },
    EXPORT: {
        id: "SEND_CAR_MANAGEMENT_EXPORT",
        name: "导出",
        type: "view",
        apiName: "stowageExport",
        method: "POSTFILE",
        url: `${moduleName}stowage/export`
    },
    CHOOSE_SIGN: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_CHOOSE_SIGN",
        name: "签收",
        type: "view"
    },
    STOWAGE_ADJUSTMENT: {
        id: "BUSINESS_MANAGEMENT_STOWAGE_ADJUSTMENT",
        name: "配载调整",
        type: "view"
    },
    CONFIRMPAPERRECEIPT: {
        id: "BUSINESS_MANAGEMENT_STOWAGE_CONFIRMPAPERRECEIPT",
        name: "纸质回单确认",
        type: "view"
    },
    INSURANCE: {
        id: "BUSINESS_MANAGEMENT_STOWAGE_INSURANCE",
        name: "运输投保",
        type: "view"
    },
    REALLOCATE: {
        id: "BUSINESS_MANAGEMENT_STOWAGE_REALLOCATE",
        name: "重新分配",
        type: "view"
    }
};
