const moduleName = "finance/calculateCostBill";
export const id = {
    id: "BASIC_DATA_DOCMENT_DEF",
    name: "计费单据定义",
    type: "menu",
    apiName: "getDocmentDefList",
    url: `${moduleName}/list`
};
// DocmentDef

export const children = {
    GET_LIST: {
        id: "BASIC_DATA_DOCMENT_DEF_GET_LIST",
        apiName: "getDocmentDefOne",
        method: "GET",
        name: "获取列表",
        type: "view",
        url: `${moduleName}`
    },
    ADD_DATA: {
        id: "BASIC_DATA_DOCMENT_DEF_ADD_DATA",
        apiName: "addDocmentDef",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}/save`
    },
    DEL_DATA: {
        id: "BASIC_DATA_DOCMENT_DEF_CODE",
        apiName: "delDocmentDef",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}/delete`
    },
    EDIT_DATA: {
        id: "BASIC_DATA_DOCMENT_DEF_EDIT_DATA",
        apiName: "editDocmentDef",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}/save`
    }
    // LOOK_MORE: {
    //     id: 'BASIC_DATA_DOCMENT_DEF_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
