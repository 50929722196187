import React, { Component } from "react";
import { Cascader, message, Input, Icon } from "antd";
import PropTypes from "prop-types";
import { cloneObject } from "@src/utils";
// // import addressData from '@src/libs/address'
import rApi from "@src/http";
import "./cascader.less";

const getAddressData = () => {
    return import("../../libs/address.json");
};

export default class CascaderAddress extends Component {
    /**
     * 父组件传值接口defaultValue
     *
     * @memberof CascaderAddress
     */
    static propTypes = {
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
        selectGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    };

    static defaultProps = {
        selectGrade: 4 // 1 加载城市, 2加载区县, 3加载街道, 4详细地址
    };

    state = {
        defaultValue: [],
        options: [],
        data: [],
        extra: "",
        show: true,
        isChange: false,
        abroadAddressList: []
    };

    constructor(props) {
        super(props);
        const { defaultValue, address } = props;
        // console.log('constructor defaultValue', address)
        if (props.getThis) {
            props.getThis(this);
        }
        if (address) {
            // this.state.defaultValue = cloneObject(address)
            // this.initData()
            if (address.pro) {
                let pro = address.pro;
                if (!pro.id && pro.code) {
                    pro.id = pro.code;
                    pro.value = pro.code;
                }
                pro.label = pro.name;
                this.state.defaultValue.push({ ...pro });
                // this.loadData(this.state.defaultValue)
            }
            if (address.city) {
                let city = address.city;
                if (!city.id && city.code) {
                    city.id = city.code;
                    city.value = city.code;
                }
                city.label = city.name;
                this.state.defaultValue.push({ ...city });
                // this.loadData(this.state.defaultValue)
            }
            if (address.dist) {
                let dist = address.dist;
                if (!dist.id && dist.code) {
                    dist.id = dist.code;
                    dist.value = dist.code;
                }
                dist.label = dist.name;
                this.state.defaultValue.push({ ...dist });
                // this.loadData(this.state.defaultValue)
            }
            if (address.street) {
                let street = address.street;
                if (!street.id && street.code) {
                    street.id = street.code;
                    street.value = street.code;
                }
                street.label = street.name;
                this.state.defaultValue.push({ ...street });
            }

            if (address.extra) {
                // this.state.extra = address.extra
                // this.setState({extra: address.extra})
                this.state.extra = address.extra;
            }
            this.state.data = this.state.defaultValue;
            // console.log('this.state.defaultValue', this.state.defaultValue)
        }
    }

    setAddress = (address) => {
        if (address) {
            // this.state.defaultValue = cloneObject(address)
            // this.initData()
            const defaultValue = [];
            let extra = "";
            if (address.pro) {
                let pro = address.pro;
                if (!pro.id && pro.code) {
                    pro.id = pro.code;
                    pro.value = pro.code;
                }
                pro.label = pro.name;
                defaultValue.push({ ...pro });
            }
            if (address.city) {
                let city = address.city;
                if (!city.id && city.code) {
                    city.id = city.code;
                    city.value = city.code;
                }
                city.label = city.name;
                defaultValue.push({ ...city });
            }
            if (address.dist) {
                let dist = address.dist;
                if (!dist.id && dist.code) {
                    dist.id = dist.code;
                    dist.value = dist.code;
                }
                dist.label = dist.name;
                defaultValue.push({ ...dist });
            }
            if (address.street) {
                let street = address.street;
                if (!street.id && street.code) {
                    street.id = street.code;
                    street.value = street.code;
                }
                street.label = street.name;
                defaultValue.push({ ...street });
            }

            if (address.extra) {
                // this.setState({extra: address.extra})
                extra = address.extra;
            }
            // this.state.data = defaultValue
            this.setState({
                data: defaultValue,
                extra
            });
            // console.log('this.state.defaultValue', defaultValue)
        } else {
            this.setState({
                data: [],
                extra: ""
            });
        }
    };

    componentDidMount() {
        this.initData();
        this.abroadAddress();
    }

    onChange = (value, selectedOptions) => {
        const { onChangeProvince, handleChangeAddress, getValueForChild } = this.props;
        selectedOptions = selectedOptions.map((item) => {
            if (item.value === "国内" || item.value === "国外") {
                this.setState({
                    isAbroad: item.value === "国外"
                });
                return {
                    ...item,
                    id: "del",
                    name: "国内外"
                };
            }
            return {
                ...item,
                id: item.value,
                name: item.label
            };
        });

        this.setState(
            {
                data: selectedOptions.filter((item) => item.id != "del"),
                isChange: true
            },
            () => {
                if (getValueForChild) {
                    let data = this.getValue();
                    getValueForChild(data);
                }
            }
        );
        if (handleChangeAddress) {
            let arr = selectedOptions.filter((item) => item.name !== "国内外").map((item) => item.label);
            // arr.filter(item=> item !== '国外')
            // console.log('selectedOptions', selectedOptions)
            handleChangeAddress(arr, selectedOptions);
        }

        if (onChangeProvince && value && value.length > 1) {
            let name = "";
            let data = value[1];
            if (selectedOptions && selectedOptions[0]) {
                let options = selectedOptions[0];
                if (options.children && options.children.length) {
                    let v = options.children.map((item) => item.value === data);
                    name = v && v.label;
                }
            }

            let dict = {
                id: data,
                name: name,
                label: name
            };

            onChangeProvince(dict);
        }
    };

    initData = () => {
        const changeValue = (list) => {
            if (!list || list.length < 0) {
                return null;
            }
            return list.map((item) => {
                return {
                    ...item,
                    children: changeValue(item.children),
                    value: item.label,
                    code: item.value
                };
            });
        };
        this.getDataAnsy = getAddressData();
        this.setState({
            loading: true
        });
        this.getDataAnsy
            .then((res) => {
                if (!this.state.unMount) {
                    this.setState({
                        loading: false,
                        options: [
                            { label: "全国", value: "全国", code: "全国" },
                            ...res.data.map((item) => ({
                                ...item,
                                children: changeValue(item.children),
                                value: item.label,
                                code: item.value
                            }))
                        ]
                    });
                }
            })
            .catch((e) => {
                if (!this.state.unMount) {
                    message.error("load address data error");
                }
                this.setState({
                    loading: false
                });
            })
            .finally(() => {
                if (!this.state.unMount) {
                    this.setState({
                        loading: false
                    });
                }
            });
    };

    loadData = (selectedOptions) => {
        // const targetOption = selectedOptions[selectedOptions.length - 1]
        // const { selectGrade } = this.props
        // // console.log('targetOption', targetOption)
        // if (selectedOptions.length === 1) {
        //     targetOption.loading = true
        //     return rApi.getCitys(targetOption).then((d) => {
        //         targetOption.loading = false
        //         targetOption.children = d.map((item, index) => {
        //             if (selectGrade === 1 || selectGrade === 'city') {
        //                 return {
        //                     ...item,
        //                     label: item.name,
        //                     value: item.id
        //                 }
        //             }
        //             return {
        //                 ...item,
        //                 isLeaf: false,
        //                 label: item.name,
        //                 value: item.id
        //             }
        //         })
        //         this.setState({
        //             options: [...this.state.options],
        //         })
        //     }).catch(e => {
        //         message.error('加载城市失败！')
        //     })
        // } else if (selectedOptions.length === 2) {
        //     targetOption.loading = true
        //     rApi.getCountys(targetOption).then(d => {
        //         targetOption.loading = false
        //         targetOption.children = d.map((item, index) => {
        //             if (selectGrade === 2 || selectGrade === 'county') {
        //                 return {
        //                     ...item,
        //                     label: item.name,
        //                     value: item.id
        //                 }
        //             }
        //             return {
        //                 ...item,
        //                 isLeaf: false,
        //                 label: item.name,
        //                 value: item.id
        //             }
        //         })
        //         this.setState({
        //             options: [...this.state.options],
        //         })
        //     }).catch(e => {
        //         message.error('加载区县失败！')
        //     })
        // } else if (selectedOptions.length === 3) {
        //     targetOption.loading = true
        //     rApi.getStreets(targetOption).then(d => {
        //         targetOption.loading = false;
        //         targetOption.children = d.map((item, index) => {
        //             return {
        //                 ...item,
        //                 label: item.name,
        //                 value: item.id
        //             }
        //         })
        //         this.setState({
        //             options: [...this.state.options],
        //         })
        //     }).catch(e => {
        //         message.error('加载街道失败！')
        //     })
        // }
    };

    getValue = () => {
        let { isChange, extra, data } = this.state;
        const { address } = this.props;
        let province = cloneObject(data[0]);
        let city = cloneObject(data[1]);
        let county = cloneObject(data[2]);
        let street = cloneObject(data[3]);
        // console.log('province', province, data)
        if (province) province.id = null;
        if (city) city.id = null;
        if (county) county.id = null;
        if (street) street.id = null;
        if (isChange) {
            if (province) {
                province.code = province.id;
                delete province.children;
            }
            if (city) {
                city.code = city.id;
                delete city.children;
            }
            if (county) {
                county.code = county.id;
                delete county.children;
            }
            if (street) {
                street.code = street.id;
                delete street.children;
            }
            if (isChange && extra) {
                let d = {
                    pro: province,
                    city: city,
                    dist: county,
                    street: street,
                    extra:
                        (province && province.name) ||
                        (city && city.name) ||
                        (county && county.name) ||
                        (street && street.name)
                            ? extra
                            : null
                };
                for (let key in d) {
                    if (!d[key]) {
                        delete d[key];
                    }
                }
                return d;
            }
            let d = {
                pro: province,
                city: city,
                dist: county,
                street: street
                //  extra: (province && province.name) || (city && city.name) || (county && county.name) || (street && street.name) ? extra : null
            };
            for (let key in d) {
                if (!d[key]) {
                    delete d[key];
                }
            }
            return d;
        } else {
            return Object.assign({}, address, { extra: extra });
        }
    };

    defaultValueToString = (s) => {
        s = s || [];
        return s.map((item) => item.name).join("/");
    };

    filter = (inputValue, path) => {
        return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };

    abroadAddress = () => {
        rApi.getAbroadArea(1).then((res) => {
            this.setState({
                abroadAddressList: res.data
            });
        });
    };

    render() {
        const { selectGrade, getPopupContainer, mapIcon, disabled } = this.props;
        let { defaultValue, options, data, loading, abroadAddressList } = this.state;
        if (!this.state.show) {
            return false;
        }
        let def = {
            defaultValue:
                typeof defaultValue === "object"
                    ? defaultValue
                    : typeof defaultValue === "string"
                    ? JSON.parse(defaultValue)
                    : null
        };
        if (!defaultValue || defaultValue.length < 1 || !def.defaultValue) {
            def = {};
        }

        let getTContainer = {};
        if (getPopupContainer) {
            getTContainer.getPopupContainer = getPopupContainer;
        } else {
            getTContainer.getPopupContainer = () => document.querySelector("#scroll-view");
        }
        // console.log('selectGrade', options, selectGrade)
        // def = {
        //     defaultValue: ['xxx', 'xxx']
        // }
        // console.log('loading', loading)
        const optionsData = [
            {
                label: "国内",
                value: "国内",
                children: options
            },
            {
                label: "国外",
                value: "国外",
                children: abroadAddressList
            }
        ];
        // console.log('data', data, options)
        return (
            <div className="flex">
                <div className="flex1">
                    <Cascader
                        // {...def}
                        // loading={!!loading}
                        // ref="cascader"
                        {...getTContainer}
                        title={this.props.title}
                        changeOnSelect
                        showSearch={this.filter}
                        size={window._size}
                        style={{ minWidth: "200px" }}
                        options={optionsData}
                        onChange={this.onChange}
                        placeholder={""}
                        allowClear
                        // loadData={this.loadData}
                        popupClassName="cas-addr-wrapper"
                        disabled={disabled}
                        displayRender={(label /* , selectedOptions */) => {
                            if (!label.length) {
                                return (
                                    (data &&
                                        data
                                            .filter((item) => item !== "国内" && item !== "国外")
                                            .map((item) => item.name)
                                            .join(" / ")) ||
                                    ""
                                );
                            }
                            return (
                                (label && label.filter((item) => item !== "国内" && item !== "国外").join(" / ")) || ""
                            );
                        }}
                    >
                        {
                            // !isChange && defaultValue && defaultValue.length > 0 ?
                            // <span
                            //     style={{width: '100%'}}
                            //     className={'ant-cascader-picker'}
                            // >
                            //     <span className={`${'ant-cascader'}-picker-label`}>
                            //         {
                            //             this.defaultValueToString(defaultValue)
                            //         }
                            //     </span>
                            //     <input type="text" className={`ant-input ant-cascader-input ${window._size === "small" ? 'ant-input-sm' : ''}`} />
                            //     <Icon type="down" className="anticon anticon-down ant-cascader-picker-arrow" />
                            // </span> : null
                        }
                    </Cascader>
                </div>
                <div style={{ width: 140 }}>
                    {selectGrade === 4 ? (
                        <Input
                            style={{ width: "calc(100% - 5px)", marginLeft: 5 }}
                            title={this.state.extra}
                            onChange={(e) => this.setState({ extra: e.target.value })}
                            value={this.state.extra}
                            placeholder="输入详细地址"
                            size={window._size}
                            disabled={this.props.disabled}
                        />
                    ) : null}
                </div>
                {mapIcon}
            </div>
        );
    }
}

// export default SourceDemo;
