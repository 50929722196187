const moduleName = "order/";
export const id = {
    id: "SEND_CAR_MANAGEMENT",
    method: "POST",
    name: "运单管理",
    type: "menu",
    apiName: "getSendCarList",
    // url: `${moduleName}stowage/list`
    url: `${moduleName}stowage/list`,
    sort: 102
};

// let moduleName = ''
export const children = {
    LOOK_STOWAGE_LIST: {
        id: "SEND_CAR_MANAGEMENT_LOOK_STOWAGE_LIST",
        //apiName: 'sendcarTrackEdit',
        //method: 'POST',
        name: "查看配载单",
        type: "view"
        // url: `${moduleName}stowage/editTrack`
    },
    TRACK_CAR: {
        id: "SEND_CAR_MANAGEMENT_TRACK_CAR",
        apiName: "sendcarTrackEdit",
        method: "POST",
        name: "追踪",
        type: "view",
        url: `${moduleName}stowage/editTrack`
    },
    TRACK_EDIT: {
        id: "SEND_CAR_MANAGEMENT_TRACK_EDIT",
        name: "追踪编辑",
        type: "view"
    },
    SIGN_CAR: {
        id: "SEND_CAR_MANAGEMENT_SIGN_CAR",
        apiName: "singCar",
        method: "POST",
        name: "签收",
        type: "view",
        url: `${moduleName}stowage/sign`
    },
    ASSESSMENT: {
        id: "SEND_CAR_MANAGEMENT_ASSESSMENT",
        name: "违约考核",
        type: "view"
    },
    EVALUATION: {
        id: "SEND_CAR_MANAGEMENT_EVALUATION",
        name: "签收评价",
        type: "view"
    },
    RETURN_FILE: {
        id: "SEND_CAR_MANAGEMENT_RETURN_FILE",
        apiName: "uploadReturnFile",
        method: "POST",
        name: "回单上传",
        type: "view",
        url: `${moduleName}stowage/upload`
    },
    UPLOAD_EDIT: {
        id: "SEND_CAR_MANAGEMENT_UPLOAD_EDIT",
        //apiName: 'uploadReturnFile',
        // method: 'POST',
        name: "回单编辑",
        type: "view"
        // url: `${moduleName}stowage/upload`
    },
    UPLOAD_DEL: {
        id: "SEND_CAR_MANAGEMENT_UPLOAD_DEL",
        //apiName: 'uploadReturnFile',
        // method: 'POST',
        name: "回单删除",
        type: "view"
        // url: `${moduleName}stowage/upload`
    },
    UPLOAD_CONFIRM: {
        id: "SEND_CAR_MANAGEMENT_UPLOAD_CONFIRM",
        //apiName: 'uploadReturnFile',
        //method: 'POST',
        name: "回单确认",
        type: "view"
        // url: `${moduleName}stowage/upload`
    },
    CANCEL_CAR: {
        id: "SEND_CAR_MANAGEMENT_CANCEL_CAR",
        apiName: "cancelSign",
        method: "GET",
        name: "取消签收",
        type: "view",
        url: `${moduleName}stowage/cancelSign`
    },
    EXPORT: {
        id: "SEND_CAR_MANAGEMENT_EXPORT",
        apiName: "sendcarExport",
        method: "GETFILE",
        name: "导出面单",
        type: "view",
        url: `${moduleName}stowage/export/logistics`
    },
    UNBIND_NB: {
        id: "SEND_CAR_MANAGEMENT_UNBIND_NB",
        name: "强制解绑NB",
        type: "view"
    }
};
