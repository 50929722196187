import load from "@src/layout/modules_load";

const HOME = load(() => import("./home.jsx"));

export default {
    HOME: {
        component: HOME,
        name: "首页"
    }
};
