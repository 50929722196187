const moduleName = "order/";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const getChildPowerJson = require("../../../utils/getChildPowerJson");
// const newPage = require('../stowage_maintenance_edit/power_hide')
export const id = {
    id: "STOWAGE_CENTER",
    method: "POST",
    name: "配载中心",
    type: "menu",
    apiName: "getStowageCenterCanBeLoadedList",
    url: `${moduleName}order/getTransportRoute`,
    sort: 3
};

// let moduleName = ''
// const nPage = getChildPowerJson.toJson(newPage)
export const children = {
    // [nPage.id]: nPage,
    CONFIRM: {
        id: "STOWAGE_CENTER_CONFIRM",
        name: "确认配载",
        type: "view",
        url: `${moduleName}stowage/save`,
        apiName: "stowageCenterConfirm"
    },
    GET_DISPATCH_INFO: {
        id: "STOWAGE_CENTER_GET_DISPATCH_INFO",
        name: "获取调度数据",
        type: "view",
        url: `${moduleName}order/dispatchDetail`,
        apiName: "getDispatchDetail"
    },
    DELIVERY_PLACE: {
        id: "STOWAGE_CENTER_DELIVERY_PLACE",
        name: "提货地",
        type: "view"
    }
    // EDIT_DATA: {
    //     id: 'STOWAGE_CENTER_EDIT_DATA',
    //     name: '录入',
    //     type: 'view'
    // },
    // LOOK_MORE: {
    //     id: 'STOWAGE_CENTER_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // },
    // BATCH_EDIT: {
    //     id: 'STOWAGE_CENTER_BATCH_EDIT',
    //     //apiName: 'deleteAllocateList',
    //     //method: 'POST',
    //     name: '批量补录',
    //     type: 'view',
    //     //url: `${moduleName}stowage/delete`
    // }

    // GET_OPERATORLIST: {
    //     id: 'STOWAGE_CENTER_GET_OPERATORLIST',
    //     apiName: 'getFilterOperatorList',
    //     method: 'GET',
    //     name: '获取筛选条件创建人列表',
    //     type: 'view',
    //     url: `${moduleName}stowageMaintain/getOperatorList`
    // }
};
