import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { toString } from "./utils";

// shouldComponentUpdate(newProps, newState) {
//   // console.log('shouldComponentUpdate', )
//   const oldProps = this.props;
//   const oldState = this.state;
//   // if (newProps.rowIndex === 2)
//   // console.log('oldProps.rowData', this.rowData.isEdit, newProps.rowData.isEdit)
//   return (!shallowEqual(oldProps, newProps) || !shallowEqual(oldState, newState)) || (!shallowEqual(this.rowData, newProps.rowData))
// }

/**
 * Cell component for BaseTable
 */
const TableCell = ({ className, cellData, column, columnIndex, rowData, rowIndex }) => {
    const cell = useMemo(
        () => <div className={className}>{React.isValidElement(cellData) ? cellData : toString(cellData)}</div>,
        [className, cellData, column]
    );
    return cell;
};

TableCell.propTypes = {
    className: PropTypes.string,
    cellData: PropTypes.any,
    column: PropTypes.object,
    columnIndex: PropTypes.number,
    rowData: PropTypes.object,
    rowIndex: PropTypes.number
};

export default TableCell;
