import React, { Component } from "react";
import Select from "./select";
// import Select2 from './select_2'

export default class RemoteSelect extends Component {
    state = {
        value: {}
    };

    constructor(props) {
        super(props);
        const def = props.defaultValue || props.def;
        if (props.def && Object.keys(props.def).length > 0) {
            this.state.value = props.def;
            if (!props.noInitChange) this.onChange(props.def);
        } else {
            // this.onChange(null)
        }
    }

    onChange = (value) => {
        // console.log('onChange', value)
        if (this.props.onChangeValue) {
            this.props.onChangeValue(value);
        }
        const onChange = this.props.onChange;
        if (onChange) {
            let obj = value ? value : null;
            onChange(obj);
        }
    };

    render() {
        const { defaultValue, def, ...rest } = this.props;

        return (
            <Select
                {...(defaultValue ? { defaultValue } : def ? { def } : {})}
                {...rest}
                onChangeValue={this.onChange}
            />
        );
    }
}

export class DataSelect extends Component {
    state = {
        value: {}
    };

    constructor(props) {
        super(props);
        if (props.def && Object.keys(props.def).length > 0) {
            this.state.value = props.def;
            this.onChange(props.def);
        } else {
            // this.onChange(null)
        }
    }

    onChange = (value) => {
        // console.log('onChange', value)
        if (this.props.onChangeValue) {
            this.props.onChangeValue(value);
        }
        const onChange = this.props.onChange;
        if (onChange) {
            let obj = value ? value : null;
            onChange(obj);
        }
    };

    render() {
        return <Select {...this.props} onChangeValue={this.onChange} />;
    }
}
