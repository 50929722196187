import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class WarehouseManegementShipmentDemandApi {
    /**
     * 获取出货需求数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf WarehouseManegementShipmentDemandApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        return this.POST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url;
        return this.POST(url, params);
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.POST(url, params);
    }

    // [children.EXPORT.apiName](params) {
    //     let url = children.EXPORT.url
    //     return this[children.EXPORT.method](url, params)
    // }

    getBatchNumberList(params) {
        //查询批次号
        let url = "wms/shipmentDemand/getBatchNumberList";
        return this.POST(url, params);
    }

    getClientsWhoHasMaterial(params) {
        // 获取仓库客户列表
        // let url = `wms/shipmentDemand/getClientList/${params.warehouseId}`
        let url = `wms/shipmentDemand/getClientNamesBySave`;
        return this.POST(url, params);
    }

    // 获取物料
    getMaterialNumber(params) {
        let url = `wms/shipmentDemand/getMaterialNumber`;
        return this.POST(url, params);
    }

    // 获取品质列表
    getQualityByShipmentDemand(params) {
        let url = `wms/shipmentDemand/getQuality`;
        return this.POST(url, params);
    }

    // 获取批次列表
    getBatchNumberByShipmentDemand(params) {
        let url = `wms/shipmentDemand/getBatchNumber`;
        return this.POST(url, params);
    }

    // 获取供应商列表
    getSupplierByShipmentDemand(params) {
        let url = `wms/shipmentDemand/getSupplier`;
        return this.POST(url, params);
    }

    // 获取包装规格列表
    getPackageTypeNameByShipmentDemand(params) {
        let url = `wms/shipmentDemand/getPackageTypeName`;
        return this.POST(url, params);
    }

    // 获取单位列表
    getUnitNameByShipmentDemand(params) {
        let url = `wms/shipmentDemand/getUnitName`;
        return this.POST(url, params);
    }

    // 获取生产日期列表
    getProductionDateByShipmentDemand(params) {
        let url = `wms/shipmentDemand/getProductionDate`;
        return this.POST(url, params);
    }

    // 获取列表项目
    getShipmentDemandProject(params) {
        let url = `wms/shipmentDemand/getProject`;
        return this.GET(url, params);
    }

    // 导入出货需求
    importBatchSave(params) {
        let url = `wms/shipmentDemand/batchSave`;
        return this.POST(url, params);
    }

    // 导出出货需求模板
    exportDemandTemp(params) {
        let url = `wms/shipmentDemand/exportDemandTemp`;
        return this.GETFILE(url, params);
    }

    // batchSaveShipmentItem
    batchSaveShipmentItem(params) {
        let url = `wms/shipmentDemand/batchSaveShipmentItem`;
        return this.POST(url, params);
    }

    // batchSaveShipmentItem
}
