import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

const prevUrl = "finance/costSpecialExpenseMaintain/";

export default class AbnormalApi {
    /**
     * 获取数据
     * @param {any} params
     * @returns
     * @memberOf WarehouseManegementReceiptDemandApi
     */

    [children.SAVE_AND_SUBMIT.apiName](params) {
        let url = `${prevUrl}saveAndSubmit/${params.flowDefineCode}`;
        return this[children.SAVE_AND_SUBMIT.method](url, params);
    }

    [children.SUBMIT.apiName](params) {
        let url = `${prevUrl}submit/${params.id}`;
        return this[children.SUBMIT.method](url, params);
    }

    [children.UPDATE.apiName](params) {
        let url = `${prevUrl}update/${params.id}`;
        return this[children.UPDATE.method](url, params);
    }

    [children.REJECT_TASK.apiName](params) {
        let url = `${prevUrl}rejectTask/${params.serviceId}`;
        return this[children.REJECT_TASK.method](url, params);
    }

    [children.CANCEL_SUMBIT.apiName](params) {
        let url = `${prevUrl}cancelSubmit/${params.id}`;
        return this[children.CANCEL_SUMBIT.method](url);
    }

    [children.COMPLETE_TASK.apiName](params) {
        let url = `${prevUrl}completeTask/${params.id}?serviceType=${params.serviceType}`;
        return this[children.COMPLETE_TASK.method](url);
    }

    [children.CHECK_APPROVE_PERMISSION.apiName](params) {
        let url = `${prevUrl}checkApprovePermission/${params.id}`;
        return this[children.CHECK_APPROVE_PERMISSION.method](url);
    }

    [children.DELETE.apiName](params) {
        let url = `${prevUrl}delete/${params.id}`;
        return this[children.DELETE.method](url);
    }
}
