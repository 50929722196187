const moduleName = "common/packageManage/";
export const id = {
    id: "BASIC_DATA_PACKAGE_SPEC",
    name: "包规管理",
    type: "menu",
    apiName: "getPackageSpecList",
    url: `${moduleName}list`
};

export const children = {
    // GET_LIST: {
    //     id: 'BASIC_DATA_PACKAGE_SPEC_GET_LIST',
    //     apiName: 'getCarTypes',
    //     method: 'POST',
    //     name: '获取列表',
    //     type: 'view',
    //     url: `${moduleName}carType/getCarTypes`
    // },
    ADD_DATA: {
        id: "BASIC_DATA_PACKAGE_SPEC_ADD_DATA",
        apiName: "addPackageSpec",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}saveOrUpdate`
    },
    DEL_DATA: {
        id: "BASIC_DATA_PACKAGE_SPEC_CODE",
        apiName: "delPackageSpec",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}delete`
    },
    EDIT_DATA: {
        id: "BASIC_DATA_PACKAGE_SPEC_EDIT_DATA",
        apiName: "editPackageSpec",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}saveOrUpdate`
    }
    // LOOK_MORE: {
    //     id: 'BASIC_DATA_PACKAGE_SPEC_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
