import React from "react";
// import rApi from '@src/http'
import { Icon, Breadcrumb, Layout } from "antd";
import { ModulesPower } from "./power_view";
import MODULEDEFINE from "@src/views/MODULEDEFINE";
import { headerHeight } from "./config";
import { connect } from "dva";

const Home = MODULEDEFINE["HOME"].component;

const RefreshView = () => {
    return <div style={{ textAlign: "center" }}>正在刷新!</div>;
};

class Continued extends React.Component {
    state = {};
    render() {
        return (
            <div className="flex flex-vertical-center flex-level-center" style={{ minHeight: 600 }}>
                {"模块开发中..."}
            </div>
        );
    }
}

@connect(({ global }) => ({
    activeTabKey: global.activeTabKey,
    contentRefresh: global.contentRefresh
}))
class ContentView extends React.Component {
    shouldComponentUpdate(nextProps) {
        const { contentRefresh, activeTabKey, tab } = this.props;
        const shouldUpdate = contentRefresh !== nextProps.contentRefresh;
        const c1 =
            nextProps.activeTabKey !== activeTabKey && (nextProps.activeTabKey === tab.id || activeTabKey === tab.id);
        // console.log('shouldComponentUpdate', tab)
        return shouldUpdate || c1;
    }

    backToParentPage = (pageKey) => {
        // 导航返回页面
        let { dispatch } = this.props;
        dispatch({
            type: "global/openTab",
            payload: {
                key: pageKey,
                component: pageKey
            }
        });
    };

    render() {
        const { tab, tabIndex, height, activeTabKey, contentRefresh } = this.props;
        const MODULE = MODULEDEFINE[tab.component];
        let ModuleComponent = MODULE ? MODULE.component : Continued;
        let name = MODULE ? tab.title || MODULE.name : "开发中...";
        let key = tab.key;
        const navInfo = tab.navInfo || {};
        const { show, parentPageKey, parentPageTitle, style } = navInfo;
        const parentModule = MODULEDEFINE[parentPageKey];

        const minHeight = height - 20 - (show ? 28 : 0);

        let contentStyle = {
            // background: '#fff',
            minHeight
            // overflowY: 'auto'
            // position: 'relative'
        };
        if (!!MODULE && !!MODULE.contentStyle) {
            contentStyle = Object.assign(contentStyle, MODULE.contentStyle);
        }
        const breadStyle = { fontSize: "14px", margin: "3px auto 7px", height: 18, lineHeight: "18px", ...style };

        // console.log("contentRefresh", contentRefresh, activeTabKey);
        return (
            <React.Fragment>
                {show && parentPageKey && parentModule ? (
                    <Breadcrumb separator=">" style={breadStyle}>
                        <Breadcrumb.Item
                            className="global_primary_color"
                            title={parentPageTitle || parentModule.name || "无"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.backToParentPage(parentPageKey);
                            }}
                        >
                            {parentPageTitle || parentModule.name || "无"}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{name}</Breadcrumb.Item>
                    </Breadcrumb>
                ) : null}
                <Layout.Content style={contentStyle}>
                    <ModulesPower info={MODULE && MODULE.id ? MODULE.id : {}} minHeight={minHeight}>
                        {contentRefresh && contentRefresh[key] ? (
                            <RefreshView />
                        ) : (
                            <ModuleComponent
                                minHeight={minHeight}
                                index={tabIndex}
                                mykey={key}
                                activeKey={activeTabKey}
                            />
                        )}
                    </ModulesPower>
                </Layout.Content>
            </React.Fragment>
        );
    }
}

@connect(({ global }) => ({
    openedTabs: global.openedTabs,
    activeTabKey: global.activeTabKey
}))
export default class Content extends React.Component {
    state = {
        height: 0
    };

    constructor(props) {
        super(props);
        const win = window;
        this.state.height = win.innerHeight - headerHeight;
    }

    onChange = (key) => {
        this.props.menuClick({ key });
    };

    componentDidMount() {
        window.addEventListener("resize", this.onWindowResize);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
    }

    onWindowResize = () => {
        this.setState({
            height: window.innerHeight - headerHeight
        });
    };

    tabBarExtraContent = () => {
        return <Icon type="double-right" />;
    };

    render() {
        let { collapsed, openedTabs, activeTabKey } = this.props;
        let { height } = this.state;
        const list = openedTabs;
        // console.log("render", openedTabs, activeTabKey);
        return (
            <React.Fragment>
                <div
                    style={{
                        display: activeTabKey === "HOME" ? "block" : "none",
                        overflowY: "auto"
                    }}
                >
                    <Home minHeight={height - 15} index={"HOME"} mykey={"HOME"} activeKey={activeTabKey} />
                </div>
                {list.map((tab, index) => {
                    const MODULE = MODULEDEFINE[tab.component];
                    const stl = {};
                    if (!!MODULE && MODULE.style) {
                        stl.style = Object.assign(stl, MODULE.style);
                    }
                    const style = stl.style || {};
                    if (window.__POWERED_BY_QIANKUN__) {
                        style.display = tab.key === activeTabKey ? "block" : "none";
                    }
                    return (
                        <div
                            key={tab.key}
                            className={`root_con ${tab.key === activeTabKey ? "active" : ""}`}
                            style={style}
                        >
                            <div className="con_scroll">
                                <ContentView tab={tab} tabIndex={index} height={height} collapsed={collapsed} />
                            </div>
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }
}
