const m = "order/sendCarAbnormal";
export const id = {
    id: "BUSINESS_MANAGEMENT_SENDCAR_ABNORMAL",
    method: "POST",
    name: "运单异常",
    type: "menu",
    apiName: "getSendcarAbnormalList",
    url: `${m}/list`,
    sort: 8
};

export const children = {
    ADD_DATA: {
        id: "BUSINESS_MANAGEMENT_SENDCAR_ABNORMAL_ADD_DATA",
        apiName: "addSendcarAbnormal",
        method: "POST",
        name: "添加异常",
        type: "view",
        url: `${m}/add`
    },
    EDIT_DATA: {
        id: "BUSINESS_MANAGEMENT_SENDCAR_ABNORMAL_EDIT_DATA",
        apiName: "editSendcarAbnormal",
        method: "POST",
        name: "处理异常",
        type: "view",
        url: `${m}/edit`
    },
    LOOK_MORE: {
        id: "BUSINESS_MANAGEMENT_SENDCAR_ABNORMAL_LOOK_MORE",
        name: "查看",
        type: "view"
    },
    DEL_DATA: {
        id: "BUSINESS_MANAGEMENT_SENDCAR_ABNORMAL_DEL_DATA",
        apiName: "deleteSendcarAbnormal",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${m}/delete`
    },
    EXPORT_DATA: {
        id: "BUSINESS_MANAGEMENT_SENDCAR_ABNORMAL_EXPORT_DATA",
        apiName: "exportSendcarAbnormal",
        method: "POSTFILE",
        name: "导出",
        type: "view",
        url: `${m}/export`
    },
    FOOTER: {
        id: "BUSINESS_MANAGEMENT_SENDCAR_ABNORMAL_FOOTER",
        name: "提交/取消",
        type: "view"
    }
};
