const moduleName = "resource/salesman";
export const id = {
    id: "PROJECT_MANAGEMENT_SALESMAN",
    method: "POST",
    name: "业务员管理",
    type: "menu",
    apiName: "getSalesmans",
    url: `${moduleName}/page`,
    sort: 2
};

export const children = {
    ADD_DATA: {
        id: "PROJECT_MANAGEMENT_SALESMAN_ADD_DATA",
        apiName: "addSalesman",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}/save`
    },
    DEL_DATA: {
        id: "PROJECT_MANAGEMENT_SALESMAN_CODE",
        apiName: "delSalesman",
        method: "DELETE",
        name: "删除",
        type: "view",
        url: `${moduleName}/remove`
    },
    EDIT_DATA: {
        id: "PROJECT_MANAGEMENT_SALESMAN_EDIT_DATA",
        apiName: "editSalesman",
        method: "PUT",
        name: "编辑",
        type: "view",
        url: `${moduleName}/edit`
    },
    BLUK_CREATE: {
        id: "PROJECT_MANAGEMENT_SALESMAN_BLUK_CREATE",
        apiName: "salesmanBatchSave",
        method: "POST",
        name: "批量导入",
        type: "view",
        url: `${moduleName}/batchSave`
    },
    EXPORT_TEMPLATE: {
        id: "PROJECT_MANAGEMENT_SALESMAN_EXPORT_TEMPLATE",
        apiName: "salesmanExportTemplate",
        method: "GETFILE",
        name: "导出模板",
        type: "view",
        url: `${moduleName}/exportTemplate`
    },
    LOOK_MORE: {
        id: "PROJECT_MANAGEMENT_SALESMAN_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
