export const id = {
    id: "RECEIVABLE_MANAGEMENT_BILLING_MANAGEMENT",
    method: "POST",
    name: "开票管理",
    type: "menu",
    sort: 10
};

export const children = {
    BILLING_APPLY: {
        id: "RECEIVABLE_MANAGEMENT_BILLING_MANAGEMENT_BILLING_APPLY",
        method: "POST",
        name: "开票申请",
        type: "view"
    },
    BILLING_CONFIRM: {
        id: "RECEIVABLE_MANAGEMENT_BILLING_MANAGEMENT_BILLING_CONFIRM",
        method: "POST",
        name: "开票确认",
        type: "view"
    },
    EXPORT: {
        id: "RECEIVABLE_MANAGEMENT_BILLING_MANAGEMENT_EXPORT",
        method: "POST",
        name: "导出数据",
        type: "view"
    }
};
