import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class InventoryApi {
    /**
     *
     * @param {any} params
     * @returns
     *
     * @memberOf InventoryApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.POST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url;
        return this.POST(url, params);
    }

    [children.ON_REPORT.apiName](params) {
        let url = children.ON_REPORT.url + "/" + params.id;
        return this.GET(url, params);
    }

    // [children.ADD_DATA.apiName](params) { // 新增
    //     let url = children.ADD_DATA.url
    //     return this.POST(url, params)
    // }
}
