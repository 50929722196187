/*
 * @Descripttion:
 * @Author: hl.huang
 * @Date: 2021-01-11 15:05:18
 * @LastEditors: 黄华林
 * @LastEditTime: 2021-01-18 15:36:23
 */
export const id = {
    id: "FINANCIAL_MANAGEMENT_PAYABLE_MANAGEMENT_PAYOUT_MANAGER",
    method: "POST",
    name: "请款管理",
    type: "menu",
    sort: 4
};
export const children = {
    PAYOUT_APPLY: {
        id: "PAYABLE_MANAGEMENT_PAYOUT_APPLY",
        name: "付款申请",
        type: "view"
    },
    ACCOUNT_CHECK: {
        id: "PAYABLE_MANAGEMENT_PAYOUT_ACCOUNT_CHECK",
        name: "会计审核",
        type: "view"
    },
    PAYOUT_CONFIRM: {
        id: "PAYABLE_MANAGEMENT_PAYOUT_CONFIRM",
        name: "付款确认",
        type: "view"
    }
};
