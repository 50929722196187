import React, { Component, Fragment } from "react";
import { Form, Input, Button, Checkbox, message, Select, Modal } from "antd";
import "./index.less";
import { trim } from "@src/utils/index";
import AddonAfter from "./addon_after";
import { localRead } from "@src/utils";
// 使用cookie账号密码
import cookie from "./cookie.js";
// import logo from '@src/libs/img/logo.png'
import aIcon from "@src/libs/img/login/a.png";
import aACtiveIcon from "@src/libs/img/login/a-active.png";
import bIcon from "@src/libs/img/login/b.png";
import bACtiveIcon from "@src/libs/img/login/b-active.png";
import cIcon from "@src/libs/img/login/c.png";
import cACtiveIcon from "@src/libs/img/login/c-active.png";
import logo from "@src/libs/img/login/logo.png";
import loginMp4 from "@src/libs/img/login/login.mp4";
import { connect } from "dva";
import rApi from "@src/http";
import hosts from "@src/config/host.env.ts";
import _ from "@src/utils/lodash";
import { phoneRule } from "@src/libs/rule";
import { md5hash } from "@src/utils/hash";

const FormItem = Form.Item;

@connect(({ global, loading }) => ({
    version: global._version,
    isLogin: global.isLogin,
    logoUrl: global.logoUrl,
    platformName: global.platformName,
    icp: global.icp,
    loginLoding: loading.effects["global/login"]
    // infoLoading: loading.effects['global/getPlatformInfo'],
}))
class Login extends Component {
    state = {
        username: "", // 账号
        password: "", // 密码
        phone: null, // 手机号
        code: null, // 验证码
        isCheck: false,
        loginType: 1, // 1-账号登录 2-手机号登录
        userType: 1, // 用户类型 1-平台用户 2-货主端 3-承运商端
        clientTypeList: [
            // 用戶类型
            {
                value: 1,
                title: "平台用户",
                active: true,
                icon: aIcon,
                activeIcon: aACtiveIcon
            },
            // {
            //     value: 2,
            //     title: "货主端",
            //     active: false,
            //     url: "/client",
            //     icon: bIcon,
            //     activeIcon: bACtiveIcon
            // },
            {
                value: 3,
                title: "承运商端",
                active: false,
                url: "/carrier/",
                icon: cIcon,
                activeIcon: cACtiveIcon
            }
        ]
    };

    constructor(props) {
        super(props);
        const { dispatch } = props;
        let info = cookie.getLastUser();
        if (process.env.NODE_ENV !== "development") {
            dispatch({
                type: "global/closeAll"
            });
        }
        if (info && info.username) {
            this.state.username = info.username;
            this.state.password = info.password;
            this.state.isCheck = info.password ? true : false;
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({
            type: "global/getPlatformInfo"
        });
        dispatch({
            type: "global/init",
            payload: {
                page: "login"
            }
        });
    }

    // 获取表头配置数据
    getTableHeaderByType = () => {
        rApi.getTableHeaderByType({ type: null })
            .then((d) => {
                //  console.log('getTableHeaderByType', d)
                if (d && d.length) {
                    localStorage.setItem("tableHeaderConfig", JSON.stringify(d));
                }
            })
            .catch((e) => {
                console.error("e", e);
            });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let { dispatch } = this.props;
        const { loginType } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (loginType === 1) {
                    if (!values.userName) {
                        message.error("请输入账号");
                        return;
                    }
                    if (!values.password) {
                        message.error("请输入密码");
                        return;
                    }
                } else {
                    if (!values.phone) {
                        message.error("请输入手机号");
                        return;
                    }
                    if (!values.code) {
                        message.error("请输入验证码");
                        return;
                    }
                }
                values.username = values.userName;
                delete values.userName;
                const password = trim(values.password);
                cookie.setUser({
                    username: values.username,
                    password: loginType === 1 ? password : null,
                    isCheck: values.remember
                });
                dispatch({
                    type: "global/login",
                    payload: {
                        // ...values,
                        ...(loginType === 1
                            ? { username: trim(values.username), password: md5hash(password) }
                            : { username: trim(values.phone), password: trim(values.code) })
                    }
                });
            }
        });
    };

    // 登陆账号类型
    changeLoginType = (loginType) => {
        const { setFieldsValue } = this.props.form;
        if (this.state.loginType === loginType) return;
        if (loginType === 1) {
            setFieldsValue({ code: null });
        }
        this.setState({ loginType });
    };

    // 用户类型
    changeUserType = (item, index) => {
        let { clientTypeList } = this.state;
        if (this.state.userType === item.value) return;
        if (item.url) {
            window.location.replace(item.url);
            return;
        }
        // if (item.value === 2 || item.value === 3) {
        //     message.error('暂未开放')
        //     return
        // }
        clientTypeList = clientTypeList.map((d) => {
            return {
                ...d,
                active: d.value === item.value ? true : false
            };
        });
        this.setState({
            userType: item.value,
            clientTypeList
        });
    };

    // 发送验证码
    getCode = async () => {
        /* if (!this.state.phone) {
            message.error("请输入手机号");
            return;
        }
        console.log("发送中。。。");
        return 1; */
        try {
            const { form } = this.props;
            const data = await form.validateFields();
            if (phoneRule.test(data.phone)) {
                await rApi.verificationCode({ phone: data.phone });
                message.success("发送成功");
                return 1;
            } else {
                message.error(phoneRule.message);
                throw Error(phoneRule.message);
            }
        } catch (error) {
            // console.log("erro ssr", error);
            message.error(error.msg || "发送失败");
            return 0;
        }
    };

    onDevEnv = () => {
        const selectKey = localStorage.getItem("hostKey");
        Modal.info({
            title: "dev 代理环境",
            content: (
                <Select
                    style={{ minWidth: 300 }}
                    defaultValue={selectKey || "comma-sscm-local"}
                    onChange={(value) => localStorage.setItem("hostKey", value)}
                >
                    {/* <Select.Option value='comma-sscm-local'> 
                            192.168.2.234
                        </Select.Option>
                        <Select.Option value='comma-sscm-online'>
                            sscm.smartcomma.com
                        </Select.Option> */}
                    {_.keys(hosts).map((item) => (
                        <Select.Option key={item} value={item}>
                            {hosts[item]}
                        </Select.Option>
                    ))}
                </Select>
            ),
            onOk() {}
        });
    };

    render() {
        const { version, loginLoding, isLogin } = this.props;
        const { getFieldDecorator } = this.props.form;
        if (isLogin) {
            return null;
        }
        let {
            clientTypeList,
            loginType,
            phone, // 手机号
            code // 验证码
        } = this.state;
        const platformName = localRead("platformName") || this.props.platformName;
        const logoUrl = localRead("logoUrl") || this.props.logoUrl;
        const icp = localRead("icp") || this.props.icp;
        return (
            <div className="login-wrapper">
                <section className="flex flex-vertical-center">
                    {/* <div className="bg-gif"/> */}
                    <video
                        muted="muted"
                        src={loginMp4}
                        width="100%"
                        autoPlay="autoplay"
                        // loop="loop"
                    />
                </section>
                <div className="login-wrapper-content flex flex-vertical-center">
                    <div
                        style={{
                            position: "absolute",
                            bottom: 10,
                            width: "100%",
                            textAlign: "center",
                            fontSize: "14px"
                        }}
                        dangerouslySetInnerHTML={{ __html: icp || "" }}
                    >
                        {/* <span title={`版本号：${version}`}>Copyright</span> © 2020 深圳逗号互联科技有限公司 版权所有 | 粤ICP备16087390号 */}
                        {/* {icp} */}
                    </div>
                    <div
                        className="flex flex-vertical-center"
                        style={{
                            position: "absolute",
                            top: 40,
                            left: 40,
                            width: "100%",
                            textAlign: "center",
                            fontSize: "14px"
                        }}
                    >
                        <div>
                            <img src={logoUrl || logo} className="logo" />
                        </div>
                        <div style={{ fontSize: "30px" }}>&emsp;{platformName}</div>
                    </div>

                    <div className="left-wrapper flex1" />
                    <div className="right-wrapper">
                        <div className="page-login">
                            <ul className="user-type flex flex-vertical-center">
                                {clientTypeList.map((item, index) => (
                                    <li
                                        className={item.active ? "flex1 user-title selected" : "flex1 user-title"}
                                        key={item.value}
                                        onClick={() => this.changeUserType(item, index)}
                                    >
                                        <img className="icon" src={item.active ? item.activeIcon : item.icon} />
                                        <span>{item.title}</span>
                                    </li>
                                ))}
                            </ul>
                            <div style={{ padding: "0 20px" }}>
                                <ul className={`tab_title ${loginType === 1 ? "left" : "right"}`}>
                                    <li
                                        className={loginType === 1 ? "active" : ""}
                                        onClick={() => this.changeLoginType(1)}
                                    >
                                        账号登录
                                    </li>
                                    <li
                                        className={loginType === 2 ? "active" : ""}
                                        onClick={() => this.changeLoginType(2)}
                                    >
                                        手机号登录
                                    </li>
                                </ul>
                                <Form onSubmit={this.handleSubmit} className="login-form">
                                    {loginType === 1 ? (
                                        <React.Fragment>
                                            <FormItem>
                                                {getFieldDecorator("userName", {
                                                    rules: [{ required: false, message: "请输入账号!" }],
                                                    initialValue: this.state.username
                                                })(
                                                    <Input
                                                        placeholder=""
                                                        className="padding-left"
                                                        prefix={<span style={{ color: "#aaa" }}>用户名</span>}
                                                    />
                                                )}
                                            </FormItem>
                                            <FormItem>
                                                {getFieldDecorator("password", {
                                                    rules: [{ required: false, message: "请输入密码!" }],
                                                    initialValue: this.state.password
                                                })(
                                                    <Input
                                                        className="padding-left"
                                                        prefix={<span style={{ color: "#aaa" }}>密&emsp;码</span>}
                                                        type="password"
                                                        placeholder=""
                                                    />
                                                )}
                                            </FormItem>
                                            <div
                                                className="flex flex-vertical-center"
                                                style={{ height: 40, padding: "0 5px" }}
                                            >
                                                <FormItem>
                                                    {getFieldDecorator("remember", {
                                                        valuePropName: "checked",
                                                        initialValue: this.state.isCheck
                                                    })(<Checkbox>记住密码</Checkbox>)}
                                                </FormItem>
                                                <div className="flex1" />
                                                <FormItem>
                                                    <a className="login-form-forgot">忘记密码</a>
                                                </FormItem>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <div>
                                            <FormItem>
                                                {getFieldDecorator("phone", {
                                                    rules: [{ required: false }],
                                                    initialValue: this.state.phone
                                                })(
                                                    <Input
                                                        placeholder=""
                                                        className="padding-left"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                phone: e.target.value
                                                            });
                                                        }}
                                                        prefix={<span style={{ color: "#aaa" }}>手机号</span>}
                                                        // type="number"
                                                    />
                                                )}
                                            </FormItem>
                                            <FormItem>
                                                {getFieldDecorator("code", {
                                                    rules: [{ required: false }],
                                                    initialValue: this.state.code
                                                })(
                                                    <Input
                                                        className="form_code"
                                                        // type="number"
                                                        addonAfter={<AddonAfter getCode={this.getCode} />}
                                                        prefix={<span style={{ color: "#aaa" }}>验证码</span>}
                                                    />
                                                )}
                                            </FormItem>
                                            <div className="flex flex-vertical-center" style={{ height: 40 }} />
                                        </div>
                                    )}
                                    <Button
                                        loading={loginLoding}
                                        size="large"
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                    >
                                        登录
                                    </Button>
                                    {process.env.NODE_ENV === "development" && (
                                        <Button type="link" onClick={this.onDevEnv}>
                                            开发环境: {localStorage.getItem("hostKey")}
                                        </Button>
                                    )}
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Form.create()(Login);
