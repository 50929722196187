import _ from "@src/utils/lodash";
import NP from "number-precision";
const keys = {
    quantity: "quantity",
    volume: "volume",
    netWeight: "netWeight",
    weight: "weight",
    billingWeight: "billingWeight"
};
export const reduceCount = (list: any[], key?: string | null | undefined) => {
    if (!key) {
        if (list && list.length < 1) {
            return {
                quantity: 0,
                volume: 0,
                weight: 0,
                netWeight: 0,
                billingWeight: 0
            };
        }
        if (list.length === 1) {
            const item = list[0];
            const obj = {};
            _.keys(keys).forEach((ele) => {
                obj[ele] = item[ele];
            });
            return obj;
        }
        return list.reduce((pre, cur) => {
            const obj = {};
            _.keys(keys).forEach((ele) => {
                const pCount =
                    typeof pre[ele] === "number"
                        ? pre[ele]
                        : typeof parseFloat(pre[ele]) === "number"
                        ? parseFloat(pre[ele])
                        : 0;
                const cCount =
                    typeof cur[ele] === "number"
                        ? cur[ele]
                        : typeof parseFloat(cur[ele]) === "number"
                        ? parseFloat(cur[ele])
                        : 0;
                let num = NP.plus(pCount || 0, cCount || 0);
                num = num < 0 ? 0 : num;
                obj[ele] = num;
            });
            return obj;
        });
    }
    if (!list || list.length < 1) {
        return 0;
    }
    // console.log('list', list)
    return (
        list.reduce((pre, cur) => {
            const preCount =
                typeof pre[key] === "number"
                    ? pre[key]
                    : typeof parseFloat(pre[key]) === "number"
                    ? parseFloat(pre[key])
                    : 0;
            const curCount =
                typeof cur[key] === "number"
                    ? cur[key]
                    : typeof parseFloat(cur[key]) === "number"
                    ? parseFloat(cur[key])
                    : 0;
            let val = NP.plus(preCount || 0, curCount || 0);
            val = val < 0 ? 0 : val;
            return {
                [key]: val
            };
        })[key] || 0
    );
};
