const moduleName = "project/";
module.exports.id = {
    id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_DETAILS",
    method: "POST",
    name: "项目明细",
    type: "menu_hide",
    apiName: "getCooperativeDetailOne",
    url: `${moduleName}cooperationProject/getOne`
};

// let moduleName = ''

module.exports.children = {
    GET_LIST: {
        id: "PROJECT_MANAGEMENT_DEMAND_IMPORT_GET_LIST",
        apiName: "getDemandsList",
        method: "POST",
        name: "获取需求列表",
        type: "view",
        url: `${moduleName}demand/list`
    },
    START_DATA: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_DETAILS_START_DATA",
        apiName: "poweroff",
        method: "POST",
        name: "启动",
        type: "view",
        url: `${moduleName}cooperationProject/start`
    },
    STOP_DATA: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_DETAILS_STOP_DATA",
        apiName: "pauseMethod",
        method: "POST",
        name: "暂停",
        type: "view",
        url: `${moduleName}cooperationProject/stop`
    },
    EDIT_DATA: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_EDIT_DATA",
        apiName: "editCooperativeProject",
        method: "POST",
        name: "保存",
        type: "view",
        url: `${moduleName}cooperationProject/edit`
    },
    IMPORT: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_IMPORT",
        apiName: "importNBDevices",
        method: "POST",
        name: "设备导入",
        type: "view",
        url: `${moduleName}cooperationProject/saveDevices`
    },
    NB_BIND: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_NB_BIND",
        name: "NB设备添加",
        type: "view"
    },
    NB_IMPORT: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_MESSAGE_NB_IMPORT",
        name: "NB设备导入",
        type: "view"
    },
    NB_EXPORT: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_MESSAGE_NB_EXPORT",
        name: "NB设备导出",
        type: "view"
    },
    NB_BIND_EDIT: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_NB_BIND_EDIT",
        name: "NB设备编辑",
        type: "view"
    },
    NB_BIND_DEL: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_NB_BIND_DEL",
        name: "NB设备删除",
        type: "view"
    },
    MESSAGE_PUSH: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_MESSAGE_PUSH",
        name: "消息推送新增",
        type: "view"
    },
    MESSAGE_WARN_PUSH: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_MESSAGE_WARN_PUSH",
        name: "异常推送新增",
        type: "view"
    },
    MESSAGE_PUSH_EDIT: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_MESSAGE_PUSH_EDIT",
        name: "消息推送编辑",
        type: "view"
    },
    MESSAGE_PUSH_DEL: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_MESSAGE_PUSH_DEL",
        name: "消息推送删除",
        type: "view"
    },
    REPORT_PUSH: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_REPORT_PUSH",
        name: "报表推送新增",
        type: "view"
    },
    REPORT_PUSH_EDIT: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_REPORT_PUSH_EDIT",
        name: "报表推送编辑",
        type: "view"
    },
    REPORT_PUSH_DEL: {
        id: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT_REPORT_PUSH_DEL",
        name: "报表推送删除",
        type: "view"
    },
    LOOK_MORE: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
