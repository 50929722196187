import React from "react";
import { Button, message, Popconfirm } from "antd";
import { Table, Parent, ColumnFactory } from "@src/components/table_template";
import RemoteSelect from "@src/components/select_databook";
import Select from "@src/components/select";
import ModalSetHeader from "./modal_setheader";
import QuotationDetails from "./quotation_details";
import FunctionPower from "@src/layout/power_view/function.jsx";
import { CascaderAddress } from "@src/components/select_address";
import { costItemObjectToString, dealResponse, columnMap } from "./utils";
import { exportReqToExcel } from "@src/utils/logic";
import UploadExcel from "@src/components/upload_excel";
import rApi from "@src/http";

export default class QuotationTable extends Parent {
    constructor(props) {
        super(props);
        if (props.getInstance) {
            props.getInstance(this);
        }
        const columns = ColumnFactory.execute([
            ["a", "起运地", 180, this.renderCell],
            ["b", "目的地", 180, this.renderCell]
            // ['c', '中转地', 160, this.renderCell],
            // ['d', '公里数', 120, this.renderCell],
            // ['e', '时效(h)', 120, this.renderCell],
            // ['f', '是否高速', 120, this.renderCell],
            // ['g', '是否分拣', 120, this.renderCell],
            // ['h', '最低收费', 120, this.renderCell],
            // ['i', '节点类型', 120, this.renderCell],
            // ['j', '备注', undefined, this.renderCell]
        ]);
        this.state = {
            loading: false,
            exportLoading: false,
            importLoading: false,
            columns,
            costList: [],
            price: {
                id: 0,
                title: "按最低价"
            },
            expenseItem: {
                showTitle: null
            },
            addressDTOList: [] // 起运地目的地数据
        };
    }

    // 列渲染
    renderCell = ({ title, key, val, row, rowIndex, ColumnItemBox, defaultRender, parent }) => {
        if (row.isEdit) {
            switch (key) {
                case "a":
                case "b":
                    return (
                        <ColumnItemBox isFormChild>
                            <CascaderAddress
                                // getPopupContainer={() => {
                                //     return popupContainer ? popupContainer() : document.body
                                // }}
                                selectGrade={3}
                                placeholder={val}
                                handleChangeAddress={(value) => this.changeAddress({ value, key, rowIndex })}
                            />
                        </ColumnItemBox>
                    );
            }
            return defaultRender(val);
        } else {
            if (key.split("-")[0] === "expense") {
                return (
                    <ColumnItemBox>
                        <span
                            style={{
                                cursor: "pointer",
                                color: "#E76400"
                            }}
                            onClick={() => this.seeDetails(row)}
                        >
                            {val}
                        </span>
                    </ColumnItemBox>
                );
            }
            return defaultRender(val);
        }
    };

    // 获取表格数据
    getData = async (params) => {
        let dataSource = [],
            total = 0;
        try {
            const { businessModeId, quotationTypeOpts } = this.props;
            const { columns, costList, price, expenseItem, addressDTOList } = this.state;
            const header = { columns, costList };
            // const list = this.gd().filter(item => item.isEdit)
            if (!businessModeId) {
                return {
                    dataSource: [],
                    total: 0
                };
            }
            let reqData = {
                ...params,
                businessModeId,
                array: quotationTypeOpts.map((item) => parseInt(item.key, 10)),
                // quotation_expenseList: costList,
                quotation_expenseList: [],
                addressDTOList,
                priceId: price.id,
                ExpenseItemTitle: expenseItem.showTitle
            };
            let res = await rApi.getMatchingRow(reqData);
            dataSource = dealResponse(res || [], { header });
            total = res.length;
        } catch (error) {
            console.log(error || "操作失败");
            message.error(error.msg || "操作失败");
            dataSource = [];
            total = 0;
        } finally {
            return { dataSource, total };
        }
    };

    // 编辑起运地目的地
    changeAddress = ({ value, key, rowIndex }) => {
        // console.log('changeAddress', value, rowIndex)
        const { addressDTOList } = this.state;
        let list = this.gd();
        list[rowIndex][key] = value;
        const K = key === "a" ? "senderProvince" : "receiveProvince";
        const VAL = value.join("/");
        const NEW_OBJ = {
            [K]: VAL
        };
        addressDTOList[rowIndex] = addressDTOList[rowIndex]
            ? {
                  ...addressDTOList[rowIndex],
                  ...NEW_OBJ
              }
            : {
                  senderProvince: null,
                  receiveProvince: null,
                  ...NEW_OBJ
              };
        this.setState({ addressDTOList });
        this.upd(list);
    };

    // 导出
    exportData = async (type = "data") => {
        try {
            this.setState({ exportLoading: true });
            const { businessModeId, quotationTypeOpts } = this.props;
            const { price, expenseItem, addressDTOList, columns } = this.state;
            const list = this.gd();
            if ((!list || !list.length) && type !== "header") {
                throw { name: "valid", msg: "当前无数据" };
            }
            const quotationExpenseDTO = columns.map(({ key, ...item }) => {
                //   console.log('quotationExpenseDTO', item)
                // const { title } = item.title.props
                const { title } = item;
                const target = columnMap[key] || { type: 12, title };
                return {
                    type: target.type,
                    title: target.title
                };
            });
            // console.log(columns)
            const APINAME = type === "data" ? "exportQuikQuotation" : "exportQuikQuotationHeader";
            const reqData = {
                array: quotationTypeOpts.map((item) => parseInt(item.key, 10)),
                businessModeId,
                quotation_expenseList: [],
                addressDTOList,
                priceId: price.id,
                ExpenseItemTitle: expenseItem.showTitle,
                quotationExpenseDTO
            };
            const res = await rApi[APINAME](reqData);
            await exportReqToExcel(res);
        } catch (error) {
            console.log(error);
            message.error(error.msg || "操作失败");
        }
        this.setState({ exportLoading: false });
    };

    // 导入
    importExcel = async (d) => {
        if (this.state.importLoading) return;
        const list = this.gd();
        if (list.some((item) => !item.isEdit)) {
            message.warning("已存在匹配数据");
            return;
        }
        try {
            // console.log('importExcel', d)
            const formatObj = {};
            for (const key in columnMap) {
                if (columnMap.hasOwnProperty(key)) {
                    const element = columnMap[key];
                    formatObj[element.title] = element.importFormat
                        ? {
                              k: key,
                              format: element.importFormat
                          }
                        : key;
                }
            }
            const header = d[0];
            let exData = d.slice(1);
            exData = exData.map((arr) => {
                const obj = {
                    _noEdit: true,
                    _noSave: true,
                    isEdit: true
                };
                arr.forEach((val, i) => {
                    const key = formatObj[header[i]];
                    // console.log('d', exData, key)
                    if (typeof key === "string") {
                        obj[key] = val;
                    } else {
                        obj[key.k] = key.format(val);
                    }
                });
                return obj;
            });
            const addressDTOList = exData.map((item) => ({
                senderProvince: item.a,
                receiveProvince: item.b
            }));
            // console.log('rt', formatObj, exData, addressDTOList)
            this.setState({ addressDTOList });
            this.upd(exData);
        } catch (error) {
            console.log(error);
            window._tips.error(error.msg || "操作失败");
        } finally {
            this.setState({ importLoading: false });
        }
    };

    // 新建
    onAdd = () => {
        // console.log('onAdd')
        let list = this.gd();
        if (list.some((item) => !item.isEdit)) {
            message.warning("已存在匹配数据");
            return;
        }
        let newRow = {
            a: "",
            b: "",
            _noEdit: true,
            _noSave: true,
            isEdit: true
        };
        list.push(newRow);
        this.upd(list);
    };

    // 打开费用项
    onExpense = () => {
        // console.log('onExpense', this)
        const { columns, costList } = this.state;
        const { quotationTypeOpts, businessModeId, businessModeName } = this.props;
        let newColumns = columns.filter((col) => ~~col.key.split("-").indexOf("expense"));
        this.modalSetheader.show({
            columns: newColumns,
            costList,
            quotationTypeOpts,
            businessModeId,
            businessModeName
        });
    };

    // 修改表头
    changeHeader = (newColumns, newCostList) => {
        let { columns } = this.state;
        const costColumns =
            ColumnFactory.execute(
                newCostList.map((item, index) => [
                    `expense-${index}`,
                    `${costItemObjectToString(item)}`,
                    200,
                    this.renderCell
                ])
            ) || [];
        columns = [...newColumns, ...costColumns];
        columns = columns.filter((col) => ~~col.key.split("-").indexOf("expense"));
        // console.log('changeHeader', newColumns, newCostList, columns)
        columns = [...columns, ...costColumns];
        // console.log('changeHeader', newColumns, newCostList)
        this.setState({
            columns,
            costList: newCostList
        });
    };

    customActionView = ({ text, record, index, onDeleteItem, onEditItem, DeleteButton }) => {
        return (
            <React.Fragment>
                {
                    // record._noDel ?
                    // null
                    // :
                    record.isEdit ? (
                        <span className="action-button" onClick={() => this.onSave(record, index)}>
                            保存
                        </span>
                    ) : (
                        <span className="action-button" onClick={() => this.onEdit(record, index)}>
                            编辑
                        </span>
                    )
                }
                {
                    // record._noDel ?
                    // null
                    // :
                    <Popconfirm
                        placement="top"
                        title="确定要刪除此项?"
                        onConfirm={() => this.onDeleteItem(record, index)}
                        okText="确定"
                        cancelText="取消"
                    >
                        <span className="action-button">删除</span>
                    </Popconfirm>
                }
            </React.Fragment>
        );
    };

    /**
     * 保存
     *
     * @memberof QuotationTable
     */

    onSave = (r, i) => {
        let list = this.gd();
        list[i] = {
            ...r,
            isEdit: false
        };
        this.upd(list);
    };

    /**
     * 编辑
     *
     * @memberof QuotationTable
     */

    onEdit = (r, i) => {
        let list = this.gd();
        list[i] = {
            ...r,
            isEdit: true
        };
        this.upd(list);
    };

    /**
     * 删除
     *
     * @memberof QuotationTable
     */
    onDeleteItem = (r, i) => {
        // console.log('删除')
        const list = this.gd();
        list.splice(i, 1);
        this.upd(list);
    };

    // 查看报价详情
    seeDetails = (rowData) => {
        // console.log('seeDetails', rowData)
        this.quotationDetails.show(rowData);
    };

    render() {
        const { loading, columns, costList, price, exportLoading, importLoading } = this.state;
        return (
            <div ref={(v) => (this.rootDom = v)}>
                <ModalSetHeader
                    getThis={(v) => (this.modalSetheader = v)}
                    changeHeader={this.changeHeader}
                    renderCell={this.renderCell}
                    {...this.props}
                />
                <QuotationDetails getThis={(v) => (this.quotationDetails = v)} />
                <Table
                    noPadding
                    isPreventActionEvent
                    isHideAddButton
                    isHideDragButton
                    isHideDeleteButton
                    isNoneSelected
                    isNonePagination
                    isUseTableMaxHeight
                    tableHeight={390}
                    actionWidth={90}
                    parent={this}
                    cusLoading={loading}
                    columns={columns}
                    getData={this.getData}
                    actionView={this.customActionView}
                    power={{
                        DEL_DATA: {}
                    }}
                    isNotFilter
                    // hasExportButton
                    onExport={this.onExport}
                    title={
                        <div
                            style={{
                                display: "flex"
                            }}
                        >
                            <Select
                                style={{
                                    width: 220
                                }}
                                placeholder="费用项基准"
                                handleFocus={() =>
                                    costList.map((cost) => ({
                                        ...cost,
                                        showTitle: costItemObjectToString(cost)
                                    }))
                                }
                                keyName="showTitle"
                                handleChange={(val, expenseItem) => {
                                    this.setState({ expenseItem });
                                }}
                            />
                            <RemoteSelect
                                defaultValue={price}
                                style={{
                                    width: 120,
                                    marginLeft: 10
                                }}
                                onChangeValue={(price = { id: null, title: "" }) => {
                                    this.setState({ price });
                                }}
                                placeholder="价格基准"
                                list={[
                                    { id: 0, title: "按最低价" },
                                    { id: 1, title: "按最高价" }
                                ]}
                                disabled={false}
                                allowClear={false}
                            />
                        </div>
                    }
                    TableHeaderChildren={
                        <React.Fragment>
                            <FunctionPower power={{}}>
                                <UploadExcel getExcelData={this.importExcel} loading={importLoading} />
                            </FunctionPower>
                            <FunctionPower power={{}}>
                                <Button
                                    onClick={() => this.exportData("header")}
                                    style={{ marginRight: 10, verticalAlign: "middle" }}
                                    loading={exportLoading}
                                    size={window._size}
                                >
                                    导出表头
                                </Button>
                            </FunctionPower>
                            <FunctionPower power={{}}>
                                <Button
                                    onClick={() => this.exportData("data")}
                                    style={{ marginRight: 10, verticalAlign: "middle" }}
                                    loading={exportLoading}
                                    size={window._size}
                                >
                                    导出
                                </Button>
                            </FunctionPower>
                            <FunctionPower power={{}}>
                                <Button
                                    style={{ marginRight: 10, verticalAlign: "middle" }}
                                    onClick={this.onExpense}
                                    size={window._size}
                                >
                                    费用项
                                </Button>
                            </FunctionPower>
                            <FunctionPower power={{}}>
                                <Button style={{ verticalAlign: "middle" }} onClick={this.onAdd} size={window._size}>
                                    新建
                                </Button>
                            </FunctionPower>
                        </React.Fragment>
                    }
                    // onDeleteItem={this.onDeleteItem}
                    //  onSaveDeleteNewData={this.onDeleteItem}
                />
            </div>
        );
    }
}
