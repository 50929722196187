import React, { Component } from "react";
import rApi from "@src/http";
import { Cascader, Input, Icon, message } from "antd";
import PropTypes from "prop-types";
import { cloneObject } from "@src/utils";
// import addressData from '@src/libs/address'
import "./cascader.less";
import _ from "lodash";
// import { Item } from 'react-contexify';

const getAddressData = () => {
    return import("../../libs/address.json");
};

{
    /* 非受控组件 */
}
export default class CascaderAddress extends Component {
    /**
     * 父组件传值接口defaultValue
     *
     * @memberof CascaderAddress
     */
    static propTypes = {
        getValueForChild: PropTypes.func, //将onchange值返回给父组件
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
        selectGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
        hasCountry: PropTypes.bool
    };

    static defaultProps = {
        selectGrade: 4, // 1 加载城市, 2加载区县, 3加载街道, 4详细地址
        disabled: false,
        hasCountry: false
    };

    state = {
        defaultValue: this.props.defaultValue || [],
        options: [],
        data: [],
        extra: "",
        show: true,
        isChange: false,
        abroadAddressList: [],
        isAbroad: false
    };

    constructor(props) {
        super(props);
        const { defaultValue, address } = props;
        if (props.getThis) {
            props.getThis(this);
        }
        if (address) {
            this.initData();
            const { value, extra } = this.getAddressFromProps(address);
            this.state.defaultValue = value;
            this.state.extra = extra;
            this.state.data = this.state.defaultValue;
            // console.log('constructor', this.state.data, this.state.defaultValue)
        }
    }
    componentDidMount() {
        this.initData();
        this.abroadAddress();
    }

    getAddressFromProps(address) {
        const value = [];
        let extra = "";
        if (address.pro) {
            value.push(address.pro);
        }
        if (address.city) {
            value.push(address.city);
        }
        if (address.dist) {
            value.push(address.dist);
        }
        if (address.street) {
            value.push(address.street);
        }

        if (address.extra) {
            extra = address.extra;
        }

        return { value, extra };
    }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (!_.isEqual(this.props.address, nextProps.address) || (nextProps.address && !this.state.data.length)) {
    //         const { value, extra } = this.getAddressFromProps(nextProps.address);
    //         this.setState({
    //             data: value,
    //             extra
    //         })
    //     }
    // }

    abroadAddress = () => {
        rApi.getAbroadArea(1).then((res) => {
            this.setState({
                abroadAddressList: res.data
            });
        });
    };

    onChange = (value, selectedOptions) => {
        const { onChangeProvince, handleChangeAddress, getValueForChild } = this.props;
        selectedOptions = selectedOptions.map((item) => {
            if (item.value === "国内" || item.value === "国外") {
                this.setState({
                    isAbroad: item.value === "国外"
                });
                return {
                    ...item,
                    id: "del",
                    name: "国内外"
                };
            }
            return {
                ...item,
                id: item.value,
                name: item.label
            };
        });

        this.setState(
            {
                data: selectedOptions.filter((item) => item.id != "del"),
                isChange: true
            },
            () => {
                if (getValueForChild) {
                    let data = this.getValue();
                    getValueForChild(data);
                }
            }
        );
        if (handleChangeAddress) {
            let arr = selectedOptions.filter((item) => item.name !== "国内外").map((item) => item.label);
            // arr.filter(item=> item !== '国外')
            console.log("selectedOptions", selectedOptions);
            handleChangeAddress(arr, selectedOptions);
        }

        if (onChangeProvince && value && value.length > 1) {
            let name = "";
            let data = value[1];
            if (selectedOptions && selectedOptions[0]) {
                let options = selectedOptions[0];
                if (options.children && options.children.length) {
                    let v = options.children.map((item) => item.value === data);
                    name = v && v.label;
                }
            }

            let dict = {
                id: data,
                name: name,
                label: name
            };

            onChangeProvince(dict);
        }
    };

    initData = () => {
        // this.setState({ options: addressData.data })
        const { hasCountry } = this.props;
        getAddressData()
            .then((res) => {
                const data = _.cloneDeep(res.data);
                if (hasCountry) {
                    data.unshift({ label: "全国", value: "全国" });
                }
                this.setState({ options: data });
            })
            .catch((e) => {
                message.error("load address data error");
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    };

    loadData = (selectedOptions) => {
        // const targetOption = selectedOptions[selectedOptions.length - 1]
        // const { selectGrade } = this.props
        // // console.log('targetOption', targetOption)
        // if (selectedOptions.length === 1) {
        //     targetOption.loading = true
        //     return rApi.getCitys(targetOption).then((d) => {
        //         targetOption.loading = false
        //         targetOption.children = d.map((item, index) => {
        //             if (selectGrade === 1 || selectGrade === 'city') {
        //                 return {
        //                     ...item,
        //                     label: item.name,
        //                     value: item.id
        //                 }
        //             }
        //             return {
        //                 ...item,
        //                 isLeaf: false,
        //                 label: item.name,
        //                 value: item.id
        //             }
        //         })
        //         this.setState({
        //             options: [...this.state.options],
        //         })
        //     }).catch(e => {
        //         message.error('加载城市失败！')
        //     })
        // } else if (selectedOptions.length === 2) {
        //     targetOption.loading = true
        //     rApi.getCountys(targetOption).then(d => {
        //         targetOption.loading = false
        //         targetOption.children = d.map((item, index) => {
        //             if (selectGrade === 2 || selectGrade === 'county') {
        //                 return {
        //                     ...item,
        //                     label: item.name,
        //                     value: item.id
        //                 }
        //             }
        //             return {
        //                 ...item,
        //                 isLeaf: false,
        //                 label: item.name,
        //                 value: item.id
        //             }
        //         })
        //         this.setState({
        //             options: [...this.state.options],
        //         })
        //     }).catch(e => {
        //         message.error('加载区县失败！')
        //     })
        // } else if (selectedOptions.length === 3) {
        //     targetOption.loading = true
        //     rApi.getStreets(targetOption).then(d => {
        //         targetOption.loading = false;
        //         targetOption.children = d.map((item, index) => {
        //             return {
        //                 ...item,
        //                 label: item.name,
        //                 value: item.id
        //             }
        //         })
        //         this.setState({
        //             options: [...this.state.options],
        //         })
        //     }).catch(e => {
        //         message.error('加载街道失败！')
        //     })
        // }
    };

    getIsAbroad = () => {
        return this.state.isAbroad;
    };

    getValue = () => {
        let { isChange, extra, data } = this.state;

        const { address } = this.props;
        let province = cloneObject(data[0]);
        let city = cloneObject(data[1]);
        let county = cloneObject(data[2]);
        let street = cloneObject(data[3]);
        let proVul = null;
        let cityVul = null;
        let countyVul = null;
        let streetVul = null;
        if (isChange) {
            if (province) {
                proVul = province.name;
            }
            if (city) {
                cityVul = city.name;
            }
            if (county) {
                countyVul = county.name;
            }
            if (street) {
                streetVul = street.name;
            }
            let s = "";
            if (proVul) {
                s += proVul;
            }
            if (cityVul) {
                s += "/";
                s += cityVul;
            }
            if (countyVul) {
                s += "/";
                s += countyVul;
            }
            if (streetVul) {
                s += "/";
                s += streetVul;
            }
            if (extra) {
                s += " ";
                s += extra;
            }

            if (isChange && extra) {
                let d = {
                    pro: proVul,
                    city: cityVul,
                    dist: countyVul,
                    street: streetVul,
                    extra: province && province.name && city && city.name ? extra : null,
                    formatAddress: s
                };
                for (let key in d) {
                    if (!d[key]) {
                        delete d[key];
                    }
                }
                return d;
            }
            let d = {
                pro: proVul,
                city: cityVul,
                dist: countyVul,
                street: streetVul,
                extra: extra ? extra : null,
                formatAddress: s
            };
            for (let key in d) {
                if (!d[key]) {
                    delete d[key];
                }
            }
            return d;
        } else {
            if (province) {
                proVul = address.pro;
            }
            if (city) {
                cityVul = address.city;
            }
            if (county) {
                countyVul = address.dist;
            }
            if (street) {
                streetVul = address.street;
            }
            let s = "";
            if (proVul) {
                s += proVul;
            }
            if (cityVul) {
                s += "/";
                s += cityVul;
            }
            if (countyVul) {
                s += "/";
                s += countyVul;
            }
            if (streetVul) {
                s += "/";
                s += streetVul;
            }
            if (extra) {
                s += " ";
                s += extra;
            }
            return Object.assign({}, address, { extra: extra, formatAddress: s });
        }
    };

    defaultValueToString = (s) => {
        s = s || [];
        return s.join("/");
    };

    filter = (inputValue, path) => {
        return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };

    render() {
        const { selectGrade, getPopupContainer, getValueForChild, disabled, style, placeholder } = this.props;
        let { defaultValue, options, isChange, abroadAddressList, data } = this.state;
        if (!this.state.show) {
            return false;
        }

        let getTContainer = {};
        if (getPopupContainer) {
            getTContainer.getPopupContainer = getPopupContainer;
        } else {
            getTContainer.getPopupContainer = () => document.querySelector("#scroll-view");
        }
        let optionsData = [
            {
                label: "国内",
                value: "国内",
                children: options
            },
            {
                label: "国外",
                value: "国外",
                children: abroadAddressList
            }
        ];
        return (
            <div className="flex" style={{ ...style }}>
                <div className="flex1">
                    <Cascader
                        // ref="cascader"
                        {...getTContainer}
                        title={this.props.title}
                        changeOnSelect
                        showSearch={this.filter}
                        size={window._size}
                        style={{ minWidth: "200px", width: "100%" }}
                        options={optionsData}
                        onChange={this.onChange}
                        placeholder={placeholder || "选择地址"}
                        allowClear
                        // loadData={this.loadData}
                        popupClassName="cas-addr-wrapper"
                        disabled={disabled}
                        displayRender={(label /* , selectedOptions */) => {
                            if (!label.length) {
                                return (
                                    (data &&
                                        data
                                            .filter((item) => item !== "国内" && item !== "国外")
                                            .map((item) => item.name)
                                            .join(" / ")) ||
                                    ""
                                );
                            }
                            return (
                                (label && label.filter((item) => item !== "国内" && item !== "国外").join(" / ")) || ""
                            );
                        }}
                    >
                        {!isChange && defaultValue && defaultValue.length > 0 ? (
                            <span
                                style={{ width: "100%" }}
                                className={`ant-cascader-picker ${disabled ? "ant-cascader-picker-disabled" : ""}`}
                            >
                                <span className={`${"ant-cascader"}-picker-label`}>
                                    {this.defaultValueToString(defaultValue)}
                                </span>
                                <input
                                    type="text"
                                    className={`ant-input ant-cascader-input ${
                                        window._size === "small" ? "ant-input-sm" : ""
                                    } ${disabled ? "ant-input-disabled" : ""}`}
                                />
                                <Icon type="down" className="anticon anticon-down ant-cascader-picker-arrow" />
                            </span>
                        ) : null}
                    </Cascader>
                </div>
                {selectGrade === 4 ? (
                    <div style={{ width: 140 }}>
                        <Input
                            disabled={disabled}
                            style={{ width: "calc(100% - 5px)", marginLeft: 5 }}
                            title={this.state.extra}
                            size={window._size}
                            onChange={(e) => {
                                let val = e.target.value;
                                try {
                                    val = val.replace(/\s+/g, "");
                                } catch (error) {
                                    val = val;
                                }
                                this.setState(
                                    {
                                        extra: val
                                    },
                                    () => {
                                        if (getValueForChild) {
                                            let data = this.getValue();
                                            getValueForChild(data);
                                        }
                                    }
                                );
                            }}
                            defaultValue={this.state.extra}
                            placeholder="输入详细地址"
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

// export default SourceDemo;
