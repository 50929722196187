import home from "@src/views/home";

const files = require.context("./", true, /\.modules.(js|ts)$/);
let modules = {};
const keys = files.keys();
for (let key of keys) {
    // if (key === './settlement/receivable/index.modules.js' || new RegExp('settlement').test(key)) {
    //     console.log('content', key, files(key))
    // }
    try {
        let content = files(key);
        if (content && content.default) {
            for (let k in content.default) {
                if (modules[k]) {
                    console.error(`模块名称 ${k} 出现重复名字`);
                }
            }
            modules = Object.assign({}, modules, content.default);
        }
    } catch (e) {}
}
// console.log('modules', modules['SETTLEMENT_ACCOUNT_RECEIVABLE'], keys)
export default {
    ...modules,
    ...home
};
