import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class ClientApi {
    /**
     * 获取客户资料数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf CarApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.POST(url, params);
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        return this.POST(url, params);
    }

    [children.RESET_PASSWORD.apiName](params) {
        // 重置密码
        let url = children.RESET_PASSWORD.url;
        return this.POST(url, params);
    }

    [children.REVIEW.apiName](params) {
        // 审核
        let url = children.REVIEW.url;
        return this.POST(url, params);
    }

    [children.ENABLE.apiName](params) {
        // 启用/禁用
        let url = children.ENABLE.url + "/" + params.id;
        return this.GET(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url;
        return this.POST(url, params);
    }

    [children.BLUK_CREATE.apiName](params) {
        let url = children.BLUK_CREATE.url;
        return this.POST(url, params);
    }

    [children.EXPORT_TEMPLATE.apiName](params) {
        let url = children.EXPORT_TEMPLATE.url;
        return this.GETFILE(url, params);
    }

    [children.EXPORT_LIST.apiName](params) {
        let url = children.EXPORT_LIST.url;
        return this.POSTFILE(url, params);
    }
    // [children.GEN_CODE.apiName](params) {
    //     let url = children.GEN_CODE.url
    //     return this.POST(url, params)
    // }
    gencode(params) {
        //获取客户代码
        return this.POST("/client/client/gencode", params);
    }

    getClientslistForProject(params) {
        //根据状态获取客户数据
        return this.POST("/client/client/listForProject", params);
    }

    reviewAgreeOnClient(params) {
        //审核通过
        const url = `/client/client/reviewAgree/${params.id}`;
        return this.GET(url, params);
    }

    reviewDisagreeOnClient(params) {
        //审核驳回
        const url = `/client/client/reviewDisagree/${params.id}`;
        return this.GET(url, params);
    }

    getPlatformAllAuth() {
        // 获取客户平台所有权限
        const url = `/client/menu/allTree`;
        return this.GET(url);
    }
    getPlatformClientAuth(clientId) {
        // 获取客户平台用户权限
        const url = `/client/menu/admin/userMenu/${clientId}`;
        return this.GET(url);
    }
    getClientReview(params) {
        return this.POST("/client/client/review", params);
    }
    getclientLog(params) {
        const url = `/client/client/getLog/${params.id}`;
        return this.GET(url, params);
    }

    getSettlementObjectById(params) {
        const url = `/client/client/getSettlementObjectById/${params.id}`;
        return this.GET(url, params);
    }
}
