import { app } from "@src/models";
import MODULEDEFINE from "@src/views/MODULEDEFINE";
// import * as mobxState from '@src/store'

const openNewPage = (params?: any) => {
    if (window["scmOpenTab"]) {
        window["scmOpenTab"]({
            ...params,
            item: {
                ...MODULEDEFINE[params.key]
            },
            module: "sscm"
        });
    }
    app["_store"].dispatch({
        type: "global/openTab",
        payload: params
    });
};

/**
 *  页面跳转声明
 */

export const toCarIndexPage = (params?: any) => {
    // 车辆列表页
    openNewPage({
        key: "RESOURCE_MANAGEMENT_BASE_CAR",
        ...params
    });
};

export const toClientSource = (params?: any) => {
    // 跳到客户资料列表页
    openNewPage({
        key: "CLIENT_SOURCE",
        ...params
    });
};

export const toDemand = (params?: any) => {
    // 跳到客户需求页
    openNewPage({
        key: "PROJECT_MANAGEMENT_DEMAND_DETAILS",
        navInfo: {
            show: true,
            parentPageKey: "PROJECT_MANAGEMENT_DEMAND_IMPORT"
        },
        ...params
    });
};

export const toDemandList = (params?: any) => {
    // 跳到需求导入规划列表页
    openNewPage({
        key: "PROJECT_MANAGEMENT_DEMAND_IMPORT",
        ...params
    });
};

export const toCooperativeDetail = (params?: any) => {
    // 跳到合作项目明细页
    openNewPage({
        key: "PROJECT_MANAGEMENT_PROJECT_DETAILS",
        navInfo: {
            show: true,
            parentPageKey: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT"
        },
        ...params
    });
};

export const toCooperativeList = (params?: any) => {
    // 跳到合作项目列表页
    openNewPage({
        key: "PROJECT_MANAGEMENT_COOPERATIVE_PROJECT",
        ...params
    });
};

export const toOrderAdd = (params?: any) => {
    // 跳到新建发货单页
    openNewPage({
        key: "BUSINESS_MANAGEMENT_ORDER_ADD",
        navInfo: {
            show: true,
            parentPageKey: "BUSINESS_MANAGEMENT_ORDER_LIST"
        },
        ...params
    });
};
export const toOrderMap = (params?: any) => {
    // 跳到发货单追踪页
    openNewPage({
        key: "BUSINESS_MANAGEMENT_ORDER_ADD",
        navInfo: {
            show: true,
            parentPageKey: "BUSINESS_MANAGEMENT_ORDER_LIST"
        },
        ...params
    });
};

export const toSettlementAdd = (params?: any) => {
    // 跳到新建发货单页
    openNewPage({
        key: "RECEIVABLE_SETTLEMENT_MAINTENANCE_ADD",
        navInfo: {
            show: true,
            parentPageKey: "RECEIVABLE_SETTLEMENT_MAINTENANCE_LIST"
        },
        ...params
    });
};

export const toOrderList = (params?: any) => {
    // 跳到发货单列表页
    openNewPage({
        key: "BUSINESS_MANAGEMENT_ORDER_LIST",
        ...params
    });
};

export const toSendcarDetails = (params?: any) => {
    // 跳到派车详情页
    openNewPage({
        key: "SENDCAR_MANAGEMENT_DETAILS",
        ...params
    });
};

export const toTrackList = (params?: any) => {
    // 跳到发货单追踪列表页
    openNewPage({
        key: "BUSINESS_MANAGEMENT_TRACK_LIST",
        ...params
    });
};

export const toTrackManagement = (params?: any) => {
    // 跳到在途追踪
    openNewPage({
        key: "TRACK_MANAGEMENT",
        ...params
    });
};

export const toAllocateEdit = (params?: any) => {
    // 跳到运单明细
    openNewPage({
        key: "BUSINESS_MANAGEMENT_ALLOCATE_LIST_EDIT",
        navInfo: {
            show: true,
            parentPageKey: "BUSINESS_MANAGEMENT_ALLOCATE_LIST"
        },
        ...params
    });
};
export const toHandleEdit = (params?: any) => {
    // 跳到应付结算详情
    openNewPage({
        key: "HANDLE_EDIT_LIST_EDIT",
        navInfo: {
            show: true,
            parentPageKey: "HANDLE_EDIT_LIST"
        },
        ...params
    });
};

export const toStowageMaintenanceEdit = (params?: any) => {
    // 跳到应付数据维护录入
    openNewPage({
        key: "STOWAGE_MAINTENANCE_EDIT",
        navInfo: {
            show: true,
            parentPageKey: "STOWAGE_MAINTENANCE_LIST"
        },
        ...params
    });
};

export const toStowageMaintenanceList = (params?: any) => {
    // 跳到应付数据维护列表页
    openNewPage({
        key: "STOWAGE_MAINTENANCE_LIST",
        ...params
    });
};

export const toOrderMaintenanceEdit = (params?: any) => {
    //跳到应收数据维护页
    openNewPage({
        key: "ORDER_MAINTENANCE_EDIT_PAGE",
        navInfo: {
            show: true,
            parentPageKey: "ORDER_MAINTENANCE_LIST"
        },
        ...params
    });
};

export const toOrderMaintenanceList = (params?: any) => {
    // 跳到应收数据维护列表页
    openNewPage({
        key: "ORDER_MAINTENANCE_LIST",
        ...params
    });
};

export const toCarrierList = (params?: any) => {
    // 跳到承运商列表页
    openNewPage({
        key: "RESOURCE_MANAGEMENT_BASE_CARRIER",
        ...params
    });
};

export const toCustomerTransport = (params?: any) => {
    // 跳到客户运输报价列表页
    openNewPage({
        key: "CLIENT_QUOTATION",
        ...params
    });
};

export const toCustomerStorage = (params?: any) => {
    // 跳到客户仓储报价列表页
    openNewPage({
        key: "PROJECT_MANAGEMENT_OFFER_CUSTOMER_STORAGE",
        ...params
    });
};

export const toPayableAccount = (params?: any) => {
    // 跳到应付对账明细页
    openNewPage({
        key: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_DETAILS",
        navInfo: {
            show: true,
            parentPageKey: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE"
        },
        ...params
    });
};

export const toReceivableAccount = (params?: any) => {
    // 跳到应收对账明细页
    openNewPage({
        key: "RECEIVABLE_SETTLEMENT_MAINTENANCE_ADD",
        navInfo: {
            show: true,
            parentPageKey: params.parentPageKey || "SETTLEMENT_ACCOUNT_RECEIVABLE"
        },
        ...params
    });
};

export const toWarehouseReceivableAccount = (params?: any) => {
    // 跳到仓库应收对账明细页
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT_DETAILS",
        navInfo: {
            show: true,
            parentPageKey: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT"
        },
        ...params
    });
};

export const toReceiptManageDetails = (params?: any) => {
    // 跳到收货管理明细页
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS",
        ...params
    });
};

export const toReceiptManage = (params?: any) => {
    // 跳到收货管理
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE",
        ...params
    });
};

export const toShipmentManage = (params?: any) => {
    // 跳到出货管理
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE",
        ...params
    });
};

export const toShipmentEntruckDetails = (params?: any) => {
    // 跳到装车发运明细页
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS",
        ...params
    });
};

export const toShipmentReport = (params?: any) => {
    // 跳到出货报表
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_SHEET_DELIVER",
        ...params
    });
};

export const toReceiptReport = (params?: any) => {
    // 跳到收货报表
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_SHEET_RECEIVE",
        ...params
    });
};

export const toQualityDetails = (params?: any) => {
    // 跳到质检详情
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_QUALITY_INSPECTION_DETAILS",
        ...params
    });
};

export const toContractCreate = (params?: any) => {
    // 跳到合同创建页
    openNewPage({
        key: "CONTRACT_MGT_CLIENT_DETAILS",
        ...params
    });
};
export const toCarrierCreate = (params?: any) => {
    // 跳到合同创建页
    openNewPage({
        key: "CONTRACT_MGT_CARRIER_DETAILS",
        ...params
    });
};
export const toContractMgt = (params?: any) => {
    // 跳到合同列表页
    openNewPage({
        key: "CONTRACT_MGT_PROJECT",
        ...params
    });
};

export const toContractCarrierMgt = (params?: any) => {
    // 跳到合同列表页
    openNewPage({
        key: "CONTRACT_MGT_CARRIER_LIST",
        ...params
    });
};

export const toElectronicWayBill = (params?: any) => {
    // 跳到电子运单详情
    openNewPage({
        key: "SUPERVISION_ELECTRONIC_WAYBILL_DETAILS",
        ...params
    });
};

export const toCapitalFlow = (params?: any) => {
    // 跳到资金流水列表页
    openNewPage({
        key: "CAPITAL_FLOW_PROJECT",
        ...params
    });
};

export const toCapitalFlowDetail = (params?: any) => {
    // 跳到资金流水详情
    openNewPage({
        key: "CAPITAL_FLOW_DETAILS",
        ...params
    });
};

export const toInsuranceDetails = (params?: any) => {
    // 跳到运输保险详情
    openNewPage({
        key: "BUSINESS_MANAGEMENT_INSURANCE_DETAILS",
        ...params
    });
};
export const toElectronicWayBillList = (params?: any) => {
    // 跳到电子运单列表
    openNewPage({
        key: "SUPERVISION_ELECTRONIC_WAYBILL_LIST",
        ...params
    });
};

export const toSendcarList = (params?: any) => {
    // 跳到运单列表
    openNewPage({
        key: "BUSINESS_MANAGEMENT_ALLOCATE_LIST",
        ...params
    });
};

export const toInsuranceList = (params?: any) => {
    // 跳到保险列表
    openNewPage({
        key: "BUSINESS_MANAGEMENT_INSURANCE_LIST",
        ...params
    });
};

export const toOrderAbnormal = (params?: any) => {
    // 跳到发货单异常
    openNewPage({
        key: "BUSINESS_MANAGEMENT_ORDER_ABNORMAL",
        ...params
    });
};

export const toSendCarAbnormal = (params?: any) => {
    // 跳到运单异常
    openNewPage({
        key: "BUSINESS_MANAGEMENT_SENDCAR_ABNORMAL",
        ...params
    });
};

export const toReceiptAbnormal = (params?: any) => {
    // 跳到收货异常
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_RECEIPT_ABNORMAL",
        ...params
    });
};

export const toShipmentAbnormal = (params?: any) => {
    // 跳到出货异常
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_SHIPMENT_ABNORMAL",
        ...params
    });
};

export const toInventoryAbnormal = (params?: any) => {
    // 跳到库存异常
    openNewPage({
        key: "WAREHOUSE_MANAGEMENT_INVENTORY_ABNORMAL",
        ...params
    });
};

export const toClearance = (params?: any) => {
    // 跳到报关管理
    openNewPage({
        key: "BUSINESS_MANAGEMENT_CLEARANCE_LIST",
        ...params
    });
};
export const toStowageCenter = (params?: any) => {
    // 跳到配载中心
    openNewPage({
        key: "STOWAGE_CENTER",
        ...params
    });
};

export const toPayableList = (params?: any) => {
    // 跳到应付对账明细
    openNewPage({
        key: "PAYABLE_MANAGEMENT_PAYABLE",
        ...params
    });
};

export const toPayableDetail = (params?: any) => {
    // 跳到应付对账明细
    openNewPage({
        key: "FINANCIAL_MANAGEMENT_ACCOUNT_PAYABLE_DETAILS_NEWS",
        navInfo: {
            show: true,
            parentPageKey: "PAYABLE_MANAGEMENT_PAYABLE"
        },
        ...params
    });
};

export const toReceivableDetail = (params?: any) => {
    openNewPage({
        key: "RECEIVABLE_MANAGEMENT_RECEIVABLE_STATEMENT_DETAIL",
        navInfo: {
            show: true,
            parentPageKey: "RECEIVABLE_MANAGEMENT_RECEIVABLE"
        },
        ...params
    });
};

export const toReceivableDetails = (params?: any) => {
    openNewPage({
        key: "RECEIVABLE_MANAGEMENT_RECEIVABLE_DETAILS",
        ...params
    });
};

export const toReceiveSettlement = (params?: any) => {
    //跳到应收结算详情
    openNewPage({
        key: "RECEIVABLE_SETTLEMENT_MAINTENANCE_ADD",
        ...params
    });
};
