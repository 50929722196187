const prevUrl = "finance/payableEstimate";

export const id = {
    id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE",
    method: "POST",
    name: "应付预估",
    type: "menu",
    apiName: "getPayableEstimateList",
    url: `${prevUrl}/list`
};

export const children = {
    ADD_DATA: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_ADD_DATA",
        apiName: "addPayableEstimate",
        method: "POST",
        name: "生成预估单(特殊业务)",
        type: "view",
        url: `${prevUrl}/save`
    },
    DELETE_DATA: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_DELETE_DATA",
        apiName: "deletePayableEstimate",
        method: "POST",
        name: "删除预估单",
        type: "view",
        url: `${prevUrl}/delete`
    },
    EDIT_DATA: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_EDIT_DATA",
        apiName: "editPayableEstimate",
        method: "POST",
        name: "编辑月份",
        type: "view",
        url: `${prevUrl}/edit`
    },
    LOOK_DATA: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_LOOK_DATA",
        name: "查看",
        type: "view"
    },
    SPEC_LOOK: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_SPEC_LOOK",
        name: "特殊业务-查看",
        type: "view"
    },
    LOOK_DETAILS: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_LOOK_DETAILS",
        name: "预估单明细",
        type: "view"
    },
    SPEC_DETAILS: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_SPEC_DETAILS",
        name: "特殊业务-明细",
        type: "view"
    },
    SPEC_DELETE: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_SPEC_DELETE",
        name: "特殊业务-删除",
        type: "view"
    },
    EXPORT_LIST: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_EXPORT_LIST",
        apiName: "exportPayableEstimateList",
        method: "POSTFILE",
        name: "应付预估导出(汇总表)",
        type: "view",
        url: `${prevUrl}/exportPayableEstimateList`
    },
    EXPORT_DETAILS: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE_EXPORT_DETAILS",
        apiName: "exportEstimatesPayableDetails",
        method: "GETFILE",
        name: "应付预估导出(明细表)",
        type: "view",
        url: `${prevUrl}/exportEstimatesPayableDetails`
    },
    AUTO_GENERATE: {
        id: "FINANCIAL_MANAGEMENT_CHARGE_PAYABLE__AUTO_GENERATE",
        apiName: "autoPayableGenerate",
        method: "GET",
        name: "生成预估单",
        type: "view",
        url: `${prevUrl}/autoGenerate`
    }
};
