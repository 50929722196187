import React from "react";
import Modular from "@src/components/modular_window";
import { Form, Input, message, Button, Spin } from "antd";
import rApi from "@src/http";
import { SelectAddressNew } from "@src/components/select_address";
import { addressFormat, addressToJson } from "@src/utils";
import RemoteSelect from "@src/components/select_databook";
import FormItem from "@src/components/FormItem";
import { Row, Col } from "@src/components/grid";
import _ from "@src/utils/lodash";
const ModularParent = Modular.ModularParent;
const labelStyle = { width: "72px", textAlign: "right", paddingRight: 5 };
class AddOrEdit extends ModularParent {
    state = {
        loading: false,
        openType: null,
        title: null,
        open: false,
        edit: false,
        address: null, //地址
        addressdata: {}, // 地址信息
        areaName: null, //片区名
        areaId: null, //片区名
        clientName: null,
        clientId: null,
        code: null, //发货方代码
        name: null, //发货方名称
        contactName: null, //联系人名字
        contactNumber: null, //联系电话
        id: null,
        status: 0, //状态（-1-删除 1-正常）
        buttonLoading: false,
        historyData: null, // 传入数据
        continueReload: false,
        reloadAddress: false,
        remark: null
    };
    tempSelectAddress = null; // 临时地址

    constructor(props) {
        super(props);
        if (props.getThis) {
            props.getThis(this);
        }
    }

    changeOpen = (state) => {
        if (!state) {
            this.clearValue();
        }
        this.setState({
            open: state
        });
    };

    actionDone = () => {
        const { parent } = this.props;
        if (parent?.searchCriteria) {
            parent.searchCriteria();
        }
        this.changeOpen(false);
        message.success("操作成功！");
    };

    getData = (info) => {
        const { openType, edit, title } = info;
        // 临时地址
        if (openType === 3) {
            this.setState({ openType, edit, title });
            return;
        }
        const { id } = info;
        this.setState({ reloadAddress: true, loading: true });
        rApi["consignee_getOne"]({ id })
            .then((res) => {
                this.setState({ ...res, openType, edit, title, addressdata: addressToJson(res) });
            })
            .catch((err) => {
                message.warn((err && err.msg) || "系统错误，请联系管理员");
            })
            .finally(() => {
                this.setState({ reloadAddress: false, loading: false });
            });
    };

    continueDone = () => {
        const { parent } = this.props;
        if (parent?.searchCriteria) {
            parent.searchCriteria();
        }
        this.continueClearValue();
        message.success("操作成功！");
    };

    show = (d) => {
        let historyData = typeof d.data === "object" ? _.cloneDeep(d.data) : null;
        // 通过接口获取数据
        if (d.detailInfo) {
            d.detailInfo.request !== false && this.getData(d.detailInfo);
            this.setState({ open: true });
        } else {
            if (d.edit) {
                d.data = Object.assign({}, d.data, {
                    openType: 1,
                    title: "编辑收货方",
                    addressdata: addressToJson(d.data)
                });
            } else if (d.data) {
                d.data = Object.assign({}, d.data, {
                    openType: 2,
                    title: "查看收货方",
                    addressdata: addressToJson(d.data)
                });
            } else {
                this.setState({
                    openType: 3,
                    title: "新建收货方"
                });
                //coconsole.log('新建')
            }

            this.setState({
                ...d.data,
                historyData: historyData,
                open: true,
                edit: d.edit
            });
        }
    };

    continueClearValue() {
        const { setFieldsValue } = this.props.form;
        this.setState(
            {
                loading: false,
                openType: null,
                title: null,
                open: false,
                edit: false,
                address: null, //地址
                addressdata: {}, // 地址信息
                areaName: null, //片区名
                areaId: null, //片区名
                clientName: null,
                clientId: null,
                code: null, //发货方代码
                name: null, //发货方名称
                contactName: null, //联系人名字
                contactNumber: null, //联系电话
                id: null,
                status: 0, //状态（-1-删除 1-正常）
                buttonLoading: false,
                historyData: null, // 传入数据
                continueReload: false,
                reloadAddress: false,
                remark: null
            },
            () => {
                this.setState({
                    continueReload: false
                });
            }
        );
    }

    clearValue() {
        // feat 422 编辑状态 根据 props 传入的 clientId/clientName 绑定 state 后续直接保存在组件内部
        this.setState({
            openType: null,
            title: null,
            open: false,
            edit: false,
            address: null, //地址
            addressdata: {}, // 地址信息
            areaName: null, //片区名
            areaId: null, //片区名
            clientName: null,
            clientId: null,
            code: null, //发货方代码
            name: null, //发货方名称
            contactName: null, //联系人名字
            contactNumber: null, //联系电话
            id: null,
            status: 0, //状态（-1-删除 1-正常）
            buttonLoading: false,
            historyData: null, // 传入数据
            continueReload: false,
            reloadAddress: false,
            remark: null
        });
    }

    continueAdd = () => {
        //继续添加
        this.onSubmit(1);
    };

    onSubmit = (flag) => {
        const { form } = this.props;
        if (this.props.selectParams) {
            // feat 422 临时地址
            const { showDetailType } = this.props.selectParams;
            // console.log('tabIdx', tabIdx);
            if (showDetailType === 3) {
                this.tempHandleSubmit();
                return;
            }
        }
        form.validateFields((errors, values) => {
            if (errors === null) {
                this.handleSubmit(flag);
            }
        });
    };

    onCancel = () => {
        // feat 422 临时地址
        if (this.props.modalCancel) {
            this.props.modalCancel();
        }
        this.changeOpen(false);
    };

    handleSubmit = (flag) => {
        let addr = this.selectAddress.getValue();
        // console.log('addr',addr)
        if (!addr || !addr.pro || !addr.city || !addr.dist) {
            message.error("请选择省市区!");
            return;
        }
        this.setState({
            buttonLoading: true
        });
        this.requestApi(flag);
    };

    requestApi = (flag) => {
        // 请求
        let {
            areaName, //片区名
            areaId, //片区名
            code, //发货方代码
            contactName, //联系人名字
            contactNumber, //联系电话
            remark,
            name,
            clientName,
            clientId,
            id,
            receiverOrSenderId
        } = this.state;

        let addr = this.selectAddress.getValue();
        let requestData = {
            address: addr?.formatAddress ?? null,
            extra: addr?.extra ?? null,
            pro: addr?.pro ?? null,
            city: addr?.city ?? null,
            dist: addr?.dist ?? null,
            street: addr?.street ?? null,
            areaName,
            areaId,
            code,
            contactName,
            contactNumber,
            name,
            remark,
            clientName,
            clientId
        };

        if (this.state.openType === 1) {
            rApi.editConsignee({
                id,
                receiverOrSenderId,
                ...requestData
            })
                .then((d) => {
                    message.success("操作成功!");
                    this.changeOpen(false);
                    this.setState(
                        {
                            buttonLoading: false
                        },
                        () => {
                            this.updateThisDataToTable({
                                address: addr, //地址
                                ...requestData
                            });
                            const { thirdEditSuccess } = this.props;
                            if (thirdEditSuccess) {
                                thirdEditSuccess({ ...d });
                            }
                        }
                    );
                })
                .catch((e) => {
                    message.error(e.msg || "操作失败！");
                    this.setState({
                        buttonLoading: false
                    });
                });
        } else if (this.state.openType === 3) {
            rApi.addConsignee({
                ...requestData
            })
                .then((d) => {
                    if (flag) {
                        this.continueDone();
                    } else {
                        this.actionDone();
                    }
                    const { thirdEditSuccess } = this.props;
                    if (thirdEditSuccess) {
                        thirdEditSuccess({ ...d });
                    }
                    this.setState({
                        buttonLoading: false
                    });
                })
                .catch((e) => {
                    message.error(e.msg || "操作失败！");
                    this.setState({
                        buttonLoading: false
                    });
                });
        }
    };

    /**
     * status = 1 // {status: this.state.status}
     *
     * @memberof AddOrEdit
     */
    updateThisDataToTable = (d) => {
        let { historyData } = this.state;
        const { parent } = this.props;
        if (!historyData) return;
        if (parent && parent.tableView) {
            parent.tableView.updateData({ ...historyData, ...d });
            // console.log('parent', parent.getTableSource())
        }
    };

    getSelectAddress = (d) => {
        this.selectAddress = d;
    };

    onChangeProvince = (value) => {
        if (value && value.id) {
            rApi.getAreaByAddress(value).then((res) => {
                // console.log('area',res)

                this.setState({
                    areaName: res.map((item) => item.title).join(","),
                    areaId: res.map((item) => item.id).join(",")
                });
            });
        } else {
            this.setState({ areaName: "", areaId: null });
        }
    };

    getValueForChild = (value) => {
        //获取地址onchang值

        this.setState({
            addressdata: value
        });
    };

    renderForm = () => {
        let {
            openType,
            addressdata, // 地址信息
            address, // 地址信息
            areaName, //片区名
            areaId, //片区名
            code, //发货方代码
            name, //发货方名称
            contactName, //联系人名字
            contactNumber, //联系电话
            continueReload,
            reloadAddress,
            clientName,
            clientId,
            remark
        } = this.state;

        // console.log("货方地址 addressdata", addressdata);
        const { getFieldDecorator } = this.props.form;
        <Spin></Spin>;
        return (
            <Form>
                <div style={{ padding: "10px 20px" }}>
                    <Row gutter={24} type={openType}>
                        <Col label="货方编码" isRequired colon span={7} text={code} labelStyle={labelStyle}>
                            <FormItem>
                                {getFieldDecorator("code", {
                                    initialValue: code,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请填写货方编码"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder=""
                                        onChange={(e) => {
                                            this.setState({ code: e.target.value });
                                        }}
                                        size={window._size}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col label="货方名称" colon span={8} isRequired text={name} labelStyle={labelStyle}>
                            <FormItem>
                                {getFieldDecorator("name", {
                                    initialValue: name,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请填写货方名称"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder=""
                                        onChange={(e) => {
                                            this.setState({ name: e.target.value });
                                        }}
                                        size={window._size}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col
                            label="关联客户"
                            colon
                            span={7}
                            isRequired
                            text={name}
                            labelStyle={labelStyle}
                            isRequired
                            text={clientName}
                        >
                            <FormItem>
                                {getFieldDecorator("clientId", {
                                    initialValue: clientId ? { id: clientId, shortname: clientName } : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请选择客户"
                                        }
                                    ]
                                })(
                                    <RemoteSelect
                                        def={clientId ? { id: clientId, shortname: clientName } : null}
                                        onChangeValue={(e) => {
                                            this.setState({
                                                clientId: (e && e.id) || null,
                                                clientName: (e && e.shortname) || null
                                            });
                                        }}
                                        getDataMethod={"getClients"}
                                        params={{ limit: 20, offset: 0 }}
                                        labelField={"shortname"}
                                        isAsyncSearch
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24} type={openType}>
                        <Col label="联系人" colon span={7} text={contactName} labelStyle={labelStyle}>
                            <Input
                                value={contactName ? contactName : ""}
                                placeholder=""
                                size={window._size}
                                onChange={(e) => {
                                    this.setState({ contactName: e.target.value });
                                }}
                            />
                        </Col>
                        <Col label="联系电话" colon span={8} text={contactNumber} labelStyle={labelStyle}>
                            <Input
                                value={contactNumber ? contactNumber : ""}
                                size={window._size}
                                placeholder=""
                                onChange={(e) => {
                                    this.setState({ contactNumber: e.target.value });
                                }}
                            />
                        </Col>
                        <Col span={7} />
                    </Row>
                    <Row gutter={24} type={openType}>
                        <Col
                            span={16}
                            isRequired
                            label="货方地址"
                            colon
                            text={addressdata ? addressFormat(addressdata) : ""}
                            labelStyle={labelStyle}
                        >
                            {continueReload || reloadAddress ? null : (
                                <SelectAddressNew
                                    //getPopupContainer={() => this.popupContainer || document.body}
                                    isAbroad={this.state.domesticAndAbroad === 2 ? true : false}
                                    getValueForChild={this.getValueForChild}
                                    onChangeProvince={this.onChangeProvince}
                                    address={addressdata ? addressdata : {}}
                                    getThis={this.getSelectAddress}
                                    title={addressdata ? addressFormat(addressdata) : "无"}
                                />
                            )}
                        </Col>
                        <Col label="所属片区" colon span={7} text={areaName} labelStyle={labelStyle}>
                            <Input
                                disabled
                                placeholder="自动识别"
                                value={areaName}
                                title={areaName}
                                size={window._size}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col label="备注" colon span={16} labelStyle={labelStyle} text={remark}>
                            <Input.TextArea
                                rows={3}
                                value={remark}
                                onChange={(e) => {
                                    this.setState({ remark: e.target.value });
                                }}
                            ></Input.TextArea>
                        </Col>
                    </Row>
                </div>
            </Form>
        );
    };

    render() {
        let { openType, open, buttonLoading, loading } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { renderContainer } = this.props;
        if (!open) return null;
        return (
            <Modular
                changeOpen={this.changeOpen}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                open={this.state.open}
                title={this.state.title}
                style={{ width: "100%", maxWidth: 850 }}
                loading={buttonLoading}
                haveFooter={openType === 2 ? false : true}
                customButton={
                    openType === 3 && !this.props.renderTabs ? (
                        <Button size={window._size} onClick={this.continueAdd}>
                            继续添加
                        </Button>
                    ) : (
                        ""
                    )
                }
                renderContainer={renderContainer}
                // getContentDom={v => this.popupContainer = v}
                // isHaveRenderBody
            >
                <Spin spinning={loading}>{this.renderForm()}</Spin>
            </Modular>
        );
    }
}

export default Form.create()(AddOrEdit);
