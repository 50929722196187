import rApi from "@src/http";
import { IDvaModel } from "../models";
import * as power from "@src/views/warehouse_management/inventory/check/power";
import * as detailsPower from "@src/views/warehouse_management/inventory/check_details/power";
import { message } from "antd";

export type nullOr<T> = T | null;

export interface ICheckItem {
    id: nullOr<number>;
    checkNumber: nullOr<string>; // 盘点单号
    checkTypeId: nullOr<number>; // 盘点方式id
    checkTypeName: nullOr<string>;
    estimateCheckTime: nullOr<string>; // 预计盘点时间
    checkDuration: nullOr<number>; // 盘点时长
    sourceNumber: nullOr<string>; // 来源单号
    warehouseId: nullOr<number>; // 仓库id
    warehouseName: nullOr<string>;
    clientId: nullOr<number>; // 客户id
    clientName: nullOr<string>;
    supplier: nullOr<string>; // 供应商
    materialNumber: nullOr<string>; // 料号
    materialName: nullOr<string>; // 物料名称
    batchNumber: nullOr<string>; // 批次号
    quality: nullOr<number>; // 品质
    remark: nullOr<string>; // 备注
    warehousePlaceIds: string[];
    status: number;
}

export type treeItem = {
    code: string;
    id: number;
    type: number;
    children: treeItem[];
};
export interface IWmsCheckState extends ICheckItem {
    treeList: treeItem[];
    checkedKeys: string[];
    detailsData: ICheckItem;
    detailsList: any[];
}

export function emptyCheckItem(): ICheckItem {
    return {
        id: null,
        checkNumber: null, // 盘点单号
        checkTypeId: null, // 盘点方式id
        checkTypeName: null,
        estimateCheckTime: null, // 预计盘点时间
        checkDuration: null, // 盘点时长
        sourceNumber: null, // 来源单号
        warehouseId: null, // 仓库id
        warehouseName: null,
        clientId: null, // 客户id
        clientName: null,
        supplier: null, // 供应商
        materialNumber: null, // 料号
        materialName: null, // 物料名称
        batchNumber: null, // 批次号
        quality: null, // 品质
        remark: null, // 备注
        warehousePlaceIds: [],
        status: 1
    };
}

function formatRes(resArr: any[]): treeItem[] {
    const fn = (arr: any[], type: number) => {
        return arr.map((item) => {
            item.children = [];
            if (item.childrenAreaList && !!item.childrenAreaList.length) {
                item.children = fn(item.childrenAreaList, 1);
            } else if (item.warehousePlaceList && !!item.warehousePlaceList.length) {
                item.children = fn(item.warehousePlaceList, 2);
            }
            return {
                id: item.id,
                type,
                code: item.code,
                children: item.children
            };
        });
    };
    return fn(resArr, 1);
}

// 盘点管理
const model: IDvaModel<IWmsCheckState> = {
    namespace: "wmsCheck",
    state: {
        treeList: [],
        checkedKeys: [],
        detailsData: emptyCheckItem(),
        ...emptyCheckItem(),
        detailsList: []
    },
    effects: {
        // 新建保存盘点
        *saveCheck({ payload }, { call, select }) {
            try {
                const detailsData = yield select(({ wmsCheck }) => wmsCheck.detailsData);
                if (detailsData && !!detailsData.id) {
                    payload.id = detailsData.id;
                }
                yield call(rApi[power.children.SAVE.apiName], payload);
                message.success("操作成功");
            } catch (error) {
                message.error(error.msg || "操作失败");
                throw error;
            }
        },
        *getWarehouseArea({ payload }, { call, put, select }) {
            try {
                const warehouseId = yield select(({ wmsCheck }) => wmsCheck.warehouseId);
                const ret = yield call(rApi["getWarehouseArea"], { id: warehouseId });
                const treeList: treeItem[] = formatRes(ret || []);
                // console.log('getWarehouseArea', ret, treeList)
                yield put({
                    type: "updateState",
                    payload: {
                        treeList
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        *getDetailsById({ payload }, { call, put, select }) {
            try {
                const ret = yield call(rApi[detailsPower.id.apiName], payload);
                const d = ret || {};
                const state = emptyCheckItem();
                for (const key in d) {
                    if (d.hasOwnProperty(key)) {
                        if (key in state) {
                            state[key] = d[key];
                        }
                    }
                }
                // console.log('getDetailsById', ret)
                yield put({
                    type: "updateState",
                    payload: {
                        detailsData: state
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },

        *getDetailsList({ payload }, { call, put, select }) {
            try {
                const detailsData = yield select(({ wmsCheck }) => wmsCheck.detailsData);
                const ret = yield call(rApi[detailsPower.children.LIST.apiName], detailsData.id);
                const detailsList = (ret || []).map((item) => ({
                    ...item,
                    inAdjust: false
                }));
                yield put({
                    type: "updateState",
                    payload: {
                        detailsList
                    }
                });
            } catch (error) {
                console.log("getData", error);
                yield put({
                    type: "updateState",
                    payload: {
                        detailsList: []
                    }
                });
            }
        },

        *changeConfirm({ payload }, { call, put, select }) {
            try {
                const detailsData = yield select(({ wmsCheck }) => wmsCheck.detailsData);
                const api =
                    detailsData.status === 1
                        ? detailsPower.children.CONFIRM.apiName
                        : detailsPower.children.UN_CONFIRM.apiName;
                yield call(rApi[api], detailsData.id);
                const status = detailsData.status === 1 ? 2 : 1;
                yield put({
                    type: "updateState",
                    payload: {
                        detailsData: {
                            ...detailsData,
                            status
                        }
                    }
                });
                message.success("操作成功");
            } catch (error) {
                console.log(error);
                message.error(error.msg || "操作失败");
            }
        },

        // 启动盘点
        *startCheck({ payload }, { call, put, select }) {
            try {
                const detailsData = yield select(({ wmsCheck }) => wmsCheck.detailsData);
                yield call(rApi[detailsPower.children.START.apiName], detailsData.id);
                yield put({
                    type: "updateState",
                    payload: {
                        detailsData: {
                            ...detailsData,
                            status: 3
                        }
                    }
                });
                message.success("操作成功");
            } catch (error) {
                console.log(error);
                message.error(error.msg || "操作失败");
            }
        },

        // 盘点完成
        *completeCheck({ payload }, { call, put, select }) {
            try {
                const detailsData = yield select(({ wmsCheck }) => wmsCheck.detailsData);
                yield call(rApi[detailsPower.children.COMPLETE.apiName], detailsData.id);
                yield put({
                    type: "updateState",
                    payload: {
                        detailsData: {
                            ...detailsData,
                            status: 4
                        }
                    }
                });
                message.success("操作成功");
            } catch (error) {
                console.log(error);
                message.error(error.msg || "操作失败");
            }
        },

        // 盘点复审
        *reviewCheck({ payload }, { call, put, select }) {
            try {
                const detailsData = yield select(({ wmsCheck }) => wmsCheck.detailsData);
                yield call(rApi[detailsPower.children.REVIEW.apiName], detailsData.id);
                yield put({
                    type: "updateState",
                    payload: {
                        detailsData: {
                            ...detailsData,
                            status: 5
                        }
                    }
                });
                message.success("操作成功");
            } catch (error) {
                console.log(error);
                message.error(error.msg || "操作失败");
            }
        },

        // 保存盘点
        *saveCheckList({ payload }, { call, put, select }) {
            try {
                const { detailsData, detailsList } = yield select(({ wmsCheck }) => ({
                    detailsData: wmsCheck.detailsData,
                    detailsList: wmsCheck.detailsList
                }));
                yield call(
                    rApi[detailsPower.children.SAVE_COUNT.apiName],
                    detailsList.map((item) => ({
                        checkManageId: detailsData.id,
                        checkQuantity: item.checkQuantity,
                        id: item.checkListId
                    }))
                );
                message.success("操作成功");
            } catch (error) {
                console.log(error);
                message.error(error.msg || "操作失败");
            }
        },

        // 创建复盘
        *replayCheck({ payload }, { call, put, select }) {
            try {
                const { detailsList, detailsData } = yield select(({ wmsCheck }) => ({
                    detailsData: wmsCheck.detailsData,
                    detailsList: wmsCheck.detailsList
                }));
                yield call(rApi[detailsPower.children.REPLAY.apiName], { id: detailsData.id, ids: payload });
                const newList = detailsList.map((item) => {
                    item.isReplay = payload.some((id) => id === item.checkListId) ? 1 : item.isReplay;
                    return item;
                });
                yield put({
                    type: "updateState",
                    payload: {
                        detailsList: newList
                    }
                });
                message.success("操作成功");
            } catch (error) {
                console.log(error);
                message.error(error.msg || "操作失败");
            }
        },

        // 保存盈亏
        *adjustCheck({ payload }, { call, put, select }) {
            try {
                const detailsList = yield select(({ wmsCheck }) => wmsCheck.detailsList);
                yield call(rApi[detailsPower.children.ADJUST.apiName], payload);
                const newList = detailsList.map((item) => {
                    const def = (item.checkQuantity || 0) - (item.mainQuantity || 0);
                    payload.forEach((p) => {
                        if (p.id === item.checkListId) {
                            item.profitLossQuantity = p.profitLossQuantity !== null ? p.profitLossQuantity : def;
                            item.inAdjust = false;
                        }
                    });
                    return item;
                });
                yield put({
                    type: "getDetailsList"
                });
                yield put({
                    type: "updateState",
                    payload: {
                        detailsList: newList
                    }
                });
                message.success("操作成功");
            } catch (error) {
                console.log(error);
                message.error(error.msg || "操作失败");
                throw error;
            }
        },

        // 确认盈亏
        *adjustConfirmCheck({ payload }, { call, put, select }) {
            try {
                const { detailsList, detailsData } = yield select(({ wmsCheck }) => ({
                    detailsData: wmsCheck.detailsData,
                    detailsList: wmsCheck.detailsList
                }));
                yield call(rApi[detailsPower.children.ADJUST_CONFIRM.apiName], { id: detailsData.id, ids: payload });
                const newList = detailsList.map((item) => {
                    item.isAdjustInventory = payload.some((id) => id === item.checkListId) ? 1 : item.isAdjustInventory;
                    return item;
                });
                yield put({
                    type: "updateState",
                    payload: {
                        detailsList: newList
                    }
                });
                message.success("操作成功");
            } catch (error) {
                console.log(error);
                message.error(error.msg || "操作失败");
            }
        },

        // 任务完结
        *finishCheck({ payload }, { call, put, select }) {
            try {
                const detailsData = yield select(({ wmsCheck }) => wmsCheck.detailsData);
                yield call(rApi[detailsPower.children.FINISH.apiName], detailsData.id);
                yield put({
                    type: "updateState",
                    payload: {
                        detailsData: {
                            ...detailsData,
                            status: 6
                        }
                    }
                });
                message.success("操作成功");
            } catch (error) {
                console.log(error);
                message.error(error.msg || "操作失败");
            }
        }
    },
    reducers: {
        updateState(state: IWmsCheckState, { payload }: any) {
            return {
                ...state,
                ...payload
            };
        },
        updateDetailsList(state: IWmsCheckState, { payload }: any) {
            return {
                ...state,
                detailsList: state.detailsList.map((item) => item)
            };
        }
    }
};
export default model;
