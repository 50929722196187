const m = "wms/inventoryFreeze";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_INVENTORY_FROZEN",
    method: "POST",
    name: "冻结管理",
    type: "menu",
    apiName: "getInventoryFrozen",
    url: `${m}/freezeList`
};

export const children = {
    CANCEL: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_FROZEN_CANCEL",
        name: "取消冻结",
        type: "view",
        apiName: "cancelInventoryFrozen",
        method: "POST",
        url: `${m}/unfreeze`
    }
};
