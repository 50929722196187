import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};
const moduleName = "/finance/payableReconciliationMain/";
export default class payable_detail {
    payableDetailExport(id) {
        return this.GETFILE(`${moduleName}exportPayableReconciliationMain?id=${id}`);
    }
}
