const prevUrl = "finance/benefit";

export const id = {
    id: "FINANCIAL_MANAGEMENT_BENEFIT",
    method: "POST",
    name: "经营效益",
    type: "menu",
    apiName: "getBenefitiSummary",
    url: `${prevUrl}/summary`
};

export const children = {
    EXPORT_ALL: {
        id: "FINANCIAL_MANAGEMENT_BENEFIT_EXPORT_ALL",
        apiName: "exportBenefitSummary",
        method: "POSTFILE",
        name: "总表导出",
        type: "view",
        url: `${prevUrl}/exportSummary`
    },
    EXPORT_SUB: {
        id: "FINANCIAL_MANAGEMENT_BENEFIT_EXPORT_SUB",
        apiName: "exportBenefitDetail",
        method: "POSTFILE",
        name: "分表导出",
        type: "view",
        url: `${prevUrl}/exportDetail`
    },
    SEARCH: {
        id: "FINANCIAL_MANAGEMENT_BENEFIT_SEARCH",
        name: "查询",
        type: "view"
    }
};
