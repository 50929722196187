import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class StatisticsApi {
    /**
     *
     * @param {any} params
     * @returns
     * @memberOf StatisticsApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [power.ADD_DATA.apiName](params) {
        let url = power.ADD_DATA.url;
        return this.POST(url, params);
    }

    // 生成费用单选择报价
    getStatisticsWarehouseQuotation(params) {
        let url = `wms/statistics/warehouseQuotation/${params.projectId}/${params.warehouseId}`;
        return this.GET(url, params);
    }
}
