const moduleName = "finance/receivableReconciliation";
export const id = {
    id: "FINANCIAL_MANAGEMENT_RECEIVABLES_TRACKING",
    method: "POST",
    name: "应收款跟踪",
    type: "menu",
    apiName: "receivableTrackList",
    url: `${moduleName}/receivableTrackList`
};

export const children = {
    ADD_DATA: {
        id: "FINANCIAL_MANAGEMENT_RECEIVABLES_TRACKING_ADD_DATA",
        // apiName: 'saveOrUpdate',
        // method: 'POST',
        name: "新增",
        type: "view"
        // url: `${moduleName}/saveOrUpdate`
    },
    DEL_DATA: {
        id: "FINANCIAL_MANAGEMENT_RECEIVABLES_TRACKING_DEL_DATA",
        // apiName: 'deleteBilling',
        // method: 'GET',
        name: "删除",
        type: "view"
        //  url: `${moduleName}/deleteBilling`
    },
    COLLECT_MAINTENANCE: {
        id: "FINANCIAL_MANAGEMENT_RECEIVABLES_TRACKING_COLLECT_MAINTENANCE",
        //apiName: 'addCar',
        //method: 'POST',
        name: "收款维护",
        type: "view"
        //url: `${moduleName}car/save`
    },
    QUICK_BILLING: {
        id: "FINANCIAL_MANAGEMENT_RECEIVABLES_TRACKING_QUICK_BILLING",
        //apiName: 'addCar',
        //method: 'POST',
        name: "快速开票",
        type: "view"
        //url: `${moduleName}car/save`
    },
    WRITE_OFF: {
        id: "FINANCIAL_MANAGEMENT_RECEIVABLES_TRACKING_WRITE_OFF",
        // apiName: 'writeOff',
        // method: 'GET',
        name: "完成核销",
        type: "view"
        // url: `${moduleName}/writeOff`
    }
    // LOOK_MORE: {
    //     id: 'FINANCIAL_MANAGEMENT_RECEIVABLES_TRACKING_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
