import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class OrderApi {
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return new Promise((resolve, reject) => {
            this.POST(url, deleteNull(params))
                .then((data) => {
                    data.records = data.records.map((item) => {
                        if (item.orderSplitList && item.orderSplitList.length > 0) {
                            return {
                                ...item
                                // children: item.orderSplitList
                            };
                        } else {
                            return item;
                        }
                    });
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    [children.DEL_DATA.apiName](params) {
        let url = children.DEL_DATA.url;
        return this.POST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url + `/1`;
        return this.POST(url, params);
    }

    [children.IMPORT_ORDER.apiName](params) {
        let url = children.IMPORT_ORDER.url;
        return this.POST(url, params);
    }
    [children.SIGN_DATA.apiName](params) {
        let url = `${children.SIGN_DATA.url}?id=${params.id}`;
        return this.POST(url, params);
    }

    [children.ORDER_EXPORT.apiName](params) {
        let url = children.ORDER_EXPORT.url;
        return this.POSTFILE(url, params);
    }

    [children.EXPORT_TEMP.apiName](params) {
        let url = children.EXPORT_TEMP.url;
        return this.GETFILE(url, params);
    }

    [children.EXPORT_BUSINESS_CONTACT.apiName](params) {
        let url = children.EXPORT_BUSINESS_CONTACT.url;
        return this.GETFILE(url, params);
    }

    [children.FREEZE_DATA.apiName](ids) {
        let url = children.FREEZE_DATA.url;
        return this.PUT(url, ids);
    }

    [children.UNFREEZE_DATA.apiName](ids) {
        let url = children.UNFREEZE_DATA.url;
        return this.PUT(url, ids);
    }
    [children.CLOSE_DATA.apiName](ids) {
        let url = children.CLOSE_DATA.url;
        return this.PUT(url, ids);
    }

    [children.UNCLOSED_DATA.apiName](ids) {
        let url = children.UNCLOSED_DATA.url;
        return this.PUT(url, ids);
    }

    getCarrierQuotationQuery(params) {
        let url = "resource/carrierQuotation/getCarrierQuotationRow";
        return this.POST(url, params);
    }

    // 批量签收
    orderBatchSign(params) {
        let url = "order/order/batchSign";
        return this.POST(url, deleteNull(params));
    }

    // 批量回单确认
    orderBatchReceiptConfirm(params) {
        let url = "order/order/batchReceiptConfirm";
        return this.POST(url, deleteNull(params));
    }

    filterOrderIdGetCarType(params) {
        //根据发货单id[]获取车型列表
        let url = "order/order/getCarType";
        return this.POST(url, Object.keys(params).map((item) => params[item])[0]);
    }

    directSendCarOrder(params) {
        //直接派车
        let url = "order/sendCar/directSendCar";
        return this.POST(url, params);
    }

    getNoStowageRoute(params) {
        //查询指定发货单的未配载路线
        let url = "order/order/getNoStowageRoute";
        return this.POST(url + "/" + params.orderId + "/" + params.orderType, params);
    }

    getOrderStageNum(params) {
        //获取发货单各阶段数量
        let url = "order/order/statistics";
        return this.GET(url, params);
    }

    getTransportData(params) {
        //获取中转地和仓库
        let url = "resource/node/getNodeAndWarehouse";
        return this.POST(url, params);
    }

    stowageAndConfirmOrder(params) {
        //配载并确认
        let url = "order/stowage/saveAndConfirm";
        return this.POST(url, params);
    }

    addTemporaryCar(params) {
        //新建临时车辆
        let url = "resource/car/temporary/create";
        return this.SPOST(url, params);
    }

    addTemporaryDriver(params) {
        //新建临时司机
        let url = "resource/driver/temporary/create";
        return this.SPOST(url, params);
    }

    getOrderReply(params) {
        //获取发货单回单上传附件列表
        let url = `order/order/getAttachment/${params.id}/${params.orderType}`;
        return this.GET(url, params);
    }

    orderReplyUpload(params) {
        //回单上传
        let url = "order/order/upload";
        return this.POST(url, params);
    }

    delOrderReceipt(params) {
        //删除单条回单
        let url = `order/order/deleteAttachment/${params.id}`;
        return this.GET(url, params);
    }

    editOrderReceipt(params) {
        //编辑回单
        let url = "order/order/editAttachment";
        return this.POST(url, params);
    }

    orderReceiptConfirm(params) {
        //回单确认
        let url = `order/order/receiptConfirm/${params.id}/${params.orderType}`;
        return this.GET(url, params);
    }

    orderDeviceUntied(params) {
        //解除绑定设备
        let url = `order/order/untied/${params.id}/${params.orderType}`;
        return this.GET(url, params);
    }

    getStowageDrivers(params) {
        //配载获取司机列表
        let url = `resource/driver/listForDispatch`;
        return this.POST(url, params);
    }

    getCarListDrivers(params) {
        // 配载获取车辆列表
        let url = `resource/car/listForDispatch`;
        return this.POST(url, params);
    }

    importPSOrder(params) {
        const url = "order/order/pusiImport";
        return this.POST(url, params);
    }

    exportPSOrderTemplate() {
        const url = "order/order/pusiExportTemplate";
        return this.GETFILE(url);
    }

    orderFindLog(params) {
        const url = `order/order/findLog/${params.id}`;
        return this.GET(url);
    }

    getPrintDetail(params) {
        const url = `order/order/downloadDetail`;
        return this.POST(url, params);
    }
}
