const m = "wms/scanning";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_QUALITY_INSPECTION_DETAILS",
    name: "质检详情",
    type: "menu_hide"
    // method: 'POST',
    // apiName: 'accountPayableList',
    // url: `order/sendCar/list`
};

export const children = {
    CONFIRM_DATA: {
        id: "WAREHOUSE_MANAGEMENT_QUALITY_INSPECTION_DETAILS_CONFIRM_DATA",
        // apiName: 'confirmReceiptScanning',
        // method: 'POST',
        name: "完成质检",
        type: "view"
        //url: `${m}/confirm`
    },
    SCANNING_SUBMIT: {
        id: "WAREHOUSE_MANAGEMENT_QUALITY_INSPECTION_DETAILS_SCANNING_SUBMIT",
        name: "开始质检",
        type: "view"
    }
};
