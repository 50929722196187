import React from "react";
import { Select, Spin } from "antd";
import rApi from "@src/http";

const { Option } = Select;

const dataKeys = ["records", "list", "clients"];

class CusSelect extends React.Component {
    static defaultProps = {
        keyName: "id",
        handleFocus: null,
        apiName: "",
        reqData: {
            limit: 99999,
            offset: 0,
            pageNo: 1,
            pageSize: 99999
        },
        renderType: 1,
        cusRender: () => {},
        triggerParent: true
    };

    state = {
        loading: false,
        data: []
    };

    // 获得焦点时回调
    onFocus = async () => {
        try {
            const { handleFocus, apiName, reqData } = this.props;
            this.setState({ loading: true });
            let data = [];
            if (handleFocus) {
                data = await handleFocus(rApi);
            } else {
                data = await rApi[apiName](reqData);
                if (!(data instanceof Array)) {
                    for (let i = 0; i < dataKeys.length; i++) {
                        const key = dataKeys[i];
                        if (data[key] instanceof Array) {
                            data = data[key];
                            break;
                        }
                    }
                    if (!(data instanceof Array)) {
                        data = [];
                        throw "数据格式化失败";
                    }
                }
            }
            this.setState({ data });
        } catch (error) {
            console.log(error);
            this.setState({ data: [] });
        }
        this.setState({ loading: false });
    };

    // 选中 option，或 input 的 value 变化（combobox 模式下）时回调
    onChange = async (value, opt) => {
        const { handleChange, keyName, onChange } = this.props;
        const { data } = this.state;
        const target = value ? data[parseInt(value, 10)] : {};
        const val = value ? target[keyName] : null;
        // console.log(keyName, value, val, data, target, data.find(item => item[keyName] === value))
        handleChange(val, target);
        if (onChange) {
            onChange(value, opt);
        }
    };

    // 文本框值变化时回调
    handleFilter = (val, opt) => {
        const { props } = opt;
        // console.log('val', opt, props, val)
        return props.title && props.title.startsWith(val);
    };

    render() {
        const { loading, data } = this.state;
        const { children, keyName, renderType, cusRender, triggerParent, onChange, ...props } = this.props;
        // console.log('props',  this.props)
        return (
            <Select
                loading={loading}
                notFoundContent={
                    loading ? (
                        <span>
                            <Spin size={window._size} />
                        </span>
                    ) : (
                        <span>暂无数据</span>
                    )
                }
                onFocus={this.onFocus}
                onChange={this.onChange}
                filterOption={this.handleFilter}
                showSearch
                allowClear
                size={window._size}
                getPopupContainer={(triggerNode) =>
                    triggerParent ? triggerNode.parentNode : document.querySelector("#scroll-view") || document.body
                }
                style={{ width: 120 }}
                {...props}
            >
                {renderType === 1 && data && data instanceof Array && data.length
                    ? data.map((item, index) => (
                          <Option key={index} title={item[keyName] ? item[keyName] + "" : ""}>
                              {item[keyName]}
                          </Option>
                      ))
                    : null}
                {renderType === 2 && cusRender(data)}
                {renderType === 3 && children}
            </Select>
        );
    }
}

export default CusSelect;
