const m = "order/waybill";
export const id = {
    id: "SUPERVISION_ELECTRONIC_WAYBILL_LIST",
    method: "POST",
    name: "电子运单",
    type: "menu",
    apiName: "getElectronicList",
    url: `${m}/list`
};

export const children = {
    EDIT_DATA: {
        id: "SUPERVISION_ELECTRONIC_WAYBILL_EDIT_DATA",
        apiName: "onSaveElectronic",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${m}/save`
    },
    ON_REPORT: {
        id: "SUPERVISION_ELECTRONIC_WAYBILL_ON_REPORT",
        apiName: "onReportElectronic",
        method: "GET",
        name: "上报",
        type: "view",
        url: `${m}/report`
    },
    DEL_DATA: {
        id: "SUPERVISION_ELECTRONIC_WAYBILL_DEL_DATA",
        apiName: "onDelElectronic",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${m}/delete`
    }
};
