const moduleName = "project/clientQuotation";
export const id = {
    id: "CLIENT_QUOTATION",
    method: "POST",
    name: "客户运输报价",
    type: "menu",
    apiName: "getClientV2Quotation",
    url: `${moduleName}/list`,
    sort: 1
};

export const children = {
    // GET_LIST: {
    //     id: 'CLIENT_QUOTATION_GET_LIST',
    //     apiName: 'getClientV2Quotation',
    //     method: 'POST',
    //     name: '获取数据',
    //     type: 'view',
    //     url: `${moduleName}carrierQuotation/list`
    // },
    ADD_DATA: {
        id: "CLIENT_QUOTATION_ADD_DATA",
        apiName: "addClientV2Quotation",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}/save`
    },
    DEL_DATA: {
        id: "CLIENT_QUOTATION_CODE",
        apiName: "delClientV2Quotation",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}/delete`
    },
    EDIT_DATA: {
        id: "CLIENT_QUOTATION_EDIT_DATA",
        apiName: "editClientV2Quotation",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}/save`
    },
    RE_STATUS: {
        id: "CLIENT_QUOTATION_RE_STATUS",
        apiName: "reStatusClientV2Quotation",
        method: "POST",
        name: "审核",
        type: "view",
        url: `${moduleName}ClientV2Quotation/review`
    },
    BATCH_SAVE: {
        id: "CLIENT_QUOTATION_BATCH_SAVE",
        apiName: "batchSaveClientV2Quotation",
        method: "POST",
        name: "导入",
        type: "view",
        url: `${moduleName}ClientV2Quotation/batchSave`
    },
    DOWNLOAD_TEMPLATE: {
        id: "CLIENT_QUOTATION_DOWNLOAD_TEMPLATE",
        apiName: "downloadTemplateClientV2Quotation",
        method: "GET",
        name: "导出模板",
        type: "view",
        url: `${moduleName}ClientV2Quotation/exportTemp`
    },
    SAVE_QUOTATION_HEADER: {
        id: "CLIENT_QUOTATION_SAVE_QUOTATION_HEADER",
        apiName: "saveQuotationHeaderClientV2Quotation",
        method: "POST",
        name: "保存报价表头",
        type: "view",
        url: `${moduleName}/quotation/header/save`
    },
    GET_QUOTATION_HEADER: {
        id: "CLIENT_QUOTATION_GET_QUOTATION_HEADER",
        apiName: "getQuotationHeaderClientV2Quotation",
        method: "GET",
        name: "获取报价表头",
        type: "view",
        url: `${moduleName}/quotation/header`
    },
    QUOTATION_SAVE: {
        id: "CLIENT_QUOTATION_GET_QUOTATION_SAVE",
        apiName: "saveQuotationClientV2Quotation",
        method: "POST",
        name: "报价保存",
        type: "view",
        url: `${moduleName}/quotation/save`
    },
    GET_QUOTATION_DATA: {
        id: "CLIENT_QUOTATION_GET_QUOTATION_DATA",
        apiName: "getQuotationClientV2Quotation",
        method: "POST",
        name: "获取报价数据",
        type: "view",
        url: `${moduleName}/quotation/data`
        // /quotation/data/{id}
    },
    DELETE_QUOTATION_DATA: {
        id: "CLIENT_QUOTATION_DELETE_QUOTATION_DATA",
        apiName: "deleteQuotationClientV2Quotation",
        method: "POST",
        name: "编辑报价数据",
        type: "view",
        url: `${moduleName}/quotation/delete`
        // /quotation/data/{id}
    },
    EXPORT_QUOTATION_HEADER: {
        id: "CLIENT_QUOTATION_EXPORT_QUOTATION_HEADER",
        apiName: "exportHeaderClientV2Quotation",
        method: "POSTFILE",
        type: "view",
        url: `${moduleName}/exportQuotationHeader`,
        name: "导出报价模板"
        // /quotation/data/{id}
    },
    EXPORT_QUOTATION_DATA: {
        id: "CLIENT_QUOTATION_EXPORT_QUOTATION_DATA",
        apiName: "exportDataClientV2Quotation",
        method: "POSTFILE",
        type: "view",
        url: `${moduleName}/exportQuotationData`,
        name: "导出报价数据"
        // /quotation/data/{id}
    },
    EXAMINE_PASS: {
        id: "CLIENT_QUOTATION_EXAMINE_PASS",
        apiName: "clientQuotationV2ExaminePass",
        method: "GET",
        name: "审核通过",
        type: "view",
        url: `${moduleName}/pass`
    },
    CANCEL_PASS: {
        id: "CLIENT_QUOTATION_CANCEL_PASS",
        apiName: "clientQuotationV2CancelExaminePass",
        method: "GET",
        name: "取消通过",
        type: "view",
        url: `${moduleName}/cancel`
    },
    EXAMINE_REJECT: {
        id: "CLIENT_QUOTATION_EXAMINE_REJECT",
        apiName: "clientQuotationV2ExamineReject",
        method: "POST",
        name: "审核驳回",
        type: "view",
        url: `${moduleName}/reject`
    },
    EXAMINE_SUBMIT: {
        id: "CLIENT_QUOTATION_EXAMINE_SUBMIT",
        apiName: "clientQuotationV2Submit",
        method: "GET",
        name: "提交",
        type: "view",
        url: `${moduleName}/submit`
    },
    INVALID_STATUS: {
        id: "CLIENT_QUOTATION_INVALID_STATUS",
        apiName: "clientQuotationV2Invalid",
        method: "GET",
        name: "作废",
        type: "view",
        url: `${moduleName}/invalid`
    }
    // LOOK_MORE: {
    //     id: 'CLIENT_QUOTATION_INVALID_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
    // RECOVERY_STATUS: {
    //     id: 'CLIENT_QUOTATION_RECOVERY_STATUS',
    //     apiName: 'clientQuotationV2Recovery',
    //     method: 'GET',
    //     name: '恢复',
    //     type: 'view',
    //     url: `${moduleName}/recevory`
    // }
};
