import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class InventoryApi {
    /**
     *
     * @param {any} params
     * @returns
     *
     * @memberOf InventoryApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, params);
    }

    getBusinessType(params) {
        // 获取业务类型
        let url = "resource/enum/getBusinessType";
        return this.GET(url, params);
    }

    getTransportOrganizationType(params) {
        // 获取运输组织类型
        let url = "resource/enum/getTransportOrganizationType";
        return this.GET(url, params);
    }

    getInsuranceAgency(params) {
        // 获取保险公司
        let url = "resource/enum/getInsuranceAgency";
        return this.GET(url, params);
    }

    getOrderWaybillByid(params) {
        // 获取电子运单
        let url = `order/waybill/${params.id}`;
        return this.GET(url, params);
    }

    // [children.ADD_DATA.apiName](params) { // 新增
    //     let url = children.ADD_DATA.url
    //     return this.POST(url, params)
    // }
}
