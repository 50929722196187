const moduleName = "resource/";
export const id = {
    id: "RESOURCE_MANAGEMENT_BASE_CAR",
    method: "POST",
    name: "车辆资源",
    type: "menu",
    apiName: "getCars",
    url: `${moduleName}car/list`,
    sort: 2
};

export const children = {
    ADD_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_ADD_DATA",
        apiName: "addCar",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}car/save`
    },
    DEL_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_CODE",
        apiName: "delCar",
        method: "GET",
        name: "删除",
        type: "view",
        url: `${moduleName}car/delete`
    },
    EDIT_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_EDIT_DATA",
        apiName: "editCar",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}car/edit`
    },
    AUTH_DATA: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_AUTH_DATA",
        apiName: "authCar",
        method: "POST",
        name: "认证",
        type: "view",
        url: `${moduleName}car/auth`
    },
    CANCEL_AUTH: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_CANCEL_AUTH",
        apiName: "cancelAuthCar",
        method: "POST",
        name: "取消认证",
        type: "view",
        url: `${moduleName}car/cancelAuth`
    },
    ENABLE: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_ENABLE",
        apiName: "onCarEnable",
        method: "POST",
        name: "启用",
        type: "view",
        url: `${moduleName}car/enable`
    },
    DISABLE: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_DISABLE",
        apiName: "onCarDisable",
        method: "POST",
        name: "禁用",
        type: "view",
        url: `${moduleName}car/disable`
    },
    REVIEW: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_REVIEW",
        apiName: "onCarReview",
        method: "POST",
        name: "审核",
        type: "view",
        url: `${moduleName}car/reviewOrReject`
    },
    ASSOCIATE_DRIVER: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_ASSOCIATE_DRIVER",
        apiName: "onCarAssociate",
        method: "POST",
        name: "关联司机",
        type: "view",
        url: `${moduleName}car/bindDriver`
    },
    REPORT: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_REPORT",
        apiName: "onCarReport",
        method: "POST",
        name: "上报",
        type: "view",
        url: `${moduleName}car/report`
    },
    EXPORT_LIST: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_EXPORT_LIST",
        apiName: "carExport",
        method: "POSTFILE",
        name: "导出",
        type: "view",
        url: `${moduleName}car/export`
    },
    EXPORT_TEMP: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_EXPORT_TEMP",
        apiName: "carExportTemplate",
        method: "GETFILE",
        name: "导出车辆模板",
        type: "view",
        url: `${moduleName}car/exportTemp`
    },
    IMPORT_CAR: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_IMPORT_CAR",
        apiName: "batchSaveCar",
        method: "POST",
        name: "导入",
        type: "view",
        url: `${moduleName}car/batchSave`
    },
    LOOK_MORE: {
        id: "RESOURCE_MANAGEMENT_BASE_CAR_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
