const prevUrl = "wms/receiptManage";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE",
    method: "POST",
    name: "收货管理",
    type: "menu",
    apiName: "getReceiptManage",
    url: `${prevUrl}/list`
};

export const children = {
    BATCH_DEL: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_GET_SUMMARY_DATA",
        apiName: "deleteReceiptManage",
        method: "POST",
        name: "批量删除",
        type: "view",
        url: `${prevUrl}/deleteByIds`
    }
    // GET_DETAILS_DATA: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_GET_DETAILS_DATA',
    //     apiName: 'getReceiptDetails',
    //     method: 'POST',
    //     name: '收货明细列表',
    //     type: 'view',
    //     url: `${detailPrevUrl}/list`
    // },
    // DETAILS_SYSTEM_RECEIPT: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_SYSTEM_RECEIPT',
    //     apiName: 'getReceiptBoardBatch',
    //     method: 'GET',
    //     name: '收货明细系统收货',
    //     type: 'view',
    //     url: `${detailPrevUrl}/systemReceipt`
    // },
    // BATCH_IN: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_BATCH_IN',
    //     apiName: 'doReceiptToStorage',
    //     method: 'POST',
    //     name: '收货明细批量入储',
    //     type: 'view',
    //     url: `${detailPrevUrl}/upperShelf`
    // },
    // DETAILS_BOARD_BATCH: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_BOARD_BATCH',
    //     apiName: 'addReceiptBoardBatch',
    //     method: 'POST',
    //     name: '收货明细批量加板',
    //     type: 'view',
    //     url: `${detailPrevUrl}/batchBoardNumber`
    // },
    // DEL_BOARD_BATCH: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DEL_BOARD_BATCH',
    //     apiName: 'delReceiptMaterialMany',
    //     method: 'POST',
    //     name: '收货明细批量删除板',
    //     type: 'view',
    //     url: `${detailPrevUrl}/delete`
    // },
    // COMPLETE_RECEIPT: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_COMPLETE_RECEIPT',
    //     apiName: 'completeReceiptManage',
    //     method: 'GET',
    //     name: '完成收货',
    //     type: 'view',
    //     url: `${prevUrl}/completeReceipt`
    // },
    // DETAILS_IMPORT: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_IMPORT',
    //     name: '收货明细导入',
    //     type: 'view'
    // },
    // DETAILS_EXPORT: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_EXPORT',
    //     name: '导出理货单',
    //     type: 'view',
    //     apiName: 'exportTallySheet',
    //     method: 'GETFILE',
    //     url: `${prevUrl}/exportTallySheet`
    // },
    // EXPORT_PDF: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_EXPORT_PDF',
    //     name: '导出签收单',
    //     type: 'view',
    //     apiName: 'exportReceiptSignSheet',
    //     method: 'GETFILE',
    //     url: `${prevUrl}/exportReceiptSignSheet`
    // },
    // DETAILS_PRINT: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_PRINT',
    //     name: '打印标签',
    //     type: 'view'
    // },
    // COMPLETE_UPPERSHELF: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_COMPLETE_UPPERSHELF',
    //     apiName: 'completeUppershelf',
    //     method: 'GET',
    //     name: '入储完成',
    //     type: 'view',
    //     url: `${prevUrl}/completeUpperShelf`
    // },
    // SCAN_IMPORT: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_SCAN_IMPORT',
    //     name: '收货扫描导入',
    //     type: 'view'
    // },
    // SCAN_EXPORT: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_SCAN_EXPORT',
    //     name: '收货扫描导出',
    //     type: 'view'
    // },
    // SCAN_EDIT: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_SCAN_EDIT',
    //     name: '收货扫描编辑',
    //     type: 'view'
    // },
    // SCAN_DEL: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_SCAN_DEL',
    //     name: '收货扫描删除',
    //     type: 'view',
    //     apiName: 'delReceiptScanData',
    //     method: 'POST',
    //     url: `${scanUrl}/delete`
    // },
    // UPLOAD_ATTACHMENT: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_UPLOAD_ATTACHMENT',
    //     name: '上传附件',
    //     type: 'view'
    // },
    // ADD_STATISTICS: {
    //     id: 'WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_ADD_STATISTICS',
    //     name: '生成费用单',
    //     type: 'view'
    // },
};
