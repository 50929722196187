import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class ReqFundsApi {
    /**
     * @param {any} params
     * @returns
     *
     * @memberOf ReqFundsApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [power.ADD_DATA.apiName](params) {
        // 新增请款单
        let url = power.ADD_DATA.url;
        return this.POST(url, params);
    }

    [power.EDIT_DATA.apiName](params) {
        // 编辑请款单
        let url = power.EDIT_DATA.url;
        return this.POST(url, params);
    }

    [power.DEL_DATA.apiName](params) {
        // 删除请款单
        let url = power.DEL_DATA.url + `/${params.id}`;
        return this.GET(url, params);
    }

    getReqAttachmentById(params) {
        // 根据id获取请款单
        return this.GET(`finance/payableReconciliation/getByPaymentId/${params.id}`);
    }
}
