import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

export default class ColumnItemBox extends Component {
    static propTypes = {
        isModeTag: PropTypes.bool, //是否 tag 模式显示
        tagBgc: PropTypes.string, //tag背景色
        pStyle: PropTypes.object, //cell样式
        style: PropTypes.object, //样式
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        tagName: PropTypes.string, //tag字符
        active: PropTypes.bool, //是否 选中 状态
        className: PropTypes.string, //样式名
        isFormChild: PropTypes.bool, //是否表单子元素
        hasPoint: PropTypes.bool, //是否带圆点
        pointBgc: PropTypes.string, //圆点背景色
        onClick: PropTypes.func
    };

    static defaultProps = {
        isModeTag: false,
        tagBgc: "#2db7f5",
        pStyle: {},
        style: {},
        name: "",
        tagName: null,
        active: false,
        className: "",
        isFormChild: false,
        hasPoint: false,
        pointBgc: "#87D068"
    };

    render() {
        let {
            textClass,
            pStyle,
            active,
            name,
            style,
            isModeTag,
            tagBgc,
            tagName,
            tagStyle,
            children,
            isFormChild,
            hasPoint,
            pointBgc,
            onClick,
            customDom,
            ...restProps
        } = this.props;
        return (
            <div
                title={name}
                // style={{ width: '100%', margin: isFormChild ? '-6px 0 -7px' : 0, ...style}}
                {...restProps}
            >
                {children ? (
                    children
                ) : (
                    <Fragment>
                        {hasPoint ? (
                            <span
                                style={{
                                    width: 8,
                                    height: 8,
                                    marginRight: 6,
                                    display: "inline-block",
                                    borderRadius: "50%",
                                    backgroundColor: pointBgc
                                }}
                            ></span>
                        ) : null}
                        <span
                            className={`text-overflow-ellipsis ${textClass || ""}`}
                            style={{ width: `calc(100% - 62)`, color: style && style.color ? style.color : null }}
                            onClick={onClick}
                        >
                            {name || !isNaN(name) || name === 0 ? name : tagName ? "" : "-"}
                        </span>
                        {isModeTag ? (
                            <span
                                style={{
                                    height: 18,
                                    lineHeight: "18px",
                                    color: "#fff",
                                    padding: "2px 7px",
                                    borderRadius: "4px",
                                    fontSize: "12px",
                                    backgroundColor: tagBgc,
                                    marginLeft: name ? 4 : 0,
                                    ...tagStyle
                                }}
                            >
                                {tagName}
                            </span>
                        ) : null}
                        {(this.props.icon && <img src={this.props.icon} />) || null}
                        {customDom}
                    </Fragment>
                )}
            </div>
        );
    }
}
