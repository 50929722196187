import { children, id } from "./power_hide";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};
const moduleName = "account/";

export default class AddOrderApi {
    /**
     * 获取新建发货单列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf UserApi
     */

    [children.CONFIRM_DATA.apiName](params) {
        //确认
        const url = children.CONFIRM_DATA.url + "/" + params.id;
        return this.POST(url, params);
    }

    // [children.ADD_PRA_ORDER.apiName](params) {
    //     const url= children.ADD_PRA_ORDER.url
    //     return this.POST(url, params)
    // }

    [children.ADD_PRA.apiName](params) {
        //提交
        const url = children.ADD_PRA.url;
        return this.POST(url, params);
    }

    // [children.GET_RECEIVERS.apiName](params) {
    //     const url= children.GET_RECEIVERS.url
    //     return this.POST(url, params)
    // }

    addPraOrder(params) {
        //提交预发货单
        const url = "order/order/saveOrderForm";
        return this.POST(url, params);
    }

    getReciversAndWarehouse(params) {
        //获取收发货方列表和仓库列表
        const url = "project/receiverOrSender/getList";
        return this.POST(url, params);
    }

    //获取单个收发货方数据
    getReciversOrSenderOne(id) {
        const url = `project/receiverOrSender/getOne/${id}`;
        return this.GET(url);
    }

    // 获取中转地数据
    getMiddleOne(id) {
        const url = `/resource/node/getOne/${id}`;
        return this.GET(url);
    }

    getProClientQuotation(params) {
        //获取客户报价
        const url = "project/cooperationProject/getClientQuotationRow";
        return this.POST(url, params);
    }

    getClientQuotationTransportMode(params) {
        //获取客户报价
        const url = "project/cooperationProject/getClientQuotationTransportMode";
        return this.POST(url, params);
    }

    getOneOrderDetails(params) {
        //根据ID获取单个发货单信息
        const url = `order/order/getOne/${params.id}`;
        return this.POST(url, params);
    }

    getEstimateExpenseByOrderId(params) {
        const url = `order/order/getEstimateExpenseByOrderId/${params.id}`;
        return this.GET(url, params);
    }

    switchQuotationCost(params) {
        const url = `order/order/switch/billingMethod/quotation/${params.id}`;
        return this.GET(url, params);
    }

    switchTempCost(params) {
        const url = `order/order/switch/billingMethod/temp/${params.id}`;
        return this.GET(url, params);
    }

    changeQuotationLine(params) {
        const url = `order/order/matchQuotationLineExpense/${params.id}/${params.quotationLineId}`;
        return this.GET(url, params);
    }

    getMatchQuotationLineExpense(params) {
        const url = `project/clientQuotation/matchQuotationLineExpense/${params.quotationLineId}`;
        return this.GET(url, params);
    }

    editEstimateExpense(params) {
        const url = `order/order/editEstimateExpense`;
        return this.POST(url, params);
    }

    getCustomsReceiverSender(params) {
        const url = `order/customs/customsReceiverSender`;
        return this.POST(url, params);
    }

    downloadCustomsZip(id) {
        const url = `order/customs/downloadCustomsZip/${id}`;
        return this.GETFILE(url);
    }

    // 新增运载段
    saveOrderReceiverSender(params) {
        const url = `order/order/saveOrderReceiverSender`;
        return this.POST(url, params);
    }

    // 编辑运载段
    updateOrderReceiverSender(params) {
        const url = `/order/order/updateOrderReceiverSender`;
        return this.POST(url, params);
    }

    // 根据收发货路线ID获取关联运单信息
    getStowageFromOrderId(id) {
        const url = `order/order/stowageInfo/${id}`;
        return this.GET(url);
    }

    // 删除运载段
    deleteOrderReceiverSender(id) {
        const url = `/order/order/deleteOrderReceiverSender/${id}`;
        return this.GET(url);
    }

    // 获取中转地资源
    getResourceNodeList() {
        const url = `/resource/node/list`;
        return this.POST(url);
    }

    uploadOrderLineAttachment(params) {
        const url = "/order/order/saveReceiverSenderAttachment";
        return this.POST(url, params);
    }

    // 导出发货单签收单
    exportSignReceipt(params) {
        const url = `/order/order/exportSignReceipt/${params.id}`;
        return this.GETFILE(url, params);
    }
    // 导出发货单local委托单
    exportLocal(params) {
        const url = `/order/order/exportLocal/${params.id}`;
        return this.GETFILE(url, params);
    }
}
