import React, { Component } from "react";
import { Select, Spin, Icon } from "antd";
import PropTypes from "prop-types";
import { isArray } from "@src/utils";
import debounce from "lodash/debounce";
import rApi from "@src/http";
import _ from "@src/utils/lodash";
import memoizeone from "memoize-one";

const Option = Select.Option;

export default class RemoteSelect extends Component {
    /**
     * 父组件传值接口onChangeValue、text、getDataMethod、params
     *
     * @memberof RemoteSelect
     */
    static propTypes = {
        onChangeValue: PropTypes.func.isRequired,
        dealData: PropTypes.func, // 处理数据方法，必须return一个数组
        text: PropTypes.string,
        list: PropTypes.array,
        getData: PropTypes.func,
        getDataMethod: PropTypes.string,
        dataKey: PropTypes.string,
        labelField: PropTypes.string,
        params: PropTypes.object,
        getThis: PropTypes.func,
        timelyFilter: PropTypes.bool,
        forceRender: PropTypes.bool, //是否重新渲染一次select
        keyName: PropTypes.string, // 下拉列表key键名
        style: PropTypes.object, //style样式
        showOrigin: PropTypes.bool, //下拉选项数据显示原始数据
        hasInput: PropTypes.bool, //是否有搜索自定义输入功能
        isAsyncSearch: PropTypes.bool, // 是否开启异步搜索->只支持getDataMethod方式搜索 且字段为keyword
        inputKey: PropTypes.string, //搜索自定义项 key 值
        isAddInputValue: PropTypes.bool, //输入框没有匹配 是否展示输入的值
        keywordsAttributes: PropTypes.string, // 搜索key值
        dropdownClassName: PropTypes.string,
        optionStyle: PropTypes.object,
        loadErrorContent: PropTypes.func // 加载失败时的显示 react.node
    };

    static defaultProps = {
        labelField: "title",
        dataKey: "records",
        forceRender: false,
        keyName: "id",
        style: {},
        showOrigin: false,
        hasInput: false,
        isAsyncSearch: false,
        inputKey: "-1",
        isAddInputValue: false
    };

    isControlValByProps = false; //是否是受控状态

    state = {
        type: null,
        data: [], //数据源
        isGetData: false,
        loading: false,
        loadError: false,
        inputOpt: null //搜索输入内容
    };

    constructor(props) {
        super(props);
        let { defaultValue, def, mode, labelField, getThis, keyName } = props;
        this.onSearch = debounce(this.onSearch, 800); // 搜索防抖
        if (getThis) {
            getThis(this);
        }
        const d_value = defaultValue || def;
        if (d_value && mode) {
            if (d_value.length > 0) {
                this.state.data.push(...d_value);
            }
        } else if (d_value && (d_value[keyName] || d_value[labelField])) {
            this.state.data.push(d_value);
        }
        //value在props中为受控组件状态
        if ("value" in props) {
            this.isControlValByProps = true;
        }

        if ("text" in props && props.text) {
            this.state.type = 1;
        } else if ("list" in props) {
            this.state.type = 2;
        } else if ("getDataMethod" in props && props.getDataMethod) {
            this.state.type = 3;
        } else if ("getData" in props && props.getData) {
            this.state.type = 4;
        }
    }

    //受控状态下直接使用props里的value就行了
    // static getDerivedStateFromProps(props, state) {
    //     if ("value" in props && props.value !== state.value) {
    //         return {
    //             value: props.value
    //         };
    //     }
    //     return null;
    // }

    onFocus = (keyword = null) => {
        const props = this.props;
        if (keyword && keyword.length < 1) {
            keyword = null;
        }
        let { list, text, getDataMethod, params, dataKey, getData, dealData, labelField, keyName, isAddInputValue } =
            this.props;
        let arr = [];
        if (keyword && keyword.length < 1) {
            keyword = null;
        }
        const resDataAddInputValue = (resData) => {
            const { inputKey, hasInput, keyName } = this.props;
            if (keyword && keyword.length > 0 && _.isArray(resData) && resData.length < 1) {
                if (isAddInputValue) {
                    resData.push({
                        id: -1,
                        [labelField]: keyword,
                        value: keyword,
                        name: keyword,
                        keyName: keyName,
                        labelName: keyword,
                        label: keyword
                    });
                } else if (hasInput && inputKey) {
                    resData.push({
                        [labelField]: keyword,
                        [keyName]: inputKey,
                        value: keyword,
                        name: keyword,
                        keyName: keyName,
                        labelName: keyword,
                        label: keyword
                    });
                }
            }
            return resData;
        };

        let { type, isGetData } = this.state;
        if ("text" in props && props.text) {
            type = 1;
        } else if ("list" in props) {
            type = 2;
        } else if ("getDataMethod" in props && props.getDataMethod) {
            type = 3;
        } else if ("getData" in props && props.getData) {
            type = 4;
        }
        this.setState({ loading: true, loadError: false });
        if (type === 1) {
            return new Promise((resolve, reject) => {
                if (!isGetData) {
                    rApi["getDicByText"](text)
                        .then((d) => {
                            if (dealData) {
                                d = dealData(d);
                            }
                            d = resDataAddInputValue(d);
                            this.setState({
                                data: [...arr, ...d],
                                loading: false,
                                isGetData: true
                            });
                        })
                        .catch((e) => {
                            this.setState({ loading: false });
                        });
                } else {
                    this.setState({ loading: false });
                }
            });
        } else if (type === 2) {
            return new Promise((resolve, reject) => {
                if (dealData) {
                    list = dealData(list);
                }
                list = resDataAddInputValue(list);
                this.setState({ loading: false, data: list });
                resolve(list);
            });
        } else if (type === 3) {
            if (!rApi[getDataMethod]) {
                return new Promise((resolve, reject) => {
                    this.setState({ loading: false });
                    reject(`getDataMethod: ${getDataMethod} is null`);
                });
            }
            let payload =
                "keywordsAttributes" in this.props
                    ? { [this.props.keywordsAttributes]: keyword }
                    : { keyword: keyword, keyWords: keyword, keywords: keyword };
            // console.log('payload', payload)
            return rApi[getDataMethod]({ ...params, ...payload })
                .then((d) => {
                    let list = [];
                    if (isArray(d)) {
                        list = d;
                    } else if (d && isArray(d[dataKey])) {
                        list = d[dataKey];
                    } else if (isArray(d.list)) {
                        list = d.list;
                    } else if (isArray(d.clients)) {
                        list = d.clients;
                    } else if (isArray(d.records)) {
                        list = d.records;
                    } else if (d.page && isArray(d.page.records)) {
                        list = d.page.records;
                    } else {
                        list = d;
                    }
                    if (dealData) {
                        list = dealData(list);
                    }
                    list = resDataAddInputValue(list);
                    // console.log('arr', arr, list)
                    this.setState({ data: [...arr, ...list], loading: false });
                })
                .catch((e) => {
                    console.error(e);
                    this.setState({ loading: false, loadError: true, data: [] });
                });
        } else if (type === 4) {
            let payload =
                "keywordsAttributes" in this.props
                    ? { [this.props.keywordsAttributes]: keyword }
                    : { keyword: keyword, keyWords: keyword, keywords: keyword };
            return getData(payload)
                .then((d) => {
                    // console.log('arr',arr,keyword)
                    d = resDataAddInputValue(d);
                    this.setState({ data: [...arr, ...d], loading: false }, () => {
                        let dict = arr[0];

                        if (dict && dict.id === -1) {
                            dict.key = dict.id;
                            this.handleChange(dict);
                        }
                    });
                })
                .catch((e) => {
                    this.setState({
                        loading: false,
                        loadError: true,
                        data: []
                    });
                });
        }
    };
    dataStore = {};
    handleChange = async (value) => {
        const { mode, labelField, showOrigin, keyName, inputKey, hasInput } = this.props;

        if (mode) {
            //console.log('value', value)
            if (isArray(value)) {
                value.forEach((item) => {
                    item.id = item.id ?? item.key;
                    const find = this.state.data.find((el) => {
                        return el[keyName] == item[keyName];
                    });
                    if (find) {
                        this.dataStore = { ...this.dataStore, [find[keyName]]: find };
                    }
                });
                value = value.map((item) => {
                    return {
                        ...item,
                        [labelField]: item.label,
                        id: item.key,
                        origin_data: this.dataStore[item[keyName]]
                    };
                });
            }
        } else {
            if (value) {
                let key = value.key;
                if (key !== inputKey) {
                    /* 如果不是选中搜索自定义输入项 */
                    this.setState({ inputOpt: null });
                }
                if (!showOrigin) {
                    /* 自定义下拉数据 */
                    value[keyName] = key;
                    value.title = value.label;
                    value.name = value.label;
                    value[labelField] = value.label;
                    value.origin_data = this.state.data.filter((item) => {
                        return item && item[keyName] === key;
                    });
                } else {
                    /* 原始获取的下拉数据 */
                    value = Object.assign(value, {
                        ...this.state.data.find((item) => {
                            return item[keyName] === key;
                        })
                    });
                    if (hasInput) {
                        /* 如果开启搜索输入 */
                        value = Object.assign(value, {
                            [keyName]: key,
                            [labelField]: value.label
                        });
                    }
                }
            } else {
                this.setState({ inputOpt: null });
            }
        }
        if (this.props.onChangeValue) {
            this.props.onChangeValue(value);
        }
    };

    /* 文本框值变化 */
    onSearch = (val) => {
        const { keyName, labelField, hasInput, inputKey, isAsyncSearch } = this.props;
        if (isAsyncSearch) {
            this.onFocus(val);
        } else {
            if (hasInput) {
                /* 如果开启了自定义搜索输入 */
                let { data, inputOpt } = this.state;
                let result = data.filter((item) => {
                    return item[labelField].indexOf ? item[labelField].indexOf(val) !== -1 : false;
                });
                if (val && result.length < 1) {
                    /* 未搜索出相应项 */
                    inputOpt = {
                        [keyName]: inputKey,
                        [labelField]: val
                    };
                    this.setState({ inputOpt });
                } else {
                    /* 已存在相应项 */
                    this.setState({ inputOpt: null });
                }
            } else {
            }
        }
    };

    // /* 失去焦点 */
    handleBlur = () => {};

    getList = () => {
        return this.state.data;
    };

    getValFormat = (hasMode) => (dealObj, keyName, labelField) => {
        let dealValue = undefined;
        if (hasMode && Array.isArray(dealObj) && dealObj.length > 0) {
            dealValue = dealObj
                .filter((item) => item[keyName] || item.key || item.id)
                .map((ele) => {
                    return {
                        ...ele,
                        key: ele[keyName] || ele.key || ele.id,
                        name: ele[labelField],
                        [labelField]: ele[labelField],
                        label: ele[labelField]
                    };
                });
        } else {
            if (dealObj && (dealObj[keyName] || dealObj[labelField])) {
                dealValue = {
                    key: dealObj[keyName],
                    label: dealObj[labelField] || dealObj["title"],
                    title: dealObj[labelField] || dealObj["title"]
                };
            }
        }
        return dealValue;
    };

    mergePropsStateVal = memoizeone((propsVal, stateVal) => {
        if (propsVal !== stateVal) {
            return {
                ...propsVal,
                ...stateVal
            };
        }
    });

    render() {
        let { loading, loadError, data, type, inputOpt } = this.state;
        let {
            labelField,
            placeholder,
            mode,
            defaultValue,
            def,
            value,
            size,
            getPopupContainer,
            disabled,
            timelyFilter,
            list,
            dealData,
            forceRender,
            keyName,
            style,
            dropdownStyle,
            hasInput,
            isAsyncSearch,
            dropdownRender,
            renderItemContent,
            optionLabelProp,
            dropdownClassName,
            optionStyle,
            loadErrorContent
        } = this.props;
        defaultValue = def || defaultValue;

        let getTContainer = {};
        if (timelyFilter && type === 2) {
            data = dealData ? dealData(list) : list;
        }

        if (getPopupContainer) {
            getTContainer.getPopupContainer = getPopupContainer;
        } else {
            getTContainer.getPopupContainer = () => document.querySelector("#scroll-view");
        }

        //非受控状态下压根就需要给select组件value的属性
        if (mode) {
            return (
                <div className={this.props.className}>
                    <Select
                        {...getTContainer}
                        disabled={!!disabled}
                        {...{
                            [this.isControlValByProps ? "value" : "defaultValue"]: this.getValFormat(true)(
                                this.isControlValByProps ? value : defaultValue,
                                keyName,
                                labelField
                            )
                        }}
                        labelInValue
                        size={size}
                        mode={mode}
                        onChange={this.handleChange}
                        onSearch={this.onSearch}
                        onDropdownVisibleChange={(open) => {
                            if (open) {
                                this.onFocus();
                            }
                        }}
                        allowClear={"allowClear" in this.props ? this.props.allowClear : true}
                        notFoundContent={
                            loading ? (
                                <span>
                                    <Spin size={window._size} />
                                </span>
                            ) : loadError ? (
                                loadErrorContent ? (
                                    loadErrorContent(this.onFocus)
                                ) : (
                                    <span onClick={() => this.onFocus()}>
                                        加载失败 <Icon type="reload" />
                                    </span>
                                )
                            ) : (
                                <span>暂无数据</span>
                            )
                        }
                        style={Object.assign({}, { width: "100%", minWidth: 90 }, style)}
                        dropdownStyle={dropdownStyle}
                        dropdownClassName={dropdownClassName}
                        filterOption={
                            isAsyncSearch
                                ? false
                                : this.props.filterOption
                                ? (input, option) => this.props.filterOption(input, option)
                                : (input, option) => {
                                      let fValue = option.props.children;
                                      if (!_.isString(fValue)) {
                                          fValue = option.props.title;
                                      }
                                      return fValue.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                  }
                        }
                        size={window._size}
                        {...(dropdownRender ? { dropdownRender } : {})}
                        {...(optionLabelProp ? { optionLabelProp } : {})}
                    >
                        {data.map((dataItem, index) => {
                            return (
                                <Option
                                    style={optionStyle}
                                    key={dataItem.id}
                                    value={dataItem.id + ""}
                                    title={dataItem[labelField]}
                                    label={dataItem[labelField]}
                                >
                                    {renderItemContent ? renderItemContent(dataItem) : dataItem[labelField]}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
            );
        }

        if (forceRender) {
            return null;
        } else {
            return (
                // <div className={this.props.className}>
                <Select
                    {...getTContainer}
                    disabled={!!disabled}
                    {...{
                        [this.isControlValByProps ? "value" : "defaultValue"]: this.getValFormat(false)(
                            this.isControlValByProps ? value : defaultValue,
                            keyName,
                            labelField
                        )
                    }}
                    size={size}
                    mode={mode}
                    labelInValue
                    showSearch
                    className={this.props.className}
                    allowClear={"allowClear" in this.props ? this.props.allowClear : true}
                    notFoundContent={
                        loading ? (
                            <span>
                                <Spin size={window._size} />
                            </span>
                        ) : loadError ? (
                            loadErrorContent ? (
                                loadErrorContent(this.onFocus)
                            ) : (
                                <span onClick={() => this.onFocus()}>
                                    加载失败 <Icon type="reload" />
                                </span>
                            )
                        ) : (
                            <span>暂无数据</span>
                        )
                    }
                    loading={loading}
                    style={Object.assign({}, { width: "100%", minWidth: 90 }, style)}
                    placeholder={
                        placeholder || (defaultValue ? defaultValue[labelField] || defaultValue.title : defaultValue)
                    }
                    optionFilterProp="children"
                    onChange={this.handleChange}
                    onDropdownVisibleChange={(open) => {
                        // console.log('open',open)
                        if (open) {
                            this.onFocus();
                        }
                    }}
                    onSearch={this.onSearch}
                    onBlur={this.handleBlur}
                    dropdownStyle={dropdownStyle}
                    dropdownClassName={dropdownClassName}
                    filterOption={
                        isAsyncSearch
                            ? false
                            : this.props.filterOption
                            ? (input, option) => this.props.filterOption(input, option)
                            : (input, option) => {
                                  let fValue = option.props.children;
                                  if (!fValue) {
                                      return false;
                                  }
                                  if (!_.isString(fValue)) {
                                      fValue = option.props.title;
                                  }
                                  return fValue.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                              }
                    }
                    size={window._size}
                    {...(dropdownRender ? { dropdownRender } : {})}
                    dropdownMatchSelectWidth={dropdownRender ? false : true}
                    {...(optionLabelProp ? { optionLabelProp } : {})}
                >
                    {data &&
                        data.length > 0 &&
                        data
                            .slice()
                            .filter((item) => item)
                            .map((dataItem) => (
                                <Option
                                    style={optionStyle}
                                    key={dataItem[keyName]}
                                    value={dataItem[keyName]}
                                    title={dataItem[labelField]}
                                >
                                    {renderItemContent ? renderItemContent(dataItem) : dataItem[labelField]}
                                </Option>
                            ))}
                </Select>
                // </div>
            );
        }
    }
}
