const m = "wms/reconciliation";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT",
    method: "POST",
    name: "仓库应收对账",
    type: "menu",
    apiName: "getWhReceivaccountList",
    url: `${m}/list`
};

export const children = {
    EDIT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT_EDIT_DATA",
        apiName: "editWhReceivaccount",
        method: "POST",
        name: "编辑应收对账单",
        type: "view",
        url: `${m}/edit`
    },
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT_DEL_DATA",
        apiName: "delWhReceivaccount",
        method: "GET",
        name: "删除应收对账单",
        type: "view",
        url: `${m}/delete`
    },
    CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT_CONFIRM",
        apiName: "confirmWhReceivaccount",
        method: "POST",
        name: "对账单确认",
        type: "view",
        url: `${m}/confirm`
    },
    CANCEL: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT_CANCEL",
        apiName: "cancelWhReceivaccount",
        method: "POST",
        name: "取消对账确认",
        type: "view",
        url: `${m}/cancel`
    },
    CONFIRM_FINANCE: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT_CONFIRM_FINANCE",
        apiName: "financeConfirmWhReceivaccount",
        method: "POST",
        name: "财务确认",
        type: "view",
        url: `${m}/confirmByFinance`
    },
    CANCEL_FINANCE: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT_CANCEL_FINANCE",
        apiName: "financeCancelWhReceivaccount",
        method: "POST",
        name: "取消财务确认",
        type: "view",
        url: `${m}/cancelByFinance`
    },
    LOGS: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVACCOUNT_LOGS",
        name: "日志",
        type: "view"
    }
};
