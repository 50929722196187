const prevUrl = "wms/receiptManage";
const exportUrl = "wms/export";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS",
    method: "GET",
    name: "收货管理明细",
    type: "menu_hide",
    apiName: "getReceiptManageById",
    url: `${prevUrl}/detail`
};

export const children = {
    CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_CONFIRM",
        apiName: "receiptManageConfirm",
        method: "GET",
        name: "确认",
        type: "view",
        url: `${prevUrl}/plan/confirm`
    },
    UN_CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_UN_CONFIRM",
        apiName: "receiptManageCancelConfirm",
        method: "GET",
        name: "取消确认",
        type: "view",
        url: `${prevUrl}/plan/cancelConfirm`
    },
    TALLY_FAST: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_TALLY_FASTA",
        apiName: "receiptManageTallyFask",
        method: "GET",
        name: "快速理货",
        type: "view",
        url: `${prevUrl}/tally/fast`
    },
    TALLY_MANUAL: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_TALLY_MANUAL",
        apiName: "receiptManageTallyManual",
        method: "POST",
        name: "手动理货",
        type: "view",
        url: `${prevUrl}/tally/manual`
    },
    SYSTEM_IN: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_SYSTEM_IN",
        apiName: "receiptManageSystemIn",
        method: "GET",
        name: "系统入储",
        type: "view",
        url: `${prevUrl}/tally/system/storage`
    },
    ASSIGN: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_ASSIGN",
        apiName: "receiptManageAssign",
        method: "POST",
        name: "指派理货人",
        type: "view",
        url: `${prevUrl}/assignedAccept`
    },
    TALLY_DELETE: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_TALLY_DELETE",
        apiName: "receiptManageTallyDelete",
        method: "GET",
        name: "理货删除",
        type: "view",
        url: `${prevUrl}/tally/delete`
    },
    TALLY_FINISH: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_TALLY_FINISH",
        apiName: "receiptManageTallyFinish",
        method: "GET",
        name: "理货完成",
        type: "view",
        url: `${prevUrl}/tally/finish`
    },
    ASSIGN_UPPER: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_ASSIGN_UPPER",
        apiName: "receiptManageAssignUpper",
        method: "POST",
        name: "指派上架人",
        type: "view",
        url: `${prevUrl}/assignedShelves`
    },
    SAVE_STORAGE: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_SAVE_STORAGE",
        apiName: "receiptManageSaveStorage",
        method: "GET_QUERY",
        name: "上架库位保存",
        type: "view",
        url: `${prevUrl}/storage/save`
    },
    UPPER_FINISH: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_UPPER_FINISH",
        apiName: "receiptManageUpperFinish",
        method: "GET",
        name: "上架完成",
        type: "view",
        url: `${prevUrl}/storage/finish`
    },
    FINISH: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_FINISH",
        apiName: "receiptManageFinish",
        method: "GET",
        name: "收货完成",
        type: "view",
        url: `${prevUrl}/finish`
    },
    STOP: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_STOP",
        apiName: "receiptManageStop",
        method: "POST",
        name: "中止收货",
        type: "view",
        url: `${prevUrl}/terminationReceipt`
    },
    SERIAL: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_SERIAL",
        apiName: "receiptManageSerial",
        method: "GET",
        name: "序列号查看",
        type: "view",
        url: `wms/receiptScanList`
    },
    CHECK: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_CHECK",
        apiName: "receiptManageFinishCheck",
        method: "GET",
        name: "检验理货清单是否含未通过质检物料",
        type: "view",
        url: `${prevUrl}/tally/ready/finish`,
        paramsType: 1,
        onlyIsApi: true
    },
    EXPORT1: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_EXPORT1",
        apiName: "receiptManageExportPlan",
        method: "POSTFILE",
        name: "导出计划单",
        type: "view",
        url: `${exportUrl}/receipt/cargoDetail`
    },
    EXPORT2: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_EXPORT2",
        apiName: "receiptManageExportTally",
        method: "POSTFILE",
        name: "导出理货单",
        type: "view",
        url: `${exportUrl}/receipt/tallyDetail`
    },
    EXPORT3: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_EXPORT3",
        apiName: "receiptManageExportUpper",
        method: "POSTFILE",
        name: "导出上架单",
        type: "view",
        url: `${exportUrl}/receipt/storageDetail`
    },
    EXPORT_T: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_EXPORT_T",
        apiName: "receiptManageExportTallyTemp",
        method: "POSTFILE",
        name: "模板理货",
        type: "view",
        url: `${exportUrl}/receipt/tallyTemplate`
    },
    IMPORT: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_MANAGE_DETAILS_IMPORT",
        apiName: "receiptManageImportTally",
        method: "POST",
        name: "导入理货单",
        type: "view",
        url: `${exportUrl}/importReceiptList`
    }
};
