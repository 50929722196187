const m = "wms/shipment";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_PICK",
    name: "拣货作业",
    type: "menu",
    method: "POST",
    apiName: "getShipmentPickList",
    url: `${m}/pickList`
};

export const children = {
    DELETE: {
        id: "WAREHOUSE_MANAGEMENT_PICK_DELETE",
        name: "删除",
        type: "view",
        apiName: "shipmentPickDelete",
        method: "POST",
        url: `${m}/deletePick`
    }
};
