import React from "react";
interface ViewProps {
    children: React.ReactNode;
    className?: string;
}
interface HeaderBoxProps {
    leftView?: React.ReactNode;
    rightView?: React.ReactNode;
    children?: React.ReactNode;
    title?: React.ReactNode;
    tags?: React.ReactNode;
    number?: React.ReactNode;
}

export const LeftView = (props: ViewProps) => {
    return <div className={props.className ? `${props.className} flex1` : "flex1"}>{props.children}</div>;
};

export const RightView = (props: ViewProps) => {
    return <div>{props.children}</div>;
};

export const HeaderBox = (props: HeaderBoxProps) => {
    return (
        <div className="flex flex-vertical-center" style={{ backgroundColor: "#eee", minHeight: 31 }}>
            <LeftView className={props.title ? "flex flex-vertical-center" : null}>
                {props.title ? (
                    <>
                        <div style={{ fontSize: "14px", marginRight: 10, color: "#FF5722" }}>
                            {props.title}
                            {props.number ? `: ${props.number}` : ""}
                        </div>
                        {props.tags && <div>{props.tags}</div>}
                    </>
                ) : (
                    props.leftView
                )}
            </LeftView>
            <RightView>{props.rightView || props.children}</RightView>
        </div>
    );
};
