/**
 * 导入所有 api 接口
 */
const files = require.context("../../views", true, /\.api.(js|ts)$/);
const files1 = require.context("../", true, /\.api.js$/);
const componentsApi = require.context("../../components", true, /\.api.js$/);
const layoutApi = require.context("../../layout", true, /\.api.js$/);

const keys = files.keys();
const keys1 = files1.keys();
const componentsKeys = componentsApi.keys();
const layoutKeys = layoutApi.keys();
// console.log('file keys', keys, keys1)

/**
 * 使用i+命名解决打包 content.default.name 名字混淆问题
 */
let modules = {};
let i = 0;

const setApi = (cxt, keys) => {
    for (let key of keys) {
        i++;
        try {
            let content = cxt(key);
            // console.log('setApi', key, keys, content)
            if (content && content.default) {
                if (typeof content.default === "function") {
                    modules[content.default.name + i] = content.default;
                    // console.log('content.default', content.default,  Object.keys(modules[content.default.name]), Object.getOwnPropertyNames(modules[content.default.name]))
                } else {
                    modules = Object.assign({}, modules, content.default);
                }
            }
        } catch (e) {
            console.error("批量导入所有文件夹出错: ", e);
        }
    }
};

setApi(files, keys);
setApi(files1, keys1);
// setApi(componentsApi, componentsKeys);
setApi(layoutApi, layoutKeys);
export default modules;
