import { children, id } from "./power";

const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class IndexApi {
    POST: any;
    GET: any;

    // 保存流程
    saveFlowDefine(params) {
        const { moduleName, ...reset } = params;
        const url = `${moduleName}/flowDefine/save`;
        console.log(params);
        return this.POST(url, { ...reset });
    }

    // 更新流程
    updateFlowDefine(params) {
        const { moduleName, flowDefineCode, ...reset } = params;
        const url = `${moduleName}/flowDefine/updateByFlowDefineCode/${flowDefineCode}`;
        return this.POST(url, { ...reset, flowDefineCode });
    }

    // 删除流程
    deleteFlowDefine(params) {
        const { moduleName, flowDefineCode } = params;
        const url = `${moduleName}/flowDefine/deleteByFlowDefineCode/${flowDefineCode}`;
        return this.POST(url, {});
    }

    // 获取所有流程
    getAllFlowDefine() {
        const url = `finance/flowDefine/findAll`;
        return this.POST(url);
    }

    // 获取所有角色
    getAllRoles(params) {
        const url = `account/role/list`;
        return this.POST(url, params);
    }
}
