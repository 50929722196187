/*
 * @Descripttion:
 * @Author: hl.huang
 * @Date: 2021-01-11 16:30:24
 * @LastEditors: 黄华林
 * @LastEditTime: 2021-01-11 16:42:05
 */

import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

const moduleName = "finance/payableRequirement/";
export default class payoutManager {
    //待付款列表
    payoutReconciliationList(params) {
        return this.POST(`${moduleName}reconciliationList`, deleteNull(params));
    }
    // 已付款列表
    payoutRequirementList(params) {
        return this.POST(`${moduleName}requirementList`, deleteNull(params));
    }
    //付款确认
    payoutCommit(params) {
        return this.POST(`${moduleName}commit`, deleteNull(params));
    }
    //会计审核
    payoutRequirement(params) {
        return this.POST(`${moduleName}requirement`, deleteNull(params));
    }
    //保存付款申请
    payoutSave(params) {
        return this.POST(`${moduleName}save`, deleteNull(params));
    }
    //收款账户
    payoutAddPayee(params) {
        return this.POST(`resource/carrier/addPayee`, deleteNull(params));
    }
}
