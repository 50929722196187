const m = "wms/receivableCost";
const m2 = "wms/reconciliation";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_RECEIVABLE",
    method: "POST",
    name: "应收费用",
    type: "menu",
    apiName: "getWhReceivableList",
    url: `${m}/list`
};

export const children = {
    PASS: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVABLE_PASS",
        apiName: "passWhReceivable",
        method: "GET",
        name: "审核通过",
        type: "view",
        url: `${m}/pass`
    },
    REJECT: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVABLE_REJECT",
        apiName: "rejectWhReceivable",
        method: "GET",
        name: "审核驳回",
        type: "view",
        url: `${m}/reject`
    },
    EDIT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVABLE_EDIT_DATA",
        apiName: "editWhReceivable",
        method: "POST",
        name: "编辑应收费用",
        type: "view",
        url: `${m}/edit`
    },
    SEE: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVABLE_SEE",
        name: "查看应收费用",
        type: "view"
    },
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVABLE_DEL_DATA",
        apiName: "deleteWhReceivable",
        method: "GET",
        name: "删除应收费用",
        type: "view",
        url: `${m}/delete`
    },
    GENERATE_BILL: {
        id: "WAREHOUSE_MANAGEMENT_RECEIVABLE_GENERATE_BILL",
        apiName: "generateWhReceivableBills",
        method: "POST",
        name: "生成应收对账单",
        type: "view",
        url: `${m2}/save`
    }
};
