const moduleName = "finance/";
module.exports.id = {
    id: "HANDLE_EDIT_LIST_EDIT",
    method: "POST",
    name: "应付结算明细",
    type: "menu_hide",
    sort: 4
    // apiName: 'getUsers',
    // url: `${moduleName}list`
};
module.exports.children = {
    SAVE_DATA: {
        id: "HANDLE_EDIT_SAVE_EDIT",
        apiName: "saveAllocateEdit",
        method: "POST",
        name: "保存",
        type: "view",
        url: `${moduleName}stowage/edit`
    },
    QUOTATION_DETAILS: {
        id: "HANDLE_EDIT_QUOTATION_DETAILS",
        name: "查看报价明细",
        type: "view"
    },
    SPECIAL: {
        id: "HANDLE_EDIT_QUOTATION_SPECIAL",
        name: "配载特殊业务",
        type: "view"
    },
    HIDE_MONEY: {
        id: "HANDLE_EDIT_HIDE_MONEY",
        name: "查看金额",
        type: "view"
    },
    CONFIRM_DATA: {
        id: "HANDLE_EDIT_CONFIRM_EDIT",
        apiName: "confirmAllocatePayable",
        method: "POST",
        name: "结算确认",
        type: "view",
        url: `${moduleName}payableSettlement/lock/confirm`
    },
    CANCEL_DATA: {
        id: "HANDLE_EDIT_CANCEL_DATA",
        apiName: "cancelAllocatePayable",
        method: "POST",
        name: "取消确认",
        type: "view",
        url: `${moduleName}payableSettlement/lock/cancel`
    },
    SUPPLEMENTARY_EXPENSE: {
        id: "HANDLE_EDIT_SUPPLEMENTARY_EXPENSE",
        name: "费用补录",
        type: "view"
    },
    PRESERVATION_COST: {
        id: "HANDLE_EDIT_PRESERVATION_COST",
        name: "保存费用",
        type: "view"
    },
    PRESERVATION_PASS: {
        id: "HANDLE_EDIT_PRESERVATION_PASS",
        name: "审核通过",
        type: "view"
    },
    PRESERVATION_REJECT: {
        id: "HANDLE_EDIT_PRESERVATION_REJECT",
        name: "审核拒绝",
        type: "view"
    }
};
