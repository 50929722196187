import { children, id } from "./power";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

const prevUrl = "wms/receiptManage";

export default class RequestApi {
    [power[id.id].apiName](reqId) {
        let url = power[id.id].url + `/${reqId}`;
        return this.GET(url);
    }

    [children.CONFIRM.apiName](id) {
        let url = children.CONFIRM.url + `/${id}`;
        return this[children.CONFIRM.method](url);
    }

    [children.UN_CONFIRM.apiName](id) {
        let url = children.UN_CONFIRM.url + `/${id}`;
        return this[children.UN_CONFIRM.method](url);
    }

    [children.TALLY_FAST.apiName](id) {
        let url = children.TALLY_FAST.url + `/${id}`;
        return this[children.TALLY_FAST.method](url);
    }

    [children.TALLY_MANUAL.apiName](params) {
        let url = children.TALLY_MANUAL.url;
        return this[children.TALLY_MANUAL.method](url, params);
    }

    [children.SYSTEM_IN.apiName](id) {
        let url = children.SYSTEM_IN.url + `/${id}`;
        return this[children.SYSTEM_IN.method](url);
    }

    [children.TALLY_DELETE.apiName](id) {
        let url = children.TALLY_DELETE.url + `/${id}`;
        return this[children.TALLY_DELETE.method](url);
    }

    [children.TALLY_FINISH.apiName](id) {
        let url = children.TALLY_FINISH.url + `/${id}`;
        return this[children.TALLY_FINISH.method](url);
    }

    [children.ASSIGN.apiName](params) {
        let url = children.ASSIGN.url;
        return this[children.ASSIGN.method](url, params);
    }

    [children.ASSIGN_UPPER.apiName](params) {
        let url = children.ASSIGN_UPPER.url;
        return this[children.ASSIGN_UPPER.method](url, params);
    }

    [children.SAVE_STORAGE.apiName](params) {
        let url = children.SAVE_STORAGE.url + `/${params.id}`;
        return this[children.SAVE_STORAGE.method](url, params);
    }

    [children.UPPER_FINISH.apiName](id) {
        let url = children.UPPER_FINISH.url + `/${id}`;
        return this[children.UPPER_FINISH.method](url);
    }

    [children.FINISH.apiName](id) {
        let url = children.FINISH.url + `/${id}`;
        return this[children.FINISH.method](url);
    }

    [children.STOP.apiName](data) {
        let url = children.STOP.url;
        return this[children.STOP.method](url, data);
    }

    [children.SERIAL.apiName](id) {
        let url = children.SERIAL.url + `/${id}`;
        return this[children.SERIAL.method](url);
    }

    getReceiptPlanList(id) {
        // 收货计划列表
        let url = `${prevUrl}/cargo/detail/${id}`;
        return this.GET(url);
    }

    getReceiptSum(id) {
        // 收货计划汇总
        let url = `${prevUrl}/count/${id}`;
        return this.GET(url);
    }

    getReceiptTallyList(params) {
        // 理货清单列表
        let url = `${prevUrl}/tally/detail/${params.id}`;
        return this.POST(url, params);
    }

    getReceiptTallySum(id) {
        // 理货清单汇总
        let url = `${prevUrl}/statisticsAccept/${id}`;
        return this.GET(url);
    }

    getReceiptUpperList(params) {
        // 上架清单列表
        let url = `${prevUrl}/storage/detail/${params.id}`;
        return this.POST(url, params);
    }

    getReceiptUpperSum(id) {
        // 上架清单汇总
        let url = `${prevUrl}/statisticsShelves/${id}`;
        return this.GET(url);
    }

    saveReceiptUpperStorage({ id, planStorageNumber }) {
        // 上架清单库位保存
        let url = `${prevUrl}/storage/save/${id}`;
        return this.GET(url, { planStorageNumber });
    }

    uploadSerialNumber(params) {
        let url = `wms/receiptScanList/import`;
        return this.POST(url, params);
    }
}
