const m = "finance/payableReconciliation";

export const id = {
    id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING",
    method: "POST",
    name: "应付款跟踪",
    type: "menu",
    apiName: "getPayableTrackList",
    url: `${m}/payableTrackList`
};

export const children = {
    CHANGE_PAYMENT: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING_CHANGE_PAYMENT",
        apiName: "changeTrackPayment",
        method: "POST",
        name: "新增/编辑付款清单",
        type: "view",
        url: `${m}/saveOrUpdatePayment`
    },
    DEL_PAYMENT: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING_DEL_PAYMENT",
        apiName: "removeTrackPayment",
        method: "POST",
        name: "删除付款清单",
        type: "view",
        url: `${m}/removePayment`
    },
    WRITEOFF: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING_WRITEOFF",
        apiName: "writeOffTrackPayment",
        method: "GET",
        name: "完成核销",
        type: "view",
        url: `${m}/writeOff`
    },
    ALL: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING_ALL",
        name: "全部",
        type: "view"
    },
    DQK: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING_DQK",
        name: "待请款",
        type: "view"
    },
    QKZ: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING_QKZ",
        name: "请款中",
        type: "view"
    },
    YQK: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING_YQK",
        name: "已请款",
        type: "view"
    },
    YHX: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING_YHX",
        name: "已核销",
        type: "view"
    },
    LOOK: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_TRACKING_LOOK",
        name: "查看",
        type: "view"
    },
    MAINTENANCE: {
        id: "FINANCIAL_MANAGEMENT_PAYABLE_MAINTENANCE",
        name: "付款维护",
        type: "view"
    }
};
