import React from "react";
import { Modal, Button, message, Select } from "antd";
import QuotationTable from "./quotation_table";
import RemoteSelect from "@src/components/select_databook";
// import rApi from '@src/http'
import { quotationTypes } from "./utils";

const { Option } = Select;

export default class ModalQuotation extends React.Component {
    constructor(props) {
        super(props);
        if (props.getThis) {
            props.getThis(this);
        }
        this.state = {
            loading: false,
            visible: false,
            quotationTypeOpts: [], // 报价类型[{ key, label }]
            businessModeName: "",
            businessModeId: null
        };
    }

    show = () => {
        this.setState({ visible: true });
    };

    close = () => {
        // console.log('close')
        this.setState({
            visible: false,
            quotationTypeOpts: [], // 报价类型[{ key, label }]
            businessModeName: "",
            businessModeId: null
        });
    };

    // 修改报价类型
    selectQuotationTypes = (quotationTypeOpts) => {
        // console.log('selectQuotationTypes', quotationTypeOpts)
        this.setState({ quotationTypeOpts });
    };

    // 开始匹配
    matching = async () => {
        this.setState({ loading: true });
        try {
            const { businessModeId, quotationTypeOpts } = this.state;
            if (!quotationTypeOpts || !quotationTypeOpts.length) {
                throw { name: "valid", msg: "请选择报价类型" };
            }
            if (!businessModeId) {
                throw { name: "valid", msg: "请选择运作模式" };
            }
            if (this.quotationTable) {
                const { expenseItem, addressDTOList } = this.quotationTable.state;
                if (!expenseItem.showTitle) {
                    throw { name: "valid", msg: "请选择费用项基准" };
                }
                if (
                    !addressDTOList ||
                    !addressDTOList.length ||
                    addressDTOList.some((item) => !item.senderProvince || !item.receiveProvince)
                ) {
                    throw { name: "valid", msg: "请选择起运地目的地" };
                }
                this.quotationTable.searchCriteria(() => {
                    this.setState({ loading: false });
                });
            }
        } catch (error) {
            console.log(error);
            message.error(error.msg || "操作失败");
            this.setState({ loading: false });
        }
    };

    render() {
        const { visible, quotationTypeOpts, businessModeName, businessModeId, loading } = this.state;
        return (
            <Modal
                ref={(v) => (this.view = v)}
                // style={{ left: 120 }}
                maskClosable={false}
                width={1220}
                visible={visible}
                title="匹配报价"
                onCancel={this.close}
                footer={null}
                bodyStyle={{
                    padding: "10px"
                }}
                style={{
                    minHeight: 300
                }}
            >
                {visible && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingBottom: 6,
                            borderBottom: "1px solid #d3d3d3"
                        }}
                    >
                        <div
                            style={{
                                display: "flex"
                            }}
                        >
                            <Select
                                mode="multiple"
                                placeholder="报价类型"
                                value={quotationTypeOpts}
                                onChange={this.selectQuotationTypes}
                                style={{ width: 380 }}
                                size={window._size}
                                labelInValue
                            >
                                {quotationTypes.map(({ id, title }) => (
                                    <Option key={id} title={title}>
                                        {title}
                                    </Option>
                                ))}
                            </Select>
                            <RemoteSelect
                                style={{
                                    width: 120,
                                    marginLeft: 10
                                }}
                                placeholder="运作模式"
                                text="业务模式"
                                dealData={(arr) => arr.filter((item) => item.title !== "报关（纯关务）")}
                                onChangeValue={(e) => {
                                    let id = e ? e.id : null;
                                    this.setState({
                                        businessModeId: id,
                                        businessModeName: e ? e.title : ""
                                    });
                                }}
                                // getPopupContainer={() => this.rootDom}
                            />
                        </div>
                        <Button type="primary" onClick={this.matching} loading={loading} size={window._size}>
                            开始匹配
                        </Button>
                    </div>
                )}
                {visible && (
                    <QuotationTable
                        parent={this}
                        getInstance={(v) => (this.quotationTable = v)}
                        quotationTypeOpts={quotationTypeOpts}
                        businessModeId={businessModeId}
                        businessModeName={businessModeName}
                    />
                )}
            </Modal>
        );
    }
}
