import request from "@src/http";
import _ from "@src/utils/lodash";
import { message } from "antd";
const rApi: any = request;
// import { AuthDef } from '@src/pages/monitor/order/index.module'
// import _ from 'lodash'
// import { message } from 'antd'
// import moment from 'moment'
// import { sleep } from '@src/libs/util'
import update from "immutability-helper";

type nullOr<T> = null | T;

export interface IMaterial {
    materialsId: nullOr<number>;
    materialNumber: nullOr<string>; // 料号
    materialName: nullOr<string>; // 品名
    itemSpecifications: nullOr<string>; // 规格
    quantity: number; // 件数
    boxCount: number; // 件数
    volume: number; // 体积
    weight: number; // 重量
    netWeight: number; // 净重
    billingWeight: number; // 计费重
}
export interface IReceiverSenderList {
    senderId: string;
    senderName: string;
    senderContact: string;
    senderPhoneNumber: string;
    senderAddress: string;
    receiverId: string;
    receiverName: string;
    receiverContact: string;
    receiverPhoneNumber: string;
    receiverAddress: string;
    materialList: IMaterial[];
    [key: string]: any;
}
export interface IState {
    liftingModeName: string; // 配送模式
    orderNumber: string;
    customerNumber: string;
    clientName: string;
    clientId: number;
    departureTime: string;
    arrivalTime: string;
    billingMethodName: string;
    billingMethodId: number;
    openDeliveryPassword: boolean;
    agingName: string;
    agingId: number;
    remark: string;
    changeState: () => void;
    deliveryRequirements: string; //送货要求
    salesmanName: string;
    salesmanId: number;
    salesmanPhoneNumber: number;
    getFieldDecorator: () => void;
    setFieldsValue: () => void;
    businessRepresentativeList: any[]; //业务员
    contacts: any[]; //联系人
    passwordRecipientList: any[]; //联系人
    receiverSenderList: IReceiverSenderList[];
    unload: string;
    arrive: string;

    [key: string]: any;
}

export function getNewGoodsRow(): IMaterial {
    return {
        materialsId: null,
        materialNumber: null,
        materialName: null,
        itemSpecifications: null,
        quantity: 0,
        boxCount: 0,
        weight: 0,
        volume: 0,
        netWeight: 0,
        billingWeight: 0
    };
}

function initState(): IState {
    return {
        pageLoading: false,
        liftingModeName: "一提一送", // 配送模式
        orderNumber: null,
        customerNumber: null,
        clientName: null,
        clientId: null,
        departureTime: null,
        arrivalTime: null,
        billingMethodName: null,
        billingMethodId: null,
        openDeliveryPassword: false,
        agingName: null,
        agingId: null,
        remark: null,
        changeState: null,
        deliveryRequirements: null,
        salesmanName: null,
        salesmanId: null,
        salesmanPhoneNumber: null,
        getFieldDecorator: null,
        setFieldsValue: null,
        businessRepresentativeList: [], //业务员
        contacts: [], //联系人
        passwordRecipientList: [], //发送的联系人
        receiverSenderList: [],
        unload: null,
        arrive: null
    };
}

export default {
    namespace: "p_order",
    state: initState(),
    effects: {
        *initData({ payload }: any, { select, call, put }: any) {
            yield put({
                type: "changeState",
                payload: {
                    pageLoading: true
                }
            });
            try {
                const res = yield call(rApi.getOneOrderDetails, payload);
                yield put({
                    type: "changeState",
                    payload: {
                        ...res
                    }
                });
            } catch (error) {
                console.log(error);
                message.error(error.msg || "获取发货单明细出错");
            } finally {
                yield put({
                    type: "changeState",
                    payload: {
                        pageLoading: false
                    }
                });
            }
        },
        *getDefaultCee({ payload }: any, { select, call, put }: any) {
            yield put({
                type: "changeState",
                payload: {
                    pageLoading: true
                }
            });
            try {
                const res = yield call(rApi.consignee_getDefaultByClient, payload);
                yield put({
                    type: "changeReceiverSenderList",
                    payload: {
                        type: "update",
                        index: 0,
                        mergeObj: {
                            receiverName: res?.name,
                            receiverId: res?.receiverOrSenderId,
                            receiverContact: res?.contactName,
                            receiverPhoneNumber: res?.contactNumber,
                            receiverAddress: res
                                ? {
                                      pro: res?.pro,
                                      city: res?.city,
                                      dist: res?.dist,
                                      street: res?.street,
                                      extra: res?.extra,
                                      formatAddress: res?.address
                                  }
                                : null,
                            receiverLatitude: res?.latitude,
                            receiverLongitude: res?.longitude,
                            receiverAreaName: res?.areaName
                        }
                    }
                });
            } catch (error) {
                console.log(error);
                message.error(error.msg || "获取发货单明细出错");
            } finally {
                yield put({
                    type: "changeState",
                    payload: {
                        pageLoading: false
                    }
                });
            }
        },
        *getDefaultCer({ payload }: any, { select, call, put }: any) {
            yield put({
                type: "changeState",
                payload: {
                    pageLoading: true
                }
            });
            try {
                const res = yield call(rApi.consigner_getDefault);
                yield put({
                    type: "changeReceiverSenderList",
                    payload: {
                        type: "update",
                        index: 0,
                        mergeObj: {
                            senderName: res?.name,
                            senderId: res?.receiverOrSenderId,
                            senderContact: res?.contactName,
                            senderPhoneNumber: res?.contactNumber,
                            senderAddress: res
                                ? {
                                      pro: res?.pro,
                                      city: res?.city,
                                      dist: res?.dist,
                                      street: res?.street,
                                      extra: res?.extra,
                                      formatAddress: res?.address
                                  }
                                : null,
                            senderLatitude: res?.latitude,
                            senderLongitude: res?.longitude,
                            senderAreaName: res?.areaName
                        }
                    }
                });
            } catch (error) {
                console.log(error);
                message.error("获取默认发货方出错:" + error.msg || error.message);
            } finally {
                yield put({
                    type: "changeState",
                    payload: {
                        pageLoading: false
                    }
                });
            }
        }
    },
    reducers: {
        changeState(state: any, { payload }: any) {
            // console.log("changeState", payload);
            return {
                ...state,
                ...payload
            };
        },
        changeReceiverSenderList(state: any, { payload }: any) {
            // console.log("changeReceiverSenderList", payload);

            let _receiverSenderList = _.cloneDeep(state.receiverSenderList);
            switch (payload.type) {
                case "update":
                    if (!_receiverSenderList[payload.index]) {
                        _receiverSenderList[payload.index] = {};
                    }
                    _receiverSenderList = update(_receiverSenderList, {
                        [payload.index]: {
                            $merge: payload.mergeObj
                        }
                    });
                    break;
                case "addMaterial":
                    if (!_receiverSenderList[payload.index]) {
                        _receiverSenderList[payload.index] = {};
                    }
                    if (!_receiverSenderList[payload.index]["materialList"]) {
                        _receiverSenderList[payload.index]["materialList"] = [];
                    }
                    _receiverSenderList = update(_receiverSenderList, {
                        [payload.index]: {
                            materialList: {
                                $push: [getNewGoodsRow()]
                            }
                        }
                    });
                    break;
                case "removeMaterial":
                    _receiverSenderList = update(_receiverSenderList, {
                        [payload.index]: {
                            materialList: {
                                $splice: [[payload.index, 1]]
                            }
                        }
                    });
                    break;
                case "updateMaterial":
                    _receiverSenderList = update(_receiverSenderList, {
                        [payload.index]: {
                            materialList: {
                                [payload.mIndex]: {
                                    $merge: payload.mergeObj
                                }
                            }
                        }
                    });
                    break;
            }
            return {
                ...state,
                receiverSenderList: _receiverSenderList
            };
        },
        clear(state: any) {
            return initState();
        }
    }
};
