import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "dva";

@connect(({ global }) => ({ powers: global.powers }))
class ModulesPower extends Component {
    static propTypes = {
        children: PropTypes.element.isRequired
    };

    state = {
        power: true
    };

    constructor(props) {
        super(props);
        const { info } = props;
        if (info.id) {
            // todo 权限处理
        }
    }

    render() {
        // const { power } = this.state
        // const Children = this.props.children
        const { info, children, powers } = this.props;
        if (process.env.NODE_ENV === "development") {
            return children;
        }
        if (!info.id || powers[info.id] || info.powerOther) {
            return children;
        } else {
            console.log("没有权限", powers, info.id);
            return (
                <div
                    style={{ width: "100%", minHeight: this.props.minHeight }}
                    className="flex flex-level-center flex-vertical-center"
                >
                    <h1>没有该项权限, 如有疑问请联系管理员!</h1>
                </div>
            );
        }
    }
}

export default ModulesPower;
