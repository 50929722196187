const m = "order/insurance/";
module.exports.id = {
    id: "BUSINESS_MANAGEMENT_INSURANCE_DETAILS",
    method: "POST",
    name: "运输保险详情",
    type: "menu_hide",
    apiName: "getTransportInsuranceById",
    url: `${m}getOne`,
    sort: 6
};

module.exports.children = {
    SAVE: {
        id: "BUSINESS_MANAGEMENT_INSURANCE_DETAILS_SAVE",
        apiName: "saveTransportInsurance",
        method: "POST",
        name: "保存",
        type: "view",
        url: `${m}save`
    }
};
