import React, { Component } from "react";
import { Menu, Dropdown, Icon, Modal, Select } from "antd";
import { headerHeight } from "../config";
import { connect } from "dva";
import version, { BUILD_DATE } from "@src/version";
import hosts from "@src/config/host.env.ts";
import _ from "@src/utils/lodash";

@connect(({ global }) => ({
    userName: global.userName
}))
export default class More extends Component {
    state = {
        visable: false
    };
    logOut = (event) => {
        let { dispatch } = this.props;
        if (event.key === "logout") {
            dispatch({ type: "global/closeAll" });
            dispatch({ type: "global/logout" });
        } else if (event.key === "personal") {
            dispatch({
                type: "global/openTab",
                payload: {
                    key: "PERSONAL_CENTER_MANAGEMENT",
                    id: "personal"
                }
            });
        } else if (event.key === "userinfo") {
        } else if (event.key === "about") {
            Modal.info({
                title: "关于",
                content: (
                    <div>
                        <div className="flex">
                            <div style={{ width: 80, textAlign: "end" }}>系统版本：</div>
                            <div>{version}</div>
                        </div>
                        <div className="flex">
                            <div style={{ width: 80, textAlign: "end" }}>Build编号：</div>
                            <div>{BUILD_DATE}</div>
                        </div>
                    </div>
                ),
                onOk() {}
            });
        } else if (event.key === "dev") {
            const selectKey = localStorage.getItem("hostKey");
            Modal.info({
                title: "dev 代理环境",
                content: (
                    <Select
                        style={{ minWidth: 300 }}
                        defaultValue={selectKey || "comma-sscm-local"}
                        onChange={(value) => localStorage.setItem("hostKey", value)}
                    >
                        {/* <Select.Option value='comma-sscm-local'> 
                            192.168.2.234
                        </Select.Option>
                        <Select.Option value='comma-sscm-online'>
                            sscm.smartcomma.com
                        </Select.Option> */}
                        {_.keys(hosts).map((item) => (
                            <Select.Option key={item} value={item}>
                                {hosts[item]}
                            </Select.Option>
                        ))}
                    </Select>
                ),
                onOk() {}
            });
        }
    };

    onVisibleChange = (visable) => {
        this.setState({
            visable: visable
        });
    };

    render() {
        const { userName } = this.props;
        let { visable } = this.state;
        let organizationName = localStorage.getItem("organizationName");
        const menu = (
            <Menu onClick={this.logOut}>
                <Menu.Item key="userinfo" className="userinfo">
                    <dl>
                        <dt>{userName}</dt>
                        <dd>{organizationName}</dd>
                    </dl>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="personal">
                    <div
                        style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <div>
                            <Icon type="edit" />
                            <span style={{ marginLeft: 10 }}>个人资料</span>
                        </div>
                        <Icon type="right" />
                    </div>
                </Menu.Item>
                <Menu.Item key="about">
                    <div
                        style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <div>
                            <Icon type="info-circle" />
                            <span style={{ marginLeft: 10 }}>关于</span>
                        </div>
                        {/* <Icon type="right" /> */}
                    </div>
                </Menu.Item>
                {process.env.NODE_ENV === "development" && (
                    <Menu.Item key="dev">
                        <div
                            style={{
                                padding: "5px 10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <div>
                                <Icon type="info-circle" />
                                <span style={{ marginLeft: 10 }}>开发环境</span>
                            </div>
                            {/* <Icon type="right" /> */}
                        </div>
                    </Menu.Item>
                )}
                <Menu.Item key="logout">
                    <div style={{ padding: "5px 10px" }}>
                        <Icon type="logout" /> <span style={{ marginLeft: 10 }}>注销</span>
                    </div>
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown trigger={["click"]} overlay={menu} onVisibleChange={this.onVisibleChange}>
                <div className="right-bottom flex1 more" style={{ height: headerHeight, cursor: "pointer" }}>
                    <span className="ant-dropdown-link">
                        {userName}
                        <Icon type="down" className={visable ? "icon-style" : "icon-trans"} />
                    </span>
                </div>
            </Dropdown>
        );
    }
}
