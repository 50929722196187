import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class LegalPersonApi {
    /**
     * 获取毛利分析列表数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.EXPORT_DATA.apiName](params) {
        let url = children.EXPORT_DATA.url;
        return this.POSTFILE(url, deleteNull(params));
    }

    getProfitCount(params) {
        // 获取合计
        let url = "finance/profit/count";
        return this.POST(url, params);
    }
}
