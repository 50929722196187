import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class DemandDetailsApi {
    /**
     * @param {any} params
     * @returns
     * @memberOf DemandDetailsApi
     */

    // // 确认
    // [children.CONFIRM_DATA.apiName](params) {
    //     let url = children.CONFIRM_DATA.url
    //     return this.POST(url, params)
    // }

    getQualityDetailsById(params) {
        //根据质检管理ID获取明细列表
        return this.GET(`wms/qualityManage/getDetailsList/${params.qualityManageId}/${params.limit}/${params.offest}`);
    }

    updateQualityDetails(params) {
        //更新详情
        return this.POST(`wms/qualityManage/update`, params);
    }

    completeQuality(params) {
        //完成质检
        return this.GET(`wms/qualityManage/completeQuality/${params.id}`);
    }

    startQuality(params) {
        //开始质检
        return this.GET(`wms/qualityManage/startQuality/${params.id}`);
    }

    getQualityManageDetailsById(params) {
        //  获取单条数据
        return this.GET(`wms/qualityManage/getOne/${params.id}`);
    }
}
