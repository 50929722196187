export default class IndexApi {
    /**
     * 获取数据
     * @param {any} params
     * @returns
     */

    getStatisticsOrder(params) {
        // 获取状态数量统计
        return this.POST(`/order/order/dashboard/statisticsOrder`, params);
    }

    getOrderInfo(params) {
        // 获取发货单信息
        return this.POST(`/order/order/dashboard/orderInfo`, params);
    }
}
