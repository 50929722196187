const m = "wms/shipmentManage";
const m2 = "wms/waveStrategy";
const m3 = "wms/shipment";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE",
    method: "POST",
    name: "出货管理",
    type: "menu",
    apiName: "getShipmentManage",
    url: `${m}/list`
};

export const children = {
    BATCH_DELETE: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_BATCH_DELETE",
        apiName: "deleteShipmentManage",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${m}/deleteByIds`
    },
    OPEN_WAVE: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_OPEN_WAVE",
        name: "开启波次计划",
        type: "view",
        method: "GET",
        apiName: "pickShipmentDetails",
        url: `${m}/quicklyPick`
    },
    GET_STRATEGY: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_GET_STRATEGY",
        name: "获取波次策略",
        type: "view",
        apiName: "getShipmentWave",
        method: "POST",
        url: `${m2}/list`,
        isOnlyApi: true
    },
    ADD_STRATEGY: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_ADD_STRATEGY",
        name: "另存为波次策略",
        type: "view"
    },
    UPDATE_STRATEGY: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_UPDATE_STRATEGY",
        name: "更新策略",
        type: "view",
        apiName: "shipmentWaveSave",
        method: "POST",
        url: `${m2}/save`
    },
    ADD_WAVE: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_ADD_WAVE",
        name: "生成波次计划",
        type: "view",
        apiName: "addShipmentWave",
        method: "GET",
        url: `${m2}/generateWavePlan`,
        paramsType: 1
    },

    DISTRIBUTION: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DISTRIBUTION",
        name: "批量系统分配",
        type: "view",
        apiName: "batchAssignAuto",
        method: "POST",
        url: `${m}/batchAssignAuto`
    }
    // ADD_PICK: {
    //     id: 'WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_ADD_PICK',
    //     name: '生成拣货单',
    //     type: 'view',
    //     apiName: 'addShipmentPick',
    //     method: 'POST',
    //     url: `${m3}/pick`
    // },
};
