import React from "react";

export default function () {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
        >
            <g id="监管" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="合同管理" transform="translate(-20.000000, -446.000000)" fill="currentColor">
                    <g id="合同" transform="translate(20.000000, 446.000000)">
                        <path
                            d="M11,0 C11.5522847,-1.01453063e-16 12,0.44771525 12,1 L12,5 L11,5 L11,1 L1,1 L1,13 L5,13 L5,14 L1,14 C0.44771525,14 6.76353751e-17,13.5522847 0,13 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L11,0 Z"
                            id="形状结合"
                        ></path>
                        <polygon id="Path" fillRule="nonzero" points="2 2 10 2 10 3 2 3"></polygon>
                        <polygon id="Path" fillRule="nonzero" points="2 4 10 4 10 5 2 5"></polygon>
                        <polygon id="Path" fillRule="nonzero" points="2 6 5 6 5 7 2 7"></polygon>
                        <polygon id="Path" fillRule="nonzero" points="2 8 5 8 5 9 2 9"></polygon>
                        <path
                            d="M11,7 C13.2,7 15,8.8 15,11 C15,13.2 13.2,15 11,15 C8.8,15 7,13.2 7,11 C7,8.8 8.8,7 11,7 M11,6 C8.25,6 6,8.25 6,11 C6,13.75 8.25,16 11,16 C13.75,16 16,13.75 16,11 C16,8.25 13.75,6 11,6 L11,6 Z"
                            id="形状"
                            fillRule="nonzero"
                        ></path>
                        <path
                            d="M11.3289111,8.19594302 L11.9867333,9.67640141 C12.0689611,9.76348719 12.1511889,9.85057298 12.2334167,9.85057298 L13.7135167,10.0247446 C13.9602,10.0247446 14.1246556,10.4601735 13.8779723,10.6343451 L12.7267834,11.6793745 C12.6445556,11.7664603 12.6445556,11.8535461 12.6445556,12.0277177 L12.9734667,13.5952618 C13.0556945,13.8565192 12.7267834,14.1177766 12.4801,13.943605 L11.1644556,13.1598329 C11.0822278,13.0727471 10.9177722,13.0727471 10.8355444,13.1598329 L9.51989998,13.943605 C9.27321664,14.1177766 9.0265333,13.8565192 9.0265333,13.5952618 L9.35544442,12.0277177 C9.35544442,11.9406319 9.35544442,11.7664603 9.27321664,11.6793745 L8.12202774,10.6343451 C7.8753444,10.4601735 8.03979996,10.1118303 8.28648329,10.0247446 L9.76658332,9.85057298 C9.84881109,9.85057298 10.0132667,9.76348719 10.0132667,9.67640141 L10.6710889,8.19594302 C10.8355444,7.93468566 11.1644556,7.93468566 11.3289111,8.19594302 Z"
                            id="路径"
                            fillRule="nonzero"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
