const moduleName = "account/";
export const id = {
    id: "USER_RIGHTS_MANAGEMENT_BASE_DEPARTMENT",
    method: "POST",
    name: "组织结构",
    type: "menu",
    apiName: "getOrganization",
    url: `${moduleName}organization/list`
};

// let moduleName = ''
export const children = {
    // GET_LIST: {
    //     id: 'USER_RIGHTS_MANAGEMENT_BASE_DEPARTMENT_GET_LIST',
    //     apiName: 'getOrganization',
    //     method: 'GET',
    //     name: '获取列表',
    //     type: 'view',
    //     url: `${moduleName}organization/list`
    // },
    ADD_DATA: {
        id: "USER_RIGHTS_MANAGEMENT_BASE_DEPARTMENT_ADD_DATA",
        apiName: "addOrganization",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}organization/create`
    },
    DEL_DATA: {
        id: "USER_RIGHTS_MANAGEMENT_BASE_DEPARTMENT_CODE",
        apiName: "delOrganization",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}organization/delete`
    },
    EDIT_DATA: {
        id: "USER_RIGHTS_MANAGEMENT_BASE_DEPARTMENT_EDIT_DATA",
        apiName: "editOrganization",
        method: "POST",
        name: "保存",
        type: "view",
        url: `${moduleName}organization/edit`
    },
    ENABLE: {
        id: "USER_RIGHTS_MANAGEMENT_BASE_DEPARTMENT_ENABLE",
        apiName: "enableOrganization",
        method: "POST",
        name: "开启",
        type: "view",
        url: `${moduleName}organization/enable`
    },
    DISABLE: {
        id: "USER_RIGHTS_MANAGEMENT_BASE_DEPARTMENT_DISBALE",
        apiName: "disableOrganization",
        method: "POST",
        name: "禁用",
        type: "view",
        url: `${moduleName}organization/disable`
    },
    GRANT_LIST: {
        id: "USER_RIGHTS_MANAGEMENT_BASE_DEPARTMENT_POWER",
        method: "POST",
        name: "获取组织功能权限",
        type: "view",
        apiName: "getOrganizationPower",
        url: `${moduleName}organization/permission/list`
    }
};
