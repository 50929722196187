import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class EditAllocate {
    /**
     * 获取配载编辑数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf UserApi
     */

    // [children.SAVE_DATA.apiName](params) {
    //     let url = children.SAVE_DATA.url
    //     return this.POST(url, params)
    // }

    [children.CONFIRM_DATA.apiName](params) {
        let url = children.CONFIRM_DATA.url + "/" + (params.id ? params.id : "");
        return this.GET(url, params);
    }

    [children.CANCEL_DATA.apiName](params) {
        let url = children.CANCEL_DATA.url + "/" + (params.id ? params.id : "");
        return this.GET(url, params);
    }

    // [children.CANCEL_DATA.apiName](params) {
    //     let url = children.CANCEL_DATA.url + '/' + (params.id ? params.id : '')
    //     return this.POST(url, params)
    // }
    // /payableSettlement/getOne/
    getEstimateExpenseAccountsPayableSettle(params) {
        let url = "finance/payableSettlement/getOne/" + (params.id ? params.id : "");
        return this.GET(url, params);
    }
    getPayableSettlementSettlementExpense(params) {
        let url = "finance/payableSettlement/getSettlementExpense/" + (params.id ? params.id : "");
        return this.GET(url, params);
    }

    reqAllocateOneDataSettle(params) {
        //获取单个配载单
        let url = `finance/payableSettlement/basicInfo/${params.id}`;
        return this.GET(url, params);
    }

    stowageEditExpenseSettle(params) {
        // 保存费用
        let url = `finance/payableSettlement/editExpense`;
        return this.POST(url, params);
    }

    stowageRouteSettle(params) {
        //获取配载的收发货方
        let url = `finance/stowage/stowageRouteSettle/${params.id}`;
        return this.GET(url, params);
    }

    payableSettlementEdit(params) {
        //获取配载的收发货方
        let url = `finance/payableSettlement/edit`;
        return this.POST(url, params);
    }

    lockPayable(params) {
        let url = `finance/payableSettlement/lock/${params.id}`;
        return this.GET(url, params);
    }

    unLockPayable(params) {
        const url = `finance/payableSettlement/unLock/${params.id}`;
        return this.GET(url, params);
    }

    updateBillingWeightPayable(params) {
        const url = `finance/payableSettlement/updateBillingWeight`;
        return this.POST(url, params);
    }

    switchSettlementPayQuotationCost(params) {
        const url = `finance/payableSettlement/switch/billingMethod/quotation/${params.id}`;
        return this.GET(url, params);
    }

    switchSettlementPayTempCost(params) {
        const url = `finance/payableSettlement/switch/billingMethod/temp/${params.id}`;
        return this.GET(url, params);
    }
    payableSettlementRejected(params) {
        const url = `finance/payableSettlement/rejected`;
        return this.POST(url, params);
    }
    payableSettlementPass(params) {
        const url = `finance/payableSettlement/review/${params.id}`;
        return this.GET(url, params);
    }
    payableSettlementBatchLockConfirm(ids) {
        const url = `finance/payableSettlement/lock/batchConfirm`;
        return this.POST(url, ids);
    }
    payableSettlementBatchRejected(ids) {
        const url = `finance/payableSettlement/batchRejected`;
        return this.POST(url, ids);
    }
    payableSettlementBatchPass(ids) {
        const url = `finance/payableSettlement/batchReview`;
        return this.POST(url, ids);
    }
}
