export const id = {
    id: "RECEIVABLE_MANAGEMENT_VERIFICATION",
    method: "POST",
    name: "收款核销",
    type: "menu",
    sort: 3
};

export const children = {
    VERIFY_BILL: {
        id: "RECEIVABLE_MANAGEMENT_VERIFICATION_VERIFY_BILL",
        method: "POST",
        name: "账单核销",
        type: "view"
    },
    EXCEPTION_VERIFY_BILL: {
        id: "RECEIVABLE_MANAGEMENT_VERIFICATION_EXCEPTION_VERIFY_BILL",
        method: "POST",
        name: "异常核销",
        type: "view"
    },
    EXPORT_BILL: {
        id: "RECEIVABLE_MANAGEMENT_VERIFICATION_EXPORT_BILL",
        method: "POST",
        name: "导出数据",
        type: "view"
    },
    CONFIRM_BILL: {
        id: "RECEIVABLE_MANAGEMENT_VERIFICATION_CONFIRM_BILL",
        method: "POST",
        name: "复核确认",
        type: "view"
    },
    APPLY_BILL: {
        id: "RECEIVABLE_MANAGEMENT_VERIFICATION_APPLY_BILL",
        method: "POST",
        name: "开票申请",
        type: "view"
    }
};
