const moduleName = "wms/businessRuleProvisioning/";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_PROVISIONING",
    name: "预配规则",
    type: "menu",
    apiName: "getProvisioningList",
    url: `${moduleName}list`
};

export const children = {
    // GET_LIST: {
    //     id: 'WAREHOUSE_MANAGEMENT_BUSINESS_RULES_PROVISIONING_GET_LIST',
    //     apiName: 'getCarTypes',
    //     method: 'POST',
    //     name: '获取列表',
    //     type: 'view',
    //     url: `${moduleName}carType/getCarTypes`
    // },
    ADD_DATA: {
        id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_PROVISIONING_ADD_DATA",
        apiName: "addProvisioning",
        method: "POST",
        name: "新建",
        type: "view",
        url: `${moduleName}save`
    },
    DEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_PROVISIONING_CODE",
        apiName: "delProvisioning",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}delete`
    },
    EDIT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_PROVISIONING_EDIT_DATA",
        apiName: "editProvisioning",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}save`
    },
    LOOK_MORE: {
        id: "WAREHOUSE_MANAGEMENT_BUSINESS_RULES_PROVISIONING_LOOK_MORE",
        name: "查看",
        type: "view"
    }
};
