/**
import { children } from '@src/views/basic_data/wordbook/power';
 * 业务相关
 */
export const booktypes1 = [
    {
        key: "INDUSTRY",
        id: 1,
        text: "客户行业"
    },
    {
        key: "BUSINESS_TYPE",
        id: 2,
        text: "客户类型"
    },
    {
        key: "TYPE_OF_SHIPPING",
        id: 5,
        text: "运输方式"
    },
    {
        key: "DELIVERY_MODE",
        id: 6,
        text: "提货方式"
    },
    {
        key: "MATERIAL_TYPE",
        id: 8,
        text: "物料类型"
    },
    {
        key: "BUSINESS_MODE",
        id: 37,
        text: "业务模式"
    },
    {
        key: "SPECIAL_BUSINESS",
        id: 38,
        text: "特殊业务"
    },
    {
        key: "PACKAGING_MATERIALS",
        id: 56,
        text: "包装材料"
    },
    {
        key: "CAPITAL_TYPE",
        id: 67,
        text: "资本类型"
    },
    {
        key: "CUSTOMER_BUSINESS_TYPE",
        id: 68,
        text: "客户业务类型"
    },
    {
        key: "SERVICE_ITEMS",
        id: 69,
        text: "业务类型"
    },
    {
        key: "SHIPPING_TERMS",
        id: 70,
        text: "运输条款"
    },
    {
        key: "CARRIER_TYPE",
        id: 75,
        text: "承运商类型"
    },
    {
        key: "SPECIAL_REQUIREMENT",
        id: "SPECIAL_REQUIREMENT",
        text: "特殊要求"
    },
    {
        key: "PORT_CLEARANCE_REQUIREMENT",
        id: "PORT_CLEARANCE_REQUIREMENT",
        text: "过港要求"
    },
    {
        key: "PICK_UP_REQUIREMENT",
        id: "PICK_UP_REQUIREMENT",
        text: "提货要求"
    },
    {
        key: "DELIVERY_REQUIREMENT",
        id: "DELIVERY_REQUIREMENT",
        text: "送货要求"
    },
    {
        key: "PAYMENT_TYPE",
        id: "PAYMENT_TYPE",
        text: "发运方式"
    },
    {
        key: "AGING_ASK",
        id: "AGING_ASK",
        text: "时效要求"
    }
];

/**
 * 车辆相关
 */
export const booktypes2 = [
    {
        key: "CAR_KAIND",
        id: 9,
        text: "车种"
    },
    {
        key: "CAR_COUNT_UNIT",
        id: 10,
        text: "车辆计量单位"
    },
    {
        key: "CAR_COLOR",
        id: 45,
        text: "车辆颜色"
    },
    {
        key: "CAR_ENERGY_TYPE",
        id: 46,
        text: "能源类型"
    }
];

/**
 * 结算相关
 */
export const booktypes3 = [
    {
        key: "WORKING_TYPE",
        id: 11,
        text: "经营类型"
    },
    {
        key: "CURRENCY",
        id: 12,
        text: "币别"
    },
    {
        key: "PAYMENT_METHOD_IN",
        id: 14,
        text: "收款方式"
    },
    {
        key: "PAYMENT_METHOD_OUT",
        id: 15,
        text: "付款方式"
    },
    {
        key: "BILLING_METHOD",
        id: 16,
        text: "计费方式"
    },
    {
        key: "COST_TYPE",
        id: 17,
        text: "费用类型"
    },
    {
        key: "COST_USAGE",
        id: 26,
        text: "费用用途"
    },
    {
        key: "BILL_TYPE_NAME",
        id: 74,
        text: "单据类型"
    },
    {
        key: "INVOICE_TYPE",
        id: 71,
        text: "发票类型"
    },
    {
        key: "RECONCILIATION_CYCLE",
        id: 72,
        text: "对账周期"
    },
    {
        key: "SETTLEMENT_METHOD",
        id: 73,
        text: "结算方式"
    },
    {
        key: "APPORTIONMENT_METHOD",
        id: "APPORTIONMENT_METHOD",
        text: "分摊方式"
    },
    {
        key: "BANK",
        id: "BANK",
        text: "银行"
    }
];

/**
 * 异常相关
 */
export const booktypes4 = [
    // {
    //     key: 'ABNORMAL_TYPE',
    //     id: 50,
    //     text: '异常类型'
    // },
    {
        key: "ABNORMAL_STEP",
        id: 51,
        text: "异常环节"
    },
    {
        key: "ABNORMAL_RESPONSIBLE",
        id: 52,
        text: "责任方"
    },
    {
        key: "ABNORMAL_FROZEN",
        id: 53,
        text: "异常冻结"
    },
    {
        key: "TRANSPORT_ABNORMAL_TYPE",
        id: 54,
        text: "运输异常类型"
    },
    {
        key: "WAREHOUSE_ABNORMAL_TYPE",
        id: 55,
        text: "仓库异常类型"
    },
    {
        key: "WRITE_OFF_ABNORMAL_SUBJECT",
        id: "WRITE_OFF_ABNORMAL_SUBJECT",
        text: "核销异常科目"
    }
];

/**
 * 单位相关
 */
export const booktypes5 = [
    {
        key: "COST_UNIT",
        id: 25,
        text: "计量单位"
    },
    {
        key: "WEIGHT_UNIT",
        id: 29,
        text: "计重单位"
    },
    {
        key: "VOLUME_UNIT",
        id: 39,
        text: "计体单位"
    },
    {
        key: "AREA_UNIT",
        id: 47,
        text: "面积单位"
    },
    {
        key: "MEASUREMENT_UNIT",
        id: 48,
        text: "尺寸单位"
    },
    {
        key: "TIMES_UNIT",
        id: 49,
        text: "时间单位"
    },
    {
        key: "OTHER_UNIT",
        id: 40,
        text: "其他单位"
    }
];

/**
 * 标签标识
 */
export const booktypes6 = [
    {
        key: "CUSTOMER_LABEL",
        id: 18,
        text: "客户标签"
    },
    {
        key: "OPERATOR_LABEL",
        id: 19,
        text: "承运商标签"
    }
];

/**
 * 用户相关
 */
export const booktypes7 = [
    {
        key: "JOB",
        id: 24,
        text: "职位"
    },
    {
        key: "DEPARTMENT",
        id: "DEPARTMENT",
        text: "部门"
    }
];

//仓库相关
export const booktypes9 = [
    {
        key: "WAREHOUSE_RELATED",
        id: 30,
        text: "仓库类型"
    },
    {
        key: "RESERVOIR_TYPE",
        id: 31,
        text: "储位类型"
    },
    {
        key: "RECEIVE_METHOD",
        id: 32,
        text: "需求来源"
    },
    {
        key: "OPERATION_MODE",
        id: 33,
        text: "操作模式"
    },
    {
        key: "TYPE_OF_RECEIPT",
        id: 3,
        text: "收货类型"
    },
    {
        key: "SHIPPING_TYPE",
        id: 4,
        text: "出货类型"
    },
    {
        key: "SHIPPING_METHOD",
        id: 7,
        text: "出货方式"
    },
    {
        key: "PICK_DEMAND",
        id: 34,
        text: "拣货要求"
    },
    {
        key: "INVENTORY_METHOD",
        id: 35,
        text: "盘点方式"
    },
    // {
    //     key: 'DEMAND_SOURCE',
    //     id: 36,
    //     text: '需求来源'
    // },
    {
        key: "QUALITY_MAINTENANCE",
        id: 42,
        text: "品质维护"
    },
    {
        key: "UNIT_RELATED",
        id: 43,
        text: "单位相关"
    },
    {
        key: "QUALITY_INSPECTION",
        id: 44,
        text: "质检类型"
    },
    {
        key: "SPLIT_STRING",
        id: 60,
        text: "仓库分隔符"
    },
    {
        key: "WAREHOUSE_TYPE",
        id: 61,
        text: "仓库类别"
    },
    {
        key: "WAREHOUSE_ATTR",
        id: 62,
        text: "仓库属性"
    },
    {
        key: "AREA_CATEGORY",
        id: 63,
        text: "库区类别"
    },
    {
        key: "AREA_TYPE",
        id: 64,
        text: "库区/位类型"
    },
    {
        key: "AREA_ATTR",
        id: 65,
        text: "库区/位属性"
    },
    {
        key: "LOCATION_RESTRICTIONS",
        id: 57,
        text: "库位限制"
    },
    {
        key: "SPACE_LIMITATION",
        id: 58,
        text: "空间限制"
    },
    {
        key: "STORAGE_IN_TYPE",
        id: 59,
        text: "入库类型"
    }
];

/**
 * 其他
 */
export const booktypes8 = [
    {
        key: "AREA",
        id: 20,
        text: "片区"
    },
    {
        key: "COOPERATIVE_STATE",
        id: 21,
        text: "合作状态"
    },
    {
        key: "MAINTENANCE_TYPE",
        id: 22,
        text: "保养类型"
    },
    {
        key: "NODE_TYPE",
        id: 23,
        text: "中转地类型"
    },
    {
        key: "SECIAL_DESCRIPTION",
        id: 27,
        text: "发货单特殊说明"
    },
    {
        key: "LIFT_MODE",
        id: 28,
        text: "提送模式"
    }
    // {
    //     key:'FRD_PROJECT',
    //     id: 41,
    //     text: '富润德项目'
    // }
];

export const booktypes0 = [
    {
        key: "noned",
        id: 0,
        text: "无"
    }
];

/**
 *  报关相关
 */
export const booktypes10 = [
    {
        key: "CUSTOMS_METHOD",
        id: "CUSTOMS_METHOD",
        text: "报关方式"
    },
    {
        key: "CUSTOMS_BUSINESS_TYPE",
        id: "CUSTOMS_BUSINESS_TYPE",
        text: "报关业务类型"
    },
    {
        key: "CUSTOMS_PORT",
        id: "CUSTOMS_PORT",
        text: "进出口岸"
    },
    {
        key: "CUSTOMS_BROKER",
        id: "CUSTOMS_BROKER",
        text: "报关方"
    }
];

export const allbooktypes = [
    ...booktypes1,
    ...booktypes2,
    ...booktypes3,
    ...booktypes4,
    ...booktypes5,
    ...booktypes6,
    ...booktypes7,
    ...booktypes8,
    ...booktypes9,
    ...booktypes10
];
export const types = [
    {
        text: "业务相关",
        children: booktypes1
    },
    {
        text: "车辆相关",
        children: booktypes2
    },
    {
        text: "结算相关",
        children: booktypes3
    },
    {
        text: "报关相关",
        children: booktypes10
    },
    {
        text: "异常相关",
        children: booktypes4
    },
    {
        text: "单位相关",
        children: booktypes5
    },
    {
        text: "标签相关",
        children: booktypes6
    },
    {
        text: "用户相关",
        children: booktypes7
    },
    {
        text: "仓库相关",
        children: booktypes9
    },
    {
        text: "其他",
        children: booktypes8
    }
];
export default {
    booktypes0,
    booktypes1,
    booktypes2,
    booktypes3,
    booktypes4,
    booktypes5,
    booktypes6,
    booktypes7,
    booktypes8,
    booktypes9,
    booktypes10,
    allbooktypes
};
