import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class BoardApi {
    /**
     * 获取数据
     * @param {any} params
     * @returns
     * @memberOf BoardApi
     */
    // [power[id.id].apiName](params) {
    //     let url = power[id.id].url
    //     return this.POST(url, deleteNull(params))
    // }

    // [children.ADD_DATA.apiName](params) {
    //     let url = children.ADD_DATA.url
    //     return this.POST(url, params)
    // }

    //获取柱状图
    getReceiptHistogram(params) {
        return this.POST("wms/kanban/receipt/treeMap", params);
    }

    //获取饼图
    getReceiptPiechart(params) {
        return this.POST("wms/kanban/receipt/roundMap", params);
    }

    //获取看板列表图
    getReceiptMonitor(params) {
        return this.POST("wms/kanban/receipt/list", params);
    }
}
