const moduleName = "order/";
module.exports.id = {
    id: "BUSINESS_MANAGEMENT_ALLOCATE_LIST_EDIT",
    method: "POST",
    name: "运单明细",
    type: "menu_hide"
    // apiName: 'getUsers',
    // url: `${moduleName}list`
};

module.exports.children = {
    SAVE_DATA: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_SAVE_EDIT",
        apiName: "saveAllocateEdit",
        method: "POST",
        name: "保存",
        type: "view",
        url: `${moduleName}stowage/edit`,
        sort: 2
    },
    CONFIRM_DATA: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_CONFIRM_EDIT",
        apiName: "confirmAllocateEdit",
        method: "POST",
        name: "确认",
        type: "view",
        url: `${moduleName}stowage/confirm`
    },
    CANCEL_DATA: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_CANCEL_DATA",
        apiName: "cancelAllocateEdit",
        method: "POST",
        name: "取消确认",
        type: "view",
        url: `${moduleName}stowage/cancel`
    },
    QUOTATION_DETAILS: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_QUOTATION_DETAILS",
        name: "查看报价明细",
        type: "view"
    },
    SPECIAL: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_QUOTATION_SPECIAL",
        name: "配载特殊业务",
        type: "view"
    },
    HIDE_MONEY: {
        id: "BUSINESS_MANAGEMENT_ALLOCATE_HIDE_MONEY",
        name: "查看金额",
        type: "view"
    }
};
