import React, { Component } from "react";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import { DragSource, DropTarget, useDrop } from "react-dnd";
import FunctionPower from "@src/layout/power_view/function.jsx";
import ItemTypes from "./ItemTypes";
import debounce from "lodash/debounce";
import { fromHex } from "@src/utils";
import { Checkbox, Switch, InputNumber, Input, Icon } from "antd";

let dragingIndex = -1;
let nextIndex = null;

const cardSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index
        };
    },
    endDrag(props, monitor, component) {
        // if (!monitor.didDrop()) {
        //   return
        // }

        // When dropped on a compatible target, do something
        const item = monitor.getItem();
        const clientOffset = monitor.getClientOffset(); // 拖拽的位置信息
        if (props.isEndDrag) {
            props.isEndDrag(props.index, item.index, clientOffset, monitor.didDrop());
        }
    }
};

const cardTarget = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

        // Get vertical middle
        //	const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        //	const hoverClientY = clientOffset.y - hoverBoundingRect.top

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        // 	return
        // }

        // // Dragging upwards
        // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        // 	return
        // }

        // Time to actually perform the action
        if (props.moveCard) {
            props.moveCard(dragIndex, hoverIndex);
        }

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
        nextIndex = hoverIndex;
    }
};

const Iviews = (props) => {
    const {
        index,
        column,
        style,
        baseStyle,
        opacity,
        allCols,
        onChangeTitle,
        onEditHeaderTitle,
        onChangeWidth,
        onChangeCheckbox,
        noEditCheackBox,
        noEditWidth,
        noDrag,
        changeExport,
        showSettingExport
    } = props;

    return (
        <div
            className={(!noDrag && "select-column-item") || ""}
            style={{
                borderTop: index === 0 ? "1px solid #d7d7d7" : 0,
                borderBottom: "1px solid #d7d7d7",
                padding: 5,
                opacity,
                ...style
            }}
        >
            <div className="flex flex-vertical-center">
                <div className="flex1" style={{ ...baseStyle }}>
                    <div className="flex flex-vertical-center">
                        <div className="flex1">
                            <div
                                style={{
                                    background: fromHex(window._global._theme.global_primary_color, 0.2),
                                    paddingLeft: allCols[index]._edit ? 0 : 10,
                                    width: column.width || 100
                                }}
                            >
                                {allCols[index]._edit ? (
                                    <Input
                                        defaultValue={column.title}
                                        style={{ width: column.width || 100 }}
                                        size={window._size}
                                        onChange={(e) => onChangeTitle(e.target.value, index)}
                                    />
                                ) : (
                                    column.title
                                )}
                            </div>
                        </div>
                        <FunctionPower power={{ id: "EDIT_TITLE" }}>
                            <Icon
                                type="edit"
                                className="global_primary_color"
                                style={{
                                    margin: "0 10px "
                                }}
                                onClick={() => onEditHeaderTitle(index)}
                            />
                        </FunctionPower>
                    </div>
                </div>
                <div style={{ borderRight: "1px solid #d7d7d7", marginRight: 10, ...baseStyle }}>
                    <span>宽度：</span>
                    <InputNumber
                        defaultValue={column.width}
                        size={window._size}
                        onChange={(value) => onChangeWidth(value, index)}
                        placeholder="50-500"
                        disabled={noEditWidth || false}
                        min={50}
                        max={500}
                        step={10}
                    />
                    <span>&emsp;px&emsp;</span>
                </div>
                <div>
                    <Switch
                        checkedChildren="显示"
                        unCheckedChildren="隐藏"
                        defaultChecked={noEditCheackBox ? false : !column.isNoDisplay}
                        disabled={noEditCheackBox || false}
                        size={window._size}
                        onChange={(checked) => onChangeCheckbox(checked, index)}
                    />
                    {!!showSettingExport && (
                        <Checkbox
                            style={{ marginLeft: 10, paddingLeft: 10, borderLeft: "1px solid #d7d7d7" }}
                            checked={column.isExport}
                            onChange={(ev) => changeExport(ev.target.checked, index)}
                        >
                            导出
                        </Checkbox>
                    )}
                </div>
            </div>
        </div>
    );
};

@DropTarget(ItemTypes.CARD, cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
}))
@DragSource(ItemTypes.CARD, cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
}))
class Card extends Component {
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
        isDragging: PropTypes.bool.isRequired,
        moveCard: PropTypes.func, // 越过边界立即触发
        isEndDrag: PropTypes.func // 拖拽完成后释放鼠标触发
    };

    constructor(props) {
        super(props);
        this.onChangeWidth = debounce(this.onChangeWidth, 800); // 修改宽度防抖
        this.onChangeTitle = debounce(this.onChangeTitle, 800); // 修改表头防抖
    }

    // 修改表头
    onChangeTitle = (value, index) => {
        let { onChangeTitle } = this.props;
        if (onChangeTitle) {
            onChangeTitle(value, index);
        }
    };

    // 修改宽度
    onChangeWidth = (value, index) => {
        let { onChangeWidth } = this.props;
        if (value && value >= 50 && value && value <= 500) {
            if (onChangeWidth) {
                onChangeWidth(value, index);
            }
        }
    };

    render() {
        const {
            index,
            column,
            lastColumn, // 最后一项
            Item,
            onChangeCheckbox, // 是否显示
            onEditHeaderTitle, // 点击编辑表头按钮
            isDragging,
            connectDragSource,
            connectDropTarget,
            filterSortItems,
            isOver,
            allCols,
            changeExport,
            showSettingExport
        } = this.props;
        const opacity = isDragging ? 0.5 : 1;
        let style = {};
        if (isOver) {
            if (index > dragingIndex) {
                style = {
                    borderBottom: "1px dashed #18B583"
                };
            } else if (index < dragingIndex) {
                style = {
                    borderTop: "1px dashed #18B583"
                };
            }
        }

        const baseStyle = { color: "#484848" };

        // if (filterSortItems.some(item => item === column.key)) { // 过滤项目
        // 	return null
        // }
        // if(!Item && (column.fixed || lastColumn)) {
        if (!Item && column.fixed) {
            // 允许last Column drag
            return (
                <div>
                    <Iviews
                        index={index}
                        column={column}
                        baseStyle={baseStyle}
                        style={style}
                        opacity={opacity}
                        allCols={allCols}
                        onChangeTitle={this.onChangeTitle}
                        onEditHeaderTitle={onEditHeaderTitle}
                        onChangeWidth={this.onChangeWidth}
                        onChangeCheckbox={onChangeCheckbox}
                        changeExport={changeExport}
                        noEditCheackBox={
                            column.fixed ? true : false
                            // || lastColumn
                        }
                        noEditWidth={lastColumn}
                        noDrag={
                            column.fixed ? true : false
                            // || lastColumn
                        }
                        showSettingExport={showSettingExport}
                    />
                </div>
            );
        }
        return connectDragSource(
            connectDropTarget(
                Item ? (
                    <div className="select-column-item" style={{ padding: "5px", ...style }}>
                        {<Item index={index} column={column} />}
                    </div>
                ) : (
                    <div>
                        <Iviews
                            index={index}
                            column={column}
                            baseStyle={baseStyle}
                            style={style}
                            opacity={opacity}
                            allCols={allCols}
                            onChangeTitle={this.onChangeTitle}
                            onEditHeaderTitle={onEditHeaderTitle}
                            onChangeWidth={this.onChangeWidth}
                            onChangeCheckbox={onChangeCheckbox}
                            changeExport={changeExport}
                            showSettingExport={showSettingExport}
                        />
                    </div>
                )
            )
        );
    }
}

export default Card;
