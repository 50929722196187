import { children, id } from "./power_hide";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

export default class EditAllocate {
    /**
     * 获取配载编辑数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf UserApi
     */

    [children.SAVE_DATA.apiName](params) {
        let url = children.SAVE_DATA.url;
        return this.POST(url, params);
    }

    [children.CONFIRM_DATA.apiName](params) {
        let url = children.CONFIRM_DATA.url + "/" + (params.id ? params.id : "");
        return this.POST(url, params);
    }

    [children.CANCEL_DATA.apiName](params) {
        let url = children.CANCEL_DATA.url + "/" + (params.id ? params.id : "");
        return this.POST(url, params);
    }

    getEstimateExpenseAccountsPayable(params) {
        let url = "order/stowage/getEstimateExpense/" + (params.id ? params.id : "");
        return this.GET(url, params);
    }

    stowageMandatoryModification(params) {
        //获取单个配载单
        let url = `order/stowage/mandatoryModification`;
        return this.POST(url, params);
    }

    reqAllocateOneData(params) {
        //获取单个配载单
        let url = `order/stowage/getOne/${params.id}`;
        return this.POST(url, params);
    }

    stowageEditExpense(params) {
        // 保存费用
        let url = `order/stowage/editExpense`;
        return this.POST(url, params);
    }

    reqAllocataOrders(params) {
        //获取配载的发货单
        let url = `order/stowage/sendCarOrders/${params.id}`;
        return this.GET(url, params);
    }

    stowageRoute(params) {
        //获取配载的收发货方
        let url = `order/stowage/stowageRoute/${params.id}`;
        return this.GET(url, params);
    }

    // 保存配载路由信息
    saveStowageRouteConfig(params) {
        const url = `order/stowageRouteConfig/save`;
        return this.POST(url, params);
    }

    // 获取地图追踪数据
    getStowageRouteConfig(params) {
        const url = `order/stowage/getStowageMapTrace/${params.id}`;
        return this.GET(url, params);
    }

    // 修改地图追踪数据
    updateStowageRouteConfig(params) {
        const url = `order/stowageRouteConfig/update/${params.id}`;
        return this.POST(url, params);
    }

    // 删除地图追踪数据
    delStowageRouteConfig(params) {
        const url = `order/stowageRouteConfig/delete/${params.id}`;
        return this.POST(url, params);
    }

    // 签收 0：正常签收 1：选择性签收
    getChooseSign(params) {
        const url = `order/stowage/chooseSign`;
        return this.POST(url, params);
    }

    updateBillingWeight(params) {
        const url = `order/stowage/updateBillingWeight`;
        return this.POST(url, params);
    }

    getCertificate(params) {
        const url = `order/app/sendCar/getAttachmentList/${params.id}`;
        return this.GET(url, params);
    }

    reDesignateTheCarrier(params) {
        // 重新指定承运商
        const url = `order/stowage/reDesignateTheCarrier/${params.id}`;
        return this.GET(url, params);
    }
    exportStowageRouteConfigTemplate(params) {
        // 空模板导出
        const url = `order/stowageRouteConfig/template`;
        return this.GETFILE(url, params);
    }
    importStowageRouteConfig(params) {
        // 地址导入接口
        const url = `order/stowageRouteConfig/batchImport`;
        return this.POST(url, params);
    }
}
