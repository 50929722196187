import axios from "axios";
import { app } from "@src/models";

const BasicAuth = "Basic YXBwOjEyMzQ1Ng==";
const defConfig = {
    timeout: 2 * 60 * 1000, // ten seconds
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    },
    withCredentials: true
};
export default class InitAxios {
    constructor() {
        this.instance = axios.create(Object.assign({}, defConfig));
        this._initRequestHeaders();
        this._interceptors();
    }

    _initRequestHeaders() {
        let instance = this.instance;
        instance.interceptors.request.use(
            (config) => {
                if (process.env.NODE_ENV === "development") {
                    const selectKey = localStorage.getItem("hostKey");
                    // console.log('selectKey', selectKey)
                    if (selectKey && selectKey.length > 0) {
                        if (selectKey === "comma-sscm-local-57") {
                            config.baseURL = `/${selectKey}`;
                        } else {
                            config.baseURL = `/${selectKey}/api`;
                        }
                    }
                } else {
                    config.baseURL = `/api`;
                }

                if (config.url !== "/account/company/info") {
                    const { isLogin, token } = app._store.getState().global;
                    // console.log('_initRequestHeaders', app._store.getState().global, isLogin, token)
                    if (isLogin && token && config.url !== "/web/token") {
                        config.headers.Authorization = `Bearer ${token}`;
                    } else {
                        config.headers.Authorization = BasicAuth;
                    }
                }
                // console.log("config",config);
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    getInstance() {
        return this.instance;
    }

    _interceptors() {
        let instance = this.instance;
        instance.interceptors.response.use(
            function (response) {
                if (response.status === 202 && response.data && response.data.errcode) {
                    if (response.data.errcode === 403 || response.data.errcode === 401) {
                        // console.log("ssssss1111----------");
                        // console.log("err res response", response);

                        app._store.dispatch({
                            type: "global/logout",
                            payload: {
                                toLogin: true
                            }
                        });
                    }
                }
                return response;
            },
            function (error) {
                // Do something with response error

                // let code = error.status || error.response.data.errcode || error.response.data.status || error.response.status
                let code = error.response
                    ? error.status || error.response.data.errcode || error.response.data.status || error.response.status
                    : error.status;
                if (code === 401 || code === 403) {
                    // console.log("ssssss22222----------");
                    // console.error("error", error, error.response);

                    app._store.dispatch({
                        type: "global/logout",
                        payload: {
                            toLogin: true
                        }
                    });
                    return Promise.reject(Error("鉴权失败"));
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }
}
