import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class ChargePayableApi {
    /**
     * @param {any} params
     * @returns
     *
     * @memberOf WarehouseManegementInventoryManageApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    [children.ADD_DATA.apiName](params) {
        let url = children.ADD_DATA.url;
        return this.POST(url, params);
    }

    [children.DELETE_DATA.apiName](params) {
        let url = children.DELETE_DATA.url + `/${params.id}`;
        return this.POST(url, params);
    }

    [children.EDIT_DATA.apiName](params) {
        let url = children.EDIT_DATA.url;
        return this.POST(url, params);
    }

    [children.EXPORT_LIST.apiName](params) {
        // 导出
        let url = children.EXPORT_LIST.url;
        return this.POSTFILE(url, params);
    }

    [children.EXPORT_DETAILS.apiName](params) {
        // 导出明细
        let url = children.EXPORT_DETAILS.url;
        return this.POSTFILE(url, params);
    }

    [children.AUTO_GENERATE.apiName](params) {
        // 生成预估单
        let url = children.AUTO_GENERATE.url;
        return this.GET(url, params);
    }

    getSpecialBusiness(params) {
        //获取单个特殊业务相关信息
        return this.POST(`order/stowage/getOneCarrier/${params.id}`);
    }

    getSpecNoEstimateList(params) {
        //预估单待开立列表
        return this.POST(`order/stowage/carrierList`, params);
    }

    getCarrierFilterList(params) {
        /* 获取承运商筛选列表 */
        return this.POST(`resource/carrier/getListAndCashCar`, params);
    }

    getCarrierById(params) {
        /* 获取单个承运商数据 */
        return this.POST(`resource/carrier/getOne/${params.id}`);
    }

    getPaymentEstimateById(params) {
        // 获取单个应付预估单
        let url = `finance/payableEstimate/getOne/${params.id}`;
        return this.GET(url, params);
    }

    getPayableEstimateDetails(params) {
        //获取应付预估明细
        return this.GET(`finance/payableEstimate/details/${params.id}`);
    }
}
