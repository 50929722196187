/**
 * Sort order for BaseTable
 */
const SortOrder = {
    /**
     * Sort data in ascending order
     */
    ASC: "asc",
    /**
     * Sort data in descending order
     */
    DESC: "desc"
};

export default SortOrder;
