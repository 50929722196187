import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class WarehouseManegementReceiptDemandApi {
    /**
     * 获取数据
     *
     * @param {any} params
     * @returns
     *
     * @memberOf WarehouseManegementReceiptDemandApi
     */
    [power[id.id].apiName](params) {
        let url = power[id.id].url;
        return this.POST(url, deleteNull(params));
    }

    getQualityManageWarehouseNames(params) {
        //获取列表仓库
        return this.GET("wms/qualityManage/getWarehouseNames");
    }

    getQualityManageClientNames(params) {
        //获取列表客户
        return this.GET("wms/qualityManage/getClientNames");
    }

    getQualityInspectionNames(params) {
        //获取列表质检人
        return this.GET("wms/qualityManage/getQualityInspectionNames");
    }
}
