import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { VariableSizeGrid as Grid } from "react-window";

import Header from "./TableHeader";

const rowHeights = new Array(1000).fill(true).map(() => 25 + Math.round(Math.random() * 50));

const getItemSize = (index) => rowHeights[index];

const Row = ({ index, style }) => <div style={style}>Row {index}</div>;

// const Example = () => (
//   <Grid
//     height={150}
//     itemCount={1000}
//     itemSize={getItemSize}
//     width={300}
//   >
//     {Row}
//   </Grid>
// );

/**
 * A wrapper of the Grid for internal only
 */
class GridTable extends React.Component {
    constructor(props) {
        super(props);

        this._setHeaderRef = this._setHeaderRef.bind(this);
        this._setBodyRef = this._setBodyRef.bind(this);
        this._setFooterRef = this._setFooterRef.bind(this);
        this._itemKey = this._itemKey.bind(this);
        this._handleItemsRendered = this._handleItemsRendered.bind(this);

        this.renderRow = this.renderRow.bind(this);
    }

    componentDidUpdate(preProps) {
        if (
            this.props.getRowHeight &&
            preProps.data.length !== this.props.length &&
            this.bodyRef &&
            this.bodyRef.resetAfterRowIndex
        ) {
            // console.log('resetAfterIndex', this.bodyRef.resetAfterRowIndex)
            // this.bodyRef.resetAfterIndex(0)
            // this.bodyRef.resetAfterIndex(0, 0)
            this.bodyRef.resetAfterRowIndex(0);
        }
        return null;
    }

    forceUpdateTable() {
        this.headerRef && this.headerRef.forceUpdate();
        // this.footerRef && this.footerRef.forceUpdate();
        this.bodyRef && this.bodyRef.forceUpdate();
    }

    scrollToPosition(args) {
        this.headerRef && this.headerRef.scrollTo(args.scrollLeft);
        this.footerRef && (this.footerRef.scrollLeft = args.scrollLeft);
        this.bodyRef && this.bodyRef.scrollTo(args);
    }

    scrollToTop(scrollTop) {
        this.bodyRef && this.bodyRef.scrollTo({ scrollTop });
    }

    scrollToLeft(scrollLeft) {
        this.headerRef && this.headerRef.scrollTo(scrollLeft);
        this.footerRef && (this.footerRef.scrollLeft = scrollLeft);
        this.bodyRef && this.bodyRef.scrollToPosition({ scrollLeft });
    }

    scrollToRow(rowIndex = 0, align = "auto") {
        this.bodyRef && this.bodyRef.scrollToItem({ rowIndex, align });
    }

    renderRow(args) {
        const { data, columns, rowRenderer, bodyWidth } = this.props;
        const rowData = data[args.rowIndex];
        // console.log('rowData', rowData, rowData.isEdit)
        // if (args.rowIndex === 2)
        // console.log('renderRow', rowData, args)
        // if (args && args.style && args.style.width) {
        //   args.style.width = bodyWidth
        // }
        return rowRenderer({
            ...args,
            columns,
            rowData
        });
    }

    render() {
        const {
            containerStyle,
            classPrefix,
            className,
            data,
            frozenData,
            width,
            height,
            rowHeight,
            getRowHeight,
            headerWidth,
            bodyWidth,
            useIsScrolling,
            onScroll,
            hoveredRowKey,
            overscanRowCount,
            // omit from rest
            style,
            onScrollbarPresenceChange,
            CustomerTable,
            ...rest
        } = this.props;
        const headerHeight = this._getHeaderHeight();
        const frozenRowCount = frozenData.length;
        let h = 0;
        if (getRowHeight) {
            frozenData.forEach((ele, index) => {
                h = h + getRowHeight(ele, index);
            });
        } else {
            h = rowHeight * frozenRowCount;
        }
        const frozenRowsHeight = h;
        const cls = cn(`${classPrefix}__table`, className);
        const containerProps = containerStyle ? { style: containerStyle } : null;
        // return <Example / >

        // console.log('height', Math.max(height - headerHeight - frozenRowsHeight, 0))
        // console.log('this._setBodyRef', this.bodyRef)
        // if (CustomerTable) {
        //   console.log('rest', rest)
        // }
        // console.log('width', width, bodyWidth)
        return (
            <div role="table" className={cls} {...containerProps}>
                {CustomerTable ? (
                    // <CustomerTable
                    //   {...rest}
                    //   className={`${classPrefix}__body`}
                    //   reff={this._setBodyRef}
                    //   data={data}
                    //   itemKey={this._itemKey}
                    //   frozenData={frozenData}
                    //   width={width}
                    //   height={Math.max(height - headerHeight - frozenRowsHeight, 0)}
                    //   rowHeight={index => {
                    //     const hi = getRowHeight && getRowHeight(data[index], index) || rowHeight
                    //     // console.log('hi', hi, data[index].id, data[index])
                    //     return hi
                    //   }}
                    //   rowCount={data.length}
                    //   overscanRowCount={overscanRowCount}
                    //   columnWidth={bodyWidth}
                    //   columnCount={1}
                    //   overscanColumnCount={0}
                    //   useIsScrolling={useIsScrolling}
                    //   hoveredRowKey={hoveredRowKey}
                    //   onScroll={onScroll}
                    //   onItemsRendered={this._handleItemsRendered}
                    //   children={this.renderRow}
                    // />
                    <CustomerTable
                        data={data}
                        children={this.renderRow}
                        className={`${classPrefix}__body`}
                        width={width}
                        columnWidth={bodyWidth}
                        height={Math.max(height - headerHeight - frozenRowsHeight, 0)}
                        reff={this._setBodyRef}
                        footerRef={this._setFooterRef}
                        hoveredRowKey={hoveredRowKey}
                        onScroll={onScroll}
                        columns={rest.columns}
                        scrollbarSize={rest.scrollbarSize}
                    ></CustomerTable>
                ) : (
                    <Grid
                        {...rest}
                        className={`${classPrefix}__body`}
                        ref={this._setBodyRef}
                        // data={data}
                        itemKey={this._itemKey}
                        // frozenData={frozenData}
                        width={width}
                        height={Math.max(height - headerHeight - frozenRowsHeight, 0)}
                        rowHeight={(index) => {
                            const hi = (getRowHeight && getRowHeight(data[index], index)) || rowHeight;
                            // console.log('hi', hi, data[index].id, data[index])
                            return hi;
                        }}
                        columnWidth={(index) => rest.columns[index]}
                        // getRowHeight={getRowHeight}
                        estimatedRowSize={Math.max(height - headerHeight - frozenRowsHeight, 0)}
                        rowCount={data.length}
                        overscanRowCount={overscanRowCount}
                        // columnWidth={bodyWidth}
                        columnCount={1}
                        overscanColumnCount={0}
                        useIsScrolling={useIsScrolling}
                        hoveredRowKey={hoveredRowKey}
                        onScroll={onScroll}
                        onItemsRendered={this._handleItemsRendered}
                        children={this.renderRow}
                    />
                )}
                {/* <Example /> */}
                {headerHeight + frozenRowsHeight > 0 && (
                    // put header after body and reverse the display order via css
                    // to prevent header's shadow being covered by body
                    <Header
                        {...rest}
                        className={`${classPrefix}__header`}
                        ref={this._setHeaderRef}
                        data={data}
                        frozenData={frozenData}
                        width={width}
                        height={Math.min(headerHeight + frozenRowsHeight, height)}
                        rowWidth={headerWidth}
                        rowHeight={rowHeight}
                        headerHeight={this.props.headerHeight}
                        headerRenderer={this.props.headerRenderer}
                        rowRenderer={this.props.rowRenderer}
                        hoveredRowKey={frozenRowCount > 0 ? hoveredRowKey : null}
                    />
                )}
            </div>
        );
    }

    _setHeaderRef(ref) {
        this.headerRef = ref;
    }

    _setBodyRef(ref) {
        this.bodyRef = ref;
    }

    _setFooterRef(ref) {
        this.footerRef = ref;
    }

    _itemKey({ rowIndex }) {
        const { data, rowKey } = this.props;
        // console.log('_itemKey', rowKey, typeof rowKey === 'function' ? rowKey(data[rowIndex], rowIndex) : data[rowIndex][rowKey])
        return typeof rowKey === "function" ? rowKey(data[rowIndex], rowIndex) : data[rowIndex][rowKey];
    }

    _getHeaderHeight() {
        const { headerHeight } = this.props;
        if (Array.isArray(headerHeight)) {
            return headerHeight.reduce((sum, height) => sum + height, 0);
        }
        return headerHeight;
    }

    _handleItemsRendered({ overscanRowStartIndex, overscanRowStopIndex, visibleRowStartIndex, visibleRowStopIndex }) {
        this.props.onRowsRendered({
            overscanStartIndex: overscanRowStartIndex,
            overscanStopIndex: overscanRowStopIndex,
            startIndex: visibleRowStartIndex,
            stopIndex: visibleRowStopIndex
        });
    }
}

GridTable.propTypes = {
    containerStyle: PropTypes.object,
    classPrefix: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    headerHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
    headerWidth: PropTypes.number.isRequired,
    bodyWidth: PropTypes.number.isRequired,
    rowHeight: PropTypes.number.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]).isRequired,
    frozenData: PropTypes.arrayOf(PropTypes.object),
    useIsScrolling: PropTypes.bool,
    overscanRowCount: PropTypes.number,
    hoveredRowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
    onScrollbarPresenceChange: PropTypes.func,

    onScroll: PropTypes.func,
    onRowsRendered: PropTypes.func,
    headerRenderer: PropTypes.func.isRequired,
    rowRenderer: PropTypes.func.isRequired
};

export default GridTable;
