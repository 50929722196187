module.exports.id = {
    id: "PROJECT_MANAGEMENT_DEMAND_DETAILS",
    method: "POST",
    name: "需求导入规划明细",
    type: "menu_hide"
};
const moduleName = "project/";

module.exports.children = {
    EXAMINE_PASS: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_EXAMINE_PASS",
        apiName: "demandExaminePass",
        method: "POST",
        name: "客户需求->审核通过",
        type: "view",
        url: `${moduleName}demand/pass`
    },
    EXAMINE_CANCEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_EXAMINE_CANCEL",
        apiName: "demandExamineCancelPass",
        method: "POST",
        name: "客户需求->取消通过",
        type: "view",
        url: `${moduleName}demand/cancel`
    },
    EXAMINE_REJECT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_EXAMINE_REJECT",
        apiName: "demandExamineReject",
        method: "POST",
        name: "客户需求->审核驳回",
        type: "view",
        url: `${moduleName}demand/reject`
    },
    DEMAND_SUBMIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_DEMAND_SUBMIT",
        apiName: "demandSubmit",
        method: "POST",
        name: "客户需求->提交",
        type: "view",
        url: `${moduleName}demand/submit`
    },
    DEMAND_SAVE: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_DEMAND_SAVE",
        apiName: "saveDemand",
        method: "POST",
        name: "客户需求->保存",
        type: "view",
        url: `${moduleName}demand/save`
    },
    GET_DEMAND: {
        id: "PROJECT_MANAGEMENT_DEMAND_IMPORT_GET_DEMAND",
        apiName: "getDemandsInfo",
        method: "POST",
        name: "客户需求",
        type: "view",
        url: `${moduleName}demand`
    },
    /////////////////////////////////////////////////////
    PLAN_PASS: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_PASS",
        apiName: "planExaminePass",
        method: "POST",
        name: "方案研发->审核通过",
        type: "view",
        url: `${moduleName}development/pass`
    },
    PLAN_CANCEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_CANCEL",
        apiName: "planExamineCancelPass",
        method: "POST",
        name: "方案研发->取消通过",
        type: "view",
        url: `${moduleName}development/cancel`
    },
    PLAN_REJECT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_REJECT",
        apiName: "planExamineReject",
        method: "POST",
        name: "方案研发->审核驳回",
        type: "view",
        url: `${moduleName}development/reject`
    },
    PLAN_SUBMIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_SUBMIT",
        apiName: "planSubmit",
        method: "POST",
        name: "方案研发->提交",
        type: "view",
        url: `${moduleName}development/submit`
    },
    PLAN_SAVE: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_SAVE",
        apiName: "planSave",
        method: "POST",
        name: "方案研发->保存",
        type: "view",
        url: `${moduleName}development/save`
    },
    GET_PLAN: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_GET_PLAN",
        apiName: "getPlanData",
        method: "POST",
        name: "方案研发",
        type: "view",
        url: `${moduleName}development`
    },
    PLAN_RELOAD: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_RELOAD",
        name: "方案研发->刷新",
        type: "view"
    },
    PLAN_CLEAR: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_CLEAR",
        name: "方案研发->批量删除",
        type: "view"
    },
    PLAN_ADD: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_ADD",
        name: "方案研发->新增",
        type: "view"
    },
    PLAN_EXPORT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_EXPORT",
        name: "方案研发->导出",
        type: "view"
    },
    PLAN_EXPORT_DATA: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_EXPORT_DATA",
        name: "方案研发->导出表头",
        type: "view"
    },
    PLAN_EXPORT_HEADER: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_EXPORT_HEADER",
        name: "方案研发->导出表数据",
        type: "view"
    },
    PLAN_IMPORT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_IMPORT",
        name: "方案研发->导入",
        type: "view"
    },
    PLAN_EXPENSE: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_EXPENSE",
        name: "方案研发->费用项",
        type: "view"
    },
    PLAN_EDIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_EDIT",
        name: "方案研发->编辑",
        type: "view"
    },
    PLAN_DEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_DEL",
        name: "方案研发->删除",
        type: "view"
    },
    PLAN_GETMILES: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_GETMILES",
        name: "方案研发->获取公里数",
        type: "view"
    },
    PLAN_STORE_ADD: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_STORE_ADD",
        name: "方案研发->仓储费用项添加",
        type: "view"
    },
    PLAN_STORE_EDIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_STORE_EDIT",
        name: "方案研发->仓储费用项编辑",
        type: "view"
    },
    PLAN_STORE_DEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_PLAN_STORE_DEL",
        name: "方案研发->仓储费用项删除",
        type: "view"
    },
    /////////////////////////////////////////////////////
    COST_PASS: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_PASS",
        apiName: "costExaminePass",
        method: "POST",
        name: "成本规划->审核通过",
        type: "view",
        url: `${moduleName}cost/pass`
    },
    COST_CANCEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_CANCEL",
        apiName: "costExamineCancelPass",
        method: "POST",
        name: "成本规划->取消通过",
        type: "view",
        url: `${moduleName}cost/cancel`
    },
    COST_REJECT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_REJECT",
        apiName: "costExamineReject",
        method: "POST",
        name: "成本规划->审核驳回",
        type: "view",
        url: `${moduleName}cost/reject`
    },
    COST_SUBMIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_SUBMIT",
        apiName: "costSubmit",
        method: "POST",
        name: "成本规划->提交",
        type: "view",
        url: `${moduleName}cost/submit`
    },
    COST_SAVE: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_SAVE",
        apiName: "costSave",
        method: "POST",
        name: "成本规划->保存",
        type: "view",
        url: `${moduleName}cost/save`
    },
    GET_COST: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_GET_COST",
        apiName: "getCostData",
        method: "POST",
        name: "成本规划",
        type: "view",
        url: `${moduleName}cost`
    },
    COST_RELOAD: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_RELOAD",
        name: "成本规划->刷新",
        type: "view"
    },
    COST_CLEAR: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_CLEAR",
        name: "成本规划->批量删除",
        type: "view"
    },
    COST_ADD: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_ADD",
        name: "成本规划->新增",
        type: "view"
    },
    COST_EXPORT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_EXPORT",
        name: "成本规划->导出",
        type: "view"
    },
    COST_EXPORT_DATA: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_EXPORT_DATA",
        name: "成本规划->导出表头",
        type: "view"
    },
    COST_EXPORT_HEADER: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_EXPORT_HEADER",
        name: "成本规划->导出表数据",
        type: "view"
    },
    COST_IMPORT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_IMPORT",
        name: "成本规划->导入",
        type: "view"
    },
    COST_EXPENSE: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_EXPENSE",
        name: "成本规划->费用项",
        type: "view"
    },
    COST_EDIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_EDIT",
        name: "成本规划->编辑",
        type: "view"
    },
    COST_DEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_DEL",
        name: "成本规划->删除",
        type: "view"
    },
    COST_GETMILES: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_GETMILES",
        name: "成本规划->获取公里数",
        type: "view"
    },
    COST_STORE_ADD: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_STORE_ADD",
        name: "成本规划->仓储费用项添加",
        type: "view"
    },
    COST_STORE_EDIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_STORE_EDIT",
        name: "成本规划->仓储费用项编辑",
        type: "view"
    },
    COST_STORE_DEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_COST_STORE_DEL",
        name: "成本规划->仓储费用项删除",
        type: "view"
    },
    ////////////////////////////////
    ESTIMATE_PASS: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_PASS",
        apiName: "estimateExaminePass",
        method: "POST",
        name: "应收报价预估->审核通过",
        type: "view",
        url: `${moduleName}estimate/pass`
    },
    ESTIMATE_CANCEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_CANCEL",
        apiName: "estimateExamineCancelPass",
        method: "POST",
        name: "应收报价预估->取消通过",
        type: "view",
        url: `${moduleName}estimate/cancel`
    },
    ESTIMATE_REJECT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_REJECT",
        apiName: "estimateExamineReject",
        method: "POST",
        name: "应收报价预估->审核驳回",
        type: "view",
        url: `${moduleName}estimate/reject`
    },
    ESTIMATE_SUBMIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_SUBMIT",
        apiName: "estimateSubmit",
        method: "POST",
        name: "应收报价预估->提交",
        type: "view",
        url: `${moduleName}estimate/submit`
    },
    ESTIMATE_SAVE: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_SAVE",
        apiName: "estimateSave",
        method: "POST",
        name: "应收报价预估->保存",
        type: "view",
        url: `${moduleName}estimate/save`
    },
    ESTIMATE_RELOAD: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_RELOAD",
        name: "应收报价预估->刷新",
        type: "view"
    },
    ESTIMATE_CLEAR: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_CLEAR",
        name: "应收报价预估->批量删除",
        type: "view"
    },
    ESTIMATE_ADD: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_ADD",
        name: "应收报价预估->新增",
        type: "view"
    },
    ESTIMATE_EXPORT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_EXPORT",
        name: "应收报价预估->导出",
        type: "view"
    },
    ESTIMATE_EXPORT_DATA: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_EXPORT_DATA",
        name: "应收报价预估->导出表头",
        type: "view"
    },
    ESTIMATE_EXPORT_HEADER: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_EXPORT_HEADER",
        name: "应收报价预估->导出表数据",
        type: "view"
    },
    ESTIMATE_IMPORT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_IMPORT",
        name: "应收报价预估->导入",
        type: "view"
    },
    ESTIMATE_EXPORT_QUO: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_EXPORT_QUO",
        name: "应收报价预估->导出报价单",
        type: "view"
    },
    ESTIMATE_EXPENSE: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_EXPENSE",
        name: "应收报价预估->费用项",
        type: "view"
    },
    ESTIMATE_EDIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_EDIT",
        name: "应收报价预估->编辑",
        type: "view"
    },
    ESTIMATE_DEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_DEL",
        name: "应收报价预估->删除",
        type: "view"
    },
    ESTIMATE_GETMILES: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_GETMILES",
        name: "应收报价预估->获取公里数",
        type: "view"
    },
    ESTIMATE_STORE_ADD: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_STORE_ADD",
        name: "应收报价预估->仓储费用项添加",
        type: "view"
    },
    ESTIMATE_STORE_EDIT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_STORE_EDIT",
        name: "应收报价预估->仓储费用项编辑",
        type: "view"
    },
    ESTIMATE_STORE_DEL: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_ESTIMATE_STORE_DEL",
        name: "应收报价预估->仓储费用项删除",
        type: "view"
    },
    GET_ESTIMATE: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_GET_ESTIMATE",
        apiName: "getEstimateData",
        method: "POST",
        name: "应收报价预估",
        type: "view",
        url: `${moduleName}estimate`
    },
    SYNC_TRANSPORT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_SYNC_TRANSPORT",
        name: "应收报价预估->同步运输成本",
        type: "view"
    },
    CREATE_QUOTATION: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_CREATE_QUOTATION",
        apiName: "createClientQuotation",
        method: "GET",
        name: "创建为运输报价",
        type: "view",
        url: `${moduleName}estimate/createClientQuotation`
    },
    SYNC_STORE: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_SYNC_STORE",
        name: "应收报价预估->同步仓储成本",
        type: "view"
    },
    STORE_QUOTATION: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_STORE_QUOTATION",
        name: "应收报价预估->创建为仓储报价",
        type: "view"
    },
    CREATE_PROJECT: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_CREATE_PROJECT",
        name: "应收报价预估->创建为合作项目",
        type: "view"
    },
    ////////////////////////////////////////////////////
    SUSPEND_DEMAND: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_SUSPEND_DEMAND",
        apiName: "suspendDemand",
        method: "GET",
        name: "终止",
        type: "view",
        url: `${moduleName}demand/suspend`
    },
    CANCEL_SUSPEND: {
        id: "PROJECT_MANAGEMENT_DEMAND_DETAILS_CANCEL_SUSPEND",
        apiName: "suspendCancel",
        method: "GET",
        name: "取消",
        type: "view",
        url: `${moduleName}demand/suspend/cancel`
    }
    // LOOK_MORE: {
    //     id: 'RESOURCE_MANAGEMENT_BASE_CAR_LOOK_MORE',
    //     name: '查看',
    //     type: 'view'
    // }
};
