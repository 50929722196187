//const sourcePower = require('./source/power')
import Glob from "glob";
// const Glob = require("glob")
// const chalk = require('chalk')
// const error = chalk.bold.red;

const keys = {};

/**
 * 获取模块权限数据并组合
 */
export const toJson = (module, path) => {
    if (module.id && module.children) {
        let obj = module.id;
        obj.children = [];
        for (let key in module.children) {
            // if (keys[module.children[key].id]) {
            //     console.log('keys', module.children[key].id, path, keys[module.children[key].id])
            // }
            // keys[module.children[key].id] = path
            obj.children.push(module.children[key]);
        }
        return obj;
    } else {
        return {};
    }
};
const paths = {};

export default function (pattern, params) {
    console.log("search position", pattern, params);
    // let i = 0;
    // for (let key in paths) {
    //     i++
    // }
    // console.log('paths', i)
    let modules = [];

    let matches = new Glob.sync(
        pattern,
        Object.assign(
            {},
            {
                matchBase: true,
                mark: true,
                realpath: true,
                absolute: true,
                nodir: false
            },
            params
        )
    );

    if (matches && matches.length > 0) {
        for (let matche of matches) {
            // if (new RegExp('stowage_maintenance').test('' + matche)) {
            //     console.log('project', matche)
            // }
            // console.log('matche', matche)
            if (!paths["" + matche]) {
                paths["" + matche] = true;
                const module = require("" + matche);
                if (module) {
                    modules.push(toJson(module, matche));
                } else {
                    // console.log(error('path:'+matche+" error"))
                }
            } else {
                console.log("path", paths["" + matche]);
            }
        }
    }

    return modules;
}
