const m = "wms/inventoryManage";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE",
    method: "POST",
    name: "库存管理",
    type: "menu",
    apiName: "getInventoryManage",
    url: `${m}/list`
};

export const children = {
    ALLOT: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE_ALLOT",
        apiName: "inventoryAllot",
        method: "POST",
        name: "调拨",
        type: "view",
        url: `${m}/allocate`
    },
    MOVE_LOCATION: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE_MOVE_LOCATION",
        apiName: "inventoryMoveLocation",
        method: "POST",
        name: "移位",
        type: "view",
        url: `${m}/displace`
    },
    CHANGE_STATUS: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE_CHANGE_STATUS",
        apiName: "changeInventoryStatus",
        method: "POST",
        name: "状态变更",
        type: "view",
        url: `${m}/statusChange`
    },
    ON_FREEZE: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE_ON_FREEZE",
        apiName: "frozenInventoryStatus",
        method: "POST",
        name: "冻结",
        type: "view",
        url: `${m}/freeze`
    },
    DISMANT_PLATE: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE_DISMANT_PLATE",
        apiName: "dismantPlate",
        method: "POST",
        name: "拆板",
        type: "view",
        url: `${m}/dismantlingPlate`
    },
    MERGE_PLATE: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE_MERGE_PLATE",
        apiName: "inventoryMergePlate",
        method: "POST",
        name: "合板",
        type: "view",
        url: `${m}/mergePlate`
    },
    SEE_LOGS: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE_SEE_LOGS",
        apiName: "getInventoryBoardLogs",
        method: "GET",
        name: "板号日志",
        type: "view",
        url: `wms/boardLog`
    },
    EXPORT_DATA: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE_EXPORT_DATA",
        apiName: "inventoryExportData",
        method: "POST",
        name: "库存导出",
        type: "view",
        url: `${m}/export`
    },
    EXPORT_SERIAL: {
        id: "WAREHOUSE_MANAGEMENT_INVENTORY_MANAGE_EXPORT_SERIAL",
        name: "序列号导出",
        type: "view"
    }
};
