/*
 * @Descripttion:
 * @Author: hl.huang
 * @Date: 2021-01-11 16:30:26
 * @LastEditors: 黄华林
 * @LastEditTime: 2021-01-11 16:36:12
 */

import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{ [id.id]: id }
};

const moduleName = "finance/payableVerification/";
export default class payableVarification {
    //复核
    payableVarificationCheck(params) {
        return this.POST(`${moduleName}check`, deleteNull(params));
    }
    //根据ID获取账单
    payableVarificationGetOne(params) {
        return this.GET(`${moduleName}getOne/${params.id}`);
    }
    //核销列表
    payableVarificationList(params) {
        return this.POST(`${moduleName}list`, deleteNull(params));
    }
    //账单核销
    payableVarificationVerification(params) {
        return this.POST(`${moduleName}verification`, deleteNull(params));
    }
}
