import request from "@src/http";
import _ from "@src/utils/lodash";
const rApi: any = request;
import { message } from "antd";
export interface IItem {
    [key: string]: any;
}

export interface ICarrierInfo {
    [key: string]: any;
}

// 承运商
export interface ICarriers {
    code: string;
    type: string;
    name: string;
    aging: string;
    abbreviation: string;
    [key: string]: any;
}

export interface IMaterialItem {
    boardCount: number;
    boxCount: number;
    heavyBubbleId: number;
    heavyBubbleName: string;
    heavyBubbleRatio: number;
    id: number;
    itemSpecifications: string;
    materialName: string;
    materialNumber: string;
    materialsId: number;
    netWeight: number;
    orderId: number;
    quantity: number;
    receiverSenderId: number;
    status: number;
    stowageOrderId: number;
    transportReceiverSenderMaterialId: number;
    unitId: number;
    unitName: string;
    volume: number;
    weight: number;
    [key: string]: any;
}
// 发货单
export interface IOrderItem {
    id: number;
    materialList: IMaterialItem[]; //配载物料
    transportReceiverSenderId: number; //发货单运输端ID
    orderId: number; //发货单ID
    orderNumber: string; //发货单号
    clientName: string; //客户名称
    stowageId: number; //配载单ID
    quantity: number; //数量
    boxCount: number; //箱数
    boardCount: number; //板数
    weight: number; //重量(kg)
    netWeight: number; //净重(kg)
    volume: number; //体积(m³)
    receiverAddress: string; //收货方地址
    senderAddress: string; //发货方地址
    senderTransit: string; //发货方中转
    receiverTransit: string; //收货方中转
    senderName: Record<string, unknown>; //发货方名
    receiverName: Record<string, unknown>; //收货方名
    senderId: number; //发货方id
    receiverId: number; //收货方id
    [key: string]: any;
}

interface Self_ChildrenItem {
    carrierName: string; //承运商名称
    carrierId: string; //承运商id
    carrierType: number; //承运商类型
    driverName: string; //司机名
    driverPhone: number; //司机手机
    driverCar: number; //司机关联车辆
    startPoint: any; //起点
    endPoint: any; //终点
    checked: boolean;
    isTransit: boolean; //是否是中转线路
    payer: number; //付款方式
    payerDisabled: boolean; //付款方式

    distributionCarriers: ICarriers[];
    otherCarrierList: ICarriers[];
    orderList: IOrderItem[];
    [key: string]: any;
}
export interface IDistribute {
    clientName: string;
    billingMethodName: string;
    createTime: string;

    // 下面是本地用的字段 可以不发送服务器
    group_uid: string; //  本地中转分拆时要的标记id
    group_summary: Record<string, unknown>;
    self_children: Self_ChildrenItem[]; //
}

export interface IState {
    distributes: IDistribute[];
    selectedCount: number;
    pageLoading: boolean;
    _filter_isDispatchCarrier: number;
    _filter_carrierId: number | string;
    _filter_carrierName: string;
}

function toMapOrderObj(source: any, checkedChildrenItem: Self_ChildrenItem): IOrderItem {
    const { carrierId, carrierName, carrierType, startPoint, endPoint } = checkedChildrenItem;
    const senderAddress = JSON.stringify({
        formatAddress: startPoint?.formatAddress,
        pro: startPoint?.pro,
        city: startPoint?.city,
        dist: startPoint?.dist,
        street: startPoint?.street,
        extra: startPoint?.extra
    });
    const receiverAddress = JSON.stringify({
        formatAddress: endPoint?.formatAddress,
        pro: endPoint?.pro,
        city: endPoint?.city,
        dist: endPoint?.dist,
        street: endPoint?.street,
        extra: endPoint?.extra
    });
    const map = {
        id: null,
        materialList: source?.receiverSenderList?.[0]?.materialList, //配载物料
        transportReceiverSenderId: source.transportReceiverSenderId, //发货单运输端ID
        orderId: source.id, //发货单ID
        orderNumber: source.orderNumber, //发货单号
        clientName: source.clientName, //客户名称
        stowageId: null, //配载单ID
        quantity: source?.totalQuantity ?? 0, //数量
        boxCount: source?.totalBoxCount ?? 0, //箱数
        boardCount: source?.totalBoardCount ?? 0, //板数
        weight: source?.totalGrossWeight ?? 0, //重量(kg)
        netWeight: source?.totalNetWeight ?? 0, //净重(kg)
        volume: source?.totalVolume ?? 0, //体积(m³)

        senderTransit: source?.receiverSenderList?.[0]?.senderTransit, //发货方中转
        receiverTransit: source?.receiverSenderList?.[0]?.receiverTransit, //收货方中转
        senderAddress: senderAddress, //发货方地址
        receiverAddress: receiverAddress, //收货方地址
        senderContactName: startPoint?.contact,
        senderContactNumber: startPoint?.phoneNumber,
        receiverContactName: endPoint?.contact,
        receiverContactNumber: endPoint?.phoneNumber,
        senderName: startPoint?.name, //发货方名
        receiverName: endPoint?.name, //收货方名
        senderId: startPoint?.id, //发货方id
        receiverId: endPoint?.id //收货方id
    };
    return map;
}
function toMapStowageObj(source: IDistribute, checkedChildrenItem: Self_ChildrenItem, index) {
    const { group_summary } = source || {};
    const {
        orderList,
        carrierId,
        carrierName,
        carrierType,
        startPoint,
        endPoint,
        driverName,
        driverId,
        driverPhone,
        driverCar,
        isTransit,
        payer
    } = checkedChildrenItem;
    const map = {
        //关联发货单  List<StowageOrderDTO>
        orderList: _.isArray(orderList) ? orderList.map((item) => toMapOrderObj(item, checkedChildrenItem)) : null,
        //费用项  List<PayableEstimateExpense>
        quotationExpenses: null,
        //联系人  List<StowageContactPerson>
        contactPersons: null,
        //需要删除的费用ID集合  List<>
        deleteExpenseIds: null,
        id: null,
        stowageNumber: null, //配载单号
        carrierId: carrierId, //承运商ID
        carrierName: carrierName, //承运商名称
        carrierType: carrierType, //承运商类型(1-关联承运商，2-其他承运商，3-现金车)
        associatePaymentCarrierId: null, //关联付款承运商ID
        associatePaymentCarrierName: null, //关联付款承运商名称
        departureTime: null, //发车时间
        carType: null, //车辆类型(1-承运商车辆 2-现金车)
        carTypeId: null, //车型ID
        carTypeName: null, //车型名称
        isHighway: null, //是否高速(0-否 1-是)
        transportationMethodId: null, //运输方式ID
        transportationMethodName: null, //运输方式名称
        carId: null, //车辆ID
        carCode: null, //车牌号
        trailerCarCode: null, //挂车车牌号码
        driverId: driverId, //司机ID
        driverName: driverName, //司机姓名
        payer: payer, //付款方式
        phone: driverPhone, //手机号
        phoneBackup: driverPhone, //电话号码
        remark: null, //备注信息
        billingMethod: null, //结算方式(1-合同价 2-现金价)
        quotationId: null, //报价单号ID
        quotationNumber: null, //报价单号
        departure: startPoint?.formatAddress, //起运地
        destination: endPoint?.formatAddress, //目的地
        transitPlaceOneAddress: isTransit ? endPoint : null, //中转地地址
        transitPlaceOneId: isTransit ? endPoint?.id : null, //中转地1 ID
        transitPlaceOneName: isTransit ? endPoint?.name : null, //中转地1（节点）
        transitPlaceTwoId: null, //中转地2 ID
        transitPlaceTwoName: null, //中转地2（节点）
        currencyId: null, //币别ID
        currencyName: null, //币别名称
        totalQuantity: group_summary?.totalQuantity, //总数
        totalBoxCount: group_summary?.totalBoxCount, //总箱数
        totalBoardCount: group_summary?.totalBoardCount, //总板数
        totalGrossWeight: group_summary?.totalGrossWeight, //总重量(kg)
        totalNetWeight: group_summary?.totalNetWeight, //总净重(kg)
        totalVolume: group_summary?.totalVolume, //总体积(m³)
        status: null, //状态()
        operatorId: null, //操作人ID
        operatorName: null, //操作人
        createTime: null, //创建时间
        updateTime: null, //修改时间
        quotationType: null, //报价类型
        estimatedCost: null, //预估费用
        selectQuotation: null, //报价路线详细信息
        transitPlaceType: null, //中转地类型(1-中转地，2-仓库)
        longitude: isTransit ? endPoint?.longitude : null, //经度
        latitude: isTransit ? endPoint?.latitude : null, //纬度
        isTextsIncluded: null, //是否含税 0-不含税 1-含税
        taxes: null,
        afterTaxAmount: null, //税后金额
        withholdingTax: null, //补扣税
        ilineObjontainVehicle: null, //是否存在整车或整柜(1-存在整车或整柜发货单 0-不存在)
        offerCost: null, //完全不含税金额
        businessModelId: null, //业务模式ID
        businessModelName: null, //业务模式
        trackMode: null, //追踪模式(0-无 1-NB车追踪 2-派车NB追踪 3-发货单NB追踪)
        trackSwitch: null, //追踪开关
        deviceNumber: null, //设备编号
        remarkDevice: null, //设备备注
        serviceFee: null, //服务费费率
        payTaxesType: null, //税率支付类别
        oillineObjale: null, //油卡比列
        oilAmount: null, //油卡金额
        specialInstruction: null, //特殊要求
        transportModeId: null, //运输方式ID
        transportModeName: null, //运输方式名称
        arrivalTime: null, //到达时间
        specialBusiness: null, //特殊业务
        quotationLineId: null, //报价行ID
        departureProvince: startPoint?.pro, //起运地省
        departureCity: startPoint?.city, //起运地市
        departureArea: startPoint?.dist, //起运地区
        destinationProvince: endPoint?.pro, //目的地省
        destinationCity: endPoint?.city, //目的地市
        destinationArea: endPoint?.dist, //目的地区
        resourceVehicleId: null, //车辆ID
        resourceVehicleName: null, //车辆名称
        singleNumber: null, //单号
        carrierDispatch: null, //是否承运商调度(0-否，1-是)
        numberPlateId: null, //车牌号ID
        numberPlateName: null, //车牌号名称
        specificationModel: null, //规格型号
        specificationModelId: null, //规格型号ID
        ilineObjustomlineObjlearance: null, //是否报关(0-否 1-是)
        sixUnionSingleNumber: null, //六联单号
        customsId: null, //报关单ID
        preConfigured: null, //是否预配载
        packageRequirement: null, //包装要求
        totalBillingWeight: null, //总计费重
        sort: index
    };

    return map;
}
function changeOrderListByLine(orderList, lineObj: { startPoint: any; endPoint: any }) {
    let _orderList = [];
    if (!_.isArray(orderList)) return [];
    orderList.forEach((ol) => {
        let _ol = _.cloneDeep(ol);

        _ol.departureArea = lineObj?.startPoint?.dist;
        _ol.departureCity = lineObj?.startPoint?.city;
        _ol.departureProvince = lineObj?.startPoint?.pro;
        _ol.destinationArea = lineObj?.endPoint?.dist;
        _ol.destinationCity = lineObj?.endPoint?.city;
        _ol.destinationProvince = lineObj?.endPoint?.pro;

        _ol.receiverSenderList[0]["senderAddress"] = JSON.stringify({
            formatAddress: lineObj?.startPoint?.formatAddress,
            pro: lineObj?.startPoint?.pro,
            city: lineObj?.startPoint?.city,
            dist: lineObj?.startPoint?.dist,
            street: lineObj?.startPoint?.street,
            extra: lineObj?.startPoint?.extra
        });
        _ol.receiverSenderList[0]["senderArea"] = lineObj?.startPoint?.area;
        _ol.receiverSenderList[0]["senderId"] = lineObj?.startPoint?.id;
        _ol.receiverSenderList[0]["senderLatitude"] = lineObj?.startPoint?.latitude;
        _ol.receiverSenderList[0]["senderLongitude"] = lineObj?.startPoint?.longitude;
        _ol.receiverSenderList[0]["senderName"] = lineObj?.startPoint?.name;
        _ol.receiverSenderList[0]["senderPhoneNumber"] = lineObj?.startPoint?.phoneNumber;

        _ol.receiverSenderList[0]["receiverAddress"] = JSON.stringify({
            formatAddress: lineObj?.endPoint?.formatAddress,
            pro: lineObj?.endPoint?.pro,
            city: lineObj?.endPoint?.city,
            dist: lineObj?.endPoint?.dist,
            street: lineObj?.endPoint?.street,
            extra: lineObj?.endPoint?.extra
        });
        _ol.receiverSenderList[0]["receiverArea"] = lineObj?.endPoint?.area;
        _ol.receiverSenderList[0]["receiverLongitude"] = lineObj?.endPoint?.longitude;
        _ol.receiverSenderList[0]["receiverId"] = lineObj?.endPoint?.id;
        _ol.receiverSenderList[0]["receiverLatitude"] = lineObj?.endPoint?.latitude;
        _ol.receiverSenderList[0]["receiverName"] = lineObj?.endPoint?.name;
        _ol.receiverSenderList[0]["receiverPhoneNumber"] = lineObj?.endPoint?.phoneNumber;

        _orderList.push(_ol);
    });
    return _orderList;
}

function initState(): IState {
    return {
        distributes: [],
        selectedCount: 0,
        pageLoading: false,
        _filter_isDispatchCarrier: 0,
        _filter_carrierId: null,
        _filter_carrierName: null
    };
}
export default {
    namespace: "orderDistribute",
    state: initState(),
    effects: {
        *sureCombine({ payload }: any, { call, select, put }: any) {
            try {
                const distributes = yield select(({ orderDistribute }) => orderDistribute.distributes);
                const { onLeftViewRefresh } = payload;
                let params = [];
                let lackCarrier = false;
                let lackDriver = false;
                let lackPayer = false;
                distributes.forEach((gEle: IDistribute) => {
                    const { billingMethodName, self_children } = gEle;
                    const isEntrusted = billingMethodName === "委托自提";

                    const checkedChildren = self_children.filter((item) => {
                        return item.checked === true;
                    });
                    if (checkedChildren.length) {
                        let paramsList = [];
                        checkedChildren.forEach((cEle: Self_ChildrenItem, index) => {
                            // 发运方式为除了委托自提时,承运商不能为空  委托自提时司机不能为空
                            if (!isEntrusted && !cEle.carrierId) {
                                lackCarrier = true;
                            } else if (isEntrusted && !cEle.driverId) {
                                lackDriver = true;
                            }
                            //有中转地时  付款方式不能为空
                            if (!cEle.payer) {
                                lackPayer = true;
                            }
                            paramsList.push(toMapStowageObj(gEle, cEle, index));
                        });
                        params.push(paramsList);
                    }
                });

                if (lackCarrier) {
                    message.warn("承运商不能为空");
                    return;
                }
                // if (lackDriver) {
                //     message.warn("委托自提时,司机不能为空");
                //     return;
                // }
                if (lackPayer) {
                    message.warn("付款方式不能为空");
                    return;
                }
                const res = yield call(rApi["stowageCenterConfirm"], params);
                message.success("分配成功");
                yield put({
                    type: "cancelCombine"
                });
                onLeftViewRefresh && onLeftViewRefresh();
            } catch (error) {
                console.error("stowageCenterConfirm", error);
                message.error(error.msg || "系统错误");
            }
        },

        *removeLine({ payload }: any, { call, select, put }: any) {
            // 删掉当前行
            //  重新搜索承运商  从上一条搜索到最后一条

            const { index, group_uid } = payload;
            yield put({
                type: "removeCombine",
                payload: {
                    index,
                    group_uid
                }
            });
            yield put({
                type: "geCarrierByLineIndexs",
                payload: {
                    group_uid: group_uid,
                    // startIndex: index === 0 ? 0 : index - 1
                    startIndex: 0
                }
            });
        },
        *updateTransit({ payload }: any, { call, select, put }: any) {
            const distributes = yield select(({ orderDistribute }) => orderDistribute.distributes);
            const { index, group_uid, data } = payload;
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            const curChildrenItem = targetGroup["self_children"][index];

            if (curChildrenItem.isTransit) {
                yield put({
                    type: "removeTransit",
                    payload
                });
            }
            yield put({
                type: "addCombine",
                payload: {
                    index: index,
                    group_uid: group_uid,
                    data
                }
            });
            yield put({
                type: "geCarrierByLineIndexs",
                payload: {
                    group_uid: group_uid,
                    // startIndex: index,
                    startIndex: 0,
                    howmany: 1
                }
            });
        },
        *removeTransit({ payload }: any, { call, select, put }: any) {
            const distributes = yield select(({ orderDistribute }) => orderDistribute.distributes);
            const { index, group_uid } = payload;
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            const len = targetGroup.self_children.length;
            if (len >= 2) {
                let _index = index;
                if (len > 2) {
                    _index = index === 0 ? 1 : index === len - 1 ? len - 2 : index;
                }
                yield put({
                    type: "removeCombine",
                    payload: {
                        index: _index,
                        group_uid: group_uid
                    }
                });
            }
        },
        *geCarrierByLineIndexs({ payload }: any, { call, select, put }: any) {
            const { group_uid, startIndex, howmany } = payload;
            const distributes = yield select(({ orderDistribute }) => orderDistribute.distributes);
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            const len = targetGroup.self_children.length;
            const endIndex = howmany ? startIndex + howmany : len - 1;
            for (let index = 0; index < len; index++) {
                if (index < startIndex) {
                    continue;
                }
                yield put({
                    type: "geCarriersByLine",
                    payload: {
                        group_uid,
                        index
                    }
                });
                if (endIndex && endIndex === index) {
                    break;
                }
            }
        },
        *geCarriersByLine({ payload }: any, { call, select, put }: any) {
            const distributes = yield select(({ orderDistribute }) => orderDistribute.distributes);
            const { group_uid, index } = payload;
            const targetGroup = distributes.find((item: IDistribute) => item.group_uid === group_uid);
            // console.log("transshipmentCarrier", targetGroup);
            if (targetGroup) {
                const { self_children } = targetGroup,
                    lineObj = self_children[index];

                yield put({
                    type: "setModelState",
                    payload: {
                        pageLoading: true
                    }
                });
                try {
                    const res = yield call(rApi.transshipmentCarrier, lineObj.orderList);
                    yield put({
                        type: "updateTransshipmentCarrier",
                        payload: {
                            group_uid,
                            index,
                            otherCarrierList: res?.[0]?.otherCarrierList,
                            distributionCarriers: res?.[0]?.distributionCarriers,
                            orderList: res?.[0]?.orderList
                        }
                    });
                    yield put({
                        type: "setModelState",
                        payload: {
                            pageLoading: false
                        }
                    });
                } catch (error) {
                    yield put({
                        type: "setModelState",
                        payload: {
                            pageLoading: false
                        }
                    });
                    console.error("transshipmentCarrier", error);
                    message.error(error.msg || "系统错误");
                }
            }
        },
        *removeTRow({ payload }: any, { call, select, put }: any) {
            const distributes = yield select(({ orderDistribute }) => orderDistribute.distributes);
            const { index, group_uid, rowId, rowIndex } = payload;
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            const len = targetGroup?.self_children?.[index]?.orderList?.length;

            // console.log("removeTRow", index, group_uid, rowId, rowIndex);
            if (len > 1) {
                yield put({
                    type: "removeCombineItem",
                    payload: {
                        index: index,
                        group_uid: group_uid,
                        rowId,
                        rowIndex
                    }
                });
                yield put({
                    type: "afterRemoveRow",
                    payload: {
                        index: index,
                        group_uid: group_uid
                    }
                });
            } else {
                yield put({
                    type: "removeCombine",
                    payload: {
                        index: index,
                        group_uid: group_uid
                    }
                });
            }
        },
        *afterRemoveRow({ payload }: any, { call, select, put }: any) {
            try {
                const distributes = yield select(({ orderDistribute }) => orderDistribute.distributes);
                const { index, group_uid } = payload;
                const targetGroup = distributes.find((item) => item.group_uid === group_uid);
                const ids = targetGroup?.self_children?.[index]?.orderList?.map((ele) => ele.id) ?? [];
                const res = yield call(rApi["rematchCarrier"], ids);

                // console.log("rematchCarrier", res);
                const distributionCarriers = res.data?.[0]?.distributionCarriers ?? [];
                const otherCarrierList = res.data?.[0]?.otherCarrierList ?? [];
                const defaultCarrier = distributionCarriers?.[0] ?? {};
                yield put({
                    type: "updateCombine",
                    payload: {
                        index,
                        group_uid,
                        data: {
                            distributionCarriers,
                            otherCarrierList,
                            carrierName: defaultCarrier?.name,
                            carrierId: defaultCarrier?.id
                        }
                    }
                });
            } catch (error) {
                console.log(error);
                message.error(error.msg || "系统错误");
            }
        }
    },
    reducers: {
        setModelState(state: any, { payload }: any) {
            return {
                ...state,
                ...payload
            };
        },
        addDistributes(state: any, { payload }: any) {
            return {
                ...state,
                distributes: payload.list
            };
        },
        //选中组合
        selectedCombine(state: any, { payload }: any) {
            const distributes = _.cloneDeep(state.distributes);
            let _selectedCount = state.selectedCount;
            const { checked, index, group_uid } = payload;
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            if (targetGroup) {
                const curDis = targetGroup.self_children[index];
                targetGroup.self_children[index] = {
                    ...curDis,
                    checked
                };
                _selectedCount = checked ? _selectedCount + 1 : _selectedCount - 1;
            }
            return {
                ...state,
                distributes,
                selectedCount: _selectedCount
            };
        },
        //批量修改
        batchUpdateCombine(state: any, { payload }: any) {
            const distributes = _.cloneDeep(state.distributes);
            const { data } = payload;
            const _distributes = distributes.map((gEle) => {
                const { billingMethodName, self_children } = gEle;
                const isEntrusted = billingMethodName === "委托自提";
                gEle.self_children = self_children.map((cEle) => {
                    if (cEle.checked && !isEntrusted) {
                        cEle = {
                            ...cEle,
                            ...data
                        };
                    }
                    return cEle;
                });
                return gEle;
            });
            return {
                ...state,
                distributes: _distributes
            };
        },
        // 中转更新
        updateTransshipmentCarrier(state: any, { payload }: any) {
            const distributes = _.cloneDeep(state.distributes);
            const { index, group_uid, otherCarrierList, distributionCarriers, orderList } = payload;
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            if (targetGroup) {
                const curDis = targetGroup.self_children[index];
                const defaultCarrier = distributionCarriers?.[0] ?? {};
                targetGroup.self_children[index] = {
                    ...curDis,
                    otherCarrierList,
                    distributionCarriers,
                    orderList,
                    carrierName: defaultCarrier?.name ?? null,
                    carrierId: defaultCarrier?.id ?? null
                };
            }
            return {
                ...state,
                distributes
            };
        },
        //更新配置   承运商配置
        updateCombine(state: any, { payload }: any) {
            const distributes = _.cloneDeep(state.distributes);

            const { data, index, group_uid } = payload;
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            if (targetGroup) {
                const curDis = targetGroup.self_children[index];
                targetGroup.self_children[index] = {
                    ...curDis,
                    ...data
                };
            }

            return {
                ...state,
                distributes
            };
        },
        // 选择中转地,切割线路
        addCombine(state: any, { payload }: any) {
            const distributes = _.cloneDeep(state.distributes);
            const { data, index, group_uid } = payload;
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            if (targetGroup) {
                const billingMethodName = targetGroup.billingMethodName;
                // 当前操作项
                const curDis = targetGroup.self_children[index];
                // 增加一项  以中转地分割
                // 如上海-->深圳  中间加南京,就变成上海-->南京 南京-->深圳 南京作为上段的终点,新段的起点
                const curLine = {
                    startPoint: curDis.startPoint,
                    endPoint: _.cloneDeep(data)
                };
                const addLine = {
                    startPoint: _.cloneDeep(data),
                    endPoint: _.cloneDeep(curDis.endPoint)
                };
                const curOrderList = changeOrderListByLine(curDis.orderList, curLine);
                const addOrderList = changeOrderListByLine(curDis.orderList, addLine);
                const addDis = {
                    carrierId: null,
                    carrierName: null,
                    carrierType: 2,
                    checked: true,
                    startPoint: addLine.startPoint,
                    endPoint: addLine.endPoint,
                    orderList: addOrderList,
                    // isTransit: true
                    isTransit: false,
                    payer: billingMethodName === "指定专线" ? 2 : curDis.payer, //除了指定专线默认后面的为客户付款,别的默认原有的
                    payerDisabled: curDis.payerDisabled
                };
                curDis.isTransit = true;
                curDis.startPoint = curDis.startPoint;
                curDis.endPoint = curLine.endPoint;
                curDis.orderList = curOrderList;
                targetGroup.self_children.splice(index + 1, 0, addDis);
            }

            return { ...state, distributes, selectedCount: state.selectedCount + 1 };
        },
        //删除
        removeCombine(state: any, { payload }: any) {
            let distributes = _.cloneDeep(state.distributes);
            const { index, group_uid } = payload;
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            if (targetGroup) {
                const len = targetGroup.self_children.length;
                if (len === 1) {
                    // 删除整组
                    distributes = distributes.filter((item) => item.group_uid !== group_uid);
                } else if (len === 2) {
                    // 合并为原来的
                    const lineObj = {
                        startPoint: _.cloneDeep(targetGroup.self_children[0]["startPoint"]),
                        endPoint: _.cloneDeep(targetGroup.self_children[1]["endPoint"])
                    };
                    targetGroup.self_children = [
                        {
                            carrierId: null,
                            carrierName: null,
                            driverName: null,
                            driverPhone: null,
                            driverCar: null,
                            checked: true,
                            startPoint: lineObj.startPoint,
                            endPoint: lineObj.endPoint,
                            orderList: changeOrderListByLine(targetGroup.self_children[0]["orderList"], lineObj),
                            isTransit: false
                        }
                    ];
                } else {
                    if (index === 0 || index === len - 1) {
                        const name = index === 0 ? "原始起运地" : "原始目的地";
                        message.warn(`已经增加了中转地,不可以删除${name}线路`);
                    } else {
                        // 当前操作项
                        const curDis = targetGroup.self_children[index];
                        const _nextDis = targetGroup.self_children[index + 1];
                        const _nextLineObj = {
                            startPoint: _.cloneDeep(curDis["startPoint"]),
                            endPoint: _nextDis.endPoint
                        };
                        targetGroup.self_children[index + 1] = {
                            ..._nextDis,
                            carrierName: null,
                            carrierId: null,
                            startPoint: _.cloneDeep(curDis["startPoint"]),
                            orderList: changeOrderListByLine(_nextDis["orderList"], _nextLineObj)
                        };
                        targetGroup.self_children.splice(index, 1);
                    }
                }
            }
            return {
                ...state,
                distributes,
                selectedCount: state.selectedCount - 1
            };
        },
        //取消分配
        cancelCombine(state: any, { payload }: any) {
            return initState();
        },
        //右侧已选下拉选择
        rightMenuChange(state: any, { payload }: any) {
            return {
                ...state
            };
        },
        removeCombineItem(state: any, { payload }: any) {
            let distributes = _.cloneDeep(state.distributes);
            const { index, group_uid, rowIndex } = payload;
            const targetGroup = distributes.find((item) => item.group_uid === group_uid);
            targetGroup.self_children[index]["orderList"].splice(rowIndex, 1);
            return {
                ...state,
                distributes
            };
        },
        filterCompose(state: any, { payload }: any) {
            const { _filter_isDispatchCarrier, _filter_carrierId, _filter_carrierName } = payload;
            return {
                ...state,
                _filter_isDispatchCarrier: _filter_isDispatchCarrier ? _filter_isDispatchCarrier : 0,
                _filter_carrierId: _filter_carrierId ? _filter_carrierId : 0,
                _filter_carrierName: _filter_carrierName ? _filter_carrierName : null
            };
        }
    }
};
