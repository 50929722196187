const prevUrl = "wms/qualityManage";
export const id = {
    id: "WAREHOUSE_MANAGEMENT_QUALITY_INSPECTION_LIST",
    method: "POST",
    name: "质检管理",
    type: "menu",
    apiName: "getInspectionList",
    url: `${prevUrl}/list`
};

export const children = {
    ON_DETAILS: {
        id: "WAREHOUSE_MANAGEMENT_QUALITY_INSPECTION_LIST_ON_DETAILS",
        // apiName: 'addReceipt',
        // method: 'POST',
        name: "明细",
        type: "view"
        // url: `${prevUrl}/add`
    }
};
