const moduleName = "order/";
import getChildPowerJson from "../../../../utils/getChildPowerJson";
import newPage from "../add2/power_hide";
export const id = {
    id: "BUSINESS_MANAGEMENT_ORDER_LIST",
    method: "POST",
    name: "发货单管理",
    type: "menu",
    apiName: "getOrderList",
    url: `${moduleName}order/list`,
    sort: 1
};

const nPage = getChildPowerJson.toJson(newPage);
export const children = {
    [nPage.id]: nPage,
    ADD_DATA: {
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_ADD_DATA",
        name: "新建",
        type: "view"
    },
    EDIT_DATA: {
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_EDIT_DATA",
        apiName: "editOrder",
        method: "POST",
        name: "编辑",
        type: "view",
        url: `${moduleName}order/edit`
    },
    DEL_DATA: {
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_DEL_DATA",
        apiName: "delOrder",
        method: "POST",
        name: "删除",
        type: "view",
        url: `${moduleName}order/delete`
    },
    IMPORT_ORDER: {
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_ORDER_IMPORT_ORDER",
        apiName: "importOrderData",
        method: "POST",
        name: "导入",
        type: "view",
        url: `${moduleName}order/batchSave`
    },
    ORDER_EXPORT: {
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_ORDER_ORDER_EXPORT",
        apiName: "orderExport",
        method: "POST",
        name: "导出",
        type: "view",
        url: `${moduleName}order/exportOrderData`
    },
    EXPORT_TEMP: {
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_ORDER_EXPORT_TEMP",
        apiName: "orderExportTemp",
        method: "POST",
        name: "导出模板",
        type: "view",
        url: `${moduleName}order/exportTemp`
    },
    EXPORT_BUSINESS_CONTACT: {
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_ORDER_EXPORT_BUSINESS_CONTACT",
        apiName: "getBusinessContact",
        method: "GET",
        name: "导出业务联系单",
        type: "view",
        url: `${moduleName}order/getBusinessContact`
    },
    LOG_DATA: {
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_LOG_DATA",
        name: "日志",
        type: "view"
    },
    print: {
        id: "BUSINESS_MANAGEMENT_ORDER_PRINT",
        name: "打印",
        type: "view"
    },
    SIGN_DATA: {
        ///order/sign/{id}/{orderType}签收
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_SIGN",
        apiName: "orderSign",
        method: "POST",
        name: "签收",
        type: "view",
        url: `${moduleName}order/sign`
    },
    CANCEL_SIGN_DATA: {
        ///order/cancelSign/{id}/{orderType}
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_CANCEL_SIGN",
        apiName: "orderCancelSign",
        method: "POST",
        name: "取消签收",
        type: "view",
        url: `${moduleName}order/cancelSign`
    },
    FREEZE_DATA: {
        // /order/freezeOrder 冻结发货单
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_FREEZE",
        apiName: "orderFreeze",
        method: "PUT",
        name: "冻结",
        type: "view",
        url: `${moduleName}order/freezeOrder`
    },
    UNFREEZE_DATA: {
        // /order/unFreeze 取消冻结
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_UNFREEZE_DATA",
        apiName: "orderUnFreeze",
        method: "PUT",
        name: "取消冻结",
        type: "view",
        url: `${moduleName}order/unFreeze`
    },
    CLOSE_DATA: {
        // /order/closedOrder
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_CLOSE",
        apiName: "orderClose",
        method: "PUT",
        name: "关闭",
        type: "view",
        url: `${moduleName}order/closedOrder`
    },
    UNCLOSED_DATA: {
        ///order/unClosed
        id: "BUSINESS_MANAGEMENT_ORDER_LIST_UNCLOSED_DATA",
        apiName: "orderUnClose",
        method: "PUT",
        name: "取消关闭",
        type: "view",
        url: `${moduleName}order/unClosed`
    }
};
