import React, { Component } from "react";
import WithDragDropContext from "@src/libs/share_HTML5Backend";
import Card from "./Card";
import { cloneObject } from "@src/utils";

@WithDragDropContext
class Container extends Component {
    allCols = [];
    static defaultProps = {
        filterSortItems: []
    };

    constructor(props) {
        super(props);
        this.allCols =
            cloneObject(props.cols || props.cards || []).map((item) => {
                return {
                    key: item.key,
                    _edit: false
                };
            }) || [];
    }

    // 编辑表头按钮
    onEditHeaderTitle = (index) => {
        let item = this.allCols[index];
        this.allCols[index] = {
            ...item,
            _edit: !item._edit
        };
        this.setState({});
    };

    render() {
        const {
            cols,
            cards,
            onChangeCheckbox,
            moveCard,
            isEndDrag,
            Item,
            filterSortItems,
            isNotFilter,
            onChangeTitle,
            onChangeWidth,
            changeExport,
            showSettingExport
        } = this.props;
        // console.log('cols', cols)
        return (
            <div>
                {(cols || cards || []).map((col, i) => {
                    // let filter = [...filterSortItems]
                    // if (!isNotFilter && (i === cols.length - 1 || col.fixed)) {
                    // 	filter.push(col.key)
                    // }
                    return (
                        <Card
                            // filterSortItems={filter}
                            onChangeCheckbox={onChangeCheckbox}
                            changeExport={changeExport}
                            onChangeTitle={onChangeTitle}
                            onChangeWidth={onChangeWidth}
                            onEditHeaderTitle={this.onEditHeaderTitle}
                            key={"cardtitle" + col.key + i}
                            index={i}
                            column={col}
                            lastColumn={i === (cols || []).length - 1 ? true : false}
                            Item={Item}
                            moveCard={moveCard}
                            isEndDrag={isEndDrag}
                            allCols={this.allCols}
                            showSettingExport={showSettingExport}
                        />
                    );
                })}
            </div>
        );
    }
}

export default Container;
