import React from "react";
import { Modal } from "antd";
import { Table, Parent, ColumnFactory } from "@src/components/table_template";

/**
 * 报价详情
 * @class QuotationDetails
 * @extends {Parent}
 */
class QuotationDetails extends Parent {
    constructor(props) {
        super(props);
        if (props.getThis) {
            props.getThis(this);
        }
        this.state = {
            visible: false,
            columns: ColumnFactory.execute([
                ["a", "起运地", 180, this.renderCell],
                ["b", "目的地", 180, this.renderCell],
                ["c", "中转地", 160, this.renderCell],
                ["d", "公里数", 120, this.renderCell],
                ["e", "时效(h)", 120, this.renderCell],
                ["f", "是否高速", 120, this.renderCell],
                ["g", "是否分拣", 120, this.renderCell],
                ["h", "最低收费", 120, this.renderCell],
                ["i", "节点类型", 120, this.renderCell],
                ["j", "备注", 150, this.renderCell]
            ]),
            dataList: []
        };
    }

    // 列渲染
    renderCell = ({ title, key, val, row, rowIndex, ColumnItemBox, defaultRender, parent }) => {
        return defaultRender(val);
    };

    show(row) {
        // console.log('show', row)
        let { columns } = this.state;
        const expenseColumns = ColumnFactory.execute(
            row.expenseCells.map((item) => [`exp-${item.columnId}`, item.title, 120, this.renderCell])
        );
        columns = [...columns, ...expenseColumns];
        row.expenseCells.forEach((item) => {
            row[`exp-${item.columnId}`] = item.value;
        });
        this.setState({ visible: true, columns, dataList: [row] });
    }

    // 弹窗隐藏
    close = () => {
        this.setState({
            visible: false,
            dataList: []
        });
    };

    render() {
        const { visible, columns, dataList } = this.state;
        return (
            <Modal
                ref={(v) => (this.view = v)}
                style={{ left: 128 }}
                width={800}
                visible={visible}
                title="报价路线详情"
                onCancel={this.close}
                footer={null}
                bodyStyle={{
                    padding: "10px"
                }}
            >
                <Table
                    noPadding
                    isPreventActionEvent
                    isHideHeaderButton
                    isNoneSelected
                    isNonePagination
                    isNoneNum
                    tableHeight={390}
                    actionWidth={90}
                    parent={this}
                    // cusLoading={loading}
                    columns={columns}
                    isCusSource
                    sourceList={dataList}
                    isNoneAction
                    title={null}
                />
            </Modal>
        );
    }
}

export default QuotationDetails;
