// import Loadable from 'react-loadable'
import React, { Component } from "react";
import { connect } from "dva";

@connect()
export default class Loading extends Component {
    state = {
        loadCount: 0
    };

    reloadComponent = () => {
        this.setState({ loadCount: 1 });
    };

    reload = () => {
        const props = this.props;
        try {
            props.loadable.prototype.reloadComponent();
        } catch (e) {}
        // console.log('props', props)
        props.dispatch({
            type: "global/refresh",
            payload: {
                index: props.index
            }
        });
    };

    render() {
        const props = this.props;
        if (props.error) {
            //   console.log('props', props, props.error)
            return (
                <div onClick={this.reload} style={{ textAlign: "center", padding: "20px 0" }}>
                    模块有更新请刷新后重试!
                </div>
            );
        } else if (props.pastDelay) {
            return <div style={{ textAlign: "center", padding: "20px 0" }}>加载中...</div>;
        } else if (props.timedOut) {
            return <div style={{ textAlign: "center", padding: "20px 0" }}>模块加载超时！</div>;
        } else {
            return null;
        }
    }
}
