import querystring from "querystring";
import InitAxios from "./instance";

const onError = function (reject, error) {
    // console.log('onError', error, error.response)
    if (error && error.message === "鉴权失败") {
        // console.log('鉴权失败')
    } else if (error && error.response) {
        reject(error.response.data);
    } else if (error && error.request) {
        reject({ errmsg: error.message });
    } else if (error && error.message) {
        reject(error.message);
    } else {
        reject(error);
    }
};

const ErrorHandling = (resolve, reject, res) => {
    // console.log('ErrorHandling', res)
    if (typeof res === "object") {
        if (
            ("status" in res && res.status < 400 && !res.errorCode && res.errorCode !== "0" && res.errorCode !== 0) ||
            ("success" in res && res.success)
        ) {
            // console.log('ErrorHandling1')
            // resolve(res.data)
            // return
            if (res.access_token) {
                resolve(res);
            }
            if ("status" in res || "success" in res) {
                resolve(res.data);
            } else {
                resolve(res);
            }
        } else if (
            !("success" in res) &&
            !res.status &&
            !res.errorCode &&
            res.errorCode !== "0" &&
            res.errorCode !== 0
        ) {
            // console.log('ErrorHandling2')
            resolve(res);
        } else if ("success" in res && res.success && "data" in res) {
            // console.log('ErrorHandling3')
            resolve(res.data);
        } else if (res.message === "success" && res.data) {
            resolve(res.data);
        } else {
            // console.log('ErrorHandling4')
            reject(res);
        }
    } else {
        resolve(res);
    }
};

export default class RequestMethod extends InitAxios {
    GET(uri, d) {
        let http = this.getInstance();
        return new Promise((resolve, reject) => {
            http.get(uri, d)
                .then(function (response) {
                    ErrorHandling(resolve, reject, response.data);
                })
                .catch(function (error) {
                    onError(reject, error);
                });
        });
    }

    GET_QUERY(uri, params) {
        let http = this.getInstance();
        return new Promise((resolve, reject) => {
            http.get(uri, { params })
                .then(function (response) {
                    ErrorHandling(resolve, reject, response.data);
                })
                .catch(function (error) {
                    onError(reject, error);
                });
        });
    }

    GETFILE(uri, d) {
        let http = this.getInstance();
        if (typeof d === "object") {
            let isFirst = true;
            for (let key in d) {
                uri = uri + `${(isFirst && "?") || "&"}${key}=${d[key]}`;
                isFirst = false;
            }
        }
        return http({
            method: "GET",
            url: uri,
            responseType: "blob",
            data: d
        });
    }

    POSTFILE(uri, params) {
        let http = this.getInstance();
        const promise = http.post(uri, params, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json"
            }
        });
        // return promise
        return new Promise((resolve, reject) => {
            promise
                .then(function (res) {
                    // console.log('res', 'success' in res, res)
                    if ("data" in res && res.data.type === "application/json") {
                        const reader = new FileReader();
                        reader.addEventListener("loadend", function () {
                            reject(JSON.parse(reader.result));
                        });
                        reader.readAsText(res.data);
                    } else {
                        resolve(res);
                    }
                })
                .catch(function (error) {
                    // console.log('e', error.response)
                    if (
                        error &&
                        error.response &&
                        error.response.data &&
                        error.response.data.type === "application/json"
                    ) {
                        const reader = new FileReader();
                        reader.addEventListener("loadend", function () {
                            //  reject(JSON.parse(reader.result))
                            console.log("err", JSON.parse(reader.result));
                            onError(reject, JSON.parse(reader.result));
                        });
                        reader.readAsText(error.response.data);
                    } else {
                        reject(error);
                    }
                });
        });
    }

    SPOST(uri, params) {
        let http = this.getInstance();
        return new Promise((resolve, reject) => {
            http.post(uri, querystring.stringify(params), {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
                .then(function (response) {
                    ErrorHandling(resolve, reject, response.data);
                })
                .catch(function (error) {
                    onError(reject, error);
                });
        });
    }
    FDPOST(uri, params, rqConfig) {
        let http = this.getInstance();
        return new Promise((resolve, reject) => {
            http.post(uri, params, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: (progressEvent) => {
                    rqConfig?.onUploadProgress?.(progressEvent);
                }
            })
                .then(function (response) {
                    ErrorHandling(resolve, reject, response.data);
                })
                .catch(function (error) {
                    onError(reject, error);
                });
        });
    }

    POST(uri, body) {
        let http = this.getInstance();
        return new Promise((resolve, reject) => {
            http.post(uri, body, { headers: { "Content-Type": "application/json" } })
                .then(function (response) {
                    ErrorHandling(resolve, reject, response.data);
                })
                .catch(function (error) {
                    onError(reject, error);
                });
        });
    }

    DELETE(uri, body) {
        let http = this.getInstance();
        return new Promise((resolve, reject) => {
            http.delete(uri, { headers: { "Content-Type": "application/json" }, data: body })
                .then(function (response) {
                    ErrorHandling(resolve, reject, response.data);
                })
                .catch(function (error) {
                    onError(reject, error);
                });
        });
    }

    _DELETE(uri, body) {
        let http = this.getInstance();
        return new Promise((resolve, reject) => {
            http.delete(uri, { headers: { "Content-Type": "application/json" }, data: body })
                .then(function (response) {
                    ErrorHandling(resolve, reject, response.data);
                })
                .catch(function (error) {
                    onError(reject, error);
                });
        });
    }

    PUT(url, data) {
        let http = this.getInstance();
        return new Promise((resolve, reject) => {
            http.put(url, data, { headers: { "Content-Type": "application/json" } })
                .then(function (response) {
                    ErrorHandling(resolve, reject, response.data);
                })
                .catch(function (error) {
                    onError(reject, error);
                });
        });
    }

    SPUT(url, data) {
        let http = this.getInstance();
        return new Promise((resolve, reject) => {
            http.put(url, stringify(data), {
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            })
                .then(function (response) {
                    ErrorHandling(resolve, reject, response.data);
                })
                .catch(function (error) {
                    onError(reject, error);
                });
        });
    }
}
