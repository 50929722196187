import React, { Suspense, useEffect, useRef, useState } from "react";
import { MP } from "@src/libs/baidumap";
import Map from "./mapInstance";

const LazyComponent = React.lazy(
    () =>
        new Promise((resolve, reject) => {
            MP("DSbseTSYLq0dwPl69EZZmjCyMgmr2rGi").then((BMapGL) => {
                resolve({
                    default: Map(BMapGL)
                } as any);
            });
        })
);

export default function Wrapper(props) {
    return (
        <Suspense fallback={<div>loading ...</div>}>
            <LazyComponent {...props}></LazyComponent>
        </Suspense>
    );
}
