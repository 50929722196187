const m = "wms/departureTransport";
const exportUrl = "wms/export";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS",
    name: "装车发运明细",
    type: "menu_hide",
    method: "GET",
    apiName: "getShipmentEntruckById",
    url: `${m}/getOne`,
    paramsType: 1
};

export const children = {
    CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS_CONFIRM",
        apiName: "confirmShipmentEntruck",
        method: "GET",
        name: "确认",
        type: "view",
        url: `${m}/confirm`,
        paramsType: 1
    },
    UN_CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS_UN_CONFIRM",
        apiName: "unConfirmShipmentEntruck",
        method: "GET",
        name: "取消确认",
        type: "view",
        url: `${m}/cancelConfirm`,
        paramsType: 1
    },
    LIST: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS_LIST",
        apiName: "getShipmentEntruckDetailsList",
        method: "GET",
        name: "获取发运明细列表",
        type: "view",
        url: `${m}/getDetailsList`,
        paramsType: 1,
        isOnlyApi: true
    },
    SHIPMENT_LIST: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS_SHIPMENT_LIST",
        apiName: "getShipmentEntruckShipmentList",
        method: "POST",
        name: "获取发运明细出库单列表",
        type: "view",
        url: `${m}/getShipmentManage`,
        isOnlyApi: true
    },
    DELETE_DETAILS: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS_DELETE_DETAILS",
        apiName: "deleteShipmentEntruckDetails",
        method: "POST",
        name: "删除发运明细",
        type: "view",
        url: `${m}/deleteDetails`
    },
    SEND: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS_SEND",
        apiName: "sendShipmentEntruckDetails",
        method: "GET",
        name: "发运",
        type: "view",
        url: `${m}/shipping`,
        paramsType: 1
    },
    ADD_SEND: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS_ADD_SEND",
        apiName: "addSendShipmentEntruckDetails",
        method: "POST",
        name: "添加发运明细",
        type: "view",
        url: `${m}/saveDetails`,
        isOnlyApi: true
    },
    SUPPLIER: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS_SUPPLIER",
        apiName: "getShipmentEntruckDetailsSupplier",
        method: "GET",
        name: "获取供应商列表",
        type: "view",
        url: `${m}/getSupplierNames`,
        paramsType: 1,
        isOnlyApi: true
    },
    EXPORT: {
        id: "WAREHOUSE_MANAGEMENT_ENTRUCK_DETAILS_EXPORT",
        name: "导出发运单",
        type: "view",
        apiName: "shipmentEntruckExport",
        method: "POSTFILE",
        url: `${exportUrl}/departureTransport/listDemand`
    }
};
