import { children, id } from "./power";
import { deleteNull } from "@src/utils";
const power = {
    ...children,
    ...{
        [id.id]: id
    }
};

export default class DemandDetailsApi {
    /**
     * @param {any} params
     * @returns
     * @memberOf DemandDetailsApi
     */

    // 确认
    [children.CONFIRM_DATA.apiName](params) {
        let url = children.CONFIRM_DATA.url;
        return this.POST(url, params);
    }

    getReceiptScanReceipteList(params) {
        // 获取收货单
        let url = "wms/receiptScan/receipt";
        return this.GET(url);
    }

    getReceiptScanMaterials(params) {
        // 根据收货单id获取物料
        let url = `wms/receiptScan/materials/${params.receiptManageId}`;
        return this.GET(url);
    }

    getReceiptScanReceiptDetails(params) {
        // 获取收货单明细
        let url = `wms/receiptScan/receiptDetails/${params.receiptManageId}`;
        return this.POST(url, params);
    }

    scanSubmit(params) {
        // 扫描提交
        let url = `wms/receiptScan/scanSubmit`;
        return this.POST(url, params);
    }

    getReceiptScanMaterialsPackage(params) {
        // 物料包规
        let url = `wms/receiptScan/materials/package/${params.id}`;
        return this.GET(url);
    }

    onDelReceiptDetailsById(params) {
        // 删除货物明细
        let url = `wms/receiptScan/delete/receiptDetails/${params.id}`;
        return this.GET(url);
    }

    receiptScanTallySubmit(params) {
        // 提交理货
        let url = `wms/receiptScan/tally/submit/${params.receiptManageId}`;
        return this.GET(url);
    }

    getReceiptScanCount(params) {
        // 获取扫描统计
        let url = `wms/receiptScan/count/${params.receiptManageId}`;
        return this.GET(url);
    }
}
