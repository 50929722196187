import React, { Component } from "react";
// import PropTypes from 'prop-types'

export default class CellBox extends Component {
    // static propTypes = {
    //     children: PropTypes.element
    // }

    render() {
        const { parentStyle, boxStyle, children, title } = this.props;
        // return
        return (
            <div
                // title={title || ''}
                {...(!!title ? { title: title.toString() } : {})}
            >
                {children}
                {/* </div> */}
            </div>
        );
    }
}
