const m = "wms/receiptDemand";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS",
    name: "收货需求明细",
    type: "menu_hide"
    // method: 'POST',
    // apiName: 'accountPayableList',
    // url: `order/sendCar/list`
};

export const children = {
    CONFIRM_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_CONFIRM_DATA",
        apiName: "confirmReceipt",
        method: "GET",
        name: "确认",
        type: "view",
        url: `${m}/confirm`
    },
    CANCEL_DATA: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_CANCEL_DATA",
        apiName: "cancelReceipt",
        method: "GET",
        name: "取消确认",
        type: "view",
        url: `${m}/cancel`
    },
    FINISH: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_FINISH",
        apiName: "receiptDemandFinish",
        method: "GET",
        name: "需求完结",
        type: "view",
        url: `${m}/finish`
    },
    UN_FINISH: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_UN_FINISH",
        apiName: "receiptDemandUnFinish",
        method: "GET",
        name: "取消完结",
        type: "view",
        url: `${m}/remove`
    },
    EDIT_BASEINFO: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_EDIT_BASEINFO",
        apiName: "receiptDemandEditBaseInfo",
        method: "POST",
        name: "修改基本信息",
        type: "view",
        url: `${m}/editBaseInfo`
    },
    SAVE_RECEIPT: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_SAVE_RECEIPT",
        apiName: "receiptDemandSaveReceiptItem",
        method: "POST",
        name: "创建收货单",
        type: "view",
        url: `${m}/saveReceiptItem`
    },
    EDIT_RECEIPT: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_EDIT_RECEIPT",
        apiName: "receiptDemandEditReceiptItem",
        method: "POST",
        name: "编辑收货单基本信息",
        type: "view",
        url: `${m}/editReceiptItem`
    },
    DELETE_RECEIPT: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_DELETE_RECEIPT",
        apiName: "receiptDemandDeleteReceiptItem",
        method: "GET",
        name: "删除收货单",
        type: "view",
        url: `${m}/deleteReceiptItem`
    },
    EDIT_DETAILS: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_EDIT_DETAILS",
        apiName: "receiptDemandEditReceiptCargoDetails",
        method: "POST",
        name: "编辑收货单货物",
        type: "view",
        url: `${m}/editReceiptCargoDetails`
    },
    DELETE_DETAILS: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_DELETE_DETAILS",
        apiName: "receiptDemandDeleteReceiptCargoDetails",
        method: "GET",
        name: "删除收货单货物",
        type: "view",
        url: `${m}/deleteReceiptCargoDetails`
    },
    IMPORT: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_IMPORT",
        apiName: "receiptDemandImportMaterials",
        method: "POST",
        name: "导入",
        type: "view",
        url: `${m}/importMaterialForSave`
    },
    EXPORT_T: {
        id: "WAREHOUSE_MANAGEMENT_RECEIPT_DEMAND_DETAILS_EXPORT_T",
        name: "导出表头",
        type: "view",
        apiName: "receiptDemandExportTemp",
        method: "GETFILE",
        url: `${m}/exportTemp`
    }
};
