import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Icon } from "antd";
import { ContextMenu, Item, Separator, ContextMenuProvider } from "react-contexify";
import { initShowTab } from "@src/config";
import { connect } from "dva";

const cannotClosed = ["HOME"];

class MyAwesomeMenu extends React.Component {
    render() {
        const { contextMenuClick } = this.props;
        return (
            <ContextMenu id="menu_id" style={{ zIndex: 1009 }}>
                <Item data="close" onClick={contextMenuClick.bind(this)}>
                    <Icon type="close" />
                    <span className="right-click-menu-text">关闭</span>
                </Item>
                <Item data="refresh" onClick={contextMenuClick.bind(this)}>
                    <Icon type="sync" />
                    <span className="right-click-menu-text">刷新</span>
                </Item>
                <Separator />
                <Item data="closeOther" onClick={contextMenuClick.bind(this)}>
                    <span className="right-click-menu-text">关闭其他</span>
                </Item>
                <Item data="closeAll" onClick={contextMenuClick.bind(this)}>
                    <Icon type="swap" />
                    <span className="right-click-menu-text">关闭全部</span>
                </Item>
                <Separator />
                <Item data="closeRight" onClick={contextMenuClick.bind(this)}>
                    <Icon type="arrow-right" />
                    <span className="right-click-menu-text">关闭右侧所有</span>
                </Item>
                <Item data="closeLeft" onClick={contextMenuClick.bind(this)}>
                    <Icon type="arrow-left" />
                    <span className="right-click-menu-text">关闭左侧所有</span>
                </Item>
            </ContextMenu>
        );
    }
}

const SortableItem = SortableElement(
    ({ tab, tabClick, tabClose, tabIndex, activeTabKey, len, menuClose, menuClick }) => {
        const canClose = tab && !cannotClosed.some((key) => key === tab.key);
        const text = tab.title;

        return (
            <ContextMenuProvider
                className={
                    "global_header_tab_item" + (activeTabKey === tab.key ? " active" : "") + (!canClose ? " index" : "")
                }
                data={tabIndex}
                id={text !== "首页" ? "menu_id" : "home_menu_id"}
                title={text}
            >
                <div
                    className="trapezoid"
                    onClick={() => {
                        menuClick(tab);
                    }}
                ></div>
                <span
                    className="title"
                    onClick={() => {
                        menuClick(tab);
                    }}
                >
                    {text}
                </span>
                {text !== "首页" && (
                    <i
                        className="iconfont close"
                        onClick={(e) => {
                            e.stopPropagation();
                            menuClose("close", tabIndex);
                        }}
                    ></i>
                )}
            </ContextMenuProvider>
        );
    }
);

const SortableList = SortableContainer(({ list, tabClick, tabClose, activeTabKey, left, menuClose, menuClick }) => {
    return (
        <div className="global_tab_list" style={{ left }}>
            {list.map((tab, index) => (
                <SortableItem
                    key={`item-${tab.key}`}
                    activeTabKey={activeTabKey}
                    tab={tab}
                    len={list.length}
                    index={index} // 用于拖动显示效果
                    collection="list"
                    tabIndex={index}
                    tabClick={tabClick}
                    tabClose={tabClose}
                    menuClose={menuClose}
                    menuClick={menuClick}
                />
            ))}
        </div>
    );
});

@connect(({ global }) => ({
    openedTabs: global.openedTabs,
    activeTabKey: global.activeTabKey,
    domWidth: global.domWidth
}))
class HeaderTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabsLeft: 0,
            theTabs: [],
            prevBtnShow: false,
            nextBtnShow: false,
            isResize: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { openedTabs, domWidth } = props;
        const { theTabs, tabsLeft, isResize } = state;
        const result = {
            theTabs: openedTabs,
            isResize
        };
        if (openedTabs.length !== theTabs.length || isResize === true) {
            const tabsWidth = 140;
            const tabsML = -20;
            const otherWidth = 200 + 180 + 28 * 2;
            let conWidth = domWidth - otherWidth;
            conWidth = conWidth > 0 ? conWidth : 0;
            const listWidth = openedTabs.reduce((rt) => (rt += tabsWidth + tabsML), 0) + Math.abs(tabsML) + tabsWidth;
            // console.log('getDerivedStateFromProps', props, state, conWidth, listWidth, isResize)
            if (!!conWidth && listWidth > conWidth) {
                if (openedTabs.length > theTabs.length) {
                    result.tabsLeft = conWidth - listWidth;
                    result.prevBtnShow = true;
                    result.nextBtnShow = false;
                } else {
                    result.prevBtnShow = tabsLeft < 0;
                    result.nextBtnShow = tabsLeft !== conWidth - listWidth;
                }
            } else {
                result.prevBtnShow = false;
                result.nextBtnShow = false;
                if (tabsLeft < 0) {
                    result.tabsLeft = 0;
                }
            }
        }
        return result;
    }

    componentDidMount() {
        if (process.env.NODE_ENV === "development") {
            initShowTab();
        }
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        this.setState({ isResize: true }, () => {
            this.setState({ isResize: false });
        });
    };

    moveTabs = (type) => {
        const { openedTabs, domWidth } = this.props;
        const tabsWidth = 140;
        const tabsML = -20;
        const otherWidth = 200 + 180 + 28 * 2;
        let conWidth = domWidth - otherWidth;
        conWidth = conWidth > 0 ? conWidth : 0;
        const listWidth = openedTabs.reduce((rt) => (rt += tabsWidth + tabsML), 0) + Math.abs(tabsML) + tabsWidth;
        if (type === "prev") {
            this.setState({ tabsLeft: 0, prevBtnShow: false, nextBtnShow: true });
        } else {
            this.setState({ tabsLeft: conWidth - listWidth, prevBtnShow: true, nextBtnShow: false });
        }
    };

    menuClose = (type, index) => {
        const { dispatch } = this.props;
        dispatch({
            type: `global/${type}`,
            payload: {
                index: index - 1
            }
        });
    };

    shouldCancelStart = (e) => {
        // console.log('shouldCancelStart', e.target.title)
        if (e.target.title === "首页") {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const { tabsLeft, prevBtnShow, nextBtnShow } = this.state;
        const { menuClick, contextMenuClick, onSortEnd, openedTabs, activeTabKey } = this.props;
        const list = openedTabs ? [...openedTabs] : [];
        if (!list.some((item) => item.key === "HOME")) {
            list.unshift({
                key: "HOME",
                component: "HOME",
                title: "首页"
            });
        }

        return (
            <React.Fragment>
                <MyAwesomeMenu contextMenuClick={contextMenuClick} />

                <div className="mid_con">
                    <div className={`prev_btn ${prevBtnShow ? "show" : "hide"}`} onClick={() => this.moveTabs("prev")}>
                        <Icon type="left" />
                    </div>
                    <div className="mid_list">
                        <SortableList
                            lockToContainerEdges
                            hideSortableGhost
                            axis={"x"}
                            lockAxis={"x"}
                            lockOffset={"0%"}
                            distance={2}
                            list={list}
                            activeTabKey={activeTabKey}
                            onSortEnd={onSortEnd}
                            shouldCancelStart={this.shouldCancelStart}
                            tabClick={this.selectMenu}
                            tabClose={this.closeMenu}
                            menuClose={this.menuClose}
                            menuClick={menuClick}
                            helperClass="global_header_sort_drag_tab_item"
                            left={tabsLeft}
                        />
                    </div>
                    <div className={`next_btn ${nextBtnShow ? "show" : "hide"}`} onClick={() => this.moveTabs("next")}>
                        <Icon type="right" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HeaderTabs;
