/* eslint-disable */
import _ from "@src/utils/lodash";
import uuidv3 from "uuid/v1";
import NPLib from "number-precision";
import moment from "moment";
import rApi from "@src/http";

const toString = Object.prototype.toString;

interface ImgClient {
    progress: number;
    progressEvent: object;
    receiverProcessFn: (hasProgress, progress, progressEvent?) => void;
    hasProgress: boolean;
    put: (file) => Promise<any>;
    signatureUrl: (url: string) => string;
    onProgress: (fn: Function) => void;
}
export const oImgClient = () => {
    return {};
};

//代理掉原有阿里云的代码
class ProxyImgClient implements ImgClient {
    progress: number;
    progressEvent: object;
    receiverProcessFn: (hasProgress, progress, progressEvent?) => void;
    hasProgress: boolean;
    constructor(props) {
        this.progress = 0;
        this.progressEvent = null;
        this.hasProgress = true;
    }
    put(file) {
        try {
            const formData = new FormData();

            formData.append("file", file);
            return rApi["commonUploadFile"](formData, {
                onUploadProgress: (progressEvent) => {
                    this.hasProgress = progressEvent.lengthComputable;
                    if (progressEvent.lengthComputable) {
                        this.progress = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                        this.progressEvent = progressEvent;
                    }
                    this.receiverProcessFn?.(this.hasProgress, this.progress, this.progressEvent);
                    // console.log("proxy pImgClient", progressEvent);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
    signatureUrl(url) {
        return url;
    }

    onProgress(fn: (...a) => void) {
        if (typeof fn === "function") {
            this.receiverProcessFn = fn;
        } else {
            console.error("传入的参数不是一个函数");
            throw new Error("传入的参数不是一个函数");
        }
    }
}
const pImgClient: () => ImgClient = new Proxy(oImgClient as any, {
    apply(target, ctx, args) {
        return new ProxyImgClient(args);
    }
});
export { pImgClient as imgClient };

export const random_string = () => {
    //生成uuid
    let reg = /-/g;
    let pwd = uuidv3().replace(reg, "");
    return pwd;
};

export const get_suffix = (filename) => {
    //截取文件后缀名a.png => png
    let pos = filename.lastIndexOf(".");
    let suffix = "";
    if (pos != -1) {
        suffix = filename.substring(pos);
    }
    return suffix;
};

export const addressToString = (address, options = {}) => {
    if (address === null || address === undefined) {
        return "";
    }
    const initOptions = {
        proName: "pro",
        cityName: "city",
        distName: "dist",
        streetName: "street",
        extraName: "extra"
    };
    options = {
        ...initOptions,
        ...options
    };
    let addressData: any = {};
    if (typeof address === "object") {
        addressData = address;
    } else if (typeof address === "string" && address.startsWith("{")) {
        addressData = JSON.parse(address);
    } else {
        addressData = address;
    }
    let s = "";
    const perHasNameKey = options["perHasNameKey"];
    const getKeysArr = [
        options["proName"],
        options["cityName"],
        options["distName"],
        options["streetName"],
        options["extraName"]
    ];
    getKeysArr.forEach((key, index) => {
        let pStr = "";
        if (addressData[key]) {
            if (index === 4) {
                pStr = " " + addressData[key];
            } else {
                if (perHasNameKey || addressData[key]["name"]) {
                    pStr = addressData[key]["name"];
                } else {
                    pStr = addressData[key];
                }
            }
        }
        s += _.isString(pStr) ? pStr : "";
    });

    return s;
};

export const addressToJson = (address) => {
    if (address === null || address === undefined) {
        return {};
    }
    const arr = ["pro", "city", "dist", "street"];
    try {
        if (Object.prototype.toString.call(address) === "[object Object]") {
            const json = {};
            let str = "";
            arr.forEach((per, index) => {
                json[per] = address[per];
                str += address[per] ? address[per] : "";
                str += index >= arr.length ? " " : "/";
            });
            json["extra"] = address["extra"];
            json["formatAddress"] = str;
            return json;
        } else if (Object.prototype.toString.call(address) === "[object Array]") {
            const json = {};
            let str = "";
            arr.forEach((per, index) => {
                json[per] = address[index];
                str += address[index] ? address[index] : "";
                str += index >= address.length ? " " : "/";
            });
            json["extra"] = address?.[5];
            json["formatAddress"] = str;
            return json;
        } else if (typeof address === "string") {
            if (address.startsWith("{")) {
                address = JSON.parse(address);
                return address;
            } else {
                let json: any = {};
                let addr = address.split(" "),
                    extra = "";
                // console.log(addr)
                if (addr.length > 1) {
                    extra = addr[1];
                }
                let addrArr = addr[0].split("/");

                addrArr.forEach((s, i) => {
                    json[arr[i]] = s;
                });
                json.extra = extra;
                json.formatAddress = address;
                return json;
            }
        } else {
            return {};
        }
    } catch (error) {
        console.error(error, "地址格式不正确");
        return {};
    }
};

export const addressJsonStringFormat = (address) => {
    try {
        return addressToJson(address);
    } catch (e) {
        console.error("地址解析错误", e);
        return {};
    }
};

export const getAddressExtra = (address) => {
    //获取地址的详细地址
    let addressData: any = addressToJson(address);
    let s = "";
    if (addressData && addressData.extra) {
        s += addressData.extra;
    }

    return s;
};

export const addressFormat = (address) => {
    try {
        if (typeof address === "string" && address.startsWith("{")) {
            address = JSON.parse(address);
        } else if (typeof address === "string") {
            return address;
        }
    } catch (e) {
        // console.error('地址解析错误', e)
        return address;
    }
    if (address && address.formatAddress) {
        return address.formatAddress;
    } else if (address && address.pro && address.pro.name) {
        addressToString(address);
    }
    return "";
};

export const dedupe = (array) => {
    //数组去重
    return Array.from(new Set(array));
};

export const ObjectArrayDeduplication = (data, filterKey) => {
    //对象数组去重
    let obj = {};
    for (let item of data) {
        obj[item[filterKey]] = item;
    }
    return Object.values(obj) || [];
};

export const addressToCity = (address) => {
    //解析地址到city层
    try {
        if (typeof address === "string" && address.startsWith("{")) {
            address = JSON.parse(address);
        } else if (typeof address === "string") {
            return address;
        }
    } catch (e) {
        return address;
    }
    let s = "";
    if (address && address.pro && address.pro.name) {
        s += address.pro.name;
    }

    if (address && address.city && address.city.name) {
        s += "/";
        s += address.city.name;
    }

    return s;
};

export const addressToPlaceholder = (address) => {
    let s = [];
    if (address && address.pro && address.pro.name) {
        s.push(address.pro.name);
    } else {
        s.push(null);
    }

    if (address && address.city && address.city.name) {
        s.push(address.city.name);
    } else {
        s.push(null);
    }

    if (address && address.dist && address.dist.name) {
        s.push(address.dist.name);
    } else {
        s.push(null);
    }

    if (address && address.street && address.street.name) {
        s.push(address.street.name);
    } else {
        s.push(null);
    }
    return s;
};

export const isArray = (o) => {
    return _.isArray(o);
};

export const stringToArray = (d) => {
    //将'[]'转为array数组即[]
    if (isArray(d)) {
        return d;
    }
    if (typeof d === "string") {
        try {
            let array = JSON.parse(d);
            if (isArray(array)) {
                return array;
            }
        } catch (e) {}
    }
    return [];
};

export const stringObjectObject = (d) => {
    //将'{}'转为{}
    if (_.isObject(d)) {
        return d;
    }
    try {
        if (typeof d === "string" && d.startsWith("{")) {
            d = JSON.parse(d);
            return d;
        } else if (typeof d === "string") {
            d = JSON.parse(d);
            return d;
        } else if (typeof d === "object") {
            return d;
        }
    } catch (e) {
        // console.error('地址解析错误', e)
        return d;
    }
};

/**
 * 处理对象中的 undefined、null、0
 * o：要处理的对象
 * ignoreKys：忽略的对象键值
 * isIgnore is object： isIgnoreZero： 是否忽略键值为零， isIgnoreNull： 是否忽略键值为null
 */
export const deleteNull = (o, ignoreKys = [], isIgnore = { isIgnoreZero: false, isIgnoreNull: false }) => {
    if (!isArray(ignoreKys)) {
        //console.error('deleteNull ignoreKys is not a array', 'o =', o, 'ignoreKys = ', ignoreKys)
        return o;
    }
    if (typeof o === "object") {
        for (let key in o) {
            if (
                !_.isNumber(o[key]) &&
                !o[key] &&
                o[key] !== false &&
                ignoreKys.filter((item) => key === item).length < 1
            ) {
                delete o[key];
            }
        }
    }
    return o;
};

export const s2ab = (s) => {
    s = window.atob(s);
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
};

export const cloneObject = function (obj) {
    // d对象数组的深拷贝
    let str;
    if (typeof obj !== "object") {
        return obj;
    }
    let newObj = obj.constructor === Array ? [] : {};
    if (typeof obj !== "object") {
        return;
    } else if (window.JSON) {
        try {
            str = JSON.stringify(obj); // 系列化对象
            newObj = JSON.parse(str); // 还原
        } catch (e) {
            //console.log('cloneObject', obj)
            if (isArray(obj)) {
                return [...obj];
            } else {
                return { ...obj, render: obj.render };
            }
        }
    } else {
        for (let i in obj) {
            newObj[i] = typeof obj[i] === "object" ? cloneObject(obj[i]) : obj[i];
        }
    }
    // console.log('cloneObject newObj', newObj)
    return newObj;
};

/**
 * 深拷贝
 * @param {*} obj 要拷贝的对象
 */
export const deepCopy = function (obj) {
    // 只拷贝对象
    if (typeof obj !== "object") return; // 根据obj的类型判断是新建一个数组还是一个对象
    var newObj = obj instanceof Array ? [] : {};
    for (var key in obj) {
        // 遍历obj,并且判断是obj的属性才拷贝
        if (obj.hasOwnProperty(key)) {
            // 判断属性值的类型，如果是对象递归调用深拷贝
            newObj[key] = typeof obj[key] === "object" ? deepCopy(obj[key]) : obj[key];
        }
    }
    return newObj;
};

export const objDeepCopy = (source) => {
    // d对象数组的深拷贝可以拷贝数组
    if (source === null) {
        return source;
    }
    if (typeof source !== "object" || (source && source["$mobx"])) {
        return source;
    }
    if (isArray(source)) {
        return source.map((item) => ({ ...item }));
    }
    let sourceCopy = {};
    for (let item in source) {
        if (sourceCopy[item] && (source[item]["$mobx"] || source[item]["$typeof"] || source[item]["$$typeof"])) {
            sourceCopy[item] = source[item];
        } else {
            sourceCopy[item] = typeof source[item] === "object" ? objDeepCopy(source[item]) : source[item];
        }
    }
    return sourceCopy;
};

export const trim = (str) => {
    //删除左右两端的空格
    // return str.replace(/(^\s*)|(\s*$)/g, "");
    return _.trim(str);
};

/**
 * 香港手机号码8位数，5|6|8|9开头+7位任意数
 */
function checkHKMobilePhone(mobile) {
    if (!new RegExp(/^(5|6|8|9)\d{7}$/).test(mobile) && !new RegExp(/^(8525|8526|8528|8529)\d{7}$/).test(mobile)) {
        return false;
    }

    return true;
}

export const validateToNextCellPhone = (rule, value, callback) => {
    //匹配电话或手机
    let reg_tPhone = /^[1]{1}[3,4,5,6,7,8,9]{1}[0-9]{9}$/;
    if (reg_tPhone.test(value) || checkHKMobilePhone(value) || !value || value === "") {
        callback();
    } else {
        callback("手机号码格式错误");
    }
};

export const validateToNextQQorEmail = (rule, value, callback) => {
    //console.log('value', value)
    let reg_email = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    let reg_qq = /^[1-9][0-9]{4,9}$/gim;
    if (reg_email.test(value) || reg_qq.test(value)) {
        callback();
    } else {
        callback("邮箱/QQ格式错误");
    }
};

export const validateToNextPhone = (rule, value, callback) => {
    //匹配电话或手机
    let reg_tPhone = /^[1]{1}[3,4,5,6,7,8,9]{1}[0-9]{9}$/;
    let reg_phone = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
    if (reg_tPhone.test(value) || reg_phone.test(value) || !value || value === "") {
        callback();
    } else {
        callback("联系电话格式错误");
    }
};

export const validateToNextPhoneOrNoNUll = (rule, value, callback) => {
    //匹配电话或手机并且不能为空
    let reg_tPhone = /^[1]{1}[3,4,5,6,7,8,9]{1}[0-9]{9}$/;
    let reg_phone = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
    if (value === "" || !value) {
        callback("请填写联系方式");
    } else {
        if (reg_tPhone.test(value) || reg_phone.test(value) || checkHKMobilePhone(value)) {
            callback();
        } else {
            callback("格式错误");
        }
    }
};

export const validateToCellPhone = (rule, value, callback) => {
    //匹配手机号
    let reg_tPhone = /^[1]{1}[3,4,5,6,7,8,9]{1}[0-9]{9}$/;
    if (reg_tPhone.test(value) || value === "" || !value || checkHKMobilePhone(value)) {
        callback();
    } else {
        callback("手机号格式错误");
    }
};

export const validateToCellPhoneAndNoNull = (rule, value, callback) => {
    //匹配手机号不能为空
    let reg_tPhone = /^[1]{1}[3,4,5,6,7,8,9]{1}[0-9]{9}$/;
    if (value === "" || !value) {
        callback("请填写手机号");
    } else if (reg_tPhone.test(value) || checkHKMobilePhone(value)) {
        callback();
    } else {
        callback("手机号格式错误");
    }
};
export const validateToHKPhoneAndNoNull = (rule, value, callback) => {
    //匹配手机号不能为空
    let reg_tPhone = /^[1]{1}[3,4,5,6,7,8,9]{1}[0-9]{9}$/;
    let hk_tPhone: any = /^(5|6|8|9)\\d{7}$/;
    // console.log(hk_tPhone(value), 'value')
    if (value === "" || !value) {
        callback("请填写手机号");
    } else if (reg_tPhone.test(value) || hk_tPhone(value) || checkHKMobilePhone(value)) {
        callback();
    } else {
        callback("手机号格式错误");
    }
};

export const validateToNextQQ = (rule, value, callback) => {
    //console.log('value', value)
    let reg_qq = /^[1-9][0-9]{4,9}$/gim;
    if (reg_qq.test(value) || !value || value === "") {
        callback();
    } else {
        callback("QQ格式错误");
    }
};

export const validateToNextCar = (rule, value, callback) => {
    //匹银行卡号
    let reg_card = /^\d{5,30}$/;
    if (reg_card.test(value) || !value || value === "") {
        callback();
    } else {
        callback("银行卡号格式错误");
    }
};

export const validateUsername = (rule, value, callback) => {
    //不能为中文并且长度在4-16位并且不能为空
    let reg = /^[a-zA-Z0-9_-]{4,16}$/;
    if (!value || value === "") {
        callback("请填写用户账号!");
    } else if (reg.test(value)) {
        callback();
    } else {
        callback("账号由字母，数字,下划线组成长度4-16位!");
    }
};

export const validatePasswordAndNoNull = (rule, value, callback) => {
    //不能为中文并且长度在4-16位并且不能为空
    let reg = /^[a-zA-Z0-9]{4,16}$/;
    if (!value || value === "") {
        callback("请填写密码");
    } else if (reg.test(value || value === "" || value === null)) {
        callback();
    } else {
        callback("密码由字母,数字组成长度4-16位");
    }
};

export const validatePassword = (rule, value, callback) => {
    //不能为中文并且长度在4-16位
    let reg = /^[a-zA-Z0-9]{4,16}$/;
    if (reg.test(value || value === "" || value === null)) {
        callback();
    } else {
        callback("密码由字母,数字组成长度4-16位");
    }
};

export const validateNumberAndText = (rule, value, callback) => {
    //匹配数字和字母
    let reg = /^[0-9a-zA-Z]+$/;
    if (reg.test(value) || !value || value === "") {
        callback();
    } else {
        callback("只能为数字和字母！");
    }
};

export const validateNumberAndLetter = (rule, value, callback) => {
    //匹配数字和字母并且不能为空
    let reg = /^[0-9a-zA-Z]+$/;
    if (!value || value === "") {
        // callback('请填写此项')
        callback();
    } else if (reg.test(value)) {
        callback();
    } else {
        callback("只能为数字和字母！");
    }
};

export const validateCarCodeAndNoNull = (rule, value, callback) => {
    //车牌号码校验包含新能源
    var xreg =
        /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[警京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼]{0,1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}|[黑黄蓝绿]{1}$/;
    if (!value || value === "") {
        callback("车牌号不能为空!");
    } else if (xreg.test(value)) {
        callback();
    } else {
        callback("车牌格式错误!");
    }
};

export const validateCarCode = (rule, value, callback) => {
    //车牌号码校验包含新能源
    var xreg =
        /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[警京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼]{0,1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}|[黑黄蓝绿]{1}$/;
    //var creg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/
    if (xreg.test(value) || !value || value === null) {
        callback();
    } else {
        callback("车牌格式错误!");
    }
};

export const validCarCode = (value) => {
    //车牌号码校验
    var xreg =
        /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[警京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼]{0,1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}|[黑黄蓝绿]{1}$/;
    //var creg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/
    if (xreg.test(value) && value && value !== null) {
        return true;
    } else {
        return false;
    }
};

export const validateUppercaseLetter = (rule, value, callback) => {
    //全部为大写字母
    var xreg = /^[A-Z]+$/;
    if (xreg.test(value) || !value || value === null) {
        callback();
    } else {
        callback("格式错误!");
    }
};

let base64map = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
export const CryptoUtil = {
    // Bit-wise rotate left
    rotl: function (n, b) {
        return (n << b) | (n >>> (32 - b));
    },

    // Bit-wise rotate right
    rotr: function (n, b) {
        return (n << (32 - b)) | (n >>> b);
    },

    // Swap big-endian to little-endian and vice versa
    endian: function (n) {
        // If number given, swap endian
        if (n.constructor == Number) {
            return (CryptoUtil.rotl(n, 8) & 0x00ff00ff) | (CryptoUtil.rotl(n, 24) & 0xff00ff00);
        }

        // Else, assume array and swap all items
        for (var i = 0; i < n.length; i++) n[i] = CryptoUtil.endian(n[i]);
        return n;
    },

    // Generate an array of any length of random bytes
    randomBytes: function (n) {
        for (var bytes = []; n > 0; n--) bytes.push(Math.floor(Math.random() * 256));
        return bytes;
    },

    // Convert a string to a byte array
    stringToBytes: function (str) {
        var bytes = [];
        for (var i = 0; i < str.length; i++) bytes.push(str.charCodeAt(i));
        return bytes;
    },

    // Convert a byte array to a string
    bytesToString: function (bytes) {
        var str = [];
        for (var i = 0; i < bytes.length; i++) str.push(String.fromCharCode(bytes[i]));
        return str.join("");
    },

    // Convert a string to big-endian 32-bit words
    stringToWords: function (str) {
        var words = [];
        for (var c = 0, b = 0; c < str.length; c++, b += 8) words[b >>> 5] |= str.charCodeAt(c) << (24 - (b % 32));
        return words;
    },

    // Convert a byte array to big-endian 32-bits words
    bytesToWords: function (bytes) {
        var words = [];
        for (var i = 0, b = 0; i < bytes.length; i++, b += 8) words[b >>> 5] |= bytes[i] << (24 - (b % 32));
        return words;
    },

    // Convert big-endian 32-bit words to a byte array
    wordsToBytes: function (words) {
        var bytes = [];
        for (var b = 0; b < words.length * 32; b += 8) bytes.push((words[b >>> 5] >>> (24 - (b % 32))) & 0xff);
        return bytes;
    },

    // Convert a byte array to a hex string
    bytesToHex: function (bytes) {
        var hex = [];
        for (var i = 0; i < bytes.length; i++) {
            hex.push((bytes[i] >>> 4).toString(16));
            hex.push((bytes[i] & 0xf).toString(16));
        }
        return hex.join("");
    },

    // Convert a hex string to a byte array
    hexToBytes: function (hex) {
        var bytes = [];
        for (var c = 0; c < hex.length; c += 2) bytes.push(parseInt(hex.substr(c, 2), 16));
        return bytes;
    },

    // Convert a byte array to a base-64 string
    bytesToBase64: function (bytes) {
        // Use browser-native function if it exists
        if (typeof btoa == "function") return btoa(CryptoUtil.bytesToString(bytes));

        var base64 = [],
            overflow;

        for (var i = 0; i < bytes.length; i++) {
            switch (i % 3) {
                case 0:
                    base64.push(base64map.charAt(bytes[i] >>> 2));
                    overflow = (bytes[i] & 0x3) << 4;
                    break;
                case 1:
                    base64.push(base64map.charAt(overflow | (bytes[i] >>> 4)));
                    overflow = (bytes[i] & 0xf) << 2;
                    break;
                case 2:
                    base64.push(base64map.charAt(overflow | (bytes[i] >>> 6)));
                    base64.push(base64map.charAt(bytes[i] & 0x3f));
                    overflow = -1;
            }
        }

        // Encode overflow bits, if there are any
        if (overflow != undefined && overflow != -1) base64.push(base64map.charAt(overflow));

        // Add padding
        while (base64.length % 4 != 0) base64.push("=");

        return base64.join("");
    },

    // Convert a base-64 string to a byte array
    base64ToBytes: function (base64) {
        // Use browser-native function if it exists
        if (typeof atob == "function") return CryptoUtil.stringToBytes(atob(base64));

        // Remove non-base-64 characters
        base64 = base64.replace(/[^A-Z0-9+\/]/gi, "");

        var bytes = [];

        for (var i = 0; i < base64.length; i++) {
            switch (i % 4) {
                case 1:
                    bytes.push(
                        (base64map.indexOf(base64.charAt(i - 1)) << 2) | (base64map.indexOf(base64.charAt(i)) >>> 4)
                    );
                    break;
                case 2:
                    bytes.push(
                        ((base64map.indexOf(base64.charAt(i - 1)) & 0xf) << 4) |
                            (base64map.indexOf(base64.charAt(i)) >>> 2)
                    );
                    break;
                case 3:
                    bytes.push(
                        ((base64map.indexOf(base64.charAt(i - 1)) & 0x3) << 6) | base64map.indexOf(base64.charAt(i))
                    );
                    break;
            }
        }
        return bytes;
    }
};

// 判断是否json格式字符串
export const isJsonString = (str) => {
    try {
        if (typeof JSON.parse(str) == "object") {
            return true;
        }
    } catch (e) {}
    return false;
};

// 判断是否空字符
export const isEmptyString = (str) => {
    try {
        if (trim(str).length < 1 || trim(str) === "" || trim(str) === null) {
            return true;
        }
    } catch (e) {}
    return false;
};

export const getBillingMethodId = () => {
    //获取报价计费id
    if (process.env.NODE_ENV === "production") {
        return 155;
    }
    return 139;
};

export const fullScreen = (el) => {
    //全屏模式
    var isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
    if (!isFullscreen) {
        //进入全屏
        (el.requestFullscreen && el.requestFullscreen()) ||
            (el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
            (el.webkitRequestFullscreen && el.webkitRequestFullscreen()) ||
            (el.msRequestFullscreen && el.msRequestFullscreen());
    } else {
        //退出全屏
        document.exitFullscreen
            ? document.exitFullscreen()
            : document.mozCancelFullScreen
            ? document.mozCancelFullScreen()
            : document.webkitExitFullscreen
            ? document.webkitExitFullscreen()
            : "";
    }
};

export const exitScreen = () => {
    //取消全屏模式
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
};

// 重置 antd form表单错误提示 参数 form 表单指针
export const resetFormError = (form) => {
    if (!form) return;
    let vals = form.getFieldsValue();
    let resetArr = [];
    for (let key of Object.keys(vals)) {
        if (!vals[key]) {
            resetArr.push(key);
        }
    }
    form.resetFields(resetArr);
};

export const PAYMENT_TERMS = {
    //付款条件
    // 0: '现结结',
    // 1: '回单结',
    // 2: '单票结',
    3: "月结",
    4: "票结"
    // 5: '每周结'
};

// 对象 key value 翻转
// 形如 { 1: 'test' } = > { 'test': 1 }
export const keyValueReversal = (obj) => {
    if (toString.call(obj) !== "[object Object]") {
        console.warn("入参非 object");
        return false;
    }
    const values = Object.values(obj);
    const keys = Object.keys(obj);
    let res: any = {};
    if (
        values.every((item) => toString.call(item) === "[object String]" || toString.call(item) === "[object Number]")
    ) {
        values.map((item: any, index) => (res[item] = keys[index]));
        return res;
    } else {
        console.warn("入参对象中存在非 Number 或 非 String 属性");
        return false;
    }
};

export const getMinMax = (arr) => {
    // 获取最小值和最大值
    let min = arr[0] || 0;
    let max = arr[0] || 0;
    for (let i = 0, len = arr.length; i < len; i++) {
        min = arr[i] < min ? arr[i] : min;
        max = arr[i] > max ? arr[i] : max;
    }
    return { min, max };
};

export const findArrIndex = (array, key, val) => {
    // 获取对象数组某个元素下标
    let i = array.length;
    while (i--) {
        if (array[i][key] === val) {
            return i;
        }
    }
    return -1;
};

// 判断是否全屏
// @return [全屏则返回当前调用全屏的元素,不全屏返回false]
export function isFullscreen() {
    return (
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        false
    );
}

/**
 * 对象与对象之间赋值
 * @param {*} result 结果对象
 * @param {*} obj 值对象
 */
export function objEvaluate(result, obj) {
    for (const key in result) {
        if (result.hasOwnProperty(key) && obj.hasOwnProperty(key)) {
            result[key] = obj[key];
        }
    }
    return result;
}

const hasOwn = Object.prototype.hasOwnProperty;

function is(x, y) {
    if (x === y) {
        return x !== 0 || y !== 0 || 1 / x === 1 / y;
    } else {
        return x !== x && y !== y;
    }
}

export function shallowEqual(objA, objB) {
    if (is(objA, objB)) return true;

    if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) {
        return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) return false;

    for (let i = 0; i < keysA.length; i++) {
        if (!hasOwn.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
            return false;
        }
    }

    return true;
}

/**
 * 数组交换位置 arr原数组 index1 index2要交互的位置下标
 *
 * @memberof fun
 */
export function swapArray(arr, index1, index2) {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0];
    return arr;
}

export function isNumber(value) {
    return typeof value === "number" && !isNaN(value);
}

export function fromHex(color, opacity = 1) {
    // 16进制色值转rgb
    let t: any = {},
        bits = color.length == 4 ? 4 : 8, //如果是shorthand， #fff, 那么bits为4位, 每一位代表的个属性, 其它的为8位 每两位代表一个属性 #ffffff00
        mask = (1 << bits) - 1; //表示字节占位符， 向左移4位或8位，var a = (1 << 4 ) - 1 -> 10000 - 1,  a.toString(2); // 1111，或者 8位的 1111 1111
    color = Number("0x" + color.substr(1)); //#ff0000 转变为16进制0xff0000;
    if (isNaN(color)) {
        return null; // Color
    }
    ["b", "g", "r"].forEach(function (x) {
        let c = color & mask;
        color >>= bits;
        t[x] = bits == 4 ? 17 * c : c; // 0xfff ， 一个f应该代表 255, 应该当[0-255]，按15等份划分，每一等份间隔 17。 所以获得的值需要乘以17, 才能表示rgb中255的值
    });

    return `rgba(${t.r}, ${t.g}, ${t.b}, ${opacity})`; // Color
}

// 判断浏览器类型
export function BrowserType() {
    let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    let isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
    // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    let isIE = window["ActiveXObject"] || "ActiveXObject" in window;
    // var isEdge = userAgent.indexOf("Windows NT 6.1; Trident/7.0;") > -1 && !isIE; //判断是否IE的Edge浏览器
    let isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    let isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    let isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
    let isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1 && !isEdge; //判断Chrome浏览器

    if (isIE) {
        let reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        let fIEVersion = parseFloat(RegExp["$1"]);
        if (userAgent.indexOf("MSIE 6.0") != -1) {
            return "IE6";
        } else if (fIEVersion == 7) {
            return "IE7";
        } else if (fIEVersion == 8) {
            return "IE8";
        } else if (fIEVersion == 9) {
            return "IE9";
        } else if (fIEVersion == 10) {
            return "IE10";
        } else if (userAgent.toLowerCase().match(/rv:([\d.]+)\) like gecko/)) {
            return "IE11";
        } else {
            return "0";
        } //IE版本过低
    } //isIE end

    if (isFF) {
        return "FF";
    }
    if (isOpera) {
        return "Opera";
    }
    if (isSafari) {
        return "Safari";
    }
    if (isChrome) {
        return "Chrome";
    }
    if (isEdge) {
        return "Edge";
    }
}

export const sleep = (timeoutMs) => new Promise((resolve) => setTimeout(resolve, timeoutMs));

export const localSave = function (key, val) {
    localStorage.setItem(key, val);
};

export const localRead = function (key) {
    return localStorage.getItem(key);
};

export const localRemove = function (key) {
    localStorage.removeItem(key);
};

export function splitCommaArr(value) {
    if (value && value.length > 0) {
        let list = [];
        if (value.indexOf("，") !== -1) {
            list = value.split("，");
        }
        if (value.indexOf(",") !== -1) {
            list = value.split(",");
        }

        if (value.indexOf("，") === -1 && value.indexOf(",") === -1) {
            list.push(value);
        }

        return list || [];
    } else {
        return [];
    }
}

export function splitDunkArr(value) {
    if (value && value.length > 0) {
        let list = [];
        if (value.indexOf("、") !== -1) {
            list = value.split("、");
        }

        if (value.indexOf("、") === -1) {
            list.push(value);
        }

        return list || [];
    } else {
        return [];
    }
}

export function isJSON(str) {
    if (typeof str == "string") {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == "object" && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
}

/*
 * 计算两点对于正北方向的朝向角度 [0,360]
 * @param {*} start format:{'lat': 30, 'lng': 120 }
 * @param {*} end
 */
export function bearing(start, end) {
    let rad = Math.PI / 180,
        lat1 = start.lat * rad,
        lat2 = end.lat * rad,
        lon1 = start.lng * rad,
        lon2 = end.lng * rad;
    const a = Math.sin(lon2 - lon1) * Math.cos(lat2);
    const b = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

    return radiansToDegrees(Math.atan2(a, b));
}

/*
 * 弧度转换为角度
 */
function radiansToDegrees(radians) {
    const degrees = radians % (2 * Math.PI);
    return (degrees * 180) / Math.PI;
}

/**
 * 根据几个经纬度的点 获取位于多点中间的 经纬度
 * @param {{ lat: number, lng: number}[] } lng_lat 经纬度集合
 */
export function getMidPosition(lng_lat) {
    let x = 0;
    let y = 0;
    let z = 0;
    const length = lng_lat.length;

    lng_lat.map((item) => {
        let lat = (item.lat * Math.PI) / 180;
        let lng = (item.lng * Math.PI) / 180;

        x += Math.cos(lat) * Math.cos(lng);
        y += Math.cos(lat) * Math.sin(lng);
        z += Math.sin(lat);
    });

    x /= length;
    y /= length;
    z /= length;

    const lng = Math.atan2(y, x);
    const hyp = Math.sqrt(x * x + y * y);
    const lat = Math.atan2(z, hyp);

    return { lat: (lat * 180) / Math.PI, lng: (lng * 180) / Math.PI };
}

// 根据 两点经纬度 获取第三条边上的一点的经纬度
export function getAnalogLngLat(lng_lat, num = 8) {
    const x = lng_lat[0];
    const y = lng_lat[1];
    const l = { lng: y.lng, lat: x.lat };
    const line = Math.pow(l.lng - x.lng, 2) + Math.pow(y.lat - l.lat, 2);
    const lng = (y.lng - x.lng) / num;
    const lat = (y.lat - x.lat) / num;

    x.lng += lng;
    x.lat += lat;

    return x;
}

export function excelTimeToTimesTamp(value) {
    const second = 25569;
    let dayTime = 24 * 60 * 60 * 1000;
    let date = (value - second) * dayTime;
    return date;
}

export function timeFilter(value) {
    let orderDateStr = null;
    let orderDate = null;
    if (value && String(value).length === 5) {
        orderDateStr = excelTimeToTimesTamp(value);
        orderDate = moment(orderDateStr).format("YYYY-MM-DD");
    } else {
        if (value) {
            orderDate = String(value).indexOf("/") !== -1 ? String(value).replace(/\//g, "-") : value;
        }
    }
    return orderDate;
}

export function dateTimeFilter(value) {
    let orderDateStr = null;
    let orderDate = null;
    if (value && (parseInt(value) as any).length === 5) {
        orderDateStr = excelTimeToTimesTamp(value);
        orderDate = moment(orderDateStr).format("YYYY-MM-DD hh:mm");
    } else {
        if (value) {
            orderDate = String(value).indexOf("/") !== -1 ? String(value).replace(/\//g, "-") : value;
        }
    }
    return orderDate;
}

export const calcTimeDiff = (prev, next, measure = "hours") => moment(prev).diff(moment(next), measure as any);

interface INP {
    ratio: number;
    strip: Function;
    plus: Function;
    minus: Function;
    times: Function;
    divide: Function;
    round: Function;
    digitLength: Function;
    float2Fixed: Function;
    enableBoundaryChecking: Function;
}

export class NP implements INP {
    ratio = 0;

    constructor({ ratio } = { ratio: 4 }) {
        this.ratio = ratio || 4;
    }

    preCheckValue(ratio, func, ...params) {
        params = params.map((el) => {
            if (el === null || _.isNaN(Number(el))) {
                return 0;
            } else {
                return el;
            }
        });
        return this[func](...params).toFixed(ratio);
    }
    strip(...params) {
        return this.preCheckValue.call(NPLib, this.ratio, "strip", ...params);
    }
    plus(...params) {
        return this.preCheckValue.call(NPLib, this.ratio, "plus", ...params);
    }
    minus(...params) {
        return this.preCheckValue.call(NPLib, this.ratio, "minus", ...params);
    }
    times(...params) {
        return this.preCheckValue.call(NPLib, this.ratio, "times", ...params);
    }
    divide(...params) {
        return this.preCheckValue.call(NPLib, this.ratio, "divide", ...params);
    }
    round(...params) {
        return this.preCheckValue.call(NPLib, this.ratio, "round", ...params);
    }
    digitLength(...params) {
        return this.preCheckValue.call(NPLib, this.ratio, "digitLength", ...params);
    }
    float2Fixed(...params) {
        return this.preCheckValue.call(NPLib, this.ratio, "float2Fixed", ...params);
    }
    enableBoundaryChecking(...params) {
        return this.preCheckValue.call(NPLib, this.ratio, "enableBoundaryChecking", ...params);
    }
}

export function countListKey(list, key) {
    if (!Array.isArray(list)) return 0;
    return list.reduce((prev, cur) => {
        prev = NPLib.plus(prev || 0, cur?.[key] || 0);
        return prev;
    }, 0);
}

// 从目标根据包含字段拷贝指定属性
export const getPropertyByAddon = (source, addonStr) => {
    if (!source) return;
    const reg = new RegExp(addonStr, "ig");
    const res = {};
    _.keys(source).forEach((key) => {
        if (reg.test(key)) {
            res[key] = source[key];
        }
    });
    return res;
};

export function selfQueryString(search: string): object | any {
    if (typeof search !== "string") {
        throw "请输入一个字符串";
    }
    const obj = {};
    const reg = /(?:\?)?([^=&]+)=([^=&]+)/g;

    let regRes;

    while ((regRes = reg.exec(search))) {
        obj[regRes[1]] = regRes[2];
    }
    return obj;
}

const changeDate = (timeNum) => {
    const d = timeNum - 1;
    const t = Math.round((d - Math.floor(d)) * 24 * 60 * 60);
    return moment(new Date(1900, 0, d, 0, 0, t));
};

/**
 * @function xlsx时间处理
 * @param s
 * @returns Date
 */
export const formatXlsxDate = (s) => {
    // console.log("formatXlsxDate", s);
    if ((_.isString(s) && s.length < 1) || s === null || s === 0 || s === undefined) {
        return null;
    }
    if (s?.getDate) {
        return moment(s);
    } else if (!_.isNaN(Number(s))) {
        return changeDate(Number(s));
    } else {
        return moment(s);
    }
};
