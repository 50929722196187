const moduleName = "finance/profit/";
export const id = {
    id: "GROSS_PROFIT_ANALYSIS_MAINTENANCE_LIST",
    method: "POST",
    name: "毛利分析",
    type: "menu",
    apiName: "getProfitList",
    url: `${moduleName}list`
};

export const children = {
    EXPORT_DATA: {
        id: "GROSS_PROFIT_ANALYSIS_MAINTENANCE_EXPORT_DATA",
        apiName: "exportProfitAnalysisData",
        method: "POSTFILE",
        name: "导出",
        type: "view",
        url: `${moduleName}exportData`
    },
    SEARCH: {
        id: "GROSS_PROFIT_ANALYSIS_MAINTENANCE_SEARCH",
        name: "查询",
        type: "view"
    }
};
