export const id = {
    id: "PAYABLE_MANAGEMENT_PAYABLE",
    method: "POST",
    name: "应付对账",
    type: "menu",
    sort: 1
};

export const children = {
    GENERATED_BILL: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_GENERATED_BILL",
        method: "POST",
        name: "生成账单",
        type: "view"
    },
    FAST_GENERATED_BILL: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_FAST_GENERATED_BILL",
        method: "POST",
        name: "快速账单",
        type: "view"
    },
    UPDATE: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_UPDATE",
        name: "修改",
        type: "view"
    },
    CONFIRM: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_CONFIRM",
        name: "确认",
        type: "view"
    },
    DELETE: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_DELETE",
        name: "删除",
        type: "view"
    },
    REVOKE: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_REVOKE",
        name: "撤销",
        type: "view"
    },
    SEND: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_SEND",
        name: "发送",
        type: "view"
    },
    EXPORT: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_EXPORT",
        name: "导出报表",
        type: "view"
    }
};
