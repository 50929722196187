/*
 * @Descripttion:
 * @Author: hl.huang
 * @Date: 2021-01-11 15:05:18
 * @LastEditors: 黄华林
 * @LastEditTime: 2021-01-11 16:38:46
 */
export const id = {
    id: "FINANCIAL_MANAGEMENT_PAYABLE_MANAGEMENT_PAYABLE_VARIFICATION",
    method: "POST",
    name: "付款核销",
    type: "menu",
    sort: 5
};
export const children = {
    COUNT_CHECK: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_VARIFICATION_COUNT_CHECK",
        name: "账单核销",
        type: "view"
    },
    ABNORMAL_CHECK: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_VARIFICATION_ABNORMAL_CHECK",
        name: "异常核销",
        type: "view"
    },
    EXPORT: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_VARIFICATION_EXPORT",
        name: "导出数据",
        type: "view"
    },
    RECHECK: {
        id: "PAYABLE_MANAGEMENT_PAYABLE_VARIFICATION_RECHECK",
        name: "复核确认",
        type: "view"
    }
};
