import React from "react";
import { connect } from "dva";
import { app } from "@src/models";

@connect(({ global }) => ({
    powers: global.powers
}))
class FunctionPower extends React.Component {
    state = {
        power: true
    };

    constructor(props) {
        super(props);
        const { power } = props;
        if (power && power.id) {
        }
    }

    render() {
        const { power, children, powers } = this.props;
        if (process.env.NODE_ENV === "development") {
            return children;
        }
        if (power === null) {
            return null;
        }
        if (!power || !power.id || power.isShow || powers[power.id]) {
            return children;
        }
        return null;
    }
}

export const showPower = ({ power }) => {
    return function (C) {
        const { powers } = app._store.getState().global;
        // console.log('showPower', powers, power)
        if ((power && power.isShow) || (power && power.id && powers[power.id])) {
            return C;
        }
    };
};

export const getPower = (power) => {
    const { powers } = app._store.getState().global;
    return (power && power.isShow) || (power && power.id && powers[power.id]);
};

/**
 * function 隐藏金额为***
 * @param {*} power-权限数据 onlyCalcul-仅作计算
 */
export const hideMoney = ({ power, onlyCalcul }) => {
    const { powers } = app._store.getState().global;
    if (onlyCalcul) {
        if (power && power.id && powers[power.id]) {
            return false;
        } else {
            return "***";
        }
    } else {
        return function (C) {
            if (power && power.id && powers[power.id]) {
                return C;
            } else {
                return "***";
            }
        };
    }
};

export default FunctionPower;
