import request from "@src/http";
import _ from "@src/utils/lodash";
import { reduceCount } from "@src/views/transport_management/stowage_center/util";
import { confirmNode } from "@src/views/transport_management/stowage_center/public";
import { v4 as uuidv4 } from "uuid";
import NP from "number-precision";
import moment from "moment";
// import { message } from 'antd';
const rApi: any = request;
// import { AuthDef } from '@src/pages/monitor/order/index.module'
// import _ from 'lodash'
// import { message } from 'antd'
// import moment from 'moment'
// import { sleep } from '@src/libs/util'
const arrayToString = (list, key) => {
    if (!list || !_.isArray(list)) {
        list = [];
        return "[]";
    }
    const array = new Set(
        list.reduce((t, c) => {
            let st = [];
            try {
                st = JSON.parse(c[key] === "" || c[key] === null ? "[]" : c[key]);
            } catch (e) {}
            return [...t, ...st];
        }, [])
    );
    const s = JSON.stringify(Array.from(array));
    return s;
};
/**
 * 配载中心
 */

export interface IItem {
    clientId: number;
    clientName: string;
    createTime: number | string | null;
    departureTime: number | string | null;
    arrivalTime: number | string | null;
    description: string;
    haveGoodsDetails: number;
    id: number;
    uid: number;
    materialList?: any[];
    orderId: number;
    orderNumber: string;
    quantity: number;
    quantityColor: string | null;
    volume: number | null;
    volumeColor: string | null;
    netWeight: number | null;
    netWeightColor: string | null;
    weight: number | null;
    weightColor: string | null;
    receiverAddress: string;
    receiverArea: string | null;
    receiverId: number;
    receiverLatitude: number | null;
    receiverLongitude: number | null;
    receiverName: string | null;
    receiverTransit: string | null;
    senderAddress: string | null;
    senderArea: string | null;
    senderId: number;
    senderLatitude: number;
    senderLongitude: number;
    senderName: string | null;
    senderTransit: string | null;
    specialInstruction: string | null;
    specialBusiness: string | null;
    contactPersons: any[];
    status: number | null;
    projectId: number | null;
    source: number | null;
    pickUpRequirement: string | null;
    packageRequirement: string | null;
    orderReceiverSenderId: number | null;
    sixUnionSingleNumber: string | null;
    billingWeight: number | null;
}

export interface ICarrierInfo {
    transportModeName: string | null; // 运输方式
    transportModeId: string | number | null; // 运输方式
    carrierId: number | null; // 承运商
    carrierName: string | null; // 承运商
    afterTaxAmount: number | null;
    associatePaymentCarrierId: number | null;
    associatePaymentCarrierName: string | null;
    serviceFee: number | null; // 服务费率
    billingMethod: number | null; // 计费方式
    businessModelId: number | null; // 业务模式
    businessModelName: string | null;
    // businessName: string | null;
    carCode: string | null;
    carId: number | null;
    carType: number | null;
    carTypeId: number | null;
    carTypeName: string | null;
    carrierType: number | null;
    companyId: number | null;
    createTime: string | null;
    currencyId: number | null;
    currencyName: string | null;
    departure: string | null;
    departureTime: string | number | null;
    arrivalTime: string | number | null;
    destination: string | null;
    deviceNumber: string | null;
    driverId: number | null;
    driverName: string | null;
    estimatedCost: number | null;
    // id: number | null;
    isContainVehicle: number | null;
    isHighway: number | null;
    isTextsIncluded: number | null;
    isCustomsClearance: number | null;
    latitude: number | null;
    longitude: number | null;
    offerCost: number | null;
    oilAmount: number | null;
    oilScale: number | null;
    operatorId: number | null;
    operatorName: string | null;
    payTaxesType: number | null;
    phone: string | null;
    phoneBackup: string | null;
    quotationNumber: string | null;
    quotationNumberId: number | null;
    quotationId?: number | null;
    quotationType: number | null;
    remark: string | null;
    remarkDevice: string | null;
    specialInstruction: string | null;
    contactPersons: any[];
    specialBusiness: string | null;
    singleNumber: string | null;
    carrierDispatch: number | null; // 是否承运商调度(0-否，1-是)
    selectQuotation: any;
    forceQuotationNumber: string | null;
    reLoadQuotation: any;
    selectExpenseItemList: any[];
    orderExpenseItemUnitCoefficientList: any[];
    expenseItemList: any[];
    sendCarCashPriceExpenseList: any[];
    taxes: string | null;
    numberPlateId: number | null;
    numberPlateName: string | null;
    resourceVehicleId: number | null;
    resourceVehicleName: string | null;
    sixUnionSingleNumber: string | null;
    specificationModel: string | null;
    specificationModelId: number | null;
    quotationExpenses: any[];
    deleteIds: any[];
    quotationLineId: number | null;
    departureProvince: string | null;
    departureCity: string | null;
    departureArea: string | null;
    destinationProvince: string | null;
    destinationCity: string | null;
    destinationArea: string | null;
    clearanceId: string | null;
    transitPlaceOneAddress: string | null;
    transitPlaceOneId: number | null;
    totalGrossWeight: number;
    totalNetWeight: number;
    totalQuantity: number;
    totalVolume: number;
    packageRequirement: string;
    trackingNumber: string;
}
export interface ICombosItem extends ICarrierInfo {
    id: number;
    stowageNumber?: string;
    checked: boolean;
    selectItems: IItem[];
    orderList: IItem[];
    uid: number;
    transitPlaceOneAddress: string | null;
    transitPlaceOneId: number | null;
    transitPlaceOneName: string | null;
    totalBillingWeight: number;
}

export interface ICombos {
    combos: ICombosItem[];
    comboStore: any;
    historyData: any;
    uid: number;
    transitPlaceOneAddress: string | null;
    transitPlaceOneId: number | null;
    transitPlaceOneName: string | null;
    taxRateList: any[];
    latitude: number | null;
    longitude: number | null;
    focusItemId: number | string | null;
    clearanceEnabled: boolean;
}
let transportModeList = [];
let count = 0;
const getTransportModeList = () => {
    return new Promise((resolve, reject) => {
        rApi["getDicByText"]("运输方式")
            .then(async (data) => {
                transportModeList = data.map((item) => ({
                    id: item.id,
                    title: item.title
                }));
                resolve(transportModeList);
            })
            .catch(() => {
                count++;
                if (count > 20) return;
                setTimeout(() => {
                    getTransportModeList();
                }, 1000);
            });
    });
};

function initComboItem() {
    const transportModeItem = (transportModeList || []).find((item) => item.title === "汽运");
    return {
        transportModeName: transportModeItem ? "汽运" : null,
        transportModeId: transportModeItem ? transportModeItem.id : null,
        carrierId: null,
        carrierName: null,
        afterTaxAmount: null,
        associatePaymentCarrierId: null,
        associatePaymentCarrierName: null,
        billingMethod: null,
        businessModelId: 257,
        businessModelName: "零担",
        carCode: null,
        carId: null,
        carType: 1,
        carTypeId: null,
        carTypeName: null,
        carrierType: null,
        companyId: null,
        createTime: null,
        currencyId: null,
        currencyName: null,
        departure: null,
        departureTime: null,
        destination: null,
        deviceNumber: null,
        driverId: null,
        driverName: null,
        estimatedCost: null,
        // id: null,
        isContainVehicle: null,
        isCustomsClearance: false,
        isHighway: null,
        isTextsIncluded: null,
        latitude: null,
        longitude: null,
        offerCost: null,
        oilAmount: null,
        oilScale: null,
        operatorId: null,
        operatorName: null,
        payTaxesType: null,
        phone: null,
        phoneBackup: null,
        quotationNumber: null,
        quotationNumberId: null,
        quotationId: null,
        quotationType: null,
        remark: null,
        remarkDevice: null,
        selectItems: [],
        transitPlaceOneAddress: null,
        transitPlaceOneId: null,
        transitPlaceOneName: null,
        serviceFee: 0,
        taxes: null
    };
}

function initState(): ICombos {
    return {
        combos: [],
        comboStore: {},
        historyData: {},
        uid: 1,
        transitPlaceOneAddress: "",
        transitPlaceOneId: null,
        transitPlaceOneName: "",
        taxRateList: [],
        latitude: null,
        longitude: null,
        focusItemId: null,
        clearanceEnabled: false
    };
}

let itemUid = 1;

export default {
    namespace: "stowageCenterState",
    state: initState(),
    effects: {
        *initTransportModeList({ payload }: any, { call, put }: any) {
            getTransportModeList();
        },
        *getTaxRateList({ payload }: any, { call, put }: any) {
            try {
                const taxRateList = yield call(rApi.getTaxRateList);
                yield put({
                    type: "updateState",
                    payload: { taxRateList }
                });
            } catch (e) {
                console.error("getTaxRateList", e);
            }
        },
        *dragDelete({ payload }: any, { call, put, select }: any) {
            let { state } = yield select(({ stowageCenterState }) => ({
                state: stowageCenterState
            }));
            const combos = state.combos;
            const comboStore = state.comboStore;
            // console.log('dragDelete', state)
            if (payload.type === "card") {
                try {
                    const sourceDroppableId = (payload.source.droppableId || "").replace("P", "");
                    const sourceIndex = combos.findIndex((items: ICombosItem) => items.id + "" === sourceDroppableId);
                    const sourceItem = combos[sourceIndex].orderList[payload.source.index];
                    if (sourceItem.id && _.isNumber(sourceItem.id)) {
                        const res = yield call(rApi.deleteStowageOrder, {
                            id: sourceItem.id
                        });
                    }
                    const listIds = {
                        [sourceItem.clearanceId || sourceItem.uid]: true
                    };
                    combos[sourceIndex].orderList = combos[sourceIndex].orderList.filter((item) => {
                        if ((item.clearanceId || item.uid) && listIds[item.clearanceId || item.uid]) {
                            delete comboStore[item.id][item.uid];
                            return false;
                        }
                        return true;
                    });

                    if (!combos[sourceIndex].orderList.length) {
                        combos[sourceIndex].stowageNumber = null;
                    }

                    // combos[sourceIndex].orderList.splice(payload.source.index, 1)
                    // // console.log('comboStore', comboStore, sourceDroppableId)
                    // delete comboStore[sourceItem.id][sourceItem.uid]
                } catch (e) {
                    console.error("e", e);
                }
            }
            yield put({
                type: "updateState",
                payload: {
                    ...state,
                    comboStore,
                    combos: combos.map((items) => ({
                        ...items,
                        list: items.orderList.map((cItem: IItem) => ({
                            ...cItem
                        }))
                    }))
                }
            });
        },
        *updateComboSort({ payload }: any, { call, put, select }: any) {
            const { state } = yield select(({ stowageCenterState }) => ({
                state: stowageCenterState
            }));
            const combos = state.combos;
            const comboStore = state.comboStore;

            if (payload.type === "COLUMN" && payload.destination && _.isNumber(payload.destination.index)) {
                const item = combos[payload.source.index];
                combos.splice(payload.source.index, 1);
                combos.splice(payload.destination.index, 0, item);
            } else if (payload.type === "card") {
                if (payload.destination && payload.destination.droppableId) {
                    // tslint:disable-next-line:radix
                    const sourceDroppableId = (payload.source.droppableId || "").replace("P", "");
                    // tslint:disable-next-line:radix
                    const destinationDroppableId = (payload.destination.droppableId || "").replace("P", "");
                    // console.log('sourceDroppableId', sourceDroppableId)
                    // console.log('destinationDroppableId', destinationDroppableId, combos)
                    const sourceIndex = combos.findIndex((items: ICombosItem) => items.id + "" === sourceDroppableId);
                    const destinationIndex = combos.findIndex(
                        (items: ICombosItem) => items.id + "" === destinationDroppableId
                    );
                    // if (combos[sourceIndex]) {
                    //     const item = combos[sourceIndex].orderList[payload.source.index]
                    //     combos[sourceIndex].orderList.splice(payload.source.index, 1)
                    //     combos[destinationIndex].orderList.splice(payload.destination.index, 0, item)
                    // }
                    // console.log('combos[sourceIndex]', combos[sourceIndex], sourceIndex, combos, payload.source.droppableId)
                    const item = combos[sourceIndex].orderList[payload.source.index];
                    try {
                        yield call(confirmNode, {
                            pendingList: [item],
                            orderList: combos[destinationIndex].orderList
                        });
                        combos[sourceIndex].orderList.splice(payload.source.index, 1);
                        delete comboStore[item.id][item.uid];
                        if (_.isNumber(combos[destinationIndex].id)) {
                            item.stowageId = combos[destinationIndex].id;
                        }
                        // 若有相同发货单 直接相加货物
                        const find = combos[destinationIndex].orderList.find((el) => el.id === item.id);
                        if (find) {
                            const keys = ["quantity", "weight", "netWeight", "volume"];
                            // 是否有货物明细
                            if (find.materialList.length) {
                                find.materialList.map((el, i) => {
                                    keys.forEach((k) => {
                                        el[k] = NP.plus(el[k] || 0, item.materialList[i][k] || 0);
                                    });
                                });
                                const { quantity, netWeight, weight, volume } = reduceCount(find.materialList);
                                find.quantity = quantity;
                                find.netWeight = netWeight;
                                find.weight = weight;
                                find.volume = volume;
                            } else {
                                keys.forEach((k) => {
                                    find[k] = NP.plus(find[k] || 0, item[k] || 0);
                                });
                            }
                        } else {
                            combos[destinationIndex].orderList.splice(payload.destination.index, 0, item);
                        }
                    } catch (e) {
                        return;
                    }
                }
            }
            yield put({
                type: "updateState",
                payload: {
                    ...state,
                    combos: combos.map((items) => ({
                        ...items,
                        list: items.orderList.map((cItem: IItem) => ({
                            ...cItem
                        }))
                    })),
                    comboStore
                }
            });
        }
    },
    reducers: {
        updateState(state: any, { payload }: any) {
            return {
                ...state,
                ...payload
            };
        },
        updateClearanceEnabled(state: any, { payload }: any) {
            return {
                ...state,
                clearanceEnabled: payload.clearanceEnabled
            };
        },
        /**
         * @function 存储配载原始数量，用于扣数时数量限制
         *
         * @param {*} state
         * @param {*} { payload }
         * @returns
         */
        setHistoryStore(state: any, { payload }: any) {
            const historyData = state.historyData;
            let haveUpdate = false;
            if (_.isArray(payload)) {
                payload.forEach((ele) => {
                    if (!historyData[ele.id]) {
                        historyData[ele.id] = { ...ele };
                        haveUpdate = true;
                    }
                });
            }
            // console.log('historyData', historyData, payload)
            if (haveUpdate) {
                return {
                    ...state,
                    historyData: { ...historyData }
                };
            } else {
                return state;
            }
        },
        addComboItem(state: any, { payload }: any) {
            // if (!transportModeList || transportModeList.length < 1) {
            //     getTransportModeList();
            // }
            const focusItemId = state.focusItemId;
            const comboStore = state.comboStore;
            let combos = state.combos;
            let departureTime = null;
            let { list, params } = payload || {
                params: {},
                list: []
            };
            list = list.map((element) => {
                if (!comboStore[element.id]) {
                    comboStore[element.id] = {
                        [element.uid]: { ...element }
                    };
                } else {
                    comboStore[element.id][element.uid] = { ...element };
                }
                if (element.departureTime) {
                    if (!departureTime) {
                        departureTime = element.departureTime;
                    }
                    if (moment(element.departureTime).toDate().getDate() < moment(departureTime).toDate().getDate()) {
                        departureTime = element.departureTime;
                    }
                }

                return {
                    ...element,
                    id: element.id + ""
                };
            });
            const uid = uuidv4();
            const specialInstruction = arrayToString(list, "specialInstruction");
            const packageRequirement = arrayToString(list, "pickUpRequirement");
            if (combos.some((item) => item.id === focusItemId)) {
                combos = combos.map((item) => {
                    if (item.id === focusItemId) {
                        const orderList = [...item.orderList, ...list];
                        const sixUnionSingleNumber = params.isCustomsClearance
                            ? (orderList.find((item) => !!item.sixUnionSingleNumber) || { sixUnionSingleNumber: null })
                                  .sixUnionSingleNumber
                            : null;
                        return {
                            ...item,
                            ...params,
                            orderList,
                            specialInstruction,
                            packageRequirement,
                            sixUnionSingleNumber
                        };
                    }
                    return item;
                });
            } else {
                const sixUnionSingleNumber = params.isCustomsClearance
                    ? (list.find((item) => !!item.sixUnionSingleNumber) || { sixUnionSingleNumber: null })
                          .sixUnionSingleNumber
                    : null;
                combos = [
                    ...state.combos,
                    {
                        id: uid,
                        uid: uid,
                        ...initComboItem(),
                        departureTime,
                        orderList: list,
                        specialInstruction,
                        packageRequirement,
                        checked: true,
                        ...params,
                        sixUnionSingleNumber
                    }
                ];
            }
            // console.log('combos', combos)
            return {
                ...state,
                comboStore,
                combos
            };
        },
        setCombos(state: any, { payload }: any) {
            const comboStore = {};
            payload.forEach((ele) => {
                ele.orderList.forEach((element) => {
                    if (!comboStore[element.id]) {
                        comboStore[element.id] = {
                            [element.uid]: { ...element }
                        };
                    } else {
                        comboStore[element.id][element.uid] = { ...element };
                    }
                });
            });
            // console.log("comboStore", comboStore);
            return {
                ...state,
                comboStore,
                historyData: {},
                combos: [...payload.map((item) => ({ ...item, checked: true }))]
            };
        },
        /**
         * @function 修改配载数量
         *
         * @param {*} state
         * @param {*} { payload }
         * @returns
         */
        updateComboItemCount(state: any, { payload }: any) {
            const comboStore = state.comboStore;
            const combos = state.combos;
            if (!payload.materialId) {
                combos[payload.combosIndex].orderList[payload.index] = {
                    ...combos[payload.combosIndex].orderList[payload.index],
                    ...payload.value
                };
                const item = combos[payload.combosIndex].orderList[payload.index];
                // console.log('updateComboItemCount', item)
                comboStore[item.id][item.uid] = {
                    ...item
                };
            } else {
                combos[payload.combosIndex].orderList[payload.index] = {
                    ...combos[payload.combosIndex].orderList[payload.index],
                    materialList: payload.materialList,
                    quantity: reduceCount(payload.materialList, "quantity"),
                    netWeight: reduceCount(payload.materialList, "netWeight") || 0,
                    weight: reduceCount(payload.materialList, "weight"),
                    volume: reduceCount(payload.materialList, "volume")
                };
                const item = combos[payload.combosIndex].orderList[payload.index];
                comboStore[item.id] = {
                    ...payload.comboRows
                };
            }

            // console.log('comboStore', comboStore)
            return {
                ...state,
                comboStore: { ...comboStore },
                combos: combos.map((items) => ({
                    ...items,
                    list: items.orderList.map((cItem: IItem) => ({ ...cItem }))
                }))
            };
        },
        updateComboItem(state: any, { payload }: any) {
            const { index, ...reset } = payload;
            const combos = state.combos;
            // console.log('updateComboItem', index, reset, combos)
            combos[index] = {
                ...combos[index],
                ...reset
            };
            return {
                ...state,
                combos: combos.map((items) => ({
                    ...items
                }))
            };
        },
        updateComboCheck(state: any, { payload }: any) {
            const combos = state.combos;
            combos[payload.index].checked = payload.checked;
            // console.log('updateComboCheck combos', combos)
            return {
                ...state,
                // comboStore: { ...comboStore },
                combos: combos.map((items) => ({
                    ...items
                }))
            };
        },
        updateComboCheckBach(state: any, { payload }: any) {
            let combos = state.combos;
            if (payload === "clear") {
                combos = combos.map((item) => ({ ...item, checked: false }));
            } else if (payload === "all") {
                combos = combos.map((item) => ({ ...item, checked: true }));
            }
            // combos[payload.index].checked = payload.checked
            return {
                ...state,
                // comboStore: { ...comboStore },
                combos: combos.map((items) => ({
                    ...items
                }))
            };
        },
        updateComboSelect(state: any, { payload }: any) {
            const combos = state.combos;
            combos[payload.index].selectItems = payload.selectItems;
            return {
                ...state,
                // comboStore: { ...comboStore },
                combos: combos.map((items) => ({
                    ...items
                }))
            };
        },
        cancelSelectItem(state: any, { payload }: any) {
            const combos = state.combos;
            const comboStore = state.comboStore;
            let cancelItem = [];
            combos.map((item) => {
                // console.log('combos', item)
                if (item.checked) {
                    cancelItem = [...cancelItem, ...item.orderList];
                } else {
                    const listIds = {};
                    const selectItems = item.selectItems || [];
                    selectItems.forEach((item) => {
                        if (item.clearanceId || item.uid) {
                            listIds[item.clearanceId || item.uid] = true;
                        }
                        return null;
                    });
                    cancelItem = [
                        ...cancelItem,
                        ...item.orderList.filter((item) => !!listIds[item.clearanceId || item.uid])
                    ];
                }
            });
            // const cancelId = {}
            // const cancelUid = {}
            const cancelUidToId = {};
            // const boardIndex = {}

            cancelItem.forEach((item: IItem, index: number) => {
                // ele.orderList.forEach(item => {
                // cancelUid[item.id] = item.uid
                cancelUidToId[item.uid] = item.id;

                // })
                // combos[index].selectItems = []
            });
            // console.log('cancelUidToId', cancelUidToId, combos, cancelItem)
            // console.log('cancelUidToId', cancelUidToId, comboStore)
            _.keys(cancelUidToId).map((item) => {
                if (comboStore[cancelUidToId[item]]) {
                    delete comboStore[cancelUidToId[item]][item];
                }
            });

            // console.log('cancelUidToId', cancelUidToId)

            return {
                ...state,
                comboStore: { ...comboStore },
                combos: combos
                    .filter((item) => !item.checked)
                    .map((item) => ({
                        ...item,
                        orderList: item.orderList.filter((ele) => !cancelUidToId[ele.uid]),
                        selectItems: []
                    }))
            };
        },
        cancelSelectBoard(state: any, { payload }: any) {
            const combos = state.combos;
            const comboStore = state.comboStore;
            const cancelItem = combos.filter((item) => item.checked);
            // const cancelId = {}
            const cancelUid = {};
            // const boardIndex = {}
            cancelItem.forEach((ele: ICombosItem, index: number) => {
                if (ele && ele.orderList) {
                    ele.orderList.forEach((item) => {
                        cancelUid[item.uid] = item.id;
                    });
                }
            });
            // console.log('cancelUid', cancelUid, cancelItem)
            _.keys(cancelUid).map((item) => {
                delete comboStore[cancelUid[item]][item];
            });
            // console.log('comboStore', comboStore)
            return {
                ...state,
                comboStore: { ...comboStore },
                combos: combos.filter((item) => !item.checked)
            };
        },
        clearStowageList(state: any, { payload }: any) {
            return {
                ...state,
                comboStore: {},
                combos: []
            };
        },
        clear(state: any) {
            return initState();
        },
        updateFocusItem(state: any, { payload }: any) {
            return {
                ...state,
                ...payload
            };
        }
    }
};
