const m = "wms/shipmentManage";
const exportUrl = "wms/export";

export const id = {
    id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS",
    method: "GET",
    name: "出货管理明细",
    type: "menu_hide",
    apiName: "getShipmentManageById",
    url: `${m}/getBasicInfo`,
    paramsType: 1
};

export const children = {
    CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_CONFIRM",
        apiName: "shipmentManageConfirm",
        method: "GET",
        name: "确认",
        type: "view",
        url: `${m}/confirm`,
        paramsType: 1
    },
    UN_CONFIRM: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_UN_CONFIRM",
        apiName: "shipmentManageCancelConfirm",
        method: "GET",
        name: "取消确认",
        type: "view",
        url: `${m}/cancelConfirm`,
        paramsType: 1
    },
    PLAN_LIST: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_PLAN_LIST",
        apiName: "shipmentManagePlanList",
        method: "POST",
        name: "获取出货计划列表",
        type: "view",
        url: `${m}/detailsList`,
        paramsType: 1,
        isOnlyApi: true
    },
    PLAN_SUM: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_PLAN_SUM",
        apiName: "shipmentManagePlanSum",
        method: "GET",
        name: "出货单明细汇总",
        type: "view",
        url: `${m}/count`,
        paramsType: 1,
        isOnlyApi: true
    },
    STOP: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_STOP",
        apiName: "shipmentManageStop",
        method: "POST",
        name: "中止出货",
        type: "view",
        url: `${m}/terminationReceipt`
    },
    SYSTEM_ALLOCATE: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_SYSTEM_ALLOCATE",
        apiName: "shipmentManageSystemAllocate",
        name: "系统分配",
        type: "view",
        method: "GET",
        url: `${m}/assignAuto`,
        paramsType: 1
    },
    HAND_ALLOCATE: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_HAND_ALLOCATE",
        apiName: "shipmentManageHandAllocate",
        name: "手动分配",
        type: "view",
        method: "POST",
        url: `${m}/assignManually`
    },
    INVENTORY: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_INVENTORY",
        apiName: "shipmentManageInventory",
        method: "GET",
        name: "获取可用库存列表数据",
        type: "view",
        url: `${m}/getAvailableStockList`,
        paramsType: 1,
        isOnlyApi: true
    },
    ALLOCATE_LIST: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_ALLOCATE_LIST",
        apiName: "shipmentManageAllocateList",
        method: "GET",
        name: "获取分配清单列表",
        type: "view",
        url: `${m}/getDistributionList`,
        paramsType: 1,
        isOnlyApi: true
    },
    CANCEL_ALLOCATE: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_CANCEL_ALLOCATE",
        apiName: "shipmentManageCancelAllocate",
        method: "GET",
        name: "撤销分配",
        type: "view",
        url: `${m}/cancelAllocation`,
        paramsType: 1
    },
    ALLOCATE_FINISH: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_ALLOCATE_FINISH",
        apiName: "shipmentManageAllocateFinish",
        method: "GET",
        name: "分配完成",
        type: "view",
        url: `${m}/assignmentCompleted`,
        paramsType: 1
    },
    SYSTEM_PICK: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_SYSTEM_PICK",
        apiName: "shipmentManageSystemPick",
        method: "GET",
        name: "快速拣货",
        type: "view",
        url: `${m}/pick/fast`,
        paramsType: 1
    },
    ASSIGN_PICK: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_ASSIGN_PICK",
        apiName: "shipmentManageAssignPick",
        method: "POST",
        name: "指派拣货人",
        type: "view",
        url: `${m}/assignedPickPerson`
    },
    PICK_LIST: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_PICK_LIST",
        apiName: "shipmentManagePickList",
        method: "GET",
        name: "获取拣货清单列表",
        type: "view",
        url: `${m}/pickList`,
        paramsType: 1,
        isOnlyApi: true
    },
    EXPORT: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_EXPORT",
        apiName: "shipmentManageExportAssign",
        method: "POST",
        name: "导出签收单",
        type: "view",
        url: `${m}/xxx`,
        paramsType: 1,
        isOnlyApi: true
    },
    CANCEL_PICK: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_CANCEL_PICK",
        apiName: "shipmentManageCancelPick",
        method: "POST",
        name: "撤销拣货",
        type: "view",
        url: `${m}/pick/revoke`
    },
    FINISH_PICK: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_FINISH_PICK",
        apiName: "shipmentManageFinishPick",
        method: "GET",
        name: "拣货完成",
        type: "view",
        url: `${m}/pick/finish`,
        paramsType: 1
    },
    FINISH: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_FINISH",
        apiName: "shipmentManageFinish",
        method: "GET",
        name: "出货完成",
        type: "view",
        url: `${m}/finish`,
        paramsType: 1
    },
    SERIAL: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_SERIAL",
        apiName: "shipmentManageSerial",
        method: "GET",
        name: "获取序列号列表",
        type: "view",
        url: `wms/shipmentScanList`,
        paramsType: 1,
        isOnlyApi: true
    },
    SERIAL_SPLIT: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_SERIAL_SPLIT",
        apiName: "shipmentManageSerialDismantled",
        method: "GET",
        name: "获取库存序列号列表",
        type: "view",
        url: `wms/shipmentScanList/inventory`,
        paramsType: 1,
        isOnlyApi: true
    },
    SPLIT: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_SPLIT",
        apiName: "shipmentManageSplit",
        method: "POST",
        name: "拆板",
        type: "view",
        url: `${m}/demolition`,
        paramsType: 2,
        paramsKeys: ["shipmentListId", "inventoryManageId"],
        paramsBodyKey: "ids"
    },
    EXPORT1: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_EXPORT1",
        apiName: "shipmentManageExportPlan",
        method: "POSTFILE",
        name: "导出计划单",
        type: "view",
        url: `${exportUrl}/shipment/cargoDetail`
    },
    EXPORT2: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_EXPORT2",
        apiName: "shipmentManageExportAllocate",
        method: "POSTFILE",
        name: "导出理货单",
        type: "view",
        url: `${exportUrl}/shipment/distributionList`
    },
    EXPORT3: {
        id: "WAREHOUSE_MANAGEMENT_SHIPMENT_MANAGE_DETAILS_EXPORT3",
        apiName: "shipmentManageExportUpper",
        method: "POSTFILE",
        name: "导出上架单",
        type: "view",
        url: `${exportUrl}/shipment/pickList`
    }
};
